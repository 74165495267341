import React, {useState, useEffect} from 'react';
import {View, StyleSheet, Image, FlatList, TouchableOpacity} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import * as DocumentPicker from 'expo-document-picker';

import HoverableView from '~/components/common/HoverableView';
import * as Icons from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import {isImg} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import Button from '../../common/Buttons';

const UPLOAD_CHAT_ATTACHMENT = gql`
    mutation UploadChatAttachment($fileBase64: String!, $fileExtension: String!) {
        uploadChatAttachment(input: {fileBase64: $fileBase64, fileExtension: $fileExtension}) {
            imageName
            error
        }
    }
`;

const NB_MAX_ATTACHMENT_DESKTOP = 3;
const NB_MAX_ATTACHMENT_MOBILE = 1;
const NB_MAX_MO = 5;

type ChatAttachmentsProps = {
    attachments: string[];
    setAttachments: (attachments: string[]) => void;
    onDisplayModalError: () => void;
    onCloseModalError: () => void;
};

export default function ChatAttachments(props: ChatAttachmentsProps) {
    const {isDesktop} = useDeviceQuery();
    const confirm = useConfirm();
    const alert = useAlert();
    const [UploadChatAttachment, {data: uploadData, error: uploadError}] = useMutation(
        UPLOAD_CHAT_ATTACHMENT
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function size(base64: string) {
        const bytes = (base64.length - 814) / 1.37;
        const mb = Math.round(bytes / (1024 * 1024));
        return mb;
    }

    function getMaxAttachment() {
        return isDesktop ? NB_MAX_ATTACHMENT_DESKTOP : NB_MAX_ATTACHMENT_MOBILE;
    }

    function displayError(message: string) {
        props.onDisplayModalError();
        alert({message: message, onClose: props.onCloseModalError});
    }

    useEffect(() => {
        if (uploadData) {
            setIsLoading(false);
            const error = uploadData?.uploadChatAttachment.error;
            if (error) {
                displayError(error);
            } else {
                const attachment = uploadData?.uploadChatAttachment.imageName;
                const newAttachment = [...props.attachments, attachment];
                props.setAttachments(newAttachment);
            }
        }
    }, [uploadData]);

    let openImagePickerAsync = async () => {
        if (props.attachments.length >= getMaxAttachment()) {
            displayError(`Le maximum de pièces jointes est atteint (${getMaxAttachment()}).`);
        } else {
            let pickerResult = await DocumentPicker.getDocumentAsync({
                multiple: false,
            });

            if (pickerResult && !pickerResult.canceled && pickerResult.output) {
                const asset = pickerResult.assets[0];
                if (size(asset.uri) > NB_MAX_MO) {
                    displayError('Le fichier dépasse le poids maximum (5Mo)');
                } else {
                    const name = asset.name;
                    const extension = name.substring(name.lastIndexOf('.') + 1, name.length);
                    const variables = {
                        fileBase64: asset.uri,
                        fileExtension: extension,
                    };
                    setIsLoading(true);
                    UploadChatAttachment({variables});
                }
            }
        }
    };

    function deleteAttachement(item: string) {
        props.onDisplayModalError();
        confirm({
            title: 'Supprimer une pièce jointe',
            message: 'Êtes-vous sur de vouloir supprimer la pièce jointe ?',
            onYes: () => {
                const newAttachment = props.attachments.filter(function (value) {
                    return value != item;
                });
                props.setAttachments(newAttachment);
                props.onCloseModalError();
            },
            onNo: () => {
                props.onCloseModalError();
            },
            yesText: 'Supprimer',
            noText: 'Annuler',
        });
    }

    function renderAttachment({item}: {item: string}) {
        const img = isImg(item);
        return (
            <View style={styles.attachmentView}>
                <HoverableView style={{}} hoverStyle={styles.hoverAttachment}>
                    <TouchableOpacity onPress={() => deleteAttachement(item)}>
                        {!img && (
                            <View style={styles.attachmentIcon}>
                                <Icons.File size={24} color={Colors.secondary} />
                            </View>
                        )}
                        {img && (
                            <Image
                                source={{
                                    uri: Settings.getUrlImageAttachment(item),
                                }}
                                style={styles.attachment}
                            />
                        )}
                    </TouchableOpacity>
                </HoverableView>
            </View>
        );
    }

    return (
        <View>
            <View style={styles.attachmentButton}>
                <Button
                    icon={
                        <Icons.Attachment
                            size={18}
                            style={{paddingRight: 10}}
                            color={Colors.disabled}
                        />
                    }
                    title=""
                    buttonStyle={{
                        width: 43,
                        borderRadius: 25,
                        alignSelf: 'center',
                        backgroundColor: 'white',
                    }}
                    onPress={openImagePickerAsync}
                    loading={isLoading}
                    style={{margin: 0, marginHorizontal: 0}}
                    loadingProps={{color: Colors.disabled}}
                />
                <View style={styles.attachementContainer}>
                    {props.attachments.length > 0 && (
                        <FlatList
                            data={props.attachments}
                            renderItem={renderAttachment}
                            keyExtractor={(item) => item}
                            contentContainerStyle={styles.attachements}
                        />
                    )}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    attachementContainer: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
    },
    attachements: {
        flexDirection: 'row',
    },
    attachment: {
        resizeMode: 'contain',
        width: 45,
        height: 45,
        borderWidth: 1,
        borderColor: Colors.primary,
    },
    attachmentIcon: {
        width: 45,
        height: 45,
        borderWidth: 1,
        borderColor: Colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
    },
    attachmentButton: {
        flexDirection: 'row',
    },
    attachmentLink: {
        color: Colors.link,
        fontSize: 14,
    },
    hoverAttachment: {
        opacity: 0.5,
    },
    attachmentView: {
        marginLeft: 10,
    },
});
