import React, {useEffect} from 'react';
import {View, StyleSheet, FlatList, ViewStyle} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {LinearGradient} from 'expo-linear-gradient';
import {TouchableOpacity} from 'react-native-gesture-handler';

import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {MessageForm} from '~/components/user/client/message/NewMessage';
import * as Colors from '~/constants/Colors';
import {ExchangeTypeEnum} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import {GetFreeMessageExpertsQuery} from './types/GetFreeMessageExpertsQuery';

const GET_FREE_MESSAGE_EXPERTS_QUERY = gql`
    query GetFreeMessageExpertsQuery {
        getFreeMessageExperts {
            edges {
                node {
                    id
                    expertId
                    expert {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                }
            }
        }
    }
`;

type FreeMessageExpert = {
    expertId: string;
    displayName: string;
    pictureName: string;
};

type FreeMessageProps = {
    visible: boolean;
    onSuccess: () => void;
    onCancel: () => void;
};

export default function FreeMessage(props: FreeMessageProps) {
    const [load, {called, loading, data, refetch}] = useLazyQuery<GetFreeMessageExpertsQuery>(
        GET_FREE_MESSAGE_EXPERTS_QUERY,
        {fetchPolicy: 'network-only'}
    );
    const [expert, setExpert] = React.useState<FreeMessageExpert | null>(null);
    const {isDesktop, isMobile} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles : mobileStyles;

    useEffect(() => {
        if (props.visible) {
            reload();
        }
    }, [props.visible]);

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    async function onSuccess() {
        reload();
        setExpert(null);
        props.onSuccess();
    }

    function onCancel() {
        setExpert(null);
        props.onCancel();
    }

    function onPressExpert(item: FreeMessageExpert) {
        setExpert(item);
    }

    function renderExpert({item}: {item: FreeMessageExpert}) {
        const onPress = () => onPressExpert(item);
        if (isDesktop) {
            return <DesktopExpert expert={item} onPress={onPress} />;
        } else {
            return <MobileExpert expert={item} onPress={onPress} />;
        }
    }

    if (!props.visible) {
        return null;
    }

    if (data) {
        const experts = data.getFreeMessageExperts?.edges.map((e) => {
            return {
                expertId: e?.node?.expert?.id ?? '',
                pictureName: e?.node?.expert?.profile?.pictureName ?? '',
                displayName: e?.node?.expert?.profile?.displayName ?? '',
            } as FreeMessageExpert;
        });

        if (experts && experts.length > 0) {
            return (
                <View style={deviceStyle.container}>
                    <View style={deviceStyle.expertContainer}>
                        {isDesktop && (
                            <View style={styles.iconContainer}>
                                <Icons.MailSend size={32} color={'white'} />
                            </View>
                        )}
                        <FlatList
                            data={experts}
                            renderItem={renderExpert}
                            keyExtractor={(item) => item.expertId}
                            contentContainerStyle={deviceStyle.listContainer}
                            horizontal={isMobile}
                            showsHorizontalScrollIndicator={isDesktop}
                        />
                        {isMobile && <WhiteGradient />}
                        {isMobile && <WhiteGradient reversed />}
                    </View>
                    {!expert && (
                        <View style={deviceStyle.noExpert}>
                            <SimpleText style={deviceStyle.noExpertText}>
                                Veuillez sélectionner le Spirite auquel vous souhaitez envoyer
                                un message de suivi.
                            </SimpleText>
                            <Info />
                        </View>
                    )}
                    {expert && (
                        <MessageForm
                            exchangeType={ExchangeTypeEnum.CLIENT_TO_EXPERT}
                            recieverId={expert.expertId}
                            messageId={'FREE'}
                            displayName={expert.displayName}
                            pictureName={expert.pictureName}
                            messageType={MessageTypeEnum.FREE}
                            onSuccess={onSuccess}
                            onCancel={onCancel}
                        />
                    )}
                </View>
            );
        } else {
            return (
                <View style={mobileStyles.noExpert}>
                    <SimpleText>
                        Vous n'avez aucun spirite disponible pour effectuer un suivi gratuit.
                    </SimpleText>
                    <Info />
                </View>
            );
        }
    }

    return <></>;
}

function WhiteGradient({reversed, width}: {reversed?: boolean; width?: number}) {
    let colors = ['white', 'transparent'];
    const gradientWidth = width ?? 20;
    let style = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: gradientWidth,
    } as ViewStyle;
    if (reversed) {
        colors = colors.reverse();
        style = {...style, right: 0};
    } else {
        style = {...style, left: 0};
    }
    return (
        <LinearGradient
            start={{x: 0, y: 1}}
            end={{x: 1, y: 1}}
            colors={colors}
            style={style}
        />
    );
}

function DesktopExpert({expert, onPress}: ExpertFreeMessageProps) {
    return (
        <TouchableOpacity style={styles.pictureContainer} onPress={onPress}>
            <ProfilePicture style={styles.profilePicture} pictureName={expert.pictureName} />
        </TouchableOpacity>
    );
}

function MobileExpert({expert, onPress}: ExpertFreeMessageProps) {
    return (
        <TouchableOpacity style={mobileStyles.pictureContainer} onPress={onPress}>
            <ProfilePicture
                style={mobileStyles.profilePicture}
                pictureName={expert.pictureName}
            />
            <SimpleText style={mobileStyles.expertName}>{expert.displayName}</SimpleText>
        </TouchableOpacity>
    );
}

export type ExpertFreeMessageProps = {
    expert: FreeMessageExpert;
    onPress: () => void;
};

function Info() {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    return (
        <View style={deviceStyles.info}>
            <Icons.Info size={20} color={Colors.link} style={deviceStyles.infoIcon} />
            <SimpleText style={deviceStyles.infoText}>
                Vous pouvez envoyer gratuitement jusqu'à 3 messages de suivi aux spirites que
                vous avez déjà consulté. Cette fonctionnalité vous permettra de demander des
                précisions sur les sujets abordés et/ou d'envoyer des éléments vous concernant
                en vue des prochaines consultations.
            </SimpleText>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginLeft: 15,
        backgroundColor: Colors.dark,
        flexDirection: 'row',
    },
    expertContainer: {
        width: 90,
        justifyContent: 'space-between',
        backgroundColor: 'white',
        margin: 15,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        flex: 1,
    },
    iconContainer: {
        backgroundColor: Colors.primary,
        padding: 25,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
    },
    listContainer: {},
    pictureContainer: {
        alignSelf: 'center',
        marginVertical: 30,
    },
    profilePicture: {
        width: 45,
        height: 45,
        borderRadius: 40,
        alignSelf: 'center',
    },
    noExpert: {
        marginHorizontal: 50,
        marginTop: 20,
    },
    noExpertText: {},
    info: {
        marginTop: 30,
        paddingTop: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 744,
    },
    infoIcon: {
        marginRight: 30,
    },
    infoText: {},
});

const mobileStyles = StyleSheet.create({
    container: {
        backgroundColor: Colors.dark,
        flexDirection: 'column',
    },
    expertContainer: {
        backgroundColor: 'white',
        paddingTop: 7,
        paddingBottom: 2,
    },
    listContainer: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    pictureContainer: {
        alignSelf: 'center',
        marginHorizontal: 10,
    },
    profilePicture: {
        width: 65,
        height: 65,
        borderRadius: 65,
        alignSelf: 'center',
        marginBottom: 5,
        borderColor: Colors.primary,
        borderWidth: 2,
    },
    expertName: {
        fontSize: 10,
        textAlign: 'center',
    },
    noExpert: {
        alignItems: 'center',
        marginHorizontal: 20,
        marginVertical: 30,
        maxWidth: 400,
        alignSelf: 'center',
    },
    noExpertText: {
        textAlign: 'center',
    },
    info: {
        maxWidth: 600,
        borderTopWidth: 1,
        borderTopColor: Colors.dark,
        marginTop: 20,
        marginBottom: 40,
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingTop: 10,
        paddingBottom: 20,
    },
    infoIcon: {
        marginVertical: 10,
        textAlign: 'center',
    },
    infoText: {},
});
