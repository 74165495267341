import React, {useState, useEffect, useImperativeHandle} from 'react';
import {View, StyleSheet, Image} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Picker} from '@react-native-picker/picker';
import {TouchableOpacity} from 'react-native-gesture-handler';

import OfferListItem from '~/components/common/IconText';
import * as Icons from '~/components/common/Icons';
import CustomModal, {PaymentAlert} from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {GetLastExpertsQuery_getLastExperts_edges_node as Expert} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {getWarningDuration} from '~/helpers/dates';
import {getOffer} from '~/helpers/experts';
import {setReloadForExpert} from '~/helpers/pack';
import redirect from '~/helpers/redirect';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {navigate} from '~/navigation';
import {ExpertsDetailsQuery_findExperts_edges_node as ExpertDetails} from '~/queries/types/ExpertsDetailsQuery';
import {GetClientOffers} from '~/queries/types/GetClientOffers';
import {QueryMe_me} from '~/queries/types/QueryMe';

import type {ExpertProfile_getExpert as ExpertProfile} from '../../../screens/types/ExpertProfile';
import Button from '../../common/Buttons';
import {Card} from '../stripe/CardInfo';
import {initializeChat} from './types/initializeChat';

type PopupChatProps = {
    visible: boolean;
    onRequestClose: () => void;
    client: QueryMe_me | null | undefined;
    clientOffers: GetClientOffers | null | undefined;
    expert: ExpertProfile | ExpertDetails | Expert | null | undefined;
    startChatExpert: (chatId: string, warning: number | null, pack: boolean) => void;
    wait: (warning: number | null) => void;
};

const INITILIAZE_CHAT = gql`
    mutation initializeChat($expertId: String!, $warning: Int!, $usePack: Boolean!) {
        initializeChat(input: {expertId: $expertId, warning: $warning, usePack: $usePack}) {
            error
            url
            chat {
                chatId
            }
        }
    }
`;

const PopupChat = React.forwardRef((props: PopupChatProps, ref) => {
    const {me, offers, minutes} = React.useContext(UserContext);
    const {isDesktop} = useDeviceQuery();
    const [initializeChat, {data: initData, error: initError}] = useMutation<initializeChat>(
        INITILIAZE_CHAT
    );
    const confirm = useConfirm();
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [warning, setWarning] = useState<number>(0);
    const [pack, setPack] = useState<boolean>(false);

    const solde = minutes?.getMinuteBalance?.minutes ?? 0;

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        const warningDuration = getWarningDuration(
            warning,
            props.expert?.chatParameters?.chatPrice?.priceDecimal
        );
        const error = initData?.initializeChat?.error;
        if (error == 'NO_PAYMENT_METHOD_ATTACHED') {
            confirm({
                title: 'Aucun moyen de paiement ajouté',
                message:
                    "Pour pouvoir utiliser nos services vous devez d'abord ajouter un moyen de paiement à votre compte. Voulez vous ajouter un moyen de paiement dès maintenant ?",
                onYes: () => navigate('AccountScreen', {event: 'add-payment-method'}),
                noText: 'Non merci',
            });
        } else if (error == 'NOT_ENOUGH_MINUTE') {
            confirm({
                title: 'Solde épuisé',
                message:
                    'Pour pouvoir utiliser nos services vous devez recharger votre solde de minutes. Voulez vous recharger dès maintenant ?',
                onYes: () => {
                    if (isDesktop) {
                        navigate('PackScreen');
                    } else {
                        navigate('Main', {
                            screen: 'Home',
                            params: {screen: 'PackScreen'},
                        });
                    }
                },
                noText: 'Non merci',
            });
        } else if (error == 'NOT_ENOUGH_FUNDS') {
            confirm({
                title: 'Fonds insuffisants',
                message:
                    'Vous ne disposez pas de fonds suffisant avec votre moyen de paiement actuel. Vous pouvez vous rendre sur votre profil pour vérifier quel moyen de paiement est utilisé.',
                onYes: () => navigate('AccountScreen'),
                yesText: 'Y aller',
                noText: 'Non merci',
            });
        } else if (error == 'HOLD_ERROR') {
            alert({
                title: "Erreur d'autoristation",
                message:
                    'Une erreur inattendue est survenue lors de la réservation de paiement effectué sur votre carte. Veuillez ré-essayer plus tard, contactez le support si le problème persiste.',
            });
        } else if (
            error == 'EXPERT_PAUSE' ||
            error == 'EXPERT_CALLING' ||
            error == 'QUEUE_NOT_EMPTY' ||
            error == 'EXPERT_CHATTING'
        ) {
            props.wait(warningDuration);
        } else if (error == 'EXPERT_UNAVAILABLE') {
            alert({
                title: 'Chat indisponible',
                message: 'Votre spirite est indisponible',
            });
        } else if (error == 'CLIENT_ALREADY_IN_QUEUE') {
            alert({
                title: 'Chat indisponible',
                message:
                    "Vous êtes déjà en liste d'attente. Si votre liste d'attente n'apparaît pas à l'écran, merci de rafraichir la page à l'aide de la touche F5.",
            });
        } else if (error == 'CLIENT_CALLING' || error == 'CLIENT_CHATTING') {
            alert({
                message:
                    "Vous êtes déjà en communication. Si votre communication n'apparaît pas à l'écran, merci de rafraichir la page à l'aide de la touche F5.",
            });
        } else if (initData?.initializeChat?.url) {
            alert({
                title: 'Concernant votre paiement',
                message: '',
                content: <PaymentAlert />,
                onClose: () => redirect(initData?.initializeChat?.url),
            });
        } else if (initData?.initializeChat?.chat?.chatId) {
            setPack(false);
            props.startChatExpert(initData?.initializeChat.chat.chatId, warningDuration, pack);
            logEvent('chat_started', {warning: warning});
        }
    }, [initData]);

    useImperativeHandle(ref, () => ({
        startChatFromOutside,
    }));

    async function startChatFromOutside(expertId: string, warning: number) {
        const mutationData = {
            expertId: expertId,
            warning: warning,
            usePack: false,
        };

        setIsLoading(true);
        setWarning(warning);
        initializeChat({variables: mutationData});
    }

    async function startChat() {
        if (pack && solde == 0) {
            return;
        }

        const mutationData = {
            expertId: props.expert?.id,
            warning: warning,
            usePack: pack,
        };

        setIsLoading(true);
        initializeChat({variables: mutationData});
    }

    function close() {
        props.onRequestClose();
        setPack(false);
    }

    function changeCard() {
        close();
        navigate('AccountScreen');
    }

    async function goToPack() {
        close();
        await setReloadForExpert(props.expert?.userId);
        setTimeout(() => {
            if (isDesktop) {
                navigate('PackScreen');
            } else {
                navigate('Main', {
                    screen: 'Home',
                    params: {screen: 'PackScreen'},
                });
            }
        }, 1000);
    }

    const name = props.expert?.profile?.displayName;
    const message = (
        <View style={styles.container}>
            <SimpleText>
                Pour lancer votre consultation, appuyez sur « Je tchatte », puis attendez que{' '}
                {name} vous réponde !
            </SimpleText>
        </View>
    );

    const title = 'Tchatter avec ' + name;
    const price = props.expert?.chatParameters?.chatPrice?.label;
    const acceptOffer = props.expert?.chatParameters?.acceptOffer;
    const offer = getOffer(props.client, props.clientOffers, props.expert?.id);
    const clientHasOffer = offer > 0;
    const stripe = props.client?.identity?.selectedPaymentMethod;

    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <CustomModal
            title={title}
            iconName="comments"
            message={pack ? <></> : message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={close}
        >
            {pack && (
                <>
                    <View style={deviceStyles.container}>
                        <SimpleText style={styles.packLabel}>
                            Vous avez choisi la consultation avec "pack"
                        </SimpleText>
                    </View>
                    <View style={deviceStyles.container}>
                        <SimpleText style={styles.minuteLabel}>
                            ATTENTION : il vous reste {solde} minutes
                        </SimpleText>
                    </View>
                    <View style={deviceStyles.container}>
                        <Image
                            source={{uri: Settings.getUrlPictoBall()}}
                            style={{alignSelf: 'center', width: 32, height: 32}}
                            accessibilityLabel={'Pack'}
                        />
                        <SimpleText style={styles.edito}>
                            Vérifiez bien que vous avez assez de crédit pour pouvoir consulter
                            votre voyant(e)
                        </SimpleText>
                        <SimpleText style={styles.edito}>
                            La consultation sera automatiquement stoppée lorsque vous arriverez
                            au bout de votre crédit. Il serait dommage que vous soyez coupé(e)
                            en plein milieu !
                        </SimpleText>
                        <SimpleText style={styles.edito}>
                            Pensez à recharger si besoin :)
                        </SimpleText>
                        <View style={styles.buttons}>
                            <Button
                                title="Je consulte"
                                icon={<Icons.Chat size={25} />}
                                onPress={startChat}
                                titleStyle={styles.buttonCallTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.pay,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                            <Button
                                title="Je recharge"
                                icon={<Icons.Sync size={25} />}
                                onPress={goToPack}
                                titleStyle={styles.buttonCallTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.highlight,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                        </View>
                    </View>
                </>
            )}
            {!pack && (
                <>
                    <View style={styles.options}>
                        <View style={deviceStyles.option}>
                            <Button
                                title="Je Tchatte"
                                icon={<Icons.Chat size={25} />}
                                onPress={startChat}
                                titleStyle={styles.buttonCallTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.pay,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                            <SimpleText style={styles.priceLabel}>
                                Tchat avec paiement à la minute : {price} / minute
                            </SimpleText>
                            <View style={styles.offerList}>
                                {acceptOffer && clientHasOffer && (
                                    <OfferListItem
                                        text={`Offre de bienvenue : ${offer} minutes gratuites`}
                                    />
                                )}
                                <OfferListItem
                                    text={`Aucun prélèvement sur votre facture téléphonique`}
                                />
                                <OfferListItem text={`Paiement sécurisé grâce au 3D secure`} />
                                <OfferListItem
                                    text={`En dessous de 2 minutes de consultation, vous ne serez pas prélevé !`}
                                />
                                <OfferListItem
                                    text={`Grâce à la popup d’avis qui s’affiche après votre consultation, donnez nous votre impression !`}
                                />
                            </View>
                            <View style={styles.cardContainer}>
                                <SimpleText style={styles.cardLabel}>
                                    Votre moyen de paiement :
                                </SimpleText>
                                <Card stripe={stripe} onPress={() => {}} preview={true} />
                                <TouchableOpacity onPress={changeCard}>
                                    <SimpleText style={styles.changeCard}>
                                        Changer de carte
                                    </SimpleText>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.warningContainer}>
                                <SimpleText style={styles.warningLabel}>
                                    Ma limite de budget : M'avertir quand j'atteins...
                                </SimpleText>
                                <Picker
                                    selectedValue={warning}
                                    style={styles.picker}
                                    onValueChange={(itemValue, itemIndex) =>
                                        setWarning(parseInt(itemValue))
                                    }
                                >
                                    <Picker.Item label=" - " value="0" />
                                    <Picker.Item label="10 €" value="10" />
                                    <Picker.Item label="15 €" value="15" />
                                    <Picker.Item label="20 €" value="20" />
                                    <Picker.Item label="25 €" value="25" />
                                    <Picker.Item label="30 €" value="30" />
                                    <Picker.Item label="35 €" value="35" />
                                    <Picker.Item label="40 €" value="40" />
                                    <Picker.Item label="45 €" value="45" />
                                    <Picker.Item label="50 €" value="50" />
                                </Picker>
                            </View>
                        </View>
                        <View style={deviceStyles.option}>
                            <Button
                                title="J'utilise un pack"
                                icon={<Icons.Chat size={25} />}
                                onPress={() => setPack(true)}
                                titleStyle={styles.buttonPackTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.pay,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                            <SimpleText style={styles.minutes}>
                                Solde actuel : {solde} minutes
                            </SimpleText>
                            <View style={styles.offerList}>
                                <OfferListItem text={`Paiement sécurisé grâce au 3D secure`} />
                                <OfferListItem text={`Une maîtrise de votre budget`} />
                                <OfferListItem text={`Une consultation immédiate`} />
                                <OfferListItem
                                    text={`Aucune obligation d'utiliser votre crédit d'un seul coup`}
                                />
                                <OfferListItem
                                    text={`Grâce à la pop up d’avis qui s’affiche après votre consultation, donnez nous votre impression !`}
                                />
                            </View>
                        </View>
                    </View>
                </>
            )}
        </CustomModal>
    );
});

export default PopupChat;

const styles = StyleSheet.create({
    options: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    option: {
        maxWidth: 400,
        backgroundColor: 'white',
        borderRadius: 5,
        margin: 10,
        padding: 10,
    },
    container: {
        backgroundColor: 'white',
        borderRadius: 5,
        margin: 20,
        padding: 10,
    },
    buttonCallTitle: {
        fontSize: 16,
        marginHorizontal: 20,
        fontStyle: 'italic',
    },
    buttonPackTitle: {
        fontSize: 16,
        marginHorizontal: 10,
        fontStyle: 'italic',
    },
    priceLabel: {
        color: Colors.secondary,
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    offerList: {
        alignSelf: 'center',
    },
    offerListLabel: {
        fontSize: 14,
    },
    offerLabel: {
        fontSize: 14,
        alignSelf: 'center',
        marginBottom: 20,
    },
    cardContainer: {
        width: 333,
        alignSelf: 'center',
        marginTop: 30,
        marginBottom: 30,
    },
    cardLabel: {
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    changeCard: {
        fontSize: 12,
        color: Colors.link,
        alignSelf: 'center',
        textAlign: 'center',
    },
    phoneLabel: {
        alignSelf: 'center',
    },
    changeNumber: {
        fontSize: 12,
        color: Colors.link,
        alignSelf: 'flex-end',
        marginTop: 10,
    },
    warningContainer: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    warningLabel: {
        fontSize: 14,
        marginRight: 20,
    },
    picker: {},
    minutes: {
        color: Colors.secondary,
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    packLabel: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
    },
    minuteLabel: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 18,
        padding: 10,
    },
    edito: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 15,
        maxWidth: 800,
    },
    buttons: {
        marginTop: 15,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    },
});

const mobileStyles = StyleSheet.create({
    option: {
        maxWidth: 400,
        backgroundColor: Colors.dark,
        borderRadius: 5,
        margin: 10,
        padding: 10,
    },
    container: {
        backgroundColor: Colors.dark,
        borderRadius: 5,
        margin: 20,
        padding: 10,
    },
});
