import React, {useState, useImperativeHandle, useEffect, useRef} from 'react';
import {
    Modal,
    Pressable,
    StyleSheet,
    Animated,
    NativeModules,
    LayoutAnimation,
    Text,
    TouchableOpacity,
    View,
    ViewProps,
} from 'react-native';

import {gql, useQuery} from '@apollo/client';
import {useLinkTo} from '@react-navigation/native';

import * as Icons from '~/components/common/Icons';
import CustomModal, {PaymentAlert} from '~/components/common/Modal';
import {SimpleText, CenterText} from '~/components/common/Texts';
import {GetLastExpertsQuery_getLastExperts_edges_node as Expert} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import {CommunicationType} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import Settings, {endpoints, MIN_DURATION_FOR_REVIEW} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {useConfirm} from '~/contexts/ConfirmContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent, {purchase} from '~/helpers/analytics';
import {getWarningDuration} from '~/helpers/dates';
import {axios} from '~/helpers/network';
import redirect from '~/helpers/redirect';
import {
    initiateCall,
    terminateClientCall,
    checkCallClient,
    CallSubscriptionParams,
} from '~/helpers/twilio/clickToCall';
import {
    waitingList,
    exitWaitingList,
    checkWaitingListClient,
    WaitListEventData,
    closeWaitingList,
} from '~/helpers/twilio/waitingList';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {navigate} from '~/navigation';
import {ExpertsDetailsQuery_findExperts_edges_node as ExpertDetails} from '~/queries/types/ExpertsDetailsQuery';
import {GetClientOffers} from '~/queries/types/GetClientOffers';
import {QueryMe_me} from '~/queries/types/QueryMe';
import {CallStatusEnum} from '~/types/graphql-global-types';

import type {ExpertProfile_getExpert as ExpertProfile} from '../../screens/types/ExpertProfile';
import ExpertNameLink, {ExpertNameTextProps} from '../common/ExpertNameLink';
import {ProfilePicture} from '../common/Images';
import PopupCall from '../expert/PopupCall';
import PopupWriteReview from './client/review/PopupWriteReview';
import type {ReviewData} from './client/review/types/calls';
import type {CallStatusExpertQuery} from './types/CallStatusExpertQuery';

const {UIManager} = NativeModules;
UIManager.setLayoutAnimationEnabledExperimental &&
    UIManager.setLayoutAnimationEnabledExperimental(true);

const ANIMATION_DURATION = 400;
const TIMEOUT_REFETCH = 5000;

const HEIGHT = 100;
const PADDING = 20;

const CallStatus = React.forwardRef<CallStatusHandles, CallStatusProps>((props, ref) => {
    const {isDesktop} = useDeviceQuery();
    const {refetchOffers} = React.useContext(UserContext);
    const {clientId, onMessage, onCallStart, onCallComplete, ...modalProps} = props;
    const [position, setPosition] = useState<number>(0);
    const [pack, setPack] = useState<boolean>(false);
    const [warning, setWarning] = useState<number | null>(null);
    const [expertId, setExpertId] = useState<string | null | undefined>(null);
    const [callId, setCallId] = useState<string | null>(null);
    const [callStatus, setCallStatus] = useState<CallStatusEnum>(CallStatusEnum.STARTED);
    const [callDuration, setCallDuration] = useState<number | null | undefined>(null);
    const [callCost, setCallCost] = useState<number | null | undefined>(null);
    const [hasCallEnded, setHasCallEnded] = useState<boolean>(false);
    const [isReviewVisible, setIsReviewVisible] = useState(false);
    const [isPopupCallVisible, setIsPopupCallVisible] = useState(false);
    const [popupExpert, setPopupExpert] = useState<
        ExpertProfile | ExpertDetails | Expert | null | undefined
    >(null);
    const [waitClientId, setWaitClientId] = useState<string | null | undefined>(null);
    const [waitExpertId, setWaitExpertId] = useState<string | null | undefined>(null);
    const [callStartDate, setCallStartDate] = useState<Date | null>(null);
    const [callEndDate, setCallEndDate] = useState<Date | null>(null);
    const [mobileInfoVisible, setMobileInfoVisible] = useState(false);
    const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
    const [error, setError] = useState<boolean>(false);
    const confirm = useConfirm();
    const alert = useAlert();

    useEffect(() => {
        async function checkWaitingList() {
            const data = await checkWaitingListClient(CommunicationType.CALL);
            if (data.expertId != '') {
                setWaitClientId(clientId);
                setWaitExpertId(data.expertId);
                setPosition(data.position);
                waitingList({
                    clientId: clientId,
                    expertId: data.expertId,
                    onWaitListMessage: _onWaitListMessage,
                    onCloseWaitingList: _onCloseWaitingList,
                    communicationType: CommunicationType.CALL,
                    position: data.position,
                });
            }
        }
        async function checkCall() {
            const data = await checkCallClient();
            if (data.expertId != '') {
                const warningDuration = getWarningDuration(data.warning, data.price);
                reallyCallExpert(
                    clientId,
                    'User:' + data.expertId,
                    data.warning,
                    warningDuration,
                    data.usePack
                );
            }
        }

        checkWaitingList();
        checkCall();
    }, []);

    function _onCallStart() {
        setCallStartDate(new Date());
        setCallDuration(0);
        setCallCost(null);
        onCallStart && onCallStart();
    }

    function _onCallComplete(
        twilioDuration: number | undefined,
        cost: number | undefined,
        url: string | null,
        usePack: boolean | undefined
    ) {
        setCallEndDate(new Date());
        setHasCallEnded(true);
        setMobileInfoVisible(false);
        if (twilioDuration && cost) {
            setCallDuration(twilioDuration);
            setCallCost(cost);
            onCallComplete && onCallComplete(twilioDuration, cost);

            if (url) {
                alert({
                    message:
                        'Conformément à la demande de votre banque, nous allons ouvrir une page sécurisé afin de finaliser votre consultation.',
                    onClose: () => redirect(url),
                });
            }
        }
        if (url == null && twilioDuration && twilioDuration > MIN_DURATION_FOR_REVIEW) {
            setIsReviewVisible(true);
        }
        if (usePack) {
            logEvent('use_pack_minutes', {duration: twilioDuration ?? 0});
        } else {
            purchase(callId, 'call', cost);
        }
        refetchOffers && refetchOffers();
    }

    async function _onMessage(message: string) {
        const messageData: CallEventData = JSON.parse(message);
        setCallStatus(messageData.code);
        setCallId(messageData.callId);
        eventDispatch(messageData);
        onMessage && onMessage(messageData);
        if (messageData?.callDuration && messageData?.callDuration > 0) {
            var seconds = Math.floor(new Date().getTime() / 1000);
            var start = seconds - messageData.callDuration;
            setCallStartDate(new Date(start * 1000));
        }
    }

    async function _onWaitListMessage(
        message: string,
        clientId: string | null | undefined,
        expertId: string | null | undefined
    ) {
        const messageData: WaitListEventData = JSON.parse(message);
        if (messageData.code == 'UPDATE_POSITION') {
            setPosition(messageData.position);
        }
        if (messageData.code == 'EXPERT_STATUS_CHANGED') {
            await exit(clientId, expertId);
            alert({
                message:
                    'Votre spirite est maintenant indisponible. Nous sommes désolé pour la gêne occasionnée.',
            });
            logEvent('waiting_list_closed');
        } else if (messageData.code == 'END') {
            await closeWaitingList();
            setExpertId(expertId ?? waitExpertId);
            await subscribeClient(messageData.communicationId);
            logEvent('waiting_list_ended');
        }
    }

    async function exit(
        clientId: string | null | undefined = null,
        expertId: string | null | undefined = null,
        requestedByClient: boolean = false
    ) {
        await exitWaitingList(
            expertId ?? waitExpertId,
            CommunicationType.CALL,
            requestedByClient
        );
    }

    async function exitManually() {
        exit(null, null, true);
        setPosition(0);
    }

    function _onCloseWaitingList() {
        setPosition(0);
        setWaitClientId('');
        setWaitExpertId('');
    }

    async function retry() {
        try {
            const data = await checkCallClient();
            if (data.expertId != '') {
                const warningDuration = getWarningDuration(data.warning, data.price);
                reallyCallExpert(
                    clientId,
                    'User:' + data.expertId,
                    data.warning,
                    warningDuration,
                    data.usePack
                );
            }
            setError(false);
        } catch (e) {
            setTimeout(retry, TIMEOUT_REFETCH);
        }
    }

    async function onError() {
        if (!error) {
            setError(true);
            setTimeout(retry, TIMEOUT_REFETCH);
            webSocket?.close();
        }
    }

    async function subscribeClient(callSid: string) {
        const uri = Settings.userCallSocket('client', callSid);
        const ws = new WebSocket(uri);
        setWebSocket(ws);

        ws.onmessage = async function (event) {
            const message = event.data;
            _onMessage(message);
        };
        ws.onclose = function (event) {
            onError();
        };
        ws.onerror = function (event) {
            onError();
        };
    }

    function eventDispatch(data: CallEventData) {
        if (!callId) {
            setCallId(data.callId);
        }
        switch (data.code) {
            case CallStatusEnum.BOTH_PARTIES_JOINED:
                _onCallStart();
                break;
            case CallStatusEnum.FINISHED:
                _onCallComplete(data.callDuration, data.callCost, data.url, data.usePack);
                break;
            case CallStatusEnum.ERROR:
                _onCallComplete(0, 0, '');
                break;
            default:
                break;
        }
    }

    /** Handles can be accessed by using a ref to the instance (useRef), then
     * simply using ref.callExpert(expertId) or ref.endCall() to respectively
     * initiate and terminate a call */
    useImperativeHandle(ref, () => ({
        joinWaitingList,
        callExpert,
        endCall,
        startCallFromOutside,
        reallyCallExpert,
    }));

    async function joinWaitingList(
        clientId: string | null | undefined,
        expertId: string | null | undefined
    ) {
        var position = await waitingList({
            clientId: clientId,
            expertId: expertId,
            onWaitListMessage: _onWaitListMessage,
            onCloseWaitingList: _onCloseWaitingList,
            communicationType: CommunicationType.CALL,
        });
        if (position != null) {
            setPosition(position);
            setWaitClientId(clientId);
            setWaitExpertId(expertId);
        }
        logEvent('waiting_list_joined', {type: 'call'});
    }

    async function callExpert(expert: ExpertProfile | Expert | null | undefined) {
        setPopupExpert(expert);
        setIsPopupCallVisible(true);
    }

    async function reallyCallExpert(
        clientId: string | null | undefined,
        expertId: string | null | undefined,
        warning: number | null,
        warningDuration: number | null,
        usePack: boolean,
        freeCall?: boolean,
        withoutCard?: boolean,
        paymentIntent?: string,
        onCancel?: () => void
    ) {
        var data = await initiateCall(
            expertId,
            clientId,
            warning,
            usePack,
            freeCall,
            withoutCard,
            paymentIntent
        );

        setIsPopupCallVisible(false);
        setPopupExpert(null);

        if (data.error == 'FREE_CALL_WITHOUT_OFFER') {
            alert({
                title: 'Aucune offre disponible pour ce spirite',
                message:
                    "Soit le spirite n'accepte pas les offres promotionnelles, soit vous n'avez plus d'offre disponible pour ce spirite. Vous allez être redirigé vers l'accueil de Kyvoitou.",
                onClose: () => redirect('/home'),
            });
        }

        if (data.error == 'NO_PAYMENT_METHOD_ATTACHED') {
            confirm({
                title: 'Aucun moyen de paiement ajouté',
                message:
                    "Pour pouvoir utiliser nos services vous devez d'abord ajouter un moyen de paiement à votre compte. Voulez vous ajouter un moyen de paiement dès maintenant ?",
                onYes: () => navigate('AccountScreen', {event: 'add-payment-method'}),
                noText: 'Non merci',
            });
        }
        if (data.error == 'NOT_ENOUGH_MINUTE') {
            confirm({
                title: 'Solde épuisé',
                message:
                    'Pour pouvoir utiliser nos services vous devez recharger votre solde de minutes. Voulez vous recharger dès maintenant ?',
                onYes: () => {
                    if (isDesktop) {
                        navigate('PackScreen');
                    } else {
                        navigate('Main', {
                            screen: 'Home',
                            params: {screen: 'PackScreen'},
                        });
                    }
                },
                noText: 'Non merci',
            });
        }
        if (data.error == 'NOT_ENOUGH_FUNDS') {
            confirm({
                title: 'Fonds insuffisants',
                message:
                    'Vous ne disposez pas de fonds suffisant avec votre moyen de paiement actuel. Vous pouvez vous rendre sur votre profil pour vérifier quel moyen de paiement est utilisé.',
                onYes: () => navigate('AccountScreen'),
                yesText: 'Y aller',
                noText: 'Non merci',
            });
        }
        if (data.error == 'HOLD_ERROR') {
            alert({
                title: 'Erreur d’autorisation',
                message:
                    'Une erreur inattendue est survenue lors de la réservation de paiement effectué sur votre carte. Veuillez ré-essayer plus tard, contactez le support si le problème persiste.',
            });
        }
        if (
            data.error == 'EXPERT_PAUSE' ||
            data.error == 'EXPERT_CALLING' ||
            data.error == 'QUEUE_NOT_EMPTY' ||
            data.error == 'EXPERT_CHATTING'
        ) {
            setWarning(warningDuration);
            confirm({
                title: 'Information',
                message:
                    "Votre spirite est déjà en communication. Vous pouvez rejoindre la liste d'attente.",
                yesText: 'Rejoindre',
                noText: 'Annuler',
                onYes: () => {
                    joinWaitingList(clientId, expertId);
                    setIsPopupCallVisible(false);
                },
                onNo: () => {
                    axios.post(endpoints.cancelCallIntent);
                    onCancel?.();
                },
            });
        } else if (data.error == 'EXPERT_UNAVAILABLE') {
            alert({
                message: "Votre spirite n'est pas disponible.",
            });
        } else if (data.error == 'CLIENT_ALREADY_IN_QUEUE') {
            alert({
                message:
                    "Vous êtes déjà dans une liste d'attente. Si votre liste d'attente n'apparaît pas à l'écran, merci de rafraichir la page à l'aide de la touche F5.",
            });
        } else if (data.error == 'CLIENT_CALLING' || data.error == 'CLIENT_CHATTING') {
            alert({
                message:
                    "Vous êtes déjà en communication. Si votre communication n'apparaît pas à l'écran, merci de rafraichir la page à l'aide de la touche F5.",
            });
        } else if (data.url != null) {
            alert({
                title: 'Concernant votre paiement',
                message: '',
                content: <PaymentAlert />,
                onClose: () => redirect(data.url),
            });
        } else if (data.callSid != null) {
            setExpertId(expertId);
            setWarning(warningDuration);
            setPack(usePack);
            await subscribeClient(data.callSid);
        }
    }

    async function endCall() {
        const result = await terminateClientCall(callId);
        if (result && result.code && result.code != callStatus) {
            _onMessage(JSON.stringify(result));
        } else {
            setExpertId(null);
            setCallDuration(null);
            setCallCost(null);
            setHasCallEnded(false);
            setCallStartDate(null);
            modalProps.onRequestClose && modalProps.onRequestClose();
            refetchOffers && refetchOffers();
            logEvent('call_stopped');
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!hasCallEnded && callStartDate) {
                const now = new Date();
                const duration = Math.round((now.getTime() - callStartDate.getTime()) / 1000);
                setCallDuration(duration);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [hasCallEnded, callStartDate]);

    const reviewData: ReviewData = {
        expertId,
        date: callEndDate,
        communicationId: 'Call:' + callId,
        communicationType: CommunicationType.CALL,
        clientMode: true,
    };

    const _popupCall = useRef<CallStatusHandles>();

    async function startCallFromOutside(warning: number) {
        _popupCall?.current?.startCallFromOutside(warning);
    }

    return (
        <>
            <PopupWriteReview
                visible={isReviewVisible}
                onRequestClose={() => setIsReviewVisible(false)}
                reviewData={reviewData}
                onSuccess={() => setIsReviewVisible(false)}
            />
            <PopupCall
                visible={isPopupCallVisible}
                onRequestClose={() => {
                    setIsPopupCallVisible(!isPopupCallVisible);
                }}
                call={reallyCallExpert}
                expert={popupExpert}
                ref={_popupCall}
            />
            <Call
                position={position}
                waitExpertId={waitExpertId}
                expertId={expertId}
                callCost={callCost}
                warning={warning}
                pack={pack}
                callDuration={callDuration}
                callStatus={callStatus}
                endCall={endCall}
                mobileInfoVisible={mobileInfoVisible}
                setMobileInfoVisible={setMobileInfoVisible}
                exit={exitManually}
            />
        </>
    );
});

type CallProps = {
    position: number;
    waitExpertId: string | null | undefined;
    expertId: string | null | undefined;
    callCost: number | null | undefined;
    warning: number | null;
    pack: boolean;
    callDuration: number | null | undefined;
    callStatus: CallStatusEnum;
    endCall: () => void;
    setMobileInfoVisible: (mobileInfoVisible: boolean) => void;
    mobileInfoVisible: boolean;
    exit: () => void;
};

function Call(props: CallProps) {
    const {isDesktop} = useDeviceQuery();
    const height = useRef(new Animated.Value(0)).current;
    const visible = props.expertId != null || props.position > 0;

    useEffect(() => {
        LayoutAnimation.spring();
        if (visible) {
            Animated.timing(height, {
                toValue: 1,
                duration: ANIMATION_DURATION,
                useNativeDriver: true,
            }).start();
        } else {
            Animated.timing(height, {
                toValue: 0,
                duration: ANIMATION_DURATION,
                useNativeDriver: true,
            }).start();
        }
    }, [visible]);

    const statusProps = callStatusProps(props.callStatus, props.position);
    const fadingOut = props.expertId == null && props.position == 0;
    const statusStyle = {
        backgroundColor: fadingOut ? Colors.disabled : statusProps.color,
        maxHeight: height.interpolate({inputRange: [0, 1], outputRange: [0, HEIGHT]}),
        padding: height.interpolate({inputRange: [0, 1], outputRange: [0, PADDING]}),
    };

    if (isDesktop) {
        return (
            <Animated.View style={[styles.statusView, statusStyle]}>
                <>
                    {props.position > 0 && (
                        <>
                            <CenterText style={{color: 'white'}}>
                                <Icons.Phone size={30} style={{marginRight: 30}} />
                                <Text>{statusProps.text} : </Text>
                                <ExpertInfoText expertId={props.waitExpertId} />
                                <WaitingPosition position={props.position} separator={true} />
                            </CenterText>
                            <WaitActions
                                onExit={props.exit}
                                style={{marginLeft: 'auto'}}
                                text={'Quitter la file'}
                                color={'black'}
                            />
                        </>
                    )}
                    {props.position == 0 && props.expertId != null && (
                        <>
                            <CenterText style={{color: 'white'}}>
                                <Icons.Phone size={30} style={{marginRight: 30}} />
                                <Text>{statusProps.text} : </Text>
                                <ExpertInfoText expertId={props.expertId} />
                                <CallDurationText
                                    callDuration={props.callDuration}
                                    separator={true}
                                />
                                <CallCostText pack={props.pack} callCost={props.callCost} />
                                <MinuteText
                                    pack={props.pack}
                                    duration={props.callDuration}
                                    status={props.callStatus}
                                />
                            </CenterText>
                            <Warning
                                warning={props.warning}
                                duration={props.callDuration}
                                status={props.callStatus}
                            />
                            <HangupButton
                                onClose={props.endCall}
                                style={{marginLeft: 'auto'}}
                                color={'black'}
                                text={"Terminer l'appel"}
                            />
                        </>
                    )}
                    {fadingOut && <SimpleText>Fermeture...</SimpleText>}
                </>
            </Animated.View>
        );
    } else if (!fadingOut) {
        return (
            <View style={[mobileStyles.containerStyle, {backgroundColor: statusProps.color}]}>
                {props.position > 0 && (
                    <>
                        <WaitingPosition position={props.position} separator={false} />
                        <WaitActions
                            onExit={props.exit}
                            style={{marginLeft: 'auto'}}
                            text={'Quitter'}
                            color={'white'}
                        />
                    </>
                )}
                {props.position == 0 && props.expertId != null && (
                    <>
                        {props.callStatus == CallStatusEnum.STARTED && (
                            <SimpleText style={{color: 'white'}}>Initialisation...</SimpleText>
                        )}
                        <TouchableOpacity
                            onPress={() =>
                                props.setMobileInfoVisible(!props.mobileInfoVisible)
                            }
                            style={{flexDirection: 'row'}}
                        >
                            {props.callStatus == CallStatusEnum.BOTH_PARTIES_JOINED && (
                                <Icons.SortDown
                                    size={14}
                                    color={'white'}
                                    style={{marginRight: 5}}
                                />
                            )}
                            <CallTextMobile
                                pack={props.pack}
                                cost={props.callCost}
                                duration={props.callDuration}
                                status={props.callStatus}
                            />
                        </TouchableOpacity>
                        <HangupButton
                            onClose={props.endCall}
                            style={{marginLeft: 'auto'}}
                            color={'white'}
                            text={'Terminer'}
                        />
                        <CustomModal
                            title={'Information sur votre appel'}
                            visible={props.mobileInfoVisible}
                            onRequestClose={() => {
                                props.setMobileInfoVisible(!props.mobileInfoVisible);
                            }}
                        >
                            <ExpertInfoMobile expertId={props.expertId} />
                        </CustomModal>
                    </>
                )}
            </View>
        );
    } else {
        return <></>;
    }
}

function callStatusProps(callStatus: CallStatusEnum, position: number) {
    if (position > 0) {
        return {color: Colors.pause, text: "En liste d'attente pour communiquer avec"};
    }

    switch (callStatus) {
        case CallStatusEnum.STARTED:
            return {
                color: Colors.light,
                text: "Initialisation de l'appel avec",
            };
        case CallStatusEnum.EXPERT_JOINED:
            return {
                color: Colors.light,
                text: "Initialisation de l'appel avec",
            };
        case CallStatusEnum.BOTH_PARTIES_JOINED:
            return {
                color: Colors.connected,
                text: 'En communication avec',
            };
        case CallStatusEnum.FINISHED:
            return {
                color: Colors.highlight,
                text: 'Fin de communication avec',
            };
        case CallStatusEnum.ERROR:
            return {
                color: Colors.error,
                text: 'Appel terminé en erreur avec',
            };
        case CallStatusEnum.CANCELED:
            return {
                color: Colors.error,
                text: 'Appel terminé en erreur avec',
            };
        default:
            return {
                color: Colors.light,
                text: "Status de l'appel inconnu",
            };
    }
}

function ExpertInfoText({
    expertId,
    style,
    ...otherProps
}: {expertId: string | null | undefined} & ExpertNameTextProps) {
    const variables = {expertId};
    const {data, loading, error} = useQuery<CallStatusExpertQuery>(CALL_EXPERT_QUERY, {
        variables,
        skip: expertId == null,
    });
    if (loading || error || !expertId) {
        return (
            <SimpleText style={[styles.expertLink, style]} {...otherProps}>
                {' '}
                --{' '}
            </SimpleText>
        );
    }
    const expert = data?.user?.profile;
    const displayName = expert?.displayName;
    return (
        <ExpertNameLink
            style={[styles.expertLink, style]}
            expertId={expertId}
            expertName={displayName}
            {...otherProps}
        />
    );
}

function ExpertInfoMobile({expertId}: {expertId: string | null | undefined}) {
    const variables = {expertId};
    const {data, loading, error} = useQuery<CallStatusExpertQuery>(CALL_EXPERT_QUERY, {
        variables,
        skip: expertId == null,
    });
    if (loading || error || !expertId) {
        return <></>;
    }
    const expert = data?.user?.profile;
    const displayName = expert?.displayName;
    return (
        <View>
            <SimpleText
                style={{alignSelf: 'center', textAlign: 'center', margin: 20, fontSize: 24}}
            >
                Actuellement en communication avec {displayName}
            </SimpleText>
            <ProfilePicture
                style={mobileStyles.profilePicture}
                pictureName={expert?.pictureName}
                displayName={displayName}
            />
        </View>
    );
}

function CallDurationText({callDuration, separator}: CallDurationTextProps) {
    if (!callDuration || callDuration < 0) return <></>;

    const seconds = callDuration % 60;
    const minutes = Math.floor(callDuration / 60) % 60;
    const hours = Math.floor(callDuration / 3600);

    return (
        <SimpleText style={styles.statusText}>
            {separator && <Text style={{marginHorizontal: 30}}>|</Text>}
            Durée de l'appel : {hours}h{minutes}m{seconds}s
        </SimpleText>
    );
}

function CallCostText({callCost, pack}: CallCostTextProps) {
    if (pack) return <></>;
    if (!callCost) return <></>;

    // Ugly but true : this var should already be a number but isn't
    callCost = parseFloat(callCost.toString());

    return (
        <SimpleText style={styles.statusText}>
            <Text style={{marginHorizontal: 30}}>|</Text> Coût de cet appel :{' '}
            {callCost.toFixed(2)}€
        </SimpleText>
    );
}

type CallTextMobileProps = {
    pack: boolean;
    cost: number | null | undefined;
    duration: number | null | undefined;
    status: CallStatusEnum;
};

function CallTextMobile(props: CallTextMobileProps) {
    const {minutes, refetchMinutes} = React.useContext(UserContext);

    useEffect(() => {
        if (props.status == CallStatusEnum.FINISHED) {
            refetchMinutes();
        }
    }, [props.status]);

    const pack = props.pack;
    const duration = props.duration;
    const cost = props.cost;
    const solde = minutes?.getMinuteBalance?.minutes ?? 0;

    if (pack) {
        return <SimpleText style={styles.statusText}>Solde restant : {solde} min.</SimpleText>;
    }

    if (!duration || duration < 0) return <></>;

    const seconds = duration % 60;
    const min = Math.floor(duration / 60) % 60;
    const hours = Math.floor(duration / 3600);

    if (!cost) {
        return (
            <SimpleText style={styles.statusText}>
                Durée de l'appel : {hours}h{min}m{seconds}s
            </SimpleText>
        );
    } else {
        // Ugly but true : this var should already be a number but isn't
        const c = parseFloat(cost.toString());

        return (
            <SimpleText style={styles.statusText}>
                Coût : {c.toFixed(2)}€ ({hours}h{min}m{seconds}s)
            </SimpleText>
        );
    }
}

type CallCostTextProps = {
    pack: boolean;
    callCost: number | null | undefined;
};

type MinuteTextProps = {
    pack: boolean;
    duration: number | null | undefined;
    status: CallStatusEnum;
};

function MinuteText(props: MinuteTextProps) {
    const {minutes, refetchMinutes} = React.useContext(UserContext);

    const solde = minutes?.getMinuteBalance?.minutes ?? 0;

    useEffect(() => {
        if (props.status == CallStatusEnum.FINISHED) {
            refetchMinutes();
        }
    }, [props.status]);

    if (props.pack && props.duration && props.status == CallStatusEnum.FINISHED) {
        return (
            <SimpleText style={styles.statusText}>
                <Text style={{marginHorizontal: 30}}>|</Text> Solde restant : {solde} min.
            </SimpleText>
        );
    } else {
        return <></>;
    }
}

type WarningProps = {
    warning: number | null;
    duration: number | null | undefined;
    status: CallStatusEnum;
};

function Warning(props: WarningProps) {
    const {play} = useAudio();

    useEffect(() => {
        async function playSound() {
            await play(require('../../assets/sound/bell.mp3'));
        }

        if (props.duration && props.duration == props.warning && props.warning > 0) {
            playSound();
        }
    }, [props.duration]);

    if (props.warning && props.duration && props.status != CallStatusEnum.FINISHED) {
        const limitReached = props.duration >= props.warning;

        if (limitReached) {
            return (
                <View style={{marginLeft: 'auto'}}>
                    <SimpleText style={styles.warning}>Limite atteinte</SimpleText>
                </View>
            );
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}

function WaitingPosition({position, separator}: {position: number; separator: boolean}) {
    return (
        <SimpleText style={styles.statusText}>
            {separator && <Text style={{marginHorizontal: 50}}>|</Text>}
            Position dans la file d'attente : {position}
        </SimpleText>
    );
}

function WaitActions({onExit, text, color, ...viewProps}: WaitActionsProps & ViewProps) {
    return (
        <View {...viewProps}>
            <Pressable onPress={onExit}>
                <SimpleText style={{textDecorationLine: 'underline', color: color}}>
                    {text}
                </SimpleText>
            </Pressable>
        </View>
    );
}

type HangupButtonProps = {
    color: string;
    text: string;
};

function HangupButton({
    onClose,
    color,
    text,
    ...viewProps
}: ActionsProps & ViewProps & HangupButtonProps) {
    return (
        <View {...viewProps}>
            <Pressable onPress={onClose}>
                <SimpleText style={{textDecorationLine: 'underline', color: color}}>
                    {text}
                </SimpleText>
            </Pressable>
        </View>
    );
}

const CALL_EXPERT_QUERY = gql`
    query CallStatusExpertQuery($expertId: ID!) {
        user(id: $expertId) {
            id
            profile {
                id
                displayName
                pictureName
            }
        }
    }
`;

type WaitActionsProps = {
    color: string;
    text: string;
    onExit?: () => void;
};

type ActionsProps = {
    onClose?: () => void;
};

export type CallEventData = {
    callSid: string;
    callId: string;
    code: CallStatusEnum;
    message: string;
    callDuration?: number;
    callCost: number;
    usePack: boolean;
    url: string | null;
};

export type onMessageArguments = (data: CallEventData) => void;

export type CallStatusProps = Modal['props'] & {
    clientId: string;
    onMessage?: onMessageArguments;
    onCallStart?: () => void;
    onCallComplete?: (
        callDuration: number | null | undefined,
        callCost: number | null | undefined
    ) => void;
};

export type CallStatusHandles = {
    joinWaitingList: (
        clientId: string | null | undefined,
        expertId: string | null | undefined
    ) => void;
    callExpert: (expert: ExpertProfile | ExpertDetails | Expert | null | undefined) => void;
    endCall?: () => void;
    newCall?: (data: CallSubscriptionParams) => void;
    startCallFromOutside: (warning: number) => void;
    reallyCallExpert: (
        clientId: string | null | undefined,
        expertId: string | null | undefined,
        warning: number | null,
        warningDuration: number | null,
        usePack: boolean,
        freeCall?: boolean,
        withoutCard?: boolean,
        paymentIntent?: string,
        onCancel?: () => void
    ) => Promise<void>;
};

type CallDurationTextProps = {
    callDuration: number | null | undefined;
    separator: boolean;
};

const styles = StyleSheet.create({
    statusText: {
        color: 'white',
        fontWeight: '500',
    },
    statusView: {
        alignSelf: 'center',
        width: '100%',
        backgroundColor: Colors.primary,
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        alignItems: 'center',
        paddingHorizontal: 20,
    },
    statusContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    buttonContainer: {
        flex: 1,
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    buttonValidate: {
        marginRight: 10,
    },
    buttonCancel: {
        marginLeft: 10,
    },
    expertLink: {
        fontWeight: 'normal',
    },
    textContainer: {
        flex: 1,
        flexDirection: 'row',
        padding: 10,
    },
    exitContainer: {
        flex: 1,
        flexDirection: 'row-reverse',
    },
    exitButton: {
        marginRight: 10,
        marginLeft: 10,
    },
    warning: {
        color: 'white',
        backgroundColor: Colors.highlight,
        borderRadius: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontSize: 14,
        height: 27,
        marginTop: 3,
        marginRight: 25,
    },
});

const mobileStyles = StyleSheet.create({
    containerStyle: {
        position: 'absolute',
        top: 75,
        flexDirection: 'row',
        left: 0,
        right: 0,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    profilePicture: {
        borderRadius: 10,
        width: 150,
        height: 150,
        margin: 30,
        alignSelf: 'center',
    },
});

export default CallStatus;
