import React, {useRef} from 'react';
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';
import Carousel, {ICarouselInstance} from 'react-native-reanimated-carousel';
import {number} from 'yup';

import * as Icons from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {groupReviews} from '~/helpers/reviews';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {HomeReviewsQuery_getHomeReviews_edges_node} from '~/queries/types/HomeReviewsQuery';
import useHomeReviews from '~/queries/useHomeReviews';

import {GradeResume} from '../common/GradeItem';
import {ProfilePicture} from '../common/Images';
import {SimpleText} from '../common/Texts';

export function ReviewCarousel() {
    const {data} = useHomeReviews();
    const carousel = useRef<ICarouselInstance | null>(null);
    const {isDesktop} = useDeviceQuery();

    const reviews = groupReviews(data, isDesktop);

    function previous() {
        carousel?.current?.prev();
    }

    function next() {
        carousel?.current?.next();
    }

    if (reviews.length == 0) {
        return <></>;
    }

    const width = isDesktop ? 1080 : 300;
    const margin = isDesktop ? 50 : 5;

    return (
        <View style={styles.container}>
            {/* <Image
                source={{uri: Settings.getUrlPictoReviews()}}
                style={{
                    alignSelf: 'center',
                    width: 150,
                    height: 150,
                    marginTop: 50,
                    marginBottom: 25,
                }}
                accessibilityLabel={'Vos avis'}
            />
            <SimpleText style={styles.text}>
                Tous vos avis nous intéressent que cela soit pour nous faire grandir ou
                partager votre joie !
            </SimpleText> */}
            <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={Colors.pastel}>
                <View style={styles.carousel}>
                    <TouchableOpacity onPress={previous} style={styles.icon}>
                        <Icons.Return
                            size={40}
                            color={Colors.secondary}
                            style={{
                                marginRight: margin,
                                marginLeft: 5,
                            }}
                        />
                    </TouchableOpacity>
                    <Carousel
                        width={width}
                        height={300}
                        data={reviews}
                        renderItem={({item}) => <Review item={item} />}
                        ref={carousel}
                    />
                    <TouchableOpacity onPress={next} style={styles.icon}>
                        <Icons.Next
                            size={40}
                            color={Colors.secondary}
                            style={{
                                marginLeft: margin,
                                marginRight: 5,
                            }}
                        />
                    </TouchableOpacity>
                </View>
            </LinearGradient>
        </View>
    );
}

type ReviewProps = {
    item: HomeReviewsQuery_getHomeReviews_edges_node[];
};

function Review(props: ReviewProps) {
    const key = props.item.length > 0 ? props.item[0].id : '';
    return (
        <View style={styles.elements} key={key}>
            {props.item.map((i) => (
                <Element item={i} key={i.id} />
            ))}
        </View>
    );
}

type ElementProps = {
    item: HomeReviewsQuery_getHomeReviews_edges_node;
};

const MAX_COMMENT_LENGTH = 100;

function Element(props: ElementProps) {
    const navigation = useNavigation();

    function onPressProfile() {
        navigation.navigate('ExpertScreen', {url: props.item.expert?.expert?.url});
    }

    const date = new Date(props.item.createdDate);
    const comment =
        props.item.comment.length > MAX_COMMENT_LENGTH
            ? props.item.comment.substring(0, MAX_COMMENT_LENGTH).trim() + '...'
            : props.item.comment;

    return (
        <View style={styles.review} key={props.item.id}>
            <View style={styles.content}>
                <ProfilePicture
                    style={styles.profilePicture}
                    pictureName={props.item.expert?.profile?.pictureName}
                    displayName={props.item.expert?.profile?.displayName}
                />
                <GradeResume
                    style={styles.grade}
                    text={props.item?.grade?.label ?? ''}
                    grade={parseInt(props.item?.grade?.gradeId ?? '0')}
                />
                <SimpleText style={styles.client}>
                    {props.item.client?.profile?.displayName}
                </SimpleText>
                <SimpleText style={styles.phrase}>
                    a consulté{' '}
                    <TouchableOpacity onPress={onPressProfile}>
                        <SimpleText style={styles.expert}>
                            {props.item.expert?.profile?.displayName}
                        </SimpleText>
                    </TouchableOpacity>
                </SimpleText>
                <SimpleText style={styles.date}>le {date.toLocaleDateString()}</SimpleText>
                <SimpleText style={styles.comment}>"{comment}"</SimpleText>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {},
    text: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
        marginLeft: 15,
        marginRight: 15,
    },
    carousel: {
        marginTop: 25,
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    review: {
        height: 300,
        width: 300,
        borderRadius: 15,
    },
    grade: {
        alignSelf: 'center',
    },
    icon: {
        alignSelf: 'center',
    },
    elements: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    content: {
        flex: 1,
    },
    profilePicture: {
        borderRadius: 50,
        width: 75,
        height: 75,
        marginBottom: 10,
        alignSelf: 'center',
        textAlign: 'center',
    },
    phrase: {
        fontSize: 14,
        textAlign: 'center',
    },
    client: {
        marginTop: 10,
        fontSize: 16,
        textAlign: 'center',
    },
    expert: {
        fontSize: 14,
        color: Colors.link,
        textAlign: 'center',
    },
    date: {
        fontSize: 12,
        textAlign: 'center',
    },
    comment: {
        height: 118,
        fontSize: 16,
        marginTop: 15,
        fontStyle: 'italic',
        textAlign: 'center',
    },
});
