import {range} from '~/helpers/iterations';

export const APP_RELEASE_YEAR = 2021;
export const CURRENT_YEAR = new Date().getFullYear();
export const APP_ACTIVE_YEARS = range(1 + CURRENT_YEAR - APP_RELEASE_YEAR, APP_RELEASE_YEAR);

export const MONTHS_NAMES = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];
