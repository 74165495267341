import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import ExpertCard from '~/components/expert/ExpertCard';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import {filterIncompleteProfile, Expert} from '~/helpers/experts';
import {getOffer} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import useHomeExperts from '~/queries/useHomeExperts';

export default function HomeExperts() {
    const {isDesktop} = useDeviceQuery();
    const {me, offers} = React.useContext(UserContext);

    const navigation = useNavigation();
    const {loading, error, data, refetch} = useHomeExperts();

    function goToSearch() {
        if (isDesktop) {
            navigation.navigate('SearchScreen', {tag: ''});
        } else {
            navigation.navigate('Main', {
                screen: 'Search',
                params: {screen: 'SearchScreen'},
            });
        }
    }

    const renderExpertCard = (expert: Expert) => {
        function onPressProfile() {
            navigation.navigate('Home', {
                screen: 'HomeExpertScreen',
                params: {url: expert?.expert?.url},
            });
        }

        return (
            <ExpertCard
                user={expert}
                offer={getOffer(me, offers, expert.id)}
                onPressProfile={onPressProfile}
                key={expert?.id}
                style={{display: 'flex'}}
            />
        );
    };

    const allExperts = data?.homeExperts?.edges.map((e) => e?.node?.expert);
    const experts = filterIncompleteProfile(allExperts);

    const desktopStyle = {
        paddingBottom: 25,
    } as ViewStyle;

    const mobileStyle = {
        backgroundColor: Colors.dark,
        paddingBottom: 10,
    } as ViewStyle;

    const style = isDesktop ? desktopStyle : mobileStyle;
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const marginBottom = isDesktop ? 0 : 15;
    const fontSize = isDesktop ? 24 : 18;

    return (
        <View style={style}>
            <Button
                title="Je découvre les voyants"
                buttonStyle={{alignSelf: 'center'}}
                titleStyle={{paddingHorizontal: 30, fontSize: fontSize}}
                style={{marginTop: 50, marginBottom: marginBottom}}
                onPress={goToSearch}
                icon={<Icons.Next size={40} />}
                iconRight
            />
            <HomeExpertTitle />
            <SimpleText style={deviceStyles.note}>
                * Pour les profils en consultation, une liste d'attente avec rappel automatique
                sur votre tel vous sera proposée.
            </SimpleText>
            {!loading && experts != null && experts.length >= 1 && (
                <View style={styles.container}>{experts.map(renderExpertCard)}</View>
            )}
        </View>
    );
}

function HomeExpertTitle() {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <>
            <SimpleText style={deviceStyles.title}>TOP 3 Des Spirites Kyvoitou.fr</SimpleText>
        </>
    );
}

const styles = StyleSheet.create({
    title: {
        paddingTop: 50,
        fontSize: 38,
        alignSelf: 'center',
        fontWeight: 'bold',
        color: Colors.secondary,
    },
    container: {
        justifyContent: 'space-around',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    note: {
        fontSize: 12,
        fontStyle: 'italic',
        textAlign: 'center',
        marginTop: 10,
    },
});

const mobileStyles = StyleSheet.create({
    title: {
        paddingTop: 50,
        fontSize: 28,
        fontWeight: 'bold',
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    note: {
        fontSize: 12,
        fontStyle: 'italic',
        textAlign: 'center',
        marginTop: 10,
    },
});
