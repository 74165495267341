import {createContext, useCallback, useContext, useEffect} from 'react';

import {useLandingPageNavigation, useLandingPageRoute} from '../route';

export enum Step {
    PICK_EXPERT_STEP_ID = 'choix-expert',
    REGISTER_STEP_ID = 'connexion',
    ADD_PAYMENT_STEP_ID = 'paiement',
    ONGOING_CALL_STEP = 'appel',
    PACK_SELECTION_STEP = 'choix-pack',
}

type UrlStepContextValue = {
    step: Step;
    setStep: (step: string) => void;
    showExpertList: () => void;
    showAuthForm: () => void;
    showPaymentCTA: () => void;
    showCallStatus: () => void;
    showPacks: () => void;
    isExpertListStep: boolean;
    isAuthStep: boolean;
    isPaymentStep: boolean;
    isOngoingCallStep: boolean;
    isPackSelectionStep: boolean;
};

export const UrlStepContext = createContext<UrlStepContextValue | undefined>(undefined);

export function useUrlStepContext() {
    const context = useContext(UrlStepContext);
    if (context == null) {
        throw new Error("URL step context wasn't setup properly (missing UrlStepProvider).");
    }
    return context;
}

export function UrlStepProvider(props: React.PropsWithChildren<{initialStep?: Step}>) {
    const route = useLandingPageRoute();
    const navigation = useLandingPageNavigation();

    let step = props.initialStep ?? Step.PICK_EXPERT_STEP_ID;
    if (route.params?.step != null) {
        step = route.params.step as Step;
    }

    const setStep = useCallback((step: string) => {
        navigation.setParams({
            step,
        });
    }, []);

    useEffect(() => {
        if (route.params?.step == null) {
            setStep(step);
        }
    }, []);

    const showExpertList = useCallback(() => setStep(Step.PICK_EXPERT_STEP_ID), []);
    const showAuthForm = useCallback(() => setStep(Step.REGISTER_STEP_ID), []);
    const showPaymentCTA = useCallback(() => setStep(Step.ADD_PAYMENT_STEP_ID), []);
    const showCallStatus = useCallback(() => setStep(Step.ONGOING_CALL_STEP), []);
    const showPacks = useCallback(() => setStep(Step.PACK_SELECTION_STEP), []);

    const value = {
        step,
        setStep,
        showExpertList,
        showAuthForm,
        showPaymentCTA,
        showCallStatus,
        showPacks,
        isExpertListStep: step == Step.PICK_EXPERT_STEP_ID,
        isAuthStep: step == Step.REGISTER_STEP_ID,
        isPaymentStep: step == Step.ADD_PAYMENT_STEP_ID,
        isOngoingCallStep: step == Step.ONGOING_CALL_STEP,
        isPackSelectionStep: step == Step.PACK_SELECTION_STEP,
    };
    return <UrlStepContext.Provider value={value}>{props.children}</UrlStepContext.Provider>;
}
