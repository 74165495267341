import React, {useState} from 'react';
import {StyleSheet, View, Image} from 'react-native';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {Camille, Jusine} from '~/components/common/Images';
import {SimpleText, MobileTitle} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import PopupSupport from '~/components/user/client/dashboard/PopupSupport';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function ContactScreen() {
    const alert = useAlert();
    const [modalSupportVisible, setModalSupportVisible] = useState(false);
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles : mobileStyles;

    function openSupport() {
        setModalSupportVisible(true);
    }

    function onSuccessSupport() {
        setModalSupportVisible(false);
        alert({
            message: 'Votre message a bien été envoyé',
            width: 400,
        });
    }

    function onRequestCloseSupport() {
        setModalSupportVisible(false);
    }

    return (
        <MainView>
            <ContactTitle />

            <View style={styles.container}>
                <SimpleText style={styles.brand}>KYVOITOU</SimpleText>
                <SimpleText style={styles.info}>{Settings.contact_mail}</SimpleText>
                <SimpleText style={styles.info}>{Settings.support_telephone}</SimpleText>
                <SimpleText style={styles.info}>ou via notre formulaire</SimpleText>
            </View>

            <View style={deviceStyle.images}>
                <Camille />
                <Jusine />
            </View>

            <Button
                onPress={openSupport}
                style={styles.button}
                title="Écrire au support"
                titleStyle={styles.title}
                icon={<Icons.MailSend size={15} color={'white'} />}
            ></Button>

            <PopupSupport
                onSuccess={onSuccessSupport}
                visible={modalSupportVisible}
                onRequestClose={onRequestCloseSupport}
            />
        </MainView>
    );
}

function ContactTitle() {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return <PageTitle>Contactez-nous</PageTitle>;
    } else {
        return <MobileTitle title={'Contactez-nous'} separator={false} />;
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        textAlign: 'center',
    },
    brand: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    info: {
        fontSize: 16,
        paddingTop: 5,
    },
    images: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        color: 'black',
        fontSize: 14,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    title: {
        marginHorizontal: 10,
    },
});

const mobileStyles = StyleSheet.create({
    images: {
        justifyContent: 'center',
    },
});
