import React from 'react';
import {StyleSheet, View} from 'react-native';

import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import {Separator} from '~/components/common/Separator';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import {getPackContent} from '~/helpers/pack';
import usePack from '~/queries/usePack';
import {PackParamList} from '~/types';
import {PackType} from '~/types/graphql-global-types';

export default function BasketScreen() {
    const alert = useAlert();
    const {me} = React.useContext(UserContext);
    const navigation = useNavigation();
    const {data} = usePack();
    const route = useRoute<RouteProp<PackParamList, 'BasketScreen'>>();

    const pack = data?.getPacks?.edges?.find((f) => f?.node?.packId == route.params?.packId);

    async function buy() {
        if (me && !me.isExpert) {
            navigation.navigate('PaymentScreen', {packId: route.params?.packId});
        } else if (me && me.isExpert) {
            alert({
                message: "Vous ne pouvez pas acheter de pack en tant qu'expert.",
                width: 400,
            });
        } else {
            alert({
                message: 'Vous devez vous connecter afin de passer commande.',
                onClose: () => {
                    navigation.navigate('LoginScreen');
                },
                width: 400,
            });
        }
    }

    // console.log('pack = ', pack);
    const content = getPackContent(pack);

    return (
        <MainView>
            <PageTitle>Votre panier</PageTitle>
            {pack && (
                <>
                    <View style={styles.box}>
                        <SimpleText style={[styles.label, styles.pack]}>Pack</SimpleText>
                        <SimpleText style={[styles.value, styles.pack]}>
                            {pack?.node?.name}
                        </SimpleText>
                        <Separator style={styles.separator} />
                        <SimpleText style={[styles.label, styles.offer]}>Contenu</SimpleText>
                        <SimpleText style={[styles.value, styles.offer]}>{content}</SimpleText>
                        <Separator style={styles.separator} />
                        <SimpleText style={[styles.label, styles.total]}>
                            Total à payer
                        </SimpleText>
                        <SimpleText style={[styles.value, styles.total]}>
                            {pack?.node?.amount} €
                        </SimpleText>
                    </View>
                    <Button
                        containerStyle={{marginTop: 20}}
                        titleStyle={{fontSize: 16, marginHorizontal: 20}}
                        title={'Valider le panier'}
                        onPress={buy}
                    />
                </>
            )}
        </MainView>
    );
}

const styles = StyleSheet.create({
    box: {
        borderColor: Colors.primary,
        borderWidth: 2,
        alignSelf: 'center',
        textAlign: 'center',
        padding: 20,
    },
    label: {
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    value: {
        color: Colors.secondary,
        paddingTop: 5,
        fontWeight: 'bold',
        fontSize: 16,
        alignSelf: 'center',
        textAlign: 'center',
    },
    pack: {
        width: 200,
    },
    offer: {
        width: 300,
    },
    total: {
        width: 100,
    },
    separator: {
        backgroundColor: Colors.primary,
        marginVertical: 20,
        height: 2,
        alignSelf: 'center',
        width: '40%',
    },
    buy: {
        textAlign: 'center',
        color: 'white',
        fontSize: 18,
    },
    button: {
        borderRadius: 5,
        paddingHorizontal: 30,
        paddingVertical: 10,
        maxWidth: 250,
        margin: 20,
    },
    center: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
