import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

import Button from '~/components/common/Buttons';
import {Bloc} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import {filterEmpty} from '~/helpers/list';
import {QueryMe_me_notificationParameters_edges as NotificationParameter} from '~/queries/types/QueryMe';

import PopupAlerting, {NotificationParametersInput} from './PopupAlerting';

export default function BlocAlerting() {
    const {me, loadingMe, refetchMe} = React.useContext(UserContext);
    const [modalVisible, setModalVisible] = useState(false);

    async function onSuccess() {
        setModalVisible(false);
        refetchMe();
    }

    function onRequestCloseDisable() {
        setModalVisible(false);
    }

    function modifyAlerting() {
        setModalVisible(true);
    }

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    const parameters = filterEmpty(me?.notificationParameters?.edges);

    const nb = parameters.filter((item: NotificationParameter) => {
        return item?.node?.activation;
    }).length;

    const alertings = parameters.map((e) => {
        return {
            typeNotification: e?.node?.typeNotification,
            activation: e?.node?.activation,
        } as NotificationParametersInput;
    });

    let text = '';
    if (nb == 0) {
        text = 'Aucune notification programmées.';
    } else if (nb == 1) {
        text = '1 notification programmée.';
    } else {
        text = nb + ' notifications programmées.';
    }

    return (
        <Bloc title="Mes Notifications" iconName="bell">
            <View style={styles.container}>
                <SimpleText style={styles.text}>{text}</SimpleText>
                <Button
                    icon={<Icons.Edit size={16} style={{marginRight: 20}} />}
                    onPress={modifyAlerting}
                    style={styles.button}
                    title="Modifier"
                />
            </View>
            <PopupAlerting
                onSuccess={onSuccess}
                visible={modalVisible}
                onRequestClose={onRequestCloseDisable}
                alertings={alertings}
            />
        </Bloc>
    );
}

const styles = StyleSheet.create({
    container: {
        margin: 15,
        alignSelf: 'center',
        backgroundColor: 'white',
        width: 300,
    },
    text: {
        alignSelf: 'center',
        paddingTop: 20,
    },
    button: {
        margin: 15,
        width: 150,
        alignSelf: 'center',
    },
});
