import * as React from 'react';
import {ScrollView, View} from 'react-native';

import ExpertPickerView from '~/components/landingPage/ExpertPickerView';
import OngoingCallView from '~/components/landingPage/OngoingCallView';
import PickedExpertView from '~/components/landingPage/PickedExpertView';
import PostRegisterView from '~/components/landingPage/PostRegisterView';
import RegisterView from '~/components/landingPage/RegisterView';
import Reviews from '~/components/landingPage/Reviews';
import TitleView from '~/components/landingPage/TitleView';
import {
    UrlStepProvider,
    useUrlStepContext,
} from '~/components/landingPage/context/UrlStepContext';
import {useCallStarted} from '~/components/landingPage/hook/useCallStarted';
import useCampaignQuery from '~/components/landingPage/hook/useCampaignQuery';
import {useExpertId} from '~/components/landingPage/hook/useExpertId';
import useSelectedExpert from '~/components/landingPage/query/useSelectedExpert';
import {
    useCallLandingPageNavigation,
    useCallLandingPageRoute,
} from '~/components/landingPage/route';
import {styles} from '~/components/landingPage/styles';
import {mobileStyles} from '~/components/user/client/message/MobileMessageBox';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import {UserContext} from '~/contexts/UserContext';
import event from '~/helpers/analytics';
import {ExpertWithLastReview} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {ClientOfferStatusEnum} from '~/types/graphql-global-types';

export default function LandingPageFreeCallNoCbScreen() {
    return (
        <UrlStepProvider>
            <Content />
        </UrlStepProvider>
    );
}

function Content() {
    const alert = useAlert();
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles.container : mobileStyles.container;
    const scrollViewRef = React.useRef<ScrollView>(null);
    function scrollToTop() {
        scrollViewRef.current?.scrollTo({y: 0, animated: false});
    }

    const query = useCampaignQuery();
    const {me, refetchMe, offers, refetchOffers} = React.useContext(UserContext);
    const allOffers = offers?.getClientOffers?.edges.map((e) => e?.node);
    const availableOffers = React.useMemo(
        () => allOffers?.filter((o) => o?.status == ClientOfferStatusEnum.AVAILABLE),
        [offers]
    );
    console.log('availableOffers', availableOffers);
    const route = useCallLandingPageRoute();
    const sessionId =
        route.params?.sessionId || me?.identity?.selectedPaymentMethod?.sessionId;

    React.useEffect(() => {
        if (offers) {
            if (availableOffers?.length == 0) {
                alert({
                    title: 'Information',
                    message:
                        'Vous avez déjà bénéficié de vos 3x5 mins. gratuites, voulez vous consulter nos offres de forfait ?',
                    onClose: () => (document.location.href = '/nos-tarifs'),
                });
            }
        }
    }, [availableOffers]);

    const {showExpertList, showAuthForm, showCallStatus} = useUrlStepContext();
    const [callAlreadyStarted, setCallAlreadyStarted] = useCallStarted();
    const [selectedExpertId, setSelectedExpertId] = useExpertId();
    const confirm = useConfirm();
    const popupAndCall = React.useCallback(
        (expert: ExpertWithLastReview) => {
            if (availableOffers?.length != allOffers?.length) {
                confirm({
                    title: "Lancement d'un appel gratuit",
                    message: `Il vous reste ${availableOffers?.length} coupons de communication gratuite, êtes vous sûr de vouloir en utiliser un avec ${expert.profile?.displayName} ?`,
                    onYes: () => {
                        showCallStatus();
                        scrollToTop();
                    },
                    yesText: 'Oui, appeler',
                    noText: 'Non',
                });
            } else {
                showCallStatus();
                scrollToTop();
            }
        },
        [showCallStatus, availableOffers, allOffers]
    );
    const onExpertSelected = React.useCallback(
        (user: ExpertWithLastReview) => {
            setSelectedExpertId(user.userId);
            if (me?.id) {
                popupAndCall(user);
            } else {
                showAuthForm();
                scrollToTop();
            }
            event('call_landing_page_expert_selected', {expert_id: user.userId});
        },
        [me, popupAndCall]
    );

    const onRegisterAccount = React.useCallback(() => {
        showCallStatus();
        scrollToTop();
        setTimeout(() => refetchMe(), 2000);
        event('call_landing_page_register');
    }, []);

    const onCancelPayment = React.useCallback(() => {
        showExpertList();
        scrollToTop();
        event('call_landing_page_refused_waiting_list');
    }, []);

    const pickAnotherExpert = React.useCallback(() => {
        showExpertList();
        setSelectedExpertId(undefined);
        scrollToTop();
        event('call_landing_page_pick_another_expert');
    }, []);

    const navigation = useCallLandingPageNavigation();
    const returnToFirstStep = React.useCallback(() => {
        refetchOffers();
        navigation.navigate('LandingPageCallFreeNoCbScreen', {});
    }, []);

    const {data, loading} = useSelectedExpert(selectedExpertId);
    const selectedExpertUser = data?.user;

    return (
        <View style={{flex: 1}}>
            <ScrollView ref={scrollViewRef} contentContainerStyle={{flexGrow: 1}}>
                <View style={deviceStyle}>
                    <TitleView nbStepsText={'deux'} />
                    <ExpertPickerView
                        freeCall
                        onExpertSelected={onExpertSelected}
                        medium="call"
                    />
                    <PickedExpertView
                        expertUser={selectedExpertUser}
                        onPickAnotherExpertPress={pickAnotherExpert}
                    />
                    <RegisterView
                        stepIndex={2}
                        style={{
                            maxWidth: 700,
                            alignSelf: 'center',
                            flex: 1,
                            marginVertical: 20,
                        }}
                        query={query}
                        onRegister={onRegisterAccount}
                    />
                    <PostRegisterView stepIndex={2} />
                    <OngoingCallView
                        useFreeCall
                        onPressReturn={returnToFirstStep}
                        sessionId={sessionId}
                        expertUser={selectedExpertUser}
                        selectedExpertId={selectedExpertId}
                        loadingExpert={loading}
                        callAlreadyStarted={callAlreadyStarted}
                        setCallAlreadyStarted={setCallAlreadyStarted}
                        onCancelPayment={onCancelPayment}
                    />
                    <Reviews />
                </View>
            </ScrollView>
        </View>
    );
}
