import React, {useCallback, useEffect, useRef} from 'react';

import {Audio, AVPlaybackStatus} from 'expo-av';
import {AVPlaybackSource} from 'expo-av/build/AV';

const DEFAULT_PLAYER_FUNCTION = {
    play: async (_: AVPlaybackSource | null) => console.log('Audio inconnu'),
    stop: async () => console.log('nothing to stop'),
    isPlaying: false,
    currentUri: undefined,
};

export const AudioContext = React.createContext<PlayerFunctions>(DEFAULT_PLAYER_FUNCTION);

export function AudioPlayer(props: {children: React.ReactNode}) {
    const [audio, setAudio] = React.useState<Audio.Sound>();
    const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
    const [currentUri, setCurrentUri] = React.useState<string>();

    const previousAudio = useRef<Audio.Sound>();
    useEffect(() => {
        previousAudio.current = audio;
    }, [audio]);

    const onPlaybackStatusUpdate = useCallback((status: AVPlaybackStatus) => {
        if (status.isLoaded) {
            setIsPlaying(status.isPlaying);
        } else {
            setIsPlaying(false);
        }
    }, []);

    const stop = useCallback(async () => {
        if (audio) {
            await audio.stopAsync();
        }
    }, [audio]);

    const play = useCallback(
        async (source: AVPlaybackSource | null) => {
            if (previousAudio.current) {
                // don't await, use await if people complain about messages overlapping
                // (stopping is fast, so in theory it shouldn't happen)
                previousAudio.current.stopAsync();
            }
            if (source) {
                const {sound} = await Audio.Sound.createAsync(source);
                sound.setStatusAsync({progressUpdateIntervalMillis: 500});
                sound.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
                if (typeof source === 'object' && 'uri' in source) {
                    setCurrentUri(source.uri);
                }
                setAudio(sound);
                try {
                    await sound.playAsync();
                } catch (error) {}
            }
        },
        [previousAudio, audio, stop]
    );

    return (
        <AudioContext.Provider value={{play, stop, isPlaying, currentUri}}>
            {props.children}
        </AudioContext.Provider>
    );
}

export function useAudio() {
    return React.useContext(AudioContext);
}

export type PlayerFunctions = {
    play: (source: AVPlaybackSource | null) => Promise<void>;
    stop: () => Promise<void>;
    isPlaying: boolean;
    currentUri: string | undefined;
};
