import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import Settings from '~/constants/Settings';

export default function LegalNoticeScreen() {
    return (
        <MainView>
            <PageTitle>Mentions légales</PageTitle>
            <View style={styles.section}>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>Entreprise :</SimpleText>
                    <SimpleText style={styles.text}>sas kyvoitou wza</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>Siège social :</SimpleText>
                    <SimpleText style={styles.text}>
                        Terre de Pompiac - 47330 CASTILLONNES
                    </SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>Capital social :</SimpleText>
                    <SimpleText style={styles.text}>202 000 €</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>RCS :</SimpleText>
                    <SimpleText style={styles.text}>884 628 124 R.C.S. Agen</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>N°TVA :</SimpleText>
                    <SimpleText style={styles.text}>FR80884628124</SimpleText>
                </View>
            </View>
            <View style={styles.section}>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>Hébergement du site :</SimpleText>
                    <SimpleText style={styles.text}>OVH</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>SAS au capital de :</SimpleText>
                    <SimpleText style={styles.text}>10 174 560 €</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>RCS :</SimpleText>
                    <SimpleText style={styles.text}>
                        Lille Métropole 424 761 419 00045
                    </SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>Code APE :</SimpleText>
                    <SimpleText style={styles.text}>2620Z</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>N°TVA :</SimpleText>
                    <SimpleText style={styles.text}>FR 22 424 761 419</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>Siège social :</SimpleText>
                    <SimpleText style={styles.text}>
                        2 rue Kellermann - 59100 Roubaix - France
                    </SimpleText>
                </View>
            </View>
            <View style={styles.section}>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>Contact Support</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>
                        Depuis la France ou l'étranger
                    </SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>{Settings.support_mail}</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>{Settings.support_telephone}</SimpleText>
                </View>
                <View style={styles.line}>
                    <SimpleText style={styles.object}>{Settings.support_mobile}</SimpleText>
                </View>
            </View>
        </MainView>
    );
}

const styles = StyleSheet.create({
    container: {
        marginVertical: 40,
    },
    title: {
        fontSize: 20,
        marginVertical: 10,
        textAlign: 'center',
    },
    section: {
        marginBottom: 50,
        paddingHorizontal: 15,
    },
    line: {
        flexDirection: 'row',
        marginTop: 5,
    },
    object: {
        fontWeight: 'bold',
    },
    text: {
        fontSize: 16,
        marginLeft: 5,
    },
    bold: {
        fontWeight: 'bold',
    },
});
