// TODO need refacto, mais la flemme
import React, {useState} from 'react';
import {ModalProps, StyleSheet, View} from 'react-native';

import {gql, useQuery, useMutation} from '@apollo/client';

import Button from '~/components/common/Buttons';
import {GradeResume} from '~/components/common/GradeItem';
import {ReviewResponseInput} from '~/components/common/Inputs';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import type {Quality} from '~/components/expert/QualityList';
import QualityList from '~/components/expert/QualityList';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import * as dates from '~/helpers/dates';

import type {
    GetCallDetails,
    GetCallDetails_call_review,
    GetCallDetails_call_review_qualities_edges,
} from './types/GetCallDetails';
import type {
    GetChatDetails,
    GetChatDetails_chat_review,
    GetChatDetails_chat_review_qualities_edges,
} from './types/GetChatDetails';
import type {
    GetMessageDetails,
    GetMessageDetails_message_review,
    GetMessageDetails_message_review_qualities_edges,
} from './types/GetMessageDetails';
import type {ReviewData} from './types/calls';
import {CommunicationType} from './types/calls';

export type CloseFunction = (preventAnimation?: boolean) => any;

export default function PopupReadReview(props: ModalProps & {reviewData: ReviewData}) {
    const {
        data: callData,
        loading: callLoading,
        error: callError,
        refetch: callRefetch,
    } = useQuery<GetCallDetails>(GET_CALL_DETAILS, {
        variables: {callId: props.reviewData.communicationId},
        skip: props.reviewData.communicationType != CommunicationType.CALL,
    });
    const {
        data: chatData,
        loading: chatLoading,
        error: chatError,
        refetch: chatRefetch,
    } = useQuery<GetChatDetails>(GET_CHAT_DETAILS, {
        variables: {chatId: props.reviewData.communicationId},
        skip: props.reviewData.communicationType != CommunicationType.CHAT,
    });
    const {
        data: messageData,
        loading: messageLoading,
        error: messageError,
        refetch: messageRefetch,
    } = useQuery<GetMessageDetails>(GET_MESSAGE_DETAILS, {
        variables: {messageId: props.reviewData.communicationId},
        skip: props.reviewData.communicationType != CommunicationType.MESSAGE,
    });

    if (
        props.reviewData.communicationType == CommunicationType.CALL &&
        callLoading &&
        !callData
    ) {
        return <></>;
    }
    if (
        props.reviewData.communicationType == CommunicationType.CALL &&
        (callError || !callData?.call)
    ) {
        return <SimpleText>Erreur lors du chargement de la review.</SimpleText>;
    }

    if (
        props.reviewData.communicationType == CommunicationType.MESSAGE &&
        messageLoading &&
        !messageData
    ) {
        return <></>;
    }
    if (
        props.reviewData.communicationType == CommunicationType.MESSAGE &&
        (messageError || !messageData?.message)
    ) {
        return <SimpleText>Erreur lors du chargement de la review.</SimpleText>;
    }

    if (
        props.reviewData.communicationType == CommunicationType.CHAT &&
        chatLoading &&
        !chatData
    ) {
        return <></>;
    }
    if (
        props.reviewData.communicationType == CommunicationType.CHAT &&
        (chatError || !chatData?.chat)
    ) {
        return <SimpleText>Erreur lors du chargement de la review.</SimpleText>;
    }

    function onRespond() {
        if (props.reviewData.communicationType == CommunicationType.CALL) {
            callRefetch();
        } else if (props.reviewData.communicationType == CommunicationType.CHAT) {
            chatRefetch();
        } else if (props.reviewData.communicationType == CommunicationType.MESSAGE) {
            messageRefetch();
        }
        props.onRequestClose && props.onRequestClose();
    }

    function getCommunicationType() {
        if (props.reviewData.communicationType == CommunicationType.CALL) {
            return 'Appel';
        } else if (props.reviewData.communicationType == CommunicationType.MESSAGE) {
            return 'Message';
        } else if (props.reviewData.communicationType == CommunicationType.CHAT) {
            return 'Chat';
        }
    }

    function getCommunication() {
        if (props.reviewData.communicationType == CommunicationType.CALL) {
            return callData?.call;
        } else if (props.reviewData.communicationType == CommunicationType.MESSAGE) {
            return messageData?.message;
        } else if (props.reviewData.communicationType == CommunicationType.CHAT) {
            return chatData?.chat;
        }
    }

    function getDate() {
        if (props.reviewData.communicationType == CommunicationType.CALL) {
            return callData?.call?.endDatetime;
        } else if (props.reviewData.communicationType == CommunicationType.MESSAGE) {
            return messageData?.message?.responseDate;
        } else if (props.reviewData.communicationType == CommunicationType.CHAT) {
            return chatData?.chat?.endDatetime;
        }
    }

    function getDuration() {
        if (
            props.reviewData.communicationType == CommunicationType.CALL &&
            callData?.call?.duration
        ) {
            return '(' + dates.SQL.timeString(callData?.call?.duration) + ')';
        } else if (props.reviewData.communicationType == CommunicationType.MESSAGE) {
            return '';
        } else if (
            props.reviewData.communicationType == CommunicationType.CHAT &&
            chatData?.chat?.duration
        ) {
            return '(' + dates.SQL.timeString(chatData?.chat?.duration) + ')';
        } else {
            return '';
        }
    }

    const type = getCommunicationType();
    const communication = getCommunication();
    const date = getDate();
    const duration = getDuration();

    if (communication) {
        const name = props.reviewData.clientMode
            ? communication?.expert?.profile?.displayName
            : communication?.client?.profile?.displayName;
        const title = `${type} avec ${name} le ${dates.SQL.dateString(date)} ${duration}`;

        return (
            <CustomModal
                mobileContentMaxWidth={800}
                title={title}
                iconName="poll"
                hasCloseIcon={true}
                {...props}
            >
                <ReviewDetails
                    review={communication.review}
                    expertId={communication.expertId}
                    onRespond={onRespond}
                />
            </CustomModal>
        );
    } else {
        return <></>;
    }
}

const ADD_REVIEW_RESPONSE = gql`
    mutation addReviewResponse($reviewId: String!, $response: String!) {
        addReviewResponse(input: {reviewId: $reviewId, response: $response}) {
            review {
                id
                response
            }
        }
    }
`;

type ReviewDetailsProps = {
    review:
        | GetCallDetails_call_review
        | GetChatDetails_chat_review
        | GetMessageDetails_message_review
        | null;
    expertId: string | undefined;
    onRespond: CloseFunction | undefined;
};

function ReviewDetails(props: ReviewDetailsProps) {
    const responseFromDb = props.review?.response;
    const {me, refetchMe} = React.useContext(UserContext);
    const [response, setResponse] = useState(responseFromDb);
    const [error, setError] = useState('');
    const [addReviewResponse, {data, error: mutationError}] = useMutation(
        ADD_REVIEW_RESPONSE,
        {
            errorPolicy: 'all',
        }
    );
    const canRespond = me?.userId == props.expertId;

    async function submit() {
        if (response === '') {
            setError('La réponse est obligatoire');
            return;
        }

        setError('');

        const data = {
            reviewId: props.review?.reviewId,
            response: response,
        };
        await addReviewResponse({variables: data});

        props.onRespond && props.onRespond();
        logEvent('review_responded');
    }

    const qualities = props.review?.qualities?.edges.map(
        (
            edge:
                | GetCallDetails_call_review_qualities_edges
                | GetMessageDetails_message_review_qualities_edges
                | GetChatDetails_chat_review_qualities_edges
                | null
        ) => {
            return {
                label: edge?.node?.quality?.label,
                starNumber: edge?.node?.starNumber,
                id: edge?.node?.id,
            } as Quality;
        }
    );
    return (
        <View>
            <View style={styles.blocContainer}>
                <SimpleText style={styles.blocTitle}>Impression</SimpleText>
                <SimpleText style={styles.centeredBlocBody}>
                    <GradeResume
                        style={styles.grade}
                        text={props.review?.grade?.label ?? ''}
                        grade={parseInt(props.review?.grade?.gradeId ?? '0')}
                    />
                </SimpleText>
            </View>
            <View style={styles.blocContainer}>
                <SimpleText style={styles.blocTitle}>Commentaire</SimpleText>
                <SimpleText style={styles.bloc_body}>{props.review?.comment}</SimpleText>
                {canRespond && (
                    <>
                        <SimpleText style={styles.responseTitle}>Votre réponse</SimpleText>
                        <ReviewResponseInput
                            onChangeText={setResponse}
                            style={{height: 75}}
                            value={response ?? ''}
                        />
                        <SimpleText style={styles.responseError}>{error}</SimpleText>
                        <Button
                            style={styles.responseButton}
                            title={responseFromDb ? 'Modifier' : 'Répondre'}
                            onPress={submit}
                            titleStyle={{fontSize: 14}}
                            containerStyle={{padding: 2}}
                        />
                    </>
                )}
                {!canRespond && responseFromDb && (
                    <>
                        <SimpleText style={styles.responseTitle}>Réponse</SimpleText>
                        <SimpleText style={styles.bloc_body}>{responseFromDb}</SimpleText>
                    </>
                )}
            </View>
            <View style={styles.blocContainer}>
                <SimpleText style={styles.blocTitle}>Qualités</SimpleText>
                <SimpleText style={styles.bloc_body}>
                    {qualities && <QualityList qualities={qualities} />}
                </SimpleText>
            </View>
        </View>
    );
}

ReviewDetails.fragments = {
    review: gql`
        fragment ReadReviewFragment on Review {
            id
            reviewId
            comment
            response
            grade {
                id
                label
                gradeId
            }
            createdDate
            qualities {
                edges {
                    node {
                        id
                        starNumber
                        quality {
                            label
                        }
                    }
                }
            }
        }
    `,
};

const GET_CALL_DETAILS = gql`
    query GetCallDetails($callId: ID!) {
        call(id: $callId) {
            id
            cost
            duration
            endDatetime
            expertId
            client {
                id
                profile {
                    id
                    displayName
                }
            }
            expert {
                id
                profile {
                    id
                    displayName
                }
            }
            review {
                ...ReadReviewFragment
            }
        }
    }
    ${ReviewDetails.fragments.review}
`;

const GET_MESSAGE_DETAILS = gql`
    query GetMessageDetails($messageId: ID!) {
        message(id: $messageId) {
            id
            cost
            responseDate
            expertId
            client {
                id
                profile {
                    id
                    displayName
                }
            }
            expert {
                id
                profile {
                    id
                    displayName
                }
            }
            review {
                ...ReadReviewFragment
            }
        }
    }
    ${ReviewDetails.fragments.review}
`;

const GET_CHAT_DETAILS = gql`
    query GetChatDetails($chatId: ID!) {
        chat(id: $chatId) {
            id
            cost
            duration
            endDatetime
            expertId
            client {
                id
                profile {
                    id
                    displayName
                }
            }
            expert {
                id
                profile {
                    id
                    displayName
                }
            }
            review {
                ...ReadReviewFragment
            }
        }
    }
    ${ReviewDetails.fragments.review}
`;

const styles = StyleSheet.create({
    description: {
        textAlign: 'center',
        fontSize: 14,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16,
        height: 130,
    },
    modalReviewTitleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    modalReviewPicto: {
        width: 29,
        height: 22,
    },
    modalReviewTitle: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
    },
    close: {
        width: 23,
        height: 20,
    },
    blocTitle: {
        color: Colors.secondary,
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    responseTitle: {
        color: Colors.secondary,
        fontSize: 14,
        fontWeight: 'bold',
        paddingTop: 20,
        paddingBottom: 10,
    },
    responseError: {
        color: Colors.errors,
        fontSize: 12,
        height: 15,
        textAlign: 'right',
        marginRight: 20,
    },
    responseButton: {
        width: 200,
        alignSelf: 'flex-end',
    },
    blocContainer: {
        marginTop: 20,
        padding: 10,
        backgroundColor: 'white',
    },
    bloc_body: {
        marginLeft: 10,
        flex: 1,
    },
    centeredBlocBody: {
        textAlign: 'center',
    },
    grade: {
        fontSize: 16,
    },
});
