import {
    HomeReviewsQuery,
    HomeReviewsQuery_getHomeReviews_edges_node as HomeReview,
} from '~/queries/types/HomeReviewsQuery';

const REVIEW_NUMBER_DESKTOP = 3;
const REVIEW_NUMBER_MOBILE = 1;

export function groupReviews(data: HomeReviewsQuery | undefined, isDesktop: boolean) {
    const n = isDesktop ? REVIEW_NUMBER_DESKTOP : REVIEW_NUMBER_MOBILE;
    let reviews = [] as HomeReview[][];
    if (data?.getHomeReviews?.edges) {
        let idx = 0;
        let g = 0;
        for (const r of data.getHomeReviews.edges) {
            if (r?.node) {
                if (idx % n == 0) {
                    if (idx > 0) {
                        g++;
                    }
                    reviews[g] = [] as HomeReview[];
                }
                reviews[g].push(r.node);
            }
            idx++;
        }
    }
    return reviews;
}
