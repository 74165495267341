import React from 'react';
import {StyleSheet, View} from 'react-native';

import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {PasswordInput} from '~/components/common/Inputs';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

const {password, passwordConfirm} = yupRules;
const validationSchema = yup.object().shape({password, passwordConfirm});

export default function PasswordForm({onSuccess}: {onSuccess: Function}) {
    const {user} = React.useContext(UserContext);

    async function modifyPassword(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        try {
            await user?.updatePassword(values.password);
            onSuccess();
            logEvent('modify_account', {fields: 'password'});
        } catch (error) {
            if (error.code == 'auth/requires-recent-login') {
                setErrors({
                    password:
                        'Par mesure de sécurité, merci de vous reconnecter avant de modifier votre mot de passe',
                });
            }
            throw error;
        }
    }

    return (
        <View>
            <Formik
                initialValues={{
                    password: '',
                    passwordConfirm: '',
                }}
                onSubmit={modifyPassword}
                validationSchema={validationSchema}
            >
                {(formikProps) => <PasswordInputs {...formikProps} />}
            </Formik>
        </View>
    );
}

function PasswordInputs(props: Props) {
    return (
        <View style={styles.container}>
            <FormLabel>Mot de passe</FormLabel>
            <PasswordInput
                placeholder={yupLabels.password}
                value={props.values.password}
                onChangeText={props.handleChange('password')}
                onBlur={() => props.setFieldTouched('password')}
                errorMessage={props.errors.password || ' '}
                onSubmitEditing={() => props.handleSubmit()}
            />
            <FormLabel>Confirmation du mot de passe</FormLabel>
            <PasswordInput
                placeholder={yupLabels.passwordConfirm}
                value={props.values.passwordConfirm}
                onChangeText={props.handleChange('passwordConfirm')}
                onBlur={() => props.setFieldTouched('passwordConfirm')}
                errorMessage={props.errors.passwordConfirm || ' '}
                onSubmitEditing={() => props.handleSubmit()}
            />
            <Button
                icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                title="Valider"
                buttonStyle={{width: 160, alignSelf: 'center', marginBottom: 20}}
                disabled={!props.isValid}
                onPress={() => props.handleSubmit()}
                loading={props.isSubmitting}
            />
        </View>
    );
}

type Inputs = Pick<YupTypes, 'password' | 'passwordConfirm'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        marginVertical: 10,
        borderRadius: 5,
        marginHorizontal: 25,
        alignContent: 'space-around',
        backgroundColor: 'white',
    },
});
