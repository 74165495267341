import * as React from 'react';
import {StyleSheet} from 'react-native';

import * as Colors from '~/constants/Colors';

import {SimpleText, TextProps} from './Texts';

export function PageTitle(props: TextProps) {
    return (
        <SimpleText
            accessibilityRole="header"
            aria-level="1"
            {...props}
            style={styles.pageTitle}
        />
    );
}

export function ModalTitle({style, ...props}: TextProps) {
    return <SimpleText {...props} style={[style, styles.modalTitle]} />;
}

const styles = StyleSheet.create({
    pageTitle: {
        fontWeight: 'bold',
        fontSize: 38,
        textTransform: 'uppercase',
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        margin: 44,
    },
    modalTitle: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
});
