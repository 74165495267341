import {useCallback, useEffect} from 'react';

import {useLandingPageNavigation, useLandingPageRoute} from '../route';

export function useCallStarted() {
    const route = useLandingPageRoute();
    const navigation = useLandingPageNavigation();
    let callAlreadyStarted: boolean | undefined;
    if (route.params?.callStarted == 'y') {
        callAlreadyStarted = true;
    }
    if (route.params?.callStarted == 'f') {
        callAlreadyStarted = false;
    }
    const setCallAlreadyStarted = useCallback((started: boolean) => {
        navigation.setParams({
            callStarted: started ? 'y' : 'f',
        });
    }, []);

    useEffect(() => {
        if (callAlreadyStarted == null) {
            setCallAlreadyStarted(false);
        }
    }, [callAlreadyStarted]);

    return [callAlreadyStarted, setCallAlreadyStarted] as const;
}
