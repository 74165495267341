import React from 'react';
import {StyleSheet, View} from 'react-native';

import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {filterEmpty} from '~/helpers/list';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import useHappyDay from '~/queries/useHappyDay';

export default function Edito() {
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles : mobileStyles;
    const {data} = useHappyDay();

    let day = '1 journée / mois';

    const dates = data?.getHappyDay?.edges?.map((e) => {
        const d = e?.node?.date ?? '';
        const n = Date.parse(d);
        const date = new Date(n);
        return date;
    });

    const now = new Date(Date.now());
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const days = filterEmpty(dates)
        .filter((f) => f.getTime() >= today.getTime())
        .sort(function (a, b) {
            return a.getTime() - b.getTime();
        });

    if (days.length > 0) {
        const date = days[0];

        if (
            now.getFullYear() == date.getFullYear() &&
            now.getMonth() == date.getMonth() &&
            now.getDate() == date.getDate()
        ) {
            day = "Aujourd'hui";
        } else {
            const j = date.toLocaleDateString('fr-FR', {weekday: 'long'});
            const d = date.getDate();
            const m = date.toLocaleString('fr-FR', {month: 'long'});
            day = `${j} ${d} ${m}`;
        }
    }

    return (
        <View style={deviceStyle.container}>
            <SimpleText style={deviceStyle.title}>Nos offres</SimpleText>
            <View style={deviceStyle.blocs}>
                <View style={[deviceStyle.bloc, deviceStyle.blocOffer]}>
                    <View style={deviceStyle.picto}>
                        <Icons.Gift color={Colors.highlight} size={50} />
                    </View>
                    <SimpleText style={deviceStyle.bloctitle}>Offre de bienvenue</SimpleText>
                    <SimpleText style={deviceStyle.descriptionOffer}>
                        Dès votre inscription, profitez de
                        <SimpleText style={deviceStyle.offer}>
                            {' '}
                            profitez de 3 séances de 5 minutes offertes{' '}
                        </SimpleText>
                        avec différents spirites de votre choix !
                    </SimpleText>
                </View>
                <View style={[deviceStyle.bloc, deviceStyle.blocMode]}>
                    <View style={deviceStyle.picto}>
                        <Icons.Users color={Colors.link} size={50} />
                    </View>
                    <SimpleText style={deviceStyle.bloctitle}>
                        3 modes de consultation
                    </SimpleText>
                    <View style={deviceStyle.modeContainer}>
                        <View>
                            <SimpleText style={deviceStyle.action}>Téléphone</SimpleText>
                            <View style={deviceStyle.modePicto}>
                                <Icons.Phone color={'white'} size={26} />
                            </View>
                            <SimpleText style={deviceStyle.action}>De vive voix</SimpleText>
                        </View>
                        <View>
                            <SimpleText style={deviceStyle.action}>Tchat</SimpleText>
                            <View style={deviceStyle.modePicto}>
                                <Icons.Chat
                                    color={'white'}
                                    size={26}
                                    style={{alignSelf: 'center'}}
                                />
                            </View>
                            <SimpleText style={deviceStyle.action}>Échange discret</SimpleText>
                        </View>
                        <View>
                            <SimpleText style={deviceStyle.action}>Email</SimpleText>
                            <View style={deviceStyle.modePicto}>
                                <Icons.Mail
                                    color={'white'}
                                    size={26}
                                    style={{alignSelf: 'center'}}
                                />
                            </View>
                            <SimpleText style={deviceStyle.action}>
                                Pour les détails
                            </SimpleText>
                        </View>
                    </View>
                </View>
                <View style={[deviceStyle.bloc, deviceStyle.blocHappy]}>
                    <View style={deviceStyle.picto}>
                        <Icons.Smile color={Colors.nice} size={50} />
                    </View>
                    <SimpleText style={deviceStyle.bloctitle}>Happy day</SimpleText>
                    <SimpleText style={deviceStyle.descriptionHappy}>
                        Découverte du site et des spirites, toutes les consultations à partir
                        de 1.50€ la minute
                    </SimpleText>
                    <SimpleText style={deviceStyle.day}>{day}</SimpleText>
                    <SimpleText style={deviceStyle.italic}>
                        * Abonnez vous à notre newsletter pour être averti des promos !
                    </SimpleText>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 30,
        paddingHorizontal: 30,
        alignItems: 'center',
    },
    title: {
        fontSize: 40,
        fontWeight: 'bold',
        color: Colors.secondary,
        textAlign: 'center',
    },
    blocs: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 25,
    },
    bloc: {
        width: 350,
        padding: 5,
        paddingBottom: 15,
        borderRadius: 8,
        margin: 10,
        borderWidth: 2,
    },
    blocOffer: {
        borderColor: Colors.highlight,
    },
    blocMode: {
        borderColor: Colors.link,
    },
    blocHappy: {
        borderColor: Colors.nice,
    },
    picto: {
        alignSelf: 'center',
        marginTop: 25,
    },
    bloctitle: {
        fontSize: 26,
        fontWeight: 'bold',
        color: Colors.secondary,
        textAlign: 'center',
        marginTop: 25,
    },
    descriptionOffer: {
        fontSize: 16,
        color: 'black',
        textAlign: 'center',
        paddingTop: 40,
        paddingHorizontal: 40,
    },
    offer: {
        color: Colors.highlight,
        fontSize: 20,
    },
    modeContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 40,
        paddingHorizontal: 15,
    },
    descriptionMode: {
        fontSize: 16,
        color: Colors.link,
        textAlign: 'center',
        paddingTop: 15,
        paddingHorizontal: 25,
    },
    action: {
        fontSize: 12,
        color: Colors.link,
        textAlign: 'center',
        paddingVertical: 5,
        width: 70,
    },
    italic: {
        fontSize: 12,
        fontStyle: 'italic',
        color: Colors.nice,
        textAlign: 'center',
        paddingTop: 5,
    },
    modePicto: {
        backgroundColor: Colors.link,
        borderRadius: 50,
        justifyContent: 'center',
        alignSelf: 'center',
        width: 50,
        height: 50,
    },
    descriptionHappy: {
        fontSize: 16,
        color: 'black',
        textAlign: 'center',
        paddingTop: 40,
        paddingHorizontal: 40,
    },
    day: {
        fontSize: 20,
        textAlign: 'center',
        color: Colors.nice,
        marginTop: 10,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        paddingTop: 10,
        paddingHorizontal: 30,
        alignItems: 'center',
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: Colors.secondary,
        textAlign: 'center',
    },
    blocs: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 10,
    },
    bloc: {
        width: 300,
        padding: 5,
        borderRadius: 8,
        margin: 10,
        paddingBottom: 25,
        borderWidth: 2,
    },
    blocOffer: {
        borderColor: Colors.highlight,
    },
    blocMode: {
        borderColor: Colors.link,
    },
    blocHappy: {
        borderColor: Colors.nice,
    },
    picto: {
        alignSelf: 'center',
        marginTop: 25,
    },
    bloctitle: {
        fontSize: 26,
        fontWeight: 'bold',
        color: Colors.secondary,
        textAlign: 'center',
        marginTop: 25,
    },
    descriptionOffer: {
        fontSize: 16,
        color: 'black',
        textAlign: 'center',
        paddingTop: 40,
        paddingHorizontal: 40,
    },
    offer: {
        color: Colors.highlight,
        fontSize: 20,
    },
    modeContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 40,
        paddingHorizontal: 15,
    },
    descriptionMode: {
        fontSize: 16,
        color: Colors.link,
        textAlign: 'center',
        padding: 25,
    },
    action: {
        fontSize: 12,
        color: Colors.link,
        textAlign: 'center',
        paddingVertical: 5,
        width: 70,
    },
    italic: {
        fontSize: 12,
        fontStyle: 'italic',
        color: Colors.link,
        textAlign: 'center',
        paddingTop: 5,
    },
    modePicto: {
        backgroundColor: Colors.link,
        borderRadius: 50,
        justifyContent: 'center',
        alignSelf: 'center',
        width: 50,
        height: 50,
    },
    descriptionHappy: {
        fontSize: 16,
        color: 'black',
        textAlign: 'center',
        paddingTop: 40,
        paddingHorizontal: 40,
    },
    day: {
        textAlign: 'center',
        color: Colors.nice,
        marginTop: 10,
        fontSize: 20,
    },
});
