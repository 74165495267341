import React from 'react';
import {StyleSheet, View} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {MailInput} from '~/components/common/Inputs';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

import ReAuthenticateModal from '../../ReAuthenticateModal';

const {email} = yupRules;
const validationSchema = yup.object().shape({email});

export default function EmailForm({onSuccess}: {onSuccess: Function}) {
    const {user, me, loadingMe} = React.useContext(UserContext);
    const [modifyEmail, {data, error: mutationError}] = useMutation(MODIFY_EMAIL, {
        errorPolicy: 'all',
    });
    const [authFormVisible, setAuthFormVisible] = React.useState(false);
    const [newEmail, setNewEmail] = React.useState<string | null>(null);
    const hideReAuthModal = () => setAuthFormVisible(false);

    async function onReAuthenticationSuccess() {
        if (newEmail) {
            await submitEmail(newEmail);
            hideReAuthModal();
        } else {
            alert("Votre email n'a pas pu être mis à jour.");
        }
    }

    async function onSubmit(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        await submitEmail(values.email);
    }

    async function submitEmail(email: string) {
        setNewEmail(email);
        try {
            await updateUserEmail(email);
            onSuccess();
            logEvent('modify_account', {fields: 'email'});
        } catch (error) {
            if (error.code == 'auth/requires-recent-login') {
                setAuthFormVisible(true);
            } else {
                throw error;
            }
        }
    }

    async function updateUserEmail(email: string) {
        const data = {
            email: email,
        };
        await user?.updateEmail(email);
        return await modifyEmail({variables: data});
    }

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    return (
        <View>
            <ReAuthenticateModal
                mobileContentMaxWidth={600}
                title="Sécurisation du changement d'email"
                message="Pour valider votre changement d'adresse email en sécurité nous avons d'abord besoin de vérifier votre mot de passe."
                visible={authFormVisible}
                onRequestClose={hideReAuthModal}
                onSuccess={onReAuthenticationSuccess}
            />
            <Formik
                initialValues={{
                    email: me?.identity?.email ?? '',
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {(formikProps) => <EmailInputs {...formikProps} />}
            </Formik>
        </View>
    );
}

function EmailInputs(props: Props) {
    return (
        <View style={styles.container}>
            <FormLabel>Email</FormLabel>
            <MailInput
                placeholder={yupLabels.email}
                value={props.values.email}
                onChangeText={props.handleChange('email')}
                onBlur={() => props.setFieldTouched('email')}
                blurOnSubmit={true}
                errorMessage={props.errors.email || ' '}
            />
            <Button
                icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                title="Valider"
                buttonStyle={{width: 160, alignSelf: 'center', marginBottom: 20}}
                disabled={!props.isValid}
                onPress={() => props.handleSubmit()}
                loading={props.isSubmitting}
            />
        </View>
    );
}

type Inputs = Pick<YupTypes, 'email'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const MODIFY_EMAIL = gql`
    mutation modifyEmail($email: String!) {
        updateUserEmail(input: {email: $email}) {
            ok
        }
    }
`;

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        marginVertical: 10,
        borderRadius: 5,
        marginHorizontal: 25,
        alignContent: 'space-around',
        backgroundColor: 'white',
    },
});
