import {useEffect} from 'react';

import {gql, useQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import {isExpired, setExpiration} from '~/helpers/storage';

import type {HappyDayQuery} from './types/HappyDayQuery';

const HAPPY_DAY_QUERY = gql`
    query HappyDayQuery {
        getHappyDay {
            edges {
                node {
                    date
                }
            }
        }
    }
`;

const HAPPY_DAY_EXPIRATION = 60;
const HAPPY_DAY_KEY = 'HAPPY_DAY_EXPIRE';
let REFETCHING = false;

export default function useHappyDay() {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    };

    const query = useQuery<HappyDayQuery>(HAPPY_DAY_QUERY, options);

    async function refetchIfCacheExpire() {
        const expired = await isExpired(HAPPY_DAY_KEY);
        if (expired && !REFETCHING) {
            REFETCHING = true;
            await query.refetch();
            setExpiration(HAPPY_DAY_KEY, HAPPY_DAY_EXPIRATION);
            REFETCHING = false;
        }
    }

    useEffect(() => {
        refetchIfCacheExpire();
    });

    return query;
}
