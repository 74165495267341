import {LinkingOptions} from '@react-navigation/native';
import * as Linking from 'expo-linking';

import {landingPageLinkingConfig} from '~/constants/LandingPageSettings';
import {RootStackParamList} from '~/types';

// https://reactnavigation.org/docs/configuring-links/

export default {
    prefixes: [Linking.makeUrl('/')],
    config: {
        screens: {
            ...landingPageLinkingConfig,
            Root: {
                screens: {
                    Main: {
                        screens: {
                            Home: {
                                screens: {
                                    HomeScreen: 'home',
                                    PackScreen: {
                                        screens: {
                                            PackScreen: 'nos-packs',
                                            BasketScreen: 'votre-panier',
                                            PaymentScreen: 'paiement',
                                            SuccessScreen: 'success',
                                            CancelScreen: 'cancel',
                                        },
                                    },
                                    LoginScreen: 'me-connecter',
                                    RegisterScreen: 'creer-mon-compte',
                                    ForgottenPasswordScreen: 'mot-de-passe-oublie',
                                    HomeExpertScreen: {
                                        path: 'home/:url',
                                    },
                                    FaqScreen: 'faq',
                                    ContactScreen: 'contact',
                                    CgvScreen: 'cgv',
                                    CguScreen: 'cgu',
                                    ApplyScreen: 'devenir-expert',
                                    JobScreen: 'offre-emploi',
                                    CommitmentScreen: 'nos-engagements',
                                    EthicScreen: 'charte-ethique',
                                    LegalNoticeScreen: 'mentions-legales',
                                },
                            },
                            Profile: {
                                screens: {
                                    ProfileScreen: 'profil',
                                    HistoricScreen: 'historique',
                                },
                            },
                            PriceScreen: 'nos-tarifs',
                            MessageScreen: 'messages',
                            Search: {
                                screens: {
                                    SearchScreen: 'recherche',
                                    ExpertScreen: {
                                        path: 'expert/:url',
                                    },
                                },
                            },
                        },
                    },
                    HowToScreen: 'comment-ca-marche',
                    AccountScreen: 'mon-compte',
                    AboutScreen: 'qui-sommes-nous',
                },
            },
            NotFound: '*',
        },
    },
} as LinkingOptions<RootStackParamList>;
