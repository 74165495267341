import React, {useContext, useEffect, useRef} from 'react';
import {StyleSheet, ScrollView, View, Pressable} from 'react-native';

import Carousel, {ICarouselInstance} from 'react-native-reanimated-carousel';

import * as Icons from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {
    sortExperts,
    ExpertWithLastReview,
    UNAVAILABLE,
    filterIncompleteProfile,
} from '~/helpers/experts';
import {filterEmpty} from '~/helpers/list';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {useExpertsDetailsWithLastReview} from '~/queries/useExperts';
import {ClientOfferStatusEnum} from '~/types/graphql-global-types';

import AudiotelImageLink from '../common/Audiotel';
import {SimpleText} from '../common/Texts';
import ExpertCard, {
    CARD_HEIGHT,
    CARD_WIDTH,
    CommunicationMedium,
} from './ExpertCardLandingPageVersion';

export type LandingPageExpertListProps = {
    medium: CommunicationMedium;
    onAuthRequired?: () => void;
    onPaymentMethodRequired?: () => void;
    onCallStarted?: () => void;
    onExpertSelected?: (expert: ExpertWithLastReview) => void;
    horizontalScroll?: boolean;
    onCancelPayment?: () => void;
    queryParams: Object;
    paymentIntent: string | undefined | null;
    paymentIntentSecret: string | undefined | null;
    freeCall?: boolean;
};

export default function ExpertList(props: LandingPageExpertListProps) {
    const {offers} = useContext(UserContext);
    const usedOffers = offers?.getClientOffers?.edges.filter(
        (e) => e?.node?.status != ClientOfferStatusEnum.AVAILABLE
    );
    const usedOffersExpertsIds = usedOffers?.map((o) => o?.node?.expertId);
    const {isDesktop, isLargeDesktop} = useDeviceQuery();
    const {loading, error, data, refetch} = useExpertsDetailsWithLastReview({
        pollInterval: 60000,
    });

    const carousel = useRef<ICarouselInstance | null>(null);

    function previous() {
        carousel?.current?.prev();
    }

    function next() {
        carousel?.current?.next();
    }

    useEffect(() => {
        if (error) {
            setTimeout(refetch, 2000);
        }
    }, [error]);

    const renderExpertCard = ({item: expert}: {item: ExpertWithLastReview}) => {
        const canUseACoupon = !usedOffersExpertsIds?.includes(expert.userId);
        return (
            <ExpertCard
                user={expert}
                key={expert?.id}
                style={
                    props.horizontalScroll
                        ? {
                              display: 'flex',
                              marginTop: 0,
                              marginBottom: 0,
                              marginHorizontal: 10,
                          }
                        : {}
                }
                medium={props.medium}
                onAuthRequired={props.onAuthRequired}
                onPaymentMethodRequired={props.onPaymentMethodRequired}
                onCallStarted={props.onCallStarted}
                onCommunicationPressed={() => props.onExpertSelected?.(expert)}
                queryParams={props.queryParams}
                onCancelPayment={props.onCancelPayment}
                paymentIntent={props.paymentIntent}
                paymentIntentSecret={props.paymentIntentSecret}
                freeCall={props.freeCall}
                canUseACoupon={canUseACoupon}
            />
        );
    };

    if (error || (loading && !data)) {
        return <></>;
    }

    const allExperts = filterEmpty(data?.findExperts?.edges.map((e) => e?.node));
    let experts = filterEmpty(allExperts); // For local dev
    // let experts = filterIncompleteProfile(allExperts);

    experts = experts.filter(
        (e) =>
            e.expert?.callStatus != UNAVAILABLE &&
            (e.callParameters?.price?.priceDecimal ?? 0) > 0
    );
    if (props.freeCall) {
        experts = experts.filter((e) => !usedOffersExpertsIds?.includes(e.userId));
    }
    experts = sortExperts(experts, null, null, '-1');

    if (experts.length < 1) {
        logEvent('call_landing_page_no_available_expert');
        return (
            <>
                <SimpleText style={styles.warningMessage}>
                    Oups aucun expert n'est disponible pour le moment, revenez plus tard.
                </SimpleText>
                <View>
                    <SimpleText style={styles.warningMessage}>
                        Si votre question est urgente, vous pouvez contacter notre service
                        Audiotel ouvert 24h/24 via ce numéro :
                    </SimpleText>
                    <AudiotelImageLink />
                </View>
            </>
        );
    }

    const carouselHorizontalMargin = 20;
    const carouselItemWidth = CARD_WIDTH + carouselHorizontalMargin;
    const width = carouselItemWidth * (isLargeDesktop ? 3 : 2);
    const margin = isDesktop ? 50 : 5;

    if (props.horizontalScroll) {
        if (isDesktop) {
            return (
                <View style={styles.carousel}>
                    <Pressable onPress={previous} style={styles.carouselIcon}>
                        <Icons.Return
                            size={40}
                            color={Colors.secondary}
                            style={{
                                marginRight: margin,
                                marginLeft: 5,
                            }}
                        />
                    </Pressable>
                    <Carousel
                        width={carouselItemWidth}
                        height={CARD_HEIGHT + 20}
                        data={experts}
                        renderItem={renderExpertCard}
                        ref={carousel}
                        style={{width: width}}
                    />
                    <Pressable onPress={next} style={styles.carouselIcon}>
                        <Icons.Next
                            size={40}
                            color={Colors.secondary}
                            style={{
                                marginLeft: margin,
                                marginRight: 5,
                            }}
                        />
                    </Pressable>
                </View>
            );
        }
        return (
            <View
                style={
                    isDesktop
                        ? styles.desktopScrollViewContainer
                        : styles.mobileScrollViewContainer
                }
            >
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={isDesktop}
                    style={styles.horizontalExpertContainer}
                >
                    {experts.map((e) => renderExpertCard({item: e}))}
                </ScrollView>
            </View>
        );
    } else {
        return (
            <View style={styles.expertContainer}>
                {experts.map((e) => renderExpertCard({item: e}))}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    expertContainer: {
        flex: 1,
        marginTop: 10,
        justifyContent: 'space-around',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    horizontalExpertContainer: {
        marginTop: 10,
        flexDirection: 'row',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        borderRadius: 10,
        padding: 10,
        maxWidth: 900,
    },
    warningMessage: {
        alignSelf: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        maxWidth: 600,
        marginBottom: 10,
    },
    mobileScrollViewContainer: {flex: 1},
    desktopScrollViewContainer: {flex: 1, alignItems: 'center'},

    carousel: {
        marginTop: 25,
        flexDirection: 'row',
        alignSelf: 'center',
    },
    carouselIcon: {
        alignSelf: 'center',
    },
});
