import * as React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {LinearGradient} from 'expo-linear-gradient';

import * as Icons from '~/components/common/Icons';
import LandingPageHeader from '~/components/landingPage/Header';
import * as Colors from '~/constants/Colors';
import {LANDING_PAGE_PATHS} from '~/constants/LandingPageSettings';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import * as RootNavigation from '~/navigation';

import {Logo} from './navigation/Logo';
import {SocialNetwork} from './navigation/SocialNetwork';
import {UserInfo} from './navigation/UserInfo';

export type HeaderProps = {};

export default function Header() {
    const currentUrl = document.location.href;
    if (LANDING_PAGE_PATHS.some((lpUrl) => currentUrl.indexOf(lpUrl) != -1)) {
        return <LandingPageHeader />;
    }

    const {isDesktop} = useDeviceQuery();
    return isDesktop ? <DesktopHeader /> : <MobileHeader />;
}

function DesktopHeader() {
    return (
        <>
            <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={Colors.rainbow}>
                <View style={styles.header}>
                    <View
                        style={{
                            paddingVertical: 20,
                            alignSelf: 'center',
                        }}
                    >
                        <Logo
                            color={'white'}
                            titleFontSize={35}
                            subtitleFontSize={12}
                            logoWidth={240}
                            separatorWidth={'20%'}
                        />
                    </View>
                    <View
                        style={{
                            paddingVertical: 20,
                            alignSelf: 'center',
                        }}
                    >
                        <SocialNetwork />
                    </View>
                    <UserInfo />
                </View>
            </LinearGradient>
        </>
    );
}

function MobileHeader() {
    return (
        <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={Colors.rainbow}>
            <View style={styles.mobileHeader}>
                <Burger />
                <View style={styles.center}>
                    <Logo
                        color={'white'}
                        titleFontSize={30}
                        subtitleFontSize={12}
                        logoWidth={230}
                        separatorWidth={'15%'}
                    />
                </View>
            </View>
        </LinearGradient>
    );
}

function Burger() {
    return (
        <TouchableOpacity onPress={() => RootNavigation.toggleDrawer()}>
            <Icons.Burger size={25} style={{paddingTop: 10, position: 'absolute'}} />
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: '6%',
        // paddingVertical: 20,
        height: 100,
    },
    mobileHeader: {
        justifyContent: 'center',
        height: 75,
        paddingHorizontal: '6%',
        paddingVertical: 15,
    },
    center: {
        alignSelf: 'center',
    },
});
