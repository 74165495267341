import React, {useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import {Bloc} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import {getCurrentMonthBenefit} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function BlocBenefit() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();
    const {me} = React.useContext(UserContext);
    const [benefit, setBenefit] = useState<string | null>(null);

    useEffect(() => {
        async function getBenefit() {
            const b = await getCurrentMonthBenefit();
            setBenefit(b);
        }
        getBenefit();
    }, [me]);

    function goToHistoric() {
        navigation.navigate('HistoricScreen');
    }

    if (!me) {
        return <></>;
    }

    return (
        <Bloc title="Mon solde" iconName={'credit-card'}>
            <View style={styles.container}>
                <SimpleText style={styles.text}>Votre solde en cours est de</SimpleText>
                <SimpleText style={styles.benefit}>{benefit} €</SimpleText>
            </View>
            {!isDesktop && (
                <TouchableOpacity style={styles.linkContainer} onPress={goToHistoric}>
                    <SimpleText style={styles.link}>
                        Voir mon historique de consultation
                    </SimpleText>
                </TouchableOpacity>
            )}
        </Bloc>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    text: {
        fontSize: 14,
        alignSelf: 'center',
    },
    benefit: {
        paddingTop: 5,
        fontSize: 20,
        alignSelf: 'center',
    },
    linkContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    link: {
        fontSize: 12,
        marginTop: 5,
        color: Colors.link,
    },
});
