import React, {useRef} from 'react';
import {StyleSheet, View, Image, ImageBackground, Pressable, ScrollView} from 'react-native';

import Carousel, {ICarouselInstance} from 'react-native-reanimated-carousel';

import * as Icons from '~/components/common/Icons';
import {Separator} from '~/components/common/Separator';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {
    GetPackQuery_getPacks_edges_node,
    GetPackQuery_getPacks_edges_node as Pack,
} from '~/queries/types/GetPackQuery';
import {useUserPacks} from '~/queries/usePack';
import {PackType} from '~/types/graphql-global-types';

const NB_PACKS_SMALL_DESKTOP = 2;
const NB_PACKS_DESKTOP = 4;
const NB_PACKS_LARGE_DESKTOP = 5;

export type {Pack};
export type PackListProps = {
    onPress: (p: Pack) => void;
};

export default function PackList(props: PackListProps) {
    const {isDesktop, isLargeDesktop, isTouchDevice} = useDeviceQuery();
    const {packs} = useUserPacks({
        showPacks: 'all',
    });

    function renderPack({item}: {item: GetPackQuery_getPacks_edges_node}) {
        return <PackView key={item.packId} pack={item} onPress={props.onPress} />;
    }

    const availablePacks = packs.filter((pack) => pack?.packType == PackType.MINUTE);
    const carouselAvailablePacks = [...availablePacks, ...availablePacks];

    const carousel = useRef<ICarouselInstance | null>(null);
    const carouselHorizontalMargin = PACK_MARGIN * 2;
    const carouselItemWidth = PACK_CARD_WIDTH + carouselHorizontalMargin;
    let maxNbPacks = isLargeDesktop ? NB_PACKS_LARGE_DESKTOP : NB_PACKS_DESKTOP;
    if (!isTouchDevice && !isDesktop) {
        maxNbPacks = NB_PACKS_SMALL_DESKTOP;
    }
    const nbDisplayedPacks = Math.min(availablePacks.length, maxNbPacks);
    const width = carouselItemWidth * nbDisplayedPacks;
    const margin = isDesktop ? 50 : 5;

    function previous() {
        carousel?.current?.prev();
    }

    function next() {
        carousel?.current?.next();
    }
    if (nbDisplayedPacks == availablePacks.length) {
        return (
            <View style={styles.carousel}>
                {availablePacks.map((p) => renderPack({item: p}))}
            </View>
        );
    }
    if (isDesktop || !isTouchDevice) {
        return (
            <View style={styles.carousel}>
                <Pressable onPress={previous} style={styles.carouselIcon}>
                    <Icons.Return
                        size={40}
                        color={Colors.secondary}
                        style={{
                            marginRight: margin,
                            marginLeft: 5,
                        }}
                    />
                </Pressable>
                <Carousel
                    width={carouselItemWidth}
                    height={PACK_CARD_HEIGHT + 2 * PACK_MARGIN + 20}
                    data={carouselAvailablePacks}
                    renderItem={renderPack}
                    ref={carousel}
                    style={{width: width}}
                    autoFillData
                />
                <Pressable onPress={next} style={styles.carouselIcon}>
                    <Icons.Next
                        size={40}
                        color={Colors.secondary}
                        style={{
                            marginLeft: margin,
                            marginRight: 5,
                        }}
                    />
                </Pressable>
            </View>
        );
    }
    return (
        <View
            style={
                isDesktop
                    ? styles.desktopScrollViewContainer
                    : styles.mobileScrollViewContainer
            }
        >
            <ScrollView
                horizontal
                showsHorizontalScrollIndicator={isDesktop}
                style={styles.horizontalExpertContainer}
            >
                {availablePacks.map((p) => renderPack({item: p}))}
            </ScrollView>
        </View>
    );
}

export function PackView(props: {pack: Pack | null; onPress?: (p: Pack) => void}) {
    const typeText =
        props.pack?.packType == PackType.MINUTE ? 'Par chat ou appel' : 'Par email';

    const mainText =
        props.pack?.packType == PackType.MINUTE
            ? `${props.pack?.minutes} minutes`
            : 'Étude complète';

    let url = Settings.getUrlBestSeller();
    if (props.pack?.decorationId) {
        url = Settings.getUrlDecoration(props.pack?.decorationId);
    }

    const onPress = () => {
        if (props.pack) {
            props.onPress?.(props.pack);
        }
    };

    return (
        <Pressable
            onPress={onPress}
            key={props.pack?.packId}
            accessibilityLabel={props.pack?.decorationId ? 'HOVER_TEST' : ''}
        >
            <PackBack pack={props.pack}>
                <>
                    {props.pack?.bestSeller && (
                        <Image
                            source={{uri: url}}
                            style={{
                                position: 'absolute',
                                right: '-9px',
                                top: '-9px',
                                width: 80,
                                height: 80,
                            }}
                            accessibilityLabel={'Best seller'}
                        />
                    )}
                    <SimpleText style={styles.title}>{props.pack?.name}</SimpleText>
                    <View style={styles.frame}>
                        <View style={styles.bloc}>
                            <SimpleText style={styles.minute}>{mainText}</SimpleText>
                            <View style={styles.container}>
                                <SimpleText style={styles.short}>
                                    {props.pack?.shortDescription}
                                </SimpleText>
                            </View>
                            <SimpleText style={styles.type}>{typeText}</SimpleText>
                            <Separator style={styles.separator} />
                            <SimpleText style={styles.price}>
                                {props.pack?.amount} €
                            </SimpleText>
                        </View>
                        {props.pack?.packType == PackType.MINUTE && (
                            <SimpleText style={styles.economy}>
                                Soit une économie de {props.pack?.economy} €
                            </SimpleText>
                        )}
                        {props.pack?.packType == PackType.EMAIL && (
                            <SimpleText style={styles.economy}>
                                Forfait {props.pack?.name}
                            </SimpleText>
                        )}
                    </View>
                </>
            </PackBack>
        </Pressable>
    );
}

type PackBackProps = {
    pack: Pack | null;
    children: JSX.Element;
};

function PackBack(props: PackBackProps) {
    const decorationId = props.pack?.decorationId;

    if (decorationId) {
        return (
            <ImageBackground
                style={styles.back}
                imageStyle={{borderRadius: 8}}
                source={{
                    uri: Settings.getUrlDecorationBack(decorationId),
                }}
                accessibilityLabel={decorationId}
            >
                {props.children}
            </ImageBackground>
        );
    }

    return (
        <View style={[styles.back, styles.gold]} accessibilityLabel="HOVER_PACK">
            {props.children}
        </View>
    );
}

const PACK_CARD_WIDTH = 195;
const PACK_CARD_HEIGHT = 320;
const PACK_MARGIN = 10;

const styles = StyleSheet.create({
    list: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        marginVertical: 30,
    },
    back: {
        width: PACK_CARD_WIDTH,
        height: PACK_CARD_HEIGHT,
        resizeMode: 'contain',
        margin: PACK_MARGIN,
        borderRadius: 8,
        alignSelf: 'center',
    },
    gold: {
        backgroundColor: Colors.primary,
    },
    light: {
        backgroundColor: 'rgb(186, 174, 146)',
    },
    frame: {
        backgroundColor: 'white',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginHorizontal: 10,
        marginBottom: 10,
        paddingVertical: 10,
    },
    bloc: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        alignSelf: 'center',
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        marginVertical: 20,
    },
    minute: {
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    short: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
        width: 150,
    },
    type: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
    },
    separator: {
        backgroundColor: Colors.highlight,
        marginVertical: 15,
        height: 2,
        alignSelf: 'center',
        width: '40%',
    },
    price: {
        fontSize: 28,
        fontWeight: 'bold',
        backgroundImage: '-webkit-linear-gradient(left, #4bc0c8, #c779d0, #feac5e)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        alignSelf: 'center',
        textAlign: 'center',
    },
    economy: {
        fontSize: 10,
        fontStyle: 'italic',
        alignSelf: 'center',
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    text: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        paddingHorizontal: 5,
        marginTop: 10,
    },
    horizontalExpertContainer: {
        marginTop: 10,
        flexDirection: 'row',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        borderRadius: 10,
        padding: 10,
        maxWidth: 900,
    },
    mobileScrollViewContainer: {flex: 1},
    desktopScrollViewContainer: {flex: 1, alignItems: 'center'},
    carousel: {
        marginTop: 25,
        flexDirection: 'row',
        alignSelf: 'center',
    },
    carouselIcon: {
        alignSelf: 'center',
    },
});
