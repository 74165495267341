import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

import {TouchableOpacity} from 'react-native-gesture-handler';

import * as Colors from '~/constants/Colors';

import * as Icons from './Icons';
import {SimpleText, TextProps} from './Texts';

export function HeaderRow({style, ...viewProps}: HeaderRowProps) {
    return <View style={[styles.headersContainer, style]} {...viewProps} />;
}

export function Row({style, ...viewProps}: HeaderRowProps) {
    return <View style={[styles.lineContainer, style]} {...viewProps} />;
}

export function Cell({style, large, ...props}: CellProps) {
    const baseStyle = large ? styles.cellLarge : styles.cellSmall;
    return <SimpleText style={[baseStyle, style]} {...props} />;
}

export function ViewCell({style, large, ...props}: ViewCellProps) {
    const baseStyle = large ? styles.cellLarge : styles.cellSmall;
    return <View style={[baseStyle, style]} {...props} />;
}

export function HeaderCell(props: HeaderCellProps) {
    const {label, large, center, onPressHeader, field, init, orderField, ...viewProps} = props;
    const {style, ...otherProps} = viewProps;
    const baseStyle = large ? styles.cellLarge : styles.cellSmall;
    const [filterAsc, setFilterAsc] = useState(false);
    const [filterInit, setFilterInit] = useState(init);

    function onPress() {
        let newFilterAsc = filterAsc;

        if (!filterInit) {
            setFilterInit(true);
            newFilterAsc = false;
        } else {
            newFilterAsc = !filterAsc;
        }

        setFilterAsc(newFilterAsc);
        onPressHeader(field, newFilterAsc ? 'asc' : 'desc');
    }

    const isActivated = orderField == field && filterInit;

    return (
        <TouchableOpacity onPress={onPress}>
            <View style={[baseStyle, style]} {...otherProps}>
                <View
                    style={[
                        styles.cellContainer,
                        {alignSelf: center ? 'center' : 'flex-start'},
                    ]}
                >
                    <SimpleText style={[styles.cellHeader]}>{label}</SimpleText>
                    <SimpleText style={styles.arrows}>
                        <Icons.SortUp
                            color={isActivated && filterAsc ? 'Inherited' : Colors.opacity}
                            style={{position: 'absolute', top: 0}}
                            size={18}
                        />
                        <Icons.SortDown
                            color={isActivated && !filterAsc ? 'Inherited' : Colors.opacity}
                            style={{position: 'absolute', top: 0}}
                            size={18}
                        />
                    </SimpleText>
                </View>
            </View>
        </TouchableOpacity>
    );
}

export type HeaderRowProps = View['props'];
export type HeaderCellProps = View['props'] & {
    label: string;
    onPressHeader: CellPressHeaderCallback;
    field: string;
    init: boolean;
    orderField: string;
    large?: boolean;
    center?: boolean;
};
export type CellProps = TextProps & {large?: boolean};
export type ViewCellProps = View['props'] & {large?: boolean};
export type CellPressHeaderCallback = (field: string, value: string) => void;

const styles = StyleSheet.create({
    headersContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 25,
        paddingBottom: 20,
        borderBottomWidth: 0.3,
        borderBottomColor: Colors.separator,
        justifyContent: 'space-around',
    },
    lineContainer: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 0.3,
        borderBottomColor: Colors.separator,
        minHeight: 50,
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    cellContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    arrows: {
        flex: 1,
        flexDirection: 'column',
        marginLeft: 15,
    },
    cellHeader: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    cellSmall: {
        width: 120,
    },
    cellLarge: {
        width: 200,
    },
});
