import {StyleSheet, View} from 'react-native';

import * as Colors from '~/constants/Colors';

import {SimpleText} from '../common/Texts';
import PromoBadge from './PromoBadge';

export type PackPromotionItem = {
    minutes: number | null;
    amount: number;
};

export default function PromoPackView({pack}: {pack: PackPromotionItem | null}) {
    if (pack == null) {
        return (
            <SimpleText style={styles.error}>
                Erreur lors du chargement de votre offre.
            </SimpleText>
        );
    }

    if (pack.minutes == null) {
        return (
            <SimpleText style={styles.error}>
                L'offre que vous consultez n'est plus disponible.
            </SimpleText>
        );
    }

    return (
        <>
            <SimpleText style={styles.promoText}>Offre exceptionnelle !</SimpleText>
            <SimpleText style={styles.promoText2}>
                {pack.minutes} minutes de voyance à moitié prix*
            </SimpleText>
            <View style={styles.promoPricesView}>
                <SimpleText style={styles.promoPriceTextBefore}>{pack.amount * 2}€</SimpleText>
                <SimpleText style={styles.promoPriceTextAfter}>{pack.amount}€</SimpleText>
                <View style={styles.promoBadgeView}>
                    <PromoBadge text="-50%" />
                </View>
            </View>
        </>
    );
}

export function PromoPriceNotice() {
    return (
        <SimpleText style={[styles.promoText2, {fontStyle: 'italic'}]}>
            *Dans le cas où vous n'utilisez pas toutes vos minutes, retrouvez votre solde dans
            votre compte client sur Kyvoitou.fr. Vous pourrez ainsi les réutiliser avec le
            médium de votre choix.
        </SimpleText>
    );
}

const styles = StyleSheet.create({
    promoBadgeView: {
        position: 'absolute',
        left: 100,
    },
    promoPriceTextBefore: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 3,
        textDecorationLine: 'line-through',
    },
    promoPriceTextAfter: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: Colors.cta,
    },
    promoPricesView: {
        alignSelf: 'center',
        justifyContent: 'center',
        marginVertical: 30,
    },
    promoText2: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 16,
        maxWidth: 600,
    },
    promoText: {
        alignSelf: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 0,
        maxWidth: 600,
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
});
