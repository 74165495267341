import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {useNavigation} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {Bloc} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {Switch} from '~/components/common/Switch';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {UserContext} from '~/contexts/UserContext';
import {AVAILABLE, PAUSE, ServiceEnum} from '~/helpers/experts';
import {axios} from '~/helpers/network';

import {
    changeStatus,
    changeStatus_changeStatus as MutationResponse,
    changeStatus_changeStatus_expert as MutationExpert,
} from './types/changeStatus';
import {pauseStatus} from './types/pauseStatus';

const CHANGE_STATUS = gql`
    mutation changeStatus($status: Boolean!, $service: String!) {
        changeStatus(input: {status: $status, service: $service}) {
            expert {
                id
                callStatus
                messageStatus
                chatStatus
            }
            status
            service
        }
    }
`;

const PAUSE_STATUS = gql`
    mutation pauseStatus($status: Boolean!) {
        pauseStatus(input: {status: $status}) {
            expert {
                id
                callStatus
                messageStatus
                chatStatus
            }
            status
        }
    }
`;

export default function BlocStatus() {
    const {loadingMe, me} = React.useContext(UserContext);
    const {play} = useAudio();
    const alert = useAlert();
    const navigation = useNavigation();
    const [changeStatus, {data: dataChange}] = useMutation<changeStatus>(CHANGE_STATUS, {
        errorPolicy: 'all',
    });
    const [pauseStatus, {data: dataPause}] = useMutation<pauseStatus>(PAUSE_STATUS, {
        errorPolicy: 'all',
    });
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (me) {
            setIsPaused(me?.expert?.callStatus == PAUSE || me?.expert?.chatStatus == PAUSE);
        }
    }, [me]);

    function goToProfile() {
        navigation.navigate('AccountScreen');
    }

    useEffect(() => {
        if (dataChange) {
            if (!dataChange.changeStatus?.status) {
                const body = JSON.stringify({service: dataChange?.changeStatus?.service});
                axios.post(Settings.closeWaitingList(), body);
            } else {
                play(require('../../../../assets/sound/ping.mp3'));
            }
            setIsLoading(false);
        }
    }, [dataChange]);

    async function setAvailable(isAvailable: boolean, service: ServiceEnum) {
        const variables = {
            status: isAvailable,
            service: service,
        };
        setIsLoading(true);
        changeStatus({variables});
    }

    useEffect(() => {
        if (dataPause) {
            if (dataPause.pauseStatus?.status) {
                play(require('../../../../assets/sound/ping.mp3'));
            }
            setIsPaused(!dataPause.pauseStatus?.status ?? false);
            setIsLoading(false);
        }
    }, [dataPause]);

    async function pause() {
        const variables = {
            status: isPaused,
        };

        pauseStatus({variables});
    }

    function checkSound() {
        alert({
            message:
                'Afin de vérifier que les sonneries fonctionnent, nous allons jouer un son',
            buttonText: 'Tester le son',
            onClose: () => play(require('../../../../assets/sound/ping.mp3')),
        });
    }

    if (loadingMe && !me) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    function availabilityText(status: string | null | undefined, isDisabled: boolean) {
        if (isDisabled) {
            return 'Tarif manquant';
        }
        if (status == AVAILABLE) {
            return 'Disponible';
        }
        if (status == PAUSE) {
            return 'Occupé';
        }
        return 'Non disponible';
    }

    function availabilityStyle(status: string | null | undefined) {
        if (status == AVAILABLE) {
            return styles.available;
        }
        if (status == PAUSE) {
            return styles.pause;
        }
        return styles.unavailable;
    }

    const isCallAvailable = me?.expert?.callStatus == AVAILABLE;
    const isCallDisable = me?.callParameters == null;
    const textCall = availabilityText(me?.expert?.callStatus, isCallDisable);

    const isMessageAvailable = me?.expert?.messageStatus == AVAILABLE;
    const isMessageDisable = me?.messageParameters?.edges?.length == 0;
    const textMessage = availabilityText(me?.expert?.messageStatus, isMessageDisable);

    const isChatAvailable = me?.expert?.chatStatus == AVAILABLE;
    const isChatDisable = me?.chatParameters == null;
    const textChat = availabilityText(me?.expert?.chatStatus, isChatDisable);

    return (
        <Bloc title="Mon Statut" iconName="user-clock">
            <View style={styles.sound}>
                <TouchableOpacity onPress={checkSound}>
                    <Icons.Sound size={16} color={Colors.pause} />
                </TouchableOpacity>
            </View>
            <View style={styles.bloc_container}>
                <View style={styles.container}>
                    <SimpleText style={styles.text}>Par téléphone</SimpleText>
                    <Switch
                        onValueChange={(itemValue) => {
                            setAvailable(itemValue, ServiceEnum.CALL);
                        }}
                        value={isCallAvailable}
                        style={styles.switch}
                        disabled={isCallDisable || isLoading || loadingMe || isPaused}
                    />
                    <SimpleText style={availabilityStyle(me?.expert?.callStatus)}>
                        {textCall}
                    </SimpleText>
                </View>
                <View style={styles.container}>
                    <SimpleText style={styles.text}>Par tchat</SimpleText>
                    <Switch
                        onValueChange={(itemValue) => {
                            setAvailable(itemValue, ServiceEnum.CHAT);
                        }}
                        value={isChatAvailable}
                        style={styles.switch}
                        disabled={isChatDisable || isLoading || loadingMe || isPaused}
                    />
                    <SimpleText style={availabilityStyle(me?.expert?.chatStatus)}>
                        {textChat}
                    </SimpleText>
                </View>
                <View style={styles.container}>
                    <SimpleText style={styles.text}>Par message</SimpleText>
                    <Switch
                        onValueChange={(itemValue) => {
                            setAvailable(itemValue, ServiceEnum.MESSAGE);
                        }}
                        value={isMessageAvailable}
                        style={styles.switch}
                        disabled={isMessageDisable || isLoading || loadingMe}
                    />
                    <SimpleText style={availabilityStyle(me?.expert?.messageStatus)}>
                        {textMessage}
                    </SimpleText>
                </View>
            </View>
            <Button
                title={isPaused ? 'Retour' : 'Pause'}
                buttonStyle={styles.back}
                onPress={pause}
                loading={isLoading || loadingMe}
            />
            <TouchableOpacity style={styles.link_container} onPress={goToProfile}>
                <SimpleText style={styles.link}>
                    Modifier les informations de mon compte
                </SimpleText>
            </TouchableOpacity>
        </Bloc>
    );
}

const styles = StyleSheet.create({
    bloc_container: {
        backgroundColor: 'white',
        marginTop: 15,
        marginHorizontal: 15,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        margin: 16,
    },
    titleTextContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    picto: {
        width: 24,
        height: 19,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        textTransform: 'uppercase',
        color: Colors.secondary,
        alignSelf: 'center',
    },
    text: {
        width: 110,
    },
    switch: {
        width: 40,
    },
    available: {
        color: Colors.nice,
        width: 110,
    },
    unavailable: {
        color: Colors.bad,
        width: 110,
    },
    pause: {
        color: Colors.pause,
        width: 110,
    },
    link_container: {
        flex: 1,
        flexDirection: 'row-reverse',
        paddingBottom: 15,
        paddingRight: 20,
        paddingLeft: 20,
    },
    link: {
        fontSize: 12,
        color: Colors.link,
    },
    sound: {
        width: 20,
        height: 20,
        position: 'absolute',
        top: 15,
        right: 15,
    },
    back: {
        width: 150,
        alignSelf: 'center',
    },
});
