import React, {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';

import {
    CommonActions,
    RouteProp,
    StackActions,
    useNavigation,
    useRoute,
} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import {axios} from '~/helpers/network';
import usePackOrder from '~/queries/usePackOrder';
import {PackParamList} from '~/types';

export default function CancelScreen() {
    const route = useRoute<RouteProp<PackParamList, 'CancelScreen'>>();
    const sessionId = route.params?.sessionId;

    const {data: dataOrder} = usePackOrder(null, sessionId);

    const order = dataOrder?.getPackOrder;

    // console.log('dataOrder = ', dataOrder);

    useMemo(() => {
        const body = JSON.stringify({
            sessionId: sessionId,
        });
        axios.post(endpoints.cancelOrder, body);
    }, [sessionId]);

    const navigation = useNavigation();

    async function goToPack() {
        navigation.navigate('PackScreen');
    }

    async function goToHome() {
        navigation.navigate('HomeScreen');
    }

    return (
        <MainView>
            <PageTitle>Votre commande a bien été annulée</PageTitle>
            <SimpleText style={styles.title}>
                Suite à une action de votre part ou une erreur lors du paiement, votre commande
                a été annulée.
            </SimpleText>
            {dataOrder && (
                <SimpleText style={styles.reference}>
                    Numéro de commande : {order?.packOrderId}
                </SimpleText>
            )}
            <View style={styles.action}>
                <Button
                    titleStyle={{fontSize: 16, marginHorizontal: 20}}
                    title={'Voir les packs'}
                    onPress={goToPack}
                />
                <Button
                    titleStyle={{fontSize: 16, marginHorizontal: 20}}
                    title={"Aller à l'accueil"}
                    onPress={goToHome}
                />
            </View>
        </MainView>
    );
}

const styles = StyleSheet.create({
    action: {
        marginTop: 30,
    },
    title: {
        color: Colors.secondary,
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        alignSelf: 'center',
    },
    reference: {
        color: Colors.secondary,
        fontSize: 10,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 25,
    },
});
