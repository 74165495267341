import {Bold, SimpleText} from '../common/Texts';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';
import {styles} from './styles';

export function Title() {
    const {isExpertListStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const isVisible = isExpertListStep || pageVersion == 2;
    if (!isVisible) {
        return null;
    }
    return (
        <SimpleText accessibilityRole="header" aria-level="1" style={styles.title}>
            Une voyance juste à un prix juste !
        </SimpleText>
    );
}

export function PromoText() {
    const {isExpertListStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const isVisible = isExpertListStep || pageVersion == 2;
    if (!isVisible) {
        return null;
    }
    return (
        <>
            <SimpleText style={styles.promoParagraph}>
                Profitez d'une voyance avec des <Bold>médiums et thérapeutes reconnus </Bold>
                pour un <Bold>prix très spécial</Bold>.
            </SimpleText>
            <SimpleText style={styles.promoParagraph}>
                Vous n'êtes plus qu'à trois étapes de prendre les bonnes décisions. N'attendez
                plus, choisissez votre voyant et{' '}
                <Bold>obtenez enfin la vie que vous méritez !</Bold>
            </SimpleText>
        </>
    );
}
