import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import {Bloc} from '~/components/common/Containers';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';

export default function BlocAccount() {
    const {loadingMe, me} = React.useContext(UserContext);
    const navigation = useNavigation();

    function goToProfile() {
        navigation.navigate('AccountScreen');
    }

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    return (
        <Bloc title="Mon compte" iconName="user">
            <View style={styles.container}>
                <View style={styles.detailContainer}>
                    <SimpleText numberOfLines={1}>{me?.identity?.email}</SimpleText>
                    <SimpleText style={styles.phone}>{me?.identity?.phone}</SimpleText>
                </View>
                <View>
                    <TouchableOpacity onPress={goToProfile}>
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={me?.profile?.pictureName}
                            displayName={me?.profile?.displayName}
                        />
                    </TouchableOpacity>
                </View>
            </View>
            <TouchableOpacity style={styles.link_container} onPress={goToProfile}>
                <SimpleText style={styles.link}>
                    Modifier les informations de mon compte
                </SimpleText>
            </TouchableOpacity>
        </Bloc>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16,
        height: 130,
        borderRadius: 5,
    },
    detailContainer: {padding: 10, width: 188},
    phone: {marginTop: 10},
    profilePicture: {
        alignSelf: 'flex-end',
        backgroundColor: Colors.primary,
        borderColor: Colors.primary,
        borderWidth: 3,
        margin: 10,
        width: 100,
        height: 100,
        borderRadius: 5,
    },
    link_container: {},
    link: {
        fontSize: 12,
        color: Colors.link,
        marginBottom: 11,
        marginRight: 16,
        alignSelf: 'flex-end',
    },
});
