import AsyncStorage from '@react-native-async-storage/async-storage';

import {isExpired, setExpiration} from './storage';

const COOKIE_EXPIRATION = 60 * 24 * 30 * 6;
const COOKIE_EXPIRATION_KEY = 'COOKIE_EXPIRE';
const COOKIE_KEY = 'COOKIE_KEY';

export async function getAcceptation() {
    const expired = await isExpired(COOKIE_EXPIRATION_KEY);
    if (expired) {
        return null;
    }

    return await AsyncStorage.getItem(COOKIE_KEY);
}

export async function setAcceptation(acceptation: string) {
    await AsyncStorage.setItem(COOKIE_KEY, acceptation);
    await setExpiration(COOKIE_EXPIRATION_KEY, COOKIE_EXPIRATION);
}
