import {LinkingOptions} from '@react-navigation/native';
import * as Linking from 'expo-linking';

import {landingPageLinkingConfig} from '~/constants/LandingPageSettings';
import {RootStackParamList} from '~/types';

// https://reactnavigation.org/docs/configuring-links/

export default {
    prefixes: [Linking.makeUrl('/')],
    config: {
        screens: {
            ...landingPageLinkingConfig,
            Root: {
                screens: {
                    Home: {
                        screens: {
                            HomeScreen: 'home',
                            LoginScreen: 'me-connecter',
                            RegisterScreen: 'creer-mon-compte',
                            ForgottenPasswordScreen: 'mot-de-passe-oublie',
                            HomeExpertScreen: {
                                path: 'home/:url',
                            },
                            PackScreen: {
                                screens: {
                                    PackScreen: 'nos-packs',
                                    BasketScreen: 'votre-panier',
                                    PaymentScreen: 'paiement',
                                    SuccessScreen: 'success',
                                    CancelScreen: 'cancel',
                                },
                            },
                        },
                    },
                    Search: {
                        screens: {
                            SearchScreen: {
                                path: 'recherche/:tag?',
                            },
                            ExpertScreen: {
                                path: 'expert/:url',
                            },
                        },
                    },
                    Information: {
                        screens: {
                            CommitmentScreen: 'nos-engagements',
                            FaqScreen: 'faq',
                            ContactScreen: 'contact',
                            CgvScreen: 'cgv',
                            CguScreen: 'cgu',
                            ApplyScreen: 'devenir-expert',
                            JobScreen: 'offre-emploi',
                            EthicScreen: 'charte-ethique',
                            LegalNoticeScreen: 'mentions-legales',
                        },
                    },
                    Profile: {
                        screens: {
                            ProfileScreen: 'profil',
                            MessageScreen: 'message',
                            HistoricScreen: 'historique',
                            AccountScreen: 'mon-compte',
                        },
                    },
                    HowToScreen: 'comment-ca-marche',
                    PriceScreen: 'nos-tarifs',
                    AboutScreen: 'qui-sommes-nous',
                },
            },
            NotFound: '*',
        },
    },
} as LinkingOptions<RootStackParamList>;
