import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';

import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {MOBILE_MAX_WIDTH} from '~/constants/Layout';
import {UserContext} from '~/contexts/UserContext';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {Logo} from '../navigation/Logo';

export function WelcomeMessage() {
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles : mobileStyles;

    return (
        <View style={deviceStyle.content}>
            <SimpleText style={deviceStyle.title} accessibilityRole="header" aria-level="1">
                Un coaching divinatoire pour prendre les plus belles décisions de votre vie !
            </SimpleText>
        </View>
    );
}

export function MobileWelcome() {
    return (
        <>
            <View style={mobileStyles.mobileBackground}>
                <SimpleText style={mobileStyles.mobileTitle}>Bienvenue sur</SimpleText>
                <Logo
                    color={Colors.secondary}
                    titleFontSize={42}
                    subtitleFontSize={14}
                    logoWidth={290}
                    separatorWidth={'22%'}
                />
            </View>
            <View style={mobileStyles.mobileBackground}>
                <View style={mobileStyles.mobileMessage}>
                    <MobileMessage />
                </View>
            </View>
        </>
    );
}

function MobileMessage() {
    const {me, loadingMe} = React.useContext(UserContext);
    const navigation = useNavigation();

    function onPressRegister() {
        navigation.navigate('RegisterScreen');
    }

    function onPressLogin() {
        navigation.navigate('LoginScreen');
    }

    if (loadingMe) {
        return <></>;
    }

    if (me) {
        return <></>;
    }

    return (
        <>
            <MobileSubscribeButton onPress={onPressRegister} />
            <SimpleText style={mobileStyles.text}>Vous avez déjà un compte ?</SimpleText>
            <TouchableOpacity onPress={onPressLogin}>
                <SimpleText style={mobileStyles.link}>Connectez-vous</SimpleText>
            </TouchableOpacity>
        </>
    );
}

type MobileSubscribeButton = {
    onPress: () => void;
};

function MobileSubscribeButton(props: MobileSubscribeButton) {
    return (
        <TouchableOpacity onPress={props.onPress}>
            <LinearGradient
                start={{x: 0, y: 0}}
                end={{x: 1, y: 0}}
                colors={Colors.rainbow}
                style={mobileStyles.button}
            >
                <SimpleText style={mobileStyles.register}>Créer un compte</SimpleText>
            </LinearGradient>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    banner: {
        alignSelf: 'center',
        width: '100%',
        height: 180,
    },
    content: {
        alignSelf: 'center',
    },
    welcome: {
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 20,
    },
    communication: {
        color: 'white',
        fontSize: 20,
        textAlign: 'center',
        alignSelf: 'center',
    },
    title: {
        alignSelf: 'center',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 40,
        marginTop: 5,
        maxWidth: MOBILE_MAX_WIDTH,
    },
});

const mobileStyles = StyleSheet.create({
    mobileBackground: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    mobileTitle: {
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
        fontWeight: 'bold',
        fontSize: 30,
        marginVertical: 25,
    },
    mobileMessage: {
        marginVertical: 50,
    },
    text: {
        marginTop: 50,
        alignSelf: 'center',
        color: Colors.secondary,
    },
    link: {
        marginTop: 10,
        fontSize: 15,
        fontWeight: 'bold',
        alignSelf: 'center',
        color: Colors.secondary,
        textDecorationLine: 'underline',
    },
    content: {
        padding: 25,
        alignSelf: 'center',
    },
    welcome: {
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        flexDirection: 'row',
        marginTop: 20,
    },
    communication: {
        color: Colors.secondary,
        fontSize: 18,
        textAlign: 'center',
    },
    title: {
        textAlign: 'center',
        alignSelf: 'center',
        color: Colors.secondary,
        fontWeight: 'bold',
        fontSize: 28,
    },
    register: {
        textAlign: 'center',
        color: 'white',
        fontSize: 14,
    },
    button: {
        borderRadius: 5,
        paddingHorizontal: 30,
        paddingVertical: 10,
    },
});
