import React, {useContext, useEffect, useState} from 'react';
import {View, StyleSheet, FlatList, TouchableOpacity} from 'react-native';

import {useFocusEffect, useNavigation} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {Bloc} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import {filterEmpty, sorted} from '~/helpers/list';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {GetMinuteHistory_getMinuteHistory_edges} from '~/queries/types/GetMinuteHistory';
import useMinuteBalance, {useMinuteHistory} from '~/queries/useMinuteBalance';
import {TransactionType} from '~/types/graphql-global-types';

export default function BlocMinute() {
    const navigation = useNavigation();
    const {isDesktop} = useDeviceQuery();
    const {me, refetchMinutes} = useContext(UserContext);
    const {data} = useMinuteBalance(me == null);

    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    function reload() {
        if (refetchMinutes) {
            refetchMinutes();
        }
    }

    function goToPack() {
        if (isDesktop) {
            navigation.navigate('PackScreen');
        } else {
            navigation.navigate('Main', {
                screen: 'Home',
                params: {screen: 'PackScreen'},
            });
        }
    }

    if (!me || !data) {
        return <></>;
    }

    const exp = data?.getMinuteBalance?.expireAt
        ? Date.parse(data?.getMinuteBalance?.expireAt)
        : null;
    const expire = exp ? new Date(exp) : null;
    const solde = data?.getMinuteBalance?.minutes ?? 0;

    return (
        <Bloc title={'Mon solde'} iconName="hourglass-start">
            <View style={styles.container}>
                <SimpleText style={styles.text}>Votre solde en cours est de</SimpleText>
                <SimpleText style={styles.minute}>{solde} minutes</SimpleText>
                {Boolean(expire) && (
                    <SimpleText style={styles.expireAt}>
                        Jusqu'au {expire?.toLocaleDateString()}
                    </SimpleText>
                )}
            </View>
            <View>
                <Button
                    icon={<Icons.Sync size={16} style={{paddingRight: 10, paddingTop: 2}} />}
                    title="Recharger"
                    buttonStyle={{padding: 4, width: 175}}
                    onPress={goToPack}
                    style={{marginTop: 5, padding: 0}}
                    titleStyle={{fontSize: 16}}
                />
            </View>
            <View style={styles.linkContainer}>
                <TouchableOpacity onPress={() => setPopupVisible(true)}>
                    <SimpleText style={styles.link}>Voir ma consommation</SimpleText>
                </TouchableOpacity>
            </View>
            <PopupTransaction visible={popupVisible} close={() => setPopupVisible(false)} />
        </Bloc>
    );
}

type PopupTransactionProps = {
    visible: boolean;
    close: () => void;
};

function PopupTransaction(props: PopupTransactionProps) {
    const {data, refetch} = useMinuteHistory();

    useEffect(() => {
        if (props.visible) {
            refetch();
        }
    }, [props.visible]);

    function getTime(t: GetMinuteHistory_getMinuteHistory_edges) {
        const time = Date.parse(t?.node?.created);
        const stamp = new Date(time);
        return stamp.getTime();
    }

    if (!data) {
        return <></>;
    }

    const noTransaction = data?.getMinuteHistory?.edges?.length == 0;

    const transactions = filterEmpty(data?.getMinuteHistory?.edges);

    const sortedTransactions = sorted(transactions, getTime, true);

    const hasExpiration = transactions.some(
        (s) => s.node?.transaction == TransactionType.EXPIRATION
    );

    return (
        <CustomModal
            visible={props.visible}
            iconName={'list-ol'}
            title={'Ma consommation'}
            onRequestClose={props.close}
            hasCloseIcon
        >
            {noTransaction && (
                <SimpleText style={styles.noTransaction}>
                    Aucune consommation pour l'instant.
                </SimpleText>
            )}
            {!noTransaction && (
                <FlatList
                    data={sortedTransactions}
                    renderItem={({item}: {item: GetMinuteHistory_getMinuteHistory_edges}) => (
                        <Transaction item={item} />
                    )}
                    keyExtractor={(item) => item.node?.created}
                    ListHeaderComponent={Header}
                />
            )}
            {hasExpiration && (
                <View style={styles.info}>
                    <Icons.Info
                        size={20}
                        color={Colors.secondary}
                        style={{alignSelf: 'center'}}
                    />
                    <SimpleText style={styles.warning}>
                        Lorsque votre solde est expiré, son total est automatiquement passé à 0
                        minutes.
                    </SimpleText>
                </View>
            )}
            <Button title="Fermer" onPress={props.close} />
        </CustomModal>
    );
}

function Header() {
    return (
        <View style={styles.head}>
            <SimpleText style={styles.header}>Date</SimpleText>
            <SimpleText style={styles.header}>Type</SimpleText>
            <SimpleText style={styles.header}>Montant</SimpleText>
            <SimpleText style={styles.header}>Validité</SimpleText>
        </View>
    );
}

type TransactionProps = {
    item: GetMinuteHistory_getMinuteHistory_edges;
};

function Transaction(props: TransactionProps) {
    function getLabel(type: TransactionType | null | undefined) {
        if (type == TransactionType.BUY) {
            return 'Achat';
        }
        if (type == TransactionType.SPEND) {
            return 'Dépense';
        }
        if (type == TransactionType.EXPIRATION) {
            return 'Expiration';
        }
        if (type == TransactionType.MANUAL) {
            return 'Régul.';
        }
    }

    const transaction = props.item.node;

    const createdAt = Date.parse(transaction?.created);
    const created = new Date(createdAt);

    const expireAt = Date.parse(transaction?.expireAt);
    const expire = new Date(expireAt);

    const positive = (transaction?.minutes ?? 0) > 0;

    return (
        <View style={styles.line}>
            <View>
                <SimpleText style={styles.date}>{created.toLocaleDateString()}</SimpleText>
                <SimpleText style={styles.hour}>{created.toLocaleTimeString()}</SimpleText>
            </View>
            <SimpleText style={styles.type}>{getLabel(transaction?.transaction)}</SimpleText>
            <SimpleText style={[styles.amount, positive ? styles.positive : styles.negative]}>
                {positive ? '+' : ''}
                {transaction?.minutes} min.
            </SimpleText>
            <SimpleText style={styles.expire}>
                {transaction?.expireAt ? expire.toLocaleDateString() : '-'}
            </SimpleText>
        </View>
    );
}

const styles = StyleSheet.create({
    noTransaction: {
        textAlign: 'center',
    },
    container: {
        alignItems: 'center',
    },
    text: {
        fontSize: 14,
        color: Colors.secondary,
    },
    minute: {
        fontSize: 18,
        color: Colors.secondary,
        marginTop: 10,
        marginBottom: 5,
    },
    expireAt: {
        fontSize: 10,
        color: Colors.secondary,
    },
    linkContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    link: {
        fontSize: 12,
        color: Colors.link,
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTopColor: Colors.secondary,
        borderTopWidth: 1,
        paddingVertical: 2,
    },
    date: {
        textAlign: 'center',
        width: 100,
    },
    hour: {
        textAlign: 'center',
        fontSize: 10,
        width: 100,
    },
    type: {
        textAlign: 'center',
        alignSelf: 'center',
        width: 100,
    },
    amount: {
        textAlign: 'center',
        alignSelf: 'center',
        width: 100,
    },
    positive: {
        color: Colors.pay,
    },
    negative: {
        color: Colors.spend,
    },
    expire: {
        textAlign: 'center',
        alignSelf: 'center',
        width: 100,
        fontSize: 12,
        color: Colors.fade,
    },
    head: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 5,
    },
    header: {
        fontWeight: 'bold',
        textAlign: 'center',
        width: 100,
    },
    info: {
        paddingTop: 20,
        flexDirection: 'row',
        maxWidth: 400,
        alignSelf: 'center',
    },
    warning: {
        textAlign: 'center',
        alignSelf: 'center',
        marginLeft: 15,
    },
});
