import {gql} from '@apollo/client';

export default gql`
    fragment Card on Stripe {
        id
        stripeId
        sessionId
        lastFourDigits
        isPaymentValidated
        country
        expirationMonth
        expirationYear
        paymentMethodId
        paymentType
        fingerprint
        created
    }
`;
