// https://stripe.com/docs/payments/save-and-reuse
// https://medium.com/codingtown/react-native-payment-using-stripe-part-1-c0c005eeda90
import * as React from 'react';
import {ActivityIndicator, View} from 'react-native';

import {Elements} from '@stripe/react-stripe-js';
import {BaseStripeElementsOptions, loadStripe} from '@stripe/stripe-js';
import Constants from 'expo-constants';

import {SimpleText} from '~/components/common/Texts';
import {useLandingPageNavigation, useLandingPageRoute} from '~/components/landingPage/route';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import {axios} from '~/helpers/network';

import {setupIntent} from './SetupIntent';
import {
    holdPaymentWithoutCard,
    holdPaymentWithoutCardData,
} from './SetupPaymentWithoutExistingCard';

// Doc here: https://stripe.com/docs/elements/appearance-api?platform=web
const STRIPE_FORM_STYLE: BaseStripeElementsOptions['appearance'] = {
    theme: 'stripe',
    variables: {
        colorPrimary: Colors.primary,
        colorDanger: Colors.bad,
        fontFamily: 'Montserrat_500Medium, Ideal Sans, system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '4px',
    },
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Constants.expoConfig?.extra?.stripeApiKey);

export function CommunicationStripeElements(
    props: React.PropsWithChildren<useStripClientSecretData>
) {
    return (
        <StripePaymentFormProvider>
            <StripeElementsContainer {...props} />
        </StripePaymentFormProvider>
    );
}
export type StripeClientSecretContextData = {
    clientSecret?: string;
    initPaymentForm?: (data: useStripClientSecretData) => void;
    loading?: boolean;
    error?: string;
};
export const StripeClientSecretContext = React.createContext<StripeClientSecretContextData>(
    {}
);
export type useStripClientSecretData = holdPaymentWithoutCardData;

const PAYMENT_FORM_ALREADY_COMPLETED = 'requires_capture' as const;
const PAYMENT_FORM_ALREADY_CREATED = 'requires_payment_method' as const;

function StripePaymentFormProvider({children}: React.PropsWithChildren<{}>) {
    const {isAuthenticated} = React.useContext(UserContext);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>();
    const [clientSecret, setClientSecret] = useUrlClientSecretState();
    const [_, setPaymentIntent] = useUrlPaymentIntent();

    const initPaymentForm = React.useCallback(
        async (data: useStripClientSecretData) => {
            if (!isAuthenticated) {
                return;
            }
            const paymentIntent = await clientPaymentIntent(clientSecret);
            if (paymentIntent?.status === PAYMENT_FORM_ALREADY_CREATED) {
                console.log(
                    'Client secret already exists, showing existing form',
                    paymentIntent.status
                );
                return;
            }
            if (paymentIntent?.status === PAYMENT_FORM_ALREADY_COMPLETED) {
                console.log('Payment intent already exists, using it', paymentIntent.id);
                setPaymentIntent(paymentIntent.id);
                return;
            }
            setLoading(true);
            if ('packId' in data && data.packId != null) {
                try {
                    const paymentIntent = await packPaymentIntent(data.packId, data);
                    if (paymentIntent) {
                        setClientSecret(paymentIntent.clientSecret);
                        setError(undefined);
                    }
                } catch (err) {
                    if (err instanceof PackPaymentIntentError) {
                        console.error(err.message, `(cause: ${err.cause})`);
                        setError(err.message);
                    }
                }
            } else {
                if (data.setupIntent) {
                    const intent = await setupIntent();
                    if (paymentIntent) {
                        setClientSecret(intent.clientSecret);
                        setError(undefined);
                    }
                } else {
                    const paymentIntent = await holdPaymentWithoutCard(data, clientSecret);
                    if (paymentIntent) {
                        setClientSecret(paymentIntent.clientSecret);
                    }
                }
            }
            setLoading(false);
        },
        [clientSecret, isAuthenticated]
    );

    return (
        <StripeClientSecretContext.Provider
            value={{clientSecret, loading, error, initPaymentForm}}
        >
            {children}
        </StripeClientSecretContext.Provider>
    );
}

function StripeElementsContainer({
    children,
    ...dataProps
}: React.PropsWithChildren<useStripClientSecretData>) {
    const {loading, error, clientSecret, initPaymentForm} = React.useContext(
        StripeClientSecretContext
    );

    const queryParams = React.useMemo(() => dataProps.returnQueryParams, []);
    React.useEffect(() => {
        initPaymentForm?.({
            amount: dataProps.amount,
            communicationId: dataProps.communicationId,
            packId: dataProps.packId,
            returnUrl: dataProps.returnUrl,
            service: dataProps.service,
            returnQueryParams: queryParams,
        });
    }, [
        initPaymentForm,
        dataProps.amount,
        dataProps.communicationId,
        dataProps.packId,
        dataProps.returnUrl,
        dataProps.service,
        queryParams,
    ]);

    if (loading) {
        return (
            <ActivityIndicator
                color={Colors.primary}
                size="large"
                style={{marginVertical: 100}}
            />
        );
    }

    if (error != null) {
        <View>
            <SimpleText>Erreur lors de la préparation du paiement</SimpleText>
            <SimpleText>{error}</SimpleText>
        </View>;
    }

    if (clientSecret == null) {
        return (
            <View>
                <SimpleText>Erreur lors du chargement de la vue Stripe.</SimpleText>
                <SimpleText>Ce problème provient de Stripe et non de Kyvoitou.com.</SimpleText>
                <SimpleText>
                    Veuillez réessayer dans quelques instant, en attendant que notre
                    prestataire de paiement soit à nouveau disponible.
                </SimpleText>
            </View>
        );
    }

    return (
        <Elements
            stripe={stripePromise}
            options={{
                clientSecret,
                appearance: STRIPE_FORM_STYLE,
                loader: 'auto' as const,
            }}
        >
            {children}
        </Elements>
    );
}

async function clientPaymentIntent(clientSecret: string | null | undefined) {
    if (clientSecret == null) {
        return undefined;
    }
    const stripe = await stripePromise;
    if (!stripe) {
        return undefined;
    }
    const {paymentIntent} = await stripe.retrievePaymentIntent(clientSecret);
    return paymentIntent;
}

function useUrlClientSecretState() {
    const route = useLandingPageRoute();
    const navigation = useLandingPageNavigation();
    const clientSecret = route.params?.payment_intent_client_secret;
    const setClientSecret = React.useCallback((secret: string | undefined) => {
        navigation.setParams({
            payment_intent_client_secret: secret,
        });
    }, []);

    return [clientSecret, setClientSecret] as const;
}

function useUrlPaymentIntent() {
    const route = useLandingPageRoute();
    const navigation = useLandingPageNavigation();
    const paymentIntent = route.params?.payment_intent;
    const setPaymentIntent = React.useCallback((secret: string | undefined) => {
        navigation.setParams({
            payment_intent: secret,
        });
    }, []);

    return [paymentIntent, setPaymentIntent] as const;
}

class PackPaymentIntentError extends Error {
    constructor(options: ErrorOptions) {
        super("Impossible d'utiliser ce forfait", options);
        Object.setPrototypeOf(this, PackPaymentIntentError.prototype);
    }
}

async function packPaymentIntent(packId: string, data: useStripClientSecretData) {
    const body = JSON.stringify({
        packId,
        card: 'NO_CARD',
        service: data.service,
        communication_id: data.communicationId,
        return_base_url: data.returnUrl,
        query_params: data.returnQueryParams,
    });
    const response = await axios.post(endpoints.buyPack, body);
    if ('ok' in response.data && !response.data.ok) {
        throw new PackPaymentIntentError({cause: response.data.reason});
    }
    if (response.data.payment_intent.client_secret == null) {
        throw new PackPaymentIntentError({
            cause: "Couldn't retrieve stripe `client_secret` from response",
        });
    }
    return {clientSecret: response.data.payment_intent.client_secret};
}
