import React, {useState, useImperativeHandle} from 'react';
import {View, StyleSheet, Image} from 'react-native';

import {Picker} from '@react-native-picker/picker';
import {CommonActions, useNavigation} from '@react-navigation/native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {GetLastExpertsQuery_getLastExperts_edges_node as Expert} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {getWarningDuration} from '~/helpers/dates';
import {getOffer} from '~/helpers/experts';
import {setReloadForExpert} from '~/helpers/pack';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {navigate, navigationRef} from '~/navigation';
import {ExpertsDetailsQuery_findExperts_edges_node as ExpertDetails} from '~/queries/types/ExpertsDetailsQuery';
import {GetClientOffers} from '~/queries/types/GetClientOffers';
import {QueryMe_me} from '~/queries/types/QueryMe';

import type {ExpertProfile_getExpert as ExpertProfile} from '../../screens/types/ExpertProfile';
import Button from '../common/Buttons';
import OfferListItem from '../common/IconText';
import {Card} from '../user/stripe/CardInfo';

type PopupCallProps = {
    visible: boolean;
    onRequestClose: () => void;
    expert: ExpertProfile | ExpertDetails | Expert | null | undefined;
    call: (
        clientId: string | undefined | null,
        expertId: string | undefined | null,
        warning: number | null,
        warningDuration: number | null,
        usePack: boolean
    ) => void;
};

const PopupCall = React.forwardRef((props: PopupCallProps, ref) => {
    const {isDesktop} = useDeviceQuery();
    const {me, offers, minutes} = React.useContext(UserContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [warning, setWarning] = useState<number>(0);
    const [pack, setPack] = useState<boolean>(false);

    const solde = minutes?.getMinuteBalance?.minutes ?? 0;

    function startCall(warning: number, usePack: boolean) {
        if (usePack && solde == 0) {
            return;
        }

        const warningDuration = getWarningDuration(
            warning,
            props.expert?.callParameters?.price?.priceDecimal
        );

        props.call(
            me?.id,
            props?.expert?.id,
            warning > 0 ? warning : null,
            warningDuration,
            usePack
        );
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            setPack(false);
        }, 3000);

        logEvent('call_started', {warning: warning});
    }

    useImperativeHandle(ref, () => ({
        startCallFromOutside,
    }));

    async function startCallFromOutside(warning: number) {
        setWarning(warning);
        startCall(warning, false);
    }

    function close() {
        props.onRequestClose();
        setPack(false);
    }

    function changePhone() {
        close();
        navigate('AccountScreen');
    }

    function changeCard() {
        close();
        navigate('AccountScreen');
    }

    async function goToPack() {
        close();
        await setReloadForExpert(props.expert?.userId);
        setTimeout(() => {
            if (isDesktop) {
                navigate('PackScreen');
            } else {
                navigate('Main', {
                    screen: 'Home',
                    params: {screen: 'PackScreen'},
                });
            }
        }, 1000);
    }

    const name = props.expert?.profile?.displayName;
    const message = (
        <View style={styles.container}>
            <SimpleText>
                Pour lancer votre consultation, appuyez sur « J'appelle », puis attendez que{' '}
                {name} vous réponde !
            </SimpleText>
        </View>
    );

    const title = 'Appeler ' + name;
    const acceptOffer = props.expert?.callParameters?.acceptOffer;
    const offer = getOffer(me, offers, props.expert?.id);
    const clientHasOffer = offer > 0;
    const price = props.expert?.callParameters?.price?.label;
    const stripe = me?.identity?.selectedPaymentMethod;
    const phone = me?.identity?.phone;

    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <CustomModal
            title={title}
            iconName="phone-alt"
            message={pack ? <></> : message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={close}
        >
            {pack && (
                <>
                    <View style={deviceStyles.container}>
                        <SimpleText style={styles.packLabel}>
                            Vous avez choisi la consultation avec "pack"
                        </SimpleText>
                    </View>
                    <View style={deviceStyles.container}>
                        <SimpleText style={styles.minuteLabel}>
                            ATTENTION : il vous reste {solde} minutes
                        </SimpleText>
                    </View>
                    <View style={deviceStyles.container}>
                        <Image
                            source={{uri: Settings.getUrlPictoBall()}}
                            style={{alignSelf: 'center', width: 32, height: 32}}
                            accessibilityLabel={'Pack'}
                        />
                        <SimpleText style={styles.edito}>
                            Vérifiez bien que vous avez assez de crédit pour pouvoir consulter
                            votre voyant(e)
                        </SimpleText>
                        <SimpleText style={styles.edito}>
                            La consultation sera automatiquement stoppée lorsque vous arriverez
                            au bout de votre crédit. Il serait dommage que vous soyez coupé(e)
                            en plein milieu !
                        </SimpleText>
                        <SimpleText style={styles.edito}>
                            Pensez à recharger si besoin :)
                        </SimpleText>
                        <View style={styles.buttons}>
                            <Button
                                title="Je consulte"
                                icon={<Icons.Phone size={25} />}
                                onPress={() => startCall(0, true)}
                                titleStyle={styles.buttonCallTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.pay,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                            <Button
                                title="Je recharge"
                                icon={<Icons.Sync size={25} />}
                                onPress={goToPack}
                                titleStyle={styles.buttonCallTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.highlight,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                        </View>
                    </View>
                </>
            )}
            {!pack && (
                <>
                    <View style={styles.container}>
                        <SimpleText style={styles.phoneLabel}>
                            Numéro de téléphone sur lequel vous souhaitez être appelé : {phone}
                        </SimpleText>
                        <TouchableOpacity onPress={changePhone}>
                            <SimpleText style={styles.changeNumber}>
                                Changer de numéro
                            </SimpleText>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.options}>
                        <View style={deviceStyles.option}>
                            <Button
                                title="J'appelle"
                                icon={<Icons.Phone size={25} />}
                                onPress={() => startCall(warning, false)}
                                titleStyle={styles.buttonCallTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.pay,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                            <SimpleText style={styles.priceLabel}>
                                Appel avec paiement à la minute : {price} / minute
                            </SimpleText>
                            <View style={styles.offerList}>
                                {acceptOffer && clientHasOffer && (
                                    <OfferListItem
                                        text={`Offre de bienvenue : ${offer} minutes gratuites`}
                                    />
                                )}
                                <OfferListItem
                                    text={`Aucun prélèvement sur votre facture téléphonique`}
                                />
                                <OfferListItem text={`Paiement sécurisé grâce au 3D secure`} />
                                <OfferListItem
                                    text={`En dessous de 2 minutes de consultation, vous ne serez pas prélevé !`}
                                />
                                <OfferListItem
                                    text={`Grâce à la popup d’avis qui s’affiche après votre consultation, donnez nous votre impression !`}
                                />
                            </View>
                            <View style={styles.cardContainer}>
                                <SimpleText style={styles.cardLabel}>
                                    Votre carte bancaire :
                                </SimpleText>
                                <Card stripe={stripe} onPress={() => {}} preview={true} />
                                <TouchableOpacity onPress={changeCard}>
                                    <SimpleText style={styles.changeCard}>
                                        Changer de carte
                                    </SimpleText>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.warningContainer}>
                                <SimpleText style={styles.warningLabel}>
                                    Ma limite de budget : M'avertir quand j'atteins...
                                </SimpleText>
                                <Picker
                                    selectedValue={warning}
                                    style={styles.picker}
                                    onValueChange={(itemValue, itemIndex) =>
                                        setWarning(parseInt(itemValue))
                                    }
                                >
                                    <Picker.Item label=" - " value="0" />
                                    <Picker.Item label="10 €" value="10" />
                                    <Picker.Item label="15 €" value="15" />
                                    <Picker.Item label="20 €" value="20" />
                                    <Picker.Item label="25 €" value="25" />
                                    <Picker.Item label="30 €" value="30" />
                                    <Picker.Item label="35 €" value="35" />
                                    <Picker.Item label="40 €" value="40" />
                                    <Picker.Item label="45 €" value="45" />
                                    <Picker.Item label="50 €" value="50" />
                                </Picker>
                            </View>
                        </View>
                        <View style={deviceStyles.option}>
                            <Button
                                title="J'utilise un pack"
                                icon={<Icons.Phone size={25} />}
                                onPress={() => setPack(true)}
                                titleStyle={styles.buttonPackTitle}
                                containerStyle={{width: 260, alignSelf: 'center'}}
                                buttonStyle={{
                                    backgroundColor: Colors.pay,
                                    borderRadius: 5,
                                    paddingVertical: 10,
                                    paddingHorizontal: 30,
                                }}
                                loading={isLoading}
                            />
                            <SimpleText style={styles.minutes}>
                                Solde actuel : {solde} minutes
                            </SimpleText>
                            <View style={styles.offerList}>
                                <OfferListItem text={`Paiement sécurisé grâce au 3D secure`} />
                                <OfferListItem text={`Une maîtrise de votre budget`} />
                                <OfferListItem text={`Une consultation immédiate`} />
                                <OfferListItem
                                    text={`Aucune obligation d'utiliser votre crédit d'un seul coup`}
                                />
                                <OfferListItem
                                    text={`Grâce à la pop up d’avis qui s’affiche après votre consultation, donnez nous votre impression !`}
                                />
                            </View>
                        </View>
                    </View>
                </>
            )}
        </CustomModal>
    );
});

export default PopupCall;

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 5,
        margin: 10,
        padding: 10,
    },
    options: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    option: {
        maxWidth: 400,
        backgroundColor: 'white',
        borderRadius: 5,
        margin: 10,
        padding: 10,
    },
    buttonCallTitle: {
        fontSize: 16,
        marginHorizontal: 20,
        fontStyle: 'italic',
    },
    buttonPackTitle: {
        fontSize: 16,
        marginHorizontal: 10,
        fontStyle: 'italic',
    },
    priceLabel: {
        color: Colors.secondary,
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    minutes: {
        color: Colors.secondary,
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    offerList: {
        alignSelf: 'center',
    },
    offerListLabel: {
        fontSize: 14,
    },
    offerLabel: {
        fontSize: 14,
        alignSelf: 'center',
        marginBottom: 20,
    },
    cardContainer: {
        width: 333,
        alignSelf: 'center',
        marginTop: 30,
        marginBottom: 10,
    },
    cardLabel: {
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    changeCard: {
        fontSize: 12,
        color: Colors.link,
        alignSelf: 'center',
        textAlign: 'center',
    },
    phoneLabel: {
        alignSelf: 'center',
    },
    changeNumber: {
        fontSize: 12,
        color: Colors.link,
        alignSelf: 'flex-end',
        marginTop: 10,
    },
    warningContainer: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    warningLabel: {
        fontSize: 14,
        marginRight: 20,
    },
    picker: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 5,
        width: 100,
    },
    packLabel: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
    },
    minuteLabel: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 18,
        padding: 10,
    },
    edito: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 15,
        maxWidth: 800,
    },
    buttons: {
        marginTop: 15,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    },
});

const mobileStyles = StyleSheet.create({
    option: {
        maxWidth: 400,
        backgroundColor: Colors.dark,
        borderRadius: 5,
        margin: 10,
        padding: 10,
    },
    container: {
        backgroundColor: Colors.dark,
        borderRadius: 5,
        margin: 20,
        padding: 10,
    },
});
