import React, {useEffect, useState} from 'react';
import {View, StyleSheet, FlatList, ScrollView, TouchableOpacity} from 'react-native';

import {gql, useQuery} from '@apollo/client';

import Button from '~/components/common/Buttons';
import {Bloc} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {UserContext} from '~/contexts/UserContext';
import {axios} from '~/helpers/network';

const TIMEOUT_SOCKET = 5000;

export default function BlocWaitingList() {
    const {me} = React.useContext(UserContext);
    const alert = useAlert();
    const {play} = useAudio();
    const [clients, setClients] = useState(new Array<string>());
    const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
    const [infoVisible, setInfoVisible] = useState<boolean>(false);
    const [clientId, setCientId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        subscribeExpert(true);
    }, [me]);

    useEffect(() => {
        setTimeout(() => subscribeExpert(false), TIMEOUT_SOCKET);
    }, [webSocket]);

    async function subscribeExpert(force: boolean) {
        const expert = me?.isExpert && me?.id;
        const socket = webSocket != null;

        if (expert && (force || !socket)) {
            const uri = Settings.waitingListSocketExpert(me?.id);
            const ws = new WebSocket(uri);

            ws.onmessage = async function (event) {
                const message = event.data;
                onWaitListMessage(message);
            };

            ws.onclose = function (event) {
                onClose();
                setWebSocket(null);
            };

            ws.onerror = function (event) {
                setWebSocket(null);
            };

            setWebSocket(ws);
        }

        if (!expert && socket) {
            webSocket.close();
        }
    }

    async function playSound() {
        play(require('../../../../assets/sound/bell.mp3'));
    }

    function onWaitListMessage(message: string) {
        const messageData: QueueEventData = JSON.parse(message);
        if (messageData.clients.length > clients.length) {
            playSound();
        }
        setClients(messageData.clients);
    }

    function onClose() {
        setClients([]);
    }

    function onPressClient(userId: string) {
        setInfoVisible(true);
        setCientId(userId);
    }

    async function handleClient() {
        setLoading(true);
        const body = JSON.stringify({
            client_id: clientId,
        });
        const response = await axios.post(Settings.waitingListNext(), body);
        if (response.data.ko) {
            alert({
                message:
                    "Vous êtes déjà en communication. Si votre communication n'apparaît pas à l'écran, merci de rafraichir la page à l'aide de la touche F5.",
            });
        }

        setInfoVisible(false);
        setLoading(false);
    }

    async function deleteClient() {
        setLoading(true);
        const body = JSON.stringify({
            client_id: clientId,
        });
        await axios.post(Settings.waitingListRemove(), body);
        setInfoVisible(false);
        setLoading(false);
    }

    function renderItem({item, index}: any) {
        return <Client userId={item.toString()} onPressClient={onPressClient} index={index} />;
    }

    if (!me) {
        return <></>;
    }

    return (
        <Bloc title="Clients en attente" iconName="list-ol">
            <View style={styles.numberContainer}>
                <SimpleText style={styles.number}>{clients.length}</SimpleText>
            </View>
            {clients.length > 0 && (
                <ScrollView style={styles.container} contentContainerStyle={{}}>
                    <FlatList
                        data={clients}
                        renderItem={renderItem}
                        keyExtractor={(item) => item}
                        contentContainerStyle={{
                            justifyContent: 'space-around',
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    />
                </ScrollView>
            )}
            {clients.length == 0 && (
                <SimpleText style={styles.noClient}>
                    Vous n'avez aucun client en attente.
                </SimpleText>
            )}
            <CustomModal
                title={'Information sur votre client en attente'}
                visible={infoVisible}
                hasCloseIcon={true}
                iconName="question-circle"
                onRequestClose={() => {
                    setInfoVisible(!infoVisible);
                }}
            >
                <View style={{width: 600}}>
                    <SimpleText>
                        Le prochain client de la liste d'attente est mis en relation environ 15
                        secondes après la fin de votre dernière communication.
                    </SimpleText>
                    <SimpleText style={{marginTop: 10}}>
                        Si toutefois, vous avez dépassé ce délai mais que le client semble
                        bloqué, vous pouvez :
                    </SimpleText>
                    <SimpleText style={styles.list}>
                        - Soit lancer manuellement la mise en relation pour débloquer le client
                    </SimpleText>
                    <SimpleText style={styles.list}>
                        - Soit, si la première solution ne fonctionne pas, supprimer le client
                        de la liste
                    </SimpleText>
                    <View
                        style={{
                            alignSelf: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            marginTop: 10,
                        }}
                    >
                        <Button
                            title="Mettre en relation"
                            buttonStyle={{width: 160, alignSelf: 'center', marginTop: 10}}
                            onPress={handleClient}
                            loading={loading}
                        />
                        <Button
                            title="Supprimer"
                            buttonStyle={{width: 160, alignSelf: 'center', marginTop: 10}}
                            onPress={deleteClient}
                            loading={loading}
                        />
                    </View>
                </View>
            </CustomModal>
        </Bloc>
    );
}

function Client(props: {
    userId: string;
    onPressClient: (userId: string) => void;
    index: number;
}) {
    const {loading, error, data} = useQuery(CLIENT_WAITING_QUERY, {
        variables: {id: 'User:' + props.userId},
    });

    if (data) {
        return (
            <View style={styles.client}>
                <View style={styles.line}>
                    <ProfilePicture
                        style={styles.profilePicture}
                        pictureName={data.user.profile.pictureName}
                    />
                    <SimpleText style={styles.name}>
                        {data.user.profile.displayName}
                    </SimpleText>
                </View>
                {props.index == 0 && (
                    <View style={styles.center}>
                        <TouchableOpacity onPress={() => props.onPressClient(props.userId)}>
                            <Icons.Question color={Colors.pause} size={18} />
                        </TouchableOpacity>
                    </View>
                )}
            </View>
        );
    }

    return (
        <View>
            <SimpleText></SimpleText>
        </View>
    );
}
export type QueueEventData = {
    clients: Array<string>;
};

const CLIENT_WAITING_QUERY = gql`
    query FindClientWaitingQuery($id: ID!) {
        user(id: $id) {
            id
            profile {
                displayName
                pictureName
            }
        }
    }
`;

const styles = StyleSheet.create({
    noClient: {
        margin: 15,
        alignSelf: 'center',
    },
    container: {
        borderRadius: 5,
        maxHeight: 225,
    },
    picto: {
        width: 24,
        height: 19,
    },
    numberContainer: {
        backgroundColor: Colors.pause,
        borderRadius: 20,
        width: 20,
        height: 20,
        position: 'absolute',
        top: 15,
        right: 15,
    },
    number: {
        color: 'white',
        fontSize: 14,
        alignSelf: 'center',
        paddingTop: 2,
    },
    client: {
        marginTop: 10,
        backgroundColor: 'white',
        padding: 15,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    line: {
        flex: 1,
        flexDirection: 'row',
    },
    profilePicture: {
        borderWidth: 2,
        borderColor: Colors.primary,
        width: 45,
        height: 45,
        borderRadius: 5,
    },
    name: {
        marginTop: 15,
        marginLeft: 15,
    },
    list: {
        marginTop: 5,
        marginLeft: 10,
    },
    center: {
        alignSelf: 'center',
    },
});
