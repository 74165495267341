import {useCallback} from 'react';

import {useLandingPageNavigation, useLandingPageRoute} from '../route';

export function usePaymentIntent() {
    const route = useLandingPageRoute();
    const navigation = useLandingPageNavigation();
    const paymentIntentSecret = route.params?.payment_intent_client_secret;
    const paymentIntent = route.params?.payment_intent;
    const setPaymentIntent = useCallback((paymentIntentId: string | undefined) => {
        navigation.setParams({
            payment_intent: paymentIntentId,
        });
    }, []);
    return {paymentIntent, paymentIntentSecret, setPaymentIntent};
}
