import React from 'react';
import {ViewStyle, View, StyleSheet, TextProps, ViewProps} from 'react-native';

import {SimpleText} from '~/components/common/Texts';
import {GRADE_COLORS} from '~/constants/Colors';
import * as Colors from '~/constants/Colors';

export function GradeItem({grade, text, percentage, style}: GradeItemProps) {
    return (
        <View style={[styles.gradeStyle, style]}>
            <GradeText text={text} grade={grade} percentage={percentage} />
            <GradeBar grade={grade} percentage={percentage} />
        </View>
    );
}

export function GradeText({grade, text, percentage, style}: GradeTextProps) {
    return (
        <View style={styles.labelStyle}>
            <SimpleText style={[styles.gradeLabelStyle, style]}>{text}</SimpleText>
            <SimpleText style={[styles.percentageLabelStyle, style]}>{percentage}%</SimpleText>
        </View>
    );
}

export function GradeBar({percentage, grade, style}: GradeBarProps) {
    const percentageStyle: ViewStyle = {
        width: percentage + '%',
        backgroundColor: grade ? GRADE_COLORS[grade - 1] : 'inherit',
    };
    return (
        <View style={[styles.percentageContainer, style]}>
            <View style={[styles.percentageStyle, percentageStyle]}></View>
        </View>
    );
}

export function GradeResume({text, grade, style}: GradeResumeProps) {
    return (
        <View style={[styles.resumeStyle, {backgroundColor: GRADE_COLORS[grade - 1]}]}>
            <SimpleText style={[styles.resumeLabelStyle, style]}>{text}</SimpleText>
        </View>
    );
}

export function GradeColorLabel({text, grade, style}: GradeResumeProps) {
    return (
        <View style={[styles.color, style]}>
            <SimpleText style={[styles.colorLabel, {color: GRADE_COLORS[grade - 1]}]}>
                {text}
            </SimpleText>
        </View>
    );
}

export function GradeColor({text, grade, style}: GradeResumeProps) {
    return <SimpleText style={[style, {color: GRADE_COLORS[grade - 1]}]}>{text}</SimpleText>;
}

export type GradeResumeProps = TextProps & {
    text: string;
    grade: number;
};
export type GradeTextProps = TextProps & {
    percentage: number;
    text: string | undefined;
    grade?: number;
};
export type GradeBarProps = ViewProps & {
    percentage: number;
    grade?: number;
};
export type GradeItemProps = ViewProps & GradeTextProps & GradeBarProps;

const styles = StyleSheet.create({
    gradeStyle: {
        alignSelf: 'stretch',
        flexDirection: 'column',
        justifyContent: 'space-around',
        marginTop: 8,
    },
    labelStyle: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    gradeLabelStyle: {
        fontSize: 11,
        paddingBottom: 5,
    },
    percentageLabelStyle: {
        fontSize: 11,
        paddingBottom: 5,
        paddingRight: 15,
    },
    percentageStyle: {
        height: 6,
        borderRadius: 4,
    },
    percentageContainer: {
        height: 6,
        backgroundColor: Colors.separator,
        overflow: 'hidden',
        borderRadius: 4,
    },
    resumeStyle: {
        marginVertical: 10,
        borderRadius: 4,
        width: 220,
        alignSelf: 'center',
    },
    resumeLabelStyle: {
        color: 'white',
        paddingVertical: 5,
        fontSize: 16,
        alignSelf: 'center',
    },
    color: {
        paddingTop: 4,
    },
    colorLabel: {
        fontSize: 11,
    },
});
