import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import * as dates from '~/helpers/dates';
import {getLastExchange} from '~/helpers/message';

import {InboxNavigationProps, MessageRowProps, getProfile, isOpen, styles} from './MessageBox';

const BOX_SHADOW = `0px 2px 7px #00000035`;

export function MobileInboxNavigation({
    inboxFilter,
    onFilterPress,
    isClient,
}: InboxNavigationProps) {
    return (
        <View style={mobileStyles.navigationContainer}>
            <View style={mobileStyles.filterContainer}>
                <MobileNavigationButton
                    selected={inboxFilter == 'received'}
                    onPress={() => onFilterPress('received')}
                    Icon={Icons.Mail}
                />
                <MobileNavigationButton
                    selected={inboxFilter == 'sent'}
                    onPress={() => onFilterPress('sent')}
                    Icon={Icons.MailSend}
                />
                <MobileNavigationButton
                    selected={inboxFilter == 'trash'}
                    onPress={() => onFilterPress('trash')}
                    Icon={Icons.Trash}
                />
            </View>
            {isClient && (
                <MobileFreeMessageButton
                    selected={inboxFilter == 'free-message'}
                    onPress={() => onFilterPress('free-message')}
                />
            )}
        </View>
    );
}

function MobileNavigationButton({selected, onPress, Icon}: MobileNavButtonProps) {
    const tint = Colors.primary;
    const text = Colors.negative;

    return (
        <Button
            icon={<Icon size={25} color={selected ? text : tint} />}
            buttonStyle={{
                backgroundColor: selected ? tint : 'white',
                paddingVertical: 15,
                paddingHorizontal: 30,
                borderRadius: 7,
                boxShadow: BOX_SHADOW,
            }}
            onPress={onPress}
        />
    );
}

function MobileFreeMessageButton({selected, onPress}: MobileFreeMessageButtonProps) {
    const tint = Colors.primary;
    const text = Colors.negative;

    return (
        <Button
            title="Rédiger un message de suivi"
            icon={<Icons.Add color={selected ? text : tint} size={12} />}
            onPress={onPress}
            titleStyle={{
                color: selected ? text : tint,
                fontSize: 12,
                paddingLeft: 10,
            }}
            buttonStyle={{
                backgroundColor: selected ? tint : text,
                flex: 1,
                borderRadius: 5,
                paddingVertical: 10,
                boxShadow: BOX_SHADOW,
            }}
            style={{alignSelf: 'stretch', marginTop: 5, overflow: 'visible'}}
        />
    );
}

export function MobileListHeaderTitle(props: MobileListHeaderTitleProps) {
    const backgroundColor = Colors.secondary;
    const color = Colors.negative;

    return (
        <View
            style={{
                backgroundColor,
                paddingVertical: 7,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: 20,
            }}
        >
            <SimpleText style={{color, textAlign: 'center', fontSize: 11}}>
                Mes messages {props.inboxTypeLabel}
            </SimpleText>
        </View>
    );
}

export function MobileMessageRow(props: MessageRowProps) {
    const profile = getProfile(props.message, props.mode);
    const open = isOpen(props.message, props.mode);
    const exchange = getLastExchange(props.message);
    const date = new Date(exchange.creationDate);
    const readStyle = open ? styles.read : styles.notRead;

    return (
        <TouchableOpacity onPress={props.onPressMessage}>
            <View style={mobileStyles.messageContainer}>
                <ProfilePicture
                    style={mobileStyles.profilePicture}
                    pictureName={profile?.pictureName}
                    displayName={profile?.displayName}
                />
                <View style={mobileStyles.middleContainer}>
                    <SimpleText style={[mobileStyles.expertName, readStyle]}>
                        {profile?.displayName}
                    </SimpleText>
                    <SimpleText numberOfLines={1} style={[mobileStyles.subject, readStyle]}>
                        {props.message.subject}
                        {/* {exchange.node.content} */}
                    </SimpleText>
                </View>
                <View style={mobileStyles.rightContainer}>
                    <SimpleText style={[mobileStyles.date, readStyle]}>
                        {dates.dateString(date)}
                    </SimpleText>
                    {props.showTrashAction && (
                        <TouchableOpacity
                            onPress={props.onPressDeleteMessage}
                            style={mobileStyles.trashIcon}
                        >
                            <Icons.Trash
                                size={15}
                                style={{textAlign: 'right'}}
                                color={Colors.secondary}
                            />
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        </TouchableOpacity>
    );
}

export type MobileNavButtonProps = {
    selected?: boolean;
    onPress: () => void;
    Icon: (props: Icons.IconProps) => JSX.Element;
};

export type MobileFreeMessageButtonProps = {
    selected?: boolean;
    onPress: () => void;
};

export type MobileListHeaderTitleProps = {
    inboxTypeLabel?: string;
};

export const mobileStyles = StyleSheet.create({
    textFilter: {},
    container: {
        flexDirection: 'column',
    },
    navigationContainer: {
        maxWidth: 500,
        alignSelf: 'center',
        alignItems: 'stretch',
        marginBottom: 20,
    },
    filterContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textSelected: {
        color: Colors.dark,
    },
    borderUnselected: {
        backgroundColor: Colors.dark,
    },
    borderSelected: {
        backgroundColor: Colors.primary,
    },
    touchFilter: {
        backgroundColor: 'red',
        paddingVertical: 15,
        paddingHorizontal: 25,
    },
    messageContainer: {
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: 10,
        marginBottom: 20,
    },
    messagePressableContainer: {
        flexDirection: 'row',
    },
    profilePicture: {
        borderRadius: 10,
        width: 45,
        height: 45,
    },
    middleContainer: {
        flex: 1,
        marginHorizontal: 10,
        justifyContent: 'space-between',
    },
    rightContainer: {
        justifyContent: 'space-between',
    },
    messages: {
        paddingHorizontal: 20,
    },
    date: {
        fontSize: 10,
        color: Colors.fade,
    },
    expertName: {
        fontWeight: '400',
    },
    subject: {
        fontSize: 11,
        color: Colors.fade,
    },
    trashIcon: {
        alignSelf: 'flex-end',
        textAlign: 'right',
    },
    noMessage: {
        alignSelf: 'center',
        textAlign: 'center',
    },
});
