import * as React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import {createDrawerNavigator} from '@react-navigation/drawer';
import {DrawerContentScrollView, DrawerItemList} from '@react-navigation/drawer';
import {HeaderBackButton} from '@react-navigation/elements';

import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import {SocialNetwork} from '~/components/navigation/SocialNetwork';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import * as auth from '~/helpers/login/auth';
import AboutScreen from '~/screens/information/AboutScreen';
import HowToScreen from '~/screens/information/HowToScreen';
import PriceScreen from '~/screens/information/PriceScreen';
import AccountScreen from '~/screens/profile/AccountScreen';

import MobileBottomTabNavigator from './MobileTabNavigator';

const Drawer = createDrawerNavigator();

export function DrawerBackButton({navigation, ...otherProps}) {
    return (
        <HeaderBackButton
            {...otherProps}
            onPress={() => {
                if (navigation.canGoBack()) {
                    navigation.goBack();
                } else {
                    navigation.navigate('HomeScreen');
                }
            }}
        />
    );
}

function CustomDrawerContent(props) {
    const {me, loadingMe} = React.useContext(UserContext);

    async function onPressLogout() {
        await auth.signOut();
        props.navigation.navigate('HomeScreen');
    }

    async function goToBlog() {
        window.open(Settings.url_blog);
    }

    return (
        <DrawerContentScrollView {...props}>
            <SocialNetwork />
            <DrawerItemList {...props} />
            <TouchableOpacity onPress={goToBlog}>
                <View style={styles.blog}>
                    <Icons.Blog color="white" size={25} />
                    <SimpleText style={styles.link}>Blog</SimpleText>
                </View>
            </TouchableOpacity>
            {me && (
                <View style={styles.logout}>
                    <TouchableOpacity onPress={onPressLogout}>
                        <SimpleText style={styles.signout}>Se déconnecter</SimpleText>
                    </TouchableOpacity>
                </View>
            )}
        </DrawerContentScrollView>
    );
}

export default function MobileDrawerNavigator() {
    return (
        <Drawer.Navigator
            drawerContent={(props) => <CustomDrawerContent {...props} />}
            initialRouteName="Main"
            screenOptions={{
                drawerInactiveTintColor: Colors.negative,
                drawerActiveTintColor: Colors.primary,
                drawerStyle: [{backgroundColor: Colors.secondary}, styles.drawerContent],
                headerShown: false,
            }}
            backBehavior="initialRoute"
        >
            <Drawer.Screen
                name="Main"
                component={MobileBottomTabNavigator}
                options={{
                    drawerLabel: 'Accueil',
                    title: 'Kyvoitou.fr | Accueil',
                    drawerIcon: ({color, size}) => (
                        <Icons.Home color={color} size={size} style={{width: size}} />
                    ),
                    headerShown: false,
                }}
            />
            <Drawer.Screen
                name="HowToScreen"
                component={HowToScreen}
                options={({navigation}) => ({
                    drawerLabel: 'Comment ça marche ?',
                    title: 'Kyvoitou.fr | Comment ça marche ?',
                    drawerIcon: ({color, size}) => (
                        <Icons.Info color={color} size={size} style={{width: size}} />
                    ),
                    headerTitle: '',
                    headerShown: true,
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <Drawer.Screen
                name="AboutScreen"
                component={AboutScreen}
                options={({navigation}) => ({
                    drawerLabel: 'Qui sommes-nous ?',
                    title: 'Kyvoitou.fr | Qui sommes-nous ?',
                    drawerIcon: ({color, size}) => (
                        <Icons.User color={color} size={size} style={{width: size}} />
                    ),
                    headerTitle: '',
                    headerShown: true,
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <Drawer.Screen
                name="AccountScreen"
                component={AccountScreen}
                options={({navigation}) => ({
                    drawerLabel: 'Mon compte',
                    title: 'Kyvoitou.fr | Mon compte',
                    drawerIcon: ({color, size}) => (
                        <Icons.Settings color={color} size={size} style={{width: size}} />
                    ),
                    headerTitle: '',
                    headerShown: true,
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
        </Drawer.Navigator>
    );
}

const styles = StyleSheet.create({
    drawerContent: {
        height: '80%',
        borderBottomRightRadius: 10,
    },
    logout: {
        marginTop: 50,
        paddingLeft: 20,
    },
    signout: {
        color: Colors.disabled,
        height: 40,
    },
    blog: {
        flexDirection: 'row',
        marginLeft: 20,
        marginTop: 10,
    },
    link: {
        color: 'white',
        fontSize: 14,
        marginLeft: 32,
        alignSelf: 'center',
    },
});
