import * as React from 'react';
import {View, StyleSheet, TouchableOpacity, Text} from 'react-native';

import {CommonActions, useNavigation} from '@react-navigation/native';

import * as Colors from '~/constants/Colors';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import * as RootNavigation from '~/navigation';

import {Separator} from '../common/Separator';

type LogoProps = {
    color: string;
    titleFontSize: number;
    subtitleFontSize: number;
    logoWidth?: number;
    separatorWidth: string;
};

export function Logo(props: LogoProps) {
    const {isDesktop} = useDeviceQuery();
    const logoWidth =
        props.logoWidth != null
            ? {
                  width: props.logoWidth,
              }
            : {};

    return (
        <TouchableOpacity
            onPress={() => {
                if (isDesktop) {
                    RootNavigation.navigationRef?.current.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [
                                {
                                    name: 'Home',
                                    state: {
                                        routes: [
                                            {
                                                name: 'HomeScreen',
                                            },
                                        ],
                                    },
                                },
                            ],
                        })
                    );
                } else {
                    RootNavigation.navigationRef?.current.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [
                                {
                                    name: 'Main',
                                    state: {
                                        routes: [
                                            {
                                                name: 'HomeScreen',
                                            },
                                        ],
                                    },
                                },
                            ],
                        })
                    );
                }
            }}
            style={[logoStyle.logo, logoWidth]}
        >
            <InnerLogo {...props} />
        </TouchableOpacity>
    );
}

export function InnerLogo(props: LogoProps) {
    const color = {
        color: props.color,
    };
    const titleSize = {
        fontSize: props.titleFontSize,
    };
    const subtitleSize = {
        fontSize: props.subtitleFontSize,
    };
    const separatorWidth = {
        width: props.separatorWidth,
    };

    return (
        <>
            <Text style={[logoStyle.title, color, titleSize]}>KYVOITOU.fr</Text>
            <View style={logoStyle.subtitleView}>
                <Separator style={[separatorWidth, logoStyle.separator]} />
                <Text style={[logoStyle.subtitle, color, subtitleSize]}>Voyance en ligne</Text>
                <Separator style={[separatorWidth, logoStyle.separator]} />
            </View>
        </>
    );
}

const logoStyle = StyleSheet.create({
    logo: {
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
    },
    title: {
        fontFamily: 'serif',
        alignSelf: 'center',
    },
    subtitleView: {
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-evenly',
    },
    separator: {
        marginVertical: 0,
        height: 1,
        alignSelf: 'center',
        backgroundColor: Colors.primary,
    },
    subtitle: {
        fontWeight: '300',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat_500Medium',
    },
});
