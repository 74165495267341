import * as React from 'react';
import {ScrollView, View, ViewStyle} from 'react-native';

import {useLinkTo} from '@react-navigation/native';
import {StripeError} from '@stripe/stripe-js';

import {Bold, SimpleText} from '~/components/common/Texts';
import AddPaymentView from '~/components/landingPage/AddPaymentView';
import ExpertPickerView from '~/components/landingPage/ExpertPickerView';
import OngoingCallView from '~/components/landingPage/OngoingCallView';
import PickedExpertView from '~/components/landingPage/PickedExpertView';
import PostPaymentView from '~/components/landingPage/PostPaymentView';
import PostRegisterView from '~/components/landingPage/PostRegisterView';
import RegisterView from '~/components/landingPage/RegisterView';
import Reviews from '~/components/landingPage/Reviews';
import TitleView from '~/components/landingPage/TitleView';
import {
    Step,
    UrlStepProvider,
    useUrlStepContext,
} from '~/components/landingPage/context/UrlStepContext';
import {useCallStarted} from '~/components/landingPage/hook/useCallStarted';
import useCampaignQuery from '~/components/landingPage/hook/useCampaignQuery';
import {useExpertId} from '~/components/landingPage/hook/useExpertId';
import useSelectedExpert from '~/components/landingPage/query/useSelectedExpert';
import {
    useCallLandingPageNavigation,
    useCallLandingPageRoute,
} from '~/components/landingPage/route';
import {styles} from '~/components/landingPage/styles';
import {mobileStyles} from '~/components/user/client/message/MobileMessageBox';
import {
    CALL_LANDING_PAGE_URL,
    PACK_CALL_LANDING_PAGE_PATH,
} from '~/constants/LandingPageSettings';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import {UserContext} from '~/contexts/UserContext';
import event from '~/helpers/analytics';
import {ExpertWithLastReview} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function LandingPageScreen() {
    return (
        <UrlStepProvider>
            <LandingPageScreenContent />
        </UrlStepProvider>
    );
}

function LandingPageScreenContent() {
    const alert = useAlert();
    const confirm = useConfirm();
    const linkTo = useLinkTo();
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles.container : mobileStyles.container;
    const scrollViewRef = React.useRef<ScrollView>(null);
    function scrollToTop() {
        scrollViewRef.current?.scrollTo({y: 0, animated: false});
    }

    const query = useCampaignQuery();
    const {me, refetchMe, offers, refetchOffers} = React.useContext(UserContext);
    const route = useCallLandingPageRoute();
    const sessionId =
        route.params?.sessionId || me?.identity?.selectedPaymentMethod?.sessionId;

    React.useEffect(() => {
        if (offers) {
            const hasOffer = offers.getClientOffers?.edges.some(
                (o) => o?.node?.status == 'AVAILABLE'
            );
            if (!hasOffer) {
                alert({
                    title: 'Information',
                    message:
                        'Vous avez déjà bénéficié de vos 3x5 mins gratuites, voulez vous consulter nos offres de forfait ?',
                    onClose: () => (document.location.href = '/nos-tarifs'),
                });
            }
        }
    }, [offers]);

    const doubleConfirm = React.useCallback(
        (onYes: () => void, expertId: string) => {
            confirm({
                altStyle: true,
                title: 'Informations sur la provision',
                message: <ProvisionMessage />,
                yesText: "C'est OK pour moi",
                noText: 'Non, je ne souhaite pas continuer',
                onYes,
                onNo: () => {
                    confirm({
                        altStyle: true,
                        title: 'Informations sur la provision',
                        message: <ProvisionMessageAgain />,
                        yesText: "J'ai changé d'avis, je souhaite continuer",
                        noText: 'Je découvre les forfaits prépayés Kyvoitou',
                        onYes,
                        onNo: () => {
                            // linkTo('/nos-packs');
                            linkTo(
                                `/${PACK_CALL_LANDING_PAGE_PATH}?step=choix-pack&selectedExpertId=${expertId}`
                            );
                        },
                    });
                },
            });
        },
        [confirm, linkTo]
    );

    const {showExpertList, showAuthForm, showPaymentCTA, showCallStatus} = useUrlStepContext();
    const [callAlreadyStarted, setCallAlreadyStarted] = useCallStarted();
    const [selectedExpertId, setSelectedExpertId] = useExpertId();
    const onExpertSelected = React.useCallback(
        (user: ExpertWithLastReview) => {
            setSelectedExpertId(user.userId);
            event('call_landing_page_expert_selected', {expert_id: user.userId});
            scrollToTop();
            if (me?.id) {
                doubleConfirm(() => {}, user.userId);
            }
        },
        [doubleConfirm, me]
    );

    function showAuthFormAndScroll() {
        showAuthForm();
        scrollToTop();
    }
    function showPaymentCtaAndScroll() {
        showPaymentCTA();
        scrollToTop();
    }

    const onRegisterAccount = React.useCallback(() => {
        showPaymentCtaAndScroll();
        setTimeout(() => refetchMe(), 2000);
        event('call_landing_page_register');
        if (selectedExpertId == null) {
            // This should only happen if the user manipulated the query params
            showExpertList();
        } else {
            doubleConfirm(() => {}, selectedExpertId);
        }
    }, [doubleConfirm]);

    const onPaymentIntentConfirmed = React.useCallback(() => {
        showCallStatus();
        event('call_landing_page_payment_hold');
    }, []);

    const onPaymentIntentError = React.useCallback((error: StripeError) => {
        event('call_landing_page_payment_hold_error', {
            stripe_code: error.code,
            stripe_message: error.message,
        });
    }, []);

    const onCallStarted = React.useCallback(() => {
        showCallStatus();
        setCallAlreadyStarted(true);
        scrollToTop();
    }, []);

    const onCancelPayment = React.useCallback(() => {
        showExpertList();
        scrollToTop();
        event('call_landing_page_refused_waiting_list');
    }, []);

    const pickAnotherExpert = React.useCallback(() => {
        showExpertList();
        setSelectedExpertId(undefined);
        scrollToTop();
        event('call_landing_page_pick_another_expert');
    }, []);

    const navigation = useCallLandingPageNavigation();
    const returnToFirstStep = React.useCallback(() => {
        refetchOffers();
        navigation.navigate('LandingPageCallScreen', {});
    }, []);

    const {data, loading} = useSelectedExpert(selectedExpertId);
    const selectedExpertUser = data?.user;
    const returnQueryParams = {...route.params};
    returnQueryParams.step = Step.ONGOING_CALL_STEP.valueOf();

    return (
        <View style={{flex: 1}}>
            <ScrollView ref={scrollViewRef} contentContainerStyle={{flexGrow: 1}}>
                <View style={deviceStyle}>
                    <TitleView nbStepsText={'trois'} />
                    <ExpertPickerView
                        onAuthRequired={showAuthFormAndScroll}
                        onPaymentMethodRequired={showPaymentCtaAndScroll}
                        onCallStarted={onCallStarted}
                        onExpertSelected={onExpertSelected}
                        onCancelPayment={onCancelPayment}
                        medium="call"
                    />
                    <PickedExpertView
                        expertUser={selectedExpertUser}
                        onPickAnotherExpertPress={pickAnotherExpert}
                    />
                    <RegisterView
                        stepIndex={2}
                        style={{
                            maxWidth: 700,
                            alignSelf: 'center',
                            flex: 1,
                            marginVertical: 20,
                        }}
                        query={query}
                        onRegister={onRegisterAccount}
                    />
                    <PostRegisterView stepIndex={2} />
                    <AddPaymentView
                        stepIndex={3}
                        expertUser={selectedExpertUser}
                        loadingExpert={loading}
                        onPaymentIntentConfirmed={onPaymentIntentConfirmed}
                        onPaymentIntentError={onPaymentIntentError}
                        returnUrl={CALL_LANDING_PAGE_URL}
                        returnQueryParams={returnQueryParams}
                    />
                    <PostPaymentView />
                    <OngoingCallView
                        onPressReturn={returnToFirstStep}
                        sessionId={sessionId}
                        expertUser={selectedExpertUser}
                        selectedExpertId={selectedExpertId}
                        loadingExpert={loading}
                        callAlreadyStarted={callAlreadyStarted}
                        setCallAlreadyStarted={setCallAlreadyStarted}
                        onCancelPayment={onCancelPayment}
                    />
                    <Reviews />
                </View>
            </ScrollView>
        </View>
    );
}

function ProvisionMessage() {
    return (
        <View>
            <ProvisionMessageRowText icon="🔮">
                Dans le souci de garantir la la sécurité de votre consultation tant pour vous
                que pour le médium, nous demandons une pré-autorisation de 100 € sur votre
                carte bancaire.
            </ProvisionMessageRowText>
            <ProvisionMessageRowText style={{marginTop: 20}} icon="🌟">
                Il est essentiel de noter que cela ne constitue pas un prélèvement, mais une
                <Bold>SIMPLE EMPREINTE BANCAIRE</Bold>.
            </ProvisionMessageRowText>
            <ProvisionMessageRowText style={{marginTop: 20}} icon="🙏">
                Nous vous remercions de votre confiance et vous souhaitons la bienvenue dans
                une expérience de consultation en toute sérénité.
            </ProvisionMessageRowText>
        </View>
    );
}

function ProvisionMessageAgain() {
    return (
        <View>
            <ProvisionMessageRowText icon="🙏">
                Nous comprenons que faire une provision sur notre site peut vous générer de
                l'appréhension mais rappelons-nous que cette étape est une mesure de sécurité
                pour garantir une transaction fluide et sécurisée.
            </ProvisionMessageRowText>
            <ProvisionMessageRowText style={{marginTop: 20}} icon="🌟">
                Si vos doutes persistent, découvrez nos forfaits voyance afin de pouvoir
                consulter en toute tranquillité. Tout est au choix tant les forfaits que les
                médiums.
            </ProvisionMessageRowText>
            <ProvisionMessageRowText style={{marginTop: 20}} icon="🔮">
                Venez tenter l'aventure avec nos voyants. Si vous acceptez la provision
                choisissez <Bold>continuer</Bold>, si vous préférez éviter une provision, venez
                découvrir nos forfaits prépayés sur Kyvoitou.
            </ProvisionMessageRowText>
        </View>
    );
}

function ProvisionMessageRowText(
    props: React.PropsWithChildren<{icon: string; style?: ViewStyle}>
) {
    return (
        <View style={[{flexDirection: 'row'}, props.style]}>
            <SimpleText style={{alignSelf: 'center', marginRight: 20, fontSize: 20}}>
                {props.icon}
            </SimpleText>
            <SimpleText style={{textAlign: 'left'}}>{props.children}</SimpleText>
        </View>
    );
}
