import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {Formik, FormikProps, FormikHelpers} from 'formik';
import {CheckBox} from 'react-native-elements';
import {TouchableOpacity} from 'react-native-gesture-handler';

import Button from '~/components/common/Buttons';
import {MailInput, PasswordInput} from '~/components/common/Inputs';
import type {InputType} from '~/components/common/Inputs';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import logEvent from '~/helpers/analytics';
import {signIn} from '~/helpers/login/auth';
import type {UserCredential} from '~/helpers/login/auth';
import {axios} from '~/helpers/network';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

type LoginFormProps = {
    onSuccess?: (credentials: UserCredential) => void;
};

const {email, password} = yupRules;
const validationSchema = yup.object().shape({email, password});

export default function LoginForm({onSuccess}: LoginFormProps) {
    async function login(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        const body = JSON.stringify({email: values.email});
        const response = await axios.post(endpoints.email, body);

        if (response.data.blocked) {
            setErrors({email: 'Ce compte est bloqué'});
        } else {
            try {
                const credentials = await signIn(values.email, values.password);
                onSuccess?.(credentials);
                logEvent('login');
            } catch (error) {
                if (error.code == 'auth/wrong-password') {
                    setErrors({password: 'Le mot de passe est incorrect'});
                }
                if (error.code == 'auth/user-not-found') {
                    setErrors({email: "L'email est introuvable"});
                }
                throw error;
            }
        }
    }

    return (
        <View>
            <Formik
                initialValues={{email: '', password: ''}}
                onSubmit={login}
                validationSchema={validationSchema}
            >
                {(formikProps) => <LoginInputs {...formikProps} />}
            </Formik>
        </View>
    );
}

function LoginInputs(props: Props) {
    const navigation = useNavigation();
    const [checked, setChecked] = useState(false);
    let passwordRef = React.useRef<InputType>(null);
    const focusPassword = () => passwordRef?.current?.focus?.();

    function onPressCheckBox() {
        setChecked(!checked);
    }

    function onPressRegister() {
        navigation.navigate('RegisterScreen');
    }

    function onPressForgottenPassword() {
        navigation.navigate('ForgottenPasswordScreen');
    }

    return (
        <View style={styles.container}>
            <FormLabel>Email</FormLabel>
            <MailInput
                placeholder={yupLabels.email}
                value={props.values.email}
                onChangeText={props.handleChange('email')}
                onBlur={() => props.setFieldTouched('email')}
                blurOnSubmit={true}
                errorMessage={props.errors.email || ' '}
                onSubmitEditing={focusPassword}
                style={styles.input}
                inputStyle={styles.input}
            />
            <FormLabel>Mot de passe</FormLabel>
            <PasswordInput
                placeholder={yupLabels.password}
                value={props.values.password}
                onChangeText={props.handleChange('password')}
                onBlur={() => props.setFieldTouched('password')}
                errorMessage={props.errors.password || ' '}
                onSubmitEditing={() => props.handleSubmit()}
                ref={passwordRef}
                style={styles.input}
                inputStyle={styles.input}
            />
            <View style={styles.checkboxContainer}>
                <CheckBox
                    checked={checked}
                    onPress={onPressCheckBox}
                    containerStyle={{
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        marginTop: 14,
                        padding: 0,
                    }}
                    style={{backgroundColor: 'transparent'}}
                    wrapperStyle={{backgroundColor: 'transparent'}}
                />
                <SimpleText style={styles.rememberLabel}>Se souvenir de moi</SimpleText>
                <View style={styles.forgotContainer}>
                    <TouchableOpacity onPress={onPressForgottenPassword}>
                        <SimpleText style={styles.forgotLabel}>
                            Mot de passe oublié ?
                        </SimpleText>
                    </TouchableOpacity>
                </View>
            </View>
            <SimpleText style={styles.accountLabel}>
                Je n'ai pas de compte ?
                <TouchableOpacity onPress={onPressRegister}>
                    <SimpleText style={styles.createLabel}>Créer un compte</SimpleText>
                </TouchableOpacity>
            </SimpleText>
            <Button
                title="S'identifier"
                buttonStyle={{width: 150, alignSelf: 'center', marginTop: 30}}
                disabled={!props.isValid}
                onPress={() => props.handleSubmit()}
                loading={props.isSubmitting}
            />
        </View>
    );
}

type Inputs = Pick<YupTypes, 'email' | 'password'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        marginHorizontal: 10,
        alignContent: 'space-around',
    },
    checkboxContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rememberLabel: {
        fontSize: 14,
        marginTop: 17,
    },
    forgotContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    forgotLabel: {
        fontSize: 14,
        marginTop: 17,
        alignSelf: 'flex-end',
        marginLeft: 20,
    },
    accountLabel: {
        fontSize: 14,
        alignSelf: 'center',
        marginTop: 20,
        textAlign: 'center',
    },
    createLabel: {
        marginLeft: 10,
        color: Colors.primary,
    },
    input: {},
});
