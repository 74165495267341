import * as React from 'react';
import {View, ViewStyle} from 'react-native';

import Svg, {Path, G} from 'react-native-svg';

type RotaryPhoneProps = {
    color?: string;
    style?: ViewStyle;
    size: number;
};

const SIZE = 26;
const WIDTH = 34;
const HEIGHT = 28;

export function RotaryPhone(props: RotaryPhoneProps) {
    const fill = props.color ? props.color : 'white';
    const ratio = props.size / SIZE;
    const scale = `scale(${ratio}, ${ratio})`;
    const width = WIDTH * ratio;
    const height = HEIGHT * ratio;
    const viewbox = `0 0 ${width} ${height}`;

    return (
        <View style={[props.style, {justifyContent: 'center'}]}>
            <Svg width={width} height={height} viewBox={viewbox} style={{alignSelf: 'center'}}>
                <G transform={scale}>
                    <Path
                        fill={fill}
                        stroke="none"
                        fillRule="evenodd"
                        fillOpacity={1}
                        d="M 17 25 L 1.367188 25 C 1.367188 21.992188 2.207031 19.175781 3.671875 16.789062 C 4.300781 16.980469 4.96875 17.082031 5.667969 17.082031 C 9.492188 17.082031 12.597656 13.964844 12.597656 10.121094 L 12.597656 9.9375 C 13.996094 9.53125 15.472656 9.308594 17 9.308594 C 18.539062 9.308594 20.015625 9.53125 21.421875 9.949219 C 21.410156 10.113281 21.402344 10.265625 21.402344 10.425781 C 21.402344 14.269531 24.507812 17.386719 28.332031 17.386719 C 29.082031 17.386719 29.800781 17.265625 30.480469 17.054688 C 31.84375 19.390625 32.632812 22.105469 32.632812 25.011719 L 17 25.011719 Z M 17 12.671875 C 19.589844 12.671875 21.683594 14.777344 21.683594 17.378906 C 21.683594 19.980469 19.589844 22.082031 17 22.082031 C 14.410156 22.082031 12.316406 19.980469 12.316406 17.378906 C 12.316406 14.777344 14.410156 12.671875 17 12.671875 Z M 17 12.671875"
                    />
                    <Path
                        fill={fill}
                        stroke="none"
                        fillRule="evenodd"
                        fillOpacity={1}
                        d="M 10.707031 7.519531 C 11.109375 8.304688 11.332031 9.1875 11.332031 10.132812 C 11.332031 13.273438 8.792969 15.824219 5.667969 15.824219 C 2.539062 15.824219 0 13.273438 0 10.132812 C 0 9.125 0.261719 8.191406 0.707031 7.367188 C 2.722656 3.121094 9.25 0.0117188 17 0.0117188 C 25.710938 0.0117188 32.886719 3.941406 33.816406 9.003906 C 33.9375 9.460938 34 9.9375 34 10.425781 C 34 13.566406 31.460938 16.117188 28.332031 16.117188 C 25.207031 16.117188 22.667969 13.566406 22.667969 10.425781 C 22.667969 9.402344 22.941406 8.445312 23.40625 7.621094 C 22.039062 6.371094 19.679688 5.546875 17 5.546875 C 14.390625 5.546875 12.082031 6.332031 10.707031 7.519531 Z M 10.707031 7.519531"
                    />
                </G>
            </Svg>
        </View>
    );
}
