import {useCallback} from 'react';

import {useLandingPageNavigation, useLandingPageRoute} from '../route';

export function useSelectedPack() {
    const route = useLandingPageRoute();
    const navigation = useLandingPageNavigation();
    const selectedPackId = route.params?.selectedPack;
    const setSelectedPackId = useCallback((packId: string | undefined) => {
        navigation.setParams({
            selectedPack: packId,
        });
    }, []);

    return [selectedPackId, setSelectedPackId] as const;
}
