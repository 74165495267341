import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

import Button from '~/components/common/Buttons';
import {Bloc} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import * as Icons from '~/components/common/Icons';

import PopupContact from './PopupContact';

export default function BlocContact() {
    const {me, loadingMe, refetchMe} = React.useContext(UserContext);
    const [modalVisible, setModalVisible] = useState(false);

    async function onSuccess() {
        setModalVisible(false);
        refetchMe();
    }

    function onRequestCloseDisable() {
        setModalVisible(false);
    }

    function modifyContact() {
        setModalVisible(true);
    }

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    const address = me?.identity?.address;
    const postalCode = me?.identity?.postalCode;
    const city = me?.identity?.city;

    return (
        <Bloc title="Mes coordonnées" iconName="house-user">
            <View style={styles.container}>
                {address && (
                    <View>
                        <SimpleText style={styles.address}>{address}</SimpleText>
                        <SimpleText style={styles.address}>{postalCode} {city}</SimpleText>
                    </View>
                )}
                {!address && (
                    <SimpleText style={styles.text}>Vous n'avez pas encore d'adresse.</SimpleText>
                )}
                <Button
                    icon={<Icons.Edit size={16} style={{marginRight: 20}} />}
                    onPress={modifyContact}
                    style={styles.button}
                    title="Modifier"
                />
            </View>
            <PopupContact
                onSuccess={onSuccess}
                visible={modalVisible}
                onRequestClose={onRequestCloseDisable}
            />
        </Bloc>
    );
}

const styles = StyleSheet.create({
    container: {
        margin: 15,
        alignSelf: 'center',
        backgroundColor: 'white',
        width: 300,
    },
    text: {
        alignSelf: 'center',
        paddingTop: 15
    },
    address: {
        paddingTop: 10,
        alignSelf: 'center',
    },
    button: {
        margin: 15,
        width: 150,
        alignSelf: 'center',
    },
});
