import {gql} from '@apollo/client';

import stripeFragments from './stripe';

export default gql`
    fragment NotificationParameters on User {
        notificationParameters {
            edges {
                node {
                    typeNotification
                    activation
                }
            }
        }
    }
    fragment Favorites on User {
        favorites {
            edges {
                node {
                    id
                    expertId
                }
            }
        }
    }
    fragment CallParameters on User {
        callParameters {
            id
            acceptOffer
            price {
                id
                priceId
                priceDecimal
                label
            }
        }
    }
    fragment MessageParameters on User {
        messageParameters {
            edges {
                node {
                    messagePrice {
                        id
                        messagePriceId
                        label
                        priceDecimal
                    }
                    type
                    description
                }
            }
        }
    }
    fragment ChatParameters on User {
        chatParameters {
            acceptOffer
            chatPrice {
                id
                chatPriceId
                label
                priceDecimal
            }
        }
    }
    fragment Tags on User {
        tags {
            edges {
                node {
                    id
                    label
                    tagTypeId
                }
            }
        }
    }
    fragment Expert on User {
        expert {
            id
            callStatus
            messageStatus
            chatStatus
            function
            shortDescription
            longDescription
            experience
            catchPhrase
            skinType
            voicemail
            created
        }
    }
    fragment Identity on User {
        identity {
            id
            phone
            email
            surname
            lastname
            gender
            address
            addressComplement
            postalCode
            city
            country
            created
            selectedPaymentMethod {
                ...Card
            }
            paymentMethods {
                edges {
                    node {
                        ...Card
                    }
                }
            }
        }
    }
    fragment Profile on User {
        profile {
            id
            displayName
            pictureName
        }
    }
    ${stripeFragments}
`;
