import {gql} from '@apollo/client';

export default gql`
    fragment ExpertDetails on User {
        id
        userId
        profile {
            id
            displayName
            pictureName
        }
        identity {
            id
            gender
            created
        }
        tags {
            edges {
                node {
                    id
                    label
                    tagTypeId
                }
            }
        }
        expert {
            id
            voicemail
            shortDescription
            skinType
            callStatus
            messageStatus
            chatStatus
            isProfileComplete
            url
            created
        }
        reviewSummary {
            id
            communicationNumber
            positiveReviewRate
        }
        reviewSummaryQuality {
            edges {
                node {
                    id
                    qualityId
                    starNumber
                    quality {
                        label
                    }
                }
            }
        }
        reviewSummaryGrade {
            edges {
                node {
                    id
                    gradeId
                    percentage
                    grade {
                        label
                    }
                }
            }
        }
        callParameters {
            id
            acceptOffer
            price {
                id
                label
                priceDecimal
            }
        }
        messageParameters {
            edges {
                node {
                    messagePrice {
                        id
                        messagePriceId
                        label
                        priceDecimal
                    }
                    type
                    description
                }
            }
        }
        chatParameters {
            acceptOffer
            chatPrice {
                id
                chatPriceId
                label
                priceDecimal
            }
        }
    }
`;
