import React from 'react';
import {View, StyleSheet} from 'react-native';

import {MainView} from '~/components/common/Containers';
import {MobileTitle} from '~/components/common/Texts';
import {EnumModeMessageBox} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import SignOut from '../../SignOut';
import BlocAccount from './BlocAccount';
import BlocExpert from './BlocExpert';
import BlocMessage from './BlocMessage';
import BlocMinute from './BlocMinute';
import BlocSupport from './BlocSupport';

export default function ClientDashboard() {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return (
            <MainView>
                <View style={styles.container}>
                    <View style={styles.column}>
                        <BlocAccount />
                        <BlocMessage mode={EnumModeMessageBox.CLIENT} />
                    </View>
                    <View style={styles.column}>
                        <BlocSupport />
                        <SignOut />
                    </View>
                    <View style={styles.column}>
                        <BlocMinute />
                        <BlocExpert />
                    </View>
                </View>
            </MainView>
        );
    } else {
        return (
            <MainView>
                <MobileTitle title={'Espace personnel'} />
                <View style={mobileStyles.container}>
                    <BlocMinute />
                    <BlocExpert />
                    <BlocMessage mode={EnumModeMessageBox.CLIENT} />
                    <BlocSupport />
                    <SignOut />
                </View>
            </MainView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
    },
    column: {
        marginHorizontal: 10,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        paddingVertical: 10,
    },
});
