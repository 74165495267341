import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ModalProps, TouchableOpacity} from 'react-native';

import {gql, useMutation} from '@apollo/client';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings, {endpoints} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {axios} from '~/helpers/network';

const UPDATE_VOICEMAIL = gql`
    mutation updateVoicemail($voicemail: String!) {
        updateVoicemail(input: {voicemail: $voicemail}) {
            ok
        }
    }
`;

const RECORD_INTERVAL = 5000;
const RECORD_CHECK = 15;
const RECORD_TIMEOUT = 120;

export default function PopupVoicemail(props: ModalProps & {onSuccess: Function}) {
    const alert = useAlert();
    const {loadingMe, me, refetchMe} = React.useContext(UserContext);
    const [updateVoicemail, {data: callData, error: callError}] = useMutation(
        UPDATE_VOICEMAIL,
        {
            errorPolicy: 'all',
        }
    );
    const [voicemail, setVoicemail] = useState<string | null>();
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [recordStartDate, setRecordStartDate] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isRecording) {
                const duration = Math.round((+new Date() - recordStartDate) / 1000);
                if (duration > RECORD_CHECK) {
                    if (duration > RECORD_TIMEOUT) {
                        cancelRecord();
                    } else {
                        checkRecord();
                    }
                }
            }
        }, RECORD_INTERVAL);
        return () => clearInterval(interval);
    }, [isRecording]);

    async function cancelRecord() {
        alert({
            message: 'Une erreur est survenue lors de votre enregistrement',
            width: 400,
        });

        setIsRecording(false);
    }

    async function cancel() {
        props.onRequestClose && props.onRequestClose();
        setVoicemail(null);
    }

    async function checkRecord() {
        const response = await axios.get(endpoints.checkVoicemail);
        if (response?.data?.mp3 != null) {
            const mp3 = Settings.getUrlMp3(response.data.mp3);
            setIsRecording(false);
            setVoicemail(mp3);
        }
    }

    async function update() {
        const data = {
            voicemail: voicemail,
        };

        await updateVoicemail({variables: data});
        refetchMe();
        props.onSuccess();
        setVoicemail(null);
        logEvent('modify_expert', {fields: 'voicemail'});
    }

    async function recordVoicemail() {
        const response = await axios.post(endpoints.recordVoicemail);
        setRecordStartDate(+new Date());
        setIsRecording(true);
    }

    const message = (
        <SimpleText style={styles.description}>
            Vous pouvez créer ou modifier votre message vocal afin de vous présenter auprès de
            vos futurs clients
        </SimpleText>
    );

    if (me) {
        // TODO : check actual voicemail & display
        const hasVoicemail = me?.expert?.voicemail != null;
        const title = hasVoicemail
            ? 'Modifier ma présentation'
            : 'Enregistrer ma présentation';
        const step =
            "Après avoir cliqué sur le bouton d'enregistrement, vous allez être appelé sur le " +
            me?.identity?.phone +
            " afin d'enregistrer votre message (90 secondes max.)";

        return (
            <CustomModal
                title="Présentation vocale"
                iconName="headphones"
                message={message}
                hasCloseIcon={true}
                visible={props.visible}
                onRequestClose={props.onRequestClose}
                mobileContentMaxWidth={500}
                desktopContentMaxWidth={600}
            >
                <SimpleText style={styles.tips}>
                    <Icons.Info size={16} style={{marginRight: 10}} color={'black'} />
                    L'enregistrement de votre présentation s'effectue en 3 étapes :
                </SimpleText>
                <Step number={1} title={'Enregistrez'} text={step} />
                <Step
                    number={2}
                    title={'Vérifiez'}
                    text={'Vous pouvez re-écouter votre message avant de le publier'}
                />
                <Step
                    number={3}
                    title={'Validez'}
                    text={
                        'Si votre message vous convient, cliquez sur le bouton de validation pour ajouter le message à votre profil'
                    }
                />

                {voicemail && (
                    <>
                        <Listen mp3={voicemail} title={'Ecouter la nouvelle présentation'} />
                        <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                            <Button
                                title={'Valider la nouvelle présentation'}
                                buttonStyle={{width: 200, alignSelf: 'center', marginTop: 10}}
                                onPress={update}
                            />
                            <Button
                                title={'Annuler'}
                                buttonStyle={{width: 200, alignSelf: 'center', marginTop: 10}}
                                onPress={cancel}
                            />
                        </View>
                    </>
                )}
                {!voicemail && hasVoicemail && !isRecording && (
                    <Listen
                        mp3={me?.expert?.voicemail}
                        title={'Ecouter ma présentation actuelle'}
                    />
                )}
                {isRecording && (
                    <SimpleText style={{alignSelf: 'center'}}>
                        Enregistrement en cours...
                    </SimpleText>
                )}
                {!voicemail && (
                    <Button
                        icon={<Icons.Micro size={16} style={{paddingRight: 10}} />}
                        title={title}
                        buttonStyle={{width: 250, alignSelf: 'center', marginTop: 10}}
                        onPress={recordVoicemail}
                        loading={isRecording}
                    />
                )}
            </CustomModal>
        );
    } else {
        return <></>;
    }
}

type ListenProps = {
    mp3: string | null | undefined;
    title: string;
};

function Listen(props: ListenProps) {
    const {play} = useAudio();

    async function playMp3() {
        if (props.mp3 != null) {
            play({uri: props.mp3});
        }
    }

    return (
        <TouchableOpacity onPress={playMp3} style={styles.play}>
            <Icons.AudioFile size={25} style={{paddingRight: 10}} color={Colors.link} />
            <SimpleText style={styles.title}>{props.title}</SimpleText>
        </TouchableOpacity>
    );
}

type StepProps = {
    number: number;
    title: string;
    text: string;
};

function Step(props: StepProps) {
    return (
        <SimpleText style={styles.tips}>
            <SimpleText style={styles.number}>
                {props.number} - {props.title} :{' '}
            </SimpleText>
            {props.text}
        </SimpleText>
    );
}

const styles = StyleSheet.create({
    description: {
        fontSize: 14,
    },
    tips: {
        fontSize: 14,
        fontStyle: 'italic',
        padding: 10,
    },
    number: {
        fontSize: 16,
        fontWeight: 'bold',
        padding: 5,
    },
    play: {
        flexDirection: 'row',
        alignSelf: 'center',
        paddingTop: 10,
    },
    title: {
        color: Colors.link,
        paddingTop: 5,
    },
});
