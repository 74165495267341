import * as React from 'react';
import {
    View,
    Image,
    ImageBackground,
    ViewStyle,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';

import * as Icons from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {isImg} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import HoverableView from './HoverableView';
import {EmptyModal} from './Modal';
import {SimpleText} from './Texts';

export function ProfilePicture({pictureName, ...props}: ProfilePictureProps) {
    const profilePicture = Settings.getUrlImageProfile(pictureName);
    return (
        <Image
            source={{uri: profilePicture}}
            accessibilityLabel={'Photo de profil de ' + props.displayName}
            {...props}
        />
    );
}

export function PicturesViewer({pictureNames, style}: PictureViewerProps) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const [displayedAttachement, setDisplayedAttachement] = React.useState<
        string | undefined
    >();

    if (!pictureNames || pictureNames.length <= 0) {
        return null;
    }

    let uri;
    if (displayedAttachement) {
        uri = Settings.getUrlImageAttachment(displayedAttachement);
    }

    return (
        <View style={[styles.picturesView, style]}>
            <EmptyModal
                visible={uri != undefined}
                onRequestClose={() => setDisplayedAttachement(undefined)}
            >
                <TouchableOpacity onPress={() => setDisplayedAttachement(undefined)}>
                    <Image source={{uri}} style={deviceStyles.fullscreenPicture} />
                </TouchableOpacity>
            </EmptyModal>
            <View style={deviceStyles.pictureList}>
                {pictureNames.map((a) => (
                    <Thumbnail
                        name={a}
                        key={`attachement_${a}`}
                        onPress={setDisplayedAttachement}
                    />
                ))}
            </View>
        </View>
    );
}

export function Thumbnail({name, onPress, style}: ThumbnailProps) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const uri = Settings.getUrlImageAttachment(name);
    const img = isImg(name);

    function onPressPdf() {
        window.open(uri);
    }

    return (
        <View style={[styles.picture, style]}>
            <HoverableView style={{}} hoverStyle={styles.pictureHover}>
                {!img && (
                    <TouchableOpacity onPress={() => onPressPdf()}>
                        <View style={deviceStyles.thumbnailIcon}>
                            <Icons.File size={24} color={Colors.secondary} />
                        </View>
                    </TouchableOpacity>
                )}
                {img && (
                    <TouchableOpacity onPress={() => onPress(name)}>
                        <Image source={{uri}} style={deviceStyles.thumbnail} />
                    </TouchableOpacity>
                )}
            </HoverableView>
        </View>
    );
}

export type ProfilePictureProps = Omit<Image['props'], 'source' | 'onError'> & {
    pictureName?: string | null;
    displayName?: string | null;
};

export type MobileProfilePictureProps = Omit<Image['props'], 'source' | 'onError'> & {
    pictureName?: string | null;
    icon: React.ReactNode;
};

export type ProfilePictureBackgroundProps = Omit<
    ImageBackground['props'],
    'source' | 'onError'
> & {
    pictureName?: string | null;
};

type ThumbnailProps = {
    name: string;
    onPress: (name: string) => void;
    style?: ViewStyle;
};

type PictureViewerProps = {
    pictureNames?: string[];
    style?: ViewStyle;
};

type PortraitProps = {
    image: string;
    name: string;
    job: string;
    email: string;
    phone: string;
    small?: boolean;
};

export function Portrait(props: PortraitProps) {
    return (
        <View style={styles.portrait}>
            <View style={styles.round}>
                <Image
                    style={styles.image}
                    source={{
                        uri: Settings.getUrlImageSupport(props.image),
                    }}
                    accessibilityLabel={props.name}
                />
            </View>
            <SimpleText style={styles.name}>{props.name}</SimpleText>
            {!props.small && (
                <>
                    <SimpleText style={styles.job}>{props.job}</SimpleText>
                    <SimpleText style={styles.email}>{props.email}</SimpleText>
                    <SimpleText style={styles.phone}>{props.phone}</SimpleText>
                </>
            )}
        </View>
    );
}

type PicProps = {
    small?: boolean;
};

export function Camille(props: PicProps) {
    return (
        <Portrait
            image={'camille.webp'}
            name={'Camille'}
            job={'Pôle Spirite'}
            email={'camille@kyvoitou.fr'}
            phone={Settings.support_telephone}
            small={props.small}
        />
    );
}

export function Jusine(props: PicProps) {
    return (
        <Portrait
            image={'justine.webp'}
            name={'Justine'}
            job={'Pôle Communication'}
            email={'justine@kyvoitou.fr'}
            phone={Settings.support_telephone}
            small={props.small}
        />
    );
}

const styles = StyleSheet.create({
    picturesView: {},
    pictureList: {
        flexDirection: 'row',
    },
    thumbnail: {
        resizeMode: 'contain',
        width: 75,
        height: 75,
        borderWidth: 1,
        borderColor: Colors.primary,
        borderRadius: 3,
    },
    thumbnailIcon: {
        width: 75,
        height: 75,
        borderWidth: 1,
        borderColor: Colors.primary,
        borderRadius: 3,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fullscreenPicture: {
        resizeMode: 'contain',
        width: 500,
        height: 500,
    },
    pictureHover: {
        opacity: 0.5,
    },
    picture: {
        marginRight: 10,
    },
    portrait: {
        alignItems: 'center',
        textAlign: 'center',
        margin: 30,
    },
    round: {
        margin: 25,
        borderRadius: 75,
        borderWidth: 3,
        borderColor: 'white',
        width: 156,
        height: 156,
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowRadius: 9,
        shadowOpacity: 1,
    },
    image: {
        width: 150,
        height: 150,
    },
    name: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    job: {
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'italic',
        marginTop: 10,
    },
    email: {
        fontSize: 16,
        marginTop: 10,
    },
    phone: {
        fontSize: 16,
        marginTop: 10,
    },
});

const mobileStyles = StyleSheet.create({
    pictureList: {
        marginTop: 15,
        justifyContent: 'space-between',
        flexDirection: 'row',
        maxWidth: '100%',
        flexWrap: 'wrap',
    },
    thumbnail: {
        resizeMode: 'contain',
        width: 50,
        height: 50,
        borderWidth: 1,
        borderColor: Colors.primary,
        borderRadius: 3,
        margin: 5,
    },
    thumbnailIcon: {
        width: 50,
        height: 50,
        borderWidth: 1,
        borderColor: Colors.primary,
        borderRadius: 3,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fullscreenPicture: {
        resizeMode: 'contain',
        width: 375,
        height: 375,
    },
});
