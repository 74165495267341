import * as React from 'react';
import {View} from 'react-native';

import {GradeItem} from '~/components/common/GradeItem';
import {filterEmpty, sorted} from '~/helpers/list';

export type Grade = {
    id: number;
    label: string;
    percentage: number;
};

type GradeListProps = {
    grades: Grade[];
};

export default function GradeList(props: GradeListProps) {
    function getGradeId(grade: Grade) {
        return grade.id;
    }

    const sortedGrades = sorted(filterEmpty(props.grades), getGradeId, false);
    return (
        <View>
            {sortedGrades.map((item, index) => (
                <GradeItem
                    grade={item.id}
                    text={item.label}
                    percentage={item.percentage}
                    key={`grade-item-${item.id}`}
                />
            ))}
        </View>
    );
}
