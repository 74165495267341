import * as React from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Platform, ViewStyle} from 'react-native';

import {Picker} from '@react-native-picker/picker';
import {Input as BaseInput} from 'react-native-elements';
import type {InputProps as DefaultInputProps} from 'react-native-elements';

import * as Icons from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';

export type InputProps = {style?: ViewStyle} & DefaultInputProps;
export type InputType = BaseInput;

const CustomInput = React.forwardRef<BaseInput, InputProps>(
    ({style, inputStyle, autoCapitalize, ...props}, ref) => {
        return (
            <BaseInput
                inputContainerStyle={[{borderBottomWidth: 0}, props.inputContainerStyle]}
                ref={ref}
                autoCapitalize={autoCapitalize || 'none'}
                style={style}
                inputStyle={[styles.input, StyleSheet.flatten(inputStyle)]}
                {...props}
            />
        );
    }
);

export const FormInput = React.forwardRef<BaseInput, InputProps>(
    ({inputStyle, ...props}, ref) => (
        <CustomInput inputStyle={[styles.formInput, inputStyle]} ref={ref} {...props} />
    )
);

export const IdentifiantInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Identifiant"
        autoCapitalize="none"
        keyboardType="default"
        autoCompleteType="name"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const MailInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Adresse email"
        autoCapitalize="none"
        keyboardType="email-address"
        autoCompleteType="email"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

type BirthDateDayInputProps = {
    day: string;
    setDay: (day: string) => void;
};

export function BirthDateDayInput(props: BirthDateDayInputProps) {
    return (
        <Picker
            selectedValue={props.day}
            style={styles.dayPicker}
            onValueChange={(itemValue, itemIndex) => props.setDay(itemValue)}
        >
            <Picker.Item label="1" value="01" />
            <Picker.Item label="1" value="02" />
            <Picker.Item label="3" value="03" />
            <Picker.Item label="4" value="04" />
            <Picker.Item label="5" value="05" />
            <Picker.Item label="6" value="06" />
            <Picker.Item label="7" value="07" />
            <Picker.Item label="8" value="08" />
            <Picker.Item label="9" value="09" />
            <Picker.Item label="10" value="10" />
            <Picker.Item label="11" value="11" />
            <Picker.Item label="12" value="12" />
            <Picker.Item label="13" value="13" />
            <Picker.Item label="14" value="14" />
            <Picker.Item label="15" value="15" />
            <Picker.Item label="16" value="16" />
            <Picker.Item label="17" value="17" />
            <Picker.Item label="18" value="18" />
            <Picker.Item label="19" value="19" />
            <Picker.Item label="20" value="20" />
            <Picker.Item label="21" value="21" />
            <Picker.Item label="22" value="22" />
            <Picker.Item label="23" value="23" />
            <Picker.Item label="24" value="24" />
            <Picker.Item label="25" value="25" />
            <Picker.Item label="26" value="26" />
            <Picker.Item label="27" value="27" />
            <Picker.Item label="28" value="28" />
            <Picker.Item label="29" value="29" />
            <Picker.Item label="30" value="30" />
            <Picker.Item label="31" value="31" />
        </Picker>
    );
}

type BirthDateMonthInputProps = {
    month: string;
    setMonth: (month: string) => void;
};

export function BirthDateMonthInput(props: BirthDateMonthInputProps) {
    return (
        <Picker
            selectedValue={props.month}
            style={styles.monthPicker}
            onValueChange={(itemValue, itemIndex) => props.setMonth(itemValue)}
        >
            <Picker.Item label="Janvier" value="01" />
            <Picker.Item label="Février" value="02" />
            <Picker.Item label="Mars" value="03" />
            <Picker.Item label="Avril" value="04" />
            <Picker.Item label="Mai" value="05" />
            <Picker.Item label="Juin" value="06" />
            <Picker.Item label="Juillet" value="07" />
            <Picker.Item label="Aout" value="08" />
            <Picker.Item label="Septembre" value="09" />
            <Picker.Item label="Octobre" value="10" />
            <Picker.Item label="Novembre" value="11" />
            <Picker.Item label="Décembre" value="12" />
        </Picker>
    );
}

type BirthDateYearInputProps = {
    year: string;
    setYear: (year: string) => void;
};

export function BirthDateYearInput(props: BirthDateYearInputProps) {
    return (
        <Picker
            selectedValue={props.year}
            style={styles.yearPicker}
            onValueChange={(itemValue, itemIndex) => props.setYear(itemValue)}
        >
            <Picker.Item label="1900" value="1900" />
            <Picker.Item label="1901" value="1901" />
            <Picker.Item label="1902" value="1902" />
            <Picker.Item label="1903" value="1903" />
            <Picker.Item label="1904" value="1904" />
            <Picker.Item label="1905" value="1905" />
            <Picker.Item label="1906" value="1906" />
            <Picker.Item label="1907" value="1907" />
            <Picker.Item label="1908" value="1908" />
            <Picker.Item label="1909" value="1909" />
            <Picker.Item label="1910" value="1910" />
            <Picker.Item label="1911" value="1911" />
            <Picker.Item label="1912" value="1912" />
            <Picker.Item label="1913" value="1913" />
            <Picker.Item label="1914" value="1914" />
            <Picker.Item label="1915" value="1915" />
            <Picker.Item label="1916" value="1916" />
            <Picker.Item label="1917" value="1917" />
            <Picker.Item label="1918" value="1918" />
            <Picker.Item label="1919" value="1919" />
            <Picker.Item label="1920" value="1920" />
            <Picker.Item label="1921" value="1921" />
            <Picker.Item label="1922" value="1922" />
            <Picker.Item label="1923" value="1923" />
            <Picker.Item label="1924" value="1924" />
            <Picker.Item label="1925" value="1925" />
            <Picker.Item label="1926" value="1926" />
            <Picker.Item label="1927" value="1927" />
            <Picker.Item label="1928" value="1928" />
            <Picker.Item label="1929" value="1929" />
            <Picker.Item label="1930" value="1930" />
            <Picker.Item label="1931" value="1931" />
            <Picker.Item label="1932" value="1932" />
            <Picker.Item label="1933" value="1933" />
            <Picker.Item label="1934" value="1934" />
            <Picker.Item label="1935" value="1935" />
            <Picker.Item label="1936" value="1936" />
            <Picker.Item label="1937" value="1937" />
            <Picker.Item label="1938" value="1938" />
            <Picker.Item label="1939" value="1939" />
            <Picker.Item label="1940" value="1940" />
            <Picker.Item label="1941" value="1941" />
            <Picker.Item label="1942" value="1942" />
            <Picker.Item label="1943" value="1943" />
            <Picker.Item label="1944" value="1944" />
            <Picker.Item label="1945" value="1945" />
            <Picker.Item label="1946" value="1946" />
            <Picker.Item label="1947" value="1947" />
            <Picker.Item label="1948" value="1948" />
            <Picker.Item label="1949" value="1949" />
            <Picker.Item label="1950" value="1950" />
            <Picker.Item label="1951" value="1951" />
            <Picker.Item label="1952" value="1952" />
            <Picker.Item label="1953" value="1953" />
            <Picker.Item label="1954" value="1954" />
            <Picker.Item label="1955" value="1955" />
            <Picker.Item label="1956" value="1956" />
            <Picker.Item label="1957" value="1957" />
            <Picker.Item label="1958" value="1958" />
            <Picker.Item label="1959" value="1959" />
            <Picker.Item label="1960" value="1960" />
            <Picker.Item label="1961" value="1961" />
            <Picker.Item label="1962" value="1962" />
            <Picker.Item label="1963" value="1963" />
            <Picker.Item label="1964" value="1964" />
            <Picker.Item label="1965" value="1965" />
            <Picker.Item label="1966" value="1966" />
            <Picker.Item label="1967" value="1967" />
            <Picker.Item label="1968" value="1968" />
            <Picker.Item label="1969" value="1969" />
            <Picker.Item label="1970" value="1970" />
            <Picker.Item label="1971" value="1971" />
            <Picker.Item label="1972" value="1972" />
            <Picker.Item label="1973" value="1973" />
            <Picker.Item label="1974" value="1974" />
            <Picker.Item label="1975" value="1975" />
            <Picker.Item label="1976" value="1976" />
            <Picker.Item label="1977" value="1977" />
            <Picker.Item label="1978" value="1978" />
            <Picker.Item label="1979" value="1979" />
            <Picker.Item label="1980" value="1980" />
            <Picker.Item label="1981" value="1981" />
            <Picker.Item label="1982" value="1982" />
            <Picker.Item label="1983" value="1983" />
            <Picker.Item label="1984" value="1984" />
            <Picker.Item label="1985" value="1985" />
            <Picker.Item label="1986" value="1986" />
            <Picker.Item label="1987" value="1987" />
            <Picker.Item label="1988" value="1988" />
            <Picker.Item label="1989" value="1989" />
            <Picker.Item label="1990" value="1990" />
            <Picker.Item label="1991" value="1991" />
            <Picker.Item label="1992" value="1992" />
            <Picker.Item label="1993" value="1993" />
            <Picker.Item label="1994" value="1994" />
            <Picker.Item label="1995" value="1995" />
            <Picker.Item label="1996" value="1996" />
            <Picker.Item label="1997" value="1997" />
            <Picker.Item label="1998" value="1998" />
            <Picker.Item label="1999" value="1999" />
            <Picker.Item label="2000" value="2000" />
            <Picker.Item label="2001" value="2001" />
            <Picker.Item label="2002" value="2002" />
            <Picker.Item label="2003" value="2003" />
            <Picker.Item label="2004" value="2004" />
            <Picker.Item label="2005" value="2005" />
            <Picker.Item label="2006" value="2006" />
            <Picker.Item label="2007" value="2007" />
            <Picker.Item label="2008" value="2008" />
            <Picker.Item label="2009" value="2009" />
            <Picker.Item label="2010" value="2010" />
            <Picker.Item label="2011" value="2011" />
            <Picker.Item label="2012" value="2012" />
            <Picker.Item label="2013" value="2013" />
            <Picker.Item label="2014" value="2014" />
            <Picker.Item label="2015" value="2015" />
            <Picker.Item label="2016" value="2016" />
            <Picker.Item label="2017" value="2017" />
            <Picker.Item label="2018" value="2018" />
            <Picker.Item label="2019" value="2019" />
            <Picker.Item label="2020" value="2020" />
        </Picker>
    );
}

export const PasswordInput = React.forwardRef<BaseInput, InputProps>((props, ref) => {
    const [isSecure, setIsSecure] = React.useState<boolean>(true);

    return (
        <FormInput
            placeholder="Mot de passe"
            secureTextEntry={isSecure}
            autoCompleteType="password"
            spellCheck={false}
            ref={ref}
            rightIcon={
                <Icons.Secure
                    size={18}
                    color={Colors.primary}
                    isSecure={isSecure}
                    onClick={() => setIsSecure(!isSecure)}
                    style={{cursor: 'pointer', marginLeft: 5, width: 24}}
                />
            }
            {...props}
        />
    );
});

export const SubjectInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Sujet du message"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const MessageInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Comment pouvons nous vous aider ?"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={4}
        ref={ref}
        {...props}
    />
));

export const ReviewInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Mon évaluation..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={7}
        ref={ref}
        {...props}
    />
));

export const FunctionInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Ma fonction..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const CatchPhraseInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Ma phrase d'accroche..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const ShortDescriptionInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Ma description courte..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const LongDescriptionInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Ma description longue..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={5}
        ref={ref}
        {...props}
    />
));

export const ExperienceInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Mon expérience..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const ServiceDescriptionInput = React.forwardRef<BaseInput, InputProps>(
    (props, ref) => (
        <FormInput
            placeholder="..."
            secureTextEntry={false}
            autoCompleteType="off"
            spellCheck={false}
            multiline={true}
            numberOfLines={3}
            ref={ref}
            {...props}
        />
    )
);

export const ContentInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Contenu du message..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={15}
        ref={ref}
        {...props}
    />
));

export const ResponseInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Rédiger une réponse..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={15}
        ref={ref}
        {...props}
    />
));

export const ChatInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <CustomInput
        placeholder="Entrez votre message ici"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={false}
        numberOfLines={1}
        enablesReturnKeyAutomatically={true}
        ref={ref}
        {...props}
    />
));

export const RecallInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Entrez votre message ici"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={5}
        maxLength={1000}
        enablesReturnKeyAutomatically={true}
        ref={ref}
        {...props}
    />
));

export const NoteInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Saisir vos notes..."
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={7}
        maxLength={5000}
        enablesReturnKeyAutomatically={true}
        ref={ref}
        {...props}
    />
));

export const SurnameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Prénom"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const LastnameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Nom"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const AddressInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Adresse"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const AddressComplementInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Complément"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const PostalCodeInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Code postal"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const CityInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Ville"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const CountryInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Pays"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const ReviewResponseInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Ma réponse"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={3}
        ref={ref}
        {...props}
    />
));

export const PresentationInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Votre présentation"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={5}
        ref={ref}
        {...props}
    />
));

export const CareerInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Votre parcours"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={5}
        ref={ref}
        {...props}
    />
));

export const ProfilInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Votre profil"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        multiline={true}
        numberOfLines={5}
        ref={ref}
        {...props}
    />
));

export const FirstNameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Votre prénom"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const BirthNameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Votre nom"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const OtherNameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Autres prénoms"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const BirthDateInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="JJ/MM/AAAA"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const BirthHourInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Votre heure de naissance"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const BirthPlaceInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Votre lieu de naissance"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const BirthDayPostalCodeInput = React.forwardRef<BaseInput, InputProps>(
    (props, ref) => (
        <FormInput
            placeholder="Code Postal"
            secureTextEntry={false}
            autoCompleteType="off"
            spellCheck={false}
            ref={ref}
            {...props}
        />
    )
);

export const BirthDayCityInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Ville"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const SpouseFirstNameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Prénom de votre conjoint"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const SpouseBirthNameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Nom de votre conjoint"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const SpouseOtherNameInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Autres prénoms de votre conjoint"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const SpouseBirthDateInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="JJ/MM/AAAA"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const SpouseBirthHourInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Heure de naissance de votre conjoint"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

export const SpouseBirthPlaceInput = React.forwardRef<BaseInput, InputProps>((props, ref) => (
    <FormInput
        placeholder="Lieu de naissance de votre conjoint"
        secureTextEntry={false}
        autoCompleteType="off"
        spellCheck={false}
        ref={ref}
        {...props}
    />
));

const styles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontSize: 30,
    },
    codeFieldRoot: {
        marginTop: 20,
    },
    cell: {
        width: 40,
        height: 40,
        lineHeight: 38,
        fontSize: 24,
        textAlign: 'center',
    },
    container: {
        flexDirection: 'row',
        paddingBottom: 10,
        alignContent: 'space-around',
        padding: 0,
    },
    input: {
        fontSize: 14,
    },
    formInput: {
        borderWidth: 1,
        borderRadius: 10,
        paddingLeft: 30,
        paddingRight: 20,
        paddingVertical: 10,
        borderColor: Colors.primary,
    },
    dayPicker: {
        borderWidth: 1,
        borderRadius: 10,
        padding: 5,
        borderColor: Colors.primary,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 5,
        width: 50,
    },
    monthPicker: {
        borderWidth: 1,
        borderRadius: 10,
        padding: 5,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 5,
        marginLeft: 10,
        width: 100,
    },
    yearPicker: {
        borderWidth: 1,
        borderRadius: 10,
        padding: 5,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 5,
        marginLeft: 10,
        width: 75,
    },
});

export default CustomInput;
