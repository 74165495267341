import React, {useState} from 'react';
import {View, StyleSheet, TouchableOpacity, Image} from 'react-native';

import * as DocumentPicker from 'expo-document-picker';

import {Bloc} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';

import PopupAccount from './PopupAccount';
import PopupEmail from './PopupEmail';
import PopupImage from './PopupImage';
import PopupPassword from './PopupPassword';

export default function BlocAccount() {
    const alert = useAlert();
    const {me, loadingMe, refetchMe} = React.useContext(UserContext);
    const [modalAccountVisible, setModalAccountVisible] = useState(false);
    const [modalEmailVisible, setModalEmailVisible] = useState(false);
    const [modalPasswordVisible, setModalPasswordVisible] = useState(false);
    const [modalImageVisible, setModalImageVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    function success() {
        alert({
            message: 'Vos données ont bien été modifiées',
        });
    }

    function modifyAccount() {
        setModalAccountVisible(true);
    }

    function onSuccessAccount() {
        setModalAccountVisible(false);
        refetchMe();
        success();
    }

    function onRequestCloseAccount() {
        setModalAccountVisible(false);
    }

    async function modifyEmail() {
        setModalEmailVisible(true);
    }

    function onSuccessEmail() {
        setModalEmailVisible(false);
        refetchMe();
        success();
    }

    function onRequestCloseEmail() {
        setModalEmailVisible(false);
    }

    async function modifyPassword() {
        setModalPasswordVisible(true);
    }

    function onSuccessPassword() {
        setModalPasswordVisible(false);
        refetchMe();
        success();
    }

    function onRequestClosePassword() {
        setModalPasswordVisible(false);
    }

    function onSuccessImage() {
        setModalImageVisible(false);
        success();
    }

    function onRequestCloseImage() {
        setModalImageVisible(false);
    }

    let openImagePickerAsync = async () => {
        let pickerResult = await DocumentPicker.getDocumentAsync({
            multiple: false,
            type: 'image/*',
        });

        if (pickerResult && !pickerResult.canceled && pickerResult.output && pickerResult.assets.length > 0) {
            setSelectedImage(pickerResult.assets[0].uri);
            setModalImageVisible(true);
        }
    };

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    return (
        <Bloc title="Mes informations" iconName="address-card">
            <View style={styles.container}>
                <TouchableOpacity
                    style={styles.editProfilePicture_container}
                    onPress={openImagePickerAsync}
                >
                    <ProfilePicture
                        style={styles.profilePicture}
                        pictureName={me.profile?.pictureName}
                        resizeMode={'cover'}
                        displayName={me?.profile?.displayName}
                    />
                    <View style={styles.editPictureContainer}>
                        <Icons.EditBox size={16} style={styles.editProfilePicture} />
                    </View>
                </TouchableOpacity>
                <View style={styles.detailContainer}>
                    <View style={styles.fieldContainer}>
                        <View style={styles.pictoLabelContainer}>
                            <Icons.Envelope size={14} color={Colors.secondary} />
                            <SimpleText style={styles.email} numberOfLines={1}>
                                : {me?.identity?.email}
                            </SimpleText>
                        </View>
                        <TouchableOpacity onPress={modifyEmail}>
                            <Icons.EditBox size={16} color={Colors.primary} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.fieldContainer}>
                        <View style={styles.pictoLabelContainer}>
                            <Icons.Lock size={14} color={Colors.secondary} />
                            <SimpleText style={styles.password}>: *********</SimpleText>
                        </View>
                        <TouchableOpacity onPress={modifyPassword}>
                            <Icons.EditBox size={16} color={Colors.primary} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.fieldContainer}>
                        <View style={styles.pictoLabelContainer}>
                            <Icons.Phone size={14} color={Colors.secondary} />
                            <View>
                                <SimpleText style={styles.phone}>
                                    : {me?.identity?.phone}
                                </SimpleText>
                            </View>
                        </View>
                        <TouchableOpacity onPress={modifyAccount}>
                            <Icons.EditBox size={16} color={Colors.primary} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <PopupAccount
                visible={modalAccountVisible}
                onRequestClose={onRequestCloseAccount}
                onSuccess={onSuccessAccount}
            />
            <PopupEmail
                visible={modalEmailVisible}
                onRequestClose={onRequestCloseEmail}
                onSuccess={onSuccessEmail}
            />
            <PopupPassword
                visible={modalPasswordVisible}
                onRequestClose={onRequestClosePassword}
                onSuccess={onSuccessPassword}
            />
            <PopupImage
                visible={modalImageVisible}
                onRequestClose={onRequestCloseImage}
                onSuccess={onSuccessImage}
                selectedImage={selectedImage}
            />
        </Bloc>
    );
}

const styles = StyleSheet.create({
    description: {
        textAlign: 'center',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        margin: 20,
    },
    modal_account_titleContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    modal_account_picto: {
        width: 29,
        height: 22,
    },
    modal_account_title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
    },
    close: {
        width: 23,
        height: 20,
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    titleTextContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'flex-start',
    },
    picto: {
        width: 29,
        height: 22,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
    },
    detailContainer: {
        padding: 10,
        borderRadius: 5,
        marginLeft: 10,
        width: 188,
        height: 110,
        backgroundColor: 'white',
        justifyContent: 'space-evenly',
    },
    email: {
        marginLeft: 5,
        fontSize: 12,
    },
    password: {
        marginLeft: 5,
        fontSize: 12,
    },
    phone: {
        marginLeft: 5,
        fontSize: 12,
    },
    profilePicture: {
        flex: 1,
    },
    editProfilePicture: {
        width: 20,
        height: 20,
        borderRadius: 5,
    },
    editProfilePicture_container: {
        alignContent: 'center',
        width: 110,
        height: 110,
        borderColor: Colors.primary,
        borderWidth: 3,
        borderRadius: 5,
        overflow: 'hidden',
        /* hack to hide a bug */
        borderTopWidth: 2,
        borderLeftWidth: 2,
        backgroundColor: Colors.primary,
        /* END: hack to hide a bug */
    },
    editPictureContainer: {
        height: 24,
        position: 'absolute',
        top: 5,
        right: 5,
    },
    fieldContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    pictoLabelContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
});
