import {gql, useQuery} from '@apollo/client';

import {GetClientOffers} from './types/GetClientOffers';

const GET_CLIENT_OFFERS = gql`
    query GetClientOffers {
        getClientOffers {
            edges {
                node {
                    clientOfferId
                    expertId
                    offerId
                    status
                    isRestricted
                    expirationDate
                    offer {
                        label
                        freeMinuteInteger
                    }
                }
            }
        }
    }
`;

export default function useOffers(skip: boolean) {
    return useQuery<GetClientOffers>(GET_CLIENT_OFFERS, {
        skip: skip,
        fetchPolicy: 'network-only',
    });
}
