import React, {useState, useEffect} from 'react';
import {StyleSheet, View} from 'react-native';

import {useFocusEffect} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {getAcceptation, setAcceptation} from '~/helpers/cookie';
import {injectGtm} from '~/helpers/injectWebCss';

type PopupCookieProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

export default function PopupCookie(props: PopupCookieProps) {
    const [visible, setVisible] = useState<boolean>(false);

    useFocusEffect(
        React.useCallback(() => {
            const requestId = requestAnimationFrame(load);
            return () => cancelAnimationFrame(requestId);
        }, [])
    );

    useEffect(() => {
        if (props.visible) {
            setVisible(true);
        }
    }, [props.visible]);

    async function load() {
        if (!visible) {
            const value = await getAcceptation();
            if (value === null) {
                setVisible(true);
                props.setVisible(true);
            }
        }
    }

    async function accept() {
        await setAcceptation('true');
        setVisible(false);
        props.setVisible(false);
        injectGtm();
    }

    async function refuse() {
        await setAcceptation('false');
        setVisible(false);
        props.setVisible(false);
    }

    if (!visible) {
        return <></>;
    } else {
        return (
            <CustomModal
                visible={visible}
                iconName={'cookie-bite'}
                title={'Gérer mes cookies'}
                style={styles.modal}
            >
                <View style={styles.container}>
                    <View style={styles.icon}>
                        <Icons.Cogs
                            size={14}
                            color={Colors.secondary}
                            style={{alignSelf: 'center'}}
                        />
                        <SimpleText style={styles.title}>Utilisation</SimpleText>
                    </View>
                    <SimpleText>
                        Notre site et son partenaire Google Analytics utilisent des cookies
                        pour receuillir des statistiques de navigation et nous permettre
                        d'améliorer notre site.
                    </SimpleText>
                    <View style={styles.check}>
                        <Icons.Check
                            size={14}
                            color={Colors.secondary}
                            style={{alignSelf: 'center'}}
                        />
                        <SimpleText style={styles.text}>
                            Aucun cookie n'est utilisé à des fins publicitaires.
                        </SimpleText>
                    </View>
                    <View style={styles.check}>
                        <Icons.Check
                            size={14}
                            color={Colors.secondary}
                            style={{alignSelf: 'center'}}
                        />
                        <SimpleText style={styles.text}>
                            Aucune publicité n'est présente sur notre site.
                        </SimpleText>
                    </View>
                    <View style={styles.icon}>
                        <Icons.Clock
                            size={14}
                            color={Colors.secondary}
                            style={{alignSelf: 'center'}}
                        />
                        <SimpleText style={styles.title}>Conservation</SimpleText>
                    </View>
                    <SimpleText>
                        Nous conservons votre choix pendant 6 mois. Vous pouvez changer d'avis
                        à tout moment en cliquant sur "Gérer mes cookies" en bas de chaque page
                        de notre site.
                    </SimpleText>
                </View>
                <View style={styles.buttons}>
                    <Button title={'Accepter'} onPress={accept} />
                    <Button title={'Refuser'} onPress={refuse} />
                </View>
            </CustomModal>
        );
    }
}

const styles = StyleSheet.create({
    modal: {
        maxWidth: 600,
    },
    container: {
        paddingHorizontal: 15,
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 25,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 15,
    },
    icon: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 10,
    },
    check: {
        marginLeft: 10,
        flexDirection: 'row',
        marginTop: 5,
        marginBottom: 5,
    },
    text: {
        marginLeft: 10,
        fontSize: 14,
    },
});
