import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';

import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import {MailInput} from '~/components/common/Inputs';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {useAlert} from '~/contexts/AlertContext';
import {subscription} from '~/helpers/newsletter';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

const {email} = yupRules;
const validationSchema = yup.object().shape({email});

type Inputs = Pick<YupTypes, 'email'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

export default function Newsletter() {
    const alert = useAlert();
    const [email, setEmail] = useState<string>('');

    function onClose() {
        setEmail('');
    }

    async function submitNewsletter(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        subscription(values.email);
        alert({
            message: 'Votre email a bien été inscrit à la newsletter.',
            onClose: onClose,
            width: 400,
        });
        resetForm();
    }

    return (
        <View style={styles.container}>
            <View style={styles.titleContainer}>
                <SimpleText style={styles.title}>Inscription</SimpleText>
                <SimpleText style={styles.subtitle}>à la newsletter</SimpleText>
            </View>
            <View style={styles.descriptionContainer}>
                <SimpleText style={styles.description}>
                    L'astrologie, la spiritualité, le développement personnel vous intéressent
                    ?
                </SimpleText>
                <SimpleText style={styles.description}>
                    Inscrivez-vous à notre newsletter * et recevez chaque semaine une bulle de
                    bien-être !
                </SimpleText>
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    onSubmit={submitNewsletter}
                    validationSchema={validationSchema}
                >
                    {(formikProps) => (
                        <View style={styles.inputContainer}>
                            <SimpleText style={styles.iconContainer}>@</SimpleText>
                            <MailInput
                                placeholder={'Adresse email'}
                                style={styles.input}
                                containerStyle={{paddingLeft: 0, paddingRight: 0, width: 500}}
                                value={formikProps.values.email}
                                onChangeText={formikProps.handleChange('email')}
                                onBlur={() => formikProps.setFieldTouched('email')}
                                blurOnSubmit={true}
                                errorMessage={formikProps.errors.email || ' '}
                            />
                            <Button
                                title="S'inscrire"
                                buttonStyle={{
                                    width: 115,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    margin: 0,
                                }}
                                style={{margin: 0}}
                                containerStyle={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    margin: 0,
                                    height: 41,
                                }}
                                titleStyle={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 3,
                                    fontSize: 14,
                                    fontWeight: 'normal',
                                    textTransform: 'uppercase',
                                }}
                                disabled={!formikProps.isValid}
                                onPress={() => formikProps.handleSubmit()}
                                loading={formikProps.isSubmitting}
                            />
                        </View>
                    )}
                </Formik>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        padding: 40,
        paddingBottom: 25,
        marginTop: 45,
        backgroundColor: Colors.dark,
        justifyContent: 'center',
    },
    titleContainer: {
        marginRight: 75,
    },
    title: {
        fontSize: 42,
        alignSelf: 'flex-start',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: Colors.secondary,
    },
    subtitle: {
        fontSize: 24,
        alignSelf: 'flex-start',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: Colors.secondary,
    },
    descriptionContainer: {
        paddingTop: 5,
    },
    description: {
        marginBottom: 2,
    },
    iconContainer: {
        backgroundColor: Colors.primary,
        paddingTop: 10,
        paddingLeft: 15,
        paddingBottom: 10,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        minWidth: 45,
        height: 41,
        color: 'white',
    },
    inputContainer: {
        flexDirection: 'row',
    },
    input: {
        backgroundColor: 'white',
        borderRadius: 0,
    },
});
