// TODO test this, it probably doesn't work yet
import * as React from 'react';

import {
    Confirm as BaseConfirm,
    ConfirmProps as BaseConfirmProps,
} from '~/components/common/Modal';
import useDeviceQuery from '~/hooks/useDeviceQuery';

const DEFAULT_SHOW_FUNCTION = (p: ConfirmProps) =>
    confirm('Confirmation indéfinie pour cet évènement');
export const ConfirmContext = React.createContext<ShowFunction>(DEFAULT_SHOW_FUNCTION);

export function ConfirmProvider(props: {children: React.ReactNode}) {
    // https://mmiller42.medium.com/advanced-compositional-react-with-usecontext-useref-and-usestate-51702dfedc71
    const [confirm, setConfirm] = React.useState<ConfirmHandle | null>(null);
    return (
        <ConfirmContext.Provider value={confirm?.show ?? DEFAULT_SHOW_FUNCTION}>
            {props.children}
            <Confirm ref={setConfirm} />
        </ConfirmContext.Provider>
    );
}

export function useConfirm() {
    return React.useContext(ConfirmContext);
}

export const Confirm = React.forwardRef<ConfirmHandle, {}>((props, ref) => {
    const {isDesktop} = useDeviceQuery();
    const [visible, setVisible] = React.useState(false);
    const [confirmProps, setConfirmProps] = React.useState<ConfirmProps | null>();
    React.useImperativeHandle(ref, () => ({show}), []);

    if (!confirmProps) {
        return null;
    }

    const {title, yesText, noText, onYes, onNo, ...otherProps} = confirmProps;

    function show(showProps: ConfirmProps) {
        setConfirmProps(showProps);
        setVisible(true);
    }

    function close() {
        setVisible(false);
        confirmProps?.onCancel?.();
    }

    function _onYes() {
        setVisible(false);
        onYes?.();
    }

    function _onNo() {
        setVisible(false);
        onNo?.();
    }

    return (
        <BaseConfirm
            {...otherProps}
            width={isDesktop ? 500 : 320}
            title={title ?? 'Notification'}
            visible={visible}
            onRequestClose={close}
            yesText={yesText ?? 'Oui'}
            onYes={_onYes}
            noText={noText ?? 'Non'}
            onNo={_onNo}
        />
    );
});

export type ConfirmProps = BaseConfirmProps & {
    onCancel?: () => void;
    yesText?: string;
    onYes?: () => void;
    noText?: string;
    onNo?: () => void;
    children?: React.ReactNode;
};

export type ShowFunction = (props: ConfirmProps) => void;

export type ConfirmHandle = {
    show: ShowFunction;
};
