import * as React from 'react';
import {StyleSheet, ImageBackground, TouchableOpacity, View, ViewProps} from 'react-native';

import {LinearGradient} from 'expo-linear-gradient';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {CallButton, MessageButton, ChatButton} from '~/components/expert/ExpertButtons';
import QualityList from '~/components/expert/QualityList';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import {
    expertGrades,
    expertQualities,
    getSpecialities,
    isFavorite,
    getSmallerMessagePrice,
    AVAILABLE,
    UNAVAILABLE,
    getText,
    minPriceText,
    isNew,
    getNumberOfSpecialities,
} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import useFavoriteMutation from '~/queries/mutations/setFavorite';
import {ExpertsDetailsQuery_findExperts_edges_node as Expert} from '~/queries/types/ExpertsDetailsQuery';
import type {ExpertProfile_getExpert} from '~/screens/types/ExpertProfile';

import GradeList from './GradeList';

export default function ExpertCard(props: ExpertCardProps) {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return (
            <View style={[styles.expertContainer, props.style]}>
                <CardHeader
                    user={props.user}
                    onFavoritePress={props.onFavoritePress}
                    onPressProfile={props.onPressProfile}
                    offer={props.offer}
                />
                <CardBody user={props.user} />
            </View>
        );
    } else {
        return (
            <MobileCard
                user={props.user}
                onFavoritePress={props.onFavoritePress}
                onPressProfile={props.onPressProfile}
                offer={props.offer}
            />
        );
    }
}

export function MobileCard({
    user,
    onFavoritePress,
    onPressProfile,
    offer,
}: ExpertCardHeaderProps) {
    const profile = user?.profile;
    const specialties = getSpecialities(user);
    const specialtiesNumber = getNumberOfSpecialities(user);
    const acceptOfferChat = user?.chatParameters?.acceptOffer ?? false;
    const acceptOfferCall = user?.callParameters?.acceptOffer ?? false;
    const acceptOffer = acceptOfferChat || acceptOfferCall;

    const price = minPriceText(user);

    let positiveReviewRate = '100%';
    if (user?.reviewSummary && user?.reviewSummary?.communicationNumber > 0) {
        positiveReviewRate = `${user?.reviewSummary?.positiveReviewRate}%`;
    }
    const communicationNumber = user?.reviewSummary?.communicationNumber;

    let offerText = '';
    if (offer > 0 && acceptOffer) {
        offerText = `${offer} min. gratuites puis ${price}`;
    } else {
        offerText = price;
    }

    const newProfil = isNew(user);
    const specialitySize = {fontSize: specialtiesNumber > 2 ? 11 : 14};

    return (
        <View style={mobileStyles.card}>
            <TouchableOpacity onPress={onPressProfile} style={mobileStyles.head}>
                <View style={mobileStyles.profil}>
                    <ProfilePicture
                        style={mobileStyles.picture}
                        pictureName={user?.profile?.pictureName}
                        displayName={user?.profile?.displayName}
                    />
                    {newProfil && <SimpleText style={mobileStyles.new}>NEW</SimpleText>}
                </View>
                <View style={mobileStyles.text}>
                    <SimpleText style={mobileStyles.pseudo}>{profile?.displayName}</SimpleText>
                    <SimpleText
                        style={[mobileStyles.speciality, specialitySize]}
                        numberOfLines={1}
                    >
                        {specialties}
                    </SimpleText>
                    <SimpleText style={mobileStyles.offer}>{offerText}</SimpleText>
                    <SimpleText style={mobileStyles.stats}>
                        <View style={mobileStyles.iconNumber}>
                            <Icons.SmileAlt
                                size={12}
                                color={Colors.secondary}
                                style={{alignSelf: 'center'}}
                            />
                            <SimpleText style={mobileStyles.prct}>
                                {positiveReviewRate}
                            </SimpleText>
                        </View>
                        <View style={mobileStyles.iconNumber}>
                            <Icons.ChatAlt
                                size={12}
                                color={Colors.secondary}
                                style={{alignSelf: 'center'}}
                            />
                            <SimpleText style={mobileStyles.nb}>
                                {communicationNumber}
                            </SimpleText>
                        </View>
                    </SimpleText>
                </View>
            </TouchableOpacity>
            <MobileExpertButtons user={user} onFavoritePress={onFavoritePress} />
        </View>
    );
}

type MobileExpertButtonsProps = {
    user: Expert | ExpertProfile_getExpert | undefined | null;
    onFavoritePress?: () => void;
};

export function MobileExpertButtons({user, onFavoritePress}: MobileExpertButtonsProps) {
    const {me, refetchMe, loadingMe} = React.useContext(UserContext);
    const alert = useAlert();

    // We will use "optimisticIsFavorite" to toggle the favorite icon before the
    // mutation is actually over (ie., when "isSettingFavorite" will be true)
    const [optimisticIsFavorite, setOptimisticIsFavorite] = React.useState(false);
    const [setFavorite, {loading, called}] = useFavoriteMutation();
    const isSettingFavorite = loading || called || loadingMe;

    const expertId = user?.id.split(':')[1];
    const favorite = isSettingFavorite ? optimisticIsFavorite : isFavorite(me, expertId);

    async function toggleFavorite() {
        if (me?.isExpert) {
            alert({
                title: 'Erreur',
                message: 'Impossible de mettre en favori en tant que spirite.',
            });
            return;
        }
        if (!me?.id) {
            alert({
                title: 'Erreur',
                message: 'Impossible de mettre en favori, veuillez vous connecter.',
            });
            return;
        }
        const dataFavorite = {
            expertId: user?.id,
            isFavorite: !favorite,
        };
        setOptimisticIsFavorite(!favorite);
        await setFavorite({variables: dataFavorite});
        refetchMe();
    }
    const shadow = {
        shadowColor: Colors.secondary,
        shadowOpacity: 0.2,
        shadowRadius: 3,
        shadowOffset: {
            height: 3,
            width: 0,
        },
        margin: 5,
        height: 36,
        width: 36,
    };

    return (
        <View style={mobileStyles.body}>
            <CallButton
                icon={<Icons.Phone size={25} color={Colors.secondary} />}
                style={{}}
                title=""
                titleStyle={{}}
                buttonStyle={mobileStyles.buttonStyle}
                containerStyle={mobileStyles.containerStyle}
                user={user}
                availableIconVisible={true}
            />
            <ChatButton
                icon={<Icons.Chat size={25} color={Colors.secondary} />}
                style={{}}
                title=""
                titleStyle={{}}
                buttonStyle={mobileStyles.buttonStyle}
                containerStyle={mobileStyles.containerStyle}
                user={user}
                availableIconVisible={true}
            />
            <MessageButton
                icon={<Icons.Mail size={25} color={Colors.secondary} />}
                style={{}}
                title=""
                titleStyle={{}}
                buttonStyle={mobileStyles.buttonStyle}
                containerStyle={mobileStyles.containerStyle}
                user={user}
                availableIconVisible={true}
            />
            <Button
                icon={
                    <Icons.Favorite size={25} color={Colors.primary} isFavorite={favorite} />
                }
                style={{margin: 0}}
                title=""
                titleStyle={{}}
                buttonStyle={{borderRadius: 10, backgroundColor: 'white'}}
                containerStyle={{
                    justifyContent: 'center',
                    width: 50,
                    height: 50,
                    borderRadius: 10,
                    borderColor: Colors.primary,
                    borderWidth: 2,
                }}
                onPress={onFavoritePress ?? toggleFavorite}
            />
        </View>
    );
}

function CardHeader({user, onFavoritePress, onPressProfile, offer}: ExpertCardHeaderProps) {
    const {me, refetchMe, loadingMe} = React.useContext(UserContext);
    // We will use "optimisticIsFavorite" to toggle the favorite icon before the
    // mutation is actually over (ie., when "isSettingFavorite" will be true)
    const [optimisticIsFavorite, setOptimisticIsFavorite] = React.useState(false);
    const [setFavorite, {loading, called}] = useFavoriteMutation();
    const isSettingFavorite = loading || called || loadingMe;

    const alert = useAlert();

    const expertInfo = user?.expert;

    const acceptOfferChat = user?.chatParameters?.acceptOffer ?? false;
    const acceptOfferCall = user?.callParameters?.acceptOffer ?? false;

    const callParameters = user?.callParameters;
    let callPrice = ' - ';
    if (callParameters?.price?.label) {
        callPrice = `${callParameters.price.label} / min`;
    }

    const smallerMessagePrice = getSmallerMessagePrice(user?.messageParameters);
    let messagePrice = ' - ';
    if (smallerMessagePrice) {
        messagePrice = smallerMessagePrice?.price;
    }

    const chatParameters = user?.chatParameters;
    let chatPrice = ' - ';
    if (chatParameters?.chatPrice?.label) {
        chatPrice = `${chatParameters.chatPrice.label} / min`;
    }

    const expertId = user?.id.split(':')[1];
    const favorite = isSettingFavorite ? optimisticIsFavorite : isFavorite(me, expertId);

    const newProfil = isNew(user);

    async function toggleFavorite() {
        if (me?.isExpert) {
            alert({
                title: 'Erreur',
                message: 'Impossible de mettre en favori en tant que spirite.',
            });
            return;
        }
        if (!me?.id) {
            alert({
                title: 'Erreur',
                message: 'Impossible de mettre en favori, veuillez vous connecter.',
            });
            return;
        }
        const dataFavorite = {
            expertId: user?.id,
            isFavorite: !favorite,
        };
        setOptimisticIsFavorite(!favorite);
        await setFavorite({variables: dataFavorite});
        refetchMe();
    }

    return (
        <View>
            <ImageBackground
                style={styles.skin}
                imageStyle={styles.skinImage}
                source={{
                    uri: Settings.getUrlImageSkinType(expertInfo?.skinType, 'small'),
                }}
                accessibilityLabel={'Photo de couverture de ' + user?.profile?.displayName}
            >
                <LinearGradient
                    colors={[Colors.opacity, 'transparent']}
                    style={styles.skinGradient}
                />
                <View style={styles.skinOverGradientView}>
                    <View style={styles.serviceContainer}>
                        <View style={styles.communicationColumn}>
                            <Icons.Phone
                                style={{marginTop: 20, alignSelf: 'center'}}
                                size={25}
                            />
                            <SimpleText style={styles.communicationText}>Téléphone</SimpleText>
                            <AvailableText
                                status={expertInfo?.callStatus}
                                offer={acceptOfferCall ? offer : 0}
                            />
                            <SimpleText style={styles.communicationPrice}>
                                {callPrice}
                            </SimpleText>
                            <CallButton
                                style={styles.communicationButton}
                                title="Appel"
                                titleStyle={{fontSize: 12}}
                                containerStyle={{padding: 2}}
                                user={user}
                            />
                        </View>
                        <View style={styles.communicationColumn}>
                            <Icons.Chat
                                style={{marginTop: 20, alignSelf: 'center'}}
                                size={25}
                            />
                            <SimpleText style={styles.communicationText}>Tchat</SimpleText>
                            <AvailableText
                                status={expertInfo?.chatStatus}
                                offer={acceptOfferChat ? offer : 0}
                            />
                            <SimpleText style={styles.communicationPrice}>
                                {chatPrice}
                            </SimpleText>
                            <ChatButton
                                style={styles.communicationButton}
                                title="Tchat"
                                titleStyle={{fontSize: 12}}
                                containerStyle={{padding: 2}}
                                buttonStyle={{margin: 0}}
                                user={user}
                            />
                        </View>
                        <View style={styles.communicationColumn}>
                            <Icons.Mail
                                style={{marginTop: 20, alignSelf: 'center'}}
                                size={25}
                            />
                            <SimpleText style={styles.communicationText}>Email</SimpleText>
                            <AvailableText status={expertInfo?.messageStatus} offer={0} />
                            <SimpleText style={styles.communicationPrice}>
                                {messagePrice}
                            </SimpleText>
                            <MessageButton
                                style={styles.communicationButton}
                                title="Question"
                                titleStyle={{fontSize: 12}}
                                containerStyle={{padding: 2}}
                                user={user}
                            />
                        </View>
                    </View>
                    <View style={styles.bottomSkinView}>
                        <Button
                            style={styles.profilButton}
                            title="Profil"
                            onPress={onPressProfile}
                            icon={<Icons.User size={15} />}
                            titleStyle={{fontSize: 12, marginLeft: 15, paddingBottom: 2}}
                            buttonStyle={{
                                borderRadius: 5,
                                backgroundColor: Colors.light,
                                width: 90,
                            }}
                            containerStyle={{}}
                        />
                        <View>
                            <ProfilePicture
                                style={styles.profilePicture}
                                pictureName={user?.profile?.pictureName}
                                displayName={user?.profile?.displayName}
                            />
                            {newProfil && <SimpleText style={styles.new}>NEW</SimpleText>}
                        </View>
                        <Button
                            style={styles.favoriteButton}
                            title={'Favoris'}
                            onPress={onFavoritePress ?? toggleFavorite}
                            icon={<Icons.Favorite isFavorite={favorite} size={15} />}
                            titleStyle={{fontSize: 12, marginLeft: 15, paddingBottom: 2}}
                            buttonStyle={{
                                borderRadius: 5,
                                backgroundColor: Colors.pause,
                                width: 90,
                            }}
                            containerStyle={{}}
                        />
                    </View>
                </View>
            </ImageBackground>
        </View>
    );
}

function AvailableText({status, offer}: {status: string | null | undefined; offer: number}) {
    function getStyle() {
        if (status == AVAILABLE) {
            return styles.available;
        }
        if (status == UNAVAILABLE) {
            return styles.unavailable;
        }
        return styles.pause;
    }

    if (status == AVAILABLE && offer > 0) {
        return <SimpleText style={styles.offer}>{offer} min. gratuites</SimpleText>;
    }

    const style = getStyle();
    const text = getText(status);
    return <SimpleText style={style}>{text}</SimpleText>;
}

function CardBody({user}: ExpertCardBodyProps) {
    const profile = user?.profile;
    const reviewSummary = user?.reviewSummary;
    const grades = expertGrades(user);
    const specialities = getSpecialities(user);
    let positiveReviewRate = '100%';
    if (reviewSummary && reviewSummary?.communicationNumber > 0) {
        positiveReviewRate = `${reviewSummary?.positiveReviewRate} %`;
    }
    let qualities = expertQualities(user);
    const specialitySize = {fontSize: specialities.length > 35 ? 13 : 16};

    return (
        <View style={styles.cardBody}>
            <View>
                <SimpleText style={styles.pseudo}>{profile?.displayName}</SimpleText>
                <SimpleText style={[styles.specialty, specialitySize]} numberOfLines={1}>
                    {specialities}
                </SimpleText>
            </View>
            <View style={styles.communicationAndReviewNumberContainerFunction}>
                <View style={styles.reviewNumberContainer}>
                    <SimpleText style={styles.reviewNumber}>{positiveReviewRate}</SimpleText>
                    <SimpleText style={styles.reviewNumberTitle}>Avis positifs</SimpleText>
                </View>
                <View style={styles.communicationContainer}>
                    <SimpleText style={styles.communications}>
                        {reviewSummary?.communicationNumber}
                    </SimpleText>
                    <SimpleText style={styles.communicationsTitle}>Communications</SimpleText>
                </View>
            </View>
            <GradeList grades={grades} />
            {qualities && qualities.length > 0 && (
                <View>
                    <SimpleText style={styles.qualitiesTitle}>
                        Qualités reconnues chez {profile?.displayName}
                    </SimpleText>
                    <QualityList qualities={qualities} />
                </View>
            )}
        </View>
    );
}

type ExpertCardHeaderProps = {
    user?: Expert;
    onPressProfile?: () => void;
};

type ExpertCardBodyProps = {
    user?: Expert;
};

export type ExpertCardProps = ViewProps & ExpertCardHeaderProps & ExpertCardBodyProps;

export function SmallCard({user, onPressProfile}: ExpertCardHeaderProps) {
    const profile = user?.profile;
    const specialties = getSpecialities(user);
    const specialtiesNumber = getNumberOfSpecialities(user);

    let positiveReviewRate = '100%';
    if (user?.reviewSummary && user?.reviewSummary?.communicationNumber > 0) {
        positiveReviewRate = `${user?.reviewSummary?.positiveReviewRate}%`;
    }
    const communicationNumber = user?.reviewSummary?.communicationNumber;

    const newProfil = isNew(user);
    const specialitySize = {fontSize: specialtiesNumber > 2 ? 11 : 14};

    return (
        <TouchableOpacity style={mobileStyles.card} onPress={onPressProfile}>
            <View style={mobileStyles.head}>
                <View style={mobileStyles.profil}>
                    <ProfilePicture
                        style={mobileStyles.picture}
                        pictureName={user?.profile?.pictureName}
                        displayName={user?.profile?.displayName}
                    />
                    {newProfil && <SimpleText style={mobileStyles.new}>NEW</SimpleText>}
                </View>
                <View style={mobileStyles.text}>
                    <SimpleText style={smallStyles.pseudo}>{profile?.displayName}</SimpleText>
                    <SimpleText
                        style={[smallStyles.speciality, specialitySize]}
                        numberOfLines={1}
                    >
                        {specialties}
                    </SimpleText>
                    <SimpleText style={smallStyles.stats}>
                        <View style={mobileStyles.iconNumber}>
                            <Icons.SmileAlt
                                size={18}
                                color={Colors.secondary}
                                style={{alignSelf: 'center'}}
                            />
                            <SimpleText style={smallStyles.prct}>
                                {positiveReviewRate}
                            </SimpleText>
                        </View>
                        <View style={mobileStyles.iconNumber}>
                            <Icons.ChatAlt
                                size={18}
                                color={Colors.secondary}
                                style={{alignSelf: 'center'}}
                            />
                            <SimpleText style={smallStyles.nb}>
                                {communicationNumber}
                            </SimpleText>
                        </View>
                    </SimpleText>
                </View>
            </View>
        </TouchableOpacity>
    );
}

const CARD_BORDER_RADIUS = 12;
const CARD_WIDTH = 349;
const CARD_HEIGHT = 713;
const DESKTOP_PICTURE_SIZE = 100;

const styles = StyleSheet.create({
    bottomSkinView: {
        width: CARD_WIDTH,
        flexDirection: 'row',
        alignItems: 'flex-end',
        position: 'absolute',
        justifyContent: 'space-between',
        top: 193,
    },
    expertContainer: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        borderRadius: CARD_BORDER_RADIUS,
        shadowColor: Colors.secondary,
        shadowOpacity: 0.16,
        shadowRadius: 10,
        shadowOffset: {
            height: 0,
            width: 0,
        },
        backgroundColor: 'white',
        marginTop: 40,
        marginBottom: 10,
    },
    profilePicture: {
        borderRadius: 60,
        width: DESKTOP_PICTURE_SIZE,
        height: DESKTOP_PICTURE_SIZE,
        marginBottom: 20,
        borderColor: 'white',
        borderWidth: 4,
        backgroundColor: Colors.dark,
    },
    favoriteButton: {},
    profilButton: {},
    cardBody: {
        flex: 1,
        paddingHorizontal: 17,
        justifyContent: 'space-around',
    },
    pseudo: {
        alignSelf: 'center',
        fontSize: 16,
        lineHeight: 19,
        fontWeight: 'bold',
    },
    specialty: {
        alignSelf: 'center',
    },
    descriptionTitle: {
        alignSelf: 'center',
        marginTop: 20,
        fontSize: 14,
    },
    description: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
        marginTop: 5,
        paddingLeft: 30,
        paddingRight: 30,
    },
    communicationText: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        color: 'white',
    },
    communicationButton: {
        alignSelf: 'center',
        width: 70,
        marginHorizontal: 0,
        marginVertical: 15,
        padding: 0,
    },
    available: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        color: Colors.nice,
    },
    unavailable: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        color: Colors.bad,
    },
    pause: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        color: Colors.pause,
    },
    communicationPrice: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        color: 'white',
    },
    skin: {
        alignSelf: 'center',
        width: CARD_WIDTH,
        height: 252,
        marginBottom: 55,
    },
    skinImage: {
        borderRadius: CARD_BORDER_RADIUS,
    },
    skinGradient: {
        zIndex: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        borderTopLeftRadius: CARD_BORDER_RADIUS,
        borderTopRightRadius: CARD_BORDER_RADIUS,
        height: 150,
    },
    skinOverGradientView: {
        zIndex: 2,
    },
    serviceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 14,
    },
    communicationColumn: {
        justifyContent: 'space-between',
        height: 187,
    },
    communicationAndReviewNumberContainerFunction: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 15,
    },
    communicationContainer: {
        alignSelf: 'center',
    },
    reviewNumberContainer: {
        alignSelf: 'center',
    },
    communicationsTitle: {
        alignSelf: 'center',
        fontSize: 14,
        color: Colors.fade,
    },
    communications: {
        alignSelf: 'center',
        fontSize: 18,
    },
    reviewNumberTitle: {
        alignSelf: 'center',
        fontSize: 14,
        color: Colors.fade,
    },
    reviewNumber: {
        alignSelf: 'center',
        fontSize: 18,
    },
    qualitiesTitle: {
        fontSize: 12,
        paddingBottom: 15,
        fontWeight: 'bold',
        color: Colors.fade,
        textAlign: 'center',
    },
    offer: {
        marginTop: 10,
        fontSize: 12,
        color: Colors.nice,
    },
    new: {
        position: 'absolute',
        top: 15,
        left: -8,
        backgroundColor: Colors.highlight,
        color: 'white',
        paddingVertical: 2,
        paddingHorizontal: 6,
        fontSize: 10,
        borderRadius: 5,
        fontWeight: 'bold',
    },
});

const MOBILE_PICTURE_SIZE = 70;

const mobileStyles = StyleSheet.create({
    card: {
        backgroundColor: 'white',
        paddingVertical: 15,
        borderRadius: 7,
        margin: 10,
        width: 320,
        borderWidth: 1,
        borderColor: Colors.disabled,
    },
    profil: {
        marginLeft: 15,
    },
    head: {
        flexDirection: 'row',
    },
    text: {
        borderLeftWidth: 1,
        borderLeftColor: Colors.disabled,
        marginHorizontal: 5,
        paddingLeft: 5,
        flex: 1,
    },
    pseudo: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.secondary,
    },
    speciality: {
        color: Colors.secondary,
        paddingTop: 2,
    },
    stats: {
        color: Colors.secondary,
        fontSize: 12,
        paddingTop: 2,
        justifyContent: 'space-between',
    },
    iconNumber: {
        flexDirection: 'row',
    },
    prct: {
        paddingLeft: 5,
        paddingRight: 15,
    },
    nb: {
        paddingLeft: 5,
    },
    body: {
        flexDirection: 'row',
        paddingTop: 5,
        justifyContent: 'space-evenly',
    },
    picture: {
        borderRadius: 15,
        width: MOBILE_PICTURE_SIZE,
        height: MOBILE_PICTURE_SIZE,
    },
    new: {
        position: 'absolute',
        top: 5,
        left: -10,
        backgroundColor: Colors.highlight,
        color: 'white',
        paddingVertical: 1,
        paddingHorizontal: 5,
        fontSize: 8,
        borderRadius: 4,
        fontWeight: 'bold',
    },
    offer: {
        color: Colors.nice,
        fontSize: 13,
        paddingTop: 2,
    },
    buttonStyle: {
        borderRadius: 10,
        backgroundColor: 'white',
    },
    containerStyle: {
        justifyContent: 'center',
        width: 50,
        height: 50,
        borderRadius: 10,
        borderColor: Colors.primary,
        borderWidth: 2,
    },
});

const smallStyles = StyleSheet.create({
    pseudo: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.secondary,
        paddingTop: 5,
    },
    speciality: {
        color: Colors.secondary,
        paddingTop: 5,
    },
    stats: {
        color: Colors.secondary,
        fontSize: 12,
        paddingTop: 5,
        justifyContent: 'space-between',
    },
    prct: {
        fontSize: 18,
        paddingLeft: 5,
        paddingRight: 15,
    },
    nb: {
        fontSize: 18,
        paddingLeft: 5,
    },
});
