import {CheckChatQuery_checkChat_exchanges_edges} from '~/components/user/chat/types/CheckChatQuery';
import {GetPreviousChatQuery_getPreviousChat_edges} from '~/components/user/chat/types/GetPreviousChatQuery';
import {sorted} from '~/helpers/list';

export type ChatExchange = {
    content: string;
    attachments: string[];
    exchangeType: string;
    exchangeId: string;
    time: number;
};

type Day = {
    index: number;
    date: string;
};

function getTime(e: ChatExchange) {
    return e.time;
}

function getDateExchange(date: string) {
    var today = new Date().toLocaleDateString();

    return {
        exchangeId: date,
        content: date == today ? "Aujourd'hui" : date,
        time: 0,
        exchangeType: 'DATE',
        attachments: [],
    } as ChatExchange;
}

export function getChatExchanges(
    src:
        | (CheckChatQuery_checkChat_exchanges_edges | null)[]
        | (GetPreviousChatQuery_getPreviousChat_edges | null)[],
    previous: boolean
) {
    if (src) {
        const exchanges = src.map((e) => {
            let attachments: string[] = [];
            if (e?.node?.attachments && e.node.attachments.edges) {
                attachments = e.node.attachments.edges.map((a) => {
                    return a?.node?.url ?? '';
                });
            }

            return {
                exchangeId: e?.node?.chatExchangeId,
                content: e?.node?.content,
                time: new Date(e?.node?.creationDate).getTime(),
                exchangeType: e?.node?.type,
                attachments: attachments,
            } as ChatExchange;
        });

        const sortedExchanges = sorted(exchanges, getTime, false);

        if (previous) {
            let date = '';
            let idx = 0;
            let days = [] as Day[];
            for (const e of sortedExchanges) {
                const d = new Date(e.time);
                const day = d.toLocaleDateString();
                if (day != date) {
                    if (date != '') {
                        days.push({
                            index: idx,
                            date: date,
                        } as Day);
                    }
                    date = day;
                }
                idx++;
            }

            for (const d of days) {
                sortedExchanges.splice(d.index, 0, getDateExchange(d.date));
            }
            if (date != '') {
                sortedExchanges.push(getDateExchange(date));
            }
        }

        return sortedExchanges;
    } else {
        return [];
    }
}
