import React, {useState} from 'react';

import {MainView} from '~/components/common/Containers';
import {PageTitle} from '~/components/common/Titles';
import PackList from '~/components/pack/PackList';

export default function PackScreen() {
    return (
        <MainView>
            <PageTitle>Nos packs</PageTitle>
            <PackList />
        </MainView>
    );
}
