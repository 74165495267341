import * as React from 'react';
import {StyleSheet} from 'react-native';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import {AlreadyConnected} from '~/components/navigation/Link';
import ForgottenPasswordForm from '~/components/user/ForgottenPasswordForm';
import {UserContext} from '~/contexts/UserContext';

export default function ForgottenPasswordScreen() {
    const {me} = React.useContext(UserContext);

    return (
        <MainView style={styles.container}>
            <SimpleText style={styles.loginTitle}>
                Un problème avec votre mot de passe
            </SimpleText>
            {!me && (
                <>
                    <SimpleText style={styles.loginSubTitle}>
                        Entrer votre adresse email !
                    </SimpleText>
                    <SimpleText style={styles.loginSubTitle}>
                        Kyvoitou.fr vous envoi un email pour réinitialiser votre mot de passe
                    </SimpleText>
                    <ForgottenPasswordForm />
                </>
            )}
            {me && <AlreadyConnected />}
        </MainView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    loginTitle: {
        fontSize: 30,
        alignSelf: 'center',
        marginBottom: 30,
        marginTop: 40,
        textAlign: 'center',
    },
    loginSubTitle: {
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
});
