// TODO use axios for fetches
import * as React from 'react';

import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    ApolloProvider as DefaultProvider,
} from '@apollo/client';
import {ApolloProviderProps as DefaultProviderProps} from '@apollo/client/react/context/ApolloProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {persistCache, AsyncStorageWrapper} from 'apollo3-cache-persist';

import {ScreenLoader} from '~/components/navigation/Loader';
import Settings from '~/constants/Settings';
import {getToken, signOut} from '~/helpers/login/auth';

const cache = new InMemoryCache();
const link = createHttpLink({uri: `${Settings.url}/graphql`, fetch: customFetch});
const defaultOptions = {
    query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
    },
};
const clientOptions = {link, cache, defaultOptions};

export const persistCacheTask = persistCache({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
});

async function customFetch(uri: string, options) {
    const authHeader = await getToken();
    if (authHeader) {
        options.headers.authorization = authHeader;
    }
    const result = await fetch(uri, options);
    if (options?.headers?.authorization && result.status == 400) {
        const data = await result.clone().json();
        const someAuthError = data.errors.some((err) => err.message == 'NOT_LOGGED_IN');
        if (someAuthError) {
            await signOut();
        }
    }
    return result;
}

export default function ApolloProvider(props: ApolloProviderProps) {
    const [client, setClient] = React.useState<Client>();

    React.useEffect(() => {
        async function initClient() {
            await persistCacheTask;
            setClient(new ApolloClient(clientOptions));
        }

        initClient();
    }, []);

    if (!client) {
        return <ScreenLoader />;
    }

    return <DefaultProvider client={client} {...props} />;
}

export type ApolloProviderProps = Omit<DefaultProviderProps<any>, 'client'>;
type Client = ApolloClient<NormalizedCacheObject>;
