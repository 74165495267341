import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {gql, useMutation} from '@apollo/client';

import {Bloc, BlocProps} from '~/components/common/Containers';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {AddPaymentButton} from '~/components/user/stripe/AddPaymentMethod';
import {UserContext} from '~/contexts/UserContext';
import type {MyPersonalInformation_me_identity_selectedPaymentMethod as StripeCard} from '~/contexts/types/MyPersonalInformation';
import {notEmpty} from '~/helpers/list';
import stripeFragments from '~/queries/fragments/stripe';

import {Card} from '../../stripe/CardInfo';
import type {CardFunction} from '../../stripe/CardInfo';

export default function BlocPayment(props: BlocProps) {
    const [selectPaymentMethod] = useMutation(SELECT_PAYMENT_METHOD);
    const [isCardPickerVisible, setCardPickerVisible] = React.useState(false);
    const showCardPicker = () => setCardPickerVisible(true);
    const hideCardPicker = () => setCardPickerVisible(false);

    function onCardPicked(card: StripeCard | null | undefined) {
        const data = {sessionId: card?.sessionId};
        selectPaymentMethod({variables: data});
        hideCardPicker();
    }

    return (
        <Bloc title="Mes moyens de paiement" iconName="credit-card" {...props}>
            <View style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
                <StripePaymentMethods onCardPress={showCardPicker} />
            </View>
            <CustomModal
                visible={isCardPickerVisible}
                onRequestClose={hideCardPicker}
                hasCloseIcon={true}
                title="Modification du moyen de paiement"
                message="Sélectionnez ou ajoutez un moyen de paiement pour vos communications futures."
            >
                <PickACard onCardPicked={onCardPicked} />
            </CustomModal>
        </Bloc>
    );
}

function StripePaymentMethods({onCardPress}: BlocPaymentProps) {
    const {me, loadingMe} = React.useContext(UserContext);

    if (loadingMe) {
        return <></>;
    }

    const stripe = me?.identity?.selectedPaymentMethod;
    const isPaymentValidated = Boolean(stripe?.isPaymentValidated);

    if (stripe && isPaymentValidated) {
        return (
            <>
                <SimpleText style={{paddingLeft: 10, paddingRight: 10}}>
                    Modifier votre moyen de paiement :
                </SimpleText>
                <Card stripe={stripe} onPress={onCardPress} preview={true} />
                {/* <Button onPress={onAddNewCard} title="Changer de moyen de paiement" /> */}
            </>
        );
    } else {
        return (
            <>
                <SimpleText style={{padding: 10, textAlign: 'center'}}>
                    Ajoutez un moyen de paiement pour pouvoir contacter nos spirites.
                </SimpleText>
                <AddPaymentButton
                    style={{marginVertical: 15}}
                    buttonStyle={{paddingHorizontal: 30}}
                    title="Enregistrer un moyen de paiement"
                />
            </>
        );
    }
}

function PickACard({onCardPicked}: PickACardProps) {
    const {me, loadingMe, refetchMe} = React.useContext(UserContext);

    if (loadingMe) {
        return <></>;
    }

    const selectedCard = me?.identity?.selectedPaymentMethod;

    const otherCards = me?.identity?.paymentMethods?.edges
        .map((edge) => edge?.node)
        .filter(notEmpty);

    function onDelete() {
        refetchMe();
    }

    return (
        <>
            {otherCards?.map((card) => (
                <Card
                    stripe={card}
                    selected={card.sessionId == selectedCard?.sessionId}
                    onPress={() => onCardPicked(card)}
                    key={card?.sessionId}
                    preview={false}
                    onDelete={onDelete}
                />
            ))}
            <AddPaymentButton
                title="Ajouter une nouvelle carte"
                style={{marginVertical: 15}}
                buttonStyle={{paddingHorizontal: 30}}
            />
        </>
    );
}

const SELECT_PAYMENT_METHOD = gql`
    mutation selectPaymentMethod($sessionId: String!) {
        selectPaymentMethod(input: {sessionId: $sessionId}) {
            ok
            identity {
                id
                selectedSessionId
                selectedPaymentMethod {
                    ...Card
                }
            }
        }
    }
    ${stripeFragments}
`;

export type BlocPaymentProps = {
    onCardPress?: CardFunction;
};

export type PickACardProps = {
    onCardPicked: CardFunction;
};

const styles = StyleSheet.create({});
