import React from 'react';
import {View, StyleSheet, ModalProps, ScrollView} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {
    FunctionInput,
    ShortDescriptionInput,
    LongDescriptionInput,
    ExperienceInput,
    CatchPhraseInput,
} from '~/components/common/Inputs';
import CustomModal from '~/components/common/Modal';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';
import useTags from '~/queries/useTags';

const {job, shortDescription, longDescription, experience, catchPhrase} = yupRules;
const validationSchema = yup
    .object()
    .shape({job, shortDescription, longDescription, experience, catchPhrase});

export default function PopupProfil(props: ModalProps & {onSuccess: Function}) {
    const message = (
        <SimpleText>
            Détaillez bien votre description en rendant votre texte facile à lire : pas de
            fautes d'orthographe, de style ou de ponctuation. Évitez également les mots en
            majuscules.
        </SimpleText>
    );

    return (
        <CustomModal
            title="Présentez votre profil"
            iconName="users"
            message={message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            mobileContentMaxWidth={600}
            desktopContentMaxWidth={800}
        >
            <ProfileForm onSuccess={props.onSuccess} />
        </CustomModal>
    );
}

function ProfileForm(props: {onSuccess: Function}) {
    const {loadingMe, me, refetchMe} = React.useContext(UserContext);
    const {loading: tagLoading, error: tagError, data: tagData} = useTags();
    const [updateExpert, {data, error: mutationError}] = useMutation(UPDATE_EXPERT, {
        errorPolicy: 'all',
    });

    if (loadingMe || (tagLoading && !tagData)) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    async function updateProfile(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        const data = {
            job: values.job,
            shortDescription: values.shortDescription,
            longDescription: values.longDescription,
            experience: values.experience,
            catchPhrase: values.catchPhrase,
        };

        await updateExpert({variables: data});
        props.onSuccess();
        logEvent('modify_expert', {fields: 'job, description, experience'});
    }

    const expert = me?.expert;
    return (
        <View>
            <Formik
                initialValues={{
                    job: expert?.function ?? '',
                    shortDescription: expert?.shortDescription ?? '',
                    longDescription: expert?.longDescription ?? '',
                    experience: expert?.experience ?? '',
                    catchPhrase: expert?.catchPhrase ?? '',
                }}
                onSubmit={updateProfile}
                validationSchema={validationSchema}
            >
                {(formikProps) => <ProfilInputs {...formikProps} />}
            </Formik>
        </View>
    );
}

function ProfilInputs(props: Props) {
    return (
        <View style={styles.container}>
            <FormLabel>Votre fonction :</FormLabel>
            <FunctionInput
                value={props.values.job}
                placeholder={yupLabels.job}
                onChangeText={props.handleChange('job')}
                onBlur={() => props.setFieldTouched('job')}
                blurOnSubmit={true}
                errorMessage={props.errors.job || ' '}
                style={styles.input}
            />
            <FormLabel>Accroche / Slogan :</FormLabel>
            <CatchPhraseInput
                value={props.values.catchPhrase}
                placeholder={yupLabels.catchPhrase}
                onChangeText={props.handleChange('catchPhrase')}
                onBlur={() => props.setFieldTouched('catchPhrase')}
                blurOnSubmit={true}
                errorMessage={props.errors.catchPhrase || ' '}
                style={styles.input}
            />
            <FormLabel>Description courte :</FormLabel>
            <ShortDescriptionInput
                value={props.values.shortDescription}
                placeholder={yupLabels.shortDescription}
                onChangeText={props.handleChange('shortDescription')}
                onBlur={() => props.setFieldTouched('shortDescription')}
                blurOnSubmit={true}
                errorMessage={props.errors.shortDescription || ' '}
                style={styles.input}
            />
            <FormLabel>Description détaillée :</FormLabel>
            <LongDescriptionInput
                value={props.values.longDescription}
                placeholder={yupLabels.longDescription}
                onChangeText={props.handleChange('longDescription')}
                onBlur={() => props.setFieldTouched('longDescription')}
                blurOnSubmit={false}
                errorMessage={props.errors.longDescription || ' '}
                style={styles.text}
            />
            <FormLabel>Expériences et qualifications :</FormLabel>
            <ExperienceInput
                value={props.values.experience}
                placeholder={yupLabels.experience}
                onChangeText={props.handleChange('experience')}
                onBlur={() => props.setFieldTouched('experience')}
                errorMessage={props.errors.experience || ' '}
                onSubmitEditing={() => props.handleSubmit()}
                style={styles.input}
            />
            <Button
                icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                title="Valider"
                buttonStyle={{width: 160, alignSelf: 'center', marginTop: 30}}
                disabled={!props.isValid}
                onPress={() => props.handleSubmit()}
                loading={props.isSubmitting}
            />
        </View>
    );
}

type Inputs = Pick<
    YupTypes,
    'job' | 'shortDescription' | 'longDescription' | 'experience' | 'catchPhrase'
>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const UPDATE_EXPERT = gql`
    mutation updateExpert(
        $job: String!
        $shortDescription: String!
        $longDescription: String!
        $experience: String!
        $catchPhrase: String!
    ) {
        updateExpert(
            input: {
                function: $job
                shortDescription: $shortDescription
                longDescription: $longDescription
                experience: $experience
                catchPhrase: $catchPhrase
            }
        ) {
            expert {
                id
                shortDescription
                longDescription
                experience
                function
                catchPhrase
            }
        }
    }
`;

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        marginHorizontal: 25,
        alignContent: 'space-around',
    },
    input: {
        backgroundColor: 'white',
    },
    text: {
        backgroundColor: 'white',
        height: 150,
    },
});
