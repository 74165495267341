import React from 'react';

import {MustBeConnected} from '~/components/navigation/Link';
import {ScreenLoader} from '~/components/navigation/Loader';
import ClientHistoric from '~/components/user/client/historic/ClientHistoric';
import ClientHistoricMobile from '~/components/user/client/historic/ClientHistoricMobile';
import ExpertHistoric from '~/components/user/expert/historic/ExpertHistoric';
import ExpertHistoricMobile from '~/components/user/expert/historic/ExpertHistoricMobile';
import {UserContext} from '~/contexts/UserContext';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function HistoricScreen() {
    const {isDesktop} = useDeviceQuery();
    const {loadingUser, user, loadingMe, me} = React.useContext(UserContext);

    if (!loadingUser && !loadingMe && !user && !me) {
        return <MustBeConnected />;
    }

    if (me) {
        if (!isDesktop) {
            if (me.isExpert) {
                return <ExpertHistoricMobile />;
            } else {
                return <ClientHistoricMobile />;
            }
        } else if (me.isExpert) {
            return <ExpertHistoric />;
        } else {
            return <ClientHistoric />;
        }
    }

    return <ScreenLoader />;
}
