import React, {useState, useEffect} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import * as DocumentPicker from 'expo-document-picker';
import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {
    MailInput,
    PresentationInput,
    CareerInput,
    ProfilInput,
} from '~/components/common/Inputs';
import {PhoneInput} from '~/components/common/PhoneInput';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {axios} from '~/helpers/network';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function ApplyScreen() {
    return (
        <MainView>
            <PageTitle>Devenir Spirite</PageTitle>
            <ApplyForm />
        </MainView>
    );
}

const {email, identifiant, presentation, career, profil} = yupRules;
const validationSchema = yup
    .object()
    .shape({email, identifiant, presentation, career, profil});

type Inputs = Pick<YupTypes, 'email' | 'identifiant' | 'presentation' | 'career' | 'profil'>;
type Helpers = FormikHelpers<Inputs>;
type ApplyInputsProps = {
    formik: FormikProps<Inputs>;
    setFiles: (setFiles: File[]) => void;
    files: File[];
    phone: string;
    setPhone: (phone: string) => void;
    phoneError: string;
    setPhoneError: (phone: string) => void;
};
type File = {
    name: string;
    type: string;
    uri: string;
};

function ApplyForm() {
    const {me, refetchMe, loadingMe} = React.useContext(UserContext);
    const alert = useAlert();
    const [files, setFiles] = React.useState<File[]>([]);
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');

    useEffect(() => {
        setPhone(me?.identity?.phone ?? '');
        setPhoneError('');
    }, [me]);

    async function apply(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        const body = JSON.stringify({
            email: values.email,
            phone: phone,
            identifiant: values.identifiant,
            presentation: values.presentation,
            career: values.career,
            profil: values.profil,
            files: files,
        });

        await axios.post(endpoints.apply, body);

        alert({
            message: 'Votre candidature a bien été envoyée.',
        });

        resetForm();
        setFiles([]);

        logEvent('send_application');
    }

    if (!me) {
        return (
            <>
                <SimpleText style={styles.center}>
                    Vous devez être connecté pour devenir spirite.
                </SimpleText>
                <SimpleText style={styles.center}>
                    Commencez d'abord par créer un compte le cas échéant puis revenez sur cette
                    page.
                </SimpleText>
            </>
        );
    }

    return (
        <Formik
            initialValues={{
                email: me?.identity?.email ?? '',
                identifiant: me?.profile?.displayName ?? '',
                presentation: '',
                career: '',
                profil: '',
            }}
            onSubmit={apply}
            validationSchema={validationSchema}
        >
            {(formikProps) => (
                <ApplyInputs
                    formik={formikProps}
                    setFiles={setFiles}
                    files={files}
                    phone={phone}
                    setPhone={setPhone}
                    phoneError={phoneError}
                    setPhoneError={setPhoneError}
                />
            )}
        </Formik>
    );
}

function ApplyInputs(props: ApplyInputsProps) {
    const confirm = useConfirm();
    const {isDesktop} = useDeviceQuery();

    async function join() {
        let pickerResult = await DocumentPicker.getDocumentAsync({multiple: false});

        if (pickerResult && !pickerResult.canceled && pickerResult.output) {
            const item = pickerResult.assets[0];
            if (item) {
                const file = {
                    name: item.name,
                    type: item.mimeType,
                    uri: item.uri,
                } as File;
                const newFiles = [...props.files, file];
                props.setFiles(newFiles);
            }
        }
    }

    function renderFile(file: File) {
        function deleteFile() {
            const newFiles = props.files.filter(function (value) {
                return value != file;
            });
            props.setFiles(newFiles);
        }

        function confirmDeleteFile() {
            confirm({
                message: `Voulez-vous supprimer cette pièce jointe ?`,
                onYes: deleteFile,
            });
        }

        return (
            <TouchableOpacity
                onPress={confirmDeleteFile}
                style={styles.documentContainer}
                key={file.name}
            >
                <Icons.File
                    size={14}
                    style={{paddingRight: 10, paddingTop: 2}}
                    color={Colors.highlight}
                />
                <SimpleText style={styles.document}>{file.name}</SimpleText>
            </TouchableOpacity>
        );
    }

    let text =
        props.files.length == 0
            ? 'Ajouter une pièce jointe'
            : 'Ajouter une autre pièce jointe';

    const width = isDesktop ? {width: 600} : {width: 300};

    return (
        <View style={styles.container}>
            <FormLabel>Numéro de téléphone</FormLabel>
            <PhoneInput
                disabled={true}
                initialize={true}
                width={300}
                phone={props.phone}
                setPhone={props.setPhone}
                phoneError={props.phoneError}
                setPhoneError={props.setPhoneError}
            />
            <FormLabel>Email</FormLabel>
            <MailInput
                placeholder={yupLabels.email}
                value={props.formik.values.email}
                onChangeText={props.formik.handleChange('email')}
                onBlur={() => props.formik.setFieldTouched('email')}
                blurOnSubmit={true}
                errorMessage={props.formik.errors.email || ' '}
                disabled={true}
                containerStyle={styles.width}
            />
            <FormLabel>Identifiant</FormLabel>
            <MailInput
                placeholder={yupLabels.identifiant}
                value={props.formik.values.identifiant}
                onChangeText={props.formik.handleChange('identifiant')}
                onBlur={() => props.formik.setFieldTouched('identifiant')}
                blurOnSubmit={true}
                errorMessage={props.formik.errors.identifiant || ' '}
                disabled={true}
                containerStyle={styles.width}
            />
            <FormLabel>Présentation</FormLabel>
            <PresentationInput
                placeholder={yupLabels.presentation}
                value={props.formik.values.presentation}
                onChangeText={props.formik.handleChange('presentation')}
                onBlur={() => props.formik.setFieldTouched('presentation')}
                blurOnSubmit={false}
                errorMessage={props.formik.errors.presentation || ' '}
                style={styles.height}
                containerStyle={width}
            />
            <FormLabel>Parcours professionnel</FormLabel>
            <CareerInput
                placeholder={yupLabels.career}
                value={props.formik.values.career}
                onChangeText={props.formik.handleChange('career')}
                onBlur={() => props.formik.setFieldTouched('career')}
                blurOnSubmit={false}
                errorMessage={props.formik.errors.career || ' '}
                style={styles.height}
                containerStyle={width}
            />
            <FormLabel>Profil</FormLabel>
            <ProfilInput
                placeholder={yupLabels.profil}
                value={props.formik.values.profil}
                onChangeText={props.formik.handleChange('profil')}
                onBlur={() => props.formik.setFieldTouched('profil')}
                blurOnSubmit={false}
                errorMessage={props.formik.errors.profil || ' '}
                style={styles.height}
                containerStyle={width}
            />
            <FormLabel>
                Vous pouvez ajouter une ou plusieurs pièces jointes de votre choix (CV, photo,
                présentation audio, ...)
            </FormLabel>
            <View style={styles.fileContainer}>
                {props.files.length > 0 && props.files.map((f) => renderFile(f))}
            </View>
            <TouchableOpacity onPress={join} style={styles.join}>
                <Icons.Attachment size={20} style={{paddingRight: 10}} color={Colors.link} />
                <SimpleText style={styles.text}>{text}</SimpleText>
            </TouchableOpacity>
            <Button
                title="Candidater"
                buttonStyle={styles.apply}
                disabled={!props.formik.isValid}
                onPress={() => props.formik.handleSubmit()}
                loading={props.formik.isSubmitting}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    center: {
        textAlign: 'center',
    },
    input: {
        width: 400,
    },
    join: {
        flexDirection: 'row',
        paddingTop: 25,
    },
    text: {
        color: Colors.link,
        paddingTop: 2,
    },
    document: {
        color: Colors.highlight,
    },
    fileContainer: {},
    documentContainer: {
        flexDirection: 'row',
        textAlign: 'left',
    },
    apply: {
        width: 150,
        alignSelf: 'center',
        marginBottom: 20,
        marginTop: 50,
    },
    height: {
        height: 100,
    },
    width: {
        width: 300,
    },
});
