import React, {useState} from 'react';
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {Picker} from '@react-native-picker/picker';
import {useFocusEffect} from '@react-navigation/native';
import {IconNode} from 'react-native-elements';

import {MainView} from '~/components/common/Containers';
import {GradeColor} from '~/components/common/GradeItem';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {ScreenLoader} from '~/components/navigation/Loader';
import PopupReadReview from '~/components/user/client/review/PopupReadReview';
import PopupWriteReview from '~/components/user/client/review/PopupWriteReview';
import {CommunicationType, ReviewData} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {APP_ACTIVE_YEARS, MONTHS_NAMES} from '~/constants/dates';
import * as dates from '~/helpers/dates';
import {getBenefit} from '~/helpers/experts';
import {filterEmpty, sorted} from '~/helpers/list';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import {MobileExpertHistoricQuery} from './types/MobileExpertHistoricQuery';

const MOBILE_EXPERT_HISTORIC_QUERY = gql`
    query MobileExpertHistoricQuery($month: Int, $year: Int) {
        findExpertCalls(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    usePack
                    status
                    expert {
                        id
                    }
                    client {
                        id
                        profile {
                            id
                            displayName
                            pictureName
                        }
                    }
                    review {
                        id
                        reviewId
                        response
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
        findExpertMessages(month: $month, year: $year) {
            edges {
                node {
                    id
                    type
                    status
                    creationDate
                    cost
                    review {
                        id
                        reviewId
                        response
                        grade {
                            label
                            gradeId
                        }
                    }
                    expert {
                        id
                    }
                    client {
                        id
                        profile {
                            id
                            displayName
                            pictureName
                        }
                    }
                    packOrder {
                        amount
                    }
                }
            }
        }
        findExpertChats(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    usePack
                    status
                    expert {
                        id
                    }
                    client {
                        id
                        profile {
                            id
                            displayName
                            pictureName
                        }
                    }
                    review {
                        id
                        reviewId
                        response
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
    }
`;

export default function ExpertHistoricMobile() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const [year, setYear] = React.useState(currentYear);
    const [month, setMonth] = React.useState(currentMonth);

    const [load, {called, loading, data, refetch}] = useLazyQuery<MobileExpertHistoricQuery>(
        MOBILE_EXPERT_HISTORIC_QUERY,
        {
            errorPolicy: 'all',
            fetchPolicy: 'cache-and-network',
            variables: {month: month + 1, year: year},
        }
    );

    useFocusEffect(
        React.useCallback(() => {
            if (month == currentMonth && year == currentYear) {
                requestAnimationFrame(reload);
            }
        }, [month, year])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    const [reviewData, setReviewData] = useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: true,
    });
    const [modalReadReviewVisible, setModalReadReviewVisible] = useState(false);

    if (loading && data == null) {
        return <ScreenLoader />;
    }

    function onReadReviewPress(reviewData: ReviewData) {
        setReviewData(reviewData);
        setModalReadReviewVisible(true);
    }

    function onRequestCloseReadReview() {
        setModalReadReviewVisible(false);
    }

    function getAverageDuration(elements: ExpertHistoricElement[]) {
        const list = elements.filter((e) => e.duration !== '');
        if (list.length <= 0) {
            return '-';
        }
        const average =
            list.reduce(
                (total, next) => total + dates.getDurationInSeconds(next.duration),
                0
            ) / list.length;
        const averageSeconds = Math.round(average);
        return dates.getDurationText(averageSeconds);
    }

    function getSumCost(elements: ExpertHistoricElement[]) {
        const list = elements.filter((e) => e.cost > 0);
        const sum = list.reduce((total, next) => total + next.cost, 0);
        return sum.toFixed(2);
    }

    function sortByDate(calls: ExpertHistoricElement[]): ExpertHistoricElement[] {
        return sorted(calls, (e: ExpertHistoricElement) => Date.parse(e.date), true);
    }

    function getMostReview(elements: ExpertHistoricElement[]) {
        let grades = new Map<string, number>();
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.gradeLabel) {
                const grade = grades.get(element.gradeLabel);
                if (grade) {
                    grades.set(element.gradeLabel, grade + 1);
                } else {
                    grades.set(element.gradeLabel, 1);
                }
            }
        }

        if (grades.size > 0) {
            const sortedGrades = new Map([...grades.entries()].sort((a, b) => b[1] - a[1]));
            return sortedGrades.keys().next().value;
        } else {
            return ' - ';
        }
    }

    const monthItems = MONTHS_NAMES.map((name, i) => (
        <Picker.Item label={name} value={i} key={`month_${i}`} />
    ));

    const yearItems = APP_ACTIVE_YEARS.map((year) => (
        <Picker.Item label={`${year}`} value={year} key={`year_${year}`} />
    ));

    var calls = data?.findExpertCalls?.edges?.filter(
        (e) =>
            (e?.node?.status == 'FINISHED' || e?.node?.status == 'ERROR') &&
            new Date(e.node.startDatetime).getFullYear() == year &&
            new Date(e.node.startDatetime).getMonth() == month
    );

    const callsElements = calls?.map((e) => {
        return {
            type: 'Appel' + (e?.node?.usePack ? ' + PACK' : ''),
            communicationType: CommunicationType.CALL,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            clientId: e?.node?.client?.id,
            clientDisplayName: e?.node?.client?.profile?.displayName,
            clientPictureName: e?.node?.client?.profile?.pictureName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            response: e?.node?.review?.response,
            cost: getBenefit(
                e?.node?.cost,
                e?.node?.usePack,
                dates.getDurationInSeconds(e?.node?.duration),
                CommunicationType.CALL
            ),
            duration: e?.node?.duration,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as ExpertHistoricElement;
    });

    const messages = data?.findExpertMessages?.edges?.filter(
        (e) =>
            (e?.node?.type == MessageTypeEnum.PACK_STUDY || e?.node?.status == 'ANSWERED') &&
            new Date(e.node.creationDate).getFullYear() == year &&
            new Date(e.node.creationDate).getMonth() == month
    );

    const messagesElements = messages?.map((e) => {
        return {
            type: e?.node?.type == MessageTypeEnum.PACK_STUDY ? 'Étude complète' : 'Message',
            communicationType: CommunicationType.MESSAGE,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            clientId: e?.node?.client?.id,
            clientDisplayName: e?.node?.client?.profile?.displayName,
            clientPictureName: e?.node?.client?.profile?.pictureName,
            date: e?.node?.creationDate,
            reviewId: e?.node?.review?.reviewId,
            response: e?.node?.review?.response,
            cost:
                e?.node?.type == MessageTypeEnum.PACK_STUDY
                    ? getBenefit(
                          e?.node?.packOrder?.amount,
                          null,
                          null,
                          CommunicationType.MESSAGE
                      )
                    : getBenefit(e?.node?.cost, null, null, CommunicationType.MESSAGE),
            duration: '',
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as ExpertHistoricElement;
    });

    var chats = data?.findExpertChats?.edges?.filter(
        (e) =>
            e?.node?.status == 'ENDED' &&
            new Date(e.node.startDatetime).getFullYear() == year &&
            new Date(e.node.startDatetime).getMonth() == month
    );

    const chatsElements = chats?.map((e) => {
        return {
            type: 'Chat' + (e?.node?.usePack ? ' + PACK' : ''),
            communicationType: CommunicationType.CHAT,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            clientId: e?.node?.client?.id,
            clientDisplayName: e?.node?.client?.profile?.displayName,
            clientPictureName: e?.node?.client?.profile?.pictureName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            response: e?.node?.review?.response,
            cost: getBenefit(
                e?.node?.cost,
                e?.node?.usePack,
                dates.getDurationInSeconds(e?.node?.duration),
                CommunicationType.CHAT
            ),
            duration: e?.node?.duration,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as ExpertHistoricElement;
    });

    const elements = [
        ...(callsElements ?? []),
        ...(messagesElements ?? []),
        ...(chatsElements ?? []),
    ];

    const total = elements?.length ?? 0;
    const duration = getAverageDuration(elements);
    const earn = getSumCost(elements).toString() + '€';
    const mostReview = getMostReview(elements);

    let displayedElements = filterEmpty(elements);
    const sortedElements = sortByDate(displayedElements);

    return (
        <MainView>
            <SimpleText style={mobileStyles.title} accessibilityRole="header" aria-level="1">
                Mon historique de consultation
            </SimpleText>
            <SimpleText style={mobileStyles.description}>
                Vous retrouverez ici toutes les statistiques liées à vos activités sur
                Kyvoitou.fr.
            </SimpleText>
            <View style={mobileStyles.filterContainer}>
                <SimpleText style={mobileStyles.pickerContainer}>Filtrer par : </SimpleText>
                <Picker
                    selectedValue={month}
                    style={mobileStyles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setMonth(parseInt(itemValue))}
                >
                    {monthItems}
                </Picker>
                <Picker
                    selectedValue={year}
                    style={mobileStyles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setYear(parseInt(itemValue))}
                >
                    {yearItems}
                </Picker>
            </View>
            {total == 0 && (
                <SimpleText style={mobileStyles.description}>
                    Vous n'avez pas d'élément dans l'historique pour ce mois-ci.
                </SimpleText>
            )}
            {total > 0 && (
                <>
                    <View style={mobileStyles.stats}>
                        <Statistic
                            icon={
                                <Icons.Users
                                    size={22}
                                    color={Colors.secondary}
                                    style={{alignSelf: 'center'}}
                                />
                            }
                            backgroundColor={'white'}
                            number={total.toString()}
                            label={'Consultations sur la période'}
                        />
                        <Statistic
                            icon={
                                <Icons.File
                                    size={22}
                                    color={'white'}
                                    style={{alignSelf: 'center'}}
                                />
                            }
                            backgroundColor={Colors.pause}
                            number={duration}
                            label={"Durée moyenne d'un échange"}
                        />
                        <Statistic
                            icon={
                                <Icons.Redo
                                    size={22}
                                    color={'white'}
                                    style={{alignSelf: 'center'}}
                                />
                            }
                            backgroundColor={Colors.available}
                            number={earn}
                            label={'De revenus'}
                        />
                        <Statistic
                            icon={
                                <Icons.Chart
                                    size={22}
                                    color={'white'}
                                    style={{alignSelf: 'center'}}
                                />
                            }
                            backgroundColor={Colors.light}
                            number={mostReview}
                            label={'Evaluation le plus souvent laissée'}
                        />
                    </View>
                    <FlatList
                        data={sortedElements}
                        renderItem={({item}) => (
                            <ExpertHistoricItem
                                item={item}
                                onReadReviewPress={onReadReviewPress}
                            />
                        )}
                        keyExtractor={(item) => item.id}
                        contentContainerStyle={mobileStyles.listContainer}
                    />
                    <PopupReadReview
                        visible={modalReadReviewVisible}
                        onRequestClose={onRequestCloseReadReview}
                        reviewData={reviewData}
                        animationType={'none'}
                    />
                </>
            )}
        </MainView>
    );
}

type ExpertHistoricItemProps = {
    item: ExpertHistoricElement;
    onReadReviewPress: (reviewData: ReviewData) => void;
};

function ExpertHistoricItem(props: ExpertHistoricItemProps) {
    const date = new Date(props.item.date);
    const d = 'Le ' + dates.dateString(date) + ' à ' + dates.timeString(date);
    const size = 20;
    const color = Colors.secondary;
    const duration = dates.getDurationInMinutes(props.item.duration);

    const reviewData = {
        date,
        communicationId: props.item?.id,
        communicationType: props.item?.communicationType,
        expertId: props.item?.expertId,
        clientMode: true,
    } as ReviewData;

    let icon = <Icons.Phone size={size} color={color} />;
    if (props.item.communicationType == CommunicationType.CHAT) {
        icon = <Icons.Chat size={size} color={color} />;
    } else if (props.item.communicationType == CommunicationType.MESSAGE) {
        icon = <Icons.Mail size={size} color={color} />;
    }

    const hasReview = props.item.reviewId != null;
    const hasResponse = Boolean(props.item.response);
    const reviewText = hasResponse ? 'Lire' : 'Répondre';

    return (
        <View style={mobileStyles.container}>
            <View style={mobileStyles.top}>
                <View>
                    <ProfilePicture
                        style={mobileStyles.picture}
                        pictureName={props.item.clientPictureName}
                        displayName={props.item.clientDisplayName}
                    />
                </View>
                <View style={mobileStyles.lines}>
                    <View style={mobileStyles.header}>
                        {icon}
                        <SimpleText style={mobileStyles.head}>{props.item.type}</SimpleText>
                    </View>
                    <View style={mobileStyles.info}>
                        <SimpleText style={mobileStyles.date}>
                            {props.item.clientDisplayName}
                        </SimpleText>
                    </View>
                    <View style={mobileStyles.info}>
                        <SimpleText style={mobileStyles.date}>{d}</SimpleText>
                    </View>
                    <View style={mobileStyles.line}>
                        <SimpleText style={mobileStyles.cost}>
                            {props.item.cost.toFixed(2)} €
                        </SimpleText>
                        {Boolean(props.item.duration) && (
                            <SimpleText style={mobileStyles.time}>
                                ({duration} min.)
                            </SimpleText>
                        )}
                    </View>
                </View>
            </View>
            <View style={mobileStyles.bottom}>
                {hasReview && (
                    <TouchableOpacity
                        onPress={() => props.onReadReviewPress(reviewData)}
                        style={mobileStyles.gradeContainer}
                    >
                        <GradeColor
                            style={mobileStyles.grade}
                            text={props.item.gradeLabel ?? ''}
                            grade={props.item.gradeId}
                        />
                        <View style={mobileStyles.link}>
                            {!hasResponse && (
                                <Icons.Reply
                                    size={16}
                                    color={Colors.link}
                                    style={{paddingRight: 10}}
                                />
                            )}
                            {hasResponse && (
                                <Icons.Read
                                    size={16}
                                    color={Colors.link}
                                    style={{paddingRight: 10}}
                                />
                            )}
                            <SimpleText style={mobileStyles.text}>{reviewText}</SimpleText>
                        </View>
                    </TouchableOpacity>
                )}
            </View>
        </View>
    );
}

type ExpertHistoricElement = {
    type: string;
    communicationType: CommunicationType;
    id: string;
    expertId: string;
    clientId: string;
    clientDisplayName: string;
    clientPictureName: string;
    date: string;
    reviewId: string;
    response: string;
    cost: number;
    duration: string;
    gradeLabel: string;
    gradeId: number;
};

type StatisticProps = {
    icon: IconNode;
    backgroundColor: string;
    number: string;
    label: string;
};

function Statistic(props: StatisticProps) {
    return (
        <View
            style={[mobileStyles.statisticContainer, {backgroundColor: props.backgroundColor}]}
        >
            {props.icon}
            <View style={mobileStyles.statistic}>
                <SimpleText style={mobileStyles.number}>{props.number}</SimpleText>
                <SimpleText style={mobileStyles.label}>{props.label}</SimpleText>
            </View>
        </View>
    );
}

const mobileStyles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 22,
        color: Colors.secondary,
        marginBottom: 30,
        marginTop: 30,
    },
    description: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        marginBottom: 30,
    },
    filterContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    pickerContainer: {
        margin: 15,
    },
    listContainer: {},
    container: {
        borderWidth: 1,
        borderColor: Colors.separator,
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 10,
        margin: 15,
        backgroundColor: 'white',
        justifyContent: 'center',
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
    },
    top: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    picture: {
        borderRadius: 10,
        width: 100,
        height: 100,
        alignSelf: 'center',
        marginRight: 20,
    },
    lines: {
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    header: {
        flexDirection: 'row',
    },
    head: {
        alignSelf: 'center',
        marginLeft: 15,
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    date: {
        alignSelf: 'center',
    },
    offer: {
        color: Colors.nice,
        alignSelf: 'center',
        marginLeft: 20,
    },
    cost: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    info: {
        flexDirection: 'row',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 5,
    },
    time: {
        alignSelf: 'center',
        marginLeft: 10,
    },
    bottom: {
        justifyContent: 'center',
    },
    link: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
    text: {
        color: Colors.link,
        alignSelf: 'center',
    },
    gradeContainer: {
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    grade: {
        fontSize: 14,
        marginRight: 15,
    },
    stats: {
        padding: 5,
    },
    statisticContainer: {
        margin: 5,
        padding: 5,
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        borderRadius: 7,
    },
    statistic: {},
    number: {
        marginTop: 5,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    label: {
        marginTop: 5,
        textAlign: 'center',
        fontSize: 12,
    },
});
