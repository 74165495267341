import React from 'react';
import {View, StyleSheet, ViewStyle, Image, TextStyle} from 'react-native';

import Settings from '~/constants/Settings';

import {SimpleText} from './Texts';

export default function OfferListItem({text, style}: {text: string; style?: ViewStyle}) {
    return (
        <View style={[styles.container, style]}>
            <Image
                source={{uri: Settings.getUrlPictoBall()}}
                style={{marginRight: 30, alignSelf: 'center', width: 32, height: 32}}
                accessibilityLabel={text}
            />
            <SimpleText style={styles.label}>{text}</SimpleText>
        </View>
    );
}

const styles = StyleSheet.create({
    label: {
        fontSize: 14,
        alignSelf: 'center',
    },
    container: {
        flexDirection: 'row',
        marginTop: 5,
    },
});

export function BallItem({
    text,
    style,
    containerStyle,
}: {
    text: string;
    style?: TextStyle;
    containerStyle?: ViewStyle;
}) {
    return (
        <View style={[styles.container, containerStyle]}>
            <Image
                source={{uri: Settings.getUrlPictoBall()}}
                style={{marginRight: 30, alignSelf: 'center', width: 32, height: 32}}
                accessibilityLabel={text}
            />
            <SimpleText style={[styles.label, style]}>{text}</SimpleText>
        </View>
    );
}
