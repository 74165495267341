import {CALL_LANDING_PAGE_URL} from '~/constants/Settings';
import {ExpertWithLastReview} from '~/helpers/experts';

import {CommunicationMedium} from '../expert/ExpertCardLandingPageVersion';
import ExpertList from '../expert/ExpertListLandingPageVersion';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePaymentIntent} from './hook/usePaymentIntent';
import {useCallLandingPageRoute} from './route';

type ExpertPickerViewProps = {
    onAuthRequired?: () => void;
    onPaymentMethodRequired?: () => void;
    onCallStarted?: () => void;
    onExpertSelected?: (expert: ExpertWithLastReview) => void;
    horizontalScroll?: boolean;
    onCancelPayment?: () => void;
    medium: CommunicationMedium;
    freeCall?: boolean;
};

export default function ExpertPickerView(props: ExpertPickerViewProps) {
    const route = useCallLandingPageRoute();
    const {isExpertListStep} = useUrlStepContext();
    const {paymentIntent, paymentIntentSecret} = usePaymentIntent();
    if (!isExpertListStep) {
        return null;
    }
    return (
        <>
            <StepTitle selected>1/ Choisissez votre voyant</StepTitle>
            <ExpertList
                horizontalScroll
                medium={props.medium}
                onAuthRequired={props.onAuthRequired}
                onPaymentMethodRequired={props.onPaymentMethodRequired}
                onCallStarted={props.onCallStarted}
                onExpertSelected={props.onExpertSelected}
                onCancelPayment={props.onCancelPayment}
                paymentIntent={paymentIntent}
                paymentIntentSecret={paymentIntentSecret}
                queryParams={route.params}
                freeCall={props.freeCall}
            />
        </>
    );
}
