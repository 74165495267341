import React from 'react';
import {Switch as BaseSwitch} from 'react-native';

import * as Colors from '~/constants/Colors';

export const Switch = React.forwardRef<BaseSwitch, BaseSwitch['props']>(
    ({value, ...otherProps}, ref) => (
        <BaseSwitch
            trackColor={{
                false: Colors.dark,
                true: Colors.primary,
            }}
            thumbColor={'white'}
            activeThumbColor={'white'} // web only
            activeTrackColor={Colors.primary} // web only
            ios_backgroundColor={Colors.dark}
            value={value}
            {...otherProps}
        />
    )
);
