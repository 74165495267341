import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {useFocusEffect} from '@react-navigation/native';

import {MainView} from '~/components/common/Containers';
import {Camille, Jusine} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';

export default function AboutScreen() {
    const [display, setDisplay] = React.useState<boolean>(false);

    useFocusEffect(
        React.useCallback(() => {
            const requestId = requestAnimationFrame(() => {
                setDisplay(true);
            });
            return () => {
                setDisplay(false);
                cancelAnimationFrame(requestId);
            };
        }, [])
    );

    return (
        <MainView>
            <PageTitle>Qui sommes-nous ?</PageTitle>
            {display && (
                <View style={styles.video}>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/gSrBXHBTHwo"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </View>
            )}
            <View style={styles.container}>
                <SimpleText style={styles.text}>
                    Kyvoitou.fr c’est une petite équipe avec une grande ambition : celle de
                    vous faire découvrir la voyance telle qu’elle est, sans complaisance et
                    sans clichés !
                </SimpleText>
                <SimpleText style={styles.text}>
                    Chaque jour, Camille et Justine vous dénichent les meilleurs voyants
                    qu’elles ont elles-mêmes testés, elles vous proposent des articles
                    d’actualité ésotérique et prennent très à cœur d’abattre les clichés liés à
                    la voyance.
                </SimpleText>
                <SimpleText style={styles.text}>
                    Sur Kyvoitou.fr vous pourrez trouver des voyants et médiums qui vous
                    proposent des coachings médiumniques et bien-être, qui vous accueilleront
                    avec bienveillance et surtout sans complaisance !
                </SimpleText>
                <SimpleText style={styles.text}>
                    Vous pouvez les contacter par téléphone, e-mail ou encore par tchat, il y
                    en a pour tous les goûts !
                </SimpleText>
                <SimpleText style={styles.text}>
                    Et si jamais vous n’arrivez pas à faire votre choix, Camille et Justine
                    vous attendent avec bonne humeur et convivialité au standard de Kyvoitou.fr
                    !
                </SimpleText>

                <View style={styles.images}>
                    <Camille small />
                    <Jusine small />
                </View>

                <SimpleText style={styles.title}>Notre vision des choses ?</SimpleText>

                <SimpleText style={styles.text}>
                    Pour nous, le plus important c’est que nos clients aient une expérience
                    agréable, qu’ils soient novices ou non. En arrivant ici, ils doivent
                    pouvoir trouver leur bonheur et bénéficier d’une consultation de qualité,
                    qu’ils viennent pour 5 minutes ou pour 1 heure !
                </SimpleText>
                <SimpleText style={styles.text}>
                    Nous nous battons également pour une rémunération juste, Camille a une
                    longue expérience de la voyance en ligne et connaît les difficultés qui
                    peuvent être rencontrées par les voyants. Alors chez Kyvoitou.fr, pas de
                    jaloux, c’est 50/50 et puis c’est tout !
                </SimpleText>
                <SimpleText style={styles.text}>
                    Pour nous, voyance et technologies s’unissent pour vous offrir le meilleur,
                    et c’est notre bien le plus précieux !
                </SimpleText>

                <SimpleText style={styles.title}>Notre mission ?</SimpleText>

                <SimpleText style={styles.text}>
                    Partagez avec vous nos talents pour vous offrir la possibilité d’une vie
                    saine, heureuse et épanouie !
                </SimpleText>

                <SimpleText style={styles.title}>Nos valeurs ?</SimpleText>

                <SimpleText style={styles.text}>
                    Le <SimpleText style={styles.bold}>PARTAGE</SimpleText> à travers nos
                    articles de blog, méticuleusement concoctés juste pour vous, afin de vous
                    éclairer sur l’univers de l’ésotérisme. Astrologie, numérologie,
                    horoscopes, voyance, spiritualité, bien-être... Tout y passe ! Cette valeur
                    de partage qui nous tient à cœur, nous l’appliquons également sur la
                    plateforme en vous proposant des voyants et médiums de qualité, que nous
                    avons accepté de partager avec vous (même si on aurait bien aimé les garder
                    rien que pour nous :P !) et en partageant avec ces derniers un outil que
                    nous avons créé de A à Z tout spécialement pour eux. Pour leur offrir une
                    expérience juste et équitable.
                </SimpleText>

                <SimpleText style={styles.text}>
                    Le <SimpleText style={styles.bold}>BIEN-FAIRE</SimpleText> en vous
                    proposant des outils spécifiques que nous avons au préalable testé
                    nous-même pour être sûr qu’ils soient adaptés à tout un chacun. Nous tenons
                    énormément à ce que l’expérience de chacun soit la plus fluide et agréable.
                    Pour cela, chaque jour nous nous creusons la tête pour trouver de nouvelles
                    idées qui pourraient vous plaire !
                </SimpleText>

                <SimpleText style={styles.text}>
                    La <SimpleText style={styles.bold}>TRANSPARENCE</SimpleText> car chez nous,
                    rien n’est caché, tout est visible ! Il est important à nos yeux que chaque
                    personne connaisse nos tarifs d’entrée, sans avoir à se poser de question
                    ni à rechercher dans les méandres du site. Vous l’avez bien retenu
                    également, chacun de nos voyants/médiums est d’abord testé et approuvé et
                    est justement rémunéré. Et grâce à la FAQ, vous pouvez connaître tous nos
                    secrets en termes de paiement ! Et vous pouvez également nous retrouver sur
                    nos réseaux sociaux pour connaître les coulisses de ce merveilleux travail
                    en équipe, où le rire et la bonne humeur sont toujours présents !
                </SimpleText>

                <SimpleText style={styles.title}>Notre équipe ?</SimpleText>

                <SimpleText style={styles.name}>Camille</SimpleText>

                <SimpleText style={styles.text}>
                    Chargée des spirites et de l’évolution de Kyvoitou.fr, en tant que gérante
                    et médium je mets un point d’honneur à tester et recruter les personnes à
                    qui vous confiez vos doutes. Je travaille chaque jour pour vous apporter le
                    meilleur en termes de contenu, de professionnels du monde de l’ésotérisme
                    et au développement de ce fabuleux outil qu’est notre plateforme de mise en
                    relation. Car finalement ce n’est que cela, que vous puissiez trouver la
                    vérité, la bienveillance, la qualité chez nous grâce à Kyvoitou.fr tout
                    simplement.
                </SimpleText>

                <SimpleText style={styles.text}>
                    Depuis toute petite, je baigne dans la voyance sous mon deuxième prénom :
                    Myrianne (Également celui de ma grand-mère qui m’accompagne dans toutes mes
                    consultations). Descendante d’une lignée de femmes médiums, j’ai toujours
                    eu à cœur d’aider les autres et de faire grandir leur potentiel, soit en
                    leur expliquant leurs grandes lignes de vie, ce à quoi ils sont destinés,
                    soit en leur communiquant des messages de l’au-delà.
                </SimpleText>

                <SimpleText style={styles.text}>
                    De ce métier de cœur est venue l’envie de créer un outil simple pour que
                    vous puissiez avoir des réponses face à vos incertitudes. L’objectif reste
                    de vous aider, non d’être dépendant de la voyance. Une des raisons
                    également de développer Kyvoitou.fr et qui me tient particulièrement en
                    estime est de placer le voyant dans un système sincère, rentable pour lui.
                    Nous n’exploitons pas nos spirites, nous les suivons et les aidons à se
                    développer. Le client est roi oui mais les voyants aussi !
                </SimpleText>

                <SimpleText style={styles.text}>
                    Tout a été créé dans une vraie bulle de bienveillance pour pouvoir tous
                    avancer et s’aider mutuellement les uns les autres, dans un système
                    gagnant/gagnant ! Ainsi le cercle vertueux donner/recevoir/partager est
                    bouclé :)
                </SimpleText>

                <SimpleText style={styles.name}>Justine</SimpleText>

                <SimpleText style={styles.text}>
                    Chargée de communication, j’épaule Camille dans ce merveilleux travail
                    qu’elle exécute avec tout son cœur et toute son énergie. Ensemble, nous
                    construisons chaque jour un peu plus Kyvoitou.fr et espérons toujours
                    répondre au maximum à la demande de chacun. Vous me retrouvez, entre
                    autres, tous les jours sur les réseaux sociaux pour vous partager nos
                    meilleurs articles et notre quotidien.
                </SimpleText>

                <SimpleText style={styles.text}>
                    Le monde de l’ésotérisme m’était totalement inconnu avant de mettre les
                    pieds dans l’entreprise de Camille, et aujourd’hui je m’épanouie dans un
                    monde qui me surprend de plus en plus ! J’en apprends chaque jour
                    d’avantage et c’est ce qui rend ce métier riche et plaisant.
                </SimpleText>
            </View>
        </MainView>
    );
}

const styles = StyleSheet.create({
    video: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 40,
    },
    container: {
        marginHorizontal: 15,
        paddingBottom: 40,
    },
    title: {
        fontSize: 20,
        marginTop: 40,
        fontWeight: 'bold',
        color: Colors.secondary,
        textAlign: 'center',
    },
    name: {
        fontSize: 16,
        marginTop: 30,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    text: {
        marginTop: 15,
        fontSize: 14,
        textAlign: 'center',
    },
    bold: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    images: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
});
