import React, {useState} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';

type QualityStarProps = {
    quality: string;
    qualityId: number;
    setStar: (qualityId: number, n: number) => void;
};

export default function QualityStar(props: QualityStarProps) {
    const [star, setStar] = useState(0);

    function getStarColor(i: number) {
        if (i > star) {
            return Colors.separator;
        } else {
            return Colors.star;
        }
    }

    function onPressStar(n: number) {
        setStar(n);
        props.setStar(props.qualityId, n);
    }

    return (
        <View style={styles.container}>
            <SimpleText style={styles.quality}>{props.quality}</SimpleText>
            <TouchableOpacity
                style={styles.picto}
                onPress={() => {
                    onPressStar(1);
                }}
            >
                <Icons.Star size={15} color={getStarColor(1)} />
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.picto}
                onPress={() => {
                    onPressStar(2);
                }}
            >
                <Icons.Star size={15} color={getStarColor(2)} />
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.picto}
                onPress={() => {
                    onPressStar(3);
                }}
            >
                <Icons.Star size={15} color={getStarColor(3)} />
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.picto}
                onPress={() => {
                    onPressStar(4);
                }}
            >
                <Icons.Star size={15} color={getStarColor(4)} />
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.picto}
                onPress={() => {
                    onPressStar(5);
                }}
            >
                <Icons.Star size={15} color={getStarColor(5)} />
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        marginBottom: 10,
    },
    quality: {
        width: 180,
    },
    picto: {
        marginTop: 2,
        marginLeft: 5,
    },
});
