export const primary = '#a99a77';
export const primaryDarker = '#827452';
export const secondary = '#213348';
export const fade = '#4d606e';
export const link = '#2185d0';
export const light = '#6dbcdb';
export const negative = '#ffffff';
export const dark = '#f5f5f5';
export const disabled = '#cccccc';
export const separator = '#d7dadb';
export const highlight = '#916ba2';
export const category = '#995b7d';
export const bad = '#df0101';
export const error = '#882244';
export const formik = '#ff190c';
export const errors = '#ee4444';
export const available = '#34bfa3';
export const unavailable = '#dd5353';
export const pause = '#ffa500';
export const star = '#ffe622';
export const pay = '#7db210';
export const spend = '#dd5353';
export const nice = '#b5cc18';
export const connected = '#21ba45';
export const shadow = '#00000020';
export const opacity = '#00000055';
export const cta = 'rgb(50, 191, 163)';

export function colorOpacity(color: string, alpha: string) {
    return `${color}${alpha}`;
}

export const rainbowFirst = '#4bc0c8';
export const rainbowSecond = '#c779d0';
export const rainbowThird = '#feac5e';

export const rainbow = [rainbowFirst, rainbowSecond, rainbowThird];

export const GRADE_COLORS = ['#21ba45', '#b5cc18', '#f2711c', '#db2828'];

export const pastel = ['#E2F2F6', '#F4E9F7', '#FEEFE4'];
