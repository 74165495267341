import {StyleSheet, View} from 'react-native';

import * as Colors from '~/constants/Colors';

import {SimpleText} from '../common/Texts';

const PROMO_BADGE_DIAMETER = 70;

export default function PromoBadge(props: {text: string}) {
    return (
        <View style={styles.promoBadge}>
            <SimpleText style={styles.promoBadgeText}>{props.text}</SimpleText>
        </View>
    );
}

const styles = StyleSheet.create({
    promoBadge: {
        backgroundColor: Colors.cta,
        alignSelf: 'flex-start',
        justifyContent: 'center',
        borderRadius: PROMO_BADGE_DIAMETER,
        height: PROMO_BADGE_DIAMETER,
        width: PROMO_BADGE_DIAMETER,
    },
    promoBadgeText: {
        fontSize: 20,
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        transform: [{rotate: '-20deg'}],
    },
});
