import React, {useState} from 'react';
import {FlatList, Image, Linking, Pressable, StyleSheet, View} from 'react-native';

import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {loadStripe} from '@stripe/stripe-js';
import Constants from 'expo-constants';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import {Card} from '~/components/user/stripe/CardInfo';
import * as Colors from '~/constants/Colors';
import Settings, {endpoints} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import {axios} from '~/helpers/network';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import usePack from '~/queries/usePack';
import {PackParamList} from '~/types';

const ERROR_MESSAGE =
    "Une erreur est survenue pendant la vérification de vos informations. Veuillez contactez l'équipe support";

const stripePromise = loadStripe(Constants.manifest.extra?.stripeApiKey);

export default function PaymentScreen() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();
    const alert = useAlert();
    const [isloading, setIsLoading] = useState<boolean>(false);
    const [loadingModalClosed, setLoadingModalClosed] = useState<boolean>(false);
    const {data, loading} = usePack();
    const route = useRoute<RouteProp<PackParamList, 'BasketScreen'>>();
    const {me, loadingMe} = React.useContext(UserContext);

    async function goToPack() {
        navigation.navigate('PackScreen');
    }

    async function goToLogin() {
        navigation.navigate('LoginScreen');
    }

    async function goToSuccess(payment_intent_id: string) {
        navigation.navigate('SuccessScreen', {paymentIntentId: payment_intent_id});
    }

    const pack = data?.getPacks?.edges?.find((f) => f?.node?.packId == route.params?.packId);

    const stripe = me?.identity?.selectedPaymentMethod;
    const isPaymentValidated = Boolean(stripe?.isPaymentValidated);

    async function buy(card: string) {
        setIsLoading(true);
        setLoadingModalClosed(false);
        const body = JSON.stringify({
            packId: pack?.node?.packId,
            card: card,
        });
        const response = await axios.post(endpoints.buyPack, body);
        // console.log('response.data = ', response.data);

        if (response.data.session) {
            Linking.openURL(response.data.session.url);
        } else if (response.data.payment_intent?.status == 'succeeded') {
            goToSuccess(response.data.payment_intent.id);
            setIsLoading(false);
        } else if (
            response.data.payment_intent?.last_payment_error.code == 'authentication_required'
        ) {
            const stripe = await stripePromise;
            const client_secret = response.data.payment_intent.client_secret;
            const pmi = response.data.payment_intent.last_payment_error.payment_method.id;
            const card = {
                payment_method: pmi,
            };
            stripe?.confirmCardPayment(client_secret, card).then(function (result) {
                if (result.error) {
                    setIsLoading(false);
                    alert({message: result.error.message ?? ERROR_MESSAGE});
                } else {
                    if (result.paymentIntent.status === 'succeeded') {
                        goToSuccess(result.paymentIntent.id);
                        setIsLoading(false);
                    }
                }
            });
        } else {
            alert({message: ERROR_MESSAGE});
            setIsLoading(false);
        }
    }

    if (loading || loadingMe) {
        return (
            <>
                <SimpleText>...</SimpleText>
            </>
        );
    }

    if (me && me.isExpert) {
        return <></>;
    }

    if (!pack) {
        return (
            <MainView>
                <PageTitle>Paiement</PageTitle>
                <SimpleText style={styles.title}>
                    Vous devez choisir un pack pour procéder au paiement
                </SimpleText>
                <Button
                    titleStyle={{fontSize: 16, marginHorizontal: 20}}
                    title={'Choisir un pack'}
                    onPress={goToPack}
                />
            </MainView>
        );
    }

    if (!me) {
        return (
            <MainView>
                <PageTitle>Paiement</PageTitle>
                <SimpleText style={styles.title}>
                    Vous devez être connecter pour procéder au paiement
                </SimpleText>
                <Button
                    titleStyle={{fontSize: 16, marginHorizontal: 20}}
                    title={'Se connecter'}
                    onPress={goToLogin}
                />
            </MainView>
        );
    }

    return (
        <MainView>
            <PageTitle>Paiement de {pack?.node?.amount} €</PageTitle>
            {isPaymentValidated && (
                <>
                    <View style={styles.card}>
                        <SimpleText style={styles.title}>Utiliser ma carte</SimpleText>
                        <Card stripe={stripe} onPress={() => buy('USER')} preview={true} />
                    </View>
                    <SimpleText style={styles.or}>OU</SimpleText>
                </>
            )}
            <Pressable style={styles.card} onPress={() => buy('OTHER')}>
                <SimpleText style={styles.title}>Choisir mon moyen de paiement</SimpleText>
                <Button
                    titleStyle={{fontSize: 16, marginHorizontal: 20}}
                    title={'Payer'}
                    onPress={() => buy('OTHER')}
                />
                <View style={styles.cards}>
                    <Icons.Visa size={24} color={Colors.secondary} />
                    <Icons.Mastercard size={24} color={Colors.secondary} />
                    <Icons.Paypal size={24} color={Colors.secondary} />
                    <Icons.Amex size={24} color={Colors.secondary} />
                    <Icons.Jcb size={24} color={Colors.secondary} />
                    <Icons.Discover size={24} color={Colors.secondary} />
                    <Icons.Diners size={24} color={Colors.secondary} />
                </View>
            </Pressable>
            <View style={styles.info}>
                <Icons.Lock size={24} color={Colors.secondary} />
                <SimpleText style={styles.secure}>Paiement sécurisé</SimpleText>
            </View>
            <CustomModal
                visible={isloading && !loadingModalClosed}
                onRequestClose={() => setLoadingModalClosed(true)}
            >
                <SimpleText style={isDesktop ? {} : styles.loading}>
                    Paiement en cours ...
                </SimpleText>
            </CustomModal>
        </MainView>
    );
}

const styles = StyleSheet.create({
    card: {
        textAlign: 'center',
        justifyContent: 'center',
        marginBottom: 25,
        backgroundColor: Colors.dark,
        borderColor: Colors.primary,
        borderWidth: 1,
        borderRadius: 5,
        padding: 15,
        width: 300,
        alignSelf: 'center',
    },
    title: {
        color: Colors.secondary,
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        alignSelf: 'center',
    },
    stripe: {
        width: 93,
        height: 44,
    },
    secure: {
        color: Colors.secondary,
        fontSize: 14,
        marginLeft: 15,
        alignSelf: 'center',
    },
    cards: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
    },
    info: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 50,
    },
    loading: {
        paddingVertical: 50,
        textAlign: 'center',
        alignSelf: 'center',
    },
    or: {
        color: Colors.secondary,
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        alignSelf: 'center',
        marginBottom: 25,
    },
});
