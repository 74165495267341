import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ModalProps, FlatList} from 'react-native';

import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {RecallInput} from '~/components/common/Inputs';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {getEmailRecall_getEmailRecall_edges as EmailRecall} from '~/components/user/expert/account/types/getEmailRecall';
import logEvent from '~/helpers/analytics';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export interface EmailRecallInput {
    dayNumber: number;
    content: string;
}

const GET_EMAIL_RECALL = gql`
    query getEmailRecall {
        getEmailRecall {
            edges {
                node {
                    dayNumber
                    content
                }
            }
        }
    }
`;

const UPDATE_EMAIL_RECALL = gql`
    mutation updateEmailRecall($emails: [EmailRecallInput]) {
        updateEmailRecall(input: {emails: $emails}) {
            ok
        }
    }
`;

const DAYS = [2, 5, 10, 15];

export default function PopupEmail(props: ModalProps & {onSuccess: Function}) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const [load, {called, loading, data, refetch}] = useLazyQuery(GET_EMAIL_RECALL, {
        fetchPolicy: 'network-only',
    });
    const [updateEmailRecall, {data: callData, error: callError}] = useMutation(
        UPDATE_EMAIL_RECALL,
        {
            errorPolicy: 'all',
        }
    );
    const [emails, setEmails] = useState<EmailRecallInput[]>([]);

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    useEffect(() => {
        let emailsRecall = DAYS.map((n: number) => {
            const email = data?.getEmailRecall?.edges?.find(
                (f: EmailRecall) => f.node?.dayNumber == n.toString()
            );

            return {
                dayNumber: n,
                content: email?.node?.content ? email?.node?.content : '',
            } as EmailRecallInput;
        });

        setEmails(emailsRecall);
    }, [data]);

    async function update() {
        const data = {
            emails: emails,
        };

        await updateEmailRecall({variables: data});
        reload();
        props.onSuccess();
        logEvent('modify_expert', {fields: 'email_recall'});
    }

    const message = (
        <SimpleText style={styles.description}>
            Pour vous permettre de recontacter vos client plus facilement, rédiger ici les
            messages que vous pourrez leur envoyer en un clic. Vous pouvez prendre des
            nouvelles de leur situation, les informer que vous êtes disponible pour un nouvel
            échange avec eux et leur indiquer vos disponibilités.
        </SimpleText>
    );

    function renderEmail({item}: {item: EmailRecallInput}) {
        const dayNumber = item.dayNumber;

        function onChange(content: string) {
            let newEmailsRecall = [...emails];
            let email = newEmailsRecall.find((e) => e.dayNumber == dayNumber);
            if (email) {
                email.content = content;
            }

            setEmails(newEmailsRecall);
        }

        return (
            <>
                <SimpleText style={styles.message}>Message "J+{dayNumber}"</SimpleText>
                <RecallInput
                    style={deviceStyles.input}
                    value={item.content}
                    onChangeText={onChange}
                />
            </>
        );
    }

    if (emails) {
        const numberOfValidEmail = emails.filter((e) => e.content != '').length;
        return (
            <CustomModal
                title="Préparez vos messages automatiques"
                iconName="mail-bulk"
                message={message}
                hasCloseIcon={true}
                visible={props.visible}
                onRequestClose={props.onRequestClose}
                mobileContentMaxWidth={600}
                desktopContentMaxWidth={800}
            >
                <SimpleText style={styles.tips}>
                    <Icons.Info size={16} style={{marginRight: 10}} color={'black'} />
                    Pour indiquer le nom de l’utilisateur automatiquement, indiquer #Nom
                </SimpleText>

                <FlatList
                    style={styles.mailContainer}
                    data={emails}
                    renderItem={renderEmail}
                    keyExtractor={(item, i) => item.dayNumber.toString()}
                />

                <SimpleText style={styles.tips}>
                    <Icons.Info size={16} style={{marginRight: 10}} color={'black'} />
                    Chaque message sera envoyé automatiquement pour un client non-payant à
                    recontacter x jours après la dernière communication
                </SimpleText>

                <Button
                    icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                    title="Valider"
                    buttonStyle={{width: 160, alignSelf: 'center', marginTop: 10}}
                    onPress={update}
                />
                {numberOfValidEmail < 4 && (
                    <SimpleText style={styles.error}>
                        Vous devez remplir les 4 messages de relances si vous souhaitez que
                        votre profil soit visible pour les clients.
                    </SimpleText>
                )}
            </CustomModal>
        );
    } else {
        return <></>;
    }
}

const styles = StyleSheet.create({
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        padding: 25,
    },
    description: {
        fontSize: 14,
    },
    tips: {
        fontSize: 14,
        fontStyle: 'italic',
        padding: 15,
    },
    mailContainer: {},
    message: {
        paddingLeft: 10,
        paddingBottom: 5,
        paddingTop: 5,
    },
    input: {
        height: 75,
        backgroundColor: 'white',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
});

const mobileStyles = StyleSheet.create({
    input: {
        height: 150,
        backgroundColor: 'white',
    },
});
