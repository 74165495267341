import React, {useEffect, useState} from 'react';

import Settings from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import {getIdentification} from '~/helpers/identification';

import {CallStatusContext} from './CallStatusContext';
import {ChatStatusContext} from './ChatStatusContext';

const TIMEOUT_SOCKET = 5000;

export function GlobalEvent(props: {children: React.ReactNode}) {
    const {me} = React.useContext(UserContext);
    const call = React.useContext(CallStatusContext);
    const chat = React.useContext(ChatStatusContext);
    const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

    enum SubscriptionType {
        CALL = 'CALL',
        CHAT = 'CHAT',
    }

    type SubscriptionParams = {
        type: SubscriptionType;
        chatId: string;
        callSid: string;
        clientId: string;
    };

    useEffect(() => {
        subscribeExpertGlobalEvents(true);
    }, [me]);

    useEffect(() => {
        setTimeout(() => subscribeExpertGlobalEvents(false), TIMEOUT_SOCKET);
    }, [webSocket]);

    async function subscribeExpertGlobalEvents(force: boolean) {
        const expert = me?.isExpert && me?.id;
        const socket = webSocket != null;

        if (expert && (force || !socket)) {
            const id = encodeURIComponent(me?.id);
            const device = encodeURIComponent(await getIdentification());
            const uri = Settings.expertGlobalSocket(id, device);
            const ws = new WebSocket(uri);

            ws.onmessage = async function (event) {
                const data: SubscriptionParams = JSON.parse(event.data);

                if (data.type == SubscriptionType.CALL) {
                    const callData = {
                        callSid: data.callSid,
                        clientId: data.clientId,
                    };
                    call?.current?.newCall && call?.current?.newCall(callData);
                }

                if (data.type == SubscriptionType.CHAT) {
                    chat?.current?.newChat &&
                        chat?.current?.newChat(data.chatId, data.clientId, me);
                }
            };

            ws.onclose = function (event) {
                setWebSocket(null);
            };

            ws.onerror = function (event) {
                setWebSocket(null);
            };

            setWebSocket(ws);
        }

        if (!expert && socket) {
            webSocket.close();
        }
    }

    return <>{props.children}</>;
}
