import React, {useState} from 'react';
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';

import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {getPackMinuteContent} from '~/helpers/pack';
import {GetPackQuery_getPacks_edges_node} from '~/queries/types/GetPackQuery';
import {PackType, StudyType} from '~/types/graphql-global-types';

import {BallItem} from '../common/IconText';
import StudyContent from './StudyContent';

type PopupPackProps = {
    pack: GetPackQuery_getPacks_edges_node | null;
    onRequestClose: () => void;
};

export default function PopupPack(props: PopupPackProps) {
    const pack = props.pack;
    const navigation = useNavigation();
    const [study, setStudy] = useState<boolean>(false);

    function goToBasket() {
        // navigation.navigate('BasketScreen', {packId: pack?.packId});

        close();
        setTimeout(() => {
            navigation.navigate('Home', {
                screen: 'PackScreen',
                params: {screen: 'BasketScreen', params: {packId: pack?.packId}},
            });
        }, 500);
    }

    function goToLogin() {
        close();
        setTimeout(() => {
            navigation.navigate('LoginScreen');
        }, 500);
    }

    async function buy() {
        if (pack?.packType == PackType.MINUTE) {
            goToBasket();
        }
        if (pack?.packType == PackType.EMAIL) {
            setStudy(true);
        }
    }

    async function close() {
        props.onRequestClose();
        setStudy(false);
    }

    const title = study ? 'Vous avez choisi : Pack ' + pack?.name : 'Pack ' + pack?.name;

    return (
        <CustomModal title={title} visible={Boolean(pack)} hasCloseIcon onRequestClose={close}>
            {!study && <PackContent pack={pack} buy={buy} />}
            {study && (
                <StudyContent pack={pack} goToBasket={goToBasket} goToLogin={goToLogin} />
            )}
        </CustomModal>
    );
}

type PackContentProps = {
    pack: GetPackQuery_getPacks_edges_node | null;
    buy: () => void;
};

function PackContent(props: PackContentProps) {
    const pack = props.pack;
    const buy = props.buy;
    return (
        <View style={styles.container}>
            <SimpleText style={styles.price}>{pack?.amount} €</SimpleText>
            <SimpleText style={styles.desc}>{pack?.longDescription}</SimpleText>
            <SimpleText style={styles.desc}>{pack?.avantage}</SimpleText>
            <SimpleText style={styles.title}>Avantages</SimpleText>
            {pack?.packType == PackType.MINUTE && (
                <BallItem text={getPackMinuteContent(pack)} style={styles.avantage} />
            )}
            {pack?.packType == PackType.EMAIL && (
                <>
                    <BallItem
                        text={
                            'Je fais ma demande via le formulaire présent sur la page suivante'
                        }
                        style={styles.avantage}
                    />
                    <BallItem
                        text={
                            'Je reçois mon étude complète par email, dans mon espace personnel'
                        }
                        style={styles.avantage}
                    />
                    <BallItem
                        text={
                            "J'ai droit à 3 messages de suivi si jamais j'ai des incompréhensions"
                        }
                        style={styles.avantage}
                    />
                    <BallItem
                        text={"Utilisation immédiate après l'achat"}
                        style={styles.avantage}
                    />
                    {pack?.studyType == StudyType.NUMEROLOGY && (
                        <BallItem
                            text={
                                'Des délais de réponses sont à prévoir, une étude complète en numérologie demande du temps (+/- 15 jours)'
                            }
                            style={styles.avantage}
                        />
                    )}
                    {pack?.studyType == StudyType.ASTROLOGY && (
                        <BallItem
                            text={
                                'Des délais de réponses sont à prévoir, une étude complète en astrologie demande du temps (+/- 15 jours)'
                            }
                            style={styles.avantage}
                        />
                    )}
                </>
            )}

            {pack?.packType == PackType.MINUTE && (
                <BallItem
                    text={`Une économie d'au moins ${pack?.economy} €*`}
                    style={styles.avantage}
                />
            )}
            {pack?.packType == PackType.MINUTE && (
                <BallItem
                    text={`Valable ${pack?.validityDays} jours à compter de la date d'achat`}
                    style={styles.avantage}
                />
            )}
            <BallItem text={`Paiement sécurisé grâce au 3D secure`} style={styles.avantage} />
            {pack?.packType == PackType.MINUTE && (
                <>
                    <BallItem text={`Une maîtrise de votre budget`} style={styles.avantage} />
                    <BallItem text={`Une consultation immédiate`} style={styles.avantage} />
                    <BallItem
                        text={`Aucune obligation d'utiliser votre crédit d'un seul coup`}
                        style={styles.avantage}
                    />
                    <BallItem
                        text={`Grâce à la pop d’avis qui s’affiche après votre consultation, donnez-nous
votre impression !`}
                        style={styles.avantage}
                    />
                </>
            )}

            {pack?.packType == PackType.EMAIL && (
                <BallItem
                    text={'Une consultation complète et détaillée'}
                    style={styles.avantage}
                />
            )}
            <TouchableOpacity onPress={buy} style={styles.center}>
                <LinearGradient
                    start={{x: 0, y: 0}}
                    end={{x: 1, y: 0}}
                    colors={Colors.rainbow}
                    style={styles.button}
                >
                    <SimpleText style={styles.buy}>J'achète ce pack</SimpleText>
                </LinearGradient>
            </TouchableOpacity>
            {pack?.packType == PackType.MINUTE && (
                <SimpleText style={styles.ps}>*selon tarif à la minute du voyant</SimpleText>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 500,
        margin: 'auto',
    },
    back: {
        width: 175,
        height: 350,
        borderRadius: 8,
    },
    bloc: {
        backgroundColor: 'white',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        // borderRadius: 5,
        marginHorizontal: 10,
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 20,
    },
    price: {
        fontSize: 36,
        fontWeight: 'bold',
        backgroundImage: '-webkit-linear-gradient(left, #4bc0c8, #c779d0, #feac5e)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        alignSelf: 'center',
        textAlign: 'center',
    },
    title: {
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
        fontSize: 20,
        marginVertical: 20,
    },
    desc: {
        fontSize: 16,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 15,
        color: Colors.secondary,
    },
    avantage: {
        color: Colors.secondary,
    },
    ps: {
        fontSize: 8,
    },
    buy: {
        textAlign: 'center',
        color: 'white',
        fontSize: 18,
    },
    button: {
        borderRadius: 5,
        paddingHorizontal: 30,
        paddingVertical: 10,
        maxWidth: 250,
        margin: 20,
    },
    center: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
