import {
    GetLastMessageQuery,
    GetLastMessageQuery_getLastMessages_edges_node_exchanges,
} from '~/components/user/client/dashboard/types/GetLastMessageQuery';
import {
    GetMessageQuery,
    GetMessageQuery_getMessagesGroupClient_edges_node_messageGroup_attachments,
    GetMessageQuery_getMessagesGroupExpert_edges_node_attachments,
    GetMessageQuery_getMessages_edges_node_exchanges,
    GetMessageQuery_getMessages_edges_node_exchanges_edges_node_attachments,
} from '~/components/user/client/message/types/GetMessageQuery';
import {sorted} from '~/helpers/list';
import {MessageTypeEnum} from '~/types/graphql-global-types';

export enum ExchangeTypeEnum {
    CLIENT_TO_EXPERT = 'CLIENT_TO_EXPERT',
    EXPERT_TO_CLIENT = 'EXPERT_TO_CLIENT',
}

export enum MessageStatusEnum {
    SENT = 'SENT',
    ANSWERED = 'ANSWERED',
    EXPIRED = 'EXPIRED',
}

export enum EnumModeMessageBox {
    CLIENT = 1,
    EXPERT = 2,
}

export function getTypeLabel(type: MessageTypeEnum, group: boolean) {
    if (group) {
        return 'Groupé';
    }

    switch (type) {
        case MessageTypeEnum.SIMPLE_QUESTION:
            return 'Question simple';
        case MessageTypeEnum.COMPLETE_STUDY:
            return 'Étude complète';
        case MessageTypeEnum.FREE:
            return 'Suivi';
        case MessageTypeEnum.PACK_STUDY:
            return 'Étude pack';
        default:
            return '';
    }
}

export function getCreationDate(exchange: MessageExchange) {
    return Date.parse(exchange.creationDate);
}

export function getLastExchangeFromMessage(item: Message) {
    const exchanges = item.exchanges;
    const lastExchange = sorted(exchanges, getCreationDate, true)[0];
    const date = lastExchange.creationDate;
    return Date.parse(date);
}

export function getLastExchange(item: Message) {
    const exchanges = item.exchanges;
    return sorted(exchanges, getCreationDate, true)[0];
}

export type MessageProfile = {
    id: string;
    displayName: string;
    pictureName: string;
};

export type MessageExchange = {
    messageExchangeId: string;
    content: string;
    type: ExchangeTypeEnum;
    creationDate: any;
    attachments: string[];
};

export type Message = {
    groupVisible: boolean;
    groupInternal: boolean;
    messageId: string;
    status: MessageStatusEnum;
    type: MessageTypeEnum;
    clientRead: boolean;
    clientArchived: boolean;
    expertRead: boolean;
    expertArchived: boolean;
    creationDate: any;
    subject: string;
    responseDate: any | null;
    reviewId: number | null;
    expert: MessageProfile;
    client: MessageProfile;
    exchanges: MessageExchange[];
};

function getAttachments(
    data:
        | GetMessageQuery_getMessagesGroupExpert_edges_node_attachments
        | GetMessageQuery_getMessagesGroupClient_edges_node_messageGroup_attachments
        | GetMessageQuery_getMessages_edges_node_exchanges_edges_node_attachments
        | null
        | undefined
) {
    let attachments = [] as string[];

    if (data?.edges) {
        for (var i = 0; i < data.edges.length; i++) {
            const src = data.edges[i];

            if (src?.node) {
                attachments.push(src.node.url);
            }
        }
    }

    return attachments;
}

function getExchanges(
    data: GetMessageQuery_getMessages_edges_node_exchanges | null | undefined
) {
    let exchanges = [] as MessageExchange[];

    if (data?.edges) {
        for (var i = 0; i < data.edges.length; i++) {
            const src = data.edges[i];

            let exchange = {
                messageExchangeId: src?.node?.messageExchangeId ?? '',
                content: src?.node?.content ?? '',
                type: src?.node?.type ?? ExchangeTypeEnum.EXPERT_TO_CLIENT,
                creationDate: src?.node?.creationDate,
                attachments: getAttachments(src?.node?.attachments),
            } as MessageExchange;

            exchanges.push(exchange);
        }
    }

    return exchanges;
}

function getMessages(data: GetMessageQuery) {
    let messages = [] as Message[];

    if (data?.getMessages?.edges) {
        for (var i = 0; i < data.getMessages.edges.length; i++) {
            const src = data.getMessages.edges[i];

            const expert = {
                id: src?.node?.expert?.id,
                displayName: src?.node?.expert?.profile?.displayName,
                pictureName: src?.node?.expert?.profile?.pictureName,
            } as MessageProfile;

            const client = {
                id: src?.node?.client?.id,
                displayName: src?.node?.client?.profile?.displayName,
                pictureName: src?.node?.client?.profile?.pictureName,
            } as MessageProfile;

            let message = {
                groupVisible: false,
                groupInternal: false,
                messageId: src?.node?.messageId ?? '',
                status: src?.node?.status ?? MessageStatusEnum.SENT,
                type: src?.node?.type ?? MessageTypeEnum.FREE,
                clientRead: src?.node?.clientRead ?? false,
                clientArchived: src?.node?.clientArchived ?? false,
                expertRead: src?.node?.expertRead ?? false,
                expertArchived: src?.node?.expertArchived ?? false,
                creationDate: src?.node?.creationDate,
                subject: src?.node?.subject ?? '',
                responseDate: src?.node?.responseDate,
                reviewId: src?.node?.reviewId,
                expert: expert,
                client: client,
                exchanges: getExchanges(src?.node?.exchanges),
            } as Message;

            messages.push(message);
        }
    }

    return messages;
}

function getMessagesGroupClient(data: GetMessageQuery) {
    let messages = [] as Message[];

    if (data?.getMessagesGroupClient?.edges) {
        for (var i = 0; i < data.getMessagesGroupClient.edges.length; i++) {
            const src = data.getMessagesGroupClient.edges[i];

            const expert = {
                id: src?.node?.messageGroup?.expert?.id,
                displayName: src?.node?.messageGroup?.expert?.profile?.displayName,
                pictureName: src?.node?.messageGroup?.expert?.profile?.pictureName,
            } as MessageProfile;

            const client = {
                id: src?.node?.client?.id,
                displayName: src?.node?.client?.profile?.displayName,
                pictureName: src?.node?.client?.profile?.pictureName,
            } as MessageProfile;

            let message = {
                groupVisible: false,
                groupInternal: true,
                messageId: src?.node?.messageGroup?.messageGroupId ?? '',
                status: MessageStatusEnum.SENT,
                type: MessageTypeEnum.FREE,
                clientRead: src?.node?.clientRead ?? false,
                clientArchived: src?.node?.clientArchived ?? false,
                expertRead: true,
                expertArchived: false,
                creationDate: src?.node?.messageGroup?.creationDate,
                subject: src?.node?.messageGroup?.subject ?? '',
                responseDate: null,
                reviewId: null,
                expert: expert,
                client: client,
                exchanges: [
                    {
                        messageExchangeId: src?.node?.messageGroupRecipientId ?? '',
                        content: src?.node?.messageGroup?.content ?? '',
                        type: ExchangeTypeEnum.EXPERT_TO_CLIENT,
                        creationDate: src?.node?.messageGroup?.creationDate,
                        attachments: getAttachments(src?.node?.messageGroup?.attachments),
                    },
                ],
            } as Message;

            messages.push(message);
        }
    }

    return messages;
}

function getMessagesGroupExpert(data: GetMessageQuery) {
    let messages = [] as Message[];

    if (data?.getMessagesGroupExpert?.edges) {
        for (var i = 0; i < data.getMessagesGroupExpert.edges.length; i++) {
            const src = data.getMessagesGroupExpert.edges[i];

            const expert = {
                id: src?.node?.expert?.id,
                displayName: src?.node?.expert?.profile?.displayName,
                pictureName: src?.node?.expert?.profile?.pictureName,
            } as MessageProfile;

            const client = {
                id: '',
                displayName: 'Tous les clients payants',
                pictureName: '',
            } as MessageProfile;

            let message = {
                groupVisible: true,
                groupInternal: true,
                messageId: src?.node?.messageGroupId ?? '',
                status: MessageStatusEnum.SENT,
                type: MessageTypeEnum.FREE,
                clientRead: false,
                clientArchived: false,
                expertRead: true,
                expertArchived: src?.node?.expertArchived,
                creationDate: src?.node?.creationDate,
                subject: src?.node?.subject ?? '',
                responseDate: null,
                reviewId: null,
                expert: expert,
                client: client,
                exchanges: [
                    {
                        messageExchangeId: src?.node?.messageGroupId ?? '',
                        content: src?.node?.content ?? '',
                        type: ExchangeTypeEnum.EXPERT_TO_CLIENT,
                        creationDate: src?.node?.creationDate,
                        attachments: getAttachments(src?.node?.attachments),
                    },
                ],
            } as Message;

            messages.push(message);
        }
    }

    return messages;
}

function getExchangesFromLastMessage(
    data: GetLastMessageQuery_getLastMessages_edges_node_exchanges | null | undefined
) {
    let exchanges = [] as MessageExchange[];

    if (data?.edges) {
        for (var i = 0; i < data.edges.length; i++) {
            const src = data.edges[i];

            let exchange = {
                messageExchangeId: '',
                content: src?.node?.content ?? '',
                type: src?.node?.type ?? ExchangeTypeEnum.EXPERT_TO_CLIENT,
                creationDate: src?.node?.creationDate,
                attachments: [],
            } as MessageExchange;

            exchanges.push(exchange);
        }
    }

    return exchanges;
}

function getLastMessages(data: GetLastMessageQuery) {
    let messages = [] as Message[];

    if (data?.getLastMessages?.edges) {
        for (var i = 0; i < data.getLastMessages.edges.length; i++) {
            const src = data.getLastMessages.edges[i];

            const expert = {
                id: src?.node?.expert?.id,
                displayName: src?.node?.expert?.profile?.displayName,
                pictureName: src?.node?.expert?.profile?.pictureName,
            } as MessageProfile;

            const client = {
                id: src?.node?.client?.id,
                displayName: src?.node?.client?.profile?.displayName,
                pictureName: src?.node?.client?.profile?.pictureName,
            } as MessageProfile;

            let message = {
                groupVisible: false,
                groupInternal: false,
                messageId: src?.node?.messageId ?? '',
                status: src?.node?.status ?? MessageStatusEnum.SENT,
                type: src?.node?.type ?? MessageTypeEnum.FREE,
                clientRead: src?.node?.clientRead ?? false,
                clientArchived: src?.node?.clientArchived ?? false,
                expertRead: src?.node?.expertRead ?? false,
                expertArchived: src?.node?.expertArchived ?? false,
                creationDate: src?.node?.creationDate,
                subject: src?.node?.subject ?? '',
                responseDate: null,
                reviewId: null,
                expert: expert,
                client: client,
                exchanges: getExchangesFromLastMessage(src?.node?.exchanges),
            } as Message;

            messages.push(message);
        }
    }

    return messages;
}

function getLastMessagesGroupClient(data: GetLastMessageQuery) {
    let messages = [] as Message[];

    if (data?.getLastMessagesGroupClient?.edges) {
        for (var i = 0; i < data.getLastMessagesGroupClient.edges.length; i++) {
            const src = data.getLastMessagesGroupClient.edges[i];

            const expert = {
                id: src?.node?.messageGroup?.expert?.id,
                displayName: src?.node?.messageGroup?.expert?.profile?.displayName,
                pictureName: src?.node?.messageGroup?.expert?.profile?.pictureName,
            } as MessageProfile;

            const client = {
                id: src?.node?.client?.id,
                displayName: src?.node?.client?.profile?.displayName,
                pictureName: src?.node?.client?.profile?.pictureName,
            } as MessageProfile;

            let message = {
                groupVisible: false,
                groupInternal: true,
                messageId: src?.node?.messageGroup?.messageGroupId ?? '',
                status: MessageStatusEnum.SENT,
                type: MessageTypeEnum.FREE,
                clientRead: src?.node?.clientRead ?? false,
                clientArchived: src?.node?.clientArchived ?? false,
                expertRead: true,
                expertArchived: false,
                creationDate: src?.node?.messageGroup?.creationDate,
                subject: src?.node?.messageGroup?.subject ?? '',
                responseDate: null,
                reviewId: null,
                expert: expert,
                client: client,
                exchanges: [
                    {
                        messageExchangeId: src?.node?.messageGroupRecipientId ?? '',
                        content: src?.node?.messageGroup?.content ?? '',
                        type: ExchangeTypeEnum.EXPERT_TO_CLIENT,
                        creationDate: src?.node?.messageGroup?.creationDate,
                        attachments: [],
                    },
                ],
            } as Message;

            messages.push(message);
        }
    }

    return messages;
}

export function mergeMessages(data: GetMessageQuery) {
    const messages = getMessages(data);
    const messagesGroupClient = getMessagesGroupClient(data);
    const messagesGroupExpert = getMessagesGroupExpert(data);

    return [...messages, ...messagesGroupClient, ...messagesGroupExpert];
}

export function mergeLastMessages(data: GetLastMessageQuery | undefined) {
    if (data) {
        const messages = getLastMessages(data);
        const messagesGroupClient = getLastMessagesGroupClient(data);

        return [...messages, ...messagesGroupClient];
    } else {
        return [];
    }
}

export function getStudyStatus(status: MessageStatusEnum) {
    if (status == MessageStatusEnum.SENT) {
        return 'En attente';
    }
    if (status == MessageStatusEnum.ANSWERED) {
        return 'Terminé';
    }
    return '-';
}

export function isImg(uri: string) {
    const name = uri.toLowerCase();
    if (name.endsWith('.png')) {
        return true;
    }
    if (name.endsWith('.jpg')) {
        return true;
    }
    if (name.endsWith('.gif')) {
        return true;
    }
    if (name.endsWith('.jpeg')) {
        return true;
    }
    return false;
}
