import {gql, MutationOptions, useMutation} from '@apollo/client';

import {setFavorite} from './types/setFavorite';

export const SET_FAVORITE = gql`
    mutation setFavorite($expertId: String!, $isFavorite: Boolean!) {
        setFavorite(input: {expertId: $expertId, isFavorite: $isFavorite}) {
            favorite {
                id
                expertId
            }
        }
    }
`;

export default function useFavoriteMutation(options?: MutationOptions<setFavorite>) {
    return useMutation<setFavorite>(SET_FAVORITE, {errorPolicy: 'all', ...options});
}
