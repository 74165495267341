// https://stripe.com/docs/payments/save-and-reuse
import * as React from 'react';
import {View, ViewStyle} from 'react-native';
import {StyleSheet} from 'react-native';

import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {StripeError} from '@stripe/stripe-js';
import type {ButtonProps} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import {SimpleText} from '~/components/common/Texts';
import {usePaymentIntent} from '~/components/landingPage/hook/usePaymentIntent';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {CommunicationStripeElements, useStripClientSecretData} from './WebElements';

export type StripeCheckoutOptions = {
    returnUrl: string;
    returnQueryParams: Record<string, string | null | undefined>;
    onPaymentIntentConfirmed?: () => void;
    onPaymentIntentError?: (error: StripeError) => void;
    setupIntent?: boolean; // Test: setup intent instead of a payment
};

export function SetupPaymentIntentForm({
    returnUrl,
    returnQueryParams,
    communicationId,
    service,
    onPaymentIntentConfirmed,
    onPaymentIntentError,
    packId,
    setupIntent,
}: ButtonProps & StripeCheckoutOptions & useStripClientSecretData) {
    const {isDesktop} = useDeviceQuery();
    const {isAuthenticated} = React.useContext(UserContext);

    const additionalDesktopStyle: ViewStyle = isDesktop
        ? {alignSelf: 'center', width: 600}
        : {};

    if (!isAuthenticated) {
        return (
            <SimpleText style={styles.promoParagraph}>
                Vous devez être connecté pour procéder au paiement
            </SimpleText>
        );
    }

    return (
        <View style={[additionalDesktopStyle, {margin: 10}]}>
            <CommunicationStripeElements
                communicationId={communicationId}
                service={service}
                returnUrl={returnUrl}
                returnQueryParams={returnQueryParams}
                packId={packId}
                setupIntent={setupIntent}
            >
                <PaymentForm
                    returnUrl={returnUrl}
                    returnQueryParams={returnQueryParams}
                    onPaymentIntentConfirmed={onPaymentIntentConfirmed}
                    onPaymentIntentError={onPaymentIntentError}
                    setupIntent={setupIntent}
                />
            </CommunicationStripeElements>
        </View>
    );
}

function PaymentForm(props: StripeCheckoutOptions) {
    const {me} = React.useContext(UserContext);
    // const {clientSecret} = React.useContext(StripeClientSecretContext);
    const {setPaymentIntent} = usePaymentIntent();
    const stripe = useStripe();
    const elements = useElements();
    const alert = useAlert();

    const [formReady, setFormReady] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [errorCode, setErrorCode] = React.useState<string>();

    const urlSearchParams = new URLSearchParams(props.returnQueryParams ?? {});
    urlSearchParams.append('event', 'stripe-success');
    const returnUrl = `${props.returnUrl}?${urlSearchParams.toString()}`;

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        let error: undefined | StripeError;
        if (props.setupIntent) {
            const {error: setupError, setupIntent} = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
                redirect: 'if_required',
            });
            error = setupError;
        } else {
            const {error: paymentError, paymentIntent} = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
                redirect: 'if_required',
            });
            error = paymentError;
            if (!error && paymentIntent) {
                setPaymentIntent(paymentIntent.id);
            }
        }

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
            setErrorCode(error.code);
            props.onPaymentIntentError?.(error);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            props.onPaymentIntentConfirmed?.();
        }
    };

    const name = React.useMemo(() => {
        if (me?.identity?.surname && me?.identity?.lastname) {
            return `${me?.identity?.surname} ${me?.identity?.lastname}`;
        }
    }, [me]);

    // React.useEffect(() => {
    //     async function checkExistingPaymentMethod() {
    //         if (!stripe || !clientSecret) {
    //             return;
    //         }
    //         const {error, paymentIntent} = await stripe.handleNextAction({
    //             clientSecret,
    //             // confirmParams: {
    //             //     return_url: returnUrl,
    //             // },
    //         });
    //         console.log('Payment intent is ', paymentIntent);
    //         if (error) {
    //             console.log('Need to register a card', error);
    //         } else {
    //             alert('All set, can proceed to call');
    //         }
    //     }
    //     checkExistingPaymentMethod();
    // }, [stripe, clientSecret, returnUrl]);

    if (errorCode != null) {
        alert({
            title: 'Erreur paiement',
            message: `Votre paiement n'a pu aboutir, appelez nous au ${Settings.support_telephone}`,
        });
    }

    return (
        <>
            <SimpleText style={{color: Colors.bad, marginVertical: 10}}>
                {errorMessage ? `Stripe : ${errorMessage}` : ''}
            </SimpleText>
            <SimpleText />
            {/* <LinkAuthenticationElement
                options={
                    me?.identity?.email
                        ? {
                              defaultValues: {
                                  email: me?.identity?.email,
                              },
                          }
                        : undefined
                }
            /> */}
            <PaymentElement
                onReady={() => setFormReady(true)}
                options={{
                    defaultValues: {
                        billingDetails: {
                            name,
                            email: me?.identity?.email,
                            phone: me?.identity?.phone ?? undefined,
                        },
                    },
                }}
            />
            {formReady && (
                <Button
                    title="Utiliser ce moyen de paiement"
                    iconName="credit-card"
                    onPress={handleSubmit}
                    buttonStyle={{backgroundColor: Colors.cta}}
                />
            )}
            {/* {errorCode == 'payment_intent_authentication_failure' && <SimpleText>{errorCode}</SimpleText>} */}
        </>
    );
}

const styles = StyleSheet.create({
    promoParagraph: {
        textAlign: 'center',
        fontSize: 16,
        padding: 20,
        maxWidth: 610,
        alignSelf: 'center',
    },
});
