export enum QualityEnum {
    LISTEN = 'La clarté de sa voyance', // 1
    HOST = 'Sa capacité d’écoute', // 2
    PRICE = 'La justesse des conseils', // 3
    RESPONSE = 'Son énergie', // 4
}

export enum GradeEnum {
    VERY_SATISFIED = 'Bluffé(e) !', // 1
    SATISFIED = 'Superbe !', // 2
    DISSATISFIED = 'Mi-Figue mi-Raisin !', // 3
    VERY_DISSATISFIED = 'Pas convaincu(e) ...', // 4
}
