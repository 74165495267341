import * as React from 'react';

import {NavigationContainer, DefaultTheme} from '@react-navigation/native';
import {StackActions} from '@react-navigation/native';
import {DrawerActions} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';

import {useAudio} from '~/contexts/AudioPlayer';
import {screen} from '~/helpers/analytics';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import NotFoundScreen from '~/screens/NotFoundScreen';
import CallLandingPageScreen from '~/screens/landingPage/Call';
import LandingPageFreeCallScreen from '~/screens/landingPage/FreeCall';
import LandingPageFreeCallNoCbScreen from '~/screens/landingPage/FreeCallNoCb';
import PackCallLandingPageScreen from '~/screens/landingPage/PackCall';
import SuperPromoCallLandingPageScreen from '~/screens/landingPage/SuperPromo';
import {RootStackParamList} from '~/types';

import LinkingConfiguration from './LinkingConfiguration';
import MobileDrawerNavigator from './MobileDrawerNavigator';
import MobileLinkingConfiguration from './MobileLinkingConfiguration';
import BottomTabNavigator from './TabNavigator';
import { CALL_LANDING_PAGE_TITLE, FREE_CALL_NO_CB_LANDING_PAGE_TITLE, PACK_CALL_LANDING_PAGE_TITLE, SUPER_PROMO_LANDING_PAGE_TITLE } from '~/constants/LandingPageSettings';

// These two functions are here to allow navigation outside the root navigator
// For example, we use it for the App global Header.
// More about this pattern here : https://reactnavigation.org/docs/navigating-without-navigation-prop/
export const navigationRef = React.createRef<typeof NavigationContainer>();
export function navigate(name: string, params?: Object) {
    navigationRef.current?.navigate(name, params);
}

export function push(...args) {
    navigationRef.current?.dispatch(StackActions.push(...args));
}

export function toggleDrawer() {
    navigationRef.current?.dispatch(DrawerActions.toggleDrawer());
}

export function openDrawer() {
    navigationRef.current?.dispatch(DrawerActions.openDrawer());
}

export function closeDrawer() {
    navigationRef.current?.dispatch(DrawerActions.closeDrawer());
}

export function getCurrentRouteName() {
    return navigationRef.current?.getCurrentRoute().name;
}

const theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: 'white',
        background: 'white',
    },
};

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation() {
    const {isDesktop} = useDeviceQuery();
    const {stop} = useAudio();
    const linking = isDesktop ? LinkingConfiguration : MobileLinkingConfiguration;

    // Get the current screen from the navigation state
    function getActiveRouteName(navigationState) {
        if (!navigationState) {
            return null;
        }

        const route = navigationState.routes[navigationState.index];

        // Parse the nested navigators
        if (route.routes) {
            return getActiveRouteName(route);
        }
        if (route.state) {
            return getActiveRouteName(route.state);
        }

        return route.name;
    }

    function onStateChange(state) {
        var name = getActiveRouteName(state);
        screen(name);
        stop();
    }

    return (
        <NavigationContainer
            theme={theme}
            linking={linking}
            ref={navigationRef}
            onStateChange={onStateChange}
        >
            <RootNavigator />
        </NavigationContainer>
    );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
    const {isDesktop} = useDeviceQuery();
    if (isDesktop) {
        return <DesktopRootNavigator />;
    } else {
        return <MobileRootNavigator />;
    }
}

function DesktopRootNavigator() {
    return (
        <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName="Root">
            <Stack.Screen
                name="LandingPageCallFreeNoCbScreen"
                component={LandingPageFreeCallNoCbScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: FREE_CALL_NO_CB_LANDING_PAGE_TITLE,
                })}
            />
            {/* <Stack.Screen
                name="LandingPageCallFreeScreen"
                component={LandingPageFreeCallScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: 'Kyvoitou.fr | Voyance gratuite par téléphone',
                })}
            /> */}
            <Stack.Screen
                name="LandingPageCallScreen"
                component={CallLandingPageScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: CALL_LANDING_PAGE_TITLE,
                })}
            />
            <Stack.Screen
                name="LandingPageCallPackScreen"
                component={PackCallLandingPageScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: PACK_CALL_LANDING_PAGE_TITLE,
                })}
            />
            <Stack.Screen
                name="LandingPageSuperPromoCallScreen"
                component={SuperPromoCallLandingPageScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: SUPER_PROMO_LANDING_PAGE_TITLE,
                })}
            />
            <Stack.Screen name="Root" component={BottomTabNavigator} />
            <Stack.Screen
                name="NotFound"
                component={NotFoundScreen}
                options={{title: 'Oops!'}}
            />
        </Stack.Navigator>
    );
}

function MobileRootNavigator() {
    return (
        <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName="Root">
            <Stack.Screen
                name="LandingPageCallFreeNoCbScreen"
                component={LandingPageFreeCallNoCbScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: FREE_CALL_NO_CB_LANDING_PAGE_TITLE,
                })}
            />
            {/* <Stack.Screen
                name="LandingPageCallFreeScreen"
                component={LandingPageFreeCallScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: 'Kyvoitou.fr | Voyance gratuite par téléphone',
                })}
            /> */}
            <Stack.Screen
                name="LandingPageCallScreen"
                component={CallLandingPageScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: CALL_LANDING_PAGE_TITLE,
                })}
            />
            <Stack.Screen
                name="LandingPageCallPackScreen"
                component={PackCallLandingPageScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: PACK_CALL_LANDING_PAGE_TITLE,
                })}
            />
            <Stack.Screen
                name="LandingPageSuperPromoCallScreen"
                component={SuperPromoCallLandingPageScreen}
                options={({navigation}) => ({
                    headerShown: false,
                    title: SUPER_PROMO_LANDING_PAGE_TITLE,
                })}
            />
            <Stack.Screen name="Root" component={MobileDrawerNavigator} />
            <Stack.Screen
                name="NotFound"
                component={NotFoundScreen}
                options={{title: 'Oops!'}}
            />
        </Stack.Navigator>
    );
}
