import React, {ReactNode} from 'react';
import {
    StyleSheet,
    Modal,
    View,
    ViewStyle,
    ModalProps,
    TouchableOpacity,
    GestureResponderEvent,
    ScrollView,
    Image,
} from 'react-native';

import * as Icons from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import Button from './Buttons';
import {SimpleText} from './Texts';
import {ModalTitle} from './Titles';

export default function CustomModal(props: CustomModalProps) {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return <BaseModal {...props} />;
    } else {
        return <MobileModal {...props} />;
    }
}

export function BaseModal(props: CustomModalProps) {
    const {
        onRequestClose,
        children,
        message,
        iconName,
        title,
        hasCloseIcon,
        desktopContentMaxWidth,
        ...modalProps
    } = props;
    const maxWidthStyle = desktopContentMaxWidth ? {maxWidth: desktopContentMaxWidth} : {};
    return (
        <PopupModal onRequestClose={onRequestClose} {...modalProps}>
            {title && (
                <Title
                    title={title}
                    iconName={iconName}
                    onRequestClose={onRequestClose}
                    hasCloseIcon={hasCloseIcon}
                />
            )}
            {message && <SimpleText style={styles.modalDescription}>{message}</SimpleText>}
            <ScrollView style={[styles.modalContentView, maxWidthStyle]}>
                {children}
            </ScrollView>
        </PopupModal>
    );
}

function MobileModal(props: CustomModalProps) {
    const {
        onRequestClose,
        children,
        message,
        iconName,
        title,
        hasCloseIcon,
        mobileContentMaxWidth,
        ...modalProps
    } = props;
    const maxWidthStyle = mobileContentMaxWidth ? {maxWidth: mobileContentMaxWidth} : {};
    return (
        <MobilePopupModal onRequestClose={onRequestClose} {...modalProps}>
            <ScrollView>
                {title && (
                    <MobileTitle
                        title={title}
                        iconName={iconName}
                        onRequestClose={onRequestClose}
                        hasCloseIcon={hasCloseIcon}
                    />
                )}
                <View style={[mobileStyles.modalContentView, maxWidthStyle]}>{children}</View>
            </ScrollView>
        </MobilePopupModal>
    );
}

function MobileTitle({title, iconName, onRequestClose, hasCloseIcon}: TitleProps) {
    return (
        <View style={mobileStyles.modalTitleContainer}>
            {iconName && Icons.getIcon(iconName, 25, Colors.secondary, {marginRight: 15})}
            <ModalTitle style={mobileStyles.modalTitle}>{title}</ModalTitle>
        </View>
    );
}

function MobilePopupModal(props: PopupModalProps) {
    const {width, children, ...emptyModalProps} = props;
    return (
        <MobileEmptyModal {...emptyModalProps}>
            <View style={[mobileStyles.modalView, {width: width}, props.style]}>
                {props.onRequestClose && (
                    <View style={mobileStyles.header}>
                        <TouchableOpacity onPress={props.onRequestClose}>
                            <SimpleText style={mobileStyles.close}>Fermer</SimpleText>
                        </TouchableOpacity>
                    </View>
                )}
                {children}
            </View>
        </MobileEmptyModal>
    );
}

function MobileEmptyModal(props: EmptyModalProps) {
    const {children, onRequestClose, ...modalProps} = props;
    return (
        <Modal
            animationType={modalProps.animationType || 'fade'}
            transparent={true}
            onRequestClose={onRequestClose}
            {...modalProps}
        >
            <View style={mobileStyles.bottomAffixView}>
                <TouchableOpacity
                    style={styles.modalBackDrop}
                    onPress={onRequestClose}
                    activeOpacity={1}
                />
                {children}
            </View>
        </Modal>
    );
}

function PopupModal(props: PopupModalProps) {
    const {width, children, ...emptyModalProps} = props;
    return (
        <EmptyModal {...emptyModalProps}>
            <View style={[styles.modalView, {width: width}, props.style]}>{children}</View>
        </EmptyModal>
    );
}

export function EmptyModal(props: EmptyModalProps) {
    const {children, onRequestClose, ...modalProps} = props;
    return (
        <Modal
            animationType={modalProps.animationType || 'fade'}
            transparent={true}
            onRequestClose={onRequestClose}
            {...modalProps}
        >
            <View style={styles.centeredView}>
                <TouchableOpacity
                    style={styles.modalBackDrop}
                    onPress={onRequestClose}
                    activeOpacity={1}
                />
                {children}
            </View>
        </Modal>
    );
}

function Title({title, iconName, onRequestClose, hasCloseIcon}: TitleProps) {
    return (
        <View style={styles.modalTitleContainer}>
            {iconName && Icons.getIcon(iconName, 25, Colors.secondary, {marginRight: 15})}
            <ModalTitle style={styles.modalTitle}>{title}</ModalTitle>
            {hasCloseIcon && (
                <TouchableOpacity onPress={onRequestClose}>
                    <Icons.Close size={20} color={Colors.bad} />
                </TouchableOpacity>
            )}
        </View>
    );
}

export function Alert({buttonText, content, ...modalProps}: AlertProps) {
    return (
        <BaseModal {...modalProps}>
            {content}
            <Button
                title={buttonText}
                style={{marginTop: 35}}
                onPress={modalProps.onRequestClose}
            />
        </BaseModal>
    );
}

export function Confirm({
    yesText,
    noText,
    onCancel,
    onYes,
    onNo,
    altStyle,
    onRequestClose,
    children,
    ...modalProps
}: ConfirmProps) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles : mobileStyles;
    function _onRequestClose() {
        onCancel?.();
        onRequestClose?.();
    }
    return (
        <BaseModal {...modalProps} onRequestClose={_onRequestClose}>
            <>
                {children}
                <View style={altStyle ? styles.confirmButtonsAltStyle : styles.confirmButtons}>
                    <Button
                        title={yesText}
                        onPress={onYes}
                        style={deviceStyle.confirmButton}
                    />
                    <Button
                        title={noText}
                        onPress={onNo}
                        style={
                            altStyle
                                ? deviceStyle.confirmButtonAltNo
                                : deviceStyle.confirmButton
                        }
                        buttonStyle={altStyle ? styles.noButtonAlt : {}}
                        titleStyle={altStyle ? styles.noButtonAltText : {}}
                    />
                </View>
            </>
        </BaseModal>
    );
}

export function MinimizablePopup(props: MinimizablePopupProps) {
    const {isDesktop} = useDeviceQuery();

    if (!props.visible) {
        return <></>;
    }

    if (!props.reduced) {
        const style = isDesktop ? styles.minimizablePopup : mobileStyles.minimizablePopup;

        return (
            <Modal transparent={true} visible={props.visible}>
                <View style={styles.centeredView}>
                    <TouchableOpacity
                        style={styles.modalBackDrop}
                        onPress={props.onBackgroundClick}
                        activeOpacity={1}
                    />
                    <View style={style}>{props.children}</View>
                </View>
            </Modal>
        );
    } else {
        const style = isDesktop ? {} : mobileStyles.reducedPopup;

        return <View style={[props.style, style]}>{props.children}</View>;
    }
}

export type AlertProps = {
    title?: string;
    message: string;
    content?: ReactNode;
    buttonText?: string;
    onRequestClose?: () => void;
    visible?: boolean;
    width?: number;
} & CustomModalProps;

export type ConfirmProps = {
    title?: string;
    message: ReactNode;
    yesText?: string;
    noText?: string;
    onRequestClose?: () => void;
    onCancel?: () => void;
    onYes?: () => void;
    onNo?: () => void;
    altStyle?: boolean;
    visible?: boolean;
} & CustomModalProps;

type TitleProps = {
    title?: string;
    iconName?: string;
    hasCloseIcon?: boolean;
    onRequestClose?: (event: GestureResponderEvent) => void;
};

export type EmptyModalProps = ModalProps & {
    children?: React.ReactNode;
};

export type PopupModalProps = EmptyModalProps & {
    width?: number;
};

export type CustomModalProps = EmptyModalProps &
    TitleProps & {
        message?: ReactNode;
        mobileContentMaxWidth?: number;
        desktopContentMaxWidth?: number;
    };

export type MinimizablePopupProps = TitleProps & {
    visible: boolean;
    reduced: boolean;
    onBackgroundClick: () => void;
    children: React.ReactNode;
    style: ViewStyle;
};

export function PaymentAlert() {
    return (
        <View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Image
                    source={{uri: Settings.getUrlPictoBall()}}
                    style={{marginRight: 15, alignSelf: 'center', width: 32, height: 32}}
                    accessibilityLabel={'Sécurité'}
                />
                <SimpleText>
                    Pour la sécurité de tous (clients et médiums), une emprunte bancaire de 100
                    € est nécessaire.
                </SimpleText>
            </View>
            <View
                style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}
            >
                <Image
                    source={{uri: Settings.getUrlPictoBall()}}
                    style={{
                        marginRight: 15,
                        alignSelf: 'center',
                        width: 32,
                        height: 32,
                    }}
                    accessibilityLabel={'Sécurité'}
                />
                <SimpleText>
                    Il s'agit d'une pré-autorisation et non d'un prélèvement.
                </SimpleText>
            </View>
            <View
                style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}
            >
                <Image
                    source={{uri: Settings.getUrlPictoBall()}}
                    style={{
                        marginRight: 15,
                        alignSelf: 'center',
                        width: 32,
                        height: 32,
                    }}
                    accessibilityLabel={'Sécurité'}
                />
                <SimpleText>
                    Le montant prélevé est calculé à la fin de votre communication en fonction
                    des minutes payantes consommées.
                </SimpleText>
            </View>
            <View
                style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 30}}
            >
                <Icons.Hand
                    size={32}
                    color={Colors.secondary}
                    style={{marginRight: 15, alignSelf: 'center'}}
                />
                <SimpleText>
                    Une question ? Appelez-nous au {Settings.support_telephone}.
                </SimpleText>
            </View>
        </View>
    );
}

export function MessagePaymentAlert() {
    return (
        <View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Image
                    source={{uri: Settings.getUrlPictoBall()}}
                    style={{marginRight: 15, alignSelf: 'center', width: 32, height: 32}}
                    accessibilityLabel={'Sécurité'}
                />
                <SimpleText>
                    Pour finaliser la réservation de votre consultation avec votre banque, nous
                    allons ouvrir une page sécurisée.
                </SimpleText>
            </View>
            <View
                style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}
            >
                <Image
                    source={{uri: Settings.getUrlPictoBall()}}
                    style={{
                        marginRight: 15,
                        alignSelf: 'center',
                        width: 32,
                        height: 32,
                    }}
                    accessibilityLabel={'Sécurité'}
                />
                <SimpleText>
                    Il s'agit du protocole 3D secure qui permet d'optimiser la sécurité de
                    votre paiement.
                </SimpleText>
            </View>
            <View
                style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 30}}
            >
                <Icons.Hand
                    size={32}
                    color={Colors.secondary}
                    style={{marginRight: 15, alignSelf: 'center'}}
                />
                <SimpleText>
                    Une question ? Appelez-nous au {Settings.support_telephone}.
                </SimpleText>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBackDrop: {
        backgroundColor: Colors.opacity,
        flex: 1,
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    modalView: {
        alignSelf: 'center',
        margin: 20,
        backgroundColor: Colors.dark,
        borderRadius: 20,
        padding: 35,
        shadowColor: 'black',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        maxHeight: '100%',
        flexShrink: 1,
    },
    modalDescription: {
        textAlign: 'center',
    },
    modalTitleContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
        alignItems: 'center',
    },
    modalTitle: {
        flex: 1,
        marginHorizontal: 30,
        textAlign: 'center',
    },
    confirmButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 35,
    },
    confirmButtonsAltStyle: {
        marginTop: 15,
    },
    noButtonAlt: {backgroundColor: 'transparent'},
    noButtonAltText: {
        color: Colors.primaryDarker,
        textDecorationLine: 'underline',
    },
    confirmButton: {
        minWidth: 200,
    },
    confirmButtonAltNo: {
        marginTop: 0,
    },
    modalContentView: {
        alignSelf: 'center',
        width: '100%',
    },
    minimizablePopup: {
        width: 900,
        backgroundColor: Colors.dark,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
});

const mobileStyles = StyleSheet.create({
    bottomAffixView: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    modalView: {
        alignSelf: 'stretch',
        margin: 0,
        backgroundColor: 'white',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        maxHeight: '80%',
    },
    header: {
        alignSelf: 'stretch',
        padding: 5,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: Colors.link,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    modalTitleContainer: {
        flex: 1,
        flexDirection: 'row',
        marginVertical: 10,
        marginHorizontal: 10,
        textAlign: 'center',
        backgroundColor: Colors.dark,
        padding: 15,
        borderRadius: 8,
        justifyContent: 'center',
    },
    modalTitle: {},
    close: {
        color: 'white',
        marginRight: 20,
    },
    confirmButton: {
        minWidth: 115,
    },
    confirmButtonAltNo: {
        marginTop: 0,
    },
    modalContentView: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 600,
    },
    minimizablePopup: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    reducedPopup: {
        top: 75,
        height: 25,
    },
});
