import React, {useCallback, useState} from 'react';
import {StyleSheet, TouchableOpacity, Image, Text, View, Pressable, Modal} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Link, useNavigation} from '@react-navigation/native';
import {Formik, FormikProps, FormikHelpers} from 'formik';
import {CheckBox} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import {
    BirthDateDayInput,
    BirthDateMonthInput,
    BirthDateYearInput,
    IdentifiantInput,
    MailInput,
    PasswordInput,
} from '~/components/common/Inputs';
import {PhoneInput} from '~/components/common/PhoneInput';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import {AlreadyConnected} from '~/components/navigation/Link';
import * as Colors from '~/constants/Colors';
import Settings, {IS_DEBUG} from '~/constants/Settings';
import {endpoints} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {register} from '~/helpers/login/auth';
import {axios} from '~/helpers/network';
import {subscription} from '~/helpers/newsletter';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';
import {CguText} from '~/screens/information/CguScreen';
import {QueryUtm} from '~/types';
import {GenderEnum} from '~/types/graphql-global-types';

import {MessageError} from '../common/Error';
import CustomModal from '../common/Modal';

const {identifiant, email, password, passwordConfirm, cgv, newsletter} = yupRules;
const validationSchema = yup
    .object()
    .shape({identifiant, email, password, passwordConfirm, cgv, newsletter});

const PROD_INITIAL_VALUES = {
    identifiant: '',
    email: '',
    password: '',
    passwordConfirm: '',
    cgv: false,
    phone: '',
    newsletter: false,
};

const randDevId = (Math.random() + 1).toString(36).substring(7);
const randDevPhone = Math.floor(Math.random() * 9_999);
const DEV_INITIAL_VALUES = {
    identifiant: `chris_test_ID_${randDevId}`,
    email: `christian.glacet+${randDevId}@gmail.com`,
    password: '123456',
    passwordConfirm: '123456',
    phone: `+3362493${randDevPhone}`,
    cgv: true,
    newsletter: false,
};

type RegisterFormProps = {
    query: QueryUtm;
    overrideOnRegister?: () => void;
    landingPageVersion?: boolean;
};

export default function RegisterForm(props: RegisterFormProps) {
    const {me} = React.useContext(UserContext);
    const alert = useAlert();
    const navigation = useNavigation();
    const [createUser, {data}] = useMutation(CREATE_USER);
    const [selectedGender, setSelectedGender] = useState<GenderEnum | null>(null);
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [genderError, setGenderError] = useState<string>('');
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>('01');
    const [year, setYear] = useState<string>('1900');

    async function registerUser(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        try {
            const body = JSON.stringify({phone: phone});
            const response = await axios.post(endpoints.phone, body);

            if (!selectedGender) {
                setGenderError('Vous devez sélectionner un genre');
            } else if (!response.data.available) {
                setGenderError('');
                setPhoneError('Ce numéro de téléphone est déjà utilisé');
            } else {
                const credentials = await register(values.email, values.password);
                const token = await credentials?.user?.getIdToken();
                const data = {
                    authToken: token,
                    displayName: values.identifiant,
                    birthdate: `${day}/${month}/${year}`,
                    email: values.email,
                    phone: phone,
                    gender: selectedGender,
                    registrationUrl: `${window.location.origin}${window.location.pathname}`,
                };
                await createUser({variables: data});
                if (values.newsletter) {
                    await subscription(values.email);
                }
                if (props.overrideOnRegister == null) {
                    alert({
                        title: 'Merci de votre inscription !',
                        message:
                            "Vous êtes désormais connecté, vous allez être redirigé vers l'accueil.",
                        onClose: () => navigation.navigate('HomeScreen'),
                    });
                } else {
                    props.overrideOnRegister();
                }
                logEvent('register', props.query);

                if (props.query.utm_medium != null) {
                    logEvent(`register_from_${props.query.utm_medium}`, props.query);
                }
            }
        } catch (error) {
            if (error.code == 'auth/email-already-in-use') {
                setErrors({email: 'Cet email est déjà utilisé'});
            }
            throw error;
        }
    }

    return (
        <View>
            {me && <AlreadyConnected />}
            {!me && (
                <Formik
                    initialValues={IS_DEBUG ? DEV_INITIAL_VALUES : PROD_INITIAL_VALUES}
                    onSubmit={registerUser}
                    validationSchema={validationSchema}
                >
                    {(formikProps) => (
                        <RegisterInputs
                            formikProps={formikProps}
                            selectedGender={selectedGender}
                            setSelectedGender={setSelectedGender}
                            phone={phone}
                            setPhone={setPhone}
                            phoneError={phoneError}
                            setPhoneError={setPhoneError}
                            genderError={genderError}
                            day={day}
                            setDay={setDay}
                            month={month}
                            setMonth={setMonth}
                            year={year}
                            setYear={setYear}
                            landingPageVersion={props.landingPageVersion}
                        />
                    )}
                </Formik>
            )}
        </View>
    );
}

function RegisterInputs(props: RegisterInputsProps) {
    const [cguVisible, setCguVisible] = useState(false);
    const showCgu = useCallback(() => setCguVisible(true), []);
    const hideCgu = useCallback(() => setCguVisible(false), []);
    function onPressCgv() {
        props.formikProps.setFieldValue('cgv', !props.formikProps.values.cgv);
    }
    function onPressNewsletter() {
        props.formikProps.setFieldValue('newsletter', !props.formikProps.values.newsletter);
    }

    const labelStyle = props.landingPageVersion ? {marginTop: 5} : {};
    const noErrorAboveLabelStyle = props.landingPageVersion ? {marginTop: 20} : {};
    const rememberLabel = props.landingPageVersion ? {marginTop: 5} : {};
    const formBorderStyle = props.landingPageVersion
        ? {borderColor: Colors.primary, borderWidth: 2, borderRadius: 2, padding: 10}
        : {};

    return (
        <View style={[styles.container, formBorderStyle]}>
            <FormLabel style={labelStyle}>Genre</FormLabel>
            <View style={styles.genders}>
                <ItemPressable
                    setSelected={props.setSelectedGender}
                    text={'Femme'}
                    value={GenderEnum.WOMAN}
                    selected={props.selectedGender == GenderEnum.WOMAN}
                />
                <ItemPressable
                    setSelected={props.setSelectedGender}
                    text={'Homme'}
                    value={GenderEnum.MAN}
                    selected={props.selectedGender == GenderEnum.MAN}
                />
                <ItemPressable
                    setSelected={props.setSelectedGender}
                    text={'Autre'}
                    value={GenderEnum.OTHER}
                    selected={props.selectedGender == GenderEnum.OTHER}
                />
            </View>
            <MessageError message={props.genderError} />
            <FormLabel style={noErrorAboveLabelStyle}>{yupLabels.identifiant}</FormLabel>
            <IdentifiantInput
                placeholder={yupLabels.identifiant}
                value={props.formikProps.values.identifiant}
                onChangeText={props.formikProps.handleChange('identifiant')}
                onBlur={() => props.formikProps.setFieldTouched('identifiant')}
                blurOnSubmit={true}
                errorMessage={props.formikProps.errors.identifiant || ' '}
                autoCompleteType="off"
                style={styles.input}
                inputStyle={styles.input}
            />
            <FormLabel style={noErrorAboveLabelStyle}>Date de naissance</FormLabel>
            <View style={styles.dateContainer}>
                <BirthDateDayInput day={props.day} setDay={props.setDay} />
                <BirthDateMonthInput month={props.month} setMonth={props.setMonth} />
                <BirthDateYearInput year={props.year} setYear={props.setYear} />
            </View>
            <FormLabel style={noErrorAboveLabelStyle}>Email</FormLabel>
            <MailInput
                placeholder={yupLabels.email}
                value={props.formikProps.values.email}
                onChangeText={props.formikProps.handleChange('email')}
                onBlur={() => props.formikProps.setFieldTouched('email')}
                blurOnSubmit={true}
                errorMessage={props.formikProps.errors.email || ' '}
                style={styles.input}
                inputStyle={styles.input}
            />
            <FormLabel style={labelStyle}>Numéro de téléphone</FormLabel>
            <PhoneInput
                disabled={false}
                initialize={false}
                phone={IS_DEBUG ? props.formikProps.values.phone : props.phone}
                setPhone={props.setPhone}
                phoneError={props.phoneError}
                setPhoneError={props.setPhoneError}
            />
            <FormLabel style={labelStyle}>Mot de passe</FormLabel>
            <PasswordInput
                placeholder={yupLabels.password}
                value={props.formikProps.values.password}
                onChangeText={props.formikProps.handleChange('password')}
                onBlur={() => props.formikProps.setFieldTouched('password')}
                errorMessage={props.formikProps.errors.password || ' '}
                onSubmitEditing={() => props.formikProps.handleSubmit()}
                autoCompleteType="off"
                style={styles.input}
                inputStyle={styles.input}
            />
            <FormLabel style={labelStyle}>Confirmation du mot de passe</FormLabel>
            <PasswordInput
                placeholder={yupLabels.passwordConfirm}
                value={props.formikProps.values.passwordConfirm}
                onChangeText={props.formikProps.handleChange('passwordConfirm')}
                onBlur={() => props.formikProps.setFieldTouched('passwordConfirm')}
                errorMessage={props.formikProps.errors.passwordConfirm || ' '}
                onSubmitEditing={() => props.formikProps.handleSubmit()}
                style={styles.input}
                inputStyle={styles.input}
            />
            <View style={styles.checkboxContainer}>
                <CheckBox
                    checked={props.formikProps.values.newsletter}
                    onPress={onPressNewsletter}
                    style={styles.checkbox}
                    onBlur={() => props.formikProps.setFieldTouched('newsletter')}
                    containerStyle={{
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        marginTop: 14,
                        padding: 0,
                    }}
                    wrapperStyle={{backgroundColor: 'transparent'}}
                />
                <SimpleText style={[styles.rememberLabel, rememberLabel]}>
                    Je souhaite recevoir l'actualité ésotérique (horoscope, guidances, etc) et
                    les offres exceptionnelles de Kyvoitou.fr
                </SimpleText>
            </View>
            <View style={styles.checkboxContainer}>
                <CheckBox
                    checked={props.formikProps.values.cgv}
                    onPress={onPressCgv}
                    style={styles.checkbox}
                    onBlur={() => props.formikProps.setFieldTouched('cgv')}
                    containerStyle={{
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        marginTop: 14,
                        padding: 0,
                    }}
                    wrapperStyle={{backgroundColor: 'transparent'}}
                />
                <SimpleText style={styles.rememberLabel}>
                    J'accepte sans réserve les{' '}
                    <Pressable onPress={showCgu}>
                        <SimpleText style={{textDecorationLine: 'underline'}}>
                            conditions générales d'utilisation du service de Kyvoitou.fr
                        </SimpleText>
                    </Pressable>
                    <CustomModal
                        visible={cguVisible}
                        iconName={'balance-scale'}
                        title={"Conditions générales d'utilisation"}
                        style={styles.cguModal}
                        onRequestClose={hideCgu}
                    >
                        <CguText />
                    </CustomModal>
                </SimpleText>
            </View>
            <Text style={{color: Colors.errors, fontSize: 12, marginLeft: 6}}>
                {props.formikProps.errors.cgv || ' '}
            </Text>
            <Button
                title="Je m'inscris"
                buttonStyle={{
                    width: 150,
                    alignSelf: 'center',
                    marginTop: props.landingPageVersion ? 10 : 30,
                    backgroundColor: props.landingPageVersion ? Colors.cta : Colors.primary,
                }}
                style={props.landingPageVersion ? {margin: 0} : {}}
                disabled={!props.formikProps.isValid || props.phoneError != ''}
                onPress={() => props.formikProps.handleSubmit()}
                loading={props.formikProps.isSubmitting}
            />
        </View>
    );
}

type ItemPressableProps = {
    setSelected: (item: GenderEnum) => void;
    text: string;
    value: GenderEnum;
    selected: boolean;
};

function ItemPressable(props: ItemPressableProps) {
    function onPress() {
        props.setSelected(props.value);
    }

    const icon = props.selected ? 'check-circle' : 'circle';
    const background = props.selected
        ? {backgroundColor: Colors.secondary}
        : {backgroundColor: Colors.dark};
    const text = props.selected ? {color: 'white'} : {color: Colors.secondary};
    const url = props.selected
        ? Settings.getUrlPictoSelected()
        : Settings.getUrlPictoUnselected();

    return (
        <TouchableOpacity onPress={onPress} style={{height: 40}}>
            <View style={[styles.item, background]}>
                <SimpleText style={text}>{props.text}</SimpleText>
                <Image
                    style={{height: 12, width: 12, marginLeft: 10, marginTop: 3}}
                    source={{uri: url}}
                    accessibilityLabel={props.text}
                />
            </View>
        </TouchableOpacity>
    );
}

type Inputs = Pick<
    YupTypes,
    'identifiant' | 'email' | 'password' | 'passwordConfirm' | 'cgv' | 'newsletter' | 'phone'
>;
type Helpers = FormikHelpers<Inputs>;
type RegisterInputsProps = {
    formikProps: FormikProps<Inputs>;
    selectedGender: string | null;
    setSelectedGender: (elt: GenderEnum) => void;
    phone: string;
    setPhone: (phone: string) => void;
    phoneError: string;
    setPhoneError: (phone: string) => void;
    genderError: string;
    day: string;
    setDay: (day: string) => void;
    month: string;
    setMonth: (month: string) => void;
    year: string;
    setYear: (year: string) => void;
    landingPageVersion?: boolean;
};

const CREATE_USER = gql`
    mutation createUser(
        $authToken: String!
        $displayName: String!
        $email: String!
        $phone: String!
        $gender: String!
        $birthdate: String!
        $registrationUrl: String!
    ) {
        createUser(
            input: {
                authToken: $authToken
                phone: $phone
                email: $email
                gender: $gender
                birthdate: $birthdate
                displayName: $displayName
                registrationUrl: $registrationUrl
            }
        ) {
            user {
                id
            }
        }
    }
`;

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        marginHorizontal: 10,
        alignContent: 'space-around',
    },
    checkboxContainer: {
        flexDirection: 'row',
    },
    checkbox: {
        backgroundColor: 'transparent',
    },
    rememberLabel: {
        fontSize: 14,
        marginTop: 17,
    },
    forgotContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    radioLabel: {
        fontSize: 14,
        alignSelf: 'flex-start',
        borderWidth: 0,
    },
    genders: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: 10,
    },
    dateContainer: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: 10,
    },
    item: {
        borderRadius: 5,
        width: 100,
        flex: 1,
        flexDirection: 'row',
        padding: 5,
        marginRight: 15,
        marginTop: 10,
    },
    input: {},
    cguModal: {
        maxWidth: 600,
    },
});
