import {useContext} from 'react';
import {View} from 'react-native';

import {UserContext} from '~/contexts/UserContext';
import {QueryUtm} from '~/types';

import {ViewProps} from '../common/Containers';
import RegisterForm from '../user/RegisterForm';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';

export type RegisterViewProps = ViewProps & {
    stepIndex: number;
    query: QueryUtm;
    onRegister: () => void;
};

export default function RegisterView({query, onRegister, ...viewProps}: RegisterViewProps) {
    const {me} = useContext(UserContext);
    const {isAuthStep, isPaymentStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const displayed = isAuthStep || (pageVersion == 2 && !isPaymentStep && me == null);

    if (!displayed) {
        return null;
    }

    return (
        <>
            <StepTitle selected={isAuthStep}>
                {viewProps.stepIndex}/ Créez votre compte
            </StepTitle>
            <View {...viewProps}>
                <RegisterForm
                    landingPageVersion
                    query={query}
                    overrideOnRegister={onRegister}
                />
            </View>
        </>
    );
}
