import AsyncStorage from '@react-native-async-storage/async-storage';

const IDENTIFICATION_KEY = 'IDENTIFICATION';

export async function getIdentification() {
    const value = await AsyncStorage.getItem(IDENTIFICATION_KEY);

    if (value !== null) {
        return value;
    } else {
        const item = generateGuid();
        AsyncStorage.setItem(IDENTIFICATION_KEY, item);
        return item;
    }
}

function generateGuid() {
    var result, i, j;
    result = '';
    for (j = 0; j < 32; j++) {
        if (j == 8 || j == 12 || j == 16 || j == 20) result = result + '-';
        i = Math.floor(Math.random() * 16)
            .toString(16)
            .toUpperCase();
        result = result + i;
    }
    return result;
}
