import PackList, {PackListProps} from './PackList';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';

export default function PickPackView(props: PackListProps) {
    const {isPackSelectionStep} = useUrlStepContext();
    if (!isPackSelectionStep) {
        return null;
    }
    return (
        <>
            <StepTitle selected>2/ Choisissez votre forfait</StepTitle>
            <PackList onPress={props.onPress} />
        </>
    );
}
