import * as React from 'react';
import {StyleSheet} from 'react-native';

import {RouteProp, useRoute} from '@react-navigation/native';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import RegisterForm from '~/components/user/RegisterForm';
import * as Colors from '~/constants/Colors';
import {HomeParamList, QueryUtm} from '~/types';

export default function RegisterScreen() {
    const route = useRoute<RouteProp<HomeParamList, 'RegisterScreen'>>();

    // This assigment looks stupid but it doesn't work otherwise
    const source = route.params?.utm_source;
    const medium = route.params?.utm_medium;
    const campaign = route.params?.utm_campaign;

    // Don't touch, params need to be assign before creating object
    const query = {
        utm_source: source,
        utm_medium: medium,
        utm_campaign: campaign,
    } as QueryUtm;

    return (
        <MainView style={styles.container}>
            <SimpleText style={styles.loginTitle}>S'inscrire sur Kyvoitou.fr</SimpleText>
            <RegisterForm query={query} />
        </MainView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    loginTitle: {
        color: Colors.secondary,
        fontSize: 30,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 40,
    },
});
