import {CommunicationType} from '~/components/user/client/review/types/calls';
import Settings from '~/constants/Settings';
import logEvent from '~/helpers/analytics';

import {axios} from '../network';

export type WaitListEventData = {
    code: string;
    message: string;
    position: number;
    communicationType: CommunicationType;
    communicationId: string;
};

const openedWebSockets = new Map<string, WebSocket>();

export async function waitingList(params: WaitingListParamsClient) {
    let position = params.position;

    if (!position) {
        const body = JSON.stringify({
            expert_id: params.expertId,
            communication_type: params.communicationType,
        });
        const response = await axios.post(Settings.waitingList(), body);
        const data = await response.data;
        position = data.position;
    }

    if (position && position != -1) {
        subscribeClient(params);
        return position;
    }

    return null;
}

export async function checkWaitingListClient(communicationType: CommunicationType) {
    const body = JSON.stringify({communication_type: communicationType});
    const response = await axios.post(Settings.checkWaitingListClient(), body);
    return response.data;
}

async function subscribeClient(params: WaitingListParamsClient) {
    if (params.clientId && params.expertId) {
        const uri = Settings.waitingListSocketClient(params.clientId, params.expertId);
        const socketKey = Settings.waitingListSocketKeyClient(
            params.clientId,
            params.expertId
        );
        const ws = new WebSocket(uri);
        openedWebSockets.set(socketKey, ws);
        ws.onmessage = async function (event) {
            const message = event.data;
            params.onWaitListMessage &&
                params.onWaitListMessage(message, params.clientId, params.expertId);
        };
        ws.onclose = function (event) {
            openedWebSockets.delete(socketKey);
            params.onCloseWaitingList && params.onCloseWaitingList();
        };
    }
}

export async function exitWaitingList(
    expertId: string | null | undefined,
    communicationType: CommunicationType,
    requestedByClient: boolean = false
) {
    const body = JSON.stringify({
        expert_id: expertId,
        communication_type: communicationType,
        requested_by_client: requestedByClient,
    });
    await axios.post(Settings.exitWaitingList(), body);

    closeWaitingList();
}

export async function closeWaitingList() {
    for (const ws of openedWebSockets.values()) {
        ws.close();
    }
    openedWebSockets.clear();
    logEvent('waiting_list_exited');
}

export type WaitingListParamsClient = {
    clientId: string | null | undefined;
    expertId: string | null | undefined;
    communicationType: CommunicationType;
    onWaitListMessage?: (
        message: string,
        clientId: string | null | undefined,
        expertId: string | null | undefined
    ) => void;
    onCloseWaitingList?: () => void;
    position?: number;
};
