import React, {useState, useEffect, useRef} from 'react';
import {
    View,
    StyleSheet,
    FlatList,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {colors, Input} from 'react-native-elements';
import {ScrollView, TouchableOpacity} from 'react-native-gesture-handler';

import * as Icons from '~/components/common/Icons';
import {ProfilePicture, PicturesViewer} from '~/components/common/Images';
import {ChatInput} from '~/components/common/Inputs';
import {MinimizablePopup} from '~/components/common/Modal';
import {Separator} from '~/components/common/Separator';
import {SimpleText} from '~/components/common/Texts';
import PopupWriteReview from '~/components/user/client/review/PopupWriteReview';
import type {ReviewData} from '~/components/user/client/review/types/calls';
import {CommunicationType} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {MIN_DURATION_FOR_REVIEW} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {useConfirm} from '~/contexts/ConfirmContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {ChatExchange} from '~/helpers/chat';
import * as dates from '~/helpers/dates';
import {getBenefit, getCurrentMonthBenefit} from '~/helpers/experts';
import {ExchangeTypeEnum} from '~/helpers/message';
import yup, {yupRules, YupTypes} from '~/helpers/yup';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import Button from '../../common/Buttons';
import PopupStatistics from '../client/statistics/PopupStatistics';
import ChatAttachments from './ChatAttachments';
import {ChatProfile, ChatStatusEnum, TIMEOUT_WRITING} from './ChatStatus';
import {endChat} from './types/endChat';

type ChatProps = {
    visible: boolean;
    isReduced: boolean;
    setIsReduced: (isReduced: boolean) => void;
    onRequestClose: () => void;
    client: ChatProfile | null;
    expert: ChatProfile | null;
    type: ExchangeTypeEnum | null;
    status: ChatStatusEnum | null;
    chatId: string | null;
    warning: number | null;
    pack: boolean;
    duration: number | null;
    exchanges: ChatExchange[];
    cost: number | null;
    isWriting: boolean | null;
    hasNewMessage: boolean | null;
    offer: string | undefined;
    onRead: () => void;
    onError: () => void;
    error: boolean;
    chatRefetch: () => void;
    onChatEnd: (cost: number, usePack: boolean) => void;
};

type Inputs = Pick<YupTypes, 'chat'>;
type Helpers = FormikHelpers<Inputs>;

const START_CHAT = gql`
    mutation startChat($chatId: String!) {
        startChat(input: {chatId: $chatId}) {
            chat {
                chatId
            }
        }
    }
`;

const WRITING_CHAT = gql`
    mutation writingChat($chatId: String!, $exchangeType: String!) {
        writingChat(input: {chatId: $chatId, exchangeType: $exchangeType}) {
            chat {
                chatId
            }
        }
    }
`;

const SEND_CHAT = gql`
    mutation sendChat(
        $chatId: String!
        $exchangeType: String!
        $content: String!
        $attachments: [String]
    ) {
        sendChat(
            input: {
                chatId: $chatId
                exchangeType: $exchangeType
                content: $content
                attachments: $attachments
            }
        ) {
            chat {
                chatId
            }
        }
    }
`;

const END_CHAT = gql`
    mutation endChat($chatId: String!) {
        endChat(input: {chatId: $chatId}) {
            chat {
                chatId
                cost
                usePack
            }
            code
        }
    }
`;

export default function Chat(props: ChatProps) {
    const [endChat, {data: exitData, error: exitError}] = useMutation<endChat>(END_CHAT);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isReviewVisible, setIsReviewVisible] = useState(false);
    const [benefit, setBenefit] = useState<string | null>(null);
    const {isDesktop} = useDeviceQuery();
    const alert = useAlert();
    const [attachments, setAttachments] = useState<string[]>([]);

    useEffect(() => {
        if (exitData) {
            console.log('exitData = ', exitData);
        }
        setIsLoading(false);
        if (exitData?.endChat?.chat) {
            props.onChatEnd(
                parseInt(exitData.endChat.chat.cost?.toString() ?? '0'),
                exitData.endChat.chat.usePack ?? false
            );
        } else if (exitData?.endChat?.code == 'ALREADY_ENDED') {
            props.onRequestClose();
            alert({
                message:
                    "Votre communication s'est terminé correctement. Vous pouvez retrouver les détails dans votre historique.",
            });
        }
    }, [exitData]);

    useEffect(() => {
        if (exitError) {
            console.log('exitError = ', exitError?.message);
            setIsLoading(false);
            props.onError();
        }
    }, [exitError]);

    useEffect(() => {
        async function updateBenefit() {
            const result = await getCurrentMonthBenefit();
            setBenefit(result);
        }

        if (
            props.status == ChatStatusEnum.ENDED &&
            props.type == ExchangeTypeEnum.EXPERT_TO_CLIENT
        ) {
            updateBenefit();
        }
    }, [props.status]);

    async function stopChat() {
        const mutationData = {
            chatId: props.chatId,
        };

        setIsLoading(true);
        try {
            endChat({variables: mutationData});
        } catch (e) {
            console.log('stopChat::catch ', e);
        }
        props.chatRefetch();
        logEvent('chat_stoped');
    }

    async function exitChat() {
        const cost = props.cost ?? 0;
        const offer = props.offer != undefined;
        const canReview =
            props.duration &&
            props.duration > MIN_DURATION_FOR_REVIEW &&
            (cost > 0 || offer || props.pack);
        if (props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT && canReview) {
            reviewChat();
        } else {
            props.onRequestClose();
        }
    }

    async function reviewChat() {
        setIsReviewVisible(true);
    }

    async function closeReviewAndChat() {
        setIsReviewVisible(false);
        props.onRequestClose();
    }

    function minimize() {
        props.setIsReduced(!props.isReduced);
        props.onRead();
    }

    function maximize() {
        props.setIsReduced(false);
    }

    function getProfile() {
        if (props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT) {
            return props.expert;
        } else if (props.type == ExchangeTypeEnum.EXPERT_TO_CLIENT) {
            return props.client;
        }
    }

    const profile = getProfile();

    const name = profile?.name;
    const picture = profile?.picture;

    const popupStyle = props.isReduced
        ? styles.reduced
        : {borderRadius: 0, padding: 0, backgroundColor: 'transparent'};

    const reviewData: ReviewData = {
        expertId: props.expert?.id,
        date: new Date(),
        communicationId: 'Chat:' + props.chatId,
        communicationType: CommunicationType.CHAT,
        clientMode: true,
    };

    return (
        <MinimizablePopup
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            onBackgroundClick={minimize}
            reduced={props.isReduced}
            style={popupStyle}
        >
            <ChatHeader
                status={props.status}
                isReduced={props.isReduced}
                setIsReduced={props.setIsReduced}
                picture={picture}
                name={name}
                duration={props.duration}
                offer={props.offer}
                type={props.type}
                warning={props.warning}
                pack={props.pack}
                hasNewMessage={props.hasNewMessage}
                minimize={minimize}
            />
            <View style={{padding: isDesktop ? 20 : 0, flex: props.isReduced ? 0 : 1}}>
                <ChatExchanges
                    isReduced={props.isReduced}
                    type={props.type}
                    status={props.status}
                    minimize={minimize}
                    maximize={maximize}
                    chatId={props.chatId}
                    name={name}
                    client={props.client}
                    expert={props.expert}
                    isWriting={props.isWriting}
                    exchanges={props.exchanges}
                    onError={props.onError}
                    error={props.error}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    chatRefetch={props.chatRefetch}
                />
                <WaitingForExpert
                    isReduced={props.isReduced}
                    type={props.type}
                    status={props.status}
                />
                <Start
                    isReduced={props.isReduced}
                    type={props.type}
                    status={props.status}
                    chatId={props.chatId}
                    chatRefetch={props.chatRefetch}
                />
                <ChatEnd
                    pack={props.pack}
                    isReduced={props.isReduced}
                    cost={props.cost}
                    duration={props.duration}
                    status={props.status}
                    type={props.type}
                    benefit={benefit}
                />
                <ChatButtons
                    isReduced={props.isReduced}
                    stopChat={stopChat}
                    isLoading={isLoading}
                    exitChat={exitChat}
                    status={props.status}
                    type={props.type}
                    client={props.client}
                    isReviewVisible={isReviewVisible}
                    closeReviewAndChat={closeReviewAndChat}
                    reviewData={reviewData}
                    minimize={minimize}
                    maximize={maximize}
                />
            </View>
        </MinimizablePopup>
    );
}

type StartProps = {
    chatId: string | null;
    isReduced: boolean;
    type: ExchangeTypeEnum | null;
    status: ChatStatusEnum | null;
    chatRefetch: () => void;
};

function Start(props: StartProps) {
    const [startChat, {data, error: startChatError}] = useMutation(START_CHAT);

    useEffect(() => {
        if (startChatError) {
            console.log('startChatError = ', startChatError?.message);
        }
    }, [startChatError]);

    async function onStartChat() {
        const mutationData = {
            chatId: props.chatId,
        };

        try {
            const result = await startChat({variables: mutationData});
        } catch (e) {
            console.log('onStartChat::catch ', e);
        }
        logEvent('chat_accepted');
        props.chatRefetch();
    }

    return (
        <>
            {!props.isReduced &&
                props.status == ChatStatusEnum.INITIALIZED &&
                props.type == ExchangeTypeEnum.EXPERT_TO_CLIENT && (
                    <>
                        <View style={styles.buttonContainer}>
                            <Button
                                icon={<Icons.Chat size={25} style={{paddingRight: 15}} />}
                                title="Commencer"
                                buttonStyle={{alignSelf: 'center', paddingHorizontal: 10}}
                                onPress={onStartChat}
                            />
                        </View>
                        <View
                            style={{
                                paddingTop: 20,
                                paddingHorizontal: 10,
                                flexDirection: 'row',
                            }}
                        >
                            <Icons.Info size={20} color={Colors.secondary} />
                            <SimpleText
                                style={{
                                    textAlign: 'center',
                                    marginLeft: 15,
                                    alignSelf: 'center',
                                }}
                            >
                                Par mesure de précaution, Kyvoitou.fr vous appelle sur votre
                                mobile afin de vous avertir des demandes de Tchat.
                            </SimpleText>
                        </View>
                    </>
                )}
        </>
    );
}

type WaitingForExpertProps = {
    isReduced: boolean;
    type: ExchangeTypeEnum | null;
    status: ChatStatusEnum | null;
};

function WaitingForExpert(props: WaitingForExpertProps) {
    return (
        <>
            {!props.isReduced &&
                props.status == ChatStatusEnum.INITIALIZED &&
                props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT && (
                    <View style={styles.waitContainer}>
                        <SimpleText style={{fontWeight: 'bold'}}>
                            En attente du spirite
                        </SimpleText>
                        <SimpleText style={{marginTop: 10}}>
                            Nous contactons votre médium.
                        </SimpleText>
                        <SimpleText>
                            Merci d'attendre quelques minutes le temps que celui-ci prenne la
                            communication.
                        </SimpleText>
                        <SimpleText style={{fontStyle: 'italic'}}>
                            L'équipe Kyvoitou.fr
                        </SimpleText>
                    </View>
                )}
        </>
    );
}

type ChatExchangesProps = {
    isReduced: boolean;
    type: ExchangeTypeEnum | null;
    status: ChatStatusEnum | null;
    minimize: () => void;
    maximize: () => void;
    chatId: string | null;
    name: string | undefined;
    client: ChatProfile | null;
    expert: ChatProfile | null;
    isWriting: boolean | null;
    exchanges: ChatExchange[];
    onError: () => void;
    error: boolean;
    attachments: string[];
    setAttachments: (attachments: string[]) => void;
    chatRefetch: () => void;
};

function ChatExchanges(props: ChatExchangesProps) {
    const {isDesktop} = useDeviceQuery();
    const [writingChat, {data: writeData, error: writeError}] = useMutation(WRITING_CHAT);
    const [sendChat, {data: sendData, error: sendError}] = useMutation(SEND_CHAT);
    const [currentlyWriting, setCurrentlyWriting] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(500);
    const scrollViewRef = useRef<ScrollView>(null);
    const inputRef = useRef<Input>(null);

    useEffect(() => {
        scrollViewRef.current?.scrollTo({y: height, animated: false});
        inputRef.current?.focus();
    }, [height]);

    useEffect(() => {
        if (sendError) {
            props.onError();
            console.log('sendError = ', sendError?.message);
        }
    }, [sendError]);

    useEffect(() => {
        if (writeError) {
            props.onError();
            console.log('writeError = ', writeError?.message);
        }
    }, [writeError]);

    const deviceStyles = isDesktop ? styles : mobileStyles;

    const {chat} = yupRules;
    const validationSchema = yup.object().shape({chat});

    async function submitChat(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        const mutationData = {
            chatId: props.chatId,
            content: values.chat,
            attachments: props.attachments,
            exchangeType: props.type,
        };

        try {
            const result = await sendChat({variables: mutationData});
        } catch (e) {
            console.log('submitChat::catch ', e);
        }
        props.chatRefetch();

        resetForm();
        props.setAttachments([]);
    }

    function getExchangeProfile(exchange: ChatExchange) {
        if (exchange.exchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT) {
            return props.client;
        } else if (exchange.exchangeType == ExchangeTypeEnum.EXPERT_TO_CLIENT) {
            return props.expert;
        }
    }

    async function onWriting() {
        if (!currentlyWriting) {
            setCurrentlyWriting(true);

            const mutationData = {
                chatId: props.chatId,
                exchangeType: props.type,
            };

            try {
                writingChat({variables: mutationData});

                const timeout = setTimeout(() => {
                    setCurrentlyWriting(false);
                }, TIMEOUT_WRITING);
            } catch (e) {
                console.log('onWriting::catch ', e);
            }
        }
    }

    function getStyle(exchange: ChatExchange) {
        if (props.type == exchange.exchangeType) {
            return styles.fromMe;
        } else {
            return styles.toMe;
        }
    }

    function getBackgroundStyle(exchange: ChatExchange) {
        if (props.type == exchange.exchangeType) {
            return styles.fromMeBackground;
        } else {
            return styles.toMeBackground;
        }
    }

    function renderExchange({item}: {item: ChatExchange}) {
        const exchange: ChatExchange = item;
        const exchangeTypeStyle = getStyle(exchange);
        const exchangeTypeBackgroundStyle = getBackgroundStyle(exchange);
        const date = new Date(exchange.time);
        const profile = getExchangeProfile(exchange);
        const picture = profile?.picture;

        if (item.exchangeType == 'DATE') {
            return (
                <View style={styles.dateContainer}>
                    <SimpleText style={styles.dashed}></SimpleText>
                    <SimpleText style={styles.date}>{exchange.content}</SimpleText>
                    <SimpleText style={styles.dashed}></SimpleText>
                </View>
            );
        }

        return (
            <View style={[styles.lineContainer, exchangeTypeStyle]}>
                {exchange.exchangeType != props.type && (
                    <ProfilePicture style={styles.profilePicture} pictureName={picture} />
                )}
                <View style={styles.width}>
                    <View style={[styles.exchange, exchangeTypeBackgroundStyle]}>
                        <SimpleText style={styles.content}>{exchange.content}</SimpleText>
                        <PicturesViewer
                            pictureNames={exchange.attachments}
                            style={styles.attachementContainer}
                        />
                    </View>
                    <SimpleText style={[exchangeTypeStyle, styles.time]}>
                        {dates.timeString(date)}
                    </SimpleText>
                </View>
                {exchange.exchangeType == props.type && (
                    <ProfilePicture style={styles.profilePicture} pictureName={picture} />
                )}
            </View>
        );
    }

    function onKeyPress(
        event: NativeSyntheticEvent<TextInputKeyPressEventData>,
        formikProps: FormikProps<Inputs>
    ) {
        if (event.nativeEvent.key == 'Enter') {
            formikProps.handleSubmit();
            setImmediate(() => inputRef.current?.focus());
        }
    }

    return (
        <>
            {!props.isReduced &&
                (props.status == ChatStatusEnum.STARTED ||
                    props.status == ChatStatusEnum.ENDED) && (
                    <>
                        <ScrollView
                            ref={scrollViewRef}
                            style={deviceStyles.messages}
                            onContentSizeChange={(contentWidth, contentHeight) => {
                                setHeight(contentHeight);
                            }}
                        >
                            {props.exchanges.length == 0 && (
                                <SimpleText style={styles.noMessage}>
                                    Il n'y a pas encore de message : soyez le premier à écrire
                                    un message !
                                </SimpleText>
                            )}
                            <FlatList
                                data={props.exchanges}
                                renderItem={renderExchange}
                                keyExtractor={(item: ChatExchange) => item.exchangeId}
                                style={styles.exchangeContainer}
                            />
                        </ScrollView>
                        {props.status == ChatStatusEnum.ENDED && (
                            <View style={styles.end}>
                                <Separator style={deviceStyles.separator} />
                                <SimpleText>Fin du tchat</SimpleText>
                                <Separator style={deviceStyles.separator} />
                            </View>
                        )}
                        {!props.error && props.status == ChatStatusEnum.STARTED && (
                            <View style={deviceStyles.writingContainer}>
                                {props.isWriting && (
                                    <SimpleText style={styles.writing}>
                                        {props.name} est en train d'écrire
                                    </SimpleText>
                                )}
                            </View>
                        )}
                        {props.error && (
                            <View style={deviceStyles.errorContainer}>
                                <SimpleText style={deviceStyles.error}>
                                    Nous constatons une erreur de connexion : tentative de
                                    reconnexion en cours...
                                </SimpleText>
                            </View>
                        )}
                        {props.status == ChatStatusEnum.STARTED && (
                            <View style={deviceStyles.formContainer}>
                                <Formik
                                    initialValues={{
                                        chat: '',
                                    }}
                                    onSubmit={submitChat}
                                    validationSchema={validationSchema}
                                    validateOnBlur={false}
                                    validateOnChange={true}
                                    validateOnMount={false}
                                >
                                    {(formikProps) => (
                                        <View style={styles.inputContainer}>
                                            <ChatInput
                                                value={formikProps.values.chat}
                                                onChangeText={(e) => {
                                                    formikProps.handleChange('chat')(e);
                                                    onWriting();
                                                }}
                                                onBlur={() =>
                                                    formikProps.setFieldTouched('chat')
                                                }
                                                errorMessage={''}
                                                errorStyle={{height: 0}}
                                                style={styles.chatInput}
                                                inputContainerStyle={{
                                                    borderBottomWidth: 0,
                                                }}
                                                containerStyle={
                                                    isDesktop ? {width: 550} : {width: 200}
                                                }
                                                onKeyPress={(event) =>
                                                    onKeyPress(event, formikProps)
                                                }
                                                autoFocus={true}
                                                ref={inputRef}
                                            />
                                            <Button
                                                icon={<Icons.MailSend size={20} />}
                                                title=""
                                                buttonStyle={{
                                                    width: 43,
                                                    borderRadius: 25,
                                                    alignSelf: 'center',
                                                    padding: 11,
                                                }}
                                                disabled={
                                                    !formikProps.isValid ||
                                                    formikProps.values.chat.length == 0 // TODO faire ça en propre?
                                                }
                                                onPress={() => formikProps.handleSubmit()}
                                                loading={formikProps.isSubmitting}
                                                containerStyle={{
                                                    backgroundColor: 'white',
                                                    borderRadius: 0,
                                                    marginTop: 0,
                                                }}
                                                style={{marginTop: 0}}
                                            />
                                            <ChatAttachments
                                                setAttachments={props.setAttachments}
                                                attachments={props.attachments}
                                                onDisplayModalError={props.minimize}
                                                onCloseModalError={props.maximize}
                                            />
                                        </View>
                                    )}
                                </Formik>
                            </View>
                        )}
                    </>
                )}
        </>
    );
}

type ChatHeaderProps = {
    status: ChatStatusEnum | null;
    isReduced: boolean;
    setIsReduced: (isReduced: boolean) => void;
    picture: string | undefined;
    name: string | undefined;
    duration: number | null;
    offer: string | undefined;
    type: ExchangeTypeEnum | null;
    warning: number | null;
    pack: boolean;
    hasNewMessage: boolean | null;
    minimize: () => void;
};

function ChatHeader(props: ChatHeaderProps) {
    const {isDesktop} = useDeviceQuery();

    const iconSize = isDesktop ? 40 : 20;
    const text = isDesktop ? `Vous êtes en tchat avec ${props.name}` : props.name;
    const profilPictureStyle = isDesktop ? styles.profilePicture : mobileStyles.profilePicture;
    const containerStyle = isDesktop
        ? styles.container
        : props.isReduced
        ? mobileStyles.containerReduced
        : mobileStyles.container;
    const buttonStyle = props.isReduced ? styles.maximize : styles.minimize;

    return (
        <View style={containerStyle}>
            <View style={styles.header}>
                {isDesktop && <Icons.Chat size={iconSize} />}
                {!isDesktop && (
                    <TouchableOpacity
                        onPress={() => props.setIsReduced(!props.isReduced)}
                        containerStyle={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {!props.isReduced && (
                            <Icons.SortUp
                                size={14}
                                color={'white'}
                                style={{marginRight: 10, marginTop: 5}}
                            />
                        )}
                        {props.isReduced && (
                            <Icons.SortDown
                                size={14}
                                color={'white'}
                                style={{marginRight: 10, marginTop: -5}}
                            />
                        )}
                    </TouchableOpacity>
                )}
                {(isDesktop || !props.isReduced) && (
                    <ProfilePicture style={profilPictureStyle} pictureName={props.picture} />
                )}
                <View>
                    {(isDesktop || !props.isReduced) && (
                        <SimpleText style={styles.title}>{text}</SimpleText>
                    )}
                    <ChatDurationText duration={props.duration} />
                    {isDesktop && props.offer && (
                        <SimpleText style={styles.offer}>
                            <Icons.Info
                                size={16}
                                style={{marginRight: 5, textAlignVertical: 'bottom'}}
                                color={'white'}
                            />
                            {props.offer}
                        </SimpleText>
                    )}
                    {isDesktop &&
                        props.pack &&
                        props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT && (
                            <ChatMinuteText duration={props.duration} status={props.status} />
                        )}
                </View>
            </View>
            {isDesktop && props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT && (
                <Warning warning={props.warning} duration={props.duration} />
            )}
            {isDesktop && (
                <View style={styles.minimizeContainer}>
                    <TouchableOpacity
                        onPress={props.minimize}
                        style={styles.minimizeContainer}
                    >
                        {props.isReduced && props.hasNewMessage && (
                            <View style={styles.newMessage}>
                                <SimpleText style={{color: 'white'}}>
                                    Nouveau(x) message(s)
                                </SimpleText>
                            </View>
                        )}
                        <SimpleText style={buttonStyle}>
                            Passer en mode {props.isReduced ? 'agrandit' : 'réduit'}
                        </SimpleText>
                        {!props.isReduced && <Icons.SortUp size={20} />}
                        {props.isReduced && <Icons.SortDown size={20} />}
                    </TouchableOpacity>
                </View>
            )}
        </View>
    );
}

type ChatEndProps = {
    isReduced: boolean;
    pack: boolean;
    cost: number | null;
    duration: number | null;
    status: ChatStatusEnum | null;
    type: ExchangeTypeEnum | null;
    benefit: string | null;
};

function ChatEnd(props: ChatEndProps) {
    const {minutes, refetchMinutes} = React.useContext(UserContext);

    useEffect(() => {
        if (props.status == ChatStatusEnum.ENDED) {
            refetchMinutes();
        }
    }, [props.status]);

    const solde = minutes?.getMinuteBalance?.minutes ?? 0;

    const cost = props.cost ?? 0;
    const hasCost = cost > 0;

    return (
        <>
            {!props.isReduced && props.status == ChatStatusEnum.ENDED && (
                <View style={styles.textContainer}>
                    <SimpleText style={{fontWeight: 'bold'}}>
                        Récapitulatif de votre consultation
                    </SimpleText>
                    {props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT && props.pack && (
                        <SimpleText>Solde restant : {solde} min.</SimpleText>
                    )}
                    {hasCost &&
                        !props.pack &&
                        props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT && (
                            <SimpleText>Coût : {props.cost}€</SimpleText>
                        )}
                    {!hasCost && props.type == ExchangeTypeEnum.CLIENT_TO_EXPERT && (
                        <SimpleText> - </SimpleText>
                    )}
                    {hasCost && props.type == ExchangeTypeEnum.EXPERT_TO_CLIENT && (
                        <SimpleText>
                            Gain :{' '}
                            {getBenefit(
                                props.cost,
                                props.pack,
                                props.duration,
                                CommunicationType.CHAT
                            ).toFixed(2)}
                            €
                        </SimpleText>
                    )}
                    {props.type == ExchangeTypeEnum.EXPERT_TO_CLIENT && props.benefit && (
                        <SimpleText>Solde : {props.benefit}€</SimpleText>
                    )}
                </View>
            )}
        </>
    );
}

type ChatButtonsProps = {
    isReduced: boolean;
    stopChat: () => void;
    isLoading: boolean;
    exitChat: () => void;
    status: ChatStatusEnum | null;
    type: ExchangeTypeEnum | null;
    client: ChatProfile | null;
    isReviewVisible: boolean;
    closeReviewAndChat: () => void;
    reviewData: ReviewData;
    minimize: () => void;
    maximize: () => void;
};

function ChatButtons(props: ChatButtonsProps) {
    const {isDesktop} = useDeviceQuery();
    const confirm = useConfirm();

    function stopChat() {
        props.minimize();
        confirm({
            title: 'Attention',
            message: 'Êtes-vous sur de vouloir terminer ce tchat ?',
            yesText: 'Terminer',
            noText: 'Annuler',
            onYes: () => {
                props.stopChat();
                props.maximize();
            },
            onNo: () => {
                props.maximize();
            },
        });
    }

    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <>
            {!props.isReduced && (
                <>
                    <View
                        style={
                            isDesktop ? styles.buttonContainer : mobileStyles.buttonContainer
                        }
                    >
                        <View
                            style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}
                        >
                            <Button
                                icon={
                                    <Icons.Stop
                                        size={25}
                                        style={{paddingRight: 10, paddingTop: 2}}
                                    />
                                }
                                title="Terminer ce tchat"
                                onPress={stopChat}
                                containerStyle={{backgroundColor: 'white'}}
                                loading={props.isLoading}
                                disabled={props.status == ChatStatusEnum.ENDED}
                            />
                            <Button
                                title="Fermer"
                                onPress={props.exitChat}
                                containerStyle={{backgroundColor: 'white'}}
                                disabled={props.status != ChatStatusEnum.ENDED}
                            />
                        </View>
                        <PopupWriteReview
                            visible={props.isReviewVisible}
                            onRequestClose={props.closeReviewAndChat}
                            reviewData={props.reviewData}
                            onSuccess={props.closeReviewAndChat}
                        />
                        <Statistic type={props.type} client={props.client} />
                    </View>
                    <View style={deviceStyles.tips}>
                        <SimpleText style={styles.problem}>
                            Un problème avec le tchat ?{' '}
                        </SimpleText>
                        <TouchableOpacity onPress={() => location.reload()}>
                            <SimpleText style={styles.solution}>Recharger la page</SimpleText>
                        </TouchableOpacity>
                    </View>
                </>
            )}
        </>
    );
}

type StatisticProps = {
    type: ExchangeTypeEnum | null;
    client: ChatProfile | null;
};

function Statistic(props: StatisticProps) {
    const {isDesktop} = useDeviceQuery();
    const [statisticsClientId, setStatisticsClientId] = React.useState<string | null>(null);
    const [modalStatisticsVisible, setModalStatisticsVisible] = React.useState(false);

    function onStatisticsPress() {
        if (props.client?.id) {
            setStatisticsClientId(props.client.id);
            setModalStatisticsVisible(true);
        }
    }

    function onRequestCloseStatistics() {
        setModalStatisticsVisible(false);
    }

    return (
        <>
            {isDesktop && props.type == ExchangeTypeEnum.EXPERT_TO_CLIENT && (
                <>
                    <TouchableOpacity style={styles.statistics} onPress={onStatisticsPress}>
                        <SimpleText style={styles.statisticsLabel}>
                            Voir mes statistiques avec ce client
                        </SimpleText>
                        <Icons.See
                            style={{
                                borderLeftColor: Colors.highlight,
                                borderLeftWidth: 1,
                                alignSelf: 'flex-end',
                                padding: 10,
                            }}
                            size={20}
                            color={Colors.highlight}
                        />
                    </TouchableOpacity>
                    <PopupStatistics
                        visible={modalStatisticsVisible}
                        onRequestClose={onRequestCloseStatistics}
                        clientId={statisticsClientId}
                    />
                </>
            )}
        </>
    );
}

type ChatDurationTextProps = {
    duration: number | null;
};

function ChatDurationText({duration}: ChatDurationTextProps) {
    if (!duration || duration < 0) return <SimpleText> </SimpleText>;

    const seconds = duration % 60;
    const minutes = Math.floor(duration / 60) % 60;
    const hours = Math.floor(duration / 3600);

    return (
        <SimpleText style={styles.duration}>
            Durée du tchat : {hours}h{minutes}m{seconds}s
        </SimpleText>
    );
}

type ChatMinuteTextProps = {
    duration: number | null;
    status: ChatStatusEnum | null;
};

function ChatMinuteText({duration, status}: ChatMinuteTextProps) {
    const {minutes} = React.useContext(UserContext);

    if (status == ChatStatusEnum.ENDED) {
        return <></>;
    }

    if (!duration || duration < 60) {
        return <></>;
    }

    const solde = minutes?.getMinuteBalance?.minutes ?? 0;

    if (solde == 0) {
        return <></>;
    }

    const chatDuration = Math.ceil(duration / 60);

    const left = solde - chatDuration;

    return <SimpleText style={styles.duration}>Solde restant : {left} min.</SimpleText>;
}

type WarningProps = {
    warning: number | null;
    duration: number | null;
};

function Warning(props: WarningProps) {
    const {play} = useAudio();

    useEffect(() => {
        async function playSound() {
            await play(require('../../../assets/sound/bell.mp3'));
        }

        if (props.duration && props.duration == props.warning && props.warning > 0) {
            playSound();
        }
    }, [props.duration]);

    if (props.warning && props.duration) {
        const limitReached = props.duration >= props.warning;

        if (limitReached) {
            return (
                <View style={{marginLeft: 'auto'}}>
                    <SimpleText style={styles.warning}>Limite atteinte</SimpleText>
                </View>
            );
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.connected,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    header: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
    },
    profilePicture: {
        borderRadius: 60,
        width: 45,
        height: 45,
        marginHorizontal: 20,
    },
    title: {
        fontSize: 16,
        color: 'white',
    },
    duration: {
        fontSize: 12,
        color: 'white',
    },
    minimizeContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    minimize: {
        fontSize: 12,
        color: 'white',
        paddingRight: 20,
    },
    maximize: {
        fontSize: 12,
        color: 'white',
        paddingRight: 20,
        paddingTop: 7,
    },
    writingContainer: {
        height: 20,
        marginVertical: 20,
        alignSelf: 'flex-start',
    },
    writing: {
        backgroundColor: Colors.colorOpacity(Colors.light, '50'),
        paddingVertical: 5,
        paddingHorizontal: 20,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    errorContainer: {
        height: 20,
        marginVertical: 20,
        alignSelf: 'flex-start',
    },
    error: {
        color: 'red',
        backgroundColor: 'white',
        paddingVertical: 5,
        paddingHorizontal: 20,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 20,
    },
    waitContainer: {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: 40,
    },
    textContainer: {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: 20,
    },
    exchangeContainer: {
        flex: 1,
    },
    exchange: {
        flex: 1,
        flexDirection: 'column',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        borderRadius: 5,
    },
    fromMe: {
        alignSelf: 'flex-end',
    },
    toMe: {
        alignSelf: 'flex-start',
    },
    fromMeBackground: {
        backgroundColor: Colors.colorOpacity(Colors.light, '50'),
        padding: 10,
    },
    toMeBackground: {
        backgroundColor: Colors.dark,
        padding: 10,
    },
    content: {
        fontSize: 14,
        wordBreak: 'break-word',
    },
    dateContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 25,
    },
    date: {
        fontSize: 12,
        alignSelf: 'center',
        padding: 5,
        borderRadius: 5,
    },
    dashed: {
        width: '35%',
        marginBottom: 10,
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'black',
    },
    messages: {
        backgroundColor: 'white',
        flexDirection: 'column',
        height: 300,
        padding: 5,
    },
    formContainer: {
        backgroundColor: 'white',
        paddingTop: 25,
        borderRadius: 10,
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        borderRadius: 10,
    },
    chatInput: {
        backgroundColor: 'white',
    },
    attachementContainer: {
        paddingTop: 10,
    },
    time: {
        fontSize: 10,
        backgroundColor: 'white',
        color: Colors.fade,
        paddingTop: 5,
    },
    lineContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 25,
    },
    reduced: {
        alignSelf: 'center',
        width: '100%',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        borderRadius: 0,
    },
    newMessage: {
        color: 'white',
        backgroundColor: Colors.highlight,
        borderRadius: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontSize: 14,
        height: 27,
        marginTop: 3,
        marginRight: 25,
    },
    noMessage: {
        fontStyle: 'italic',
        alignSelf: 'center',
        marginTop: 50,
    },
    warning: {
        color: 'white',
        backgroundColor: Colors.highlight,
        borderRadius: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontSize: 14,
        height: 27,
        marginRight: 25,
    },
    end: {
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'center',
        paddingBottom: 15,
    },
    separator: {
        backgroundColor: Colors.dark,
        marginVertical: 0,
        height: 2,
        alignSelf: 'center',
        width: 350,
        marginHorizontal: 10,
    },
    offer: {
        paddingTop: 3,
        fontSize: 12,
        flexDirection: 'row',
        color: 'white',
    },
    statistics: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.highlight,
        borderRadius: 7,
        margin: 15,
        alignSelf: 'flex-end',
    },
    statisticsLabel: {
        color: Colors.highlight,
        fontSize: 14,
        flex: 1,
        textAlign: 'center',
        padding: 10,
    },
    width: {
        flex: 1,
    },
    tips: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
    },
    problem: {
        color: Colors.bad,
        fontSize: 12,
    },
    solution: {
        color: Colors.bad,
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        fontSize: 12,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        backgroundColor: Colors.connected,
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    containerReduced: {
        backgroundColor: Colors.connected,
        paddingHorizontal: 10,
        paddingVertical: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    buttonContainer: {
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'center',
    },
    profilePicture: {
        borderRadius: 5,
        width: 40,
        height: 40,
        marginHorizontal: 10,
    },
    messages: {
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 5,
        flex: 1,
    },
    writingContainer: {
        height: 20,
        marginVertical: 5,
        alignSelf: 'flex-start',
    },
    errorContainer: {
        height: 30,
        marginVertical: 5,
        alignSelf: 'flex-start',
    },
    error: {
        color: 'red',
        backgroundColor: 'white',
        paddingHorizontal: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        fontSize: 12,
    },
    formContainer: {
        backgroundColor: 'white',
        paddingTop: 5,
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowRadius: 15,
        shadowOpacity: 1,
    },
    separator: {
        backgroundColor: Colors.dark,
        marginVertical: 0,
        height: 2,
        alignSelf: 'center',
        width: 100,
        marginHorizontal: 10,
    },
    tips: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 10,
    },
});
