import * as React from 'react';
import {GestureResponderEvent, View, ViewStyle} from 'react-native';

import {TouchableOpacity} from 'react-native-gesture-handler';

export default function HoverableView({
    hoverStyle,
    style,
    onPress,
    ...props
}: HoverableViewProps) {
    const baseStyle = style ?? {};
    const [currentStyle, setCurrentStyle] = React.useState<ViewStyle | ViewStyle[]>(baseStyle);

    const onHover = () => setCurrentStyle([baseStyle, hoverStyle]);
    const onHoverExit = () => setCurrentStyle(baseStyle);

    const passingProps = {
        onMouseEnter: onHover,
        onMouseLeave: onHoverExit,
        style: currentStyle,
        ...props,
    };
    if (onPress) {
        return <TouchableOpacity onPress={onPress} {...passingProps} />;
    } else {
        return <View {...passingProps} />;
    }
}

export function useHoverableProps() {
    const [isHovered, setIsHovered] = React.useState(false);
    const onMouseEnter = React.useCallback(() => setIsHovered(true), []);
    const onMouseLeave = React.useCallback(() => setIsHovered(false), []);

    return {
        isHovered,
        props: {
            onMouseEnter,
            onMouseLeave,
        },
    };
}

export type HoverableViewProps = View['props'] & {
    hoverStyle: ViewStyle;
    style?: ViewStyle;
    onPress?: ((event: GestureResponderEvent) => void) & (() => void);
};
