import React, {useState, useEffect} from 'react';
import {View, StyleSheet} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {useNavigation} from '@react-navigation/native';

import * as Icons from '~/components/common/Icons';
import CustomModal, {MessagePaymentAlert} from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {GetLastExpertsQuery_getLastExperts_edges_node as Expert} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import * as Colors from '~/constants/Colors';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import logEvent from '~/helpers/analytics';
import redirect from '~/helpers/redirect';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {ExpertsDetailsQuery_findExperts_edges_node as ExpertDetails} from '~/queries/types/ExpertsDetailsQuery';
import {QueryMe_me} from '~/queries/types/QueryMe';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import type {ExpertProfile_getExpert as ExpertProfile} from '../../screens/types/ExpertProfile';
import Button from '../common/Buttons';
import OfferListItem from '../common/IconText';
import {holdMessagePayment} from './types/holdMessagePayment';

type PopupMessageProps = {
    visible: boolean;
    onRequestClose: () => void;
    client: QueryMe_me | null | undefined;
    expert: ExpertProfile | ExpertDetails | Expert | null | undefined;
};

const HOLD_MESSAGE_PAYMENT = gql`
    mutation holdMessagePayment($expertId: String!, $messageType: String!) {
        holdMessagePayment(input: {expertId: $expertId, messageType: $messageType}) {
            messageId
            error
            url
        }
    }
`;

export default function PopupMessage(props: PopupMessageProps) {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();
    const [holdMessagePayment, {data, error}] = useMutation<holdMessagePayment>(
        HOLD_MESSAGE_PAYMENT
    );
    const confirm = useConfirm();
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<string>('');

    useEffect(() => {
        setIsLoading(false);
        props.onRequestClose();
        if (data?.holdMessagePayment?.error == 'NO_PAYMENT_METHOD_ATTACHED') {
            confirm({
                title: 'Aucun moyen de paiement ajouté',
                message:
                    "Pour pouvoir utiliser nos services vous devez d'abord ajouter un moyen de paiement à votre compte. Voulez vous ajouter un moyen de paiement dès maintenant ?",
                onYes: () =>
                    navigation.navigate('AccountScreen', {event: 'add-payment-method'}),
                noText: 'Non merci',
            });
        } else if (data?.holdMessagePayment?.error == 'NOT_ENOUGH_FUNDS') {
            confirm({
                title: 'Fonds insuffisants',
                message:
                    'Vous ne disposez pas de fonds suffisant avec votre moyen de paiement actuel. Vous pouvez vous rendre sur votre profil pour vérifier quel moyen de paiement est utilisé.',
                onYes: () => navigation.navigate('AccountScreen'),
                yesText: 'Y aller',
                noText: 'Non merci',
            });
        } else if (data?.holdMessagePayment?.error == 'HOLD_ERROR') {
            alert({
                title: "Erreur d'autoristation",
                message:
                    'Une erreur inattendue est survenue lors de la réservation de paiement effectué sur votre carte. Veuillez ré-essayer plus tard, contactez le support si le problème persiste.',
            });
        } else if (data?.holdMessagePayment?.url) {
            alert({
                title: 'Concernant votre paiement',
                message: '',
                content: <MessagePaymentAlert />,
                onClose: () => redirect(data?.holdMessagePayment?.url),
            });
        } else if (data?.holdMessagePayment?.messageId) {
            const params = {
                expert_id: props.expert?.id,
                message_id: data?.holdMessagePayment.messageId,
                type: messageType,
            };
            navigation.navigate('MessageScreen', params);
        }
    }, [data]);

    async function writeMessage(type: MessageTypeEnum) {
        const mutationData = {
            expertId: props.expert?.id,
            messageType: type,
        };

        setIsLoading(true);
        setMessageType(type);
        await holdMessagePayment({variables: mutationData});
        logEvent('message_started');
    }

    function getValue(
        expert: ExpertProfile | ExpertDetails | Expert | null | undefined,
        type: MessageTypeEnum
    ) {
        let label = '0';
        let amount = 0;
        let description = '';

        if (expert?.messageParameters && expert?.messageParameters.edges) {
            const parameter = expert.messageParameters.edges.find(
                (e) => e?.node?.type == type
            );
            if (parameter?.node) {
                description = parameter.node?.description;
                if (parameter.node?.messagePrice?.label) {
                    label = parameter.node?.messagePrice?.label;
                    amount = parameter.node?.messagePrice?.priceDecimal;
                }
            }
        }
        return {
            label: label,
            description: description,
            amount: amount,
        };
    }

    const name = props.expert?.profile?.displayName;

    const title = 'Poser votre question à ' + name;

    const simpleMessageValues = getValue(props.expert, MessageTypeEnum.SIMPLE_QUESTION);
    const completeMessageValues = getValue(props.expert, MessageTypeEnum.COMPLETE_STUDY);

    const message = <SimpleText></SimpleText>;

    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <CustomModal
            title={title}
            iconName="envelope"
            message={message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
        >
            {simpleMessageValues.amount > 0 && (
                <View style={deviceStyles.container}>
                    <SimpleText style={deviceStyles.title}>
                        <SimpleText style={deviceStyles.text}>QUESTION SIMPLE </SimpleText>
                        <SimpleText style={deviceStyles.text}>
                            {simpleMessageValues.label}
                        </SimpleText>
                    </SimpleText>
                    <View style={deviceStyles.bloc}>
                        <SimpleText style={deviceStyles.description}>
                            {simpleMessageValues.description}
                        </SimpleText>
                        <View style={deviceStyles.columnContainer}>
                            <View style={deviceStyles.leftContainer}>
                                <OfferListItem text="Votre réponse dans les 24h" />
                                <OfferListItem text="Une réponse claire et sans complaisance" />
                                <OfferListItem text="Envoyez des photos pour plus de précision" />
                            </View>
                            <View style={deviceStyles.rightContainer}>
                                <Button
                                    title="Question Simple"
                                    icon={<Icons.Mail size={25} />}
                                    onPress={() => {
                                        writeMessage(MessageTypeEnum.SIMPLE_QUESTION);
                                    }}
                                    titleStyle={styles.button_call_title}
                                    containerStyle={{width: 250, alignSelf: 'center'}}
                                    buttonStyle={{
                                        backgroundColor: Colors.pay,
                                        borderRadius: 5,
                                        paddingVertical: 10,
                                        paddingHorizontal: 10,
                                    }}
                                    loading={isLoading}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            )}
            {completeMessageValues.amount > 0 && (
                <View style={deviceStyles.container}>
                    <SimpleText style={deviceStyles.title}>
                        <SimpleText style={deviceStyles.text}>ÉTUDE COMPLÈTE </SimpleText>
                        <SimpleText style={deviceStyles.text}>
                            {completeMessageValues.label}
                        </SimpleText>
                    </SimpleText>
                    <View style={deviceStyles.bloc}>
                        <SimpleText style={deviceStyles.description}>
                            {completeMessageValues.description}
                        </SimpleText>
                        <View style={deviceStyles.columnContainer}>
                            <View style={deviceStyles.leftContainer}>
                                <OfferListItem text="Votre réponse dans les 48h" />
                                <OfferListItem text="Une voyance complète" />
                                <OfferListItem text="Envoi de pièces jointes si besoin" />
                            </View>
                            <View style={deviceStyles.rightContainer}>
                                <Button
                                    title="Étude complète"
                                    icon={<Icons.Mail size={25} />}
                                    onPress={() => {
                                        writeMessage(MessageTypeEnum.COMPLETE_STUDY);
                                    }}
                                    titleStyle={styles.button_call_title}
                                    containerStyle={{width: 250, alignSelf: 'center'}}
                                    buttonStyle={{
                                        backgroundColor: Colors.pay,
                                        borderRadius: 5,
                                        paddingVertical: 10,
                                        paddingHorizontal: 10,
                                    }}
                                    loading={isLoading}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            )}
        </CustomModal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 5,
        margin: 20,
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 10,
    },
    description: {
        fontSize: 14,
        marginTop: 20,
        marginBottom: 20,
    },
    label: {
        fontSize: 14,
        paddingTop: 7,
    },
    columnContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftContainer: {
        width: 350,
    },
    rightContainer: {
        width: 400,
        paddingTop: 20,
    },
    button_call_title: {
        fontSize: 16,
        marginHorizontal: 20,
        fontStyle: 'italic',
    },
    bloc: {},
    text: {},
});

const mobileStyles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 15,
    },
    columnContainer: {},
    leftContainer: {
        alignItems: 'flex-start',
    },
    rightContainer: {
        paddingTop: 20,
    },
    title: {
        fontSize: 16,
        padding: 8,
        color: 'white',
        backgroundColor: Colors.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        alignSelft: 'center',
        textAlign: 'center',
    },
    description: {
        fontSize: 14,
        marginTop: 20,
        marginBottom: 20,
        alignSelft: 'center',
        textAlign: 'center',
    },
    bloc: {
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: Colors.disabled,
        padding: 5,
    },
    text: {
        color: 'white',
    },
    iconContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
