export const CALL_LANDING_PAGE_PATH = 'voyance-gratuite-telephone';
export const PACK_CALL_LANDING_PAGE_PATH = 'forfaits-voyance';
export const SUPER_PROMO_LANDING_PAGE_PATH = 'voyance-promotion'; // Attention si vous changez ce chemin, pensez à changer le champ `onlyAvailableAtUrl` du pack correspondant à la promotion
export const FREE_CALL_NO_CB_LANDING_PAGE_PATH = 'voyance-gratuite-telephone-sans-cb';
export const FREE_CALL_LANDING_PAGE_PATH = `voyance-gratuite-telephone-avec-cb`;
export const CALL_LANDING_PAGE_URL = `${location.origin}/${CALL_LANDING_PAGE_PATH}`;
export const PACK_CALL_LANDING_PAGE_URL = `${location.origin}/${PACK_CALL_LANDING_PAGE_PATH}`;
export const SUPER_PROMO_LANDING_PAGE_URL = `${location.origin}/${SUPER_PROMO_LANDING_PAGE_PATH}`;
export const FREE_CALL_NO_CB_LANDING_PAGE_URL = `${location.origin}/${FREE_CALL_NO_CB_LANDING_PAGE_PATH}`;
export const FREE_CALL_LANDING_PAGE_URL = `${location.origin}/${FREE_CALL_LANDING_PAGE_PATH}`;
export const CALL_LANDING_PAGE_TITLE = 'Voyance Gratuite Téléphone | Kyvoitou.fr';
export const PACK_CALL_LANDING_PAGE_TITLE = 'Voyance Forfaits | Kyvoitou.fr';
export const SUPER_PROMO_LANDING_PAGE_TITLE = 'Voyance Promotion | Kyvoitou.fr';
export const FREE_CALL_NO_CB_LANDING_PAGE_TITLE = 'Voyance Gratuite sans CB | Kyvoitou.fr';
export const FREE_CALL_LANDING_PAGE_TITLE = `Voyance Gratuite avec CB | Kyvoitou.fr`;
export const LANDING_PAGE_PATHS = [
    CALL_LANDING_PAGE_PATH,
    PACK_CALL_LANDING_PAGE_PATH,
    SUPER_PROMO_LANDING_PAGE_PATH,
    FREE_CALL_NO_CB_LANDING_PAGE_PATH,
    // FREE_CALL_LANDING_PAGE_PATH,
];
export const landingPageLinkingConfig = {
    LandingPageCallScreen: CALL_LANDING_PAGE_PATH,
    LandingPageCallPackScreen: PACK_CALL_LANDING_PAGE_PATH,
    LandingPageCallFreeNoCbScreen: FREE_CALL_NO_CB_LANDING_PAGE_PATH,
    // LandingPageCallFreeScreen: FREE_CALL_LANDING_PAGE_PATH,
    LandingPageSuperPromoCallScreen: SUPER_PROMO_LANDING_PAGE_PATH,
};
