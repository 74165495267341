import * as React from 'react';
import {ActivityIndicator, ScrollView, View} from 'react-native';

import {useLinkTo} from '@react-navigation/native';
import {StripeError} from '@stripe/stripe-js';

import {SimpleText} from '~/components/common/Texts';
import AddPaymentView from '~/components/landingPage/AddPaymentView';
import ExpertPickerView from '~/components/landingPage/ExpertPickerView';
import {KyvoitouHomeButton} from '~/components/landingPage/KyvoitouHomeButton';
import OngoingCallView from '~/components/landingPage/OngoingCallView';
import PickedExpertView from '~/components/landingPage/PickedExpertView';
import PostPaymentView from '~/components/landingPage/PostPaymentView';
import PostRegisterView from '~/components/landingPage/PostRegisterView';
import PromoPackView, {PromoPriceNotice} from '~/components/landingPage/PromoPackView';
import RegisterView from '~/components/landingPage/RegisterView';
import Reviews from '~/components/landingPage/Reviews';
import {Title, PromoText} from '~/components/landingPage/TitleViewSuperPromo';
import {
    Step,
    UrlStepProvider,
    useUrlStepContext,
} from '~/components/landingPage/context/UrlStepContext';
import {useCallStarted} from '~/components/landingPage/hook/useCallStarted';
import useCampaignQuery from '~/components/landingPage/hook/useCampaignQuery';
import {useExpertId} from '~/components/landingPage/hook/useExpertId';
import usePackForUrl from '~/components/landingPage/hook/usePackForUrl';
import useSelectedExpert from '~/components/landingPage/query/useSelectedExpert';
import {
    useCallLandingPageRoute,
    useCallPackLandingPageNavigation,
} from '~/components/landingPage/route';
import {styles} from '~/components/landingPage/styles';
import {mobileStyles} from '~/components/user/client/message/MobileMessageBox';
import * as Colors from '~/constants/Colors';
import {PACK_CALL_LANDING_PAGE_URL} from '~/constants/LandingPageSettings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import {purchase} from '~/helpers/analytics';
import event from '~/helpers/analytics';
import {ExpertWithLastReview} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {useMinuteHistory} from '~/queries/useMinuteBalance';
import {useUserAllowedToBuyPack} from '~/queries/usePack';
import {TransactionType} from '~/types/graphql-global-types';

export default function LandingPageScreen() {
    return (
        <UrlStepProvider>
            <Content />
        </UrlStepProvider>
    );
}

function Content() {
    const {me, refetchMe, minutes} = React.useContext(UserContext);
    const {pack: selectedPack, loading: loadingSelectedPack} = usePackForUrl();
    const {data: allowedPack} = useUserAllowedToBuyPack(selectedPack?.packId, me?.userId);
    const packAvailability = allowedPack?.isPackAvailableForUser;
    const alert = useAlert();
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles.container : mobileStyles.container;
    const scrollViewRef = React.useRef<ScrollView>(null);
    function scrollToTop() {
        scrollViewRef.current?.scrollTo({y: 0, animated: false});
    }

    const query = useCampaignQuery();
    const linkTo = useLinkTo();
    const {data: minutesHistoryData, refetch: refetchMinutesHistory} = useMinuteHistory();
    const route = useCallLandingPageRoute();
    const sessionId =
        route.params?.sessionId || me?.identity?.selectedPaymentMethod?.sessionId;

    React.useEffect(() => {
        const transactions = minutesHistoryData?.getMinuteHistory?.edges.map((e) => e?.node);
        const hasTransaction = transactions?.some(
            (t) => t?.transaction == TransactionType.SPEND
        );
        if (hasTransaction) {
            const balance = minutes?.getMinuteBalance?.minutes ?? 0;
            const hasBalance = balance > 0;
            const balanceText = hasBalance
                ? `\n\nVous pourrez profiter de votre solde de ${balance} minutes restantes avec l'entièreté de nos médiums.`
                : ``;
            alert({
                title: 'Heureux de vous revoir !',
                message: `Vous allez être redirigé vers l'accueil de Kyvoitou pour profiter de l'expérience complète sur la plateforme. ${balanceText}`,
                buttonText: "C'est parti 🚀",
                onClose: () => {
                    linkTo('/home');
                },
            });
        }
    }, [minutes]);

    const {
        showExpertList,
        showAuthForm,
        showPaymentCTA,
        showCallStatus,
        isAuthStep,
        isPaymentStep,
        isExpertListStep,
    } = useUrlStepContext();
    const [callAlreadyStarted, setCallAlreadyStarted] = useCallStarted();
    const [selectedExpertId, setSelectedExpertId] = useExpertId();
    const onExpertSelected = React.useCallback(
        (user: ExpertWithLastReview) => {
            setSelectedExpertId(user.userId);
            if (me?.id) {
                showPaymentCTA();
            } else {
                showAuthForm();
            }
            scrollToTop();
            event('call_landing_page_expert_selected', {expert_id: user.userId});
        },
        [me]
    );

    function showPaymentCtaAndScroll() {
        showPaymentCTA();
        scrollToTop();
    }

    const onRegisterAccount = React.useCallback(() => {
        showPaymentCtaAndScroll();
        setTimeout(() => refetchMe(), 2000);
        event('call_landing_page_register');
    }, []);

    const onPaymentIntentConfirmed = React.useCallback(() => {
        purchase(`Pack:${selectedPack?.packId}`, 'pack', selectedPack?.amount);
        showCallStatus();
    }, [selectedPack]);

    const onPaymentIntentError = React.useCallback((error: StripeError) => {
        event('call_landing_page_pack_payment_method_error', {
            stripe_code: error.code,
            stripe_message: error.message,
        });
    }, []);

    const onCancelPayment = React.useCallback(() => {
        showExpertList();
        scrollToTop();
        event('call_landing_page_pack_refused_waiting_list');
    }, []);

    const pickAnotherExpert = React.useCallback(() => {
        showExpertList();
        setSelectedExpertId(undefined);
        scrollToTop();
        event('call_landing_page_pack_pick_another_expert');
    }, []);

    const {data, loading} = useSelectedExpert(selectedExpertId);
    const selectedExpertUser = data?.user;

    const returnQueryParams = {...route.params};
    returnQueryParams.step = Step.ONGOING_CALL_STEP.valueOf();

    const navigation = useCallPackLandingPageNavigation();
    const returnToFirstStep = React.useCallback(() => {
        refetchMinutesHistory();
        navigation.navigate('LandingPageCallPackScreen', {});
    }, []);

    const canUserUsePack = packAvailability?.date && packAvailability?.quantity;

    return (
        <View style={{flex: 1}}>
            <ScrollView ref={scrollViewRef} contentContainerStyle={{flexGrow: 1}}>
                <View style={deviceStyle}>
                    <Title />
                    {(isAuthStep || isPaymentStep || isExpertListStep) && (
                        <View style={{marginTop: 40}}>
                            {loadingSelectedPack ? (
                                <ActivityIndicator
                                    color={Colors.rainbowFirst}
                                    size={40}
                                    style={{marginBottom: 40}}
                                />
                            ) : (
                                <PromoPackView pack={selectedPack} />
                            )}
                        </View>
                    )}
                    {isExpertListStep && me != null && !packAvailability?.date && (
                        <View style={{marginTop: 20}}>
                            <SimpleText style={styles.promoParagraph}>
                                Cette offre n'est malheureusement plus disponible. Vous pouvez
                                vous rendre sur Kyvoitou et profiter de l'ensemble de nos
                                services.
                            </SimpleText>
                            <KyvoitouHomeButton />
                        </View>
                    )}
                    {isExpertListStep &&
                        me != null &&
                        packAvailability?.date &&
                        !packAvailability?.quantity && (
                            <View style={{marginTop: 20}}>
                                <SimpleText style={styles.promoParagraph}>
                                    Vous avez déjà profité de cette offre. Vous pouvez vous
                                    rendre sur Kyvoitou et découvrir de l'ensemble de nos
                                    services.
                                </SimpleText>
                                <KyvoitouHomeButton />
                            </View>
                        )}
                    {(canUserUsePack || me == null) && (
                        <>
                            <PromoText />
                            {(isAuthStep || isPaymentStep || isExpertListStep) && (
                                <PromoPriceNotice />
                            )}
                            <ExpertPickerView
                                onPaymentMethodRequired={showPaymentCtaAndScroll}
                                onExpertSelected={onExpertSelected}
                                medium="no-action"
                            />
                            <PickedExpertView
                                expertUser={selectedExpertUser}
                                onPickAnotherExpertPress={pickAnotherExpert}
                            />
                            <RegisterView
                                stepIndex={2}
                                style={{
                                    maxWidth: 700,
                                    alignSelf: 'center',
                                    flex: 1,
                                    marginVertical: 20,
                                }}
                                query={query}
                                onRegister={onRegisterAccount}
                            />
                            <PostRegisterView stepIndex={2} />
                            <AddPaymentView
                                stepIndex={3}
                                packId={selectedPack?.packId}
                                expertUser={selectedExpertUser}
                                loadingExpert={loading}
                                onPaymentIntentConfirmed={onPaymentIntentConfirmed}
                                onPaymentIntentError={onPaymentIntentError}
                                returnUrl={PACK_CALL_LANDING_PAGE_URL}
                                returnQueryParams={returnQueryParams}
                            />
                            <PostPaymentView />
                            <OngoingCallView
                                usePack
                                onPressReturn={returnToFirstStep}
                                sessionId={sessionId}
                                expertUser={selectedExpertUser}
                                selectedExpertId={selectedExpertId}
                                loadingExpert={loading}
                                callAlreadyStarted={callAlreadyStarted}
                                setCallAlreadyStarted={setCallAlreadyStarted}
                                onCancelPayment={onCancelPayment}
                            />
                            <Reviews />
                        </>
                    )}
                </View>
            </ScrollView>
        </View>
    );
}
