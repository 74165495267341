import * as React from 'react';
import {View, StyleSheet, TouchableOpacity, Image} from 'react-native';

import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {SimpleText} from '../common/Texts';

export function SocialNetwork() {
    const {isDesktop} = useDeviceQuery();

    function facebook() {
        window.open('https://www.facebook.com/SiteKyvoitou');
    }

    function instagram() {
        window.open('https://www.instagram.com/kyvoitou.fr/');
    }

    function tiktok() {
        window.open('https://www.tiktok.com/@kyvoitou.fr');
    }

    function youtube() {
        window.open('https://www.youtube.com/channel/UCnGMWgsKrXIM-dZWi7WS_5g');
    }

    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <View style={deviceStyles.container}>
            <TouchableOpacity accessibilityRole="button" onPress={instagram}>
                <Image
                    style={styles.social}
                    source={{
                        uri: Settings.getUrlImageLogo('instagram.webp'),
                    }}
                    accessibilityLabel="Instagram Kyvoitou.fr"
                />
            </TouchableOpacity>
            <TouchableOpacity accessibilityRole="button" onPress={youtube}>
                <Image
                    style={styles.social}
                    source={{
                        uri: Settings.getUrlImageLogo('youtube.webp'),
                    }}
                    accessibilityLabel="Youtube Kyvoitou.fr"
                />
            </TouchableOpacity>
            <TouchableOpacity accessibilityRole="button" onPress={facebook}>
                <Image
                    style={styles.social}
                    source={{
                        uri: Settings.getUrlImageLogo('facebook.webp'),
                    }}
                    accessibilityLabel="Facebook Kyvoitou.fr"
                />
            </TouchableOpacity>
            <TouchableOpacity accessibilityRole="button" onPress={tiktok}>
                <Image
                    style={styles.social}
                    source={{
                        uri: Settings.getUrlImageLogo('tiktok.webp'),
                    }}
                    accessibilityLabel="TikTok Kyvoitou.fr"
                />
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        zIndex: 0,
    },
    network: {
        color: 'white',
        fontSize: 16,
        paddingVertical: 10,
    },
    social: {
        width: 32,
        height: 32,
        marginLeft: 16,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        zIndex: 0,
        paddingVertical: 20,
    },
});
