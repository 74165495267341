import {Bold, SimpleText, Underlined} from '../common/Texts';
import {useUrlStepContext} from './context/UrlStepContext';
import {styles} from './styles';

export default function PackTitleView() {
    const {isExpertListStep} = useUrlStepContext();
    const isVisible = isExpertListStep;
    if (!isVisible) {
        return null;
    }
    return (
        <>
            <PackTitle />
            <PackPromoText />
        </>
    );
}

function PackTitle() {
    return (
        <SimpleText accessibilityRole="header" aria-level="1" style={styles.title}>
            Obtenez la voyance que vous méritez !
        </SimpleText>
    );
}

function PackPromoText() {
    return (
        <>
            <SimpleText style={styles.promoParagraph}>
                Chez Kyvoitou.fr nous favorisons le <Bold>développement personnel</Bold> pour
                vous permettre de devenir <Bold>la meilleure version de vous même</Bold> !
            </SimpleText>
            <SimpleText style={styles.promoParagraph}>
                Rappelez-vous, vous avez <Bold>votre libre arbitre</Bold> ! Vous êtes créateur
                de votre vie. C'est pourquoi nous vous proposons un{' '}
                <Bold>coaching divinatoire</Bold> pour prendre les plus belles décisions de
                votre existence.
            </SimpleText>
            <SimpleText style={styles.promoParagraph}>
                Nous vous proposons d'arriver à cette <Bold>plénitude</Bold> à votre rythme
                grâce à nos différents forfaits.
            </SimpleText>
            <SimpleText style={styles.promoParagraph}>
                Pour enfin, obtenir les changements que vous désirez tant, c'est très simple !
                Suivez ces <Underlined>quatres étapes</Underlined> et dans quelques minutes
                obtenez les réponses à vos questions !
            </SimpleText>
        </>
    );
}
