import {QueryUtm} from '~/types';

import {useLandingPageRoute} from '../route';

export default function useCampaignQuery() {
    const route = useLandingPageRoute();

    // This assignment looks stupid but it doesn't work otherwise
    const source = route.params?.utm_source;
    const medium = route.params?.utm_medium;
    const campaign = route.params?.utm_campaign;

    // Don't touch, params need to be assign before creating object
    return {
        utm_source: source,
        utm_medium: medium,
        utm_campaign: campaign,
    } as QueryUtm;
}
