import React, {useState, useEffect} from 'react';
import {View, StyleSheet, Image, FlatList, TouchableOpacity} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import * as DocumentPicker from 'expo-document-picker';
import 'react-native-gesture-handler';

import HoverableView from '~/components/common/HoverableView';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import {isImg} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import Button from '../../../common/Buttons';

const UPLOAD_ATTACHMENT = gql`
    mutation UploadAttachment($fileBase64: String!, $fileExtension: String!) {
        uploadAttachment(input: {fileBase64: $fileBase64, fileExtension: $fileExtension}) {
            imageName
            error
        }
    }
`;

const NB_MAX_ATTACHMENT = 5;
const NB_MAX_MO = 5;

type UploadAttachmentsProps = {
    setAttachments: (atachments: string[]) => void;
    buttonBackgroundColor: string;
};

export default function UploadAttachments(props: UploadAttachmentsProps) {
    const {isDesktop, isMobile} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const confirm = useConfirm();
    const alert = useAlert();
    const [uploadAttachment, {data: uploadData, error: uploadError}] = useMutation(
        UPLOAD_ATTACHMENT
    );
    const [attachments, setAttachments] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function size(base64: string) {
        const bytes = (base64.length - 814) / 1.37;
        const mb = Math.round(bytes / (1024 * 1024));
        return mb;
    }

    useEffect(() => {
        if (uploadData) {
            setIsLoading(false);
            const error = uploadData.uploadAttachment.error;
            if (error) {
                alert({message: error});
            } else {
                const attachment = uploadData.uploadAttachment.imageName;
                const newAttachment = [...attachments, attachment];
                setAttachments(newAttachment);
                props.setAttachments(newAttachment);
            }
        }
    }, [uploadData]);

    let openImagePickerAsync = async () => {
        if (attachments.length >= NB_MAX_ATTACHMENT) {
            alert({
                message: `Le nombre maximum de pièces jointes est atteint (${NB_MAX_ATTACHMENT}).`,
            });
        } else {
            let pickerResult = await DocumentPicker.getDocumentAsync({
                multiple: false,
            });

            if (pickerResult && !pickerResult.canceled && pickerResult.output) {
                const asset = pickerResult.assets[0];
                if (size(asset.uri) > NB_MAX_MO) {
                    alert({message: 'Le fichier dépasse le poids maximum (5Mo)'});
                } else {
                    const name = asset.name;
                    const extension = name.substring(name.lastIndexOf('.') + 1, name.length);
                    const variables = {
                        fileBase64: asset.uri,
                        fileExtension: extension,
                    };

                    setIsLoading(true);
                    uploadAttachment({variables});
                }
            }
        }
    };

    function deleteAttachement(item: string) {
        confirm({
            title: 'Supprimer une pièce jointe',
            message: 'Êtes-vous sur de vouloir supprimer la pièce jointe ?',
            onYes: () => {
                const newAttachment = attachments.filter(function (value) {
                    return value != item;
                });
                setAttachments(newAttachment);
                props.setAttachments(newAttachment);
            },
            yesText: 'Supprimer',
            noText: 'Annuler',
        });
    }

    function renderAttachment({item}: {item: string}) {
        const img = isImg(item);
        return (
            <View style={styles.attachmentView}>
                <HoverableView style={{}} hoverStyle={styles.hoverAttachment}>
                    <TouchableOpacity onPress={() => deleteAttachement(item)}>
                        {!img && (
                            <View style={styles.attachmentIcon}>
                                <Icons.File size={24} color={Colors.secondary} />
                            </View>
                        )}
                        {img && (
                            <Image
                                source={{
                                    uri: Settings.getUrlImageAttachment(item),
                                }}
                                style={styles.attachment}
                            />
                        )}
                    </TouchableOpacity>
                </HoverableView>
            </View>
        );
    }

    return (
        <View style={mobileStyles.attachmentButton}>
            {attachments.length > 0 && (
                <View style={deviceStyles.attachementContainer}>
                    {isDesktop && (
                        <SimpleText style={styles.attachementLabel}>Pièces jointes</SimpleText>
                    )}
                    <FlatList
                        data={attachments}
                        renderItem={renderAttachment}
                        keyExtractor={(item) => item}
                        contentContainerStyle={deviceStyles.attachements}
                        horizontal={isMobile}
                    />
                </View>
            )}
            <Button
                icon={
                    <Icons.Attachment
                        size={20}
                        style={{paddingRight: 10}}
                        color={Colors.link}
                    />
                }
                title="Ajouter une pièce jointe"
                buttonStyle={{backgroundColor: props.buttonBackgroundColor, borderWidth: 0}}
                onPress={openImagePickerAsync}
                loading={isLoading}
                loadingProps={{color: Colors.disabled}}
                style={{marginTop: 0, marginHorizontal: 0}}
                titleStyle={{color: Colors.link, fontSize: 14}}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    attachementContainer: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'flex-start',
        padding: 10,
    },
    attachementLabel: {
        paddingTop: 16,
    },
    attachements: {
        flex: 1,
        flexDirection: 'row',
    },
    attachment: {
        resizeMode: 'contain',
        width: 45,
        height: 45,
        borderWidth: 1,
        borderColor: Colors.primary,
    },
    attachmentIcon: {
        width: 45,
        height: 45,
        borderWidth: 1,
        borderColor: Colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
    },
    attachmentButton: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    hoverAttachment: {
        opacity: 0.5,
    },
    attachmentView: {
        marginLeft: 10,
    },
});

const mobileStyles = StyleSheet.create({
    attachmentButton: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
    attachementContainer: {
        alignSelf: 'center',
    },
    attachements: {
        flexDirection: 'row',
        alignSelf: 'center',
        marginBottom: 30,
    },
});
