import {useMemo} from 'react';

import {filterEmpty} from '~/helpers/list';
import usePack from '~/queries/usePack';

export default function usePackForUrl() {
    const {data, ...rest} = usePack();
    const selectedPack = useMemo(() => {
        const packs = filterEmpty(data?.getPacks?.edges.map((e) => e?.node));
        const selectedPacks = packs.filter(
            (p) => p.onlyAvailableAtUrl == window.location.pathname
        );
        if (selectedPacks.length == 0) {
            return null;
        }
        return selectedPacks[0];
    }, [data]);

    return {pack: selectedPack, ...rest};
}
