import * as React from 'react';
import {useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';
import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import {MailInput} from '~/components/common/Inputs';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {subscription} from '~/helpers/newsletter';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

import PopupCookie from './PopupCookie';
import {Separator} from './common/Separator';
import {SimpleText} from './common/Texts';

const {email} = yupRules;
const validationSchema = yup.object().shape({email});

type Inputs = Pick<YupTypes, 'email'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

export default function Footer() {
    const alert = useAlert();
    const navigation = useNavigation();
    const [email, setEmail] = useState<string>('');
    const [popupCookieVisible, setPopupCookieVisible] = useState<boolean>(false);

    async function submitNewsletter(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        subscription(values.email);
        alert({
            message: 'Votre email a bien été inscrit à la newsletter.',
            onClose: onClose,
            width: 400,
        });
        resetForm();
    }

    function onClose() {
        setEmail('');
    }

    function review() {
        window.open(Settings.google_form);
    }

    function blog() {
        window.open(Settings.url_blog);
    }

    function search() {
        navigation.navigate('SearchScreen', {tag: ''});
    }

    return (
        <LinearGradient
            style={styles.footer}
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}
            colors={Colors.rainbow}
        >
            <View style={styles.container}>
                <View style={styles.title}>
                    <SimpleText style={styles.page}>Newsletter</SimpleText>
                    <SimpleText style={styles.page}>Comprendre</SimpleText>
                    <SimpleText style={styles.page}>Nos services</SimpleText>
                    <SimpleText style={styles.page}>Juridique</SimpleText>
                    <SimpleText style={styles.page}>Recrutement</SimpleText>
                </View>
                <Separator style={styles.separator} />
                <View style={styles.section}>
                    <View style={styles.newsletter}>
                        <SimpleText style={styles.link}>Actus & Promos *</SimpleText>
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            onSubmit={submitNewsletter}
                            validationSchema={validationSchema}
                        >
                            {(formikProps) => <NewsletterInputs {...formikProps} />}
                        </Formik>
                    </View>
                    <View style={styles.column}>
                        <TouchableOpacity
                            accessibilityRole="button"
                            onPress={search}
                            style={styles.button}
                        >
                            <SimpleText style={styles.link}>Consulter un médium</SimpleText>
                        </TouchableOpacity>
                        <PageLink screen={'HowToScreen'} text={'Comment ça marche ?'} />
                        <PageLink screen={'CommitmentScreen'} text={'Nos engagements KVT'} />
                        <PageLink screen={'FaqScreen'} text={'Aide : FAQ'} />
                        <PageLink screen={'EthicScreen'} text={'Notre charte éthique'} />
                    </View>
                    <View style={styles.column}>
                        <PageLink screen={'PriceScreen'} text={'Nos tarifs'} />
                        <PageLink screen={'AboutScreen'} text={'Qui sommes-nous ?'} />
                        <PageLink screen={'ContactScreen'} text={'Contact & SAV'} />
                        <TouchableOpacity
                            accessibilityRole="button"
                            onPress={review}
                            style={styles.button}
                        >
                            <SimpleText style={styles.link}>Donner votre avis</SimpleText>
                        </TouchableOpacity>
                        <TouchableOpacity
                            accessibilityRole="button"
                            onPress={blog}
                            style={styles.button}
                        >
                            <SimpleText style={styles.link}>BLOG</SimpleText>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.column}>
                        <PageLink
                            screen={'CgvScreen'}
                            text={'Conditions générales de vente'}
                        />
                        <PageLink
                            screen={'CguScreen'}
                            text={"Conditions générales d'utilisation"}
                        />
                        <PageLink screen={'LegalNoticeScreen'} text={'Mentions légales'} />
                        <TouchableOpacity
                            accessibilityRole="button"
                            onPress={() => setPopupCookieVisible(true)}
                            style={styles.button}
                        >
                            <SimpleText style={styles.link}>
                                Utilisation des cookies
                            </SimpleText>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.column}>
                        <PageLink screen={'ApplyScreen'} text={'Devenir spirite'} />
                        <PageLink screen={'JobScreen'} text={"Offre d'emploi"} />
                    </View>
                </View>
                <SimpleText style={styles.disclaimer}>
                    * Je souhaite recevoir l'actualité ésotérique (horoscopes, numérologie,
                    guidances, etc...) et les pubs de kyvoitou.fr
                </SimpleText>
                <SimpleText style={styles.copyright}>
                    &copy; - Kyvoitou.fr - Tous droits réservés.
                </SimpleText>
            </View>
            <PopupCookie visible={popupCookieVisible} setVisible={setPopupCookieVisible} />
        </LinearGradient>
    );
}

function NewsletterInputs(props: Props) {
    return (
        <View style={styles.inputs}>
            <MailInput
                style={styles.mail}
                placeholder={yupLabels.email}
                value={props.values.email}
                onChangeText={props.handleChange('email')}
                onBlur={() => props.setFieldTouched('email')}
                blurOnSubmit={true}
                errorMessage={props.errors.email || ' '}
                inputStyle={styles.input}
                containerStyle={styles.padding}
            />
            <Button
                title="Je m'inscris"
                buttonStyle={styles.subscribe}
                style={styles.margin}
                disabled={!props.isValid}
                onPress={() => props.handleSubmit()}
                loading={props.isSubmitting}
            />
        </View>
    );
}

function PageLink(props: {screen: string; text: string}) {
    const navigation = useNavigation();

    function navigationFunction() {
        navigation.navigate(props.screen);
    }

    return (
        <TouchableOpacity style={styles.button} onPress={navigationFunction}>
            <SimpleText style={styles.link}>{props.text}</SimpleText>
        </TouchableOpacity>
    );
}

export function FooterMobile() {
    const [popupCookieVisible, setPopupCookieVisible] = useState<boolean>(false);

    function review() {
        window.open(Settings.google_form);
    }

    return (
        <View style={mobileStyles.footer}>
            <SimpleText style={mobileStyles.title}>En savoir plus sur Kyvoitou.fr</SimpleText>
            <View style={mobileStyles.container}>
                <View>
                    <PageLinkMobile
                        left
                        screen={'CommitmentScreen'}
                        text={'Nos engagements KVT'}
                    />
                    <PageLinkMobile left screen={'FaqScreen'} text={'Aide : FAQ'} />
                    <PageLinkMobile left screen={'ContactScreen'} text={'Contact & SAV'} />
                    <PageLinkMobile
                        left
                        screen={'EthicScreen'}
                        text={'Notre charte éthique'}
                    />
                    <TouchableOpacity
                        accessibilityRole="button"
                        onPress={review}
                        style={mobileStyles.button}
                    >
                        <SimpleText style={mobileStyles.link}>Donner votre avis</SimpleText>
                    </TouchableOpacity>
                    <TouchableOpacity
                        accessibilityRole="button"
                        onPress={() => setPopupCookieVisible(true)}
                        style={mobileStyles.button}
                    >
                        <SimpleText style={mobileStyles.link}>
                            Utilisation des cookies
                        </SimpleText>
                    </TouchableOpacity>
                </View>
                <View>
                    <PageLinkMobile right screen={'CgvScreen'} text={'CGV'} />
                    <PageLinkMobile right screen={'CguScreen'} text={'CGU'} />
                    <PageLinkMobile
                        right
                        screen={'LegalNoticeScreen'}
                        text={'Mentions légales'}
                    />
                    <PageLinkMobile right screen={'ApplyScreen'} text={'Devenir spirite'} />
                    <PageLinkMobile right screen={'JobScreen'} text={"Offre d'emploi"} />
                </View>
            </View>
            <SimpleText style={mobileStyles.copyright}>
                &copy; - Kyvoitou.fr - Tous droits réservés.
            </SimpleText>
            <PopupCookie visible={popupCookieVisible} setVisible={setPopupCookieVisible} />
        </View>
    );
}

function PageLinkMobile(props: {
    screen: string;
    text: string;
    left?: boolean;
    right?: boolean;
}) {
    const navigation = useNavigation();

    function navigationFunction() {
        // navigation.navigate(props.screen);
        navigation.navigate('Main', {screen: 'Home', params: {screen: props.screen}});
    }

    let textAlign = {};
    if (props.left) {
        textAlign = {textAlign: 'left'};
    }
    if (props.right) {
        textAlign = {textAlign: 'right'};
    }

    return (
        <TouchableOpacity style={mobileStyles.button} onPress={navigationFunction}>
            <SimpleText style={[mobileStyles.link, textAlign]}>{props.text}</SimpleText>
        </TouchableOpacity>
    );
}

export type HeaderProps = {};

const styles = StyleSheet.create({
    footer: {
        marginTop: 150,
        paddingVertical: 24,
    },
    container: {
        alignSelf: 'center',
    },
    title: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    page: {
        color: 'white',
        fontSize: 16,
        textTransform: 'uppercase',
        width: 170,
    },
    separator: {
        height: 1,
        backgroundColor: 'white',
        marginTop: 10,
        marginBottom: 25,
        width: 1080,
    },
    section: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    newsletter: {
        width: 170,
    },
    column: {
        width: 170,
    },
    button: {
        marginBottom: 20,
    },
    link: {
        color: 'white',
        fontSize: 14,
    },
    disclaimer: {
        color: 'white',
        fontSize: 10,
        marginBottom: 5,
    },
    copyright: {
        color: 'white',
        fontSize: 12,
    },
    inputs: {
        alignItems: 'flex-start',
        marginTop: 5,
    },
    mail: {
        maxWidth: 180,
    },
    subscribe: {
        width: 180,
        alignSelf: 'center',
    },
    margin: {
        margin: 0,
    },
    input: {
        backgroundColor: 'white',
        borderRadius: 5,
    },
    padding: {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

const mobileStyles = StyleSheet.create({
    footer: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderTopColor: Colors.primary,
        borderTopWidth: 1,
        width: '100%',
    },
    title: {
        fontSize: 12,
        textAlign: 'center',
        color: Colors.highlight,
        marginBottom: 10,
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    column: {},
    button: {
        marginBottom: 5,
    },
    link: {
        color: Colors.secondary,
        fontSize: 10,
    },
    copyright: {
        fontSize: 10,
        textAlign: 'center',
        color: Colors.fade,
        marginTop: 10,
    },
});
