import React from 'react';
import {View, StyleSheet} from 'react-native';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import BlocAccount from './BlocAccount';
import BlocAlerting from './BlocAlerting';
import BlocContact from './BlocContact';
import BlocDisable from './BlocDisable';
import BlocPayment from './BlocPayment';

export default function ClientAccount() {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return (
            <MainView>
                <SimpleText style={styles.title} accessibilityRole="header" aria-level="1">
                    Mon Compte
                </SimpleText>
                <SimpleText style={styles.description}>
                    Retrouvez ici les informations personnelles de votre compte Kyvoitou.fr.
                    Celles-ci sont strictement confidentielles et ne seront jamais transmises.
                    Vous pouvez les compléter, les modifier ou les supprimer à tout moment.
                </SimpleText>
                <View style={styles.container}>
                    <View style={styles.column}>
                        <BlocAccount />
                        <BlocContact />
                    </View>
                    <View style={styles.column}>
                        <BlocPayment />
                    </View>
                    <View style={styles.column}>
                        <BlocAlerting />
                        <BlocDisable />
                    </View>
                </View>
            </MainView>
        );
    } else {
        return (
            <MainView>
                <SimpleText style={styles.title}>Paramètres</SimpleText>
                <View style={mobileStyles.container}>
                    <BlocAccount />
                    <BlocPayment />
                    <BlocContact />
                    <BlocAlerting />
                    <BlocDisable />
                </View>
            </MainView>
        );
    }
}

const styles = StyleSheet.create({
    title: {
        fontWeight: 'bold',
        fontSize: 38,
        textTransform: 'uppercase',
        color: Colors.secondary,
        alignSelf: 'center',
        margin: 44,
    },
    description: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
    },
    column: {
        marginHorizontal: 10,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        alignSelf: 'center',
    },
});
