import React from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import * as Colors from '~/constants/Colors';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {SimpleText} from '../common/Texts';

export function AlreadyConnected() {
    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <SimpleText>Vous êtes déjà connecté</SimpleText>
            <TouchableOpacity onPress={() => navigation.navigate('HomeScreen')}>
                <SimpleText style={styles.link}>Aller à l'accueil</SimpleText>
            </TouchableOpacity>
        </View>
    );
}

export function MustBeConnected() {
    const navigation = useNavigation();
    const {isDesktop} = useDeviceQuery();

    function onPress() {
        if (isDesktop) {
            navigation.navigate('LoginScreen');
        } else {
            navigation.navigate('Main', {screen: 'Home', params: {screen: 'LoginScreen'}});
        }
    }

    return (
        <View style={styles.container}>
            <SimpleText>Vous devez être connecté pour accéder à cette page</SimpleText>
            <TouchableOpacity onPress={onPress}>
                <SimpleText style={styles.link}>Je me connecte</SimpleText>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        margin: 25,
        textAlign: 'center',
        alignSelf: 'center',
    },
    link: {
        color: Colors.link,
        fontSize: 12,
        margin: 10,
        textAlign: 'center',
        alignSelf: 'center',
    },
});
