import {useCallback, useContext, useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';

import {useLinkTo} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';

import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {CallStatusContext} from '~/contexts/CallStatusContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {ViewProps} from '../common/Containers';
import {ProfilePicture} from '../common/Images';
import {SimpleText} from '../common/Texts';
import {KyvoitouHomeButton} from './KyvoitouHomeButton';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePaymentIntent} from './hook/usePaymentIntent';
import {SelectedExpertUser} from './query/useSelectedExpert';
import {useCallLandingPageRoute} from './route';
import {styles} from './styles';

export type OngoingCallViewProps = ViewProps & {
    sessionId: string | undefined;
    expertUser: SelectedExpertUser | undefined;
    selectedExpertId: string | undefined;
    loadingExpert?: boolean;
    callAlreadyStarted: boolean | undefined;
    setCallAlreadyStarted: (started: boolean) => void;
    onCancelPayment: () => void;
    onPressReturn: () => void;
    usePack?: boolean;
    useFreeCall?: boolean;
};

const WAITING_FOR_BALANCE_INTERVAL_MS = 1000;
const MAX_TOTAL_WAITING_DURATION_MS = 5000;

export default function OngoingCallView({
    sessionId,
    expertUser,
    selectedExpertId,
    loadingExpert,
    callAlreadyStarted,
    setCallAlreadyStarted,
    onCancelPayment,
    onPressReturn,
    usePack,
    useFreeCall,
    ...viewProps
}: OngoingCallViewProps) {
    const {isMobile} = useDeviceQuery();
    const alert = useAlert();
    const {me, minutes, refetchMinutes} = useContext(UserContext);
    const [waitingForBalance, setWaitingForBalance] = useState(false);
    const [totalWaitingTime, setTotalWaitingTime] = useState(0);
    const increaseWaitingTime = useCallback(
        (ms: number) => setTotalWaitingTime((t) => t + ms),
        []
    );
    const waitedTooLong = totalWaitingTime > MAX_TOTAL_WAITING_DURATION_MS;
    const call = useContext(CallStatusContext);
    const route = useCallLandingPageRoute();
    const {paymentIntent} = usePaymentIntent();
    const {isOngoingCallStep} = useUrlStepContext();
    const balance = minutes?.getMinuteBalance?.minutes ?? 0;
    const shouldUsePack = usePack ?? false;
    const withoutCard = !shouldUsePack;

    const waitForBalance = useCallback(
        (ms: number) =>
            setTimeout(() => {
                refetchMinutes();
                setWaitingForBalance(false);
                increaseWaitingTime(ms);
            }, ms),
        []
    );

    const shouldWaitForBalance = useCallback(() => {
        if (waitingForBalance && balance > 0) {
            setWaitingForBalance(false);
            return false;
        }
        if (waitingForBalance && balance == 0) {
            return true;
        }
        if (shouldUsePack && balance == 0 && !waitingForBalance) {
            if (!waitedTooLong) {
                setWaitingForBalance(true);
                waitForBalance(WAITING_FOR_BALANCE_INTERVAL_MS);
            } else {
                alert({
                    title: 'Paiement pack en attente de confirmation',
                    message: `L'achat de votre pack est toujours en attente de confirmation. Il est possible que ce problème se résolve de lui même en attendant quelques secondes supplémentaires. Si ce problème persiste, contactez le support Kyvoitou au ${Settings.support_telephone}`,
                    buttonText: 'Attendre',
                    onClose: () => {
                        setTotalWaitingTime(0);
                        setWaitingForBalance(true);
                        waitForBalance(WAITING_FOR_BALANCE_INTERVAL_MS);
                    },
                    width: 380,
                });
            }
            return true;
        }
        return false;
    }, [waitingForBalance, balance, shouldUsePack]);

    useEffect(() => {
        if (selectedExpertId == null) {
            return;
        }
        if (!me?.id) {
            return;
        }
        if (callAlreadyStarted || !isOngoingCallStep) {
            return;
        }
        if (shouldWaitForBalance()) {
            return;
        }

        const userId = `User:${selectedExpertId}`;
        call?.current?.reallyCallExpert(
            me.id,
            `User:${selectedExpertId}`,
            0,
            0,
            shouldUsePack,
            useFreeCall ?? false,
            withoutCard,
            paymentIntent,
            onCancelPayment
        );
        logEvent('call_landing_page_call_started', {expert_id: userId});
        setCallAlreadyStarted(true);
    }, [callAlreadyStarted, selectedExpertId, me, shouldWaitForBalance, isOngoingCallStep]);

    const isVisible = isOngoingCallStep;

    if (!isVisible) {
        return null;
    }

    const {step, event, ..._} = route.params;

    return (
        <View {...viewProps}>
            <View style={{marginTop: 50}}>
                <View style={styles.callView}>
                    <ProfilePicture
                        style={styles.profilePicture}
                        pictureName={expertUser?.profile?.pictureName}
                        displayName={expertUser?.profile?.displayName}
                    />
                    <SimpleText style={styles.callMessage}>
                        Appel initié avec {expertUser?.profile?.displayName} vers{' '}
                        {me?.identity?.phone}
                    </SimpleText>
                </View>
                <SimpleText style={styles.promoParagraph}>
                    {!useFreeCall && !usePack && (
                        <SimpleText>Votre Cb à bien été renseignée.</SimpleText>
                    )}
                    <SimpleText>
                        Restez près de votre téléphone ({me?.identity?.phone}), votre voyant,{' '}
                        {expertUser?.profile?.displayName}, va vous appeler dans quelques
                        instants !
                    </SimpleText>
                </SimpleText>
                {useFreeCall && !usePack && (
                    <SimpleText style={styles.promoParagraph}>
                        Pour cet appel : les 5 minutes sont gratuites, la communication sera
                        coupée automatiquement une fois ce temps écoulé.
                    </SimpleText>
                )}
                {!useFreeCall && !usePack && (
                    <SimpleText style={styles.promoParagraph}>
                        Pour cet appel : les 5 première minutes sont gratuites,
                        {!usePack && (
                            <SimpleText>
                                les minutes suivantes seront facturées{' '}
                                {expertUser?.callParameters?.price?.priceDecimal}€/min.
                            </SimpleText>
                        )}
                        {usePack && (
                            <SimpleText>
                                les minutes suivantes seront prélevées sur votre forfait.
                            </SimpleText>
                        )}
                    </SimpleText>
                )}
                <SimpleText style={styles.promoParagraph}>
                    Le statut de cet appel sera visible en {isMobile ? 'haut' : 'bas'} de votre
                    écran.
                </SimpleText>
                {!usePack && (
                    <SimpleText style={styles.promoParagraph}>
                        A la fin de votre appel, vous pouvez revenir au choix des experts en
                        cliquant{' '}
                        <Pressable onPress={onPressReturn}>
                            <SimpleText style={{textDecorationLine: 'underline'}}>
                                ici
                            </SimpleText>
                        </Pressable>
                        .
                    </SimpleText>
                )}
                {usePack && (
                    <>
                        <SimpleText style={styles.promoParagraph}>
                            A la fin de votre consultation, rendez-vous sur Kyvoitou.fr et
                            profitez de l'ensemble de nos services.
                        </SimpleText>
                        <KyvoitouHomeButton />
                    </>
                )}
                <SimpleText style={styles.promoParagraph}>Bonne consultation !</SimpleText>
                <SimpleText style={styles.promoParagraph}>L'équipe Kyvoitou.fr</SimpleText>
            </View>
        </View>
    );
}
