import React, {useState} from 'react';
import {View, StyleSheet, FlatList} from 'react-native';

import {TouchableOpacity} from 'react-native-gesture-handler';

import DateText from '~/components/common/Date';
import {GradeResume} from '~/components/common/GradeItem';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import {CommunicationType, ReviewData} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {filterEmpty, sorted} from '~/helpers/list';

import type {
    ExpertProfile_getExpert_reviews,
    ExpertProfile_getExpert_reviews_edges_node,
    ExpertProfile_getExpert_reviews_edges_node_qualities_edges,
} from '../../screens/types/ExpertProfile';
import PopupReadReview from '../user/client/review/PopupReadReview';

type ReviewsMobileProps = {
    reviews: ExpertProfile_getExpert_reviews | null | undefined;
    expertId: string | undefined;
};

export default function ReviewsMobile(props: ReviewsMobileProps) {
    const [reviewData, setReviewData] = useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: true,
    });
    const [modalReadReviewVisible, setModalReadReviewVisible] = useState(false);

    function onPressReview(review: ExpertProfile_getExpert_reviews_edges_node) {
        let communicationId = '';
        let communicationType = CommunicationType.CALL;
        if (review.call) {
            communicationId = review.call.id;
            communicationType = CommunicationType.CALL;
        } else if (review.message) {
            communicationId = review.message.id;
            communicationType = CommunicationType.MESSAGE;
        } else if (review.chat) {
            communicationId = review.chat.id;
            communicationType = CommunicationType.CHAT;
        }

        let reviewData: ReviewData = {
            communicationId: communicationId,
            communicationType: communicationType,
            date: review?.createdDate,
            expertId: props.expertId,
            clientMode: true,
        };

        setReviewData(reviewData);
        setModalReadReviewVisible(true);
    }

    function onRequestCloseReadReview() {
        setModalReadReviewVisible(false);
    }

    function getCreationDate(review: ExpertProfile_getExpert_reviews_edges_node) {
        return Date.parse(review.createdDate);
    }

    const reviews = filterEmpty(props.reviews?.edges?.map((r) => r?.node ?? null));
    const sortedReviews = sorted(reviews, getCreationDate, true);
    const firstReviews = sortedReviews.slice(0, 3);

    return (
        <>
            {firstReviews.length > 0 && (
                <View style={styles.bloc}>
                    <SimpleText style={styles.label}>Evaluations</SimpleText>
                    {firstReviews.map((r, i) => {
                        return (
                            <ReviewMobile
                                reviewContent={r}
                                onPress={onPressReview}
                                key={r.reviewId}
                                last={i == firstReviews.length - 1}
                            />
                        );
                    })}
                </View>
            )}
            <PopupReadReview
                visible={modalReadReviewVisible}
                onRequestClose={onRequestCloseReadReview}
                reviewData={reviewData}
                animationType={'none'}
            />
        </>
    );
}

export function ReviewMobile(props: ReviewMobileProps) {
    function isQuality(f: ExpertProfile_getExpert_reviews_edges_node_qualities_edges) {
        return f.node?.starNumber ? f.node?.starNumber > 2 : false;
    }

    const qualities = filterEmpty(props.reviewContent.qualities?.edges)
        .filter(isQuality)
        .map((edge) => {
            return edge.node?.quality ? edge.node?.quality?.label : '';
        });

    function renderQuality(item: {item: string}) {
        return (
            <View style={styles.quality}>
                <Icons.Validate
                    color={Colors.secondary}
                    style={{marginRight: 10, marginTop: 2}}
                    size={14}
                />
                <SimpleText>{item.item}</SimpleText>
            </View>
        );
    }

    let icon = 'phone-alt';
    if (props.reviewContent.call) {
        icon = 'phone-alt';
    } else if (props.reviewContent.chat) {
        icon = 'comments';
    } else if (props.reviewContent.message) {
        icon = 'envelope';
    }

    const date = new Date(props.reviewContent.createdDate);
    const cardStyle = props.last ? styles.lastCard : {};

    return (
        <TouchableOpacity onPress={() => props.onPress(props.reviewContent)}>
            <View style={[styles.reviewCard, cardStyle]}>
                <View style={styles.reviewResume}>
                    <View style={styles.reviewTitle}>
                        {Icons.getIcon(icon, 24, 'white', {
                            backgroundColor: Colors.secondary,
                            borderRadius: 5,
                            padding: 5,
                            justifyContent: 'center',
                        })}
                        <SimpleText style={styles.reviewClientDisplayName}>
                            {props.reviewContent.client?.profile?.displayName}
                        </SimpleText>
                        <SimpleText style={styles.reviewDate}>
                            <DateText date={date} />
                        </SimpleText>
                    </View>
                    <GradeResume
                        text={props.reviewContent.grade?.label || ''}
                        grade={props.reviewContent.gradeId}
                    />
                    <FlatList
                        data={qualities}
                        renderItem={renderQuality}
                        keyExtractor={(item, index) => `quality${index}`}
                    />
                </View>
                <View style={styles.reviewContent}>
                    <SimpleText style={styles.reviewText}>
                        {props.reviewContent.comment}
                    </SimpleText>
                </View>
            </View>
        </TouchableOpacity>
    );
}

type ReviewMobileProps = {
    reviewContent: ExpertProfile_getExpert_reviews_edges_node;
    onPress: (review: ExpertProfile_getExpert_reviews_edges_node) => void;
    last: boolean;
};

const styles = StyleSheet.create({
    bloc: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: Colors.disabled,
        marginVertical: 15,
    },
    label: {
        backgroundColor: Colors.secondary,
        color: 'white',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: 'center',
        padding: 4,
    },
    reviewCard: {
        borderStyle: 'dashed',
        borderBottomColor: Colors.secondary,
        borderBottomWidth: 1,
    },
    lastCard: {
        borderBottomWidth: 0,
    },
    reviewResume: {
        backgroundColor: Colors.dark,
        padding: 10,
    },
    reviewTitleCard: {
        flexDirection: 'row',
    },
    reviewTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    reviewDate: {
        alignSelf: 'center',
    },
    reviewClientDisplayName: {
        alignSelf: 'center',
    },
    reviewContent: {
        padding: 15,
    },
    quality: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 10,
    },
    reviewText: {
        textAlign: 'left',
    },
});
