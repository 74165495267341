import Settings, {endpoints} from '~/constants/Settings';
import logEvent from '~/helpers/analytics';

import {axios} from './network';

const NEWSLETTER_REQUEST_OPTIONS = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'x-apikey': Settings.api_key_newsletter,
    },
};

export async function subscription(email: string) {
    var body = JSON.stringify({
        email: email,
    });

    axios.post(endpoints.newsletter, body);

    const fetch_options = {body, ...NEWSLETTER_REQUEST_OPTIONS};
    fetch(Settings.url_newsletter, fetch_options);

    logEvent('newsletter_subscribed');
}
