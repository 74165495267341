import * as React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {ErrorMessage} from 'formik';

import * as Colors from '~/constants/Colors';

export type TextProps = Text['props'];

export function SimpleText({style, ...props}: TextProps) {
    return <Text {...props} style={[style, {fontFamily: 'Montserrat_500Medium'}]} />;
}

export function Bold({style, ...props}: TextProps) {
    return <SimpleText style={[{fontWeight: 'bold'}, style]} {...props} />;
}

export function Underlined({style, ...props}: TextProps) {
    return <SimpleText style={[{textDecorationLine: 'underline'}, style]} {...props} />;
}

export function AlignedText({children, alignItems, ...otherProps}: AlignedTextProps) {
    return (
        <SimpleText {...otherProps}>
            <View style={{flexDirection: 'row', alignItems: alignItems}} children={children} />
        </SimpleText>
    );
}

export function CenterText(props: TextProps) {
    return <AlignedText alignItems="center" {...props} />;
}

export function FormLabel({style, ...otherProps}: TextProps) {
    return <SimpleText style={[styles.formLabel, style]} {...otherProps} />;
}

type MobileTitleProps = {
    title: string;
    separator?: boolean;
};

export function MobileTitle(props: MobileTitleProps) {
    const separator = props.separator == undefined || props.separator;
    return (
        <>
            <SimpleText accessibilityRole="header" aria-level="1" style={mobileStyles.title}>
                {props.title}
            </SimpleText>
            {separator && <SimpleText style={mobileStyles.separator}></SimpleText>}
        </>
    );
}

type AlignedTextProps = TextProps & {alignItems: Alignment};
type Alignment = 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch';

type FormikErrorMessageProps = {
    field: string;
};

export function FormikErrorMessage(props: FormikErrorMessageProps) {
    return (
        <Text style={styles.error}>
            <ErrorMessage name={props.field} className="formik-error" />
        </Text>
    );
}

const styles = StyleSheet.create({
    formLabel: {
        fontSize: 14,
        marginBottom: 10,
        marginTop: 30,
        marginLeft: 10,
    },
    error: {
        fontSize: 12,
        color: Colors.formik,
        margin: 5,
        fontFamily: 'BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        alignSelf: 'flex-start',
        textAlign: 'left',
    },
});

const mobileStyles = StyleSheet.create({
    title: {
        paddingVertical: 25,
        fontSize: 32,
        fontWeight: 'bold',
        alignSelf: 'center',
        textAlign: 'center',
        backgroundColor: Colors.dark,
    },
    separator: {
        borderBottomColor: Colors.secondary,
        borderBottomWidth: 2,
        width: 100,
        alignSelf: 'center',
    },
});
