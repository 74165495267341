import React, {useState} from 'react';
import {StyleSheet, TouchableOpacity, FlatList, Image, ViewStyle, View} from 'react-native';

import {useFocusEffect} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {endpoints} from '~/constants/Settings';
import * as dates from '~/helpers/dates';
import removeHtmlTags from '~/helpers/regex';
import getCachedUrlContent from '~/helpers/storage';
import useDeviceQuery from '~/hooks/useDeviceQuery';

type Post = {
    id: string;
    link: string;
    title: string;
    content: string;
    category: string;
    date: string;
    image: string;
};

const WORDPRESS_MEDIA = 'wp:featuredmedia';
const WORDPRESS_TERM = 'wp:term';
const WORDPRESS_CATEGORY = 'category';
const WORDPRESS_TAG = 'post_tag';
const DEFAULT_CATEGORY = 'Non classé';

export default function Posts() {
    const {isDesktop} = useDeviceQuery();

    const [posts, setPosts] = useState<Post[]>([]);

    useFocusEffect(
        React.useCallback(() => {
            const requestId = requestAnimationFrame(load);
            return () => cancelAnimationFrame(requestId);
        }, [])
    );

    function findImage(raw, n: number) {
        let image = '';
        if (raw._embedded && raw._embedded[WORDPRESS_MEDIA]) {
            for (let i = 0; i < raw._embedded[WORDPRESS_MEDIA].length; i++) {
                const media = raw._embedded[WORDPRESS_MEDIA][i];
                if (media.source_url) {
                    image = media.source_url;
                }
            }
        }

        if (image == '') {
            return Settings.defaultImagePost(n);
        }

        return image;
    }

    function findCategory(raw) {
        let category = '';
        if (raw._embedded && raw._embedded[WORDPRESS_TERM]) {
            for (let i = 0; i < raw._embedded[WORDPRESS_TERM].length; i++) {
                const terms = raw._embedded[WORDPRESS_TERM][i];
                for (let j = 0; j < terms.length; j++) {
                    const term = terms[j];
                    if (
                        (term.taxonomy == WORDPRESS_CATEGORY ||
                            term.taxonomy == WORDPRESS_TAG) &&
                        term.name != DEFAULT_CATEGORY
                    ) {
                        category = term.name;
                    }
                }
            }
        }

        if (category == '') {
            return DEFAULT_CATEGORY;
        }

        return category;
    }

    async function load() {
        const data = await getCachedUrlContent(endpoints.wordpress);

        let posts = [];
        for (let idx = 0; idx < data.length; idx++) {
            const raw = data[idx];
            const createdDate = new Date(raw.date);

            const post: Post = {
                id: raw.id,
                link: raw.link,
                title: removeHtmlTags(raw.title.rendered),
                content: removeHtmlTags(raw.excerpt.rendered),
                date: dates.dateString(createdDate),
                category: findCategory(raw),
                image: findImage(raw, idx + 1),
            };
            posts.push(post);
        }

        setPosts(posts);
    }

    function readPost(item: Post) {
        window.open(item.link);
    }

    const desktopStyle = {
        flexDirection: 'row',
        justifyContent: 'space-between',
    } as ViewStyle;

    const mobileStyle = {
        flexDirection: 'column',
        alignItems: 'center',
    } as ViewStyle;

    const style = isDesktop ? desktopStyle : mobileStyle;
    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <View style={deviceStyles.blogPostsView}>
            <PostTitle />
            {
                <FlatList
                    keyExtractor={(item) => item.id.toString()}
                    data={posts}
                    renderItem={({item}) => (
                        <TouchableOpacity
                            onPress={() => readPost(item)}
                            style={styles.postContainer}
                        >
                            <SimpleText style={styles.postCategory}>
                                {item.category}
                            </SimpleText>
                            <Image
                                style={styles.postImage}
                                source={{uri: item.image}}
                                accessibilityLabel={item.title}
                            />
                            <SimpleText style={styles.postDate}>{item.date}</SimpleText>
                            <View style={styles.titleFrame}>
                                <SimpleText style={styles.postTitle} numberOfLines={2}>
                                    {item.title}
                                </SimpleText>
                            </View>
                            <SimpleText style={deviceStyles.contentFrame}>
                                <SimpleText style={styles.postContent} numberOfLines={4}>
                                    {item.content}
                                </SimpleText>
                            </SimpleText>
                            <Button
                                title="Lire Plus"
                                buttonStyle={{width: 160}}
                                onPress={() => readPost(item)}
                                containerStyle={{alignSelf: 'flex-start'}}
                                style={{marginLeft: 0}}
                            />
                        </TouchableOpacity>
                    )}
                    contentContainerStyle={style}
                />
            }
        </View>
    );
}

function PostTitle() {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <>
            <SimpleText style={deviceStyles.title}>Les 3 derniers articles</SimpleText>
            <SimpleText style={deviceStyles.subtitle}>
                Retrouvez tous nos articles sur l'ésotérisme et des guidances gratuites sur le{' '}
                <TouchableOpacity onPress={() => window.open(Settings.url_blog)}>
                    <SimpleText>blog.kyvoitou.fr</SimpleText>
                </TouchableOpacity>
            </SimpleText>
        </>
    );
}

const styles = StyleSheet.create({
    title: {
        paddingTop: 45,
        fontSize: 42,
        alignSelf: 'center',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        color: Colors.secondary,
    },
    subtitle: {
        paddingBottom: 45,
        fontSize: 20,
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
    },
    postContainer: {
        maxWidth: 320,
    },
    postCategory: {
        color: Colors.category,
        borderBottomWidth: 1,
        borderBottomColor: Colors.category,
        fontWeight: 'bold',
        paddingBottom: 10,
        marginBottom: 15,
    },
    postImage: {
        resizeMode: 'cover',
        height: 190,
    },
    postDate: {
        fontSize: 12,
        color: Colors.fade,
        paddingTop: 10,
        paddingBottom: 5,
    },
    titleFrame: {
        height: 50,
    },
    postTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        paddingVertical: 5,
    },
    contentFrame: {
        height: 58,
    },
    postContent: {
        fontSize: 12,
    },
    blogPostsView: {},
});

const mobileStyles = StyleSheet.create({
    title: {
        paddingTop: 25,
        fontSize: 26,
        alignSelf: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        color: Colors.secondary,
    },
    subtitle: {
        paddingBottom: 25,
        fontSize: 16,
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
    },
    blogPostsView: {
        paddingHorizontal: 10,
    },
    contentFrame: {},
});
