import React, {useState, useCallback, useRef} from 'react';
import {StyleSheet, Image, TouchableOpacity, View, ScrollView} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {SimpleText, MobileTitle} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {sorted, filterEmpty} from '~/helpers/list';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {GetFaqQuery, GetFaqQuery_faqCategories_edges} from './types/GetFaqQuery';

const GET_FAQ_QUERY = gql`
    query GetFaqQuery {
        faqCategories {
            edges {
                node {
                    faqCategoryId
                    label
                    description
                    order
                    faqs {
                        edges {
                            node {
                                question
                                answer
                                top
                                order
                            }
                        }
                    }
                }
            }
        }
    }
`;

type Category = {
    id: string;
    label: string;
    description: string;
    order: number;
};

type Faq = {
    question: String;
    answer: String;
    order: number;
};

export default function FaqScreen() {
    const [category, setCategory] = useState<Category | null>(null);
    const [load, {called, loading, data, refetch}] = useLazyQuery<GetFaqQuery>(GET_FAQ_QUERY);
    const _scrollView = useRef<ScrollView>(null);

    useFocusEffect(
        useCallback(() => {
            requestAnimationFrame(execute);
        }, [])
    );

    function execute() {
        if (!loading && !data) {
            load();
        }
    }

    function back() {
        setCategory(null);
    }

    function onPress(category: Category) {
        setCategory(category);
        _scrollView?.current?.scrollTo({x: 0, y: 0, animated: false});
    }

    if (!data) {
        return <></>;
    }

    function getCategoryOrder(category: Category) {
        return category.order;
    }

    function getFaqOrder(faq: Faq) {
        return faq.order;
    }

    const categories = data.faqCategories?.edges?.map(
        (edge: GetFaqQuery_faqCategories_edges | null) => {
            return {
                id: edge?.node?.faqCategoryId ?? '',
                label: edge?.node?.label ?? '',
                description: edge?.node?.description ?? '',
                order: edge?.node?.order ?? 0,
            } as Category;
        }
    );

    const sortedCategories = sorted(filterEmpty(categories), getCategoryOrder, false);

    const faqs = [] as Faq[];
    if (data.faqCategories?.edges) {
        for (var i = 0; i < data.faqCategories?.edges.length; i++) {
            const cat = data.faqCategories?.edges[i];

            if (cat?.node?.faqs?.edges) {
                for (var j = 0; j < cat?.node?.faqs?.edges.length; j++) {
                    const faq = cat?.node?.faqs?.edges[j];

                    const foundCategory =
                        category != null && category.id == cat?.node?.faqCategoryId;
                    const foundTop = category == null && faq?.node?.top;

                    if (foundCategory || foundTop) {
                        faqs.push({
                            question: faq?.node?.question ?? '',
                            answer: faq?.node?.answer ?? '',
                            order: faq?.node?.order ?? 0,
                        } as Faq);
                    }
                }
            }
        }
    }

    const sortedFaqs = sorted(filterEmpty(faqs), getFaqOrder, false);

    return (
        <MainView ref={_scrollView}>
            <FaqTitle />
            {category != null && <CategoryTitle back={back} category={category} />}
            <Faqs faqs={sortedFaqs} />
            <Categories categories={sortedCategories} onPress={onPress} />
        </MainView>
    );
}

type CategoryTitleProps = {
    category: Category;
    back: () => void;
};

function CategoryTitle(props: CategoryTitleProps) {
    const shadow = {
        shadowColor: Colors.secondary,
        shadowOpacity: 0.16,
        shadowRadius: 10,
        shadowOffset: {
            height: 0,
            width: 0,
        },
    };

    return (
        <View style={styles.return}>
            <Button
                title=""
                icon={<Icons.Return size={44} color={'white'} />}
                buttonStyle={styles.back}
                onPress={props.back}
                style={styles.margin}
            />
            <SimpleText style={styles.category}>{props.category.label}</SimpleText>
        </View>
    );
}

type FaqsProps = {
    faqs: Faq[];
};

function Faqs(props: FaqsProps) {
    const {isDesktop} = useDeviceQuery();

    const style = isDesktop ? styles.brick : mobileStyles.brick;

    function renderFaq(faq: Faq) {
        return (
            <View style={style} key={faq.order}>
                <SimpleText style={styles.brick_title}>{faq.question}</SimpleText>
                <SimpleText>{faq.answer}</SimpleText>
            </View>
        );
    }

    if (props.faqs.length > 0) {
        return (
            <View style={styles.brick_container}>
                {props.faqs && props.faqs.map(renderFaq)}
            </View>
        );
    } else {
        return (
            <SimpleText style={styles.brick_title}>
                Il n'y a aucune question / réponse dans cette rubrique !
            </SimpleText>
        );
    }
}

type CategoriesProps = {
    categories: Category[];
    onPress: (category: Category) => void;
};

function Categories(props: CategoriesProps) {
    const {isDesktop} = useDeviceQuery();

    const style = isDesktop ? styles.bloc : mobileStyles.bloc;

    function renderCategory(category: Category) {
        return (
            <TouchableOpacity
                style={style}
                onPress={() => props.onPress(category)}
                key={category.id}
            >
                <Image
                    style={styles.bloc_image}
                    source={{
                        uri: Settings.getUrlImageFaq(category.id),
                    }}
                    accessibilityLabel={category.label}
                />
                <SimpleText style={styles.bloc_title}>{category.label}</SimpleText>
                <SimpleText style={styles.bloc_description}>{category.description}</SimpleText>
            </TouchableOpacity>
        );
    }

    return (
        <>
            <View style={styles.second_title_container}>
                <SimpleText style={styles.title}>Toutes Les Catégories</SimpleText>
            </View>
            <View style={styles.bloc_container}>
                {props.categories && props.categories.map(renderCategory)}
                <View style={style}>
                    <Image
                        style={styles.bloc_image}
                        source={{
                            uri: Settings.getUrlImageFaqContact(),
                        }}
                        accessibilityLabel={'Contactez-nous'}
                    />
                    <SimpleText style={styles.bloc_title}>
                        Vous ne trouvez pas ce que vous cherchez ?
                    </SimpleText>
                    <SimpleText style={styles.bloc_description}>
                        Vous pouvez nous contacter par téléphone au{' '}
                        {Settings.support_telephone} ou par email sur {Settings.support_mail}.
                    </SimpleText>
                </View>
            </View>
        </>
    );
}

function FaqTitle() {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return (
            <>
                <PageTitle>Comment pouvons nous vous aidez ?</PageTitle>
                <View style={styles.title_container}>
                    <Image
                        style={styles.title_image}
                        source={{
                            uri: Settings.getUrlPictoFaqStar(),
                        }}
                        accessibilityLabel={'Comment pouvons nous vous aidez ?'}
                    />
                    <SimpleText style={styles.title}>Questions Fréquemment Posées</SimpleText>
                </View>
            </>
        );
    } else {
        return (
            <>
                <MobileTitle title={'Comment pouvons nous vous aidez ?'} />
                <View style={mobileStyles.title_container}>
                    <Image
                        style={mobileStyles.title_image}
                        source={{
                            uri: Settings.getUrlPictoFaqStar(),
                        }}
                        accessibilityLabel={'Comment pouvons nous vous aidez ?'}
                    />
                    <SimpleText style={mobileStyles.title}>
                        Questions Fréquemment Posées
                    </SimpleText>
                </View>
            </>
        );
    }
}

const styles = StyleSheet.create({
    title_container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    title_image: {
        marginRight: 50,
        marginTop: 4,
        width: 21,
        height: 21,
    },
    title: {
        color: Colors.highlight,
        fontSize: 24,
        marginBottom: 40,
        fontWeight: 'bold',
    },
    second_title_container: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 40,
    },
    brick_title: {
        fontSize: 18,
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 10,
    },
    brick_container: {
        justifyContent: 'center',
    },
    brick: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.dark,
        borderRadius: 10,
        padding: 20,
        marginVertical: 10,
        marginHozirontal: 50,
    },
    bloc_container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bloc: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.dark,
        borderRadius: 10,
        padding: 20,
        width: 350,
        height: 502,
        margin: 10,
    },
    bloc_image: {
        width: 259,
        height: 292,
    },
    bloc_title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 45,
        textAlign: 'center',
    },
    bloc_description: {
        fontSize: 14,
        marginTop: 30,
        textAlign: 'center',
    },
    category: {
        color: Colors.highlight,
        fontSize: 24,
        marginLeft: 20,
    },
    return: {
        flexDirection: 'row',
        padding: 20,
        backgroundColor: Colors.dark,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 25,
    },
    back: {
        width: 60,
        height: 60,
        borderRadius: 30,
        alignSelf: 'center',
        backgroundColor: Colors.primary,
    },
    margin: {
        margin: 0,
        marginHorizontal: 0,
    },
});

const mobileStyles = StyleSheet.create({
    title_container: {
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    title_image: {
        width: 21,
        height: 21,
        textAlign: 'center',
        margin: 15,
    },
    title: {
        color: Colors.highlight,
        fontSize: 24,
        marginBottom: 40,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    brick: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        margin: 10,
    },
    bloc: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        width: 350,
        height: 502,
        margin: 10,
    },
});
