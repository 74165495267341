import * as React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {ViewProps} from '~/components/common/Containers';
import HoverableView from '~/components/common/HoverableView';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useConfirm} from '~/contexts/ConfirmContext';
import * as dates from '~/helpers/dates';
import {axios} from '~/helpers/network';
import type {QueryMe_me_identity_selectedPaymentMethod as StripeCard} from '~/queries/types/QueryMe';

export function Card({
    stripe: card,
    onPress,
    style,
    children,
    preview,
    selected,
    onDelete,
}: CardProps) {
    if (!card || !card.lastFourDigits) {
        return null;
    }

    function onCardPress() {
        onPress && onPress(card);
    }

    const selectedStyle = selected ? {borderColor: 'black'} : {};

    if (!onPress) {
        return (
            <>
                <View style={[styles.nonPressableCard, selectedStyle]}>
                    <CardView stripe={card} style={style} preview={preview}>
                        {children}
                    </CardView>
                </View>
                {!preview && <CardDate card={card} />}
            </>
        );
    }

    return (
        <>
            <HoverableView
                style={[styles.pressableCard, selectedStyle]}
                hoverStyle={styles.pressableCardHover}
            >
                <TouchableOpacity onPress={onCardPress}>
                    <CardView
                        stripe={card}
                        style={style}
                        preview={preview}
                        onDelete={onDelete}
                    >
                        {children}
                    </CardView>
                </TouchableOpacity>
            </HoverableView>
            {!preview && <CardDate card={card} />}
        </>
    );
}

type CardDateProps = {
    card: StripeCard;
};

function CardDate({card}: CardDateProps) {
    const date = new Date(card?.created);

    return (
        <SimpleText style={styles.date}>
            Ajoutée le {dates.dateString(date)} à {dates.timeString(date)}
        </SimpleText>
    );
}

function CardView({stripe: card, style, children, preview, onDelete}: CardViewProps) {
    const paymentIcon = ICONS.get(card?.paymentType || '') ?? DEFAULT_ICON;
    const confirm = useConfirm();
    const alert = useAlert();

    async function deleteCard() {
        const body = JSON.stringify({session_id: card?.sessionId});
        await axios.post(endpoints.deleteCard, body);
        alert({
            message: 'Votre carte a bien été supprimée.',
        });
        onDelete && onDelete();
    }

    async function onCardDelete() {
        confirm({
            title: 'Attention',
            message: 'Voulez vous réellement supprimer cette carte ?',
            yesText: 'Supprimer',
            noText: 'Annuler',
            onYes: () => {
                deleteCard();
            },
        });
    }

    return (
        <View style={styles.cardView}>
            <View style={[styles.cardInfoView, style]}>
                <View style={styles.cardIcon}>
                    {Icons.getIcon(paymentIcon, 40, Colors.secondary, {}, 'fab')}
                </View>
                <View>
                    <CardNumber {...card} />
                    <CardExpiration {...card} />
                </View>
                {!preview && (
                    <View style={styles.trashIcon}>
                        <TouchableOpacity onPress={onCardDelete}>
                            <Icons.Trash size={18} color={Colors.secondary} />
                        </TouchableOpacity>
                    </View>
                )}
            </View>
            {children}
        </View>
    );
}

export function CardNumber({lastFourDigits}: CardNumberProps) {
    if (!lastFourDigits) {
        console.error('CardNumber requires "lastFourDigits".');
        return <SimpleText>Numéro de carte inconnu</SimpleText>;
    }
    return (
        <SimpleText>
            <SimpleText style={styles.altText}>**** **** ****</SimpleText> {lastFourDigits}
        </SimpleText>
    );
}

export function CardExpiration({expirationMonth, expirationYear}: CardExpirationProps) {
    if (!expirationMonth || !expirationYear) {
        console.error('CardExpiration requires both "expirationMonth" and "expirationYear".');
        return <SimpleText>Date d'expiration inconnue</SimpleText>;
    }
    let month = expirationMonth;
    if (month?.length == 1) {
        month = '0' + month;
    }
    return (
        <SimpleText style={styles.altText}>
            Expire le {month}/{expirationYear}
        </SimpleText>
    );
}

// Stripe accepted cards: https://stripe.com/docs/card-brand-choice#card-brands
// FontAwesome5 card icons: https://fontawesome.com/icons?d=gallery&p=2&q=cc-&m=free
export const DEFAULT_ICON = 'credit-card';
export const ICONS = new Map([
    ['visa', 'cc-visa'],
    ['cartes_bancaires', 'cc-visa'],
    ['mastercard', 'cc-mastercard'],
    ['amex', 'cc-amex'],
    ['diners_club', 'cc-diners-club'],
    ['discover', 'cc-discover'],
    ['jcb', 'cc-jcb'],
    ['unionpay', 'cc-visa'],
]);

export type CardFunction = (card: StripeCard | null | undefined) => void;
export type CardViewProps = ViewProps & {
    preview: boolean;
    onDelete?: () => void;
    stripe: StripeCard | null | undefined;
};
export type CardProps = CardViewProps & {
    selected?: boolean;
    onPress?: CardFunction;
};
export type CardExpirationProps = {
    expirationMonth?: string | null;
    expirationYear?: string | null;
};
export type CardNumberProps = {
    lastFourDigits?: string | null;
};

const HOVER_BORDER_COLOR = Colors.colorOpacity(Colors.primary, 'ff');

const styles = StyleSheet.create({
    nonPressableCard: {
        alignSelf: 'center',
        borderRadius: 10,
        borderWidth: 1,
        marginTop: 15,
    },
    pressableCard: {
        alignSelf: 'center',
        borderRadius: 10,
        backgroundColor: Colors.colorOpacity(Colors.negative, '99'),
        borderColor: Colors.shadow,
        borderWidth: 1,
        marginTop: 15,
    },
    pressableCardHover: {
        backgroundColor: 'white',
        borderColor: HOVER_BORDER_COLOR,
    },
    cardView: {
        justifyContent: 'center',
        paddingHorizontal: 35,
        paddingTop: 10,
        paddingBottom: 10,
    },
    cardInfoView: {
        flexDirection: 'row',
        alignContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        flexGrow: 0,
    },
    cardIcon: {paddingRight: 20},
    trashIcon: {paddingLeft: 20},
    altText: {opacity: 0.7},
    date: {
        fontSize: 10,
        alignSelf: 'center',
        paddingTop: 5,
        marginBottom: 10,
    },
});
