import React, {useEffect, useState} from 'react';
import {View, StyleSheet} from 'react-native';

import {gql, useQuery, useMutation} from '@apollo/client';
import {useNavigation} from '@react-navigation/native';
import {Formik, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SubjectInput, ContentInput} from '~/components/common/Inputs';
import {SimpleText, FormLabel} from '~/components/common/Texts';
import {ScreenLoader} from '~/components/navigation/Loader';
import MessageHeader from '~/components/user/client/message/MessageHeader';
import UploadAttachments from '~/components/user/client/message/UploadAttachments';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent, {purchase} from '~/helpers/analytics';
import {EnumModeMessageBox, ExchangeTypeEnum, getTypeLabel} from '~/helpers/message';
import {axios} from '~/helpers/network';
import yup, {yupRules, YupTypes} from '~/helpers/yup';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import {sendMessage} from './types/sendMessage';
import {sendMessageGroup} from './types/sendMessageGroup';

export const GROUP = 'GROUP';

type NewMessageProps = {
    expertId: string;
    messageId: string;
    messageType: MessageTypeEnum;
};

const SEND_MESSAGE = gql`
    mutation sendMessage(
        $exchangeType: String!
        $messageId: String!
        $recieverId: String!
        $messageType: String!
        $subject: String!
        $content: String!
        $attachments: [String]
    ) {
        sendMessage(
            input: {
                exchangeType: $exchangeType
                messageId: $messageId
                recieverId: $recieverId
                messageType: $messageType
                subject: $subject
                content: $content
                attachments: $attachments
            }
        ) {
            message {
                id
                messageId
                cost
            }
            exchange {
                id
            }
        }
    }
`;

const SEND_MESSAGE_GROUP = gql`
    mutation sendMessageGroup($subject: String!, $content: String!, $attachments: [String]) {
        sendMessageGroup(
            input: {subject: $subject, content: $content, attachments: $attachments}
        ) {
            messageGroup {
                id
                messageGroupId
            }
        }
    }
`;

export default function NewMessage(props: NewMessageProps) {
    const expertId = props.expertId;
    const messageId = props.messageId;
    const messageType = props.messageType;
    const {loadingMe, me} = React.useContext(UserContext);
    const navigation = useNavigation();
    const cleanParams = {expert_id: undefined, message_id: undefined, type: undefined};

    async function onSuccess() {
        navigation.navigate('MessageScreen', cleanParams);
    }

    async function onCancel() {
        const body = JSON.stringify({message_id: messageId});
        axios.post(endpoints.cancelMessageIntent, body);
        navigation.setParams(cleanParams);

        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate('MessageScreen', cleanParams);
        }
    }

    if (expertId && messageType) {
        const variables = {id: expertId};
        const {loading, data} = useQuery(EXPERT_PROFILE_QUERY, {
            variables,
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
            skip: !expertId,
        });

        if ((loading && !data) || loadingMe) {
            return <ScreenLoader />;
        }

        if (!data) {
            return <SimpleText>Aucune information sur ce spirite</SimpleText>;
        }

        const user = data.user;
        const profile = user?.profile;
        const displayName = profile?.displayName;
        const pictureName = profile?.pictureName;

        return (
            <MainView>
                <MessageHeader mode={EnumModeMessageBox.CLIENT} />
                <MessageForm
                    exchangeType={ExchangeTypeEnum.CLIENT_TO_EXPERT}
                    recieverId={expertId}
                    messageId={messageId}
                    displayName={displayName}
                    pictureName={pictureName}
                    messageType={messageType}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                />
            </MainView>
        );
    }

    return <SimpleText>Paramètre incorrect.</SimpleText>;
}

type MessageFormProps = {
    exchangeType: ExchangeTypeEnum;
    recieverId: string;
    messageId: string;
    pictureName: string;
    displayName: string;
    messageType: MessageTypeEnum;
    onSuccess: () => void;
    onCancel: () => void;
};

export function MessageForm(props: MessageFormProps) {
    const alert = useAlert();
    const {isDesktop, isMobile} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const [sendMessage, {error: sendMessageError}] = useMutation<sendMessage>(SEND_MESSAGE, {
        errorPolicy: 'all',
    });
    const [sendMessageGroup] = useMutation<sendMessageGroup>(SEND_MESSAGE_GROUP, {
        errorPolicy: 'all',
    });
    const [attachments, setAttachments] = useState<string[]>([]);

    useEffect(() => {
        if (sendMessageError) {
            console.log('sendMessageError : ', sendMessageError?.message);
        }
    }, [sendMessageError]);

    type Inputs = Pick<YupTypes, 'subject' | 'content'>;
    type Helpers = FormikHelpers<Inputs>;

    const group = props.recieverId == GROUP;

    async function submitMessage(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        if (group) {
            const data = {
                subject: values.subject,
                content: values.content,
                attachments: attachments,
            };

            try {
                const result = await sendMessageGroup({variables: data});
                const id = result.data?.sendMessageGroup?.messageGroup?.id;
                if (id) {
                    logEvent('message_sent');
                    alert({
                        title: 'Information',
                        message: 'Votre message a bien été envoyé.',
                        onClose: () => props.onSuccess(),
                    });
                    return;
                }
            } catch (e) {
                console.log('sendMessageGroup::catch ', e);
                alert({
                    title: 'Information',
                    message:
                        "Une erreur s'est produite lors de l'envoi de votre message, veuillez ré-essayer plus tard.",
                });
            }
        } else {
            const data = {
                exchangeType: props.exchangeType,
                messageId: props.messageId,
                recieverId: props.recieverId,
                messageType: props.messageType,
                subject: values.subject,
                content: values.content,
                attachments: attachments,
            };
            try {
                const result = await sendMessage({variables: data});
                const id = result.data?.sendMessage?.message?.messageId;
                const cost = result.data?.sendMessage?.message?.cost;
                if (id) {
                    if (cost) {
                        purchase(id, 'message', cost);
                    }
                    logEvent('message_sent');
                    alert({
                        title: 'Information',
                        message: 'Votre message a bien été envoyé.',
                        onClose: () => props.onSuccess(),
                    });
                    // Because sometimes the popup is behind the form modal
                    setTimeout(() => props.onSuccess(), 500);
                    return;
                }
            } catch (e) {
                console.log('sendMessage::catch ', e);
                alert({
                    title: 'Information',
                    message:
                        "Une erreur s'est produite lors de l'envoi de votre message, veuillez ré-essayer plus tard.",
                });
            }
        }
    }

    function cancel() {
        props.onCancel();
    }

    const typeLabel = getTypeLabel(props.messageType, group);

    const {subject, content} = yupRules;
    const validationSchema = yup.object().shape({subject, content});

    return (
        <View style={deviceStyles.newMessageContainer}>
            {isDesktop && (
                <View style={styles.headerContainer}>
                    <ProfilePicture
                        style={{borderRadius: 50, width: 50, height: 50}}
                        pictureName={props.pictureName}
                    />
                    <SimpleText style={styles.newMessage}>
                        Envoyer un nouveau message
                        <SimpleText style={styles.italic}> {typeLabel} </SimpleText>
                        <SimpleText>à {props.displayName}</SimpleText>
                    </SimpleText>
                </View>
            )}
            <Formik
                initialValues={{
                    subject: '',
                    content: '',
                }}
                onSubmit={submitMessage}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <View style={deviceStyles.formContainer}>
                        <View style={deviceStyles.subjectContainer}>
                            {isMobile && (
                                <View style={mobileStyles.toField}>
                                    <SimpleText>
                                        À{' '}
                                        <SimpleText style={mobileStyles.toFieldName}>
                                            {props.displayName}
                                        </SimpleText>
                                    </SimpleText>
                                </View>
                            )}
                            {isDesktop && <FormLabel>Objet</FormLabel>}
                            <SubjectInput
                                value={formikProps.values.subject}
                                placeholder={'Objet'}
                                onChangeText={formikProps.handleChange('subject')}
                                onBlur={() => formikProps.setFieldTouched('subject')}
                                errorMessage={formikProps.errors.subject || ' '}
                                style={[deviceStyles.input, deviceStyles.subjectInput]}
                                containerStyle={mobileStyles.container}
                                inputContainerStyle={deviceStyles.inputContainer}
                                errorStyle={mobileStyles.error}
                            />
                        </View>
                        <View style={deviceStyles.contentContainer}>
                            {isDesktop && <FormLabel>Sujet</FormLabel>}
                            <ContentInput
                                value={formikProps.values.content}
                                placeholder={'Rédiger votre message ...'}
                                onChangeText={formikProps.handleChange('content')}
                                onBlur={() => formikProps.setFieldTouched('content')}
                                errorMessage={formikProps.errors.content || ' '}
                                style={[deviceStyles.input, deviceStyles.contentInput]}
                                containerStyle={mobileStyles.container}
                                inputContainerStyle={deviceStyles.inputContainer}
                                errorStyle={mobileStyles.error}
                            />
                        </View>
                        <UploadAttachments
                            setAttachments={setAttachments}
                            buttonBackgroundColor={'white'}
                        />
                        <View style={styles.buttons}>
                            <Button
                                title="Envoyer"
                                icon={<Icons.MailSend size={16} style={{paddingRight: 10}} />}
                                buttonStyle={{width: 160, alignSelf: 'center', marginTop: 10}}
                                disabled={!formikProps.isValid}
                                onPress={() => formikProps.handleSubmit()}
                                loading={formikProps.isSubmitting}
                            />
                            <Button
                                title="Annuler"
                                buttonStyle={{width: 160, alignSelf: 'center', marginTop: 10}}
                                onPress={cancel}
                                loading={formikProps.isSubmitting}
                            />
                        </View>
                    </View>
                )}
            </Formik>
        </View>
    );
}

const EXPERT_PROFILE_QUERY = gql`
    query ExpertProfilePicture($id: ID!) {
        user(id: $id) {
            id
            profile {
                displayName
                pictureName
            }
        }
    }
`;

const styles = StyleSheet.create({
    newMessageContainer: {
        width: 700,
        padding: 20,
        alignSelf: 'center',
        backgroundColor: Colors.dark,
        marginLeft: 20,
    },
    formContainer: {
        alignSelf: 'center',
        backgroundColor: 'white',
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
        marginBottom: 20,
    },
    newMessage: {
        fontSize: 16,
        fontWeight: 'bold',
        paddingTop: 10,
        paddingLeft: 25,
    },
    subjectContainer: {},
    subjectLabel: {
        width: 75,
    },
    container: {},
    inputContainer: {
        width: 600,
        borderBottomWidth: 0,
    },
    contentContainer: {},
    contentLabel: {
        width: 75,
    },
    input: {
        backgroundColor: 'white',
        borderColor: Colors.primary,
        borderRadius: 10,
        borderWidth: 1,
        padding: 5,
    },
    subjectInput: {
        height: 50,
    },
    contentInput: {
        height: 300,
    },
    buttons: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    italic: {
        fontStyle: 'italic',
    },
});

const mobileStyles = StyleSheet.create({
    newMessageContainer: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 600,
        marginTop: 30,
        borderStartColor: 'white',
        padding: 0,
    },
    formContainer: {
        backgroundColor: 'white',
    },
    container: {
        paddingHorizontal: 0,
    },
    inputContainer: {
        borderBottomWidth: 0,
    },
    subjectContainer: {},
    contentContainer: {},
    input: {
        backgroundColor: 'white',
        borderColor: Colors.separator,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 0,
        padding: 5,
    },
    toField: {
        borderTopColor: Colors.separator,
        borderTopWidth: 1,
        minHeight: 40,
        paddingVertical: 10,
        paddingHorizontal: 30,
    },
    toFieldName: {
        marginLeft: 20,
        fontSize: 14,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
    },
    subjectInput: {
        height: 50,
    },
    contentInput: {
        height: 300,
    },
    error: {
        minHeight: 40,
        paddingVertical: 10,
        paddingRight: 20,
        paddingLeft: 30,
        margin: 0,
        fontSize: 10,
    },
});
