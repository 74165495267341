/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum CallStatusEnum {
  BOTH_PARTIES_JOINED = "BOTH_PARTIES_JOINED",
  CANCELED = "CANCELED",
  CLIENT_HANG_UP = "CLIENT_HANG_UP",
  ERROR = "ERROR",
  EXPERT_HANG_UP = "EXPERT_HANG_UP",
  EXPERT_JOINED = "EXPERT_JOINED",
  FINISHED = "FINISHED",
  STARTED = "STARTED",
  TERMINATED_BY_CRON = "TERMINATED_BY_CRON",
}

/**
 * An enumeration.
 */
export enum ChatStatusEnum {
  ENDED = "ENDED",
  INITIALIZED = "INITIALIZED",
  STARTED = "STARTED",
  TERMINATED_BY_CRON = "TERMINATED_BY_CRON",
}

/**
 * An enumeration.
 */
export enum ClientOfferStatusEnum {
  AVAILABLE = "AVAILABLE",
  IN_USE = "IN_USE",
  USED = "USED",
}

/**
 * An enumeration.
 */
export enum ExchangeTypeEnum {
  CLIENT_TO_EXPERT = "CLIENT_TO_EXPERT",
  EXPERT_TO_CLIENT = "EXPERT_TO_CLIENT",
}

/**
 * An enumeration.
 */
export enum GenderEnum {
  MAN = "MAN",
  OTHER = "OTHER",
  WOMAN = "WOMAN",
}

/**
 * An enumeration.
 */
export enum MessageStatusEnum {
  ANSWERED = "ANSWERED",
  EXPIRED = "EXPIRED",
  SENT = "SENT",
}

/**
 * An enumeration.
 */
export enum MessageTypeEnum {
  COMPLETE_STUDY = "COMPLETE_STUDY",
  FREE = "FREE",
  PACK_STUDY = "PACK_STUDY",
  SIMPLE_QUESTION = "SIMPLE_QUESTION",
}

/**
 * An enumeration.
 */
export enum PackOrderStatus {
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
  ERROR = "ERROR",
  WAITING = "WAITING",
}

/**
 * An enumeration.
 */
export enum PackType {
  EMAIL = "EMAIL",
  MINUTE = "MINUTE",
}

/**
 * An enumeration.
 */
export enum StudyType {
  ASTROLOGY = "ASTROLOGY",
  NUMEROLOGY = "NUMEROLOGY",
}

/**
 * An enumeration.
 */
export enum TagTypesEnum {
  SPECIALTIES = "SPECIALTIES",
  THEMES = "THEMES",
}

/**
 * An enumeration.
 */
export enum TransactionType {
  BUY = "BUY",
  EXPIRATION = "EXPIRATION",
  MANUAL = "MANUAL",
  SPEND = "SPEND",
}

/**
 * An enumeration.
 */
export enum TypeNotification {
  MESSAGE_RECIEVED = "MESSAGE_RECIEVED",
  PAYMENT_DONE = "PAYMENT_DONE",
}

export interface EmailRecallInput {
  dayNumber?: number | null;
  content?: string | null;
}

export interface MessageInput {
  id?: string | null;
  group?: boolean | null;
}

export interface MessageParameterInput {
  messagePriceId?: number | null;
  typeMessage?: string | null;
  description?: string | null;
}

export interface NotificationParametersInput {
  typeNotification?: string | null;
  activation?: boolean | null;
}

export interface ReviewQualityInput {
  qualityId?: number | null;
  starNumber?: number | null;
}

export interface UserContactInput {
  surname?: string | null;
  lastname?: string | null;
  gender?: string | null;
  address?: string | null;
  addressComplement?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
