import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ModalProps, FlatList} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {CheckBox} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import logEvent from '~/helpers/analytics';
import {TypeNotification} from '~/types/graphql-global-types';

const UPDATE_ALERTING = gql`
    mutation updateAlerting($notificationParameters: [NotificationParametersInput!]) {
        updateAlerting(input: {notificationParameters: $notificationParameters}) {
            ok
        }
    }
`;

export type NotificationParametersInput = {
    typeNotification: TypeNotification;
    activation: boolean;
};

export default function PopupAlerting(
    props: ModalProps & {onSuccess: Function} & {alertings: NotificationParametersInput[]}
) {
    const [notifications, setNotifications] = useState<NotificationParametersInput[]>(
        props.alertings
    );
    const [updateAlerting, {data: callData, error: callError}] = useMutation(UPDATE_ALERTING, {
        errorPolicy: 'all',
    });

    const message = (
        <SimpleText>
            Choisissez les notifications que vous souhaitez recevoir selon les types d'alertes
            qui vous sont envoyés.
        </SimpleText>
    );

    async function validate() {
        const data = {
            notificationParameters: notifications,
        };

        await updateAlerting({variables: data});
        props.onSuccess();
        logEvent('modify_account', {fields: 'alerting'});
    }

    function toggle(typeNotification: TypeNotification) {
        let newNotifications = [...notifications];
        let notification = newNotifications.find(
            (e) => e.typeNotification == typeNotification
        );
        if (notification) {
            notification.activation = !notification.activation;
        } else {
            newNotifications.push({
                typeNotification: typeNotification,
                activation: true,
            } as NotificationParametersInput);
        }

        setNotifications(newNotifications);
    }

    function getAlerting(typeNotification: TypeNotification) {
        return (
            notifications.find((e) => e.typeNotification == typeNotification)?.activation ??
            false
        );
    }

    const messageRecieved = getAlerting(TypeNotification.MESSAGE_RECIEVED);
    const paymentDone = getAlerting(TypeNotification.PAYMENT_DONE);

    return (
        <CustomModal
            title="Mes Notifications"
            iconName="bell"
            hasCloseIcon={true}
            message={message}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            mobileContentMaxWidth={500}
        >
            <View style={styles.container}>
                <SimpleText style={styles.title}>Mes alertes e-mail</SimpleText>
                <NotificationCheck
                    typeNotification={TypeNotification.MESSAGE_RECIEVED}
                    title={"Un spirite m'envoie un message ou me répond"}
                    activation={messageRecieved}
                    toggle={toggle}
                />
                <NotificationCheck
                    typeNotification={TypeNotification.PAYMENT_DONE}
                    title={"J'ai effectué un paiement"}
                    activation={paymentDone}
                    toggle={toggle}
                />
                <Button
                    icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                    title="Valider"
                    buttonStyle={{width: 160, alignSelf: 'center', marginTop: 10}}
                    onPress={validate}
                />
            </View>
        </CustomModal>
    );
}

type NotificationCheckProps = {
    typeNotification: TypeNotification;
    title: string;
    activation: boolean;
    toggle: (notification: TypeNotification) => void;
};

function NotificationCheck(props: NotificationCheckProps) {
    return (
        <CheckBox
            key={props.typeNotification}
            title={props.title}
            checked={props.activation}
            onPress={() => props.toggle(props.typeNotification)}
            containerStyle={{borderWidth: 0, margin: 0, padding: 0, backgroundColor: 'white'}}
        />
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        padding: 15,
    },
    title: {
        color: Colors.secondary,
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 20,
        textTransform: 'uppercase',
    },
});
