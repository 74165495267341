import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ModalProps, FlatList} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {CheckBox} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import useTags from '~/queries/useTags';
import {TagTypesEnum} from '~/types/graphql-global-types';

const UPDATE_TAG = gql`
    mutation updateTag($tags: [String!]) {
        updateTag(input: {tags: $tags}) {
            user {
                id
                tags {
                    edges {
                        node {
                            id
                            label
                            tagTypeId
                        }
                    }
                }
            }
        }
    }
`;

export default function PopupSpecialty(props: ModalProps & {onSuccess: Function}) {
    const {isDesktop} = useDeviceQuery();
    const message = (
        <SimpleText>
            Choisissez les spécialités qui correspondent à votre profil.
            {'\n'}Sélectionnez vos spécialités :
        </SimpleText>
    );

    const desktopTitle = 'Définissez vos spécialités';
    const mobileTitle = 'Vos spécialités';

    return (
        <CustomModal
            title={isDesktop ? desktopTitle : mobileTitle}
            iconName="book"
            hasCloseIcon={true}
            message={message}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
        >
            <SpecialtiesForm onSuccess={props.onSuccess} />
        </CustomModal>
    );
}

type CheckBoxData = {
    id: string;
    title: string;
    checked: boolean;
    type: TagTypesEnum;
};

function SpecialtiesForm({onSuccess}: {onSuccess: Function}) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const {loadingMe, me, refetchMe} = React.useContext(UserContext);
    const {loading: tagLoading, error: tagError, data: tagData} = useTags();
    const [updateTag, {data, error: mutationError}] = useMutation(UPDATE_TAG, {
        errorPolicy: 'all',
    });
    const [specialities, setSpecialities] = useState<CheckBoxData[]>([]);

    useEffect(() => {
        const tagSpecialities = toCheckbox(TagTypesEnum.SPECIALTIES);
        if (tagSpecialities) {
            setSpecialities(tagSpecialities);
        }
    }, [tagLoading]);

    function toCheckbox(tagType: TagTypesEnum) {
        const tags = tagData?.tagType?.edges.find((type) => type?.node?.tagTypeId == tagType);

        return tags?.node?.tags?.edges.map((e, i) => {
            const tag = me?.tags?.edges.some((f) => f?.node?.id == e?.node?.id);

            return {
                id: e?.node?.id,
                title: e?.node?.label,
                checked: tag,
                type: tagType,
            } as CheckBoxData;
        });
    }

    async function onSubmit() {
        const tagSpecialities = specialities
            ?.filter((f) => f.checked)
            .map((e, i) => {
                return e.id;
            });

        const data = {
            tags: tagSpecialities,
        };

        const result = await updateTag({variables: data});
        onSuccess();
        logEvent('modify_expert', {fields: 'speciality'});
    }

    function renderItemTag({item}: {item: CheckBoxData}) {
        async function toggleCheckedCustom() {
            if (item.type == TagTypesEnum.SPECIALTIES) {
                const newArr = specialities?.map((existingTag, i) => {
                    if (item.id == existingTag?.id) {
                        return {...item, checked: !item.checked};
                    } else {
                        return existingTag;
                    }
                });
                setSpecialities(newArr);
            }
        }

        return (
            <CheckBox
                key={item.id}
                title={item.title}
                checked={item.checked}
                onPress={toggleCheckedCustom}
                containerStyle={{
                    backgroundColor: 'white',
                    borderWidth: 0,
                    margin: 0,
                    width: 200,
                }}
                textStyle={{fontWeight: 'normal'}}
            />
        );
    }

    if (loadingMe || tagLoading) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    return (
        <View style={deviceStyles.mainView}>
            {specialities && (
                <>
                    <SimpleText style={styles.category}>Spécialités</SimpleText>
                    <FlatList
                        numColumns={isDesktop ? 3 : 1}
                        data={specialities}
                        renderItem={renderItemTag}
                        keyExtractor={(item) => item.id}
                        style={styles.categoryList}
                    />
                </>
            )}
            <Button
                icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                onPress={onSubmit}
                title="Valider"
                buttonStyle={{width: 160, alignSelf: 'center'}}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    mainView: {},
    tagContainer: {
        backgroundColor: 'white',
        borderRadius: 5,
        marginTop: 20,
    },
    listWrap: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        borderRadius: 5,
    },
    category: {
        fontSize: 16,
        fontWeight: 'bold',
        paddingVertical: 20,
    },
    categoryList: {
        backgroundColor: 'white',
    },
});

const mobileStyles = StyleSheet.create({
    mainView: {
        marginHorizontal: 20,
    },
});
