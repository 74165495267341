import * as React from 'react';
import {ViewStyle, TextStyle, View, TouchableOpacity} from 'react-native';

import {IconNode} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import PopupMessage from '~/components/expert/PopupMessage';
import {GetLastExpertsQuery_getLastExperts_edges_node as Expert} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import {useAlert} from '~/contexts/AlertContext';
import {CallStatusContext} from '~/contexts/CallStatusContext';
import {ChatStatusContext} from '~/contexts/ChatStatusContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {AVAILABLE, getColor, UNAVAILABLE} from '~/helpers/experts';
import {ExpertsDetailsQuery_findExperts_edges_node as ExpertDetails} from '~/queries/types/ExpertsDetailsQuery';

import type {ExpertProfile_getExpert as ExpertProfile} from '../../screens/types/ExpertProfile';
import {SimpleText} from '../common/Texts';

export type ExpertButtonProps = {
    onAuthRequired?: () => void;
    onPaymentMethodRequired?: () => void;
    onCallStarted?: () => void;
    onCancelPayment?: () => void;
    onPress?: () => void;
    user?: ExpertProfile | ExpertDetails | Expert | null | undefined;
    style?: ViewStyle;
    buttonStyle?: ViewStyle;
    titleStyle: TextStyle;
    containerStyle?: ViewStyle;
    title: string;
    icon?: IconNode;
    availableIconVisible?: boolean;
    info?: boolean;
    informations?: JSX.Element;
    paymentIntent: string | undefined | null;
    paymentIntentSecret: string | undefined | null;
    freeCall?: boolean;
};

export function CallButton(props: ExpertButtonProps) {
    const {me} = React.useContext(UserContext);
    const call = React.useContext(CallStatusContext);
    const alert = useAlert();

    function isParameterOk() {
        const price = props.user?.callParameters?.price?.priceDecimal ?? 0;
        return price > 0;
    }

    function callPress() {
        props.onPress?.();
        logEvent('landing_page_1_call_pressed');
        if (me?.isExpert) {
            alert({
                title: 'Erreur',
                message: "Impossible d'appeler en tant que spirite.",
            });
            return;
        }
        if (!me?.id) {
            props.onAuthRequired?.();
            return;
        }
        if (!props.user?.id) {
            alert({
                title: 'Erreur',
                message: 'Impossible de lancer cet appel pour le moment.',
            });
            return;
        }
        if (props.user?.expert?.callStatus == UNAVAILABLE) {
            alert({
                title: 'Erreur',
                message: "Le spirite n'est plus disponible, veuillez essayer plus tard.",
            });
            return;
        }
        if (!me.identity?.selectedPaymentMethod?.isPaymentValidated) {
            props.onPaymentMethodRequired?.();
            return;
        }
        if (!isParameterOk()) {
            alert({
                title: 'Erreur',
                message: "Le service n'est pas disponible, veuillez essayer plus tard.",
            });
            return;
        }

        props.onCallStarted?.();
        call?.current?.reallyCallExpert(
            me.id,
            props.user.id,
            0,
            0,
            false,
            props.freeCall,
            true,
            props.paymentIntent ?? undefined,
            props.onCancelPayment
        );
        logEvent('call_landing_page_call_started_button_click', {expert_id: props.user.id});
    }

    return (
        <>
            <StatusButton
                buttonProps={props}
                onPress={callPress}
                status={props.user?.expert?.callStatus}
            />
        </>
    );
}

export function MessageButton(props: ExpertButtonProps) {
    const {me, refetchMe} = React.useContext(UserContext);
    const [isPopupMessageVisible, setIsPopupMessageVisible] = React.useState(false);
    const alert = useAlert();

    function isParameterOk() {
        const length = props.user?.messageParameters?.edges?.length ?? 0;
        const parameter = length > 0;
        if (!parameter) {
            return false;
        }
        let ok = false;
        for (let idx = 0; idx < length; idx++) {
            const parameter = props.user?.messageParameters?.edges[idx];
            const price = parameter?.node?.messagePrice?.priceDecimal ?? 0;
            const description = parameter?.node?.messagePrice?.label;
            if (price > 0 && description != '') {
                ok = true;
            }
        }
        return ok;
    }

    function messagePress() {
        if (me?.isExpert) {
            alert({
                title: 'Erreur',
                message: "Impossible d'envoyer un message en tant que spirite.",
            });
            return;
        }
        if (!me?.id) {
            alert({
                title: 'Erreur',
                message: "Impossible d'envoyer un message, veuillez vous connecter.",
            });
            return;
        }
        if (!props.user?.id) {
            alert({
                title: 'Erreur',
                message: 'Impossible de commencer une message pour le moment.',
            });
            return;
        }
        if (props.user?.expert?.messageStatus != AVAILABLE) {
            alert({
                title: 'Erreur',
                message: "Le spirite n'est pas disponible, veuillez essayer plus tard.",
            });
            return;
        }
        if (!isParameterOk()) {
            alert({
                title: 'Erreur',
                message: "Le service n'est pas disponible, veuillez essayer plus tard.",
            });
            return;
        }

        setIsPopupMessageVisible(true);
        logEvent('message_viewed');
    }

    function onClosePopupMessage() {
        setIsPopupMessageVisible(false);
    }

    return (
        <>
            <StatusButton
                buttonProps={props}
                onPress={messagePress}
                status={props.user?.expert?.messageStatus}
            />
            <PopupMessage
                visible={isPopupMessageVisible}
                client={me}
                expert={props.user}
                onRequestClose={onClosePopupMessage}
            />
        </>
    );
}

export function ChatButton(props: ExpertButtonProps) {
    const {me, offers, refetchOffers} = React.useContext(UserContext);
    const chat = React.useContext(ChatStatusContext);
    const alert = useAlert();

    function isParameterOk() {
        const price = props.user?.chatParameters?.chatPrice?.priceDecimal ?? 0;
        return price > 0;
    }

    function chatPress() {
        if (me?.isExpert) {
            alert({
                title: 'Erreur',
                message: 'Impossible de tchater en tant que spirite.',
            });
            return;
        }
        if (!me?.id) {
            alert({
                title: 'Erreur',
                message: 'Impossible de tchater, veuillez vous connecter.',
            });
            return;
        }
        if (!props.user?.id) {
            alert({
                title: 'Erreur',
                message: 'Impossible de commencer un tchat pour le moment.',
            });
            return;
        }
        if (props.user?.expert?.chatStatus == UNAVAILABLE) {
            alert({
                title: 'Erreur',
                message: "Le spirite n'est pas disponible, veuillez essayer plus tard.",
            });
            return;
        }
        if (!isParameterOk()) {
            alert({
                title: 'Erreur',
                message: "Le service n'est pas disponible, veuillez essayer plus tard.",
            });
            return;
        }

        chat?.current?.chatExpert(me, props.user, offers, refetchOffers);
        logEvent('chat_viewed');
    }

    return (
        <StatusButton
            buttonProps={props}
            onPress={chatPress}
            status={props.user?.expert?.chatStatus}
        />
    );
}

type StatusButtonProps = {
    buttonProps: ExpertButtonProps;
    status: string | null | undefined;
    onPress: () => void;
};

function StatusButton(props: StatusButtonProps) {
    if (props.buttonProps.availableIconVisible) {
        return (
            <View>
                <CommunicationButton buttonProps={props.buttonProps} onPress={props.onPress} />
                <Icons.Circle
                    size={10}
                    color={getColor(props.status)}
                    style={{position: 'absolute', left: 44, top: -2}}
                />
            </View>
        );
    }

    return <CommunicationButton buttonProps={props.buttonProps} onPress={props.onPress} />;
}

type CommunicationButtonProps = {
    buttonProps: ExpertButtonProps;
    onPress: () => void;
};

function CommunicationButton(props: CommunicationButtonProps) {
    if (props.buttonProps.info) {
        return (
            <CommunicationButtonWithInfo
                buttonProps={props.buttonProps}
                onPress={props.onPress}
            />
        );
    }

    return (
        <Button
            icon={props.buttonProps.icon}
            title={props.buttonProps.title}
            onPress={props.onPress}
            style={props.buttonProps.style}
            buttonStyle={props.buttonProps.buttonStyle}
            titleStyle={props.buttonProps.titleStyle}
            containerStyle={props.buttonProps.containerStyle}
        />
    );
}

function CommunicationButtonWithInfo(props: CommunicationButtonProps) {
    return (
        <TouchableOpacity style={props.buttonProps.containerStyle} onPress={props.onPress}>
            {props.buttonProps.icon}
            <SimpleText style={props.buttonProps.titleStyle}>
                {props.buttonProps.title}
            </SimpleText>
            {props.buttonProps.informations}
        </TouchableOpacity>
    );
}
