import * as React from 'react';
import {View, StyleSheet} from 'react-native';

import * as RootNavigation from '~/navigation';

import LoginStatus from '../user/LoginStatus';

export function UserInfo() {
    return (
        <View style={styles.userInfo}>
            <LoginStatus
                onLoginPress={() => RootNavigation.navigate('LoginScreen')}
                onUserPress={() => RootNavigation.navigate('ProfileScreen')}
                onRegisterPress={() => RootNavigation.navigate('RegisterScreen')}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    userInfo: {
        alignContent: 'center',
        justifyContent: 'center',
    },
});
