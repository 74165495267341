import React from 'react';
import {Text} from 'react-native';

export default function DateText({date}: DateTextProps) {
    return <Text>{dateString(date)}</Text>;
}

function dateString(date: DateType) {
    return date ? date.toLocaleDateString() : 'date inconnue';
}

export type DateType = Date | undefined | null;
export type DateTextProps = {
    date?: DateType;
};
