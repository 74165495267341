import {gql, useQuery} from '@apollo/client';

const EXPERT_PROFILE_QUERY = gql`
    query LandingPageSelectedExpert($id: ID!) {
        user(id: $id) {
            id
            profile {
                id
                displayName
                pictureName
            }
            expert {
                id
                longDescription
                shortDescription
                experience
                catchPhrase
                callStatus
                messageStatus
                chatStatus
                skinType
                voicemail
                created
            }
            callParameters {
                id
                acceptOffer
                price {
                    id
                    label
                    priceDecimal
                }
            }
        }
    }
`;

export type SelectedExpertUser = LandingPageSelectedExpert['user'];

export default function useSelectedExpert(expertId: string | undefined) {
    return useQuery<LandingPageSelectedExpert>(EXPERT_PROFILE_QUERY, {
        variables: {id: `User:${expertId}`},
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !expertId,
    });
}
