import React, {useState, useEffect} from 'react';
import {StyleSheet, Image, TouchableOpacity, View} from 'react-native';

import {RouteProp, useRoute} from '@react-navigation/native';

import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import ExpertList from '~/components/expert/ExpertList';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {ServiceEnum} from '~/helpers/experts';
import {notEmpty} from '~/helpers/list';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {TagsQuery_tagType_edges_node_tags} from '~/queries/types/TagsQuery';
import useTags from '~/queries/useTags';
import {SearchParamList} from '~/types';
import {SearchEngineItem} from '~/types/expertListOptions';
import {TagTypesEnum} from '~/types/graphql-global-types';

export const ALL = '-1';
export const FAVORITE = '-2';

const DEFAULT_GENDER_LIST = [
    {id: '0', text: 'Femme', selected: false},
    {id: '1', text: 'Homme', selected: false},
    {id: FAVORITE, text: 'Favoris', selected: false},
    {id: ALL, text: 'Tous', selected: true},
] as SearchEngineItem[];

const DEFAULT_AVAILABILITY_LIST = [
    {id: ServiceEnum.CALL, text: 'Téléphone', selected: false},
    {id: ServiceEnum.MESSAGE, text: 'Email', selected: false},
    {id: ServiceEnum.CHAT, text: 'Tchat', selected: false},
] as SearchEngineItem[];

const DEFAULT_ORDER_LIST = [
    {id: '0', text: 'Les mieux notés', selected: false},
    {id: '1', text: 'Les plus consultés', selected: false},
    {id: '2', text: 'Les prix doux', selected: false},
    {id: '3', text: 'Les nouveaux', selected: false},
] as SearchEngineItem[];

const DEFAULT_CONNECTED_LIST = [
    {id: '0', text: 'Connectés', selected: false},
] as SearchEngineItem[];

type MetaTag = {
    title: string;
    description: string;
};

const DEFAULT_SPECIALITY = {
    title: 'Je choisis un médium !',
    description:
        'Retrouvez sur cette page tous nos médiums, astrologues, voyants, tarologues et professionnels bien-être !',
};

export default function SearchScreen() {
    const route = useRoute<RouteProp<SearchParamList, 'SearchScreen'>>();
    const {loading: tagLoading, error: tagError, data: tagData} = useTags();
    const {isDesktop, isMobile} = useDeviceQuery();

    const [genders, setGenders] = useState<SearchEngineItem[]>(DEFAULT_GENDER_LIST);
    const [specialities, setSpecialities] = useState<SearchEngineItem[]>([]);
    const [availabilities, setAvailabilities] = useState<SearchEngineItem[]>(
        DEFAULT_AVAILABILITY_LIST
    );
    const [connected, setConnected] = useState<SearchEngineItem[]>(DEFAULT_CONNECTED_LIST);
    const [orders, setOrders] = useState<SearchEngineItem[]>(DEFAULT_ORDER_LIST);
    const [open, setOpen] = useState<boolean>(false);
    const [param, setParam] = useState<string>('');
    const [speciality, setSpeciality] = useState<MetaTag>(DEFAULT_SPECIALITY);

    useEffect(() => {
        setTags();
    }, [tagLoading, route.params]);

    function setTags() {
        let tag = '' as string | null | undefined;
        if (route.params) {
            tag = route.params.tag;
            if (param != tag && open) {
                onPress();
            }
            if (tag) {
                setParam(tag);
            }
        }

        if (tagData && tagData.tagType) {
            const tagTypes = tagData.tagType.edges.map((edge) => edge?.node).filter(notEmpty);
            const specialityTagType = tagTypes.filter(
                (e) => e.tagTypeId == TagTypesEnum.SPECIALTIES
            )[0];

            if (specialityTagType.tags) {
                const mySpecialities = toEngine(specialityTagType.tags, tag);
                setSpecialities(mySpecialities);
                const speciality = specialityTagType.tags.edges.find(
                    (f) => f?.node?.urlLabel == tag
                );
                if (speciality?.node) {
                    setSpeciality({
                        title: speciality.node.title,
                        description: speciality.node.description,
                    });
                } else {
                    setSpeciality(DEFAULT_SPECIALITY);
                }
            }
        }
    }

    function toEngine(
        tagType: TagsQuery_tagType_edges_node_tags,
        tag: string | null | undefined
    ) {
        const tags = tagType.edges.map((edge) => edge?.node).filter(notEmpty);
        return tags.map((t) => {
            return {
                id: t.id,
                text: t.label,
                selected: t.urlLabel == tag,
            } as SearchEngineItem;
        });
    }

    function onPress() {
        setOpen(!open);
    }

    return (
        <MainView>
            <PageTitle>{speciality.title}</PageTitle>
            <SimpleText style={styles.subtitle} accessibilityRole="header" aria-level="2">
                {speciality.description}
            </SimpleText>
            {isDesktop && (
                <DesktopSearch
                    genders={genders}
                    specialities={specialities}
                    connected={connected}
                    availabilities={availabilities}
                    orders={orders}
                    setGenders={setGenders}
                    setSpecialities={setSpecialities}
                    setConnected={setConnected}
                    setAvailabilities={setAvailabilities}
                    setOrders={setOrders}
                    onPress={onPress}
                    open={open}
                />
            )}
            {isMobile && (
                <MobileSearch
                    genders={genders}
                    specialities={specialities}
                    connected={connected}
                    availabilities={availabilities}
                    orders={orders}
                    setGenders={setGenders}
                    setSpecialities={setSpecialities}
                    setConnected={setConnected}
                    setAvailabilities={setAvailabilities}
                    setOrders={setOrders}
                    onPress={onPress}
                    open={open}
                />
            )}
            <View>
                <ExpertList
                    genders={genders}
                    specialities={specialities}
                    connected={connected}
                    availabilities={availabilities}
                    orders={orders}
                />
            </View>
        </MainView>
    );
}

type SearchProps = {
    open: boolean;
    onPress: () => void;
    genders: SearchEngineItem[];
    specialities: SearchEngineItem[];
    orders: SearchEngineItem[];
    connected: SearchEngineItem[];
    availabilities: SearchEngineItem[];
    setGenders: (genders: SearchEngineItem[]) => void;
    setSpecialities: (specialities: SearchEngineItem[]) => void;
    setOrders: (orders: SearchEngineItem[]) => void;
    setConnected: (connected: SearchEngineItem[]) => void;
    setAvailabilities: (availabilities: SearchEngineItem[]) => void;
};

function DesktopSearch(props: SearchProps) {
    return (
        <View style={styles.view}>
            <View style={styles.search}>
                <View style={styles.line}>
                    <SimpleText style={styles.label}>Afficher uniquement</SimpleText>
                    <View style={styles.subline}>
                        <SearchEngineItemList
                            list={props.genders}
                            setList={props.setGenders}
                            exclusive={false}
                            wrap={true}
                        />
                    </View>
                    <TouchableOpacity onPress={props.onPress}>
                        <View style={styles.buttons}>
                            <Icons.Slider size={20} color={'black'} style={{marginTop: 5}} />
                            {props.open && (
                                <Icons.SortUp
                                    size={20}
                                    color={'black'}
                                    style={{marginTop: props.open ? 10 : 1, marginLeft: 15}}
                                />
                            )}
                            {!props.open && (
                                <Icons.SortDown
                                    size={20}
                                    color={'black'}
                                    style={{marginTop: props.open ? 10 : 1, marginLeft: 15}}
                                />
                            )}
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.separator} />
                {props.open && (
                    <>
                        <View style={styles.line}>
                            <SimpleText style={styles.label}>Filtrer par</SimpleText>
                            <View style={styles.subline}>
                                <SearchEngineItemList
                                    list={props.specialities}
                                    setList={props.setSpecialities}
                                    exclusive={false}
                                    wrap={true}
                                />
                            </View>
                        </View>
                        <View style={styles.line}>
                            <SimpleText style={styles.label}>Trier par</SimpleText>
                            <View style={styles.subline}>
                                <SearchEngineItemList
                                    list={props.orders}
                                    setList={props.setOrders}
                                    exclusive={true}
                                    wrap={true}
                                />
                            </View>
                        </View>
                        <View style={styles.separator} />
                        <View style={styles.line}>
                            <SimpleText style={styles.label}>Disponibilité</SimpleText>
                            <View style={styles.subline}>
                                <SearchEngineItemList
                                    list={props.connected}
                                    setList={props.setConnected}
                                    exclusive={false}
                                    wrap={false}
                                />
                                <SearchEngineItemList
                                    list={props.availabilities}
                                    setList={props.setAvailabilities}
                                    exclusive={false}
                                    wrap={true}
                                />
                            </View>
                        </View>
                    </>
                )}
            </View>
        </View>
    );
}

function MobileSearch(props: SearchProps) {
    function resetSelected(items: SearchEngineItem[]) {
        return items.map((i) => {
            return {
                id: i.id,
                text: i.text,
                selected: i.id == ALL ? true : false,
            } as SearchEngineItem;
        });
    }

    function onClean() {
        props.setGenders(resetSelected(props.genders));
        props.setSpecialities(resetSelected(props.specialities));
        props.setAvailabilities(resetSelected(props.availabilities));
        props.setConnected(resetSelected(props.connected));
        props.setOrders(resetSelected(props.orders));
    }

    function getSelectedItems(items: SearchEngineItem[]) {
        return items.filter((i) => i.selected);
    }

    function getFilters() {
        let filters = [] as SearchEngineItem[];
        filters = [...filters, ...getSelectedItems(props.genders)];
        filters = [...filters, ...getSelectedItems(props.specialities)];
        filters = [...filters, ...getSelectedItems(props.availabilities)];
        return filters;
    }

    function renderFilter(item: SearchEngineItem) {
        return (
            <SimpleText style={mobileStyles.filter} key={item.id}>
                {item.text}
            </SimpleText>
        );
    }

    const filters = getFilters();
    const n = filters.length;

    return (
        <View style={mobileStyles.container}>
            <View style={mobileStyles.search}>
                <View style={mobileStyles.filters}>{filters.map((f) => renderFilter(f))}</View>
                <TouchableOpacity onPress={props.onPress} style={mobileStyles.button}>
                    <SimpleText style={mobileStyles.text}>Filtres ({n})</SimpleText>
                    <Icons.SortUp
                        size={16}
                        color={Colors.secondary}
                        style={{paddingTop: 9, marginLeft: 10}}
                    />
                </TouchableOpacity>
            </View>
            <CustomModal visible={props.open} onRequestClose={props.onPress}>
                <View style={mobileStyles.line}>
                    <SimpleText style={mobileStyles.label}>Afficher seulement</SimpleText>
                    <View style={styles.subline}>
                        <SearchEngineItemList
                            list={props.genders}
                            setList={props.setGenders}
                            exclusive={false}
                            wrap={true}
                        />
                    </View>
                </View>
                <View style={mobileStyles.line}>
                    <SimpleText style={mobileStyles.label}>Filtrer par Spécialité</SimpleText>
                    <View style={styles.subline}>
                        <SearchEngineItemList
                            list={props.specialities}
                            setList={props.setSpecialities}
                            exclusive={false}
                            wrap={true}
                        />
                    </View>
                </View>
                <View style={mobileStyles.line}>
                    <SimpleText style={mobileStyles.label}>Trier par</SimpleText>
                    <View style={styles.subline}>
                        <SearchEngineItemList
                            list={props.orders}
                            setList={props.setOrders}
                            exclusive={true}
                            wrap={true}
                        />
                    </View>
                </View>
                <View style={mobileStyles.line}>
                    <SimpleText style={mobileStyles.label}>Disponibilité</SimpleText>
                    <View style={styles.subline}>
                        <SearchEngineItemList
                            list={props.availabilities}
                            setList={props.setAvailabilities}
                            exclusive={false}
                            wrap={true}
                        />
                    </View>
                </View>
                <View style={mobileStyles.buttons}>
                    <TouchableOpacity style={mobileStyles.deleteButton} onPress={onClean}>
                        <SimpleText style={mobileStyles.delete}>
                            Supprimer les filtres
                        </SimpleText>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={mobileStyles.validateButton}
                        onPress={props.onPress}
                    >
                        <SimpleText style={mobileStyles.validate}>Valider</SimpleText>
                    </TouchableOpacity>
                </View>
            </CustomModal>
        </View>
    );
}

type SearchEngineItemListProps = {
    setList: (elt: SearchEngineItem[]) => void;
    list: SearchEngineItem[];
    exclusive: boolean;
    wrap: boolean;
};

function SearchEngineItemList(props: SearchEngineItemListProps) {
    function setItem(item: SearchEngineItem) {
        let newItems = [...props.list];

        for (let index = 0; index < newItems.length; index++) {
            const element = newItems[index];
            if (props.exclusive || item.id == ALL) {
                if (element.id == item.id) {
                    element.selected = true;
                } else {
                    element.selected = false;
                }
            } else {
                if (element.id == item.id) {
                    element.selected = !element.selected;
                } else if (element.id == ALL) {
                    element.selected = false;
                }
            }
        }

        if (!newItems.some((i) => i.selected)) {
            const all = newItems.find((f) => f.id == ALL);
            if (all) {
                all.selected = true;
            }
        }

        props.setList(newItems);
    }

    function renderItem(item: SearchEngineItem) {
        return <SearchEnginePressable item={item} setSelected={setItem} key={item.text} />;
    }

    return (
        <View style={props.wrap ? styles.listWrap : styles.list}>
            {props.list.map(renderItem)}
        </View>
    );
}

type SearchEnginePressableProps = {
    setSelected: (item: SearchEngineItem) => void;
    item: SearchEngineItem;
    key: string;
};

function SearchEnginePressable(props: SearchEnginePressableProps) {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return <DesktopSearchEnginePressable {...props} />;
    } else {
        return <MobileSearchEnginePressable {...props} />;
    }
}

function DesktopSearchEnginePressable(props: SearchEnginePressableProps) {
    function onPress() {
        props.setSelected(props.item);
    }

    const background = props.item.selected
        ? {backgroundColor: Colors.light}
        : {backgroundColor: Colors.dark};
    const text = props.item.selected ? {color: 'white'} : {color: Colors.light};
    const url = props.item.selected
        ? Settings.getUrlPictoSelected()
        : Settings.getUrlPictoUnselected();

    return (
        <TouchableOpacity onPress={onPress}>
            <View style={[styles.item, background]}>
                <SimpleText style={text}>{props.item.text}</SimpleText>
                <Image
                    style={{height: 12, width: 12, marginLeft: 5, marginTop: 3}}
                    source={{uri: url}}
                    accessibilityLabel={props.item.text}
                />
            </View>
        </TouchableOpacity>
    );
}

function MobileSearchEnginePressable(props: SearchEnginePressableProps) {
    function onPress() {
        props.setSelected(props.item);
    }

    const background = props.item.selected
        ? {backgroundColor: Colors.primary}
        : {backgroundColor: Colors.dark};
    const text = props.item.selected ? {color: 'white'} : {color: 'black'};
    return (
        <TouchableOpacity onPress={onPress}>
            <View style={[mobileStyles.item, background]}>
                <SimpleText style={text}>{props.item.text}</SimpleText>
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    view: {
        flex: 1,
        paddingHorizontal: 140,
        paddingVertical: 30,
    },
    search: {
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    line: {
        flexDirection: 'row',
        paddingTop: 20,
        paddingBottom: 0,
        paddingHorizontal: 30,
    },
    separator: {
        borderBottomWidth: 1,
        borderBottomColor: Colors.dark,
        marginHorizontal: 20,
    },
    label: {
        paddingTop: 10,
        paddingRight: 30,
        width: 200,
    },
    list: {
        marginRight: 30,
    },
    listWrap: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 5,
        marginLeft: 15,
        padding: 10,
        marginBottom: 20,
    },
    buttons: {
        alignSelf: 'flex-end',
        flexDirection: 'row',
    },
    subline: {
        flex: 1,
        flexDirection: 'row',
    },
    subtitle: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        backgroundColor: Colors.dark,
        paddingTop: 30,
    },
    description: {
        fontSize: 22,
        alignSelf: 'center',
        paddingVertical: 25,
    },
    search: {
        backgroundColor: 'white',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderWidth: 1,
        borderColor: Colors.disabled,
        marginHorizontal: 2,
    },
    filters: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: 5,
        flexWrap: 'wrap',
        flexShrink: 1,
    },
    filter: {
        padding: 7,
        color: 'white',
        borderRadius: 5,
        backgroundColor: Colors.primary,
        margin: 5,
    },
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderLeftWidth: 1,
        borderColor: Colors.disabled,
    },
    text: {},
    line: {
        padding: 5,
    },
    item: {
        borderRadius: 5,
        margin: 5,
        padding: 5,
        textAlign: 'center',
    },
    label: {
        color: Colors.highlight,
        fontWeight: 'bold',
        padding: 10,
    },
    buttons: {
        borderTopWidth: 1,
        borderTopColor: Colors.disabled,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        padding: 5,
    },
    deleteButton: {
        backgroundColor: Colors.dark,
        padding: 7,
        borderRadius: 5,
    },
    delete: {},
    validateButton: {
        backgroundColor: Colors.primary,
        padding: 7,
        borderRadius: 5,
    },
    validate: {
        color: 'white',
    },
    subtitle: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
});
