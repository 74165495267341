import {useQuery, gql, QueryHookOptions} from '@apollo/client';

import user from '~/queries/fragments/user';

import {QueryMe, QueryMe_me} from './types/QueryMe';

export default function useQueryMe(options: QueryHookOptions<any> | undefined) {
    return useQuery<QueryMe>(QUERY_ME, options);
}

export type Me = QueryMe_me;

export const QUERY_ME = gql`
    query QueryMe {
        me {
            id
            userId
            isExpert
            ...Profile
            ...Identity
            ...Expert
            ...Tags
            ...CallParameters
            ...MessageParameters
            ...ChatParameters
            ...Favorites
            ...NotificationParameters
        }
    }
    ${user}
`;
