import React, {useState, useEffect} from 'react';
import {View, StyleSheet, ViewStyle} from 'react-native';

import {CountryCode, getExampleNumber, parsePhoneNumber, ParseError} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import {Input} from 'react-native-elements';

import * as Colors from '~/constants/Colors';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import CountryPicker from './CountryPicker';

const DEFAULT_COUNTRY = 'FR' as CountryCode;
const DEFAULT_PLACEHOLDER = '+33 6 12 34 56 78';

type PhoneInputProps = {
    disabled: boolean;
    initialize: boolean;
    width?: number;
    phone: string;
    setPhone: (phone: string) => void;
    phoneError: string;
    setPhoneError: (phone: string) => void;
};

export function PhoneInput(props: PhoneInputProps) {
    const [country, setCountry] = useState<CountryCode | null>(null);
    const [placeholder, setPlaceholder] = useState<string>(DEFAULT_PLACEHOLDER);
    const {isDesktop} = useDeviceQuery();

    useEffect(() => {
        validate();
    }, [country]);

    if (props.initialize && props.phone != '' && country == null) {
        try {
            const number = parsePhoneNumber(props.phone);
            if (number?.country) {
                setCountry(number.country);
            }
        } catch (error) {}
    }

    if (!props.initialize && country == null) {
        setCountry(DEFAULT_COUNTRY);
    }

    function validate() {
        const empty = props.phone == '';
        if (empty) {
            props.setPhoneError('Le téléphone est obligatoire');
            return;
        }

        try {
            const number = parsePhoneNumber(props.phone, country ?? DEFAULT_COUNTRY);

            const valid = number.isValid();

            if (!valid) {
                props.setPhoneError('Le numéro de téléphone est invalide');
                return;
            }

            if (number.country != country) {
                props.setPhoneError(
                    'Le numéro de téléphone ne correspond pas au pays sélectionné'
                );
                return;
            }

            props.setPhoneError('');
            props.setPhone(number.number.toString());
        } catch (error) {
            if (error instanceof ParseError) {
                props.setPhoneError('Le numéro de téléphone est invalide');
                return;
            } else {
                throw error;
            }
        }
    }

    function onValueChange(code: string) {
        const countryCode = code as CountryCode;
        setCountry(countryCode);
        const phoneNumber = getExampleNumber(countryCode, examples);
        setPlaceholder(phoneNumber?.formatInternational() ?? DEFAULT_PLACEHOLDER);
    }

    function onChangeText(phone: string) {
        props.setPhone(phone);
    }

    function onBlur() {
        validate();
    }

    const container = (isDesktop
        ? {flexDirection: 'row', width: props.width, zIndex: 999999}
        : {zIndex: 999999}) as ViewStyle;
    const marginTop = isDesktop ? 0 : 15;

    return (
        <View style={container}>
            {!props.disabled && <CountryPicker country={country} setCountry={onValueChange} />}
            <View style={{flex: 1}}>
                <Input
                    value={props.phone}
                    placeholder={placeholder}
                    autoCapitalize="none"
                    keyboardType="phone-pad"
                    autoCompleteType="off"
                    spellCheck={false}
                    onChangeText={onChangeText}
                    onBlur={onBlur}
                    blurOnSubmit={true}
                    errorMessage={props.phoneError}
                    style={styles.formInput}
                    inputStyle={{fontSize: 14}}
                    inputContainerStyle={{borderBottomWidth: 0, marginTop: marginTop}}
                    disabled={props.disabled}
                    containerStyle={{zIndex: -1}}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    formInput: {
        borderWidth: 1,
        borderRadius: 10,
        paddingLeft: 30,
        paddingRight: 20,
        paddingVertical: 10,
        borderColor: Colors.primary,
    },
});
