import * as React from 'react';
import {StyleSheet} from 'react-native';

import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {EnumModeMessageBox} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';

type MessageHeaderProps = {
    mode: EnumModeMessageBox;
};

export default function MessageHeader(props: MessageHeaderProps) {
    const {isDesktop} = useDeviceQuery();
    return isDesktop ? <DesktopMessageHeader mode={props.mode} /> : <MobileMessageHeader />;
}

export function DesktopMessageHeader(props: MessageHeaderProps) {
    const target = props.mode == EnumModeMessageBox.CLIENT ? 'spirites' : 'clients';
    return (
        <>
            <SimpleText style={styles.title} accessibilityRole="header" aria-level="1">
                Ma Messagerie
            </SimpleText>
            <SimpleText style={styles.description}>
                Vous retrouverez ici tous vos échanges avec vos {target}.
            </SimpleText>
        </>
    );
}

export function MobileMessageHeader() {
    return (
        <SimpleText style={mobileStyles.title} accessibilityRole="header" aria-level="1">
            Ma Messagerie
        </SimpleText>
    );
}

const styles = StyleSheet.create({
    title: {
        fontWeight: 'bold',
        fontSize: 38,
        textTransform: 'uppercase',
        color: Colors.secondary,
        alignSelf: 'center',
        margin: 44,
    },
    description: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        marginBottom: 25,
    },
});

const mobileStyles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 22,
        color: Colors.secondary,
        marginBottom: 30,
        marginTop: 30,
    },
});
