import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import {Bloc} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';

export default function BlocReload() {
    return (
        <Bloc>
            <View style={styles.tips}>
                <SimpleText style={styles.problem}>Un problème avec le tchat ? </SimpleText>
                <TouchableOpacity onPress={() => location.reload()}>
                    <SimpleText style={styles.solution}>Recharger la page</SimpleText>
                </TouchableOpacity>
            </View>
        </Bloc>
    );
}

const styles = StyleSheet.create({
    tips: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    problem: {
        color: Colors.bad,
        fontSize: 12,
    },
    solution: {
        color: Colors.bad,
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        fontSize: 12,
    },
});
