import * as React from 'react';

import {MustBeConnected} from '~/components/navigation/Link';
import {ScreenLoader} from '~/components/navigation/Loader';
import ClientDashboard from '~/components/user/client/dashboard/ClientDashboard';
import ExpertDashboard from '~/components/user/expert/dashboard/ExpertDashboard';
import {UserContext} from '~/contexts/UserContext';

export default function UserProfileScreen() {
    const {loadingUser, user, loadingMe, me} = React.useContext(UserContext);

    if (!loadingUser && !loadingMe && !user && !me) {
        return <MustBeConnected />;
    }

    if (me) {
        if (me.isExpert) {
            return <ExpertDashboard />;
        } else {
            return <ClientDashboard />;
        }
    }

    return <ScreenLoader />;
}
