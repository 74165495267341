import React, {useState} from 'react';
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {Picker} from '@react-native-picker/picker';
import {useFocusEffect} from '@react-navigation/native';

import {MainView} from '~/components/common/Containers';
import {GradeColor} from '~/components/common/GradeItem';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {ScreenLoader} from '~/components/navigation/Loader';
import PopupReadReview from '~/components/user/client/review/PopupReadReview';
import PopupWriteReview from '~/components/user/client/review/PopupWriteReview';
import {CommunicationType, ReviewData} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {MIN_DURATION_FOR_REVIEW} from '~/constants/Settings';
import {APP_ACTIVE_YEARS, MONTHS_NAMES} from '~/constants/dates';
import * as dates from '~/helpers/dates';
import {filterEmpty, sorted} from '~/helpers/list';
import {MessageTypeEnum, PackOrderStatus} from '~/types/graphql-global-types';

import {MobileHistoricQuery} from './types/MobileHistoricQuery';

const MOBILE_HISTORIC_QUERY = gql`
    query MobileHistoricQuery($month: Int, $year: Int) {
        findCalls(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    status
                    expert {
                        id
                        profile {
                            id
                            displayName
                            pictureName
                        }
                    }
                    clientOffer {
                        offer {
                            id
                            label
                            freeMinuteInteger
                        }
                    }
                    review {
                        id
                        reviewId
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
        findMessages(month: $month, year: $year) {
            edges {
                node {
                    id
                    type
                    status
                    creationDate
                    cost
                    review {
                        id
                        reviewId
                        grade {
                            label
                            gradeId
                        }
                    }
                    expert {
                        id
                        profile {
                            id
                            displayName
                            pictureName
                        }
                    }
                    packOrder {
                        amount
                        pack {
                            name
                        }
                    }
                }
            }
        }
        findChats(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    status
                    expert {
                        id
                        profile {
                            id
                            displayName
                            pictureName
                        }
                    }
                    clientOffer {
                        offer {
                            id
                            label
                            freeMinuteInteger
                        }
                    }
                    review {
                        id
                        reviewId
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
        findPackOrders(month: $month, year: $year) {
            edges {
                node {
                    packOrderId
                    status
                    amount
                    minutes
                    created
                    pack {
                        name
                    }
                }
            }
        }
    }
`;

export default function ClientHistoricMobile() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const [year, setYear] = React.useState(currentYear);
    const [month, setMonth] = React.useState(currentMonth);

    const [load, {called, loading, data, refetch}] = useLazyQuery<MobileHistoricQuery>(
        MOBILE_HISTORIC_QUERY,
        {
            errorPolicy: 'all',
            fetchPolicy: 'cache-and-network',
            variables: {month: month + 1, year: year},
        }
    );

    useFocusEffect(
        React.useCallback(() => {
            if (month == currentMonth && year == currentYear) {
                requestAnimationFrame(reload);
            }
        }, [month, year])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    const [reviewData, setReviewData] = useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: true,
    });
    const [modalWriteReviewVisible, setModalWriteReviewVisible] = useState(false);
    const [modalReadReviewVisible, setModalReadReviewVisible] = useState(false);

    if (loading && data == null) {
        return <ScreenLoader />;
    }

    function onWriteReviewPress(reviewData: ReviewData) {
        setReviewData(reviewData);
        setModalWriteReviewVisible(true);
    }

    function onReadReviewPress(reviewData: ReviewData) {
        setReviewData(reviewData);
        setModalReadReviewVisible(true);
    }

    function onRequestCloseWriteReview() {
        setModalWriteReviewVisible(false);
    }

    function onRequestCloseReadReview() {
        setModalReadReviewVisible(false);
    }

    function endReview() {
        reload();
        setModalWriteReviewVisible(false);
    }

    function sortByDate(calls: HistoricElement[]): HistoricElement[] {
        return sorted(calls, (e: HistoricElement) => Date.parse(e.date), true);
    }

    const monthItems = MONTHS_NAMES.map((name, i) => (
        <Picker.Item label={name} value={i} key={`month_${i}`} />
    ));

    const yearItems = APP_ACTIVE_YEARS.map((year) => (
        <Picker.Item label={`${year}`} value={year} key={`year_${year}`} />
    ));

    const calls = data?.findCalls?.edges?.filter(
        (e) =>
            (e?.node?.status == 'FINISHED' || e?.node?.status == 'ERROR') &&
            new Date(e.node.startDatetime).getFullYear() == year &&
            new Date(e.node.startDatetime).getMonth() == month
    );

    const callsElements = calls?.map((e) => {
        return {
            type: 'Appel',
            communicationType: CommunicationType.CALL,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            expertDisplayName: e?.node?.expert?.profile?.displayName,
            expertPictureName: e?.node?.expert?.profile?.pictureName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            cost: e?.node?.cost,
            duration: e?.node?.duration,
            clientOfferLabel: e?.node?.clientOffer?.offer?.label,
            freeMinuteInteger: e?.node?.clientOffer?.offer?.freeMinuteInteger,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as HistoricElement;
    });

    const messages = data?.findMessages?.edges?.filter(
        (e) =>
            (e?.node?.type == MessageTypeEnum.PACK_STUDY || e?.node?.status == 'ANSWERED') &&
            new Date(e.node.creationDate).getFullYear() == year &&
            new Date(e.node.creationDate).getMonth() == month
    );

    const messagesElements = messages?.map((e) => {
        return {
            type: e?.node?.type == MessageTypeEnum.PACK_STUDY ? 'Étude complète' : 'Message',
            communicationType: CommunicationType.MESSAGE,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            expertDisplayName: e?.node?.expert?.profile?.displayName,
            expertPictureName: e?.node?.expert?.profile?.pictureName,
            date: e?.node?.creationDate,
            reviewId: e?.node?.review?.reviewId,
            cost:
                e?.node?.type == MessageTypeEnum.PACK_STUDY
                    ? e?.node?.packOrder?.amount
                    : e?.node?.cost,
            duration: '',
            clientOfferLabel:
                e?.node?.type == MessageTypeEnum.PACK_STUDY
                    ? 'Pack ' + e?.node?.packOrder?.pack?.name
                    : '',
            freeMinuteInteger: 0,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as HistoricElement;
    });

    const chats = data?.findChats?.edges?.filter(
        (e) =>
            e?.node?.status == 'ENDED' &&
            new Date(e.node.startDatetime).getFullYear() == year &&
            new Date(e.node.startDatetime).getMonth() == month
    );

    const chatsElements = chats?.map((e) => {
        return {
            type: 'Chat',
            communicationType: CommunicationType.CHAT,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            expertDisplayName: e?.node?.expert?.profile?.displayName,
            expertPictureName: e?.node?.expert?.profile?.pictureName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            cost: e?.node?.cost,
            duration: e?.node?.duration,
            clientOfferLabel: e?.node?.clientOffer?.offer?.label,
            freeMinuteInteger: e?.node?.clientOffer?.offer?.freeMinuteInteger,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as HistoricElement;
    });

    const packOrders = data?.findPackOrders?.edges?.filter(
        (e) =>
            e?.node?.status == PackOrderStatus.CONFIRMED &&
            e?.node?.minutes &&
            new Date(e.node.created).getFullYear() == year &&
            new Date(e.node.created).getMonth() == month
    );

    const packOrdersElements = packOrders?.map((e) => {
        return {
            type: 'Pack',
            communicationType: 'Pack',
            id: e?.node?.packOrderId,
            expertId: '-',
            expertDisplayName: null,
            expertPictureName: null,
            date: e?.node?.created,
            reviewId: null,
            cost: e?.node?.amount,
            duration: null,
            clientOfferLabel: 'Pack ' + e?.node?.pack?.name,
            freeMinuteInteger: 0,
            gradeLabel: null,
            gradeId: null,
        } as HistoricElement;
    });

    const elements = [
        ...(callsElements ?? []),
        ...(messagesElements ?? []),
        ...(chatsElements ?? []),
        ...(packOrdersElements ?? []),
    ];

    let displayedElements = filterEmpty(elements);
    const sortedElements = sortByDate(displayedElements);

    return (
        <MainView>
            <SimpleText style={mobileStyles.title} accessibilityRole="header" aria-level="1">
                Mon historique de consultation
            </SimpleText>
            <SimpleText style={mobileStyles.description}>
                Vous retrouverez ici toutes les statistiques liées à vos activités sur
                Kyvoitou.fr.
            </SimpleText>
            <View style={mobileStyles.filterContainer}>
                <SimpleText style={mobileStyles.pickerContainer}>Filtrer par : </SimpleText>
                <Picker
                    selectedValue={month}
                    style={mobileStyles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setMonth(parseInt(itemValue))}
                >
                    {monthItems}
                </Picker>
                <Picker
                    selectedValue={year}
                    style={mobileStyles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setYear(parseInt(itemValue))}
                >
                    {yearItems}
                </Picker>
            </View>
            {elements.length == 0 && (
                <SimpleText style={mobileStyles.description}>
                    Vous n'avez pas d'élément dans l'historique pour ce mois-ci.
                </SimpleText>
            )}
            {elements.length > 0 && (
                <FlatList
                    data={sortedElements}
                    renderItem={({item}) => (
                        <HistoricItem
                            item={item}
                            onWriteReviewPress={onWriteReviewPress}
                            onReadReviewPress={onReadReviewPress}
                        />
                    )}
                    keyExtractor={(item) => item.id}
                    contentContainerStyle={mobileStyles.listContainer}
                />
            )}
            <PopupWriteReview
                visible={modalWriteReviewVisible}
                onRequestClose={onRequestCloseWriteReview}
                reviewData={reviewData}
                onSuccess={endReview}
            />
            <PopupReadReview
                visible={modalReadReviewVisible}
                onRequestClose={onRequestCloseReadReview}
                reviewData={reviewData}
                animationType={'none'}
            />
        </MainView>
    );
}

type HistoricItemProps = {
    item: HistoricElement;
    onWriteReviewPress: (reviewData: ReviewData) => void;
    onReadReviewPress: (reviewData: ReviewData) => void;
};

function HistoricItem(props: HistoricItemProps) {
    const date = new Date(props.item.date);
    const d = 'Le ' + dates.dateString(date) + ' à ' + dates.timeString(date);
    const size = 20;
    const color = Colors.secondary;
    const duration = props.item.duration
        ? dates.getDurationInMinutes(props.item.duration)
        : null;
    const durationInSeconds = duration ? duration * 60 : 0;

    const reviewData = {
        date,
        communicationId: props.item?.id,
        communicationType: props.item?.communicationType,
        expertId: props.item?.expertId,
        clientMode: true,
    } as ReviewData;

    let icon = <></>;

    if (props.item.communicationType == CommunicationType.CALL) {
        icon = <Icons.Phone size={size} color={color} />;
    } else if (props.item.communicationType == CommunicationType.CHAT) {
        icon = <Icons.Chat size={size} color={color} />;
    } else if (props.item.communicationType == CommunicationType.MESSAGE) {
        icon = <Icons.Mail size={size} color={color} />;
    } else if (props.item.communicationType == 'Pack') {
        icon = <Icons.Box size={size} color={color} />;
    }

    const reviewId = props.item?.reviewId;

    const hasReview = props.item?.type != 'Pack' && reviewId != null;
    const canReview =
        props.item?.type != 'Pack' &&
        durationInSeconds > MIN_DURATION_FOR_REVIEW &&
        (props.item?.cost > 0 || props.item?.freeMinuteInteger > 0);

    const label = props.item.type == 'Pack' ? props.item.clientOfferLabel : props.item.type;

    return (
        <View style={mobileStyles.container}>
            <View style={mobileStyles.top}>
                {Boolean(props.item.expertPictureName) && (
                    <View>
                        <ProfilePicture
                            style={mobileStyles.picture}
                            pictureName={props.item.expertPictureName}
                            displayName={props.item.expertDisplayName}
                        />
                    </View>
                )}
                <View style={mobileStyles.lines}>
                    <View style={mobileStyles.header}>
                        {icon}
                        <SimpleText style={mobileStyles.head}>{label}</SimpleText>
                        {Boolean(props.item.duration) && (
                            <SimpleText style={mobileStyles.time}>
                                ({duration} min.)
                            </SimpleText>
                        )}
                    </View>
                    <View style={mobileStyles.info}>
                        <SimpleText style={mobileStyles.date}>
                            {props.item.expertDisplayName}
                        </SimpleText>
                    </View>
                    <View style={mobileStyles.info}>
                        <SimpleText style={mobileStyles.date}>{d}</SimpleText>
                    </View>
                    <View style={mobileStyles.line}>
                        <SimpleText style={mobileStyles.cost}>{props.item.cost} €</SimpleText>
                        {Boolean(props.item.freeMinuteInteger > 0) && (
                            <SimpleText style={mobileStyles.offer}>
                                {props.item.freeMinuteInteger} min. gratuites
                            </SimpleText>
                        )}
                    </View>
                </View>
            </View>
            <View style={mobileStyles.bottom}>
                {hasReview && Boolean(props.item.gradeId) && (
                    <TouchableOpacity
                        onPress={() => props.onReadReviewPress(reviewData)}
                        style={mobileStyles.gradeContainer}
                    >
                        <GradeColor
                            style={mobileStyles.grade}
                            text={props.item.gradeLabel ?? ''}
                            grade={props.item.gradeId}
                        />
                        <View style={mobileStyles.link}>
                            <Icons.Read
                                size={16}
                                color={Colors.link}
                                style={{paddingRight: 10}}
                            />
                            <SimpleText style={mobileStyles.text}>
                                Lire l'évaluation
                            </SimpleText>
                        </View>
                    </TouchableOpacity>
                )}
                {!hasReview && canReview && (
                    <View style={mobileStyles.write}>
                        <TouchableOpacity
                            onPress={() => props.onWriteReviewPress(reviewData)}
                            style={mobileStyles.link}
                        >
                            <Icons.Write
                                size={16}
                                color={Colors.link}
                                style={{paddingRight: 10}}
                            />
                            <SimpleText style={mobileStyles.text}>
                                Rédiger mon évaluation
                            </SimpleText>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
        </View>
    );
}

type HistoricElement = {
    type: string;
    communicationType: CommunicationType | 'Pack';
    id: string;
    expertId: string;
    expertDisplayName: string | null;
    expertPictureName: string | null;
    date: string;
    reviewId: string | null;
    cost: number;
    duration: string | null;
    freeMinuteInteger: number;
    clientOfferLabel: string | undefined;
    gradeLabel: string | null;
    gradeId: number | null;
};

const mobileStyles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 22,
        color: Colors.secondary,
        marginBottom: 30,
        marginTop: 30,
    },
    description: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        marginBottom: 30,
    },
    filterContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    pickerContainer: {
        margin: 15,
    },
    listContainer: {},
    container: {
        borderWidth: 1,
        borderColor: Colors.separator,
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 10,
        margin: 15,
        backgroundColor: 'white',
        justifyContent: 'center',
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
    },
    top: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    picture: {
        borderRadius: 10,
        width: 100,
        height: 100,
        alignSelf: 'center',
        marginRight: 20,
    },
    lines: {
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    header: {
        flexDirection: 'row',
    },
    head: {
        alignSelf: 'center',
        marginLeft: 15,
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    date: {
        alignSelf: 'center',
    },
    offer: {
        color: Colors.nice,
        alignSelf: 'center',
        marginLeft: 20,
    },
    cost: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    info: {
        flexDirection: 'row',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 5,
    },
    time: {
        alignSelf: 'center',
        marginLeft: 10,
    },
    bottom: {
        justifyContent: 'center',
    },
    link: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
    text: {
        color: Colors.link,
        alignSelf: 'center',
    },
    write: {
        marginTop: 10,
    },
    gradeContainer: {
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    grade: {
        fontSize: 14,
        marginRight: 15,
    },
});
