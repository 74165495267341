import {Dimensions} from 'react-native';

export const MOBILE_MAX_WIDTH = 1024;
export const LARGE_DESKTOP_MIN_WIDTH = 1300;
const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
    window: {
        width,
        height,
    },
    isSmallDevice: width < 375,
};
