import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

export default async function getCachedUrlContent(urlAsKey: string, expireInMinutes = 60) {
    const value = await AsyncStorage.getItem(urlAsKey);

    let storedObject = null;

    if (value !== null) {
        storedObject = JSON.parse(value);

        if (
            storedObject !== null &&
            storedObject['expireAt'] &&
            new Date(storedObject.expireAt) < new Date()
        ) {
            AsyncStorage.removeItem(urlAsKey);
            storedObject = null;
        }
    }

    if (storedObject === null) {
        const response = await axios.get(urlAsKey);
        storedObject = {
            data: response.data,
            expireAt: getExpireDate(expireInMinutes),
        };
        AsyncStorage.setItem(urlAsKey, JSON.stringify(storedObject));
    }

    return storedObject.data;
}

export function getExpireDate(expireInMinutes: number) {
    const now = new Date();
    let expireTime = new Date(now);
    expireTime.setMinutes(now.getMinutes() + expireInMinutes);
    return expireTime;
}

export async function isExpired(key: string) {
    const value = await AsyncStorage.getItem(key);
    let result = true;
    let time = null;

    if (value !== null) {
        time = new Date(value);

        if (time !== null && time < new Date()) {
            AsyncStorage.removeItem(key);
            time = null;
        } else {
            result = false;
        }
    }

    return result;
}

export async function setExpiration(key: string, expiration: number) {
    const expireAt = getExpireDate(expiration);
    AsyncStorage.setItem(key, expireAt.toString());
}
