import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import {gql, useQuery, useMutation} from '@apollo/client';
import {CheckBox} from 'react-native-elements';

import * as Icons from '~/components/common/Icons';
import {NoteInput} from '~/components/common/Inputs';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {useConfirm} from '~/contexts/ConfirmContext';
import * as dates from '~/helpers/dates';
import {getBenefit} from '~/helpers/experts';
import {CallStatusEnum} from '~/types/graphql-global-types';

import Button from '../common/Buttons';
import {CommunicationType} from '../user/client/review/types/calls';
import PopupStatistics from '../user/client/statistics/PopupStatistics';

type PopupMemoProps = {
    visible: boolean;
    onRequestClose: () => void;
    clientId: string | null;
    clientName: string | null | undefined;
    duration: number | null;
    status: CallStatusEnum;
    cost: number;
    pack: boolean;
    offer: string | null;
};

const MEMO_QUERY = gql`
    query GetMemoQuery($clientId: String!) {
        getMemo(clientId: $clientId) {
            note
            lastUpdateDate
        }
    }
`;

const UPDATE_MEMO = gql`
    mutation updateMemo($clientId: String!, $note: String!) {
        updateMemo(input: {clientId: $clientId, note: $note}) {
            memo {
                lastUpdateDate
            }
        }
    }
`;

export default function PopupMemo(props: PopupMemoProps) {
    const variables = {clientId: props.clientId};
    const {data, loading, error, refetch} = useQuery(MEMO_QUERY, {
        variables,
        skip: props.clientId == null,
        fetchPolicy: 'network-only',
    });
    const [updateMemo, {data: mutationData, error: mutationError}] = useMutation(UPDATE_MEMO);
    const [note, setNote] = useState<string>('');
    const [isModified, setIsModified] = useState<boolean>(false);
    const [autosave, setAutosave] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [statisticsClientId, setStatisticsClientId] = React.useState<string | null>(null);
    const [modalStatisticsVisible, setModalStatisticsVisible] = React.useState(false);
    const confirm = useConfirm();

    React.useEffect(() => {
        const note = data?.getMemo?.note;
        const lastUpdateDate = data?.getMemo?.lastUpdateDate;
        if (note != undefined && lastUpdateDate != undefined) {
            setNote(note);
            setLastUpdate(new Date(lastUpdateDate));
        } else {
            setNote('');
            setLastUpdate(null);
        }
    }, [data]);

    React.useEffect(() => {
        if (props.status == CallStatusEnum.FINISHED) {
            saveMemo();
        }
    }, [props.status]);

    async function saveMemo() {
        const mutationData = {
            clientId: props.clientId,
            note: note,
        };

        const result = await updateMemo({variables: mutationData});

        if (result.data.updateMemo?.memo?.lastUpdateDate) {
            setLastUpdate(new Date(result.data.updateMemo?.memo?.lastUpdateDate));
        }
        setIsModified(false);
    }

    function onChangeNote(e: string) {
        setNote(e);
        setIsModified(true);
    }

    function onClose() {
        if (isModified) {
            confirm({
                title: 'Attention',
                message: 'Voulez vous enregistrer vos notes ?',
                yesText: 'Enregistrer',
                noText: 'Ne pas enregistrer',
                onYes: () => {
                    saveMemo();
                    props.onRequestClose();
                },
                onNo: props.onRequestClose,
            });
        } else {
            props.onRequestClose();
        }
    }

    function onStatisticsPress() {
        if (props.clientId) {
            setStatisticsClientId(props.clientId);
            setModalStatisticsVisible(true);
        }
    }

    function onRequestCloseStatistics() {
        setModalStatisticsVisible(false);
    }

    function getStatus() {
        switch (props.status) {
            case CallStatusEnum.STARTED:
                return {color: Colors.light, text: 'Initialisation'};
            case CallStatusEnum.EXPERT_JOINED:
                return {color: Colors.connected, text: 'Appel en cours...'};
            case CallStatusEnum.BOTH_PARTIES_JOINED:
                return {color: Colors.connected, text: 'Appel en cours...'};
            case CallStatusEnum.FINISHED:
                return {color: Colors.highlight, text: "Fin de l'appel"};
            case CallStatusEnum.ERROR:
                return {color: Colors.error, text: "Erreur lors de l'appel"};
            case CallStatusEnum.CANCELED:
                return {color: Colors.error, text: "Erreur lors de l'appel"};
            default:
                return {color: Colors.light, text: ' - '};
        }
    }

    const message = <SimpleText></SimpleText>;

    const title = 'Appel avec ' + props.clientName;
    const info = getStatus();
    const statusStyle = {color: info.color};
    let time = ' - ';
    if (props.duration) {
        const minutes = Math.floor(props.duration / 60) % 60;
        const hours = Math.floor(props.duration / 3600);
        time = `${hours}h${minutes}m`;
    }
    let money = ' - ';
    if (props.status == CallStatusEnum.FINISHED) {
        money = `${getBenefit(
            props.cost,
            props.pack,
            props.duration,
            CommunicationType.CALL
        ).toFixed(2)}€`;
    }
    const saveStyle = isModified ? {color: 'red'} : {color: 'black'};
    let lastUpdateText = ' ';
    if (lastUpdate) {
        const date = dates.dateString(lastUpdate);
        const time = dates.timeString(lastUpdate);
        lastUpdateText = `Enregistré le ${date} à ${time}`;
    }

    const padding = props.offer ? {paddingTop: 15} : {paddingTop: 30};

    return (
        <CustomModal
            title={title}
            iconName="clipboard"
            message={message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            width={850}
        >
            <View style={styles.headerContainer}>
                <View style={[styles.statContainer, padding]}>
                    {props.offer && (
                        <SimpleText style={styles.offer}>
                            <Icons.Info
                                size={16}
                                style={{marginRight: 5, textAlignVertical: 'bottom'}}
                                color={'black'}
                            />
                            {props.offer}
                        </SimpleText>
                    )}
                    <View style={styles.durationContainer}>
                        <SimpleText style={styles.label}>DUREE DE L'APPEL :</SimpleText>
                        <SimpleText style={styles.duration}>{time}</SimpleText>
                    </View>
                    <View style={styles.costContainer}>
                        <SimpleText style={styles.label}>REVENU GENERE :</SimpleText>
                        <SimpleText style={styles.cost}>{money}</SimpleText>
                    </View>
                </View>
                <View style={styles.statusContainer}>
                    <SimpleText style={[styles.status, statusStyle]}>{info.text}</SimpleText>
                </View>
            </View>
            <View style={styles.titleContainer}>
                <SimpleText style={styles.title}>
                    BLOC-NOTES
                    <SimpleText style={styles.subtitle}>
                        {' '}
                        (visible seulement par vous)
                    </SimpleText>
                </SimpleText>
                <SimpleText style={styles.description}>
                    Ajouter ici vos notes durant l'appel ou les questions à poser à
                    l'utilisateur. Un bon moyen pour le suivi de votre client ! Vous pourrez si
                    vous le souhaitez, partager vos notes avec votre client par la suite.
                </SimpleText>
            </View>
            <View>
                <View style={styles.noteContainer}>
                    <View style={styles.headerNoteContainer}>
                        <View>
                            <SimpleText style={styles.headerNote}>NOTES</SimpleText>
                            <SimpleText style={styles.date}>{lastUpdateText}</SimpleText>
                        </View>
                        <View>
                            <TouchableOpacity onPress={saveMemo} style={styles.saveContainer}>
                                <Icons.Save size={24} color={isModified ? 'red' : 'black'} />
                                <SimpleText style={[styles.save, saveStyle]}>
                                    Enregistrer mes notes
                                </SimpleText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <NoteInput style={styles.input} value={note} onChangeText={onChangeNote} />
                    <CheckBox
                        key={props.clientId}
                        title={"Enregistrer automatiquement mes notes à la fin de l'appel"}
                        checked={autosave}
                        onPress={() => setAutosave(!autosave)}
                        containerStyle={{
                            borderWidth: 0,
                            margin: 0,
                            padding: 0,
                            backgroundColor: 'white',
                        }}
                    />
                </View>
            </View>
            <View style={styles.actionContainer}>
                <Button
                    title="Fermer"
                    containerStyle={{margin: 0, padding: 0, backgroundColor: 'white'}}
                    buttonStyle={{width: 150}}
                    onPress={onClose}
                />
                <TouchableOpacity style={styles.statistics} onPress={onStatisticsPress}>
                    <SimpleText style={styles.statisticsLabel}>
                        Voir mes statistiques avec ce client
                    </SimpleText>
                    <Icons.See
                        style={{
                            borderLeftColor: Colors.highlight,
                            borderLeftWidth: 1,
                            alignSelf: 'flex-end',
                            padding: 10,
                        }}
                        size={20}
                        color={Colors.highlight}
                    />
                </TouchableOpacity>
                <PopupStatistics
                    visible={modalStatisticsVisible}
                    onRequestClose={onRequestCloseStatistics}
                    clientId={statisticsClientId}
                />
            </View>
        </CustomModal>
    );
}

const styles = StyleSheet.create({
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    statContainer: {
        backgroundColor: 'white',
        paddingLeft: 30,
        paddingRight: 75,
    },
    durationContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    label: {
        fontSize: 14,
    },
    offer: {
        flex: 1,
    },
    duration: {
        fontSize: 14,
        color: Colors.highlight,
        marginLeft: 15,
        width: 100,
    },
    costContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    cost: {
        fontSize: 14,
        color: Colors.primary,
        marginLeft: 15,
        width: 100,
    },
    statusContainer: {
        backgroundColor: 'white',
        padding: 50,
        paddingHorizontal: 75,
    },
    status: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    titleContainer: {
        margin: 30,
    },
    title: {
        flex: 1,
        flexDirection: 'row',
        fontSize: 16,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    subtitle: {
        fontSize: 16,
        alignSelf: 'center',
        fontWeight: 'normal',
    },
    description: {
        fontSize: 14,
        alignSelf: 'center',
        marginTop: 30,
    },
    noteContainer: {
        backgroundColor: 'white',
        paddingHorizontal: 25,
        paddingVertical: 15,
    },
    header: {
        fontSize: 16,
        paddingTop: 20,
    },
    headerNoteContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        paddingBottom: 30,
        marginBottom: 30,
    },
    headerNote: {
        fontSize: 16,
    },
    date: {
        fontSize: 12,
    },
    saveContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    save: {
        marginLeft: 10,
        marginTop: 3,
        fontSize: 16,
    },
    input: {
        height: 200,
        backgroundColor: 'white',
    },
    actionContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    statistics: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.highlight,
        borderRadius: 7,
        margin: 15,
    },
    statisticsLabel: {
        color: Colors.highlight,
        fontSize: 14,
        flex: 1,
        textAlign: 'center',
        padding: 12,
    },
});
