import React, {useEffect} from 'react';
import {FlatList, StyleSheet, Image, View} from 'react-native';

import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import CustomModal, {CustomModalProps, Alert} from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import ExpertCard from '~/components/expert/ExpertCard';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import expert from '~/queries/fragments/expert';
import {useExpertsDetails} from '~/queries/useExperts';

import {Skins, Skins_skins_edges_node as Skin} from './types/Skins';

export default function PopupSkin({onRequestClose, ...props}: PopupSkinProps) {
    const {isDesktop} = useDeviceQuery();
    const {me} = React.useContext(UserContext);
    const {data} = useExpertsDetails({});
    const [mutateSkin] = useMutation(SET_SKIN);
    const [alertVisible, setAlertVisible] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
    const [originalSkin, setOriginalSkin] = React.useState<string>('');
    const [selectedSkin, setSelectedSkin] = React.useState<string>('');

    React.useEffect(() => {
        const skin = me?.expert?.skinType;
        skin && setOriginalSkin(skin.toString());
    }, [props.visible]);

    function onFakeProfilePress() {
        setAlertMessage(
            'Les utilisateurs de Kyvoitou.fr pourront afficher les détails de votre profil grâce à ce bouton.'
        );
        setAlertVisible(true);
    }

    async function selectSkin(id: string) {
        setSelectedSkin(id);
        await updateSkin(id);
    }

    async function updateSkin(id: string) {
        id && (await mutateSkin({variables: {id}}));
        logEvent('modify_expert', {fields: 'skin'});
    }

    function cancel() {
        updateSkin(originalSkin);
        onRequestClose?.();
    }

    function validate() {
        onRequestClose?.();
    }

    const expert = data?.findExperts?.edges?.find((f) => f?.node?.userId == me?.userId);

    return (
        <CustomModal
            title="Choisir une image de fond"
            message="Cette image sera utilisée pour le fond votre profil public (recherche et fiche)."
            iconName="image"
            hasCloseIcon
            onRequestClose={cancel}
            {...props}
        >
            <View style={styles.view}>
                {isDesktop && me && me?.isExpert && expert?.node && (
                    <ExpertCard
                        user={expert?.node}
                        key={me.id}
                        style={styles.expert}
                        onPressProfile={onFakeProfilePress}
                    />
                )}
                <SkinPicker
                    onSelection={selectSkin}
                    visible={props.visible}
                    originalSkin={originalSkin}
                    selectedSkin={selectedSkin}
                />
            </View>
            <Alert
                title="Information"
                message={alertMessage ?? ''}
                visible={alertVisible}
                buttonText="Merci"
                onRequestClose={() => setAlertVisible(false)}
            />
            <View style={styles.buttons}>
                <Button
                    icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                    title="Ok"
                    onPress={validate}
                    style={styles.button}
                />
                <Button title="Annuler" onPress={cancel} style={styles.button} />
            </View>
        </CustomModal>
    );
}

type SkinPickerProps = {
    onSelection?: (id: string) => void;
    visible: boolean | undefined;
    originalSkin: string;
    selectedSkin: string;
};

const SKIN_BY_PAGE = 6;

function SkinPicker({onSelection, visible, originalSkin, selectedSkin}: SkinPickerProps) {
    const [load, {called, loading, data, refetch}] = useLazyQuery<Skins>(SKINS_QUERY);
    const [page, setPage] = React.useState<number>(1);
    const {isDesktop} = useDeviceQuery();

    React.useEffect(() => {
        if (data?.skins?.edges) {
            const lst = data?.skins?.edges?.map((s) =>
                s?.node?.skinType ? parseInt(s.node?.skinType) : 0
            );

            const idx = lst?.indexOf(parseInt(originalSkin)) ?? 0;

            setPage(Math.floor(idx / SKIN_BY_PAGE) + 1);
        }
    }, [data]);

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    React.useEffect(() => {
        reload();
    }, [visible]);

    if (loading && !data) {
        return <></>;
    }

    function renderItem(item?: Skin | null | undefined) {
        if (!item || !item?.skinType) {
            return null;
        }
        const skinType = item.skinType;
        const original = originalSkin == item.skinType;
        const selected = selectedSkin == item.skinType;

        const selectedStyle = selected ? styles.selected : {};
        const originalStyle = original ? styles.original : {};

        return (
            <View style={{width: 200}} key={item.skinType}>
                <TouchableOpacity
                    style={[styles.border, selectedStyle, originalStyle]}
                    onPress={() => onSelection?.(skinType)}
                    key={skinType}
                >
                    <Image
                        source={{uri: Settings.getUrlImageSkinType(skinType, 'small')}}
                        style={{width: 150, height: 100, margin: 20}}
                        resizeMode="center"
                        accessibilityLabel={'Skin ' + skinType}
                    />
                </TouchableOpacity>
            </View>
        );
    }

    const skins = data?.skins?.edges.map((e) => e?.node);

    const nbSkins = skins?.length ?? 0;
    const division = nbSkins / SKIN_BY_PAGE;
    const nbPage = nbSkins > 0 ? Math.ceil(division) : 0;
    let pages = nbPage > 0 ? Array.from(Array(nbPage + 1).keys()) : [];
    pages.shift();

    const start = (page - 1) * SKIN_BY_PAGE;
    const end = page * SKIN_BY_PAGE;
    const pageSkins = skins?.slice(start, end);

    return (
        <View
            style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}
        >
            <View
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    maxWidth: 450,
                    marginTop: 20,
                }}
            >
                {pageSkins?.map((s) => renderItem(s))}
            </View>
            <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                {pages.map((p) => (
                    <TouchableOpacity
                        onPress={() => setPage(p)}
                        style={{padding: 5}}
                        key={p.toString()}
                    >
                        <SimpleText style={{fontWeight: p == page ? 'bold' : 'normal'}}>
                            {p}
                        </SimpleText>
                    </TouchableOpacity>
                ))}
            </View>
        </View>
    );
}

const SKINS_QUERY = gql`
    query Skins {
        skins {
            edges {
                node {
                    skinType
                }
            }
        }
    }
`;

const SET_SKIN = gql`
    mutation setSkin($id: String!) {
        setSkin(input: {skinType: $id}) {
            ok
            user {
                ...ExpertDetails
            }
        }
    }
    ${expert}
`;

export type PopupSkinProps = Omit<CustomModalProps, 'children'> & {onSuccess: () => void};
const styles = StyleSheet.create({
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: 25,
    },
    button: {
        width: 200,
    },
    border: {
        borderWidth: 1,
        borderColor: Colors.dark,
        margin: 5,
    },
    selected: {
        borderColor: 'black',
    },
    original: {
        borderColor: Colors.disabled,
    },
    view: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
    },
    expert: {
        marginHorizontal: 40,
        marginTop: 25,
    },
});
