import React from 'react';
import {StyleSheet, ModalProps, Text} from 'react-native';

import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';

import DisableForm from './DisableForm';

export default function PopupDisable(props: ModalProps & {onSuccess: Function}) {
    const message = <SimpleText>Vous allez nous manquer !</SimpleText>;
    return (
        <CustomModal
            title="Supprimer mon compte"
            iconName="user-slash"
            message={message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            mobileContentMaxWidth={700}
        >
            <SimpleText style={styles.important}>
                <Text style={{fontWeight: 'bold'}}>ATTENTION : </Text>
                Cette action est irréversible, toutes vos données personnelles seront
                définitivement supprimées.
            </SimpleText>
            <DisableForm onSuccess={props.onSuccess} />
        </CustomModal>
    );
}

const styles = StyleSheet.create({
    important: {
        margin: 20,
        color: 'red',
        fontSize: 16,
        alignSelf: 'center',
        textAlign: 'center',
    },
});
