import React, {useCallback, useState} from 'react';
import {StyleSheet, TouchableOpacity, View, Image, ImageBackground} from 'react-native';

import * as Icons from '~/components/common/Icons';
import {Separator} from '~/components/common/Separator';
import {SimpleText} from '~/components/common/Texts';
import PopupPack from '~/components/pack/PopupPack';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {GetPackQuery_getPacks_edges_node} from '~/queries/types/GetPackQuery';
import {useUserPacks} from '~/queries/usePack';
import {PackType} from '~/types/graphql-global-types';

import {useHoverableProps} from '../common/HoverableView';

export default function PackList() {
    const {isDesktop} = useDeviceQuery();
    const {packs} = useUserPacks({showPacks: 'all'});
    const [pack, setPack] = useState<GetPackQuery_getPacks_edges_node | null>(null);

    function renderPack(item: GetPackQuery_getPacks_edges_node) {
        return <Pack packData={item} onPress={setPack} />;
    }

    return (
        <>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: 5,
                    paddingHorizontal: 5,
                }}
            >
                <Icons.Hand
                    size={32}
                    color={Colors.secondary}
                    style={{marginRight: 15, alignSelf: 'center'}}
                />
                <SimpleText
                    style={{
                        color: Colors.secondary,
                        fontSize: 20,
                        alignSelf: 'center',
                        textAlign: isDesktop ? 'left' : 'center',
                    }}
                >
                    Découvrez nos packs afin de pouvoir consulter en toute tranquillité, et
                    vous pouvez les utiliser avec tous nos voyants !
                </SimpleText>
            </View>
            <SimpleText style={styles.text}>
                Tous les packs sont cumulables. A chaque nouvel achat cumulé, la date de
                validité est repoussée !
            </SimpleText>
            <View style={styles.list}>{packs.map((p) => renderPack(p))}</View>
            <PopupPack
                pack={pack}
                onRequestClose={() => {
                    setPack(null);
                }}
            />
        </>
    );
}

type PackProps = {
    packData: GetPackQuery_getPacks_edges_node;
    onPress: (pack: GetPackQuery_getPacks_edges_node) => void;
};

function Pack({packData, onPress}: PackProps) {
    const typeText = packData?.packType == PackType.MINUTE ? 'Par chat ou appel' : 'Par email';
    const {isHovered, props: hoverableProps} = useHoverableProps();

    const mainText =
        packData?.packType == PackType.MINUTE
            ? `${packData?.minutes} minutes`
            : 'Étude complète';

    let url = Settings.getUrlBestSeller();
    if (packData?.decorationId) {
        url = Settings.getUrlDecoration(packData?.decorationId);
    }

    return (
        <TouchableOpacity
            onPress={() => onPress(packData)}
            key={packData?.packId}
            {...hoverableProps}
        >
            <PackBack pack={packData} isHovered={isHovered}>
                <>
                    {packData?.bestSeller && (
                        <Image
                            source={{uri: url}}
                            style={{
                                position: 'absolute',
                                right: '-9px',
                                top: '-9px',
                                width: 80,
                                height: 80,
                            }}
                            accessibilityLabel={'Best seller'}
                        />
                    )}
                    <SimpleText style={styles.title}>{packData?.name}</SimpleText>
                    <View style={styles.frame}>
                        <View style={styles.bloc}>
                            <SimpleText style={styles.minute}>{mainText}</SimpleText>
                            <View style={styles.container}>
                                <SimpleText style={styles.short}>
                                    {packData?.shortDescription}
                                </SimpleText>
                            </View>
                            <SimpleText style={styles.type}>{typeText}</SimpleText>
                            <Separator style={styles.separator} />
                            <SimpleText style={styles.price}>{packData?.amount} €</SimpleText>
                        </View>
                        {packData?.packType == PackType.MINUTE && (
                            <SimpleText style={styles.economy}>
                                Soit une économie de {packData?.economy} €
                            </SimpleText>
                        )}
                        {packData?.packType == PackType.EMAIL && (
                            <SimpleText style={styles.economy}>
                                Forfait {packData?.name}
                            </SimpleText>
                        )}
                    </View>
                </>
            </PackBack>
        </TouchableOpacity>
    );
}

type PackBackProps = {
    pack: GetPackQuery_getPacks_edges_node | null;
    isHovered: boolean;
    children: JSX.Element;
};

function PackBack(props: PackBackProps) {
    const decorationId = props.pack?.decorationId;

    if (decorationId) {
        return (
            <ImageBackground
                style={[styles.back, props.isHovered ? styles.backHover : {}]}
                imageStyle={{borderRadius: 8}}
                source={{
                    uri: Settings.getUrlDecorationBack(decorationId),
                }}
                accessibilityLabel={decorationId}
            >
                {props.children}
            </ImageBackground>
        );
    }

    return (
        <View style={[styles.back, styles.gold, props.isHovered ? styles.backHover : {}]}>
            {props.children}
        </View>
    );
}

const styles = StyleSheet.create({
    list: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        marginVertical: 30,
    },
    back: {
        width: 175,
        height: 300,
        resizeMode: 'contain',
        margin: 10,
        borderRadius: 8,
        alignSelf: 'center',
    },
    backHover: {
        width: 195,
        height: 320,
        margin: 0,
    },
    gold: {
        backgroundColor: Colors.primary,
    },
    light: {
        backgroundColor: 'rgb(186, 174, 146)',
    },
    frame: {
        backgroundColor: 'white',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginHorizontal: 10,
        marginBottom: 10,
        paddingVertical: 10,
    },
    bloc: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        alignSelf: 'center',
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        marginVertical: 20,
    },
    minute: {
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    short: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
        width: 150,
    },
    type: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
    },
    separator: {
        backgroundColor: Colors.highlight,
        marginVertical: 15,
        height: 2,
        alignSelf: 'center',
        width: '40%',
    },
    price: {
        fontSize: 28,
        fontWeight: 'bold',
        backgroundImage: '-webkit-linear-gradient(left, #4bc0c8, #c779d0, #feac5e)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        alignSelf: 'center',
        textAlign: 'center',
    },
    economy: {
        fontSize: 10,
        fontStyle: 'italic',
        alignSelf: 'center',
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    text: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        paddingHorizontal: 5,
        marginTop: 10,
    },
});
