import * as React from 'react';

import {Alert as BaseAlert, AlertProps as BaseAlertProps} from '~/components/common/Modal';

const DEFAULT_SHOW_FUNCTION = (p: AlertProps) => {};
export const AlertContext = React.createContext<ShowFunction>(DEFAULT_SHOW_FUNCTION);

export function AlertProvider(props: {children: React.ReactNode}) {
    // https://mmiller42.medium.com/advanced-compositional-react-with-usecontext-useref-and-usestate-51702dfedc71
    const [alert, setAlert] = React.useState<AlertHandle | null>(null);
    return (
        <AlertContext.Provider value={alert?.show ?? DEFAULT_SHOW_FUNCTION}>
            {props.children}
            <Alert ref={setAlert} />
        </AlertContext.Provider>
    );
}

export function useAlert() {
    return React.useContext(AlertContext);
}

export const Alert = React.forwardRef<AlertHandle, {}>((props, ref) => {
    const [visible, setVisible] = React.useState(false);
    const [alertProps, setAlertProps] = React.useState<AlertProps | null>();

    React.useImperativeHandle(ref, () => ({show}), []);

    function show(showProps: AlertProps) {
        setAlertProps(showProps);
        setVisible(true);
    }

    function close() {
        setVisible(false);
        alertProps?.onClose?.();
    }

    if (!alertProps) {
        // console.log('Error while displaying an alert');
        return null;
    }

    return (
        <BaseAlert
            width={alertProps.width ?? 300}
            title={alertProps?.title ?? 'Notification'}
            message={alertProps?.message}
            visible={visible}
            onRequestClose={close}
            buttonText={alertProps?.buttonText ?? 'Ok'}
            content={alertProps.content}
        />
    );
});

export type AlertProps = BaseAlertProps & {onClose?: () => void};

export type ShowFunction = (props: AlertProps) => void;

export type AlertHandle = {
    show: ShowFunction;
};
