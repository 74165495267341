import * as React from 'react';
import {StyleSheet, ScrollView, ViewStyle, View} from 'react-native';

import Footer, {FooterMobile} from '~/components/Footer';
import {Seo} from '~/components/Seo';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {MOBILE_MAX_WIDTH} from '~/constants/Layout';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export type ViewProps = View['props'];
export type InputProps = {style?: ViewStyle} & ViewProps;

export const MainView = React.forwardRef<ScrollView, InputProps>(({style, ...props}, ref) => {
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles.container : mobileStyles.container;
    return (
        <>
            <Seo />
            <ScrollView ref={ref} contentContainerStyle={{flexGrow: 1}}>
                <View style={[deviceStyle, style]} {...props} />
                {isDesktop && <Footer />}
                {!isDesktop && <FooterMobile />}
            </ScrollView>
        </>
    );
});

export function CardView({shadow, style, ...props}: CardViewProps) {
    const shadowStyle = shadow ? styles.cardShadow : {};
    return <View style={[styles.card, shadowStyle, style]} {...props} />;
}

export function Bloc({title, iconName, style, children, ...props}: BlocProps) {
    const {isDesktop} = useDeviceQuery();
    const blocStyle = isDesktop ? styles.bloc : mobileStyles.bloc;

    return (
        <View style={[blocStyle, style]} {...props}>
            {title && (
                <View style={styles.blocTitleContainer}>
                    {iconName && Icons.getIcon(iconName, 18, Colors.secondary)}
                    <View style={styles.blocTitleTextContainer}>
                        <SimpleText style={styles.blocTitle}>{title}</SimpleText>
                    </View>
                </View>
            )}
            {children}
        </View>
    );
}

export type CardViewProps = ViewProps & {
    shadow?: boolean;
};

export type BlocProps = ViewProps & {
    title?: string;
    iconName?: string;
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: 'center',
        maxWidth: 1366,
        width: '100%',
    },
    emphase: {
        flex: 1,
        backgroundColor: Colors.dark,
        padding: 50,
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    card: {
        borderRadius: 10,
        backgroundColor: 'white',
        padding: 13,
        overflow: 'hidden',
    },
    cardShadow: {
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
    },
    bloc: {
        width: 350,
        backgroundColor: Colors.dark,
        marginTop: 20,
        alignSelf: 'center',
        padding: 10,
    },
    blocTitleContainer: {
        flexDirection: 'row',
        margin: 16,
    },
    blocTitleTextContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    blocTitle: {
        fontWeight: 'bold',
        fontSize: 15,
        textTransform: 'uppercase',
        color: Colors.secondary,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: 'center',
        maxWidth: MOBILE_MAX_WIDTH,
        width: '100%',
        backgroundColor: Colors.dark,
    },
    bloc: {
        width: 350,
        backgroundColor: 'white',
        marginVertical: 10,
        marginHorizontal: 5,
        alignSelf: 'center',
        padding: 10,
    },
});
