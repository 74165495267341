import {gql, useQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import {GetPackOrderQuery} from './types/GetPackOrderQuery';

const GET_PACK_ORDER_QUERY = gql`
    query GetPackOrderQuery($paymentIntentId: String, $sessionId: String) {
        getPackOrder(paymentIntentId: $paymentIntentId, sessionId: $sessionId) {
            packOrderId
            status
            packId
            amount
            created
        }
    }
`;

export default function usePackOrder(
    paymentIntentId?: string | null | undefined,
    sessionId?: string | null | undefined
) {
    const options: QueryHookOptions = {
        variables: {
            paymentIntentId: paymentIntentId,
            sessionId: sessionId,
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    };

    const query = useQuery<GetPackOrderQuery>(GET_PACK_ORDER_QUERY, options);

    return query;
}
