import React, {useState, useEffect} from 'react';
import {View, StyleSheet, Image, ModalProps} from 'react-native';

import {gql, useMutation} from '@apollo/client';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import * as Colors from '~/constants/Colors';
import logEvent from '~/helpers/analytics';

import {SetProfilePicture} from './types/SetProfilePicture';

export default function PopupImage(props: PopupImageProps) {
    const [setProfilePicture, {data, error, loading}] = useMutation<SetProfilePicture>(
        SET_profilePictureTURE
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
            props.onSuccess();
            logEvent('modify_account', {fields: 'picture'});
        }
    }, [data]);

    async function enregistrerImage() {
        const variables = {
            base64Image: props.selectedImage,
            squareLength: 200,
        };

        setProfilePicture({variables});
        setIsLoading(true);
    }

    return (
        <CustomModal
            title="Changer la photo de profil"
            iconName="portrait"
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
        >
            <Image
                style={styles.selectedImage}
                source={{
                    uri: props.selectedImage,
                }}
                accessibilityLabel={'Photo de profil'}
            />
            <View style={styles.buttonContainer}>
                <Button
                    icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                    title={'Valider'}
                    buttonStyle={styles.button}
                    onPress={enregistrerImage}
                    loading={isLoading}
                />
            </View>
        </CustomModal>
    );
}

const SET_profilePictureTURE = gql`
    mutation SetProfilePicture($base64Image: String!, $squareLength: Int!) {
        setProfilePicture(input: {base64Image: $base64Image, squareLength: $squareLength}) {
            profile {
                id
                pictureName
            }
        }
    }
`;

export type PopupImageProps = ModalProps & {
    onSuccess: () => void;
    selectedImage: string;
};

const styles = StyleSheet.create({
    modalPictureTitleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    modalPicturePicto: {
        width: 29,
        height: 22,
    },
    modalPictureTitle: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
    },
    close: {
        width: 23,
        height: 20,
    },
    button: {
        margin: 10,
        width: 160,
    },
    buttonCancel: {
        marginLeft: 10,
    },
    selectedImage: {
        alignSelf: 'center',
        width: 200,
        height: 200,
        marginTop: 19,
        borderColor: Colors.primary,
        borderWidth: 2,
        borderRadius: 5,
    },
    buttonContainer: {
        flex: 1,
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
