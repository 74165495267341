import React, {useState} from 'react';

import {useFocusEffect} from '@react-navigation/native';
import {Helmet} from 'react-helmet';

import {getCurrentRouteName} from '~/navigation/index';

export function Seo() {
    const [config, setConfig] = useState<SeoConfig | null>(null);

    useFocusEffect(
        React.useCallback(() => {
            const requestId = requestAnimationFrame(loadConfig);
            return () => cancelAnimationFrame(requestId);
        }, [])
    );

    function loadConfig() {
        const currentRouteName = getCurrentRouteName();

        let seo = null;
        for (var i = 0; i < SeoConfigs.length; i++) {
            const c = SeoConfigs[i];
            if (c.route == currentRouteName) {
                seo = c;
            }
        }

        setConfig(seo);
    }

    return (
        <Helmet>
            <html lang="fr" translate="no" />
            {config && <link rel="canonical" href={config.canonical} />}
            {config && <meta name="description" content={config.description} />}
            <meta http-equiv="content-language" content="fr" />
            <meta name="google" content="notranslate" />
        </Helmet>
    );
}

type SeoConfig = {
    route: string;
    canonical: string;
    description: string;
};

const SeoConfigs = [
    {
        route: 'HomeScreen',
        canonical: 'https://www.kyvoitou.fr',
        description:
            'Kyvoitou.fr favorise le développement personnel pour vous permettre de devenir la meilleure version éveillée de vous même !',
    } as SeoConfig,
    {
        route: 'SearchScreen',
        canonical: 'https://www.kyvoitou.fr/experts',
        description:
            'Dans la catégorie qui vous intéresse, choisissez un spirite dont la spécialité correspond à votre situation.',
    } as SeoConfig,
    {
        route: 'HowToScreen',
        canonical: 'https://www.kyvoitou.fr/comment-ca-marche',
        description: 'Vous avez trouvé le spirite que vous voulez joindre ? Parfait !',
    } as SeoConfig,
    {
        route: 'PriceScreen',
        canonical: 'https://www.kyvoitou.fr/nos-tarifs',
        description: 'Plusieurs façon de profiter de nos offres en fonction de vos moyens.',
    } as SeoConfig,
    {
        route: 'CommitmentScreen',
        canonical: 'https://www.kyvoitou.fr/nos-engagements',
        description:
            'Les spirites de Kyvoitou.fr sont sélectionnés selon des critères rigoureux.',
    } as SeoConfig,
    {
        route: 'FaqScreen',
        canonical: 'https://www.kyvoitou.fr/faq',
        description: 'Comment pouvons nous vous aidez ?',
    } as SeoConfig,
    {
        route: 'AboutScreen',
        canonical: 'https://www.kyvoitou.fr/qui-sommes-nous',
        description:
            "Venez tenter l'expérience d'un coaching divinatoire pour prendre les plus belles décisions de votre vie.",
    } as SeoConfig,
    {
        route: 'ContactScreen',
        canonical: 'https://www.kyvoitou.fr/contact',
        description: 'Contactez-nous par email ou par téléphone.',
    } as SeoConfig,
] as SeoConfig[];
