import React, {useState} from 'react';
import {View, StyleSheet, TouchableOpacity, ModalProps} from 'react-native';

import {gql, useMutation, useQuery} from '@apollo/client';
import {Picker} from '@react-native-picker/picker';

import Button from '~/components/common/Buttons';
import {ReviewInput} from '~/components/common/Inputs';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {useAlert} from '~/contexts/AlertContext';
import logEvent from '~/helpers/analytics';
import * as dates from '~/helpers/dates';
import {QualityEnum, GradeEnum} from '~/types/enum';

import QualityStar from './QualityStar';
import type {ReviewExpertInfo} from './types/ReviewExpertInfo';
import type {ReviewData} from './types/calls';
import {CommunicationType} from './types/calls';

const ADD_REVIEW = gql`
    mutation addReview(
        $communicationId: String!
        $communicationType: String!
        $comment: String!
        $gradeId: Int!
        $qualities: [ReviewQualityInput]
    ) {
        addReview(
            input: {
                communicationId: $communicationId
                communicationType: $communicationType
                comment: $comment
                gradeId: $gradeId
                qualities: $qualities
            }
        ) {
            review {
                id
            }
        }
    }
`;

const REVIEW_EXPERT_INFO = gql`
    query ReviewExpertInfo($expertId: ID!) {
        user(id: $expertId) {
            id
            profile {
                id
                displayName
            }
        }
    }
`;

const defaultQualities = [
    {qualityId: 1, starNumber: 0},
    {qualityId: 2, starNumber: 0},
    {qualityId: 3, starNumber: 0},
    {qualityId: 4, starNumber: 0},
];

export default function PopupWriteReview(
    props: ModalProps & {reviewData: ReviewData; onSuccess?: Function}
) {
    const alert = useAlert();
    const [addReview, {data, error: mutationError}] = useMutation(ADD_REVIEW, {
        errorPolicy: 'all',
    });

    const expertId = props.reviewData.expertId;
    const variables = {expertId};
    const {
        data: expertData,
        loading: expertLoading,
        error: expertError,
    } = useQuery<ReviewExpertInfo>(REVIEW_EXPERT_INFO, {
        variables,
        skip: expertId == null,
    });
    const [gradeId, setGradeId] = useState(0);
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');
    const [qualities, setQualities] = useState(defaultQualities);

    function setStar(qualityId: number, starNumber: number) {
        let newQualities = [...qualities];
        let quality = newQualities.find((e) => e.qualityId == qualityId);
        if (quality) {
            quality.starNumber = starNumber;
        }
        setQualities(newQualities);
    }

    function getCommunicationType() {
        if (props.reviewData.communicationType == CommunicationType.CALL) {
            return "l'appel";
        } else if (props.reviewData.communicationType == CommunicationType.MESSAGE) {
            return 'le message';
        } else if (props.reviewData.communicationType == CommunicationType.CHAT) {
            return 'le chat';
        }
    }

    async function review() {
        if (gradeId == 0) {
            setError('Le niveau de satisfaction est obligatoire');
            return;
        }
        if (comment == '') {
            setError("L'évaluation est obligatoire");
            return;
        }

        setError('');

        const data = {
            communicationId: props.reviewData.communicationId,
            communicationType: props.reviewData.communicationType,
            comment: comment,
            gradeId: gradeId,
            qualities: qualities,
        };
        const result = await addReview({variables: data});
        props.onSuccess && props.onSuccess();
        alert({
            title: 'Information',
            message: 'Votre avis a bien été enregistré.',
        });
        logEvent('review_written');
    }

    const expertName = expertData?.user?.profile?.displayName;
    const date = props.reviewData.date;
    const type = getCommunicationType();

    return (
        <CustomModal
            title={'Votre avis compte !'}
            iconName="poll"
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
        >
            <View>
                <View style={styles.dateContainer}>
                    <SimpleText style={styles.blocTitle}>Date de communication</SimpleText>
                    <SimpleText style={styles.callDate}>
                        Le {dates.dateString(date)} à {dates.timeString(date)}
                    </SimpleText>
                </View>
                <View style={styles.gradeContainer}>
                    <SimpleText style={styles.blocTitle}>
                        Mon impression sur la consultation
                    </SimpleText>
                    <Picker
                        selectedValue={gradeId}
                        style={styles.pickerContainer}
                        onValueChange={(itemIndex, itemValue) => setGradeId(itemValue)}
                    >
                        <Picker.Item label="Satisfaction..." value="0" />
                        <Picker.Item label={GradeEnum.VERY_SATISFIED} value="1" />
                        <Picker.Item label={GradeEnum.SATISFIED} value="2" />
                        <Picker.Item label={GradeEnum.DISSATISFIED} value="3" />
                        <Picker.Item label={GradeEnum.VERY_DISSATISFIED} value="4" />
                    </Picker>
                </View>
                <View style={styles.qualityContainer}>
                    <SimpleText style={styles.blocTitle}>Qualités</SimpleText>
                    <View style={styles.starContainer}>
                        <QualityStar
                            quality={QualityEnum.LISTEN}
                            qualityId={1}
                            setStar={setStar}
                        />
                        <QualityStar
                            quality={QualityEnum.HOST}
                            qualityId={2}
                            setStar={setStar}
                        />
                        <QualityStar
                            quality={QualityEnum.PRICE}
                            qualityId={3}
                            setStar={setStar}
                        />
                        <QualityStar
                            quality={QualityEnum.RESPONSE}
                            qualityId={4}
                            setStar={setStar}
                        />
                    </View>
                </View>
                <View style={styles.reviewContainer}>
                    <SimpleText style={styles.blocTitle}>Mon commentaire</SimpleText>
                    <ReviewInput onChangeText={setComment} style={{height: 90}} />
                </View>
                <View>
                    <SimpleText style={styles.reviewError}>{error}</SimpleText>
                </View>
                <View style={styles.reviewButtonContainer}>
                    <TouchableOpacity onPress={props.onRequestClose}>
                        <SimpleText style={styles.cancelButton}>
                            Donner mon avis plus tard
                        </SimpleText>
                    </TouchableOpacity>
                    <Button
                        style={styles.reviewButton}
                        title="Evaluer"
                        onPress={review}
                        titleStyle={{fontSize: 14}}
                        containerStyle={{padding: 2}}
                    />
                </View>
            </View>
        </CustomModal>
    );
}

const styles = StyleSheet.create({
    description: {
        textAlign: 'center',
        fontSize: 14,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16,
        height: 130,
    },
    modalReviewTitleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    modalReviewPicto: {
        width: 29,
        height: 22,
    },
    modalReviewTitle: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
    },
    close: {
        width: 23,
        height: 20,
    },
    blocTitle: {
        color: Colors.secondary,
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    dateContainer: {
        marginTop: 20,
        padding: 10,
        backgroundColor: 'white',
    },
    gradeContainer: {
        marginTop: 20,
        padding: 10,
        backgroundColor: 'white',
    },
    qualityContainer: {
        marginTop: 20,
        padding: 10,
        backgroundColor: 'white',
    },
    reviewContainer: {
        marginTop: 20,
        padding: 10,
        backgroundColor: 'white',
    },
    callDate: {
        marginLeft: 10,
    },
    pickerContainer: {
        marginLeft: 10,
        width: 200,
    },
    starContainer: {
        marginLeft: 10,
    },
    reviewError: {
        color: Colors.errors,
        fontSize: 12,
        height: 15,
        alignSelf: 'center',
        marginTop: 5,
    },
    reviewButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 20,
    },
    cancelButton: {
        color: Colors.link,
        paddingTop: 25,
    },
    reviewButton: {
        width: 100,
        textTransform: 'upper',
    },
});
