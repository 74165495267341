import * as React from 'react';
import {StyleSheet} from 'react-native';

import {Button as DefaultButton} from 'react-native-elements';
import type {ButtonProps as DefaultButtonProps} from 'react-native-elements';

import {getIcon} from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';

export default function Button({
    style,
    titleStyle,
    buttonStyle,
    containerStyle,
    icon,
    iconName,
    ...otherProps
}: ButtonProps) {
    const buttonIcon = icon
        ? icon
        : iconName
        ? getIcon(iconName, 20, 'white', {marginRight: 25})
        : undefined;
    return (
        <DefaultButton
            style={[styles.style, style]}
            titleStyle={[styles.title, titleStyle]}
            buttonStyle={[styles.button, buttonStyle]}
            containerStyle={[styles.container, containerStyle]}
            icon={buttonIcon}
            {...otherProps}
        />
    );
}

export interface ButtonProps extends DefaultButtonProps {
    /** Le nom de l'icône utilisée, la liste est ici : https://fontawesome.com/icons?d=gallery&p=2&m=free  */
    iconName?: string;
}

const styles = StyleSheet.create({
    style: {
        margin: 15,
        alignSelf: 'center',
        color: 'white',
    },
    button: {
        backgroundColor: Colors.primary,
    },
    title: {},
    container: {},
});
