import React, {useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {Picker} from '@react-native-picker/picker';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import {BallItem} from '~/components/common/IconText';
import * as Icons from '~/components/common/Icons';
import {
    BirthDateInput,
    BirthDayCityInput,
    BirthDayPostalCodeInput,
    BirthHourInput,
    BirthNameInput,
    BirthPlaceInput,
    FirstNameInput,
    OtherNameInput,
    SpouseBirthDateInput,
    SpouseBirthHourInput,
    SpouseBirthNameInput,
    SpouseBirthPlaceInput,
    SpouseFirstNameInput,
    SpouseOtherNameInput,
} from '~/components/common/Inputs';
import {FormikErrorMessage, FormLabel, SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import {SmallCard} from '~/components/expert/ExpertCard';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import {Expert, filterIncompleteProfile} from '~/helpers/experts';
import {axios} from '~/helpers/network';
import {getStudyPerson, getStudyTag} from '~/helpers/pack';
import yup, {yupRules, YupTypes} from '~/helpers/yup';
import {GetPackQuery_getPacks_edges_node} from '~/queries/types/GetPackQuery';
import useExperts, {useExpertsDetails} from '~/queries/useExperts';
import usePack from '~/queries/usePack';
import {PackParamList} from '~/types';
import {StudyType} from '~/types/graphql-global-types';

type StudyContentProps = {
    pack: GetPackQuery_getPacks_edges_node | null;
    goToBasket: () => void;
    goToLogin: () => void;
};

export default function StudyContent(props: StudyContentProps) {
    const [expert, setExpert] = useState<Expert | null>(null);
    const alert = useAlert();
    const {me, loadingMe} = React.useContext(UserContext);
    const navigation = useNavigation();
    const {data: expertList} = useExpertsDetails({});

    const pack = props.pack;

    const renderExpertCard = (expert: Expert) => {
        function onPressProfile() {
            setExpert(expert);
        }

        return <SmallCard user={expert} onPressProfile={onPressProfile} key={expert?.id} />;
    };

    const allExperts = expertList?.findExperts?.edges.map((e) => e?.node);
    let experts = filterIncompleteProfile(allExperts);

    const tagLabel = getStudyTag(pack?.studyType);

    const filteredExperts = experts.filter((e) =>
        e.tags?.edges?.some((t) => t?.node?.label == tagLabel)
    );

    const label = getStudyPerson(pack?.studyType);

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return (
            <View>
                <SimpleText style={styles.title}>
                    Vous devez être connecter pour procéder
                </SimpleText>
                <Button
                    titleStyle={{fontSize: 16, marginHorizontal: 20}}
                    title={'Se connecter'}
                    onPress={props.goToLogin}
                />
            </View>
        );
    }

    return (
        <View>
            {pack && !expert && (
                <View style={styles.container}>
                    <SimpleText style={styles.title}>
                        Afin de profiter de votre pack {pack?.name}, merci de sélectionner un
                        voyant :
                    </SimpleText>
                    <View style={styles.list}>{filteredExperts.map(renderExpertCard)}</View>
                </View>
            )}
            {pack && expert && (
                <View style={styles.container}>
                    <SimpleText style={styles.title}>Vous avez sélectionné :</SimpleText>
                    <View style={styles.list}>
                        <SmallCard user={expert} key={expert?.id} />
                    </View>
                    <TouchableOpacity onPress={() => setExpert(null)}>
                        <SimpleText style={styles.change}>Changer</SimpleText>
                    </TouchableOpacity>
                    <SimpleText style={styles.title}>
                        Afin de pouvoir mener votre étude complète, notre {label} a besoin de
                        quelques informations à votre sujet :
                    </SimpleText>
                    {pack?.studyType == StudyType.NUMEROLOGY && (
                        <NumerologyForm
                            pack={pack}
                            expert={expert}
                            goToBasket={props.goToBasket}
                        />
                    )}
                    {pack?.studyType == StudyType.ASTROLOGY && (
                        <AstrologyForm
                            pack={pack}
                            expert={expert}
                            goToBasket={props.goToBasket}
                        />
                    )}
                </View>
            )}
        </View>
    );
}

type StudyFormProps = {
    pack: GetPackQuery_getPacks_edges_node;
    expert: Expert;
    goToBasket: () => void;
};

function NumerologyForm(props: StudyFormProps) {
    const navigation = useNavigation();

    type Inputs = Pick<
        YupTypes,
        'firstname' | 'othername' | 'birthdate' | 'birthhour' | 'birthplace'
    >;
    type Helpers = FormikHelpers<Inputs>;

    async function submit(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        const data = {
            expertid: props.expert?.userId,
            packname: props.pack?.name,
            firstname: values.firstname,
            othername: values.othername,
            birthdate: values.birthdate,
            birthhour: values.birthhour,
            birthplace: values.birthplace,
        };

        const body = JSON.stringify(data);
        const response = await axios.post(endpoints.saveStudy, body);

        // console.log('response = ', response);
        props.goToBasket();
    }

    const {firstname, othername, birthdate, birthhour, birthplace} = yupRules;
    const validationSchema = yup
        .object()
        .shape({firstname, othername, birthdate, birthhour, birthplace});

    return (
        <Formik
            initialValues={{
                firstname: '',
                othername: '',
                birthdate: '',
                birthhour: '',
                birthplace: '',
            }}
            onSubmit={submit}
            validationSchema={validationSchema}
        >
            {(formikProps) => (
                <View style={styles.form}>
                    <View>
                        <FormLabel style={styles.label}>Votre prénom</FormLabel>
                        <FirstNameInput
                            value={formikProps.values.firstname}
                            onChangeText={formikProps.handleChange('firstname')}
                            onBlur={() => formikProps.setFieldTouched('firstname')}
                            errorMessage={formikProps.errors.firstname || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>
                            Si vous en avez, vos autres prénoms
                        </FormLabel>
                        <OtherNameInput
                            value={formikProps.values.othername}
                            onChangeText={formikProps.handleChange('othername')}
                            onBlur={() => formikProps.setFieldTouched('othername')}
                            errorMessage={formikProps.errors.othername || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>Votre date de naissance</FormLabel>
                        <BirthDateInput
                            value={formikProps.values.birthdate}
                            onChangeText={formikProps.handleChange('birthdate')}
                            onBlur={() => formikProps.setFieldTouched('birthdate')}
                            errorMessage={formikProps.errors.birthdate || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>Votre heure de naissance</FormLabel>
                        <BirthHourInput
                            value={formikProps.values.birthhour}
                            onChangeText={formikProps.handleChange('birthhour')}
                            onBlur={() => formikProps.setFieldTouched('birthhour')}
                            errorMessage={formikProps.errors.birthhour || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>Votre lieu de naissance</FormLabel>
                        <BirthPlaceInput
                            value={formikProps.values.birthplace}
                            onChangeText={formikProps.handleChange('birthplace')}
                            onBlur={() => formikProps.setFieldTouched('birthplace')}
                            errorMessage={formikProps.errors.birthplace || ' '}
                            style={styles.input}
                        />
                    </View>
                    <View style={styles.buttons}>
                        <Button
                            icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                            title="Valider"
                            buttonStyle={{
                                width: 160,
                                alignSelf: 'center',
                                marginTop: 10,
                            }}
                            disabled={!formikProps.isValid}
                            onPress={() => formikProps.handleSubmit()}
                            loading={formikProps.isSubmitting}
                        />
                    </View>
                    <SimpleText style={styles.title}>
                        Cette étude vous donnera accès à votre thème natal composé de :
                    </SimpleText>
                    <View style={styles.avantages}>
                        <BallItem text={`Votre nombre d'expression`} style={styles.avantage} />
                        <BallItem text={`Votre chemin de vie`} style={styles.avantage} />
                        <BallItem text={`Votre nombre intime`} style={styles.avantage} />
                        <BallItem
                            text={`Votre nombre de réalisation`}
                            style={styles.avantage}
                        />
                        <BallItem text={`Votre jour de naissance`} style={styles.avantage} />
                        <BallItem text={`Votre nombre d'équilibre`} style={styles.avantage} />
                        <BallItem text={`Vos trois cycles de vie`} style={styles.avantage} />
                        <BallItem text={`Votre grille d'inclusion`} style={styles.avantage} />
                        <BallItem
                            text={`Les défis à relever dans votre vie`}
                            style={styles.avantage}
                        />
                    </View>
                </View>
            )}
        </Formik>
    );
}

function AstrologyForm(props: StudyFormProps) {
    const navigation = useNavigation();

    type Inputs = Pick<
        YupTypes,
        | 'firstname'
        | 'othername'
        | 'birthdate'
        | 'birthhour'
        | 'birthplace'
        | 'astrologytype'
        | 'birthdaypostalcode'
        | 'birthdaycity'
        | 'spousefirstname'
        | 'spouseothername'
        | 'spousebirthdate'
        | 'spousebirthhour'
        | 'spousebirthplace'
    >;
    type Helpers = FormikHelpers<Inputs>;

    async function submit(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        const data = {
            expertid: props.expert?.userId,
            packname: props.pack?.name,
            studytype: props.pack?.studyType,
            firstname: values.firstname,
            othername: values.othername,
            birthdate: values.birthdate,
            birthhour: values.birthhour,
            birthplace: values.birthplace,
            astrologytype: values.astrologytype,
            birthdaypostalcode: values.birthdaypostalcode,
            birthdaycity: values.birthdaycity,
            spousefirstname: values.spousefirstname,
            spouseothername: values.spouseothername,
            spousebirthdate: values.spousebirthdate,
            spousebirthhour: values.spousebirthhour,
            spousebirthplace: values.spousebirthplace,
        };

        const body = JSON.stringify(data);
        const response = await axios.post(endpoints.saveStudy, body);

        // console.log('response = ', response);
        props.goToBasket();
    }

    const {
        firstname,
        othername,
        birthdate,
        birthhour,
        birthplace,
        astrologytype,
        birthdaypostalcode,
        birthdaycity,
        spousefirstname,
        spouseothername,
        spousebirthdate,
        spousebirthhour,
        spousebirthplace,
    } = yupRules;
    const validationSchema = yup.object().shape({
        firstname,
        othername,
        birthdate,
        birthhour,
        birthplace,
        astrologytype,
        birthdaypostalcode,
        birthdaycity,
        spousefirstname,
        spouseothername,
        spousebirthdate,
        spousebirthhour,
        spousebirthplace,
    });

    return (
        <Formik
            initialValues={{
                firstname: '',
                othername: '',
                birthdate: '',
                birthhour: '',
                birthplace: '',
                astrologytype: '',
                birthdaypostalcode: '',
                birthdaycity: '',
                spousefirstname: '',
                spouseothername: '',
                spousebirthdate: '',
                spousebirthhour: '',
                spousebirthplace: '',
            }}
            onSubmit={submit}
            validationSchema={validationSchema}
        >
            {(formikProps) => (
                <View style={styles.form}>
                    <View style={styles.fields}>
                        <FormLabel style={styles.label}>Je choisis mon étude</FormLabel>
                        <Picker
                            selectedValue={formikProps.values.astrologytype}
                            style={styles.picker}
                            onValueChange={(itemValue, itemIndex) => {
                                formikProps.setFieldValue('astrologytype', itemValue, false);
                            }}
                        >
                            <Picker.Item label=" - " value="" />
                            <Picker.Item label="Thème astral" value="1" />
                            <Picker.Item label="Météo de l'année" value="2" />
                            <Picker.Item label="Astro géographie" value="3" />
                            <Picker.Item label="Compatibilité couple" value="4" />
                        </Picker>
                        <FormikErrorMessage field="astrologytype" />

                        <View style={styles.text}>
                            {formikProps.values.astrologytype == '1' && (
                                <SimpleText style={styles.description}>
                                    Cette étude est basée sur le placement des planètes au
                                    moment précis de votre naissance. Ainsi, à l'aide de votre
                                    carte du ciel, vous pourrez découvrir les grandes lignes de
                                    votre chemin de vie. Grâce à cette étude, comprenez mieux
                                    votre personnalité et apprenez à vous connaître !
                                </SimpleText>
                            )}
                            {formikProps.values.astrologytype == '2' && (
                                <>
                                    <SimpleText style={styles.description}>
                                        Cette étude va permettre de vous donner la tendance
                                        générale de votre année à venir, en fonction de votre
                                        date d'anniversaire, associée au lieu où vous vous
                                        trouviez ce jour là, en parallèle de votre thème natal.
                                        Elle permet d'étudier votre année d'un anniversaire à
                                        l'autre et de vous aider à vous préparer au mieux à ce
                                        qui vous attend.
                                    </SimpleText>
                                </>
                            )}
                            {formikProps.values.astrologytype == '3' && (
                                <SimpleText style={styles.description}>
                                    Grâce à cette étude, découvrez quels sont les meilleurs
                                    lieu de voyage pour vous, les meilleurs endroits pour
                                    organiser un évènement ou encore pour vivre ! C'est un
                                    outil très juste qui a déjà prouvé sa justesse !
                                </SimpleText>
                            )}
                            {formikProps.values.astrologytype == '4' && (
                                <>
                                    <SimpleText style={styles.description}>
                                        Découvrez les compatibilités de votre couple à travers
                                        cette étude qui met en parallèle vos cartes du ciel
                                        respectives pour voir et comprendre les dates clés de
                                        votre vie de couple. Cette étude permet également de
                                        découvrir les grandes lignes de votre vie de couple et
                                        sur quels point vous vous rejoignez l'un l'autre.
                                    </SimpleText>
                                </>
                            )}
                        </View>
                        <FormLabel style={styles.label}>Votre prénom</FormLabel>
                        <FirstNameInput
                            value={formikProps.values.firstname}
                            onChangeText={formikProps.handleChange('firstname')}
                            onBlur={() => formikProps.setFieldTouched('firstname')}
                            errorMessage={formikProps.errors.firstname || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>
                            Si vous en avez, vos autres prénoms
                        </FormLabel>
                        <OtherNameInput
                            value={formikProps.values.othername}
                            onChangeText={formikProps.handleChange('othername')}
                            onBlur={() => formikProps.setFieldTouched('othername')}
                            errorMessage={formikProps.errors.othername || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>Votre date de naissance</FormLabel>
                        <BirthDateInput
                            value={formikProps.values.birthdate}
                            onChangeText={formikProps.handleChange('birthdate')}
                            onBlur={() => formikProps.setFieldTouched('birthdate')}
                            errorMessage={formikProps.errors.birthdate || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>Votre heure de naissance</FormLabel>
                        <BirthHourInput
                            value={formikProps.values.birthhour}
                            onChangeText={formikProps.handleChange('birthhour')}
                            onBlur={() => formikProps.setFieldTouched('birthhour')}
                            errorMessage={formikProps.errors.birthhour || ' '}
                            style={styles.input}
                        />
                        <FormLabel style={styles.label}>Votre lieu de naissance</FormLabel>
                        <BirthPlaceInput
                            value={formikProps.values.birthplace}
                            onChangeText={formikProps.handleChange('birthplace')}
                            onBlur={() => formikProps.setFieldTouched('birthplace')}
                            errorMessage={formikProps.errors.birthplace || ' '}
                            style={styles.input}
                            onKeyPress={(event) => {
                                if (event.nativeEvent.key == 'Enter') {
                                    formikProps.handleSubmit();
                                }
                            }}
                        />
                        {formikProps.values.astrologytype == '2' && (
                            <>
                                <FormLabel style={styles.label}>
                                    Lieu où vous étiez pour votre anniversaire
                                </FormLabel>
                                <BirthDayPostalCodeInput
                                    value={formikProps.values.birthdaypostalcode}
                                    onChangeText={formikProps.handleChange(
                                        'birthdaypostalcode'
                                    )}
                                    onBlur={() =>
                                        formikProps.setFieldTouched('birthdaypostalcode')
                                    }
                                    errorMessage={formikProps.errors.birthdaypostalcode || ' '}
                                    style={styles.input}
                                />
                                <BirthDayCityInput
                                    value={formikProps.values.birthdaycity}
                                    onChangeText={formikProps.handleChange('birthdaycity')}
                                    onBlur={() => formikProps.setFieldTouched('birthdaycity')}
                                    errorMessage={formikProps.errors.birthdaycity || ' '}
                                    style={styles.input}
                                />
                            </>
                        )}
                        {formikProps.values.astrologytype == '4' && (
                            <>
                                {/* <FormLabel style={styles.label}>
                                    Son nom de naissance
                                </FormLabel>
                                <SpouseBirthNameInput
                                    value={formikProps.values.spousebirthname}
                                    onChangeText={formikProps.handleChange('spousebirthname')}
                                    onBlur={() =>
                                        formikProps.setFieldTouched('spousebirthname')
                                    }
                                    errorMessage={formikProps.errors.spousebirthname || ' '}
                                    style={styles.input}
                                /> */}
                                <FormLabel style={styles.label}>Son prénom</FormLabel>
                                <SpouseFirstNameInput
                                    value={formikProps.values.spousefirstname}
                                    onChangeText={formikProps.handleChange('spousefirstname')}
                                    onBlur={() =>
                                        formikProps.setFieldTouched('spousefirstname')
                                    }
                                    errorMessage={formikProps.errors.spousefirstname || ' '}
                                    style={styles.input}
                                />
                                <FormLabel style={styles.label}>
                                    S'il/elle en a, ses autres prénoms
                                </FormLabel>
                                <SpouseOtherNameInput
                                    value={formikProps.values.spouseothername}
                                    onChangeText={formikProps.handleChange('spouseothername')}
                                    onBlur={() =>
                                        formikProps.setFieldTouched('spouseothername')
                                    }
                                    errorMessage={formikProps.errors.spouseothername || ' '}
                                    style={styles.input}
                                />
                                <FormLabel style={styles.label}>
                                    Sa date de naissance
                                </FormLabel>
                                <SpouseBirthDateInput
                                    value={formikProps.values.spousebirthdate}
                                    onChangeText={formikProps.handleChange('spousebirthdate')}
                                    onBlur={() =>
                                        formikProps.setFieldTouched('spousebirthdate')
                                    }
                                    errorMessage={formikProps.errors.spousebirthdate || ' '}
                                    style={styles.input}
                                />
                                <FormLabel style={styles.label}>
                                    Son heure de naissance
                                </FormLabel>
                                <SpouseBirthHourInput
                                    value={formikProps.values.spousebirthhour}
                                    onChangeText={formikProps.handleChange('spousebirthhour')}
                                    onBlur={() =>
                                        formikProps.setFieldTouched('spousebirthhour')
                                    }
                                    errorMessage={formikProps.errors.spousebirthhour || ' '}
                                    style={styles.input}
                                />
                                <FormLabel style={styles.label}>
                                    Son lieu de naissance
                                </FormLabel>
                                <SpouseBirthPlaceInput
                                    value={formikProps.values.spousebirthplace}
                                    onChangeText={formikProps.handleChange('spousebirthplace')}
                                    onBlur={() =>
                                        formikProps.setFieldTouched('spousebirthplace')
                                    }
                                    errorMessage={formikProps.errors.spousebirthplace || ' '}
                                    style={styles.input}
                                    onKeyPress={(event) => {
                                        if (event.nativeEvent.key == 'Enter') {
                                            formikProps.handleSubmit();
                                        }
                                    }}
                                />
                            </>
                        )}
                    </View>
                    <View style={styles.buttons}>
                        <Button
                            icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                            title="Valider"
                            buttonStyle={{
                                width: 160,
                                alignSelf: 'center',
                                marginTop: 10,
                            }}
                            disabled={!formikProps.isValid}
                            onPress={() => formikProps.handleSubmit()}
                            loading={formikProps.isSubmitting}
                        />
                    </View>

                    <SimpleText style={styles.title}>
                        Cette étude vous donnera accès à votre thème natal composé de :
                    </SimpleText>
                    <View style={styles.avantages}>
                        <BallItem text={`Votre carte natale`} style={styles.avantage} />
                        <BallItem
                            text={`Votre signe astrologique expliqué`}
                            style={styles.avantage}
                        />
                        <BallItem text={`Vos maisons astrologiques`} style={styles.avantage} />
                        <BallItem
                            text={`Vos planètes en signes et en maisons`}
                            style={styles.avantage}
                        />
                        <BallItem
                            text={`L'analyse des transits planétaires`}
                            style={styles.avantage}
                        />
                    </View>
                </View>
            )}
        </Formik>
    );
}

const styles = StyleSheet.create({
    container: {},
    title: {
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
        fontSize: 20,
        marginVertical: 20,
    },
    list: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    },
    change: {
        fontSize: 12,
        color: Colors.link,
        alignSelf: 'center',
        textAlign: 'center',
    },
    form: {
        alignSelf: 'center',
        alignItems: 'center',
    },
    fields: {},
    label: {
        marginTop: 10,
    },
    input: {},
    buttons: {},
    avantages: {
        alignSelf: 'center',
        textAlign: 'center',
    },
    avantage: {
        color: Colors.secondary,
    },
    picker: {
        borderColor: Colors.primary,
        borderRadius: 8,
        padding: 5,
        color: Colors.secondary,
    },
    text: {
        alignItems: 'center',
    },
    description: {
        maxWidth: 500,
    },
});
