import {gql, useQuery, useLazyQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import expert from './fragments/expert';
import type {ExpertQuery} from './types/ExpertQuery';
import type {ExpertsDetailsQuery} from './types/ExpertsDetailsQuery';
import {ExpertsDetailsQueryWithLastReview} from './types/ExpertsDetailsQueryWithLastReview';
import type {ExpertsQuery} from './types/ExpertsQuery';

const tagFragment = gql`
    fragment TagContent on Tag {
        id
        label
        tagTypeId
    }
`;

const EXPERTS_QUERY = gql`
    query ExpertsQuery {
        findExperts {
            edges {
                node {
                    id
                    userId
                    profile {
                        id
                        displayName
                        pictureName
                    }
                    tags {
                        edges {
                            node {
                                id
                                ...TagContent
                            }
                        }
                    }
                }
            }
        }
    }
    ${tagFragment}
`;

export const EXPERTS_DETAILS_QUERY = gql`
    query ExpertsDetailsQuery {
        findExperts {
            edges {
                node {
                    id
                    ...ExpertDetails
                }
            }
        }
    }
    ${expert}
`;

export default function useExperts() {
    return useQuery<ExpertsQuery>(EXPERTS_QUERY);
}

export function useExpertsDetails(options: QueryHookOptions) {
    return useQuery<ExpertsDetailsQuery>(EXPERTS_DETAILS_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        ...options,
    });
}

const reviewsFragment = gql`
    fragment ReviewsFragment on User {
        reviews(last: 1) {
            edges {
                node {
                    id
                    reviewId
                    createdDate
                    gradeId
                    comment
                    grade {
                        id
                        label
                    }
                    call {
                        id
                    }
                    chat {
                        id
                    }
                    message {
                        id
                    }
                }
            }
        }
    }
`;

export const EXPERTS_DETAILS_QUERY_WITH_LAST_REVIEW = gql`
    query ExpertsDetailsQueryWithLastReview {
        findExperts {
            edges {
                node {
                    id
                    ...ExpertDetails
                    ...ReviewsFragment
                }
            }
        }
    }
    ${expert}
    ${reviewsFragment}
`;

export function useExpertsDetailsWithLastReview(options: QueryHookOptions) {
    return useQuery<ExpertsDetailsQueryWithLastReview>(
        EXPERTS_DETAILS_QUERY_WITH_LAST_REVIEW,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            ...options,
        }
    );
}
