import * as React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {StackScreenProps} from '@react-navigation/stack';

import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {RootStackParamList} from '~/types';

export default function NotFoundScreen({
    navigation,
}: StackScreenProps<RootStackParamList, 'NotFound'>) {
    return (
        <View style={styles.container}>
            <SimpleText style={styles.title}>
                Vous êtes visiblement perdu, cet écran n'existe pas.
            </SimpleText>
            <TouchableOpacity onPress={() => navigation.replace('Root')} style={styles.link}>
                <SimpleText style={styles.linkText}>Retourner sur l'accueil</SimpleText>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    link: {
        marginTop: 15,
        paddingVertical: 15,
    },
    linkText: {
        fontSize: 14,
        color: Colors.link,
    },
});
