import React, {useEffect, useState} from 'react';
import {View, TouchableOpacity, StyleSheet, Linking} from 'react-native';

import {CommonActions} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';

import HoverableView from '~/components/common/HoverableView';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {notEmpty} from '~/helpers/list';
import {sorted} from '~/helpers/list';
import {
    TagsQuery_tagType_edges_node_tags,
    TagsQuery_tagType_edges_node_tags_edges_node,
} from '~/queries/types/TagsQuery';
import useTags from '~/queries/useTags';
import {TagTypesEnum} from '~/types/graphql-global-types';

import Dropdown, {DropdownItem} from './Dropdown';

const HOVER_COLOR = Colors.colorOpacity(Colors.primary, '22');
const SEARCH = 'Search';
const BLOG = 'Blog';
const INFORMATION = 'Information';
const PROFILE = 'Profile';
const SEARCHSCREEN = 'SearchScreen';
const ABOUTSCREEN = 'AboutScreen';
const HOWSCREEN = 'HowToScreen';
const PRICESCREEN = 'PriceScreen';

export default function TabBar({state, descriptors, navigation}) {
    const {loading: tagLoading, data: tagData} = useTags();
    const [specialities, setSpecialities] = useState<DropdownItem[]>([]);

    useEffect(() => {
        if (tagData && tagData.tagType) {
            const tagTypes = tagData.tagType.edges.map((edge) => edge?.node).filter(notEmpty);
            const specialityTagType = tagTypes.filter(
                (e) => e.tagTypeId == TagTypesEnum.SPECIALTIES
            )[0];

            if (specialityTagType.tags) {
                const mySpecialities = toEngine(specialityTagType.tags);
                setSpecialities(mySpecialities);
            }
        }
    }, [tagLoading]);

    function getOrder(item: TagsQuery_tagType_edges_node_tags_edges_node) {
        return item.order;
    }

    function toEngine(tagType: TagsQuery_tagType_edges_node_tags) {
        const tags = tagType.edges.map((edge) => edge?.node).filter(notEmpty);
        const sortedTags = sorted(tags, getOrder, false);
        return sortedTags.map((tag) => {
            return {
                value: tag.urlLabel,
                title: tag.label,
            } as DropdownItem;
        });
    }

    function getActiveRouteState(route) {
        if (!route.routes || route.routes.length === 0 || route.index >= route.routes.length) {
            return route;
        }

        const childActiveRoute = route.routes[route.index];
        return getActiveRouteState(childActiveRoute);
    }

    function isTagFocused(route, state, index, tag: TagTypesEnum) {
        const search = route.name == SEARCH;
        const isFocused = state.index === index;

        let isTagFocused = false;
        if (search && isFocused) {
            const searchRoute = state.routes.find((r) => r.name == SEARCH);
            if (searchRoute && searchRoute.state) {
                const searchScreenRoute = searchRoute.state.routes.find(
                    (r) => r.name == SEARCHSCREEN
                );
                if (searchScreenRoute && searchScreenRoute.params) {
                    isTagFocused =
                        tag in searchScreenRoute.params &&
                        searchScreenRoute.params[tag] !== undefined;
                }
            }
        }
        return isTagFocused;
    }

    const focusedOptions = descriptors[state.routes[state.index].key].options;

    if (focusedOptions.tabBarVisible === false) {
        return null;
    }

    const createTab = (route, index) => {
        const {options} = descriptors[route.key];
        const label =
            options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                ? options.title
                : route.name;

        const search = route.name == SEARCH;
        const blog = route.name == BLOG;
        const info = route.name == INFORMATION;
        const profile = route.name == PROFILE;
        const isFocused = state.index === index;

        // console.log('inner route =', route.state?.routes?.[0]);

        const isFocusedSpeciality = isTagFocused(
            route,
            state,
            index,
            TagTypesEnum.SPECIALTIES
        );

        const onPress = () => {
            const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
            });

            if (route.name == 'Home' && !event.defaultPrevented) {
                const activeRoute = getActiveRouteState(state);
                if (activeRoute.name != 'Home') {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{name: 'HomeScreen'}],
                        })
                    );
                }
            }

            if (!isFocused && !event.defaultPrevented) {
                if (route.name == 'About') {
                    navigation.navigate(ABOUTSCREEN);
                } else if (route.name == 'How') {
                    navigation.navigate(HOWSCREEN);
                } else if (route.name == 'Price') {
                    navigation.navigate(PRICESCREEN);
                } else {
                    navigation.navigate(route.name);
                }
            }
        };

        const onPressItem = (parameter: string, value: string) => {
            navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
            });

            let parameters = {} as {[key: string]: string | undefined};
            parameters['tag'] = value;

            navigation.navigate(SEARCHSCREEN, parameters);
        };

        if (info) {
            return null;
        }

        return (
            <View key={route.name}>
                {!search && !blog && !info && (
                    <Tab
                        label={label}
                        isFocused={isFocused}
                        tabBarAccessibilityLabel={options.tabBarAccessibilityLabel}
                        tabBarTestID={options.tabBarTestID}
                        onPress={onPress}
                        onLongPress={onPress}
                    />
                )}
                {search && (
                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <Tab
                            label={label}
                            isFocused={isFocused}
                            tabBarAccessibilityLabel={options.tabBarAccessibilityLabel}
                            tabBarTestID={options.tabBarTestID}
                            onPress={onPress}
                            onLongPress={onPress}
                        />
                        <Dropdown
                            label="Nos experts"
                            onPressItem={onPressItem}
                            isFocused={isFocusedSpeciality}
                            options={specialities}
                            parameter={TagTypesEnum.SPECIALTIES}
                        />
                    </View>
                )}
                {blog && <Blog />}
            </View>
        );
    };

    function TabBarPlaceholder() {
        return <View style={{height: 100}} />;
    }

    return (
        <>
            <TabBarPlaceholder />
            <View
                style={{
                    position: 'absolute',
                    width: '100%',
                    zIndex: 5555,
                }}
            >
                <LinearGradient
                    start={{x: 0, y: 0}}
                    end={{x: 1, y: 0}}
                    colors={Colors.rainbow}
                >
                    <View style={styles.tabBar}>{state.routes.map(createTab)}</View>
                </LinearGradient>
            </View>
        </>
    );
}

type TabProps = {
    label: string;
    tabBarAccessibilityLabel: string;
    tabBarTestID: string;
    isFocused: boolean;
    onPress: () => void;
    onLongPress: () => void;
};

function Tab(props: TabProps) {
    let isFocusedTextStyle = {};
    if (props.isFocused) {
        isFocusedTextStyle = {
            color: Colors.primary,
            fontWeight: 'bold',
        };
    } else {
        isFocusedTextStyle = {
            color: Colors.secondary,
            fontWeight: 'normal',
        };
    }

    return (
        <TouchableOpacity
            accessibilityRole="button"
            accessibilityState={props.isFocused ? {selected: true} : {}}
            accessibilityLabel={props.tabBarAccessibilityLabel}
            testID={props.tabBarTestID}
            onPress={props.onPress}
            onLongPress={props.onLongPress}
        >
            <HoverableView style={styles.tab} hoverStyle={styles.tabHover}>
                <SimpleText style={[styles.tabText, isFocusedTextStyle]}>
                    {props.label}
                </SimpleText>
            </HoverableView>
        </TouchableOpacity>
    );
}

function Blog() {
    function onPress() {
        window.open(Settings.url_blog);
    }

    let isFocusedTextStyle = {};
    isFocusedTextStyle = {
        color: Colors.secondary,
        fontWeight: 'normal',
    };

    return (
        <TouchableOpacity accessibilityRole="button" onPress={onPress}>
            <HoverableView style={styles.tab} hoverStyle={styles.tabHover}>
                <SimpleText style={[styles.tabText, isFocusedTextStyle]}>Blog</SimpleText>
            </HoverableView>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    tabBar: {
        width: 1080,
        justifyContent: 'space-evenly',
        marginBottom: 43,
        borderRadius: 5,
        flexDirection: 'row',
        alignSelf: 'center',
        backgroundColor: 'white',
    },
    tabText: {
        fontFamily: 'montserrat-medium',
        textTransform: 'uppercase',
        fontSize: 14,
        paddingVertical: 20,
        textAlign: 'center',
    },
    tabHover: {
        backgroundColor: HOVER_COLOR,
    },
    tab: {},
});
