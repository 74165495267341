import React, {useCallback, useState} from 'react';
import {StyleSheet, View} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {ButtonGroup} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import {MessageInput} from '~/components/common/Inputs';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';

import ReAuthenticateModal from '../../ReAuthenticateModal';

const DELETE_USER = gql`
    mutation deleteUser($reason: String!) {
        deleteUser(input: {reason: $reason}) {
            ok
        }
    }
`;

enum ReasonEnum {
    DONT_NEED_ANYMORE = 'Je n’ai plus besoin de consulter.',
    NO_MORE_FREE_COM = 'Je n’ai plus les moyens de consulter.',
    TOO_MUCH_EMAIL = 'Je ne trouve pas mon bonheur avec les spirites proposés.',
    UNHAPPY = 'Les prix sont trop élevés.',
    OTHER = 'Autres (précisez)', // OTHER = 4
}

const REASONS = Object.values(ReasonEnum);
const OTHER = 4;

export default function DisableForm({onSuccess}: {onSuccess: Function}) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [explication, setExplication] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [authFormVisible, setAuthFormVisible] = useState(false);
    const [isReAuthenticated, setIsReAuthenticated] = useState(false);
    const hideReAuthModal = useCallback(() => setAuthFormVisible(false), []);
    const showReAuthModal = useCallback(() => setAuthFormVisible(true), []);
    const {user} = React.useContext(UserContext);
    const [deleteUser] = useMutation(DELETE_USER);

    async function submit() {
        if (isReAuthenticated) {
            submitDeletion();
        } else {
            showReAuthModal();
        }
    }

    async function submitDeletion() {
        setIsReAuthenticated(true);
        const reason = selectedIndex == OTHER ? explication : REASONS[selectedIndex];
        if (reason == '') {
            setErrorMessage("Merci de remplir l'explication.");
        } else {
            try {
                const mutationData = {
                    reason: reason,
                };
                await deleteUser({variables: mutationData});
                await user?.delete();
                onSuccess();
                logEvent('delete_account');
            } catch (error) {
                console.log(error);
                if (error.code == 'auth/requires-recent-login') {
                    setErrorMessage(
                        'Par mesure de sécurité, merci de vous reconnecter avant de supprimer votre compte.'
                    );
                }
            }
        }
    }

    const buttons = [];
    for (let i = 0; i < REASONS.length; i++) {
        const component = () => (
            <SimpleText style={styles.radioLabel}>{REASONS[i]}</SimpleText>
        );
        buttons.push({element: component});
    }

    return (
        <>
            <ReAuthenticateModal
                mobileContentMaxWidth={600}
                title="Sécurisation de suppression de compte"
                message="Pour valider votre suppression de compte en sécurité nous avons d'abord besoin de vérifier votre mot de passe."
                visible={authFormVisible}
                onRequestClose={hideReAuthModal}
                onSuccess={submitDeletion}
            />
            <View style={styles.container}>
                <FormLabel>
                    Avant de nous quitter, prenez quelques secondes pour nous dire pourquoi
                    vous ne souhaitez plus être inscrit(e) sur Kyvoitou.fr :
                </FormLabel>
                <ButtonGroup
                    onPress={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    buttons={buttons}
                    containerStyle={{
                        flex: 1,
                        flexDirection: 'column',
                        height: 300,
                        borderWidth: 0,
                    }}
                    buttonStyle={{height: 25, padding: 10, borderWidth: 0}}
                    buttonContainerStyle={{marginHorizontal: 15, borderWidth: 0}}
                    textStyle={{borderWidth: 0}}
                    selectedButtonStyle={{backgroundColor: Colors.disabled, borderRadius: 7}}
                    innerBorderStyle={{width: 0}}
                />
                <FormLabel>Je souhaite supprimer mon compte car...</FormLabel>
                <MessageInput
                    placeholder={'Je souhaite supprimer mon compte car...'}
                    value={explication}
                    onChangeText={setExplication}
                    blurOnSubmit={false}
                    errorMessage={errorMessage}
                    maxLength={500}
                    style={{height: 90}}
                />
                <Button
                    title="Supprimer Mon Compte"
                    buttonStyle={{width: 250, alignSelf: 'center', marginBottom: 20}}
                    onPress={submit}
                />
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 5,
        marginHorizontal: 10,
        alignContent: 'space-around',
        backgroundColor: 'white',
    },
    radioLabel: {
        fontSize: 14,
        alignSelf: 'flex-start',
        borderWidth: 0,
        margin: 10,
    },
});
