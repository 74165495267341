import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {Bloc} from '~/components/common/Containers';
import * as Colors from '~/constants/Colors';

import PopupDisable from './PopupDisable';

export default function BlocDisable() {
    const [modalVisible, setModalVisible] = useState(false);
    const navigation = useNavigation();

    async function onSuccess() {
        setModalVisible(false);
        navigation.navigate('HomeScreen');
    }

    function onRequestCloseDisable() {
        setModalVisible(false);
    }

    function openDisable() {
        setModalVisible(true);
    }

    return (
        <Bloc title="Supprimer mon compte" iconName="user-slash">
            <View style={styles.buttonContainer}>
                <Button
                    onPress={openDisable}
                    style={styles.button}
                    title="Supprimer mon compte"
                ></Button>
            </View>
            <PopupDisable
                onSuccess={onSuccess}
                visible={modalVisible}
                onRequestClose={onRequestCloseDisable}
            />
        </Bloc>
    );
}

const styles = StyleSheet.create({
    button: {
        margin: 15,
        width: 250,
        alignSelf: 'center',
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    titleTextContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'flex-start',
    },
    picto: {
        width: 29,
        height: 22,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
    },
    buttonContainer: {
        margin: 30,
        backgroundColor: 'white',
    },
});
