import React from 'react';
import {StyleSheet, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import {MailInput} from '~/components/common/Inputs';
import {FormLabel} from '~/components/common/Texts';
import {useAlert} from '~/contexts/AlertContext';
import logEvent from '~/helpers/analytics';
import {sendPasswordResetEmail} from '~/helpers/login/auth';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

const {email} = yupRules;
const validationSchema = yup.object().shape({email});

export default function ResetPasswordForm() {
    const alert = useAlert();
    const navigation = useNavigation();

    async function requestResetPassword(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        try {
            await sendPasswordResetEmail(values.email);
            alert({
                message:
                    'Vous allez recevoir un email afin de réinitialiser votre mot de passe.',
                onClose: onClose,
                width: 400,
            });
            logEvent('reset_password');
        } catch (error) {
            if (error.code == 'auth/user-not-found') {
                setErrors({email: "L'email est introuvable"});
            }
            throw error;
        }
    }

    function onClose() {
        navigation.navigate('HomeScreen');
    }

    return (
        <View>
            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={requestResetPassword}
                validationSchema={validationSchema}
            >
                {(formikProps) => <ForgottenPasswordInputs {...formikProps} />}
            </Formik>
        </View>
    );
}

function ForgottenPasswordInputs(props: Props) {
    return (
        <View style={styles.container}>
            <FormLabel>Email</FormLabel>
            <MailInput
                placeholder={yupLabels.email}
                value={props.values.email}
                onChangeText={props.handleChange('email')}
                onBlur={() => props.setFieldTouched('email')}
                blurOnSubmit={true}
                errorMessage={props.errors.email || ' '}
                style={styles.input}
                inputStyle={styles.input}
            />
            <Button
                title="Réinitialiser"
                buttonStyle={{width: 150, alignSelf: 'center', marginTop: 30}}
                disabled={!props.isValid}
                onPress={() => props.handleSubmit()}
                loading={props.isSubmitting}
            />
        </View>
    );
}

type Inputs = Pick<YupTypes, 'email'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        marginHorizontal: 25,
        alignContent: 'space-around',
    },
    checkboxContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    rememberLabel: {
        fontSize: 14,
        marginTop: 17,
    },
    forgotContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    input: {},
});
