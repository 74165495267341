export type Country = {
    code: string;
    name: string;
};

export const COUNTRIES = [
    {code: 'AF', name: 'Afghanistan'},
    {code: 'ZA', name: 'Afrique du Sud'},
    {code: 'AL', name: 'Albanie'},
    {code: 'DZ', name: 'Algérie'},
    {code: 'DE', name: 'Allemagne'},
    {code: 'AD', name: 'Andorre'},
    {code: 'AO', name: 'Angola'},
    {code: 'AI', name: 'Anguilla'},
    {code: 'AG', name: 'Antigua-et-Barbuda'},
    {code: 'SA', name: 'Arabie saoudite'},
    {code: 'AR', name: 'Argentine'},
    {code: 'AM', name: 'Arménie'},
    {code: 'AW', name: 'Aruba'},
    {code: 'AU', name: 'Australie'},
    {code: 'AT', name: 'Autriche'},
    {code: 'AZ', name: 'Azerbaïdjan'},
    {code: 'BS', name: 'Bahamas'},
    {code: 'BH', name: 'Bahreïn'},
    {code: 'BD', name: 'Bangladesh'},
    {code: 'BB', name: 'Barbade'},
    {code: 'BY', name: 'Bélarus'},
    {code: 'BE', name: 'Belgique'},
    {code: 'BZ', name: 'Belize'},
    {code: 'BJ', name: 'Bénin'},
    {code: 'BM', name: 'Bermudes'},
    {code: 'BT', name: 'Bhoutan'},
    {code: 'BO', name: 'Bolivie'},
    {code: 'BA', name: 'Bosnie-Herzégovine'},
    {code: 'BW', name: 'Botswana'},
    {code: 'BR', name: 'Brésil'},
    {code: 'BN', name: 'Brunéi Darussalam'},
    {code: 'BG', name: 'Bulgarie'},
    {code: 'BF', name: 'Burkina Faso'},
    {code: 'BI', name: 'Burundi'},
    {code: 'KH', name: 'Cambodge'},
    {code: 'CM', name: 'Cameroun'},
    {code: 'CA', name: 'Canada'},
    {code: 'CV', name: 'Cap-Vert'},
    {code: 'CL', name: 'Chili'},
    {code: 'CN', name: 'Chine'},
    {code: 'CY', name: 'Chypre'},
    {code: 'CO', name: 'Colombie'},
    {code: 'KM', name: 'Comores'},
    {code: 'CG', name: 'Congo-Brazzaville'},
    {code: 'KP', name: 'Corée du Nord'},
    {code: 'KR', name: 'Corée du Sud'},
    {code: 'CR', name: 'Costa Rica'},
    {code: 'CI', name: 'Côte d’Ivoire'},
    {code: 'HR', name: 'Croatie'},
    {code: 'CU', name: 'Cuba'},
    {code: 'DK', name: 'Danemark'},
    {code: 'DJ', name: 'Djibouti'},
    {code: 'DM', name: 'Dominique'},
    {code: 'EG', name: 'Égypte'},
    {code: 'SV', name: 'El Salvador'},
    {code: 'AE', name: 'Émirats arabes unis'},
    {code: 'EC', name: 'Équateur'},
    {code: 'ER', name: 'Érythrée'},
    {code: 'ES', name: 'Espagne'},
    {code: 'EE', name: 'Estonie'},
    {code: 'VA', name: 'État de la Cité du Vatican'},
    {code: 'FM', name: 'États fédérés de Micronésie'},
    {code: 'US', name: 'États-Unis'},
    {code: 'ET', name: 'Éthiopie'},
    {code: 'FJ', name: 'Fidji'},
    {code: 'FI', name: 'Finlande'},
    {code: 'FR', name: 'France'},
    {code: 'GA', name: 'Gabon'},
    {code: 'GM', name: 'Gambie'},
    {code: 'GE', name: 'Géorgie'},
    {code: 'GH', name: 'Ghana'},
    {code: 'GI', name: 'Gibraltar'},
    {code: 'GR', name: 'Grèce'},
    {code: 'GD', name: 'Grenade'},
    {code: 'GL', name: 'Groenland'},
    {code: 'GP', name: 'Guadeloupe'},
    {code: 'GU', name: 'Guam'},
    {code: 'GT', name: 'Guatemala'},
    {code: 'GG', name: 'Guernesey'},
    {code: 'GN', name: 'Guinée'},
    {code: 'GQ', name: 'Guinée équatoriale'},
    {code: 'GW', name: 'Guinée-Bissau'},
    {code: 'GY', name: 'Guyana'},
    {code: 'GF', name: 'Guyane française'},
    {code: 'HT', name: 'Haïti'},
    {code: 'HN', name: 'Honduras'},
    {code: 'HU', name: 'Hongrie'},
    {code: 'CX', name: 'Île Christmas'},
    {code: 'IM', name: 'Île de Man'},
    {code: 'NF', name: 'Île Norfolk'},
    {code: 'AX', name: 'Îles Åland'},
    {code: 'KY', name: 'Îles Caïmans'},
    {code: 'CC', name: 'Îles Cocos - Keeling'},
    {code: 'CK', name: 'Îles Cook'},
    {code: 'FO', name: 'Îles Féroé'},
    {code: 'FK', name: 'Îles Malouines'},
    {code: 'MP', name: 'Îles Mariannes du Nord'},
    {code: 'MH', name: 'Îles Marshall'},
    {code: 'SB', name: 'Îles Salomon'},
    {code: 'TC', name: 'Îles Turks et Caïques'},
    {code: 'VG', name: 'Îles Vierges britanniques'},
    {code: 'VI', name: 'Îles Vierges des États-Unis'},
    {code: 'IN', name: 'Inde'},
    {code: 'ID', name: 'Indonésie'},
    {code: 'IQ', name: 'Irak'},
    {code: 'IR', name: 'Iran'},
    {code: 'IE', name: 'Irlande'},
    {code: 'IS', name: 'Islande'},
    {code: 'IL', name: 'Israël'},
    {code: 'IT', name: 'Italie'},
    {code: 'JM', name: 'Jamaïque'},
    {code: 'JP', name: 'Japon'},
    {code: 'JE', name: 'Jersey'},
    {code: 'JO', name: 'Jordanie'},
    {code: 'KZ', name: 'Kazakhstan'},
    {code: 'KE', name: 'Kenya'},
    {code: 'KG', name: 'Kirghizistan'},
    {code: 'KI', name: 'Kiribati'},
    {code: 'KW', name: 'Koweït'},
    {code: 'LA', name: 'Laos'},
    {code: 'LS', name: 'Lesotho'},
    {code: 'LV', name: 'Lettonie'},
    {code: 'LB', name: 'Liban'},
    {code: 'LR', name: 'Libéria'},
    {code: 'LY', name: 'Libye'},
    {code: 'LI', name: 'Liechtenstein'},
    {code: 'LT', name: 'Lituanie'},
    {code: 'LU', name: 'Luxembourg'},
    {code: 'MK', name: 'Macédoine'},
    {code: 'MG', name: 'Madagascar'},
    {code: 'MY', name: 'Malaisie'},
    {code: 'MW', name: 'Malawi'},
    {code: 'MV', name: 'Maldives'},
    {code: 'ML', name: 'Mali'},
    {code: 'MT', name: 'Malte'},
    {code: 'MA', name: 'Maroc'},
    {code: 'MQ', name: 'Martinique'},
    {code: 'MU', name: 'Maurice'},
    {code: 'MR', name: 'Mauritanie'},
    {code: 'YT', name: 'Mayotte'},
    {code: 'MX', name: 'Mexique'},
    {code: 'MD', name: 'Moldavie'},
    {code: 'MC', name: 'Monaco'},
    {code: 'MN', name: 'Mongolie'},
    {code: 'ME', name: 'Monténégro'},
    {code: 'MS', name: 'Montserrat'},
    {code: 'MZ', name: 'Mozambique'},
    {code: 'MM', name: 'Myanmar'},
    {code: 'NA', name: 'Namibie'},
    {code: 'NR', name: 'Nauru'},
    {code: 'NP', name: 'Népal'},
    {code: 'NI', name: 'Nicaragua'},
    {code: 'NE', name: 'Niger'},
    {code: 'NG', name: 'Nigéria'},
    {code: 'NU', name: 'Niue'},
    {code: 'NO', name: 'Norvège'},
    {code: 'NC', name: 'Nouvelle-Calédonie'},
    {code: 'NZ', name: 'Nouvelle-Zélande'},
    {code: 'OM', name: 'Oman'},
    {code: 'UG', name: 'Ouganda'},
    {code: 'UZ', name: 'Ouzbékistan'},
    {code: 'PK', name: 'Pakistan'},
    {code: 'PW', name: 'Palaos'},
    {code: 'PA', name: 'Panama'},
    {code: 'PG', name: 'Papouasie-Nouvelle-Guinée'},
    {code: 'PY', name: 'Paraguay'},
    {code: 'NL', name: 'Pays-Bas'},
    {code: 'PE', name: 'Pérou'},
    {code: 'PH', name: 'Philippines'},
    {code: 'PL', name: 'Pologne'},
    {code: 'PF', name: 'Polynésie française'},
    {code: 'PR', name: 'Porto Rico'},
    {code: 'PT', name: 'Portugal'},
    {code: 'QA', name: 'Qatar'},
    {code: 'HK', name: 'R.A.S. chinoise de Hong Kong'},
    {code: 'MO', name: 'R.A.S. chinoise de Macao'},
    {code: 'CF', name: 'République centrafricaine'},
    {code: 'CD', name: 'République démocratique du Congo'},
    {code: 'DO', name: 'République dominicaine'},
    {code: 'CZ', name: 'République tchèque'},
    {code: 'RE', name: 'Réunion'},
    {code: 'RO', name: 'Roumanie'},
    {code: 'GB', name: 'Royaume-Uni'},
    {code: 'RU', name: 'Russie'},
    {code: 'RW', name: 'Rwanda'},
    {code: 'EH', name: 'Sahara occidental'},
    {code: 'BL', name: 'Saint-Barthélémy'},
    {code: 'KN', name: 'Saint-Kitts-et-Nevis'},
    {code: 'SM', name: 'Saint-Marin'},
    {code: 'MF', name: 'Saint-Martin'},
    {code: 'PM', name: 'Saint-Pierre-et-Miquelon'},
    {code: 'VC', name: 'Saint-Vincent-et-les Grenadines'},
    {code: 'SH', name: 'Sainte-Hélène'},
    {code: 'LC', name: 'Sainte-Lucie'},
    {code: 'WS', name: 'Samoa'},
    {code: 'AS', name: 'Samoa américaines'},
    {code: 'ST', name: 'Sao Tomé-et-Principe'},
    {code: 'SN', name: 'Sénégal'},
    {code: 'RS', name: 'Serbie'},
    {code: 'SC', name: 'Seychelles'},
    {code: 'SL', name: 'Sierra Leone'},
    {code: 'SG', name: 'Singapour'},
    {code: 'SK', name: 'Slovaquie'},
    {code: 'SI', name: 'Slovénie'},
    {code: 'SO', name: 'Somalie'},
    {code: 'SD', name: 'Soudan'},
    {code: 'LK', name: 'Sri Lanka'},
    {code: 'SE', name: 'Suède'},
    {code: 'CH', name: 'Suisse'},
    {code: 'SR', name: 'Suriname'},
    {code: 'SJ', name: 'Svalbard et Île Jan Mayen'},
    {code: 'SZ', name: 'Swaziland'},
    {code: 'SY', name: 'Syrie'},
    {code: 'TJ', name: 'Tadjikistan'},
    {code: 'TW', name: 'Taïwan'},
    {code: 'TZ', name: 'Tanzanie'},
    {code: 'TD', name: 'Tchad'},
    {code: 'IO', name: "Territoire britannique de l'océan Indien"},
    {code: 'PS', name: 'Territoire palestinien'},
    {code: 'TH', name: 'Thaïlande'},
    {code: 'TL', name: 'Timor oriental'},
    {code: 'TG', name: 'Togo'},
    {code: 'TK', name: 'Tokelau'},
    {code: 'TO', name: 'Tonga'},
    {code: 'TT', name: 'Trinité-et-Tobago'},
    {code: 'TN', name: 'Tunisie'},
    {code: 'TM', name: 'Turkménistan'},
    {code: 'TR', name: 'Turquie'},
    {code: 'TV', name: 'Tuvalu'},
    {code: 'UA', name: 'Ukraine'},
    {code: 'UY', name: 'Uruguay'},
    {code: 'VU', name: 'Vanuatu'},
    {code: 'VE', name: 'Venezuela'},
    {code: 'VN', name: 'Viêt Nam'},
    {code: 'WF', name: 'Wallis-et-Futuna'},
    {code: 'YE', name: 'Yémen'},
    {code: 'ZM', name: 'Zambie'},
    {code: 'ZW', name: 'Zimbabwe'},
] as Country[];
