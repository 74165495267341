import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {TouchableOpacity} from 'react-native-gesture-handler';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';

import {SimpleText} from '../common/Texts';

export type LoginStatusProps = {
    onUserPress: () => void;
    onLoginPress: () => void;
    onRegisterPress: () => void;
};

export default function LoginStatus(props: LoginStatusProps) {
    const {me, loadingMe} = React.useContext(UserContext);

    if (!loadingMe && me) {
        const profile = me.profile;
        return (
            <TouchableOpacity onPress={props.onUserPress}>
                <View style={styles.logged}>
                    <ProfilePicture
                        style={{
                            borderRadius: 70,
                            width: 70,
                            height: 70,
                            alignSelf: 'center',
                        }}
                        pictureName={profile?.pictureName}
                        displayName={profile?.displayName}
                    />
                    <SimpleText style={styles.name}>{profile?.displayName}</SimpleText>
                </View>
            </TouchableOpacity>
        );
    } else {
        return (
            <View style={styles.container}>
                <Button
                    titleStyle={{fontSize: 14, marginHorizontal: 20, color: Colors.secondary}}
                    buttonStyle={{backgroundColor: 'white'}}
                    title={'Connexion'}
                    onPress={props.onLoginPress}
                />
                <Button
                    titleStyle={{fontSize: 14, marginHorizontal: 20}}
                    title={'Inscription'}
                    onPress={props.onRegisterPress}
                />
            </View>
        );
    }
}

export type ProfileMobileProps = {
    color: string;
    size: number;
};

export function ProfileMobile(props: ProfileMobileProps) {
    const {me, loadingMe} = React.useContext(UserContext);

    if (!loadingMe && me && me.profile?.pictureName !== 'default.png') {
        const profile = me.profile;
        return (
            <ProfilePicture
                style={{
                    borderRadius: props.size,
                    width: props.size,
                    height: props.size,
                    alignSelf: 'center',
                }}
                pictureName={profile?.pictureName}
                displayName={profile?.displayName}
            />
        );
    } else {
        return <Icons.Profile size={props.size} color={props.color} />;
    }
}

const styles = StyleSheet.create({
    logged: {
        flex: 1,
        flexDirection: 'row',
        minWidth: 300,
        justifyContent: 'center',
        paddingVertical: 15,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        minWidth: 300,
        justifyContent: 'space-evenly',
        paddingVertical: 15,
        alignItems: 'center',
    },
    name: {
        fontSize: 16,
        color: 'white',
        alignSelf: 'center',
        marginLeft: 10,
    },
});
