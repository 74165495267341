import React, {useState} from 'react';
import {View, StyleSheet, FlatList, TouchableOpacity} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {Picker} from '@react-native-picker/picker';
import {useFocusEffect} from '@react-navigation/native';

import {MainView} from '~/components/common/Containers';
import {GradeColorLabel} from '~/components/common/GradeItem';
import * as Icons from '~/components/common/Icons';
import * as Table from '~/components/common/Table';
import {SimpleText, TextProps} from '~/components/common/Texts';
import {ScreenLoader} from '~/components/navigation/Loader';
import * as Colors from '~/constants/Colors';
import {MIN_DURATION_FOR_REVIEW} from '~/constants/Settings';
import {APP_ACTIVE_YEARS, MONTHS_NAMES} from '~/constants/dates';
import * as dates from '~/helpers/dates';
import {sorted, filterEmpty, Key} from '~/helpers/list';
import {MessageTypeEnum, PackOrderStatus} from '~/types/graphql-global-types';

import PopupReadReview from '../review/PopupReadReview';
import PopupWriteReview from '../review/PopupWriteReview';
import type {ReviewData} from '../review/types/calls';
import {CommunicationType} from '../review/types/calls';
import type {HistoricQuery} from './types/HistoricQuery';

export default function ClientHistoric() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonth);

    const [load, {called, loading, data, refetch}] = useLazyQuery<HistoricQuery>(
        HISTORIC_QUERY,
        {
            errorPolicy: 'all',
            fetchPolicy: 'cache-and-network',
            variables: {month: month + 1, year: year},
        }
    );

    useFocusEffect(
        React.useCallback(() => {
            if (month == currentMonth && year == currentYear) {
                requestAnimationFrame(reload);
            }
        }, [month, year])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    const [reviewData, setReviewData] = useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: true,
    });
    const [modalWriteReviewVisible, setModalWriteReviewVisible] = useState(false);
    const [modalReadReviewVisible, setModalReadReviewVisible] = useState(false);

    if (loading && data == null) {
        return <ScreenLoader />;
    }

    function onWriteReviewPress(reviewData: ReviewData) {
        setReviewData(reviewData);
        setModalWriteReviewVisible(true);
    }

    function onReadReviewPress(reviewData: ReviewData) {
        setReviewData(reviewData);
        setModalReadReviewVisible(true);
    }

    function onRequestCloseWriteReview() {
        setModalWriteReviewVisible(false);
    }

    function onRequestCloseReadReview() {
        setModalReadReviewVisible(false);
    }

    function endReview() {
        reload();
        setModalWriteReviewVisible(false);
    }

    const monthItems = MONTHS_NAMES.map((name, i) => (
        <Picker.Item label={name} value={i} key={`month_${i}`} />
    ));

    const yearItems = APP_ACTIVE_YEARS.map((year) => (
        <Picker.Item label={`${year}`} value={year} key={`year_${year}`} />
    ));

    return (
        <MainView>
            <SimpleText style={styles.title} accessibilityRole="header" aria-level="1">
                Mon historique de consultation
            </SimpleText>
            <SimpleText style={styles.description}>
                Vous retrouverez ici toutes les statistiques liées à vos activités sur
                Kyvoitou.fr.
            </SimpleText>
            <View style={styles.filterContainer}>
                <SimpleText style={styles.pickerContainer}>Filtrer par : </SimpleText>
                <Picker
                    selectedValue={month}
                    style={styles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setMonth(parseInt(itemValue))}
                >
                    {monthItems}
                </Picker>
                <Picker
                    selectedValue={year}
                    style={styles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setYear(parseInt(itemValue))}
                >
                    {yearItems}
                </Picker>
            </View>
            <HistoricElementTable
                data={data}
                month={month}
                year={year}
                onWriteReviewPress={onWriteReviewPress}
                onReadReviewPress={onReadReviewPress}
            />
            <PopupWriteReview
                visible={modalWriteReviewVisible}
                onRequestClose={onRequestCloseWriteReview}
                reviewData={reviewData}
                onSuccess={endReview}
            />
            <PopupReadReview
                visible={modalReadReviewVisible}
                onRequestClose={onRequestCloseReadReview}
                reviewData={reviewData}
                animationType={'none'}
            />
        </MainView>
    );
}

type HistoricElement = {
    type: string;
    communicationType?: CommunicationType | null;
    id: string;
    expertId: string;
    expertDisplayName: string;
    date: string;
    reviewId?: string | null;
    cost: number;
    duration: string;
    clientOfferLabel: string;
    freeMinuteInteger: number;
    gradeLabel?: string | null;
    gradeId?: number | null;
};

function HistoricElementTable(props: HistoricElementTableProps) {
    const [order, setOrder] = useState({field: 'date', value: 'desc'});

    function onPressHeader(field: string, value: string) {
        setOrder({field, value});
    }

    const renderRow = ({item}: {item: HistoricElement}) => {
        const date = new Date(item.date);

        function currentData() {
            return {
                date,
                communicationId: item?.id,
                communicationType: item?.communicationType,
                expertId: item?.expertId,
                clientMode: true,
            } as ReviewData;
        }

        function writeReview() {
            const reviewData = currentData();
            props.onWriteReviewPress(reviewData);
        }

        function readReview() {
            const reviewData = currentData();
            props.onReadReviewPress(reviewData);
        }

        return (
            <Row
                element={item}
                onWriteReviewPress={writeReview}
                onReadReviewPress={readReview}
            />
        );
    };

    const calls = props.data?.findCalls?.edges?.filter(
        (e) =>
            (e?.node?.status == 'FINISHED' || e?.node?.status == 'ERROR') &&
            new Date(e.node.startDatetime).getFullYear() == props.year &&
            new Date(e.node.startDatetime).getMonth() == props.month
    );

    const callsElements = calls?.map((e) => {
        return {
            type: 'Appel',
            communicationType: CommunicationType.CALL,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            expertDisplayName: e?.node?.expert?.profile?.displayName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            cost: e?.node?.cost,
            duration: e?.node?.duration,
            clientOfferLabel: e?.node?.clientOffer?.offer?.label,
            freeMinuteInteger: e?.node?.clientOffer?.offer?.freeMinuteInteger,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as HistoricElement;
    });

    const messages = props.data?.findMessages?.edges?.filter(
        (e) =>
            (e?.node?.type == MessageTypeEnum.PACK_STUDY || e?.node?.status == 'ANSWERED') &&
            new Date(e.node.creationDate).getFullYear() == props.year &&
            new Date(e.node.creationDate).getMonth() == props.month
    );

    const messagesElements = messages?.map((e) => {
        return {
            type: e?.node?.type == MessageTypeEnum.PACK_STUDY ? 'Étude complète' : 'Message',
            communicationType: CommunicationType.MESSAGE,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            expertDisplayName: e?.node?.expert?.profile?.displayName,
            date: e?.node?.creationDate,
            reviewId: e?.node?.review?.reviewId,
            cost:
                e?.node?.type == MessageTypeEnum.PACK_STUDY
                    ? e?.node?.packOrder?.amount
                    : e?.node?.cost,
            duration: '',
            clientOfferLabel:
                e?.node?.type == MessageTypeEnum.PACK_STUDY
                    ? 'Pack ' + e?.node?.packOrder?.pack?.name
                    : '',
            freeMinuteInteger: 0,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as HistoricElement;
    });

    const chats = props.data?.findChats?.edges?.filter(
        (e) =>
            e?.node?.status == 'ENDED' &&
            new Date(e.node.startDatetime).getFullYear() == props.year &&
            new Date(e.node.startDatetime).getMonth() == props.month
    );

    const chatsElements = chats?.map((e) => {
        return {
            type: 'Chat',
            communicationType: CommunicationType.CHAT,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            expertDisplayName: e?.node?.expert?.profile?.displayName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            cost: e?.node?.cost,
            duration: e?.node?.duration,
            clientOfferLabel: e?.node?.clientOffer?.offer?.label,
            freeMinuteInteger: e?.node?.clientOffer?.offer?.freeMinuteInteger,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as HistoricElement;
    });

    const packOrders = props.data?.findPackOrders?.edges?.filter(
        (e) =>
            e?.node?.status == PackOrderStatus.CONFIRMED &&
            e?.node?.minutes &&
            new Date(e.node.created).getFullYear() == props.year &&
            new Date(e.node.created).getMonth() == props.month
    );

    const packOrdersElements = packOrders?.map((e) => {
        return {
            type: 'Pack',
            communicationType: null,
            id: e?.node?.packOrderId,
            expertId: '-',
            expertDisplayName: '-',
            date: e?.node?.created,
            reviewId: null,
            cost: e?.node?.amount,
            duration: '-',
            clientOfferLabel: 'Pack ' + e?.node?.pack?.name,
            freeMinuteInteger: 0,
            gradeLabel: null,
            gradeId: null,
        } as HistoricElement;
    });

    const elements = [
        ...(callsElements ?? []),
        ...(messagesElements ?? []),
        ...(chatsElements ?? []),
        ...(packOrdersElements ?? []),
    ];

    if (elements?.length == 0) {
        return (
            <SimpleText style={styles.description}>
                Vous n'avez pas d'élément dans l'historique pour ce mois-ci.
            </SimpleText>
        );
    }

    const reverse = order.value == 'desc';
    const displayedElements = filterEmpty(elements);
    const sortedElements = sortHistoricElement(displayedElements, order.field, reverse);

    return (
        <>
            <View>
                <HeaderRow order={order.field} onPressHeader={onPressHeader} />
                <FlatList
                    data={sortedElements}
                    renderItem={renderRow}
                    keyExtractor={(item) => item.id}
                />
            </View>
        </>
    );
}

function HeaderRow({order, onPressHeader, ...otherProps}: HeaderRowProps) {
    return (
        <Table.HeaderRow {...otherProps}>
            <Table.HeaderCell
                large
                label={'Expert'}
                onPressHeader={onPressHeader}
                field={'expert'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                label={'Type'}
                onPressHeader={onPressHeader}
                field={'type'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                large
                label={'Date'}
                onPressHeader={onPressHeader}
                field={'date'}
                init={true}
                orderField={order}
            />
            <Table.HeaderCell
                label={'Durée'}
                onPressHeader={onPressHeader}
                field={'duration'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                large
                label={'Offre'}
                onPressHeader={onPressHeader}
                field={'offer'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                label={'Coût'}
                onPressHeader={onPressHeader}
                field={'cost'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                center
                label={'Avis'}
                onPressHeader={onPressHeader}
                field={'review'}
                init={false}
                orderField={order}
            />
        </Table.HeaderRow>
    );
}

function Row(props: RowProps) {
    const element = props.element;
    const date = new Date(element.date);
    const reviewId = element?.reviewId;

    const duration = element.duration ? dates.getDurationInMinutes(element.duration) : null;
    const durationInSeconds = duration ? duration * 60 : 0;

    const hasReview = element.type != 'Pack' && reviewId != null;
    const canReview =
        element.type != 'Pack' &&
        durationInSeconds > MIN_DURATION_FOR_REVIEW &&
        (element?.cost > 0 || Boolean(element?.clientOfferLabel));
    return (
        <Table.Row>
            <Table.Cell large>{element?.expertDisplayName}</Table.Cell>
            <Table.Cell>{element?.type}</Table.Cell>
            <Table.Cell large>
                {dates.dateString(date)} à {dates.timeString(date)}
            </Table.Cell>
            <Table.Cell>{element?.duration ? element?.duration : '-'}</Table.Cell>
            <Table.Cell large>
                {element?.clientOfferLabel ? element?.clientOfferLabel : '-'}
            </Table.Cell>
            <Table.Cell>{element?.cost} €</Table.Cell>
            <Table.ViewCell>
                {hasReview && (
                    <TouchableOpacity onPress={props.onReadReviewPress}>
                        <GradeColorLabel
                            style={{fontSize: 12, alignSelf: 'center', marginBottom: 5}}
                            text={element?.gradeLabel ?? ''}
                            grade={element?.gradeId}
                        />
                        <View style={styles.link}>
                            <Icons.Read
                                size={16}
                                color={Colors.link}
                                style={{paddingRight: 10}}
                            />
                            <SimpleText style={styles.text}>Lire</SimpleText>
                        </View>
                    </TouchableOpacity>
                )}
                {!hasReview && canReview && (
                    <TouchableOpacity onPress={props.onWriteReviewPress} style={styles.link}>
                        <Icons.Write
                            size={16}
                            color={Colors.link}
                            style={{paddingRight: 10}}
                        />
                        <SimpleText style={styles.text}>Rédiger</SimpleText>
                    </TouchableOpacity>
                )}
                {!hasReview && !canReview && <SimpleText style={styles.text}>-</SimpleText>}
            </Table.ViewCell>
        </Table.Row>
    );
}

const COMPARISONS = new Map<string, Key<HistoricElement>>([
    ['expert', (element: HistoricElement) => element.expertDisplayName],
    ['type', (element: HistoricElement) => element.type],
    ['date', (element: HistoricElement) => Date.parse(element.date)],
    ['duration', (element: HistoricElement) => element.duration],
    ['offer', (element: HistoricElement) => element?.freeMinuteInteger ?? 0],
    ['cost', (element: HistoricElement) => element.cost ?? 0],
    ['review', (element: HistoricElement) => (element.reviewId ? 1 : -1)],
]);

function sortHistoricElement(
    elements: HistoricElement[],
    order: string,
    reverse?: boolean
): HistoricElement[] {
    const comparison = COMPARISONS.get(order);
    if (!comparison) {
        return [...elements];
    }
    return sorted(elements, comparison, reverse);
}

type HistoricElementTableProps = {
    data?: HistoricQuery;
    year: number;
    month: number;
    onWriteReviewPress: (reviewData: ReviewData) => void;
    onReadReviewPress: (reviewData: ReviewData) => void;
};

type HeaderRowProps = View['props'] & {
    onPressHeader: CellPressHeaderCallback;
    order: string;
};

type RowProps = {
    element: HistoricElement;
    onReadReviewPress: () => void;
    onWriteReviewPress: () => void;
};

type HeaderCellProps = View['props'] & {
    label: string;
    onPressHeader: CellPressHeaderCallback;
    field: string;
    init: boolean;
    orderField: string;
};

type CellProps = TextProps & {
    large?: boolean;
};

type CellPressHeaderCallback = (field: string, value: string) => void;

const HISTORIC_QUERY = gql`
    query HistoricQuery($month: Int, $year: Int) {
        findCalls(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    status
                    expert {
                        id
                        profile {
                            id
                            displayName
                        }
                    }
                    clientOffer {
                        offer {
                            id
                            label
                            freeMinuteInteger
                        }
                    }
                    review {
                        id
                        reviewId
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
        findMessages(month: $month, year: $year) {
            edges {
                node {
                    id
                    type
                    status
                    creationDate
                    cost
                    review {
                        id
                        reviewId
                        grade {
                            label
                            gradeId
                        }
                    }
                    expert {
                        id
                        profile {
                            id
                            displayName
                        }
                    }
                    packOrder {
                        amount
                        pack {
                            name
                        }
                    }
                }
            }
        }
        findChats(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    status
                    expert {
                        id
                        profile {
                            id
                            displayName
                        }
                    }
                    clientOffer {
                        offer {
                            id
                            label
                            freeMinuteInteger
                        }
                    }
                    review {
                        id
                        reviewId
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
        findPackOrders(month: $month, year: $year) {
            edges {
                node {
                    packOrderId
                    status
                    amount
                    minutes
                    created
                    pack {
                        name
                    }
                }
            }
        }
    }
`;

const styles = StyleSheet.create({
    title: {
        fontWeight: 'bold',
        fontSize: 38,
        textTransform: 'uppercase',
        color: Colors.secondary,
        alignSelf: 'center',
        margin: 44,
    },
    description: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    filterContainer: {
        flexDirection: 'row',
        margin: 25,
        justifyContent: 'flex-end',
    },
    pickerContainer: {
        margin: 20,
    },
    link: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
    text: {
        color: Colors.link,
    },
});
