import {Pressable, View} from 'react-native';

import {ProfilePicture} from '../common/Images';
import {SimpleText} from '../common/Texts';
import StepCheckedIcon from './StepIcon';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';
import {SelectedExpertUser} from './query/useSelectedExpert';
import {styles} from './styles';

export type PickedExpertViewProps = {
    expertUser: SelectedExpertUser | null | undefined;
    onPickAnotherExpertPress: () => void;
};

export default function PickedExpertView(props: PickedExpertViewProps) {
    const {
        isAuthStep,
        isPaymentStep,
        isOngoingCallStep,
        isPackSelectionStep,
    } = useUrlStepContext();
    const pageVersion = usePageVersion();
    const displayName = props.expertUser?.profile?.displayName;
    const pictureName = props.expertUser?.profile?.pictureName;
    const isVisible =
        isAuthStep ||
        isPaymentStep ||
        isPackSelectionStep ||
        (pageVersion == 2 && isOngoingCallStep);
    if (!isVisible) {
        return null;
    }
    return (
        <>
            <StepTitle>
                1/ Voyant choisi <StepCheckedIcon />
            </StepTitle>
            <View style={styles.expertBadge}>
                <View style={styles.expertBadgeInfo}>
                    <ProfilePicture
                        style={styles.smallProfilePicture}
                        pictureName={pictureName}
                        displayName={displayName}
                    />
                    <SimpleText style={styles.expertBadgeMessage}>
                        Vous avez choisi{' '}
                        <SimpleText style={{fontWeight: 'bold'}}>{displayName}</SimpleText>{' '}
                        pour votre appel.
                    </SimpleText>
                </View>
                <Pressable
                    style={styles.expertBadgeLink}
                    onPress={props.onPickAnotherExpertPress}
                >
                    <SimpleText style={styles.expertBadgeLinkText}>
                        Choisir un autre expert
                    </SimpleText>
                </Pressable>
            </View>
        </>
    );
}
