import {View} from 'react-native';

import {ReviewCarousel} from '../home/ReviewCarousel';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';

export default function Reviews() {
    const {isExpertListStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const isVisible = isExpertListStep || pageVersion == 2;
    if (!isVisible) {
        return null;
    }
    return (
        <View style={{marginTop: 50}}>
            <ReviewCarousel />
        </View>
    );
}
