import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import HoverableView from '../common/HoverableView';

export function Payments() {
    return (
        <View style={styles.container}>
            <PaymentMinute />
            <PaymentPack />
        </View>
    );
}

export function PaymentPack() {
    const navigation = useNavigation();
    return (
        <HoverableView style={styles.notHover} hoverStyle={styles.hover}>
            <TouchableOpacity
                onPress={() => navigation.navigate('PackScreen')}
                style={{flex: 1}}
            >
                <View style={styles.back}>
                    <View style={styles.bloc}>
                        <SimpleText style={styles.title}>Paiement avec packs</SimpleText>
                        <SimpleText style={styles.description}>
                            Des <SimpleText style={styles.bold}>forfaits voyance</SimpleText>{' '}
                            au meilleur prix !
                        </SimpleText>
                        <SimpleText style={styles.text}>
                            A partir de <SimpleText style={styles.amount}>20€</SimpleText>
                        </SimpleText>
                    </View>
                </View>
            </TouchableOpacity>
        </HoverableView>
    );
}

export function PaymentMinute() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();

    function goToSearch() {
        if (isDesktop) {
            navigation.navigate('SearchScreen', {tag: ''});
        } else {
            navigation.navigate('Main', {
                screen: 'Search',
                params: {screen: 'SearchScreen'},
            });
        }
    }

    return (
        <HoverableView style={styles.notHover} hoverStyle={styles.hover}>
            <TouchableOpacity onPress={goToSearch} style={{flex: 1}}>
                <View style={styles.back}>
                    <View style={styles.bloc}>
                        <SimpleText style={styles.title}>Paiement à la minute</SimpleText>
                        <SimpleText style={styles.description}>
                            Offre découverte :{' '}
                            <SimpleText style={styles.bold}>3 séances de 5 minutes</SimpleText>{' '}
                            avec les médiums de votre choix !
                        </SimpleText>
                        <SimpleText style={styles.text}>
                            A partir de <SimpleText style={styles.amount}>1.50€</SimpleText> /
                            min
                        </SimpleText>
                    </View>
                </View>
            </TouchableOpacity>
        </HoverableView>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    back: {
        borderColor: Colors.primary,
        borderWidth: 4,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    bloc: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        padding: 22,
        flex: 1,
        maxWidth: 292,
        maxHeight: 292,
    },
    title: {
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
        fontSize: 28,
    },
    description: {
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
        fontSize: 20,
    },
    bold: {
        fontWeight: 'bold',
        color: Colors.secondary,
        fontSize: 20,
    },
    text: {
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
        fontSize: 16,
    },
    amount: {
        fontSize: 30,
        backgroundImage: '-webkit-linear-gradient(left, #4bc0c8, #c779d0, #feac5e)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    notHover: {
        margin: 30,
        width: 300,
        height: 300,
    },
    hover: {
        margin: 15,
        width: 330,
        height: 330,
    },
});
