import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';

import {SimpleText, TextProps} from '~/components/common/Texts';
import {navigate} from '~/navigation';

export const UNKNOWN_EXPERT_NAME = 'spirite inconnu';

export default function ExpertNameLink({
    expertId,
    expertName,
    onPress,
    style,
    ...textProps
}: ExpertNameLinkProps) {
    const navigationOptions = expertId ? {id: expertId} : {};

    function navigateToExpert() {
        onPress && onPress({expertId, expertName});
        navigate('Expert', navigationOptions);
    }

    const text = expertName ? `#${expertName}` : UNKNOWN_EXPERT_NAME;

    return (
        <TouchableOpacity onPress={navigateToExpert}>
            <SimpleText style={[styles.link, style]} {...textProps}>{` ${text} `}</SimpleText>
        </TouchableOpacity>
    );
}

export type ExpertLinkClickedProps = {expertId?: string; expertName?: string};

export type ExpertNameTextProps = Omit<TextProps, 'onPress'>;
export type ExpertNameLinkProps = ExpertNameTextProps & {
    expertId?: string;
    expertName?: string;
    onPress?: (data: ExpertLinkClickedProps) => void;
};

const styles = StyleSheet.create({
    link: {
        fontWeight: 'bold',
    },
});
