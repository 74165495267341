import React, {useState, useEffect} from 'react';
import {View, StyleSheet, FlatList, TouchableOpacity} from 'react-native';

import {gql, useMutation, useQuery} from '@apollo/client';
import {Formik} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture, PicturesViewer} from '~/components/common/Images';
import {ResponseInput} from '~/components/common/Inputs';
import {SimpleText} from '~/components/common/Texts';
import UploadAttachments from '~/components/user/client/message/UploadAttachments';
import PopupWriteReview from '~/components/user/client/review/PopupWriteReview';
import type {ReviewData} from '~/components/user/client/review/types/calls';
import {CommunicationType} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import * as dates from '~/helpers/dates';
import {sorted, filterEmpty} from '~/helpers/list';
import {
    ExchangeTypeEnum,
    getCreationDate,
    Message,
    MessageExchange,
    MessageStatusEnum,
} from '~/helpers/message';
import yup, {yupRules, YupTypes} from '~/helpers/yup';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import {GetClientFreeMessageQuery} from './types/GetClientFreeMessageQuery';

const GET_CLIENT_FREE_MESSAGE_QUERY = gql`
    query GetClientFreeMessageQuery($expertId: String!) {
        getClientFreeMessage(expertId: $expertId) {
            id
            freeMessageNumberSinceLastPaying
        }
    }
`;

const MAX_FREE_MESSAGES = 3;
const MAX_CLIENT_EXCHANGE_IN_PAYING_THREAD = 2;

export default function Exchange(props: MessageProps) {
    const {isDesktop} = useDeviceQuery();
    const {me} = React.useContext(UserContext);
    const expertId = props.message.expert.id;
    const variables = {expertId};
    const {loading, data, refetch} = useQuery<GetClientFreeMessageQuery>(
        GET_CLIENT_FREE_MESSAGE_QUERY,
        {variables, skip: expertId == null}
    );

    React.useEffect(() => {
        refetch();
    }, [expertId]);

    if (!props.message) {
        return null;
    }

    const recipient = getProfile(props.exchangeType, props.message);
    const messages = props.message.exchanges;
    const sortedMessages = sorted(filterEmpty(messages), getCreationDate, false);

    function onStatisticsPress() {
        if (props.message?.client) {
            props.onStatisticsClientPress(props.message.client.id);
        }
    }

    function onResponseSuccess() {
        refetch();
        props.onResponseSuccess();
    }

    function isPayingThreadLimitReached() {
        if (
            props.exchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT &&
            props.message.type != MessageTypeEnum.FREE
        ) {
            const clientExchanges = props.message.exchanges.filter(
                (e) => e.type == ExchangeTypeEnum.CLIENT_TO_EXPERT
            ).length;
            return clientExchanges >= MAX_CLIENT_EXCHANGE_IN_PAYING_THREAD;
        } else {
            return false;
        }
    }

    function isFreeMessageLimiteReached() {
        if (props.exchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT) {
            const number = data?.getClientFreeMessage?.freeMessageNumberSinceLastPaying;
            return number == undefined || number >= MAX_FREE_MESSAGES;
        } else {
            return false;
        }
    }

    function renderMessage({item, index}: {item: MessageExchange; index: number}) {
        return (
            <SingleMessage
                index={index}
                message={item}
                exchangeType={props.exchangeType}
                messageType={props.message.type}
                recipientPicture={recipient.pictureName}
                myPicture={me?.profile?.pictureName}
            />
        );
    }

    const limitFree = isFreeMessageLimiteReached();
    const limitPaying = isPayingThreadLimitReached();

    const canReview =
        props.exchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT &&
        !props.message?.reviewId &&
        props.message?.status != MessageStatusEnum.SENT &&
        !props.message.groupInternal;

    return (
        <View style={isDesktop ? styles.container : mobileStyles.container}>
            <ExchangeHeader
                recipientName={recipient?.displayName}
                subject={props.message.subject}
            />
            <View style={styles.listContainer}>
                <FlatList
                    data={sortedMessages}
                    renderItem={renderMessage}
                    keyExtractor={(item) => item.messageExchangeId}
                    style={styles.exchangeContainer}
                />
            </View>
            <ResponseView
                message={props.message}
                exchangeType={props.exchangeType}
                onResponseSuccess={onResponseSuccess}
                limitFree={limitFree}
                limitPaying={limitPaying}
            />
            {canReview && (
                <ReviewButton message={props.message} onSuccess={props.onReviewSuccess} />
            )}
            {isDesktop && props.exchangeType == ExchangeTypeEnum.EXPERT_TO_CLIENT && (
                <View style={styles.statisticsContainer}>
                    <TouchableOpacity style={styles.statistics} onPress={onStatisticsPress}>
                        <SimpleText style={styles.statisticsLabel}>
                            Voir mes statistiques avec ce client
                        </SimpleText>
                        <Icons.See
                            style={{
                                borderLeftColor: Colors.highlight,
                                borderLeftWidth: 1,
                                padding: 20,
                                alignSelf: 'flex-end',
                            }}
                            size={20}
                            color={Colors.highlight}
                        />
                    </TouchableOpacity>
                </View>
            )}
        </View>
    );
}

function ExchangeHeader({subject, recipientName}: HeaderProps) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    return (
        <View style={deviceStyles.topContainer}>
            <View style={deviceStyles.headerContainer}>
                <SimpleText style={deviceStyles.subject}>{subject}</SimpleText>
                {recipientName && (
                    <SimpleText>
                        Avec <SimpleText style={deviceStyles.name}>{recipientName}</SimpleText>
                    </SimpleText>
                )}
            </View>
        </View>
    );
}

function ResponseView(props: ReponseViewProps) {
    const alert = useAlert();
    const [attachments, setAttachments] = useState<string[]>([]);
    const [sendResponse, {data, error: sendResponseError}] = useMutation(SEND_RESPONSE, {
        errorPolicy: 'all',
    });

    useEffect(() => {
        if (sendResponseError) {
            console.log('sendResponseError = ', sendResponseError?.message);
        }
    }, [sendResponseError]);

    async function submitResponse(values: Inputs) {
        const data = {
            messageId: props.message.messageId,
            exchangeType: props.exchangeType,
            content: values.content,
            attachments: attachments,
            group: props.message.groupInternal,
        };

        try {
            await sendResponse({variables: data});
            alert({
                title: 'Information',
                message: 'Votre réponse a bien été envoyé.',
                onClose: () => props.onResponseSuccess(),
            });
            logEvent('message_responded');
            return;
        } catch (e) {
            console.log('submitResponse::catch ', e);
        }
        alert({
            title: 'Information',
            message:
                "Une erreur s'est produite lors de l'envoi de votre réponse, veuillez ré-essayer plus tard.",
        });
    }

    const {content} = yupRules;
    const validationSchema = yup.object().shape({content});

    if (props.limitFree) {
        return (
            <SimpleText style={styles.error}>
                Vous n'avez plus de message gratuit pour l'instant
            </SimpleText>
        );
    }

    if (props.limitPaying) {
        return (
            <SimpleText style={styles.error}>
                Vous ne pouvez plus répondre dans ce fil de discussion. Lancez une consultation
                pour echanger avec votre spirite.
            </SimpleText>
        );
    }

    if (props.message.groupVisible) {
        return (
            <SimpleText style={styles.error}>
                Vous ne pouvez pas répondre à un message groupé
            </SimpleText>
        );
    }

    return (
        <View style={styles.responseContainer}>
            <Formik
                initialValues={{content: ''}}
                onSubmit={submitResponse}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <View style={styles.formContainer}>
                        <View style={styles.contentContainer}>
                            <ResponseInput
                                value={formikProps.values.content}
                                onChangeText={formikProps.handleChange('content')}
                                onBlur={() => formikProps.setFieldTouched('content')}
                                errorMessage={formikProps.errors.content || ' '}
                                style={styles.contentInput}
                                inputContainerStyle={{borderBottomWidth: 0}}
                            />
                        </View>
                        <UploadAttachments
                            setAttachments={setAttachments}
                            buttonBackgroundColor={Colors.dark}
                        />
                        <View style={styles.buttons}>
                            <Button
                                icon={<Icons.Reply size={16} style={{paddingRight: 10}} />}
                                title="Répondre"
                                buttonStyle={{
                                    width: 160,
                                    alignSelf: 'center',
                                    marginTop: 10,
                                }}
                                disabled={!formikProps.isValid}
                                onPress={() => formikProps.handleSubmit()}
                                loading={formikProps.isSubmitting}
                            />
                        </View>
                    </View>
                )}
            </Formik>
        </View>
    );
}

function ReviewButton({message, onSuccess}: ReviewProps) {
    const [isReviewVisible, setIsReviewVisible] = useState(false);

    function reviewMessage() {
        setIsReviewVisible(true);
    }

    function endReview() {
        setIsReviewVisible(false);
        onSuccess();
    }

    const reviewData: ReviewData = {
        expertId: message?.expert?.id,
        date: new Date(message?.responseDate),
        communicationId: 'Message:' + message?.messageId,
        communicationType: CommunicationType.MESSAGE,
        clientMode: true,
    };

    return (
        <>
            <Button
                icon={<Icons.Comment size={25} style={{paddingRight: 10, paddingTop: 4}} />}
                title="Donner mon avis"
                onPress={reviewMessage}
            />
            <PopupWriteReview
                visible={isReviewVisible}
                onRequestClose={() => setIsReviewVisible(false)}
                reviewData={reviewData}
                onSuccess={endReview}
            />
        </>
    );
}

function SingleMessage(props: SingleMessageProps) {
    const {isDesktop} = useDeviceQuery();
    const message = props.message;
    if (!message) {
        return null;
    }
    const date = new Date(message.creationDate);
    const isOutgoing = props.exchangeType == message.type;
    const pictureName = isOutgoing ? props.myPicture : props.recipientPicture;
    const exchangeTypeStyle = getStyle(props);
    const displayedAttachments = message.attachments;

    const deviceStyle = isDesktop ? styles : mobileStyles;
    const free = isFreeMessageInThread(props);

    return (
        <View style={[deviceStyle.exchange, exchangeTypeStyle]}>
            <ProfilePicture
                style={{borderRadius: 50, width: 50, height: 50, marginRight: 25}}
                pictureName={pictureName}
            />
            <View style={deviceStyle.exchangeBody}>
                <SimpleText style={styles.content}>{message.content}</SimpleText>
                {isDesktop && displayedAttachments.length > 0 && (
                    <View style={styles.attachementLabelContainer}>
                        <Icons.Attachment
                            size={14}
                            style={{paddingRight: 10, paddingTop: 2}}
                            color={Colors.link}
                        />

                        <SimpleText style={styles.attachementLabel}>
                            {displayedAttachments.length} pièce(s) jointe(s)
                        </SimpleText>
                    </View>
                )}
                <PicturesViewer pictureNames={displayedAttachments} />
            </View>
            <View style={styles.info}>
                <SimpleText style={styles.exchangeDate}>
                    le {dates.dateString(date)} à {dates.timeString(date)}
                </SimpleText>
                {free && <SimpleText style={styles.toExpertFree}>Suivi</SimpleText>}
            </View>
        </View>
    );
}

function isFreeMessageInThread(args: SingleMessageProps) {
    return (
        args.messageType != MessageTypeEnum.FREE &&
        args.exchangeType == ExchangeTypeEnum.EXPERT_TO_CLIENT &&
        args.message.type == ExchangeTypeEnum.CLIENT_TO_EXPERT &&
        args.index > 0
    );
}

function getStyle(args: SingleMessageProps) {
    if (isFreeMessageInThread(args)) {
        return styles.toMeFree;
    }
    if (args.exchangeType == args.message.type) {
        return styles.fromMe;
    } else {
        return styles.toMe;
    }
}

function getProfile(exchangeType: ExchangeTypeEnum, message: Message) {
    if (exchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT) {
        return message.expert;
    } else if (exchangeType == ExchangeTypeEnum.EXPERT_TO_CLIENT) {
        return message.client;
    } else {
        return message.expert;
    }
}

type MessageProps = {
    message: Message;
    exchangeType: ExchangeTypeEnum;
    onResponseSuccess: () => void;
    onReviewSuccess: () => void;
    onStatisticsClientPress: (clientId: string) => void;
};

type ReponseViewProps = {
    message: Message;
    exchangeType: ExchangeTypeEnum;
    onResponseSuccess: () => void;
    limitFree: boolean;
    limitPaying: boolean;
};

type HeaderProps = {
    recipientName?: string;
    subject: string;
};

type SingleMessageProps = {
    index: number;
    message: MessageExchange;
    messageType: MessageTypeEnum;
    exchangeType: ExchangeTypeEnum;
    myPicture?: string;
    recipientPicture: string;
};

type Inputs = Pick<YupTypes, 'content'>;

type ReviewProps = {
    message: Message;
    onSuccess: () => void;
};

const SEND_RESPONSE = gql`
    mutation sendResponse(
        $messageId: String!
        $exchangeType: String!
        $content: String!
        $attachments: [String]
        $group: Boolean
    ) {
        sendResponse(
            input: {
                messageId: $messageId
                exchangeType: $exchangeType
                content: $content
                attachments: $attachments
                group: $group
            }
        ) {
            message {
                id
            }
            exchange {
                id
            }
        }
    }
`;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: Colors.dark,
        marginLeft: 20,
        borderRadius: 2,
    },
    topContainer: {
        alignItems: 'baseline',
        flexDirection: 'row',
        height: 110,
        padding: 30,
        borderBottomColor: Colors.separator,
        borderBottomWidth: 1,
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'column',
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 15,
    },
    name: {
        fontSize: 15,
    },
    date: {
        fontSize: 14,
    },
    subject: {
        fontSize: 22,
        fontWeight: 'bold',
    },
    listContainer: {},
    exchangeContainer: {},
    exchange: {
        borderBottomColor: Colors.separator,
        borderBottomWidth: 1,
        borderLeftColor: Colors.separator,
        borderRightColor: Colors.separator,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        backgroundColor: 'white',
        padding: 20,
        flexDirection: 'row',
        alignContent: 'space-between',
        justifyContent: 'space-between',
    },
    exchangeBody: {
        flex: 1,
    },
    fromMe: {},
    toMe: {},
    toMeFree: {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: Colors.nice,
        borderLeftColor: Colors.nice,
        borderRightColor: Colors.nice,
        borderBottomColor: Colors.nice,
    },
    info: {
        alignItems: 'flex-end',
        justifyContent: 'space-evenly',
    },
    toExpertFree: {
        backgroundColor: Colors.nice,
        color: 'white',
        borderRadius: 5,
        fontSize: 12,
        paddingLeft: 5,
        paddingRight: 5,
        height: 16,
        width: 100,
        textAlign: 'center',
    },
    content: {
        fontSize: 14,
    },
    exchangeDate: {
        fontSize: 12,
        color: Colors.fade,
        fontFamily: 'sans-serif',
    },
    responseContainer: {
        paddingTop: 25,
    },
    formContainer: {},
    contentContainer: {},
    contentInput: {
        height: 300,
        flex: 1,
        backgroundColor: 'white',
    },
    buttons: {},
    error: {
        color: 'red',
        alignSelf: 'center',
    },
    attachementLabelContainer: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        marginVertical: 10,
    },
    attachementLabel: {
        color: Colors.link,
    },
    statisticsContainer: {
        backgroundColor: 'white',
        paddingTop: 20,
    },
    statistics: {
        flex: 1,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.highlight,
        borderRadius: 7,
    },
    statisticsLabel: {
        color: Colors.highlight,
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        flex: 1,
        textAlign: 'center',
        padding: 20,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
    },
    topContainer: {
        marginVertical: 30,
    },
    headerContainer: {},
    subject: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    name: {},
    exchange: {
        marginBottom: 15,
        borderRadius: 10,
        borderBottomColor: Colors.separator,
        borderBottomWidth: 1,
        borderLeftColor: Colors.separator,
        borderRightColor: Colors.separator,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        backgroundColor: 'white',
        padding: 10,
        flexDirection: 'column',
        alignContent: 'space-between',
        justifyContent: 'space-between',
    },
    exchangeBody: {
        marginVertical: 20,
    },
});
