import {gql, useQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import type {HomeExpertsQuery} from './types/HomeExpertsQuery';
import expert from './fragments/expert';

const HOME_EXPERT_QUERY = gql`
    query HomeExpertsQuery {
        homeExperts {
            edges {
                node {
                    expert {
                        ...ExpertDetails
                    }
                }
            }
        }
    }
    ${expert}
`;

export default function useHomeExperts() {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    };
    return useQuery<HomeExpertsQuery>(HOME_EXPERT_QUERY, options);
}
