import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

import {MainView} from '~/components/common/Containers';
import BlocMessage from '~/components/user/client/dashboard/BlocMessage';
import {EnumModeMessageBox} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import SignOut from '../../SignOut';
import BlocAccount from '../../client/dashboard/BlocAccount';
import BlocBenefit from './BlocBenefit';
import BlocRecall from './BlocRecall';
import BlocReload from './BlocReload';
import BlocReview from './BlocReview';
import BlocStatus from './BlocStatus';
import BlocWaitingList from './BlocWaitingList';

export default function ExpertDashboard() {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return (
            <MainView>
                <View style={styles.container}>
                    <View style={styles.column}>
                        <BlocReload />
                        <BlocAccount />
                        <BlocReview />
                    </View>
                    <View style={styles.column}>
                        <BlocStatus />
                        <BlocRecall />
                        <SignOut />
                    </View>
                    <View style={styles.column}>
                        <BlocBenefit />
                        <BlocWaitingList />
                        <BlocMessage mode={EnumModeMessageBox.EXPERT} />
                    </View>
                </View>
            </MainView>
        );
    } else {
        return (
            <MainView>
                <View style={mobileStyles.container}>
                    <BlocReload />
                    <BlocStatus />
                    <BlocAccount />
                    <BlocBenefit />
                    <BlocWaitingList />
                    <BlocReview />
                    <BlocMessage mode={EnumModeMessageBox.EXPERT} />
                    <BlocRecall />
                    <SignOut />
                </View>
            </MainView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
    },
    column: {
        marginHorizontal: 10,
    },
});

const mobileStyles = StyleSheet.create({
    container: {},
});
