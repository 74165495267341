import React from 'react';
import {ScrollView} from 'react-native';

import {LinearGradient} from 'expo-linear-gradient';

import {MainView} from '~/components/common/Containers';
import Edito from '~/components/home/Edito';
import {Help} from '~/components/home/Help';
import HomeExperts from '~/components/home/HomeExperts';
import Newsletter from '~/components/home/Newsletter';
import Note from '~/components/home/Note';
import Offer from '~/components/home/Offer';
import {Payments} from '~/components/home/Payments';
import Posts from '~/components/home/Posts';
import {ReviewCarousel} from '~/components/home/ReviewCarousel';
import {Wave} from '~/components/home/Wave';
import {MobileWelcome, WelcomeMessage} from '~/components/home/Welcome';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function HomeScreen() {
    const {isDesktop} = useDeviceQuery();
    if (isDesktop) {
        return <DesktopHomeScreen />;
    } else {
        return <MobileHomeScreen />;
    }
}

function MobileHomeScreen() {
    const {me} = React.useContext(UserContext);

    return (
        <MainView>
            <Wave />
            {!me && <MobileWelcome />}
            <WelcomeMessage />
            <Payments />
            <HomeExperts />
            <ReviewCarousel />
            <Help />
            <Note />
            <Edito />
            <Posts />
        </MainView>
    );
}

function DesktopHomeScreen() {
    return (
        <ScrollView>
            <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={Colors.rainbow}>
                <WelcomeMessage />
            </LinearGradient>
            <Wave />
            <MainView>
                <Payments />
                <HomeExperts />
                <ReviewCarousel />
                <Help />
                <Note />
                <Edito />
                <Newsletter />
                <Posts />
            </MainView>
        </ScrollView>
    );
}
