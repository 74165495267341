import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';

import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {MailInput, MessageInput, SubjectInput} from '~/components/common/Inputs';
import {PhoneInput} from '~/components/common/PhoneInput';
import {FormLabel} from '~/components/common/Texts';
import Settings from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {axios} from '~/helpers/network';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

const {subject, message, email} = yupRules;
const validationSchema = yup.object().shape({subject, message, email});

export default function SupportForm({onSuccess}: {onSuccess: Function}) {
    const {me, loadingMe} = React.useContext(UserContext);
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');

    useEffect(() => {
        setPhone(me?.identity?.phone ?? '');
        setPhoneError('');
    }, [me]);

    async function send(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        try {
            const body = JSON.stringify({
                subject: values.subject,
                message: values.message,
                phone: phone,
                email: values.email,
            });
            const response = await axios.post(Settings.getUrlMessageSupport(), body);
            onSuccess();
            logEvent('send_message_support', {fields: 'email'});
        } catch (error) {
            throw error;
        }
    }

    if (loadingMe) {
        return <></>;
    }

    return (
        <View>
            <Formik
                initialValues={{
                    subject: '',
                    message: '',
                    email: me?.identity?.email ?? '',
                }}
                onSubmit={send}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <SupportInputs
                        formik={formikProps}
                        initialize={me != null}
                        phone={phone}
                        setPhone={setPhone}
                        phoneError={phoneError}
                        setPhoneError={setPhoneError}
                    />
                )}
            </Formik>
        </View>
    );
}

function SupportInputs(props: SupportInputsProps) {
    return (
        <View style={styles.container}>
            <FormLabel>Sujet du message</FormLabel>
            <SubjectInput
                placeholder={yupLabels.subject}
                value={props.formik.values.subject}
                onChangeText={props.formik.handleChange('subject')}
                onBlur={() => props.formik.setFieldTouched('subject')}
                blurOnSubmit={true}
                errorMessage={props.formik.errors.subject || ' '}
            />
            <FormLabel>Comment pouvons-nous vous aider ?</FormLabel>
            <MessageInput
                placeholder={yupLabels.message}
                value={props.formik.values.message}
                onChangeText={props.formik.handleChange('message')}
                onBlur={() => props.formik.setFieldTouched('message')}
                blurOnSubmit={false}
                errorMessage={props.formik.errors.message || ' '}
                style={{height: 90}}
            />
            <FormLabel>Numéro de téléphone</FormLabel>
            <PhoneInput
                disabled={false}
                initialize={props.initialize}
                width={310}
                phone={props.phone}
                setPhone={props.setPhone}
                phoneError={props.phoneError}
                setPhoneError={props.setPhoneError}
            />
            <FormLabel>Email</FormLabel>
            <MailInput
                placeholder={yupLabels.email}
                value={props.formik.values.email}
                onChangeText={props.formik.handleChange('email')}
                onBlur={() => props.formik.setFieldTouched('email')}
                blurOnSubmit={true}
                errorMessage={props.formik.errors.email || ' '}
            />
            <Button
                icon={<Icons.MailSend size={16} style={{paddingRight: 10}} />}
                title="Envoyer"
                buttonStyle={{width: 150, alignSelf: 'center', marginBottom: 20}}
                disabled={!props.formik.isValid || props.phoneError != ''}
                onPress={() => props.formik.handleSubmit()}
                loading={props.formik.isSubmitting}
            />
        </View>
    );
}

type Inputs = Pick<YupTypes, 'subject' | 'message' | 'email'>;
type Helpers = FormikHelpers<Inputs>;
type SupportInputsProps = {
    formik: FormikProps<Inputs>;
    initialize: boolean;
    phone: string;
    setPhone: (phone: string) => void;
    phoneError: string;
    setPhoneError: (phone: string) => void;
};

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        marginVertical: 10,
        borderRadius: 5,
        marginHorizontal: 25,
        alignContent: 'space-around',
        backgroundColor: 'white',
        paddingBottom: 10,
    },
});
