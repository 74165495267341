import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import {PaymentMinute, Payments} from '~/components/home/Payments';
import PackList from '~/components/pack/PackList';
import * as Colors from '~/constants/Colors';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function PriceScreen() {
    const {isDesktop} = useDeviceQuery();

    return (
        <MainView>
            <PageTitle>Nos tarifs</PageTitle>
            {isDesktop && (
                <>
                    <BlocPack />
                    <BlocMinute />
                </>
            )}
            {!isDesktop && (
                <>
                    <BlocMinute />
                    <BlocPack />
                </>
            )}
        </MainView>
    );
}

export function BlocPack() {
    return (
        <>
            <SimpleText style={styles.subtitle} accessibilityRole="header" aria-level="2">
                Chez Kyvoitou.fr, nous aimons que vous ayez la choix ! Alors nous mettons à
                votre disposition deux moyens de paiement :
            </SimpleText>
            <SimpleText style={styles.header} accessibilityRole="header" aria-level="3">
                Nos packs
            </SimpleText>
            <PackList />
        </>
    );
}
export function BlocMinute() {
    const {isDesktop} = useDeviceQuery();
    return (
        <>
            <SimpleText style={styles.header} accessibilityRole="header" aria-level="3">
                Paiement à la minute
            </SimpleText>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: 5,
                    marginHorizontal: 5,
                }}
            >
                <Icons.Hand
                    size={32}
                    color={Colors.secondary}
                    style={{marginRight: 15, alignSelf: 'center'}}
                />
                <SimpleText
                    style={{
                        color: Colors.secondary,
                        fontSize: 20,
                        alignSelf: 'center',
                        textAlign: isDesktop ? 'left' : 'center',
                    }}
                >
                    3 séances offertes soit{' '}
                    <SimpleText
                        style={{
                            fontSize: 20,
                            color: Colors.nice,
                        }}
                    >
                        15 minutes gratuites de consultation
                    </SimpleText>{' '}
                    avec les mediums de votre choix pour un moment d'exception.
                </SimpleText>
            </View>
            <View style={{alignItems: 'center'}}>
                <PaymentMinute />
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    subtitle: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        paddingHorizontal: 5,
    },
    header: {
        fontSize: 30,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 60,
        marginBottom: 30,
        paddingHorizontal: 5,
    },
});
