import * as React from 'react';
import {
    View,
    StyleSheet,
    FlatList,
    ScrollView,
    TouchableHighlight,
    TouchableOpacity,
} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {useFocusEffect, useNavigation} from '@react-navigation/native';

import {Bloc} from '~/components/common/Containers';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {CallButton, MessageButton, ChatButton} from '~/components/expert/ExpertButtons';
import {BlocContentLoader} from '~/components/navigation/Loader';
import {GetLastExpertsQuery_getLastExperts_edges_node as Expert} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import {GetLastExpertsQuery_getLastExperts_edges as Experts} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import * as Colors from '~/constants/Colors';
import {getColor, getSpecialities, getText, maxAvailability} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';

const GET_LAST_EXPERTS_QUERY = gql`
    query GetLastExpertsQuery {
        getLastExperts {
            edges {
                node {
                    id
                    userId
                    profile {
                        displayName
                        pictureName
                    }
                    expert {
                        callStatus
                        messageStatus
                        chatStatus
                    }
                    tags {
                        edges {
                            node {
                                id
                                label
                                tagTypeId
                            }
                        }
                    }
                    callParameters {
                        id
                        acceptOffer
                        price {
                            id
                            label
                            priceDecimal
                        }
                    }
                    messageParameters {
                        edges {
                            node {
                                messagePrice {
                                    id
                                    messagePriceId
                                    label
                                    priceDecimal
                                }
                                type
                                description
                            }
                        }
                    }
                    chatParameters {
                        acceptOffer
                        chatPrice {
                            id
                            chatPriceId
                            label
                            priceDecimal
                        }
                    }
                }
            }
        }
    }
`;

export default function BlocExpert() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();

    function goToHistoric() {
        navigation.navigate('HistoricScreen');
    }

    let title = 'Les spirites consultés';
    if (!isDesktop) {
        title = 'Dernier spirite consulté';
    }

    return (
        <Bloc title={title} iconName="user-tie">
            <BlocExpertContent />
            {!isDesktop && (
                <TouchableOpacity style={styles.linkContainer} onPress={goToHistoric}>
                    <SimpleText style={styles.link}>
                        Voir mon historique de consultation
                    </SimpleText>
                </TouchableOpacity>
            )}
        </Bloc>
    );
}

function BlocExpertContent() {
    const [load, {called, loading, data, refetch}] = useLazyQuery(GET_LAST_EXPERTS_QUERY, {
        fetchPolicy: 'network-only',
    });
    const {isDesktop} = useDeviceQuery();

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    function renderRow({item}: {item: Expert}) {
        const expert = item;

        const specialities = getSpecialities(item);

        const availability = maxAvailability(expert);

        return (
            <View style={styles.expertContainer}>
                <View style={styles.headerContainer}>
                    <View style={styles.profileNameContainer}>
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={expert?.profile?.pictureName}
                            displayName={expert?.profile?.displayName}
                        />
                        <View style={styles.nameContainer}>
                            <SimpleText style={styles.expertName}>
                                {expert?.profile?.displayName}
                            </SimpleText>
                            <SimpleText style={styles.specialty}>{specialities}</SimpleText>
                        </View>
                    </View>
                    <View style={styles.availabilityContainer}>
                        <SimpleText style={{color: getColor(availability)}}>
                            {getText(availability)}
                        </SimpleText>
                    </View>
                </View>
                <View style={styles.buttonContainer}>
                    <CallButton title="Appel" titleStyle={{fontSize: 12}} user={expert} />
                    <MessageButton
                        title="Question"
                        titleStyle={{fontSize: 12}}
                        user={expert}
                    />
                    <ChatButton title="Chat" titleStyle={{fontSize: 12}} user={expert} />
                </View>
            </View>
        );
    }

    if (data) {
        const filteredData = data.getLastExperts.edges;
        let list = filteredData.map((e: Experts) => {
            return e.node;
        }) as Expert[];

        const noExpert = list.length == 0;
        const listExpert = list.length > 0;

        if (!isDesktop) {
            list = list.slice(0, 1);
        }

        return (
            <>
                {noExpert && (
                    <SimpleText style={styles.noExpert}>
                        Vous n'avez aucune consultation réçente.
                    </SimpleText>
                )}
                {listExpert && (
                    <>
                        <ScrollView style={styles.experts}>
                            <FlatList
                                data={list}
                                renderItem={renderRow}
                                keyExtractor={(item) => item.id}
                                style={styles.listContainer}
                            />
                        </ScrollView>
                    </>
                )}
            </>
        );
    }

    return <BlocContentLoader />;
}

const styles = StyleSheet.create({
    experts: {
        flex: 1,
        flexDirection: 'column',
        maxHeight: 390,
    },
    noExpert: {
        alignSelf: 'center',
        padding: 17,
        flex: 1,
    },
    expertContainer: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 10,
        marginTop: 15,
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    profileNameContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    nameContainer: {},
    availabilityContainer: {},
    listContainer: {},
    profilePicture: {
        width: 37,
        height: 37,
        borderRadius: 40,
        alignSelf: 'center',
        marginRight: 8,
    },
    messageHeaderContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    expertName: {
        fontSize: 14,
    },
    specialty: {
        fontSize: 12,
        fontStyle: 'italic',
        marginTop: 10,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
    },
    linkContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    link: {
        fontSize: 12,
        marginTop: 5,
        color: Colors.link,
    },
});
