import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';

export default function JobScreen() {
    return (
        <MainView style={{padding: 15}}>
            <PageTitle>Recrutement Kyvoitou.fr</PageTitle>
            <SimpleText style={styles.text}>
                Chez Kyvoitou.fr on a à coeur d’agrandir notre équipe pour vous offrir des
                services de qualités grâce au savoir-faire de tout un chacun.
            </SimpleText>
            <SimpleText style={styles.text}>
                Fort d’un esprit d'équipe et de partage, nous recherchons continuellement des
                pépites motivées et professionnelles.
            </SimpleText>

            <View style={styles.container}>
                <Icons.Hand size={26} color={Colors.secondary} />
                <SimpleText style={styles.jobs}>Les postes envisagés :</SimpleText>
            </View>

            <View style={styles.title}>
                <Icons.Favorite size={26} color={Colors.secondary} isFavorite={false} />
                <SimpleText style={styles.job}>Community manager :</SimpleText>
            </View>

            <SimpleText style={styles.description}>
                Le CM est le garant de notre réputation et de notre marque à l’égard des
                réseaux sociaux. Il est en charge du développement de celle-ci. Il doit être
                responsable et fort de propositions pour créer un champ de présence large pour
                l’entreprise dans le monde du net.
            </SimpleText>

            <SimpleText style={styles.underline}>Les missions :</SimpleText>

            <SimpleText style={styles.mission}>
                - Création de contenus pour Instagram & Facebook, animation de la communauté,
                planification des publications…
            </SimpleText>
            <SimpleText style={styles.mission}>- Analyser les statistiques</SimpleText>
            <SimpleText style={styles.mission}>
                - Analyse des tendances sur les réseaux sociaux
            </SimpleText>
            <SimpleText style={styles.mission}>- Analyse de la réputation</SimpleText>
            <SimpleText style={styles.mission}>
                - Définition d'une stratégie sociale
            </SimpleText>
            <SimpleText style={styles.mission}>- Définition des KPI</SimpleText>
            <SimpleText style={styles.mission}>
                - Travail sur Wordpress pour mise en ligne et optimisation d’articles
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Participation à la rédaction du plan de communication
            </SimpleText>

            <SimpleText style={styles.underline}>Profil recherché :</SimpleText>

            <SimpleText style={styles.mission}>
                - Spécialiste ou Étudiant.e en communication et/ou marketing
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Bonnes capacités rédactionnelles et relationnelles
            </SimpleText>
            <SimpleText style={styles.mission}>- Proactivité</SimpleText>
            <SimpleText style={styles.mission}>- Force de propositions</SimpleText>
            <SimpleText style={styles.mission}>
                - Tu es autonome et tu aimes travailler en équipe
            </SimpleText>

            <SimpleText style={styles.underline}>Les petit + :</SimpleText>

            <SimpleText style={styles.mission}>
                - Tu as des connaissances en référencement naturel (SEO)
            </SimpleText>
            <SimpleText style={styles.mission}>- Connaissance de Wordpress</SimpleText>
            <SimpleText style={styles.mission}>- Connaissance de Canva</SimpleText>

            <View style={styles.title}>
                <Icons.Favorite size={26} color={Colors.secondary} isFavorite={false} />
                <SimpleText style={styles.job}>
                    WEBMARKETING / Spécialiste en SEO & SEA
                </SimpleText>
            </View>

            <SimpleText style={styles.description}>
                Doté de solides connaissances en matière technique, le e-marketeur ou l’expert
                en SEO / SEA participe au référencement naturel et organique du site. Le
                positionnement de l’entreprise sur les moteurs de recherche comme Google seront
                les axes principalement visés de façon à générer du trafic sur le blog et sur
                notre plateforme de voyance en ligne.
            </SimpleText>

            <SimpleText style={styles.underline}>Les missions :</SimpleText>

            <SimpleText style={styles.mission}>
                - Contribuer au développement de la visibilité de Kyvoitou.fr : référencement
                naturel et payant par mot clés ciblés
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Participer à l’optimisation de la stratégie marketing et digitale
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Rédaction, optimisation et mise en ligne d’articles de blog
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Assister la responsable en communication pour la création de contenu,
                programmation et mise en ligne
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Les éléments techniques ou d'infrastructures (nom de domaine, optimisation
                conformité code, etc.)
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Les éléments d'optimisations de contenus des pages
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Le développement et l'optimisation de liens externes backlinks à travers les
                pratiques de netlinking
            </SimpleText>
            <SimpleText style={styles.mission}>Evaluation des résultats ROI</SimpleText>

            <SimpleText style={styles.underline}>Profil recherché :</SimpleText>

            <SimpleText style={styles.mission}>
                - Spécialiste ou étudiant dans le digital, le webmarketing ou équivalent
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Tu as une grande appétence pour le SEO
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Tu as de bonnes qualités rédactionnelles
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Tu es autonome et aime travailler en équipe
            </SimpleText>
            <SimpleText style={styles.mission}>
                - Tu aimes prendre des initiatives et tu es force de proposition
            </SimpleText>

            <View style={{marginTop: 50}}>
                <SimpleText style={styles.text}>
                    Pour candidater, merci d’envoyer votre curriculum vitae et une lettre de
                    motivation ici, et nous reviendrons vers vous pour échanger.
                </SimpleText>
            </View>
            <Icons.HandDown
                color={Colors.secondary}
                size={30}
                style={{marginTop: 30, alignSelf: 'center'}}
            />
            <SimpleText style={styles.text}>{Settings.contact_mail}</SimpleText>
            <SimpleText style={styles.text}>{Settings.support_telephone}</SimpleText>
            <View style={{marginTop: 50}}>
                <SimpleText style={styles.text}>L’équipe Kyvoitou.fr</SimpleText>
            </View>
        </MainView>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 50,
    },
    text: {
        marginTop: 30,
        fontSize: 20,
        textAlign: 'center',
        color: Colors.secondary,
    },
    jobs: {
        fontSize: 20,
        color: Colors.secondary,
        marginLeft: 15,
    },
    title: {
        flexDirection: 'row',
        marginTop: 50,
        marginBottom: 10,
    },
    job: {
        fontSize: 20,
        fontWeight: 'bold',
        color: Colors.secondary,
        marginLeft: 15,
        alignSelf: 'center',
    },
    description: {
        marginTop: 15,
        fontSize: 20,
        color: Colors.secondary,
    },
    underline: {
        fontSize: 20,
        textDecorationLine: 'underline',
        color: Colors.secondary,
        marginVertical: 20,
    },
    mission: {
        fontSize: 20,
        color: Colors.secondary,
    },
});
