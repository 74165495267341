import * as React from 'react';
import {StyleSheet} from 'react-native';

import {RouteProp, useRoute} from '@react-navigation/native';

import {MustBeConnected} from '~/components/navigation/Link';
import {ScreenLoader} from '~/components/navigation/Loader';
import MessageBox from '~/components/user/client/message/MessageBox';
import NewMessage from '~/components/user/client/message/NewMessage';
import {UserContext} from '~/contexts/UserContext';
import {EnumModeMessageBox} from '~/helpers/message';
import {ProfileParamList} from '~/types';

export default function MessageScreen() {
    const route = useRoute<RouteProp<ProfileParamList, 'MessageScreen'>>();

    const {loadingUser, user, loadingMe, me} = React.useContext(UserContext);

    const expertId = route?.params?.expert_id;
    const messageId = route?.params?.message_id;
    const messageType = route?.params?.type;

    const isNewMessage = expertId && messageType;

    if (!loadingUser && !loadingMe && !user && !me) {
        return <MustBeConnected />;
    }

    if (me) {
        if (isNewMessage) {
            return (
                <NewMessage
                    expertId={expertId}
                    messageId={messageId ?? ''}
                    messageType={messageType}
                />
            );
        } else {
            return (
                <MessageBox
                    mode={me.isExpert ? EnumModeMessageBox.EXPERT : EnumModeMessageBox.CLIENT}
                />
            );
        }
    }

    return <ScreenLoader />;
}

const styles = StyleSheet.create({});
