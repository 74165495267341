import * as React from 'react';
import {StyleSheet} from 'react-native';

import {SimpleText} from './Texts';

export default function Error() {
    return <SimpleText style={styles.error}>Error</SimpleText>;
}

type MessageErrorProps = {
    message: string;
};
export function MessageError(props: MessageErrorProps) {
    if (props.message) {
        return <SimpleText style={styles.message}>{props.message}</SimpleText>;
    } else {
        return <></>;
    }
}

const styles = StyleSheet.create({
    error: {
        color: 'red',
    },
    message: {
        color: 'red',
        fontSize: 12,
        marginLeft: 10,
        marginTop: 10,
    },
});
