/* Firebase user documentation: https://firebase.google.com/docs/auth/web/start#web-v9 */
import {Platform} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import {FirebaseApp, initializeApp} from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';

export const TOKEN_CACHE_KEY = 'user-firebase-token';

let setupPersistence = Promise.resolve();
export let app: FirebaseApp | undefined;
let auth: firebaseAuth.Auth | undefined;

if (typeof window !== 'undefined') {
    console.log('Initialize APP');
    app = initializeApp(Constants.expoConfig?.extra?.firebaseConfig);
    auth = firebaseAuth.getAuth(app);
    setupPersistence = auth.setPersistence(firebaseAuth.browserLocalPersistence);
} else {
    console.log('NOT NOT NOT NOT NOT Initialize APP');
}

export type UserCredential = firebaseAuth.UserCredential;
export type User = firebaseAuth.User | null | undefined;
export type UserFunction = (user: User) => void;
export type Profile = {
    displayName?: string | null | undefined;
    photoURL?: string | null | undefined;
};

export async function register(email: string, password: string) {
    if (auth) {
        await setupPersistence;
        return await firebaseAuth.createUserWithEmailAndPassword(auth, email, password);
    }
}

export async function signIn(email: string, password: string) {
    if (auth) {
        await setupPersistence;
        return await firebaseAuth.signInWithEmailAndPassword(auth, email, password);
    }
}

export async function reAuthenticate(password: string) {
    const user = auth?.currentUser;
    if (user) {
        const credential = firebaseAuth.EmailAuthProvider.credential(
            user?.email ?? '',
            password
        );
        return await firebaseAuth.reauthenticateWithCredential(user, credential);
    }
}

export async function sendPasswordResetEmail(email: string) {
    if (auth) {
        return await firebaseAuth.sendPasswordResetEmail(auth, email);
    }
}

export async function signOut() {
    if (auth) {
        return await firebaseAuth.signOut(auth);
    }
}

export function currentUser() {
    return auth?.currentUser;
}

export async function userAuthToken(user: User) {
    const idToken = await userIdToken(user);
    return idToken ? bearer(idToken) : undefined;
}

function bearer(token?: string): string | null {
    return token ? `Bearer ${token}` : null;
}

export async function userIdToken(user: User) {
    return await user?.getIdToken();
}

export async function onAuthStateChanged(callback: UserFunction) {
    auth?.onAuthStateChanged(callback);
}

export async function onIdTokenChanged(callback: UserFunction) {
    auth?.onIdTokenChanged(callback);
}

export async function updateUserProfile(profile: Profile) {
    const user = currentUser();
    if (user) {
        return await firebaseAuth.updateProfile(user, profile);
    }
}

export async function updateEmail(email: string) {
    const user = currentUser();
    if (user) {
        return await firebaseAuth.updateEmail(user, email);
    }
}

export async function getToken() {
    const user = currentUser();
    if (user) {
        const authHeader = await userAuthToken(user);
        if (authHeader) {
            AsyncStorage.setItem(TOKEN_CACHE_KEY, authHeader);
            return authHeader;
        }
    } else {
        if (Platform.OS === 'web' && typeof window === 'undefined') {
            return null;
        }
        return await AsyncStorage.getItem(TOKEN_CACHE_KEY);
    }

    return null;
}

const TIME_MARGIN = 60;

export function tokenExpired(bearer: string) {
    const tokenContent = parseJwt(bearer);
    // console.log('Token Content ', tokenContent);
    const {exp} = tokenContent;
    const expirationDatetime = exp * 1000 - TIME_MARGIN;
    // console.log(`${Date.now()} >= ${expirationDatetime}`);
    return Date.now() >= expirationDatetime;
}

function parseJwt(bearer: string) {
    var token = bearer.split(' ')[1];
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}
