import React, {useContext, useRef} from 'react';
import {View, StyleSheet, ScrollView, ImageBackground, TouchableOpacity} from 'react-native';

import {gql, useMutation, useQuery} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/core';
import {useNavigation} from '@react-navigation/native';
import {RouteProp, useRoute} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {Separator} from '~/components/common/Separator';
import {SimpleText, MobileTitle} from '~/components/common/Texts';
import {CallButton, MessageButton, ChatButton} from '~/components/expert/ExpertButtons';
import {MobileExpertButtons} from '~/components/expert/ExpertCard';
import GradeList, {Grade} from '~/components/expert/GradeList';
import QualityList from '~/components/expert/QualityList';
import type {Quality} from '~/components/expert/QualityList';
import ReviewsMobile from '~/components/expert/ReviewMobile';
import Reviews from '~/components/expert/Reviews';
import {isPaymentIntentReadyToCapture} from '~/components/user/stripe/AddPaymentMethod';
import * as Colors from '~/constants/Colors';
import Settings, {STRIPE_3DS_HOLD} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {CallStatusContext} from '~/contexts/CallStatusContext';
import {ChatStatusContext} from '~/contexts/ChatStatusContext';
import {UserContext} from '~/contexts/UserContext';
import {
    getSmallerMessagePrice,
    AVAILABLE,
    UNAVAILABLE,
    getText,
    isNew,
    minPriceText,
} from '~/helpers/experts';
import {isFavorite, getOffer, getSpecialities} from '~/helpers/experts';
import {filterEmpty} from '~/helpers/list';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import useFavoriteMutation from '~/queries/mutations/setFavorite';
import {SearchParamList} from '~/types';

import type {ExpertProfile, ExpertProfile_getExpert} from './types/ExpertProfile';
import {confirmPaymentHold} from './types/confirmPaymentHold';

const CONFIRM_PAYMENT_HOLD = gql`
    mutation confirmPaymentHold(
        $service: String!
        $paymentIntentId: String!
        $success: Boolean!
        $communicationId: String
    ) {
        confirmPaymentHold(
            input: {
                service: $service
                paymentIntentId: $paymentIntentId
                success: $success
                communicationId: $communicationId
            }
        ) {
            ok
        }
    }
`;

export default function ExpertScreen() {
    const route = useRoute<RouteProp<SearchParamList, 'ExpertScreen'>>();
    const {isDesktop} = useDeviceQuery();
    const {me, loadingMe, offers, refetchOffers} = React.useContext(UserContext);
    const [confirmPaymentHold] = useMutation<confirmPaymentHold>(CONFIRM_PAYMENT_HOLD);
    const navigation = useNavigation();
    const alert = useAlert();
    const url = route.params.url;
    const chat = React.useContext(ChatStatusContext);
    const call = React.useContext(CallStatusContext);

    const variables = {url: url};
    const {loading, data} = useQuery<ExpertProfile>(EXPERT_PROFILE_QUERY, {
        variables,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !url,
    });

    useFocusEffect(
        React.useCallback(() => {
            if (route.params) {
                !loadingMe && !loading && route.params.event && dispatch(route.params.event);
            }
        }, [loading, loadingMe, route.params])
    );

    const EVENTS = new Map([[STRIPE_3DS_HOLD, on3dsHold]]);

    async function dispatch(event: string) {
        const result = await EVENTS.get(event)?.();
        navigation.setParams({
            event: undefined,
            payment_intent: undefined,
            payment_intent_client_secret: undefined,
            service: undefined,
            source_redirect_slug: undefined,
            source_type: undefined,
            message_type: undefined,
            message_id: undefined,
            warning: undefined,
        });
        return result;
    }

    async function on3dsHold() {
        const paymentIntentClientSecret = route.params.payment_intent_client_secret;
        if (paymentIntentClientSecret) {
            const success = await isPaymentIntentReadyToCapture(paymentIntentClientSecret);
            if (success) {
                // Trigger communication only after popup
                alert({
                    title: 'Merci',
                    message:
                        'Votre réservation de paiement a bien été effectué. Votre consultation va commencer.',
                    onClose: () => confirmHold(success),
                });
            } else {
                // Must cancel payment asap
                await confirmHold(success);
                alert({
                    title: 'Avertissement',
                    message:
                        "Une erreur est survenue pendant votre réservation de paiement. Veuillez contactez l'équipe support.",
                });
            }
        }
    }

    async function confirmHold(success: boolean) {
        const service = route.params.service;
        const paymentIntent = route.params.payment_intent;
        const messageType = route.params.message_type;
        const messageId = route.params.message_id;
        const warning = route.params.warning ?? 0;
        const mutationData = {
            service: service,
            paymentIntentId: paymentIntent,
            success: success,
            communicationId: messageId,
        };

        const result = await confirmPaymentHold({variables: mutationData});
        if (success && data?.getExpert?.id && result.data?.confirmPaymentHold?.ok && service) {
            if (service == 'MESSAGE') {
                const params = {
                    expert_id: data.getExpert.id,
                    message_id: messageId,
                    type: messageType,
                };
                navigation.navigate('MessageScreen', params);
            }
            if (service == 'CHAT') {
                chat?.current?.chatExpert(me, data.getExpert, offers, refetchOffers);
                chat?.current?.startChatFromOutside(data.getExpert.id, warning);
            }
            if (service == 'CALL') {
                call?.current?.callExpert(data.getExpert);
                call?.current?.startCallFromOutside(warning);
            }
        }
    }

    const offer = getOffer(me, offers, data?.getExpert?.id);

    if ((loading && !data) || !data) {
        return <></>;
    }

    const user = data.getExpert;

    return (
        <>
            {isDesktop && <DesktopExpert user={user} offer={offer} />}
            {!isDesktop && <MobileExpert user={user} offer={offer} />}
        </>
    );
}

type ExpertProps = {
    user: ExpertProfile_getExpert | null;
    offer: number;
};

function DesktopExpert({user, offer}: ExpertProps) {
    const {me, loadingMe, refetchMe} = useContext(UserContext);

    const _scrollView = useRef<ScrollView>(null);
    const alert = useAlert();
    const {play, stop, isPlaying} = useAudio();

    // We will use "optimisticIsFavorite" to toggle the favorite icon before the
    // mutation is actually over (ie., when "isSettingFavorite" will be true)
    const [optimisticIsFavorite, setOptimisticIsFavorite] = React.useState(false);
    const [setFavorite, {loading: loadingFav, called}] = useFavoriteMutation();
    const isSettingFavorite = loadingFav || called || loadingMe;

    const profile = user?.profile;
    const userId = user?.id.split(':')[1];
    const expertDetails = user?.expert;

    const displayName = profile?.displayName;
    const pictureName = profile?.pictureName;
    const shortDescription = expertDetails?.shortDescription;
    const longDescription = expertDetails?.longDescription;
    const catchPhrase = expertDetails?.catchPhrase;
    const experience = expertDetails?.experience;
    const callNumber = user?.reviewSummary?.callNumber;
    const messageNumber = user?.reviewSummary?.messageNumber;
    const chatNumber = user?.reviewSummary?.chatNumber;

    let callPriceLabel = ' - ';
    if (user?.callParameters?.price?.label) {
        callPriceLabel = `${user?.callParameters?.price?.label} la minute`;
    }
    let chatPriceLabel = ' - ';
    if (user?.chatParameters?.chatPrice?.label) {
        chatPriceLabel = `${user?.chatParameters?.chatPrice?.label} la minute`;
    }

    const acceptOfferChat = user?.chatParameters?.acceptOffer ?? false;
    const acceptOfferCall = user?.callParameters?.acceptOffer ?? false;

    const smallerMessagePrice = getSmallerMessagePrice(user?.messageParameters);
    let messagePrice = ' - ';
    if (smallerMessagePrice) {
        messagePrice = smallerMessagePrice?.price;
    }

    let positiveReviewRate = ' - ';

    if (user?.reviewSummary && user?.reviewSummary?.callNumber > 0) {
        positiveReviewRate = `${user?.reviewSummary?.positiveReviewRate} %`;
    }

    const favorite = isSettingFavorite ? optimisticIsFavorite : isFavorite(me, userId);

    const specialities = getSpecialities(user);
    const newProfil = isNew(user);

    const grades = filterEmpty(user?.reviewSummaryGrade?.edges).map((e, i) => {
        return {
            id: e?.node?.gradeId ? parseInt(e?.node?.gradeId) : 0,
            percentage: e?.node?.percentage,
            label: e?.node?.grade?.label,
        } as Grade;
    });

    const qualities = user?.reviewSummaryQuality?.edges.map((e, i) => {
        return {
            id: e?.node?.qualityId,
            starNumber: e?.node?.starNumber,
            label: e?.node?.quality?.label,
        } as Quality;
    });

    async function toggleFavorite() {
        if (me?.isExpert) {
            alert({
                title: 'Erreur',
                message: 'Impossible de mettre en favori en tant que spirite.',
            });
            return;
        }
        const dataFavorite = {
            clientId: me?.id,
            expertId: user?.id,
            isFavorite: !favorite,
        };
        setOptimisticIsFavorite(!favorite);
        const result = await setFavorite({variables: dataFavorite});
        refetchMe();
    }

    function onPressReviewLink() {
        _scrollView?.current?.scrollTo(2200);
    }

    async function listen() {
        if (isPlaying) {
            stop();
        } else if (expertDetails?.voicemail != null) {
            play({uri: expertDetails.voicemail});
        }
    }

    const callText = callNumber ?? 0 > 1 ? 'consultations' : 'consultation';
    const chatText = callNumber ?? 0 > 1 ? 'discussions' : 'discussion';
    const messageText = callNumber ?? 0 > 1 ? 'réponses envoyées' : 'réponse envoyée';

    return (
        <MainView ref={_scrollView}>
            <View style={styles.container}>
                <ImageBackground
                    style={styles.skin}
                    imageStyle={styles.skinImage}
                    source={{
                        uri: Settings.getUrlImageSkinType(expertDetails?.skinType, 'large'),
                    }}
                    accessibilityLabel={'Photo de couverture de ' + profile?.displayName}
                >
                    <SimpleText style={styles.name}>{displayName}</SimpleText>
                    <SimpleText style={styles.speciality}>{specialities}</SimpleText>
                    <View style={styles.profil}>
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={pictureName}
                            displayName={displayName}
                        />
                        {newProfil && <SimpleText style={styles.new}>NEW</SimpleText>}
                    </View>
                </ImageBackground>
                <View style={styles.bloc}>
                    <View style={styles.serviceContainer}>
                        <CallButton
                            style={{}}
                            title="J'appelle"
                            icon={<Icons.Phone size={45} color={Colors.secondary} />}
                            titleStyle={styles.titleStyle}
                            containerStyle={styles.containerStyle}
                            user={user}
                            info={true}
                            informations={
                                <View>
                                    <AvailableText
                                        status={expertDetails?.callStatus}
                                        offer={acceptOfferCall ? offer : 0}
                                    />
                                    <Separator style={styles.separator} />
                                    <SimpleText style={styles.number}>
                                        {callNumber} {callText}
                                    </SimpleText>
                                    <SimpleText style={styles.price}>
                                        {callPriceLabel}
                                    </SimpleText>
                                </View>
                            }
                        />
                    </View>
                    <View style={styles.serviceContainer}>
                        <ChatButton
                            style={{}}
                            title={'Je tchatte'}
                            icon={
                                <Icons.Chat
                                    size={45}
                                    color={Colors.secondary}
                                    style={{alignSelf: 'center'}}
                                />
                            }
                            titleStyle={styles.titleStyle}
                            containerStyle={styles.containerStyle}
                            user={user}
                            info={true}
                            informations={
                                <View>
                                    <AvailableText
                                        status={expertDetails?.chatStatus}
                                        offer={acceptOfferChat ? offer : 0}
                                    />
                                    <Separator style={styles.separator} />
                                    <SimpleText style={styles.number}>
                                        {chatNumber} {chatText}
                                    </SimpleText>
                                    <SimpleText style={styles.price}>
                                        {chatPriceLabel}
                                    </SimpleText>
                                </View>
                            }
                        />
                    </View>
                    <View style={styles.serviceContainer}>
                        <MessageButton
                            style={{}}
                            title={'Je pose ma question'}
                            icon={
                                <Icons.Mail
                                    size={45}
                                    color={Colors.secondary}
                                    style={{alignSelf: 'center'}}
                                />
                            }
                            titleStyle={styles.titleStyle}
                            containerStyle={styles.containerStyle}
                            user={user}
                            info={true}
                            informations={
                                <View>
                                    <AvailableText
                                        status={expertDetails?.messageStatus}
                                        offer={0}
                                    />
                                    <Separator style={styles.separator} />
                                    <SimpleText style={styles.number}>
                                        {messageNumber} {messageText}
                                    </SimpleText>
                                    <SimpleText style={styles.price}>
                                        {messagePrice}
                                    </SimpleText>
                                </View>
                            }
                        />
                    </View>
                </View>
                <View style={styles.bloc}>
                    <View style={styles.reviewContainer}>
                        <SimpleText style={styles.title}>Vos avis</SimpleText>
                        {grades && grades.length > 0 && (
                            <>
                                <GradeList grades={grades} />
                                <SimpleText
                                    style={styles.reviewLink}
                                    onPress={onPressReviewLink}
                                >
                                    Plus d'avis ici
                                </SimpleText>
                            </>
                        )}
                        {grades && grades.length == 0 && (
                            <SimpleText style={styles.emptyBlockMessage}>
                                Ce spirite n'a encore aucune évaluation. Soyez le premier à
                                communiquer avec lui et laisser votre avis !
                            </SimpleText>
                        )}
                    </View>
                    <View style={styles.buttonContainer}>
                        <TouchableOpacity onPress={() => toggleFavorite()}>
                            <Icons.Favorite
                                isFavorite={favorite}
                                size={60}
                                color={Colors.primary}
                                style={{alignSelf: 'center'}}
                            />
                            <SimpleText style={styles.favorite}>
                                {favorite ? 'Consultant favoris' : 'Mettre en favoris'}
                            </SimpleText>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.bloc}>
                    <View style={styles.gradeContainer}>
                        <SimpleText style={styles.title}>Qualités reconnues</SimpleText>
                        {qualities && qualities.length > 0 && (
                            <QualityList qualities={qualities} />
                        )}
                        {qualities && qualities.length == 0 && (
                            <SimpleText style={styles.emptyBlockMessage}>
                                Cet spirite n'a encore aucune évaluation. Soyez le premier à
                                communiquer avec lui et laisser votre avis !
                            </SimpleText>
                        )}
                    </View>
                    {shortDescription && shortDescription.length > 0 && (
                        <View style={styles.shortDescriptionContainer}>
                            <SimpleText style={styles.title}>Description courte</SimpleText>
                            <SimpleText>{shortDescription}</SimpleText>
                        </View>
                    )}
                </View>
                {catchPhrase && catchPhrase.length > 0 && (
                    <View style={styles.bloc}>
                        <View style={styles.descriptionContainer}>
                            <SimpleText style={styles.title}>Accroche / Slogan</SimpleText>
                            <SimpleText>{catchPhrase}</SimpleText>
                        </View>
                    </View>
                )}
                {longDescription && longDescription.length > 0 && (
                    <View style={styles.bloc}>
                        <View style={styles.descriptionContainer}>
                            <SimpleText style={styles.title}>Description détaillée</SimpleText>
                            <SimpleText>{longDescription}</SimpleText>
                            {expertDetails?.voicemail != null && (
                                <Button
                                    icon={
                                        isPlaying ? (
                                            <Icons.Stop size={16} style={{paddingRight: 10}} />
                                        ) : (
                                            <Icons.HeadPhone
                                                size={16}
                                                style={{paddingRight: 10}}
                                            />
                                        )
                                    }
                                    title={
                                        isPlaying ? 'Stopper' : 'Ecouter la présentation audio'
                                    }
                                    buttonStyle={{
                                        width: 300,
                                        alignSelf: 'center',
                                        marginTop: 10,
                                    }}
                                    onPress={listen}
                                />
                            )}
                        </View>
                    </View>
                )}
                {experience && experience.length > 0 && (
                    <View style={styles.bloc}>
                        <View style={styles.descriptionContainer}>
                            <SimpleText style={styles.title}>
                                Expériences et qualifications
                            </SimpleText>
                            <SimpleText>{experience}</SimpleText>
                        </View>
                    </View>
                )}
                <View style={styles.bloc}>
                    <View style={styles.descriptionContainer}>
                        <SimpleText style={styles.title}>Evaluations</SimpleText>
                        <Reviews reviews={user?.reviews} expertId={user?.userId} />
                    </View>
                </View>
            </View>
        </MainView>
    );
}

function AvailableText({status, offer}: {status: string | null | undefined; offer: number}) {
    function getStyle() {
        if (status == AVAILABLE) {
            return styles.available;
        }
        if (status == UNAVAILABLE) {
            return styles.unavailable;
        }
        return styles.pause;
    }

    if (status == AVAILABLE && offer > 0) {
        return <SimpleText style={styles.offer}>{offer} min. gratuites</SimpleText>;
    }

    const style = getStyle();
    const text = getText(status);
    return <SimpleText style={style}>{text}</SimpleText>;
}

function MobileExpert({offer, user}: ExpertProps) {
    const {play, stop, isPlaying} = useAudio();

    const profile = user?.profile;
    const expertDetails = user?.expert;

    const displayName = profile?.displayName;
    const pictureName = profile?.pictureName;
    const longDescription = expertDetails?.longDescription;
    const catchPhrase = expertDetails?.catchPhrase;
    const experience = expertDetails?.experience;
    const communicationNumber = user?.reviewSummary?.communicationNumber;

    const acceptOfferChat = user?.chatParameters?.acceptOffer ?? false;
    const acceptOfferCall = user?.callParameters?.acceptOffer ?? false;
    const acceptOffer = acceptOfferChat || acceptOfferCall;

    let positiveReviewRate = ' - ';

    if (user?.reviewSummary && user?.reviewSummary?.callNumber > 0) {
        positiveReviewRate = `${user?.reviewSummary?.positiveReviewRate} %`;
    }

    const price = minPriceText(user);

    let offerText = '';
    if (offer > 0 && acceptOffer) {
        offerText = `${offer} min. gratuites puis ${price}`;
    } else {
        offerText = price;
    }

    const specialities = getSpecialities(user);
    const newProfil = isNew(user);

    async function listen() {
        if (isPlaying) {
            stop();
        } else if (expertDetails?.voicemail != null) {
            play({uri: expertDetails.voicemail});
        }
    }

    return (
        <MainView>
            <MobileTitle title={'Profil du Spirite'} />
            <View style={mobileStyles.container}>
                <View style={mobileStyles.profil}>
                    <ProfilePicture
                        style={mobileStyles.picture}
                        pictureName={pictureName}
                        displayName={displayName}
                    />
                    {newProfil && <SimpleText style={mobileStyles.new}>NEW</SimpleText>}
                </View>
                <SimpleText style={mobileStyles.name}>{displayName}</SimpleText>
                <SimpleText style={mobileStyles.speciality}>{specialities}</SimpleText>
                <MobileExpertButtons user={user} />
                <SimpleText style={mobileStyles.offer}>{offerText}</SimpleText>
                {catchPhrase && catchPhrase.length > 0 && (
                    <View style={mobileStyles.highlight}>
                        <SimpleText style={mobileStyles.catch}>{catchPhrase}</SimpleText>
                    </View>
                )}
                <View style={mobileStyles.stats}>
                    <View style={mobileStyles.communication}>
                        <SimpleText style={mobileStyles.text}>
                            Nombre de consultations
                        </SimpleText>
                        <SimpleText style={mobileStyles.number}>
                            {communicationNumber}
                        </SimpleText>
                    </View>
                    <View style={mobileStyles.review}>
                        <SimpleText style={mobileStyles.text}>Satisfaction client</SimpleText>
                        <SimpleText style={mobileStyles.number}>
                            {positiveReviewRate}
                        </SimpleText>
                    </View>
                </View>
                {expertDetails?.voicemail != null && (
                    <Button
                        icon={
                            isPlaying ? (
                                <Icons.Stop size={16} style={{paddingRight: 10}} />
                            ) : (
                                <Icons.HeadPhone size={16} style={{paddingRight: 10}} />
                            )
                        }
                        title={isPlaying ? 'Stopper' : 'Présentation audio'}
                        buttonStyle={{width: 275, alignSelf: 'center', marginTop: 10}}
                        onPress={listen}
                    />
                )}
                <ReviewsMobile reviews={user?.reviews} expertId={user?.userId} />
                {longDescription && longDescription.length > 0 && (
                    <View style={mobileStyles.bloc}>
                        <SimpleText style={mobileStyles.label}>Description spirite</SimpleText>
                        <SimpleText style={mobileStyles.description}>
                            {longDescription}
                        </SimpleText>
                    </View>
                )}
                {experience && experience.length > 0 && (
                    <View style={mobileStyles.bloc}>
                        <SimpleText style={mobileStyles.label}>Expériences</SimpleText>
                        <SimpleText style={mobileStyles.description}>{experience}</SimpleText>
                    </View>
                )}
            </View>
        </MainView>
    );
}

ExpertScreen.fragments = {
    expertReviews: gql`
        fragment ExpertReviewsFragment on User {
            reviewSummary {
                id
                callNumber
                messageNumber
                chatNumber
                communicationNumber
                positiveReviewRate
            }
            reviewSummaryQuality {
                edges {
                    node {
                        id
                        qualityId
                        starNumber
                        quality {
                            label
                        }
                    }
                }
            }
            reviewSummaryGrade {
                edges {
                    node {
                        id
                        gradeId
                        percentage
                        grade {
                            label
                        }
                    }
                }
            }
        }
    `,
    tags: gql`
        fragment ExpertTagsFragment on User {
            tags {
                edges {
                    node {
                        id
                        label
                        tagTypeId
                    }
                }
            }
        }
    `,
    callConfig: gql`
        fragment ExpertCallConfigFragment on User {
            callParameters {
                id
                acceptOffer
                price {
                    id
                    label
                    priceDecimal
                }
            }
        }
    `,
    messageConfig: gql`
        fragment ExpertMessageConfigFragment on User {
            messageParameters {
                edges {
                    node {
                        messagePrice {
                            id
                            messagePriceId
                            label
                            priceDecimal
                        }
                        type
                        description
                    }
                }
            }
        }
    `,
    chatConfig: gql`
        fragment ExpertChatConfigFragment on User {
            chatParameters {
                acceptOffer
                chatPrice {
                    id
                    label
                    priceDecimal
                }
            }
        }
    `,
    profile: gql`
        fragment ExpertProfileFragment on User {
            identity {
                created
            }
            profile {
                id
                displayName
                pictureName
            }
            expert {
                id
                longDescription
                shortDescription
                experience
                catchPhrase
                callStatus
                messageStatus
                chatStatus
                skinType
                voicemail
                created
            }
        }
    `,
    reviews: gql`
        fragment ReviewsFragment on User {
            reviews {
                edges {
                    node {
                        reviewId
                        createdDate
                        gradeId
                        comment
                        client {
                            id
                            profile {
                                id
                                displayName
                            }
                        }
                        qualities {
                            edges {
                                node {
                                    id
                                    starNumber
                                    quality {
                                        id
                                        label
                                    }
                                }
                            }
                        }
                        grade {
                            id
                            label
                        }
                        call {
                            id
                        }
                        chat {
                            id
                        }
                        message {
                            id
                        }
                    }
                }
            }
        }
    `,
};

const EXPERT_PROFILE_QUERY = gql`
    query GetExpertQuery($url: String!) {
        getExpert(url: $url) {
            id
            userId
            ...ExpertProfileFragment
            ...ExpertReviewsFragment
            ...ExpertTagsFragment
            ...ExpertCallConfigFragment
            ...ExpertMessageConfigFragment
            ...ExpertChatConfigFragment
            ...ReviewsFragment
        }
    }
    ${ExpertScreen.fragments.profile}
    ${ExpertScreen.fragments.expertReviews}
    ${ExpertScreen.fragments.tags}
    ${ExpertScreen.fragments.callConfig}
    ${ExpertScreen.fragments.messageConfig}
    ${ExpertScreen.fragments.chatConfig}
    ${ExpertScreen.fragments.reviews}
`;

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.dark,
        width: 1082,
        alignSelf: 'center',
        borderRadius: 15,
        marginTop: 30,
    },
    skin: {
        alignSelf: 'center',
        width: 1082,
        height: 500,
        marginBottom: 130,
    },
    skinImage: {
        borderRadius: 15,
    },
    profil: {
        width: 300,
        alignSelf: 'center',
    },
    name: {
        marginTop: 80,
        marginBottom: 30,
        fontSize: 52,
        alignSelf: 'center',
        color: 'white',
    },
    speciality: {
        fontSize: 38,
        marginBottom: 80,
        alignSelf: 'center',
        color: 'white',
    },
    profilePicture: {
        borderRadius: 150,
        width: 300,
        height: 300,
        borderColor: 'white',
        borderWidth: 5,
        alignSelf: 'center',
        backgroundColor: Colors.dark,
    },
    bloc: {
        width: 838,
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 40,
    },
    serviceContainer: {
        alignItems: 'center',
    },
    containerStyle: {
        width: 228,
        height: 235,
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderRadius: 10,
        paddingVertical: 20,
        // borderWidth: 2,
        borderColor: Colors.primary,
        shadowColor: Colors.secondary,
        shadowOpacity: 0.16,
        shadowRadius: 10,
        shadowOffset: {
            height: 0,
            width: 0,
        },
    },
    number: {
        color: Colors.secondary,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 10,
    },
    price: {
        fontSize: 14,
        fontStyle: 'italic',
        alignSelf: 'center',
        marginTop: 10,
    },
    available: {
        textAlign: 'center',
        color: Colors.available,
        fontWeight: 'bold',
        alignSelf: 'center',
        padding: 10,
        fontSize: 16,
    },
    unavailable: {
        textAlign: 'center',
        color: Colors.unavailable,
        fontWeight: 'bold',
        alignSelf: 'center',
        padding: 10,
        fontSize: 16,
    },
    pause: {
        textAlign: 'center',
        color: Colors.pause,
        fontWeight: 'bold',
        alignSelf: 'center',
        padding: 10,
        fontSize: 16,
    },
    offer: {
        textAlign: 'center',
        color: Colors.nice,
        fontWeight: 'bold',
        alignSelf: 'center',
        padding: 10,
        fontSize: 16,
    },
    titleStyle: {
        alignSelf: 'center',
        color: 'white',
        backgroundColor: Colors.primary,
        padding: 5,
        paddingHorizontal: 15,
        borderRadius: 7,
        fontSize: 14,
        marginTop: 5,
    },
    buttonStyle: {
        flexDirection: 'row',
        width: 228,
        height: 35,
        justifyContent: 'center',
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
    },
    title: {
        color: Colors.secondary,
        fontSize: 16,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginBottom: 20,
    },
    reviewContainer: {
        borderRadius: 8,
        backgroundColor: 'white',
        padding: 30,
        width: 475,
    },
    buttonContainer: {
        borderWidth: 3,
        borderRadius: 8,
        borderColor: Colors.primary,
        backgroundColor: 'white',
        width: 300,
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    favorite: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.primary,
        backgroundColor: 'white',
        alignSelf: 'center',
        textTransform: 'uppercase',
        marginTop: 30,
    },
    gradeContainer: {
        padding: 30,
        backgroundColor: 'white',
        borderRadius: 8,
        width: 475,
    },
    shortDescriptionContainer: {
        padding: 30,
        backgroundColor: 'white',
        borderRadius: 8,
        width: 300,
    },
    descriptionContainer: {
        flex: 1,
        padding: 30,
        backgroundColor: 'white',
        borderRadius: 8,
    },
    reviewLink: {
        color: Colors.link,
        fontSize: 12,
        marginTop: 10,
        textAlign: 'right',
    },
    emptyBlockMessage: {
        color: 'grey',
        paddingHorizontal: 50,
        paddingVertical: 30,
    },
    new: {
        position: 'absolute',
        top: 30,
        left: 10,
        backgroundColor: Colors.highlight,
        color: 'white',
        paddingVertical: 4,
        paddingHorizontal: 12,
        fontSize: 18,
        borderRadius: 5,
        fontWeight: 'bold',
    },
    separator: {
        backgroundColor: Colors.primary,
        width: 150,
        height: 2,
        alignSelf: 'center',
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        alignSelf: 'center',
        borderRadius: 15,
        marginTop: 30,
        paddingVertical: 20,
        paddingHorizontal: 10,
        marginHorizontal: 10,
    },
    name: {
        marginTop: 20,
        marginBottom: 20,
        fontSize: 34,
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
    },
    speciality: {
        fontSize: 16,
        marginBottom: 10,
        alignSelf: 'center',
        textAlign: 'center',
        color: Colors.secondary,
    },
    center: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    profil: {
        width: 300,
        alignSelf: 'center',
    },
    picture: {
        borderRadius: 15,
        width: 150,
        height: 150,
        alignSelf: 'center',
        textAlign: 'center',
    },
    stats: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        paddingTop: 20,
    },
    communication: {
        borderRadius: 6,
        padding: 10,
        backgroundColor: Colors.highlight,
        width: 125,
    },
    review: {
        borderRadius: 6,
        padding: 10,
        backgroundColor: Colors.connected,
        width: 125,
    },
    text: {
        color: 'white',
        marginTop: 10,
        textAlign: 'center',
    },
    number: {
        color: 'white',
        fontSize: 24,
        marginTop: 10,
        textAlign: 'center',
    },
    available: {
        position: 'absolute',
        left: 90,
        top: -2,
        borderWidth: 2,
        borderColor: 'white',
        borderRadius: 6,
        backgroundColor: 'white',
    },
    bloc: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: Colors.disabled,
        marginVertical: 15,
    },
    label: {
        backgroundColor: Colors.secondary,
        color: 'white',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: 'center',
        padding: 4,
    },
    description: {
        padding: 10,
    },
    highlight: {
        borderTopWidth: 1,
        borderTopColor: Colors.disabled,
        marginTop: 10,
        paddingTop: 20,
    },
    catch: {
        padding: 10,
        textAlign: 'center',
    },
    offer: {
        marginTop: 15,
        marginBottom: 10,
        fontSize: 16,
        color: Colors.nice,
        textAlign: 'center',
    },
    new: {
        position: 'absolute',
        top: 15,
        left: 55,
        backgroundColor: Colors.highlight,
        color: 'white',
        paddingVertical: 2,
        paddingHorizontal: 10,
        fontSize: 12,
        borderRadius: 5,
        fontWeight: 'bold',
    },
});
