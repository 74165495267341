import Constants from 'expo-constants';

import {getAcceptation} from './cookie';

const extra = Constants.manifest?.extra;
const gtmEnabled = extra?.gtmEnabled;

export default function injectWebCss() {
    const style = document.createElement('style');
    style.textContent = `textarea, select, input, button { outline: none!important; }
        div::-webkit-scrollbar { width: 5px; height: 8px; background-color: #ccc; border-radius: 10px;}
        div::-webkit-scrollbar-thumb { background-color: #1c344a; border-radius: 10px; height: 10%}
        div { scrollbar-color: #1c344a #ccc; scrollbar-width: thin;}`;
    document.head.append(style);

    if (gtmEnabled) {
        getAcceptation().then((value: string | null) => {
            if (value == 'true') {
                injectGtm();
            }
        });
    }
}

export function injectGtm() {
    const scriptHead = document.createElement('script');
    scriptHead.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NK9QV4L');`;
    document.head.append(scriptHead);

    const scriptBody = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-NK9QV4L';
    iframe.height = '0';
    iframe.width = '0';
    iframe.setAttribute('styles', 'display:none;visibility:hidden');
    scriptBody.append(iframe);
    document.body.prepend(scriptBody);
}
