import React from 'react';
import {View, StyleSheet} from 'react-native';

import {gql, useQuery} from '@apollo/client';

import {ProfilePicture} from '~/components/common/Images';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as dates from '~/helpers/dates';

type PopupStatisticsProps = {
    visible: boolean;
    onRequestClose: () => void;
    clientId: string | null;
};

const STATISTICS_QUERY = gql`
    query GetStatisticsQuery($clientId: String!) {
        getClientStatistics(clientId: $clientId) {
            firstCommunication
            earningTotal
            callNumberTotal
            callNumberSince30Days
            callLengthTotal
            callLengthAverage
            chatNumberTotal
            chatNumberSince30Days
            chatLengthTotal
            chatLengthAverage
            messageNumberTotal
            messageNumberSince30Days
            lastUpdateDate
            client {
                profile {
                    displayName
                    pictureName
                }
            }
        }
    }
`;

export default function PopupStatistics(props: PopupStatisticsProps) {
    const variables = {clientId: props.clientId};
    const {data, loading, error, refetch} = useQuery(STATISTICS_QUERY, {
        variables,
        skip: props.clientId == null,
        fetchPolicy: 'network-only',
    });
    const message = <SimpleText></SimpleText>;

    const title = 'Statistiques du client';

    if (data?.getClientStatistics) {
        const stats = data.getClientStatistics;

        let lastUpdateText = ' - ';
        if (stats.lastUpdateDate) {
            const lastUpdate = new Date(data?.getClientStatistics.lastUpdateDate);
            const date = dates.dateString(lastUpdate);
            const time = dates.timeString(lastUpdate);
            lastUpdateText = `le ${date} à ${time}`;
        }
        let firstCommunicationText = ' - ';
        if (stats.firstCommunication) {
            const first = new Date(data?.getClientStatistics.firstCommunication);
            firstCommunicationText = dates.dateString(first);
        }

        return (
            <CustomModal
                title={title}
                iconName="chart-line"
                message={message}
                hasCloseIcon={true}
                visible={props.visible}
                onRequestClose={props.onRequestClose}
                width={1000}
            >
                <View style={styles.container}>
                    <View
                        style={[
                            styles.statContainer,
                            {flexDirection: 'row', justifyContent: 'space-evenly'},
                        ]}
                    >
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={stats?.client?.profile?.pictureName}
                            displayName={stats.client?.profile?.displayName}
                        />
                        <SimpleText style={styles.title}>
                            {stats?.client?.profile?.displayName}
                        </SimpleText>
                    </View>
                    <View style={styles.statContainer}>
                        <SimpleText style={styles.title}>
                            Premier échange : {firstCommunicationText}
                        </SimpleText>
                    </View>
                    <View style={styles.statContainer}>
                        <SimpleText style={styles.title}>
                            Revenu total : {stats.earningTotal}€
                        </SimpleText>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.statContainer}>
                        <SimpleText style={styles.label}>
                            {stats.callNumberTotal} appels (dont {stats.callNumberSince30Days}{' '}
                            depuis 30 jours)
                        </SimpleText>
                        <SimpleText style={styles.label}>
                            Soit {dates.getDurationText(stats.callLengthTotal)} d'appels
                            (moyenne {dates.getDurationText(stats.callLengthAverage)})
                        </SimpleText>
                    </View>

                    <View style={styles.statContainer}>
                        <SimpleText style={styles.label}>
                            {stats.chatNumberTotal} tchats (dont {stats.chatNumberSince30Days}{' '}
                            depuis 30 jours)
                        </SimpleText>
                        <SimpleText style={styles.label}>
                            Soit {dates.getDurationText(stats.chatLengthTotal)} de tchats
                            (moyenne {dates.getDurationText(stats.chatLengthAverage)})
                        </SimpleText>
                    </View>

                    <View style={styles.statContainer}>
                        <SimpleText style={styles.label}>
                            {stats.messageNumberTotal} messages (dont{' '}
                            {stats.messageNumberSince30Days} depuis 30 jours)
                        </SimpleText>
                    </View>
                </View>
                <View>
                    <SimpleText style={styles.update}>
                        Dernière mise à jour : {lastUpdateText}
                    </SimpleText>
                </View>
            </CustomModal>
        );
    } else {
        return <></>;
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    statContainer: {
        backgroundColor: 'white',
        padding: 15,
        marginVertical: 10,
        width: 280,
        height: 105,
        justifyContent: 'center',
    },
    label: {
        paddingVertical: 10,
        textAlign: 'left',
    },
    title: {
        paddingVertical: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        alignSelf: 'center',
    },
    update: {
        fontSize: 12,
        textAlign: 'right',
        paddingTop: 15,
    },
    profilePicture: {
        borderRadius: 25,
        width: 50,
        height: 50,
        alignSelf: 'center',
    },
});
