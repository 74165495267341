import * as React from 'react';
import {TouchableOpacity, StyleSheet, View} from 'react-native';

import {
    createBottomTabNavigator,
    BottomTabBar,
    BottomTabBarProps,
} from '@react-navigation/bottom-tabs';
import {HeaderBackButton} from '@react-navigation/elements';
import {createStackNavigator} from '@react-navigation/stack';
import {LinearGradient} from 'expo-linear-gradient';

import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import {ProfileMobile} from '~/components/user/LoginStatus';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import ExpertScreen from '~/screens/ExpertScreen';
import HomeScreen from '~/screens/HomeScreen';
import SearchScreen from '~/screens/SearchScreen';
import ApplyScreen from '~/screens/information/ApplyScreen';
import CguScreen from '~/screens/information/CguScreen';
import CgvScreen from '~/screens/information/CgvScreen';
import CommitmentScreen from '~/screens/information/CommitmentScreen';
import ContactScreen from '~/screens/information/ContactScreen';
import EthicScreen from '~/screens/information/EthicScreen';
import FaqScreen from '~/screens/information/FaqScreen';
import JobScreen from '~/screens/information/JobScreen';
import LegalNoticeScreen from '~/screens/information/LegalNoticeScreen';
import PriceScreen from '~/screens/information/PriceScreen';
import BasketScreen from '~/screens/pack/BasketScreen';
import CancelScreen from '~/screens/pack/CancelScreen';
import PackScreen from '~/screens/pack/PackScreen';
import PaymentScreen from '~/screens/pack/PaymentScreen';
import SuccessScreen from '~/screens/pack/SuccessScreen';
import HistoricScreen from '~/screens/profile/HistoricScreen';
import MessageScreen from '~/screens/profile/MessageScreen';
import ProfileScreen from '~/screens/profile/ProfileScreen';
import ForgottenPasswordScreen from '~/screens/user/ForgottenPasswordScreen';
import LoginScreen from '~/screens/user/LoginScreen';
import RegisterScreen from '~/screens/user/RegisterScreen';
import {
    MobileBottomTabParamList,
    SearchParamList,
    HomeParamList,
    ProfileParamList,
    PackParamList,
} from '~/types';

import {DrawerBackButton} from './MobileDrawerNavigator';

const Tab = createBottomTabNavigator<MobileBottomTabParamList>();

export default function MobileBottomTabNavigator() {
    return (
        <Tab.Navigator
            initialRouteName="Home"
            screenOptions={{
                tabBarActiveTintColor: 'black',
                tabBarInactiveTintColor: 'white',
                tabBarLabelStyle: {
                    fontWeight: 'bold',
                },
                tabBarStyle: [
                    {
                        display: 'flex',
                    },
                    null,
                ],
                headerShown: false,
                tabBarBackground: () => (
                    <LinearGradient
                        start={{x: 0, y: 0}}
                        end={{x: 1, y: 0}}
                        colors={Colors.rainbow}
                        style={StyleSheet.absoluteFill}
                    />
                ),
            }}
        >
            <Tab.Screen
                name="Home"
                component={HomeNavigator}
                options={{
                    tabBarLabel: 'Accueil',
                    title: 'Kyvoitou.fr | Accueil',
                    tabBarIcon: ({color, size}) => <Icons.Home size={size} color={color} />,
                }}
            />
            {/* <Tab.Screen
                name="Blog"
                component={Fake}
                options={{
                    tabBarLabel: 'Blog',
                    title: 'Kyvoitou.fr | Blog',
                    tabBarButton: (props) => (
                        <TouchableOpacity {...props} onPress={openBlog} />
                    ),
                    tabBarIcon: ({color, size}) => <Icons.Blog size={size} color={color} />,
                }}
            /> */}
            <Tab.Screen
                name="PriceScreen"
                component={PriceScreen}
                options={{
                    tabBarLabel: 'Nos tarifs',
                    title: 'Kyvoitou.fr | Nos tarifs',
                    tabBarIcon: ({color, size}) => <Icons.Price size={size} color={color} />,
                }}
            />
            <Tab.Screen
                name="Search"
                component={TabSearchNavigator}
                options={{
                    tabBarLabel: 'Nos experts',
                    title: 'Kyvoitou.fr | Recherche',
                    tabBarIcon: ({color, size}) => <Icons.Search size={size} color={color} />,
                }}
            />
            <Tab.Screen
                name="MessageScreen"
                component={MessageScreen}
                options={{
                    tabBarLabel: 'Messagerie',
                    title: 'Kyvoitou.fr | Mes messages',
                    tabBarIcon: ({color, size}) => <Icons.Mail size={size} color={color} />,
                }}
            />
            <Tab.Screen
                name="Profile"
                component={TabProfileNavigator}
                options={{
                    tabBarLabel: 'Profil',
                    title: 'Kyvoitou.fr | Profil',
                    tabBarIcon: ({color, size}) => <ProfileMobile color={color} size={size} />,
                }}
            />
        </Tab.Navigator>
    );
}

function openBlog() {
    window.open(Settings.url_blog);
}

function Fake() {
    return <></>;
}

const TabSearchStack = createStackNavigator<SearchParamList>();

export function TabSearchNavigator() {
    return (
        <TabSearchStack.Navigator>
            <TabSearchStack.Screen
                name="SearchScreen"
                component={SearchScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Recherche',
                }}
            />
            <TabSearchStack.Screen
                name="ExpertScreen"
                component={ExpertScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                if (navigation.canGoBack()) {
                                    navigation.goBack();
                                } else {
                                    navigation.navigate('SearchScreen');
                                }
                            }}
                        />
                    ),
                    title: 'Recherche',
                })}
            />
        </TabSearchStack.Navigator>
    );
}

const TabProfileStack = createStackNavigator<ProfileParamList>();

export function TabProfileNavigator() {
    return (
        <TabProfileStack.Navigator>
            <TabProfileStack.Screen
                name="ProfileScreen"
                component={ProfileScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Profil',
                }}
            />
            <TabProfileStack.Screen
                name="HistoricScreen"
                component={HistoricScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                if (navigation.canGoBack()) {
                                    navigation.goBack();
                                } else {
                                    navigation.navigate('ProfileScreen');
                                }
                            }}
                        />
                    ),
                    title: 'Kyvoitou.fr | Historique',
                })}
            />
        </TabProfileStack.Navigator>
    );
}

const HomeStack = createStackNavigator<HomeParamList>();

function HomeNavigator() {
    return (
        <HomeStack.Navigator>
            <HomeStack.Screen
                name="HomeScreen"
                component={HomeScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Accueil',
                    title: 'Kyvoitou.fr | Accueil',
                }}
            />
            <HomeStack.Screen
                name="PackScreen"
                component={PackNavigator}
                options={{
                    title: 'Kyvoitou.fr | Nos packs',
                    headerShown: false,
                }}
            />
            <HomeStack.Screen
                name="LoginScreen"
                component={LoginScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Identification',
                    title: 'Kyvoitou.fr | Identification',
                }}
            />
            <HomeStack.Screen
                name="RegisterScreen"
                component={RegisterScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Enregistrement',
                    title: 'Kyvoitou.fr | Enregistrement',
                }}
            />
            <HomeStack.Screen
                name="ForgottenPasswordScreen"
                component={ForgottenPasswordScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Oubli de mot de passe',
                    title: 'Kyvoitou.fr | Oubli de mot de passe',
                }}
            />
            <HomeStack.Screen
                name="HomeExpertScreen"
                component={ExpertScreen}
                options={{
                    headerShown: true,
                    headerTitle: 'Accueil',
                    title: 'Kyvoitou.fr | Spirite du moment',
                }}
            />
            <HomeStack.Screen
                name="CgvScreen"
                component={CgvScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: 'Kyvoitou.fr | CGV',
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="CommitmentScreen"
                component={CommitmentScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: 'Kyvoitou.fr | Nos engagements',
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="FaqScreen"
                component={FaqScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: 'Kyvoitou.fr | Aide : Foire aux questions',
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="ContactScreen"
                component={ContactScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: 'Kyvoitou.fr | Contact & SAV',
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="EthicScreen"
                component={EthicScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: 'Kyvoitou.fr | Notre charte éthique',
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="CguScreen"
                component={CguScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: "Kyvoitou.fr | Conditions générales d'utilisation",
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="LegalNoticeScreen"
                component={LegalNoticeScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: 'Kyvoitou.fr | Mentions légales',
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="ApplyScreen"
                component={ApplyScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: 'Kyvoitou.fr | Devenir spirite',
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
            <HomeStack.Screen
                name="JobScreen"
                component={JobScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerTitle: '',
                    title: "Kyvoitou.fr | Offre d'emploi",
                    headerLeft: (props) => (
                        <DrawerBackButton {...props} navigation={navigation} />
                    ),
                })}
            />
        </HomeStack.Navigator>
    );
}
const PackStack = createStackNavigator<PackParamList>();

function PackNavigator() {
    return (
        <PackStack.Navigator>
            <PackStack.Screen
                name="PackScreen"
                component={PackScreen}
                options={({navigation}) => ({
                    title: 'Kyvoitou.fr | Nos packs',
                    headerTitle: "Retour à l'accueil",
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('HomeScreen');
                            }}
                        />
                    ),
                })}
            />
            <PackStack.Screen
                name="BasketScreen"
                component={BasketScreen}
                options={({navigation}) => ({
                    title: 'Kyvoitou.fr | Votre panier',
                    headerTitle: 'Changer de pack',
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('PackScreen');
                            }}
                        />
                    ),
                })}
            />
            <PackStack.Screen
                name="PaymentScreen"
                component={PaymentScreen}
                options={({route, navigation}) => ({
                    title: 'Kyvoitou.fr | Paiement',
                    headerTitle: 'Mon panier',
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('BasketScreen', route.params);
                            }}
                        />
                    ),
                })}
            />
            <PackStack.Screen
                name="SuccessScreen"
                component={SuccessScreen}
                options={{
                    title: 'Kyvoitou.fr | Récapitulatif',
                    headerShown: false,
                }}
            />
            <PackStack.Screen
                name="CancelScreen"
                component={CancelScreen}
                options={{
                    title: 'Kyvoitou.fr | Annulation',
                    headerShown: false,
                }}
            />
        </PackStack.Navigator>
    );
}

const styles = StyleSheet.create({
    bar: {
        backgroundColor: 'transparent',
        borderTopWidth: 0,
    },
});
