import {Platform} from 'react-native';

import {endpoints} from '~/constants/Settings';

import {axios} from './network';

// Must absolutely be changed at once with server version.py file
const VERSION = '1.21';
const FIRST_CHECK_TIMEOUT = 60 * 1000;
const VERSION_INTERVAL = 3600 * 1000;
const HALFDAY_INTERVAL = 12 * 3600 * 1000;

export default function ensureVersion() {
    if (Platform.OS == 'web') {
        setTimeout(checkVersion, FIRST_CHECK_TIMEOUT);
        setInterval(checkVersion, VERSION_INTERVAL);
        setTimeout(halfDayRefresh, HALFDAY_INTERVAL);
    }
}

async function checkVersion() {
    const response = await axios.get(endpoints.version);
    if (response.data != VERSION) {
        console.log('OLD VERSION DETECTED : RELOADING');
        location.reload();
    }
}

async function halfDayRefresh() {
    console.log('12H without refresh : RELOADING');
    location.reload();
}
