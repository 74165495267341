import {View} from 'react-native';

import {LinearGradient} from 'expo-linear-gradient';

import * as Colors from '~/constants/Colors';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {InnerLogo} from '../navigation/Logo';
import {styles} from './styles';

export default function Header() {
    const {isDesktop} = useDeviceQuery();
    const desktopStyle = isDesktop ? {height: 120} : {};
    return (
        <LinearGradient
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}
            colors={Colors.rainbow}
            style={[styles.header, desktopStyle]}
        >
            <View style={styles.headerContent}>
                <InnerLogo
                    color={'white'}
                    titleFontSize={35}
                    subtitleFontSize={12}
                    separatorWidth={'20%'}
                />
            </View>
        </LinearGradient>
    );
}
