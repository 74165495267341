// https://stripe.com/docs/payments/save-and-reuse
// https://medium.com/codingtown/react-native-payment-using-stripe-part-1-c0c005eeda90
import * as React from 'react';

import {loadStripe} from '@stripe/stripe-js';
import Constants from 'expo-constants';
import type {ButtonProps} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import {endpoints} from '~/constants/Settings';
import {axios} from '~/helpers/network';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Constants.expoConfig?.extra?.stripeApiKey);
export type StripeCheckoutOptions = {checkoutPath?: string; checkoutQueryParams?: Object};

export function AddPaymentButton({
    checkoutPath,
    checkoutQueryParams,
    ...props
}: ButtonProps & StripeCheckoutOptions) {
    return (
        <Button
            {...props}
            iconName="credit-card"
            onPress={() => addPaymentMethod({checkoutPath, checkoutQueryParams})}
        />
    );
}

export async function addPaymentMethod(options: StripeCheckoutOptions) {
    const {sessionId} = await fetchCheckoutSession(options);
    const stripe = await stripePromise;
    if (stripe) {
        const {error} = await stripe.redirectToCheckout({sessionId});
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        console.log(error);
    }
}

export async function checkPaymentMethod(sessionId: string) {
    const data = {
        session_id: sessionId,
    };

    const body = JSON.stringify(data);

    const response = await axios.post(endpoints.getPaymentMethod, body);
    console.log('response.data', response.data);
    return response.data;
}

async function fetchCheckoutSession(options: StripeCheckoutOptions) {
    const response = await axios.get(endpoints.createStripeCustomer, {
        params: {
            checkoutPath: options.checkoutPath,
            ...options.checkoutQueryParams,
        },
    });
    return response.data;
}

export async function isPaymentIntentSuccess(clientSecret: string) {
    const stripe = await stripePromise;
    const result = await stripe?.retrievePaymentIntent(clientSecret);
    return result?.paymentIntent?.status == 'succeeded';
}

export async function isPaymentIntentReadyToCapture(clientSecret: string) {
    const stripe = await stripePromise;
    const result = await stripe?.retrievePaymentIntent(clientSecret);
    return result?.paymentIntent?.status == 'requires_capture';
}
