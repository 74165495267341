import React, {useState} from 'react';
import {View, StyleSheet, FlatList, ScrollView} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/native';

import {Bloc} from '~/components/common/Containers';
import {GradeColorLabel} from '~/components/common/GradeItem';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {BlocContentLoader} from '~/components/navigation/Loader';
import PopupReadReview from '~/components/user/client//review/PopupReadReview';
import type {ReviewData} from '~/components/user/client/review/types/calls';
import {CommunicationType} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import * as dates from '~/helpers/dates';
import {sorted} from '~/helpers/list';
import {filterEmpty} from '~/helpers/list';

import {GetLastReviewsQuery_getLastReviews_edges_node as Review} from './types/GetLastReviewsQuery';
import {GetLastReviewsQuery_getLastReviews_edges as Reviews} from './types/GetLastReviewsQuery';

const GET_LAST_REVIEWS_QUERY = gql`
    query GetLastReviewsQuery {
        getLastReviews {
            edges {
                node {
                    id
                    reviewId
                    expertId
                    call {
                        id
                    }
                    message {
                        id
                    }
                    chat {
                        id
                    }
                    client {
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    createdDate
                    gradeId
                    grade {
                        gradeId
                        label
                    }
                }
            }
        }
    }
`;

export default function BlocReview() {
    return (
        <Bloc title="Mes évaluations" iconName="medal">
            <BlocReviewContent />
        </Bloc>
    );
}

function BlocReviewContent() {
    const [load, {called, loading, data, refetch}] = useLazyQuery(GET_LAST_REVIEWS_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    const [modalReadReviewVisible, setModalReadReviewVisible] = useState(false);
    const [reviewData, setReviewData] = useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: true,
    });

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    function onPressReviewLink(review: Review) {
        let communicationId = '';
        let communicationType = CommunicationType.CALL;
        if (review.call) {
            communicationId = review.call.id;
            communicationType = CommunicationType.CALL;
        } else if (review.message) {
            communicationId = review.message.id;
            communicationType = CommunicationType.MESSAGE;
        } else if (review.chat) {
            communicationId = review.chat.id;
            communicationType = CommunicationType.CHAT;
        }
        const reviewData = {
            communicationId: communicationId,
            communicationType: communicationType,
            date: review?.createdDate,
            expertId: review.expertId,
            clientMode: true,
        } as ReviewData;

        setReviewData(reviewData);
        setModalReadReviewVisible(true);
    }

    function onRequestCloseReadReview() {
        setModalReadReviewVisible(false);
    }

    function renderRow({item}: {item: Review}) {
        const review = item;
        const date = new Date(review.createdDate);
        const grade = review?.grade?.gradeId ? parseInt(review?.grade?.gradeId) : 0;

        return (
            <View style={styles.reviewContainer}>
                <View style={styles.headerContainer}>
                    <View style={styles.profileNameContainer}>
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={review?.client?.profile?.pictureName}
                            displayName={review?.client?.profile?.displayName}
                        />
                        <View style={styles.nameContainer}>
                            <SimpleText style={styles.client}>
                                {review?.client?.profile?.displayName}
                            </SimpleText>
                            <SimpleText style={styles.date}>
                                le {dates.dateString(date)} à {dates.timeString(date)}
                            </SimpleText>
                        </View>
                    </View>
                </View>
                <View style={styles.buttonContainer}>
                    <View style={styles.iconGradeContainer}>
                        {review.chat && (
                            <Icons.Chat
                                size={24}
                                color={Colors.secondary}
                                style={{margin: 7}}
                            />
                        )}
                        {review.call && (
                            <Icons.Phone
                                size={24}
                                color={Colors.secondary}
                                style={{margin: 7}}
                            />
                        )}
                        {review.message && (
                            <Icons.Mail
                                size={24}
                                color={Colors.secondary}
                                style={{margin: 7}}
                            />
                        )}
                        <GradeColorLabel
                            style={styles.grade}
                            text={review?.grade?.label ?? ''}
                            grade={grade}
                        />
                    </View>
                    <SimpleText
                        style={styles.reviewLink}
                        onPress={() => onPressReviewLink(review)}
                    >
                        Voir le commentaire
                    </SimpleText>
                </View>
            </View>
        );
    }

    function getCreatedDate(item: Reviews) {
        return Date.parse(item?.node?.createdDate);
    }

    if (data) {
        const filteredData = data.getLastReviews.edges;
        const sortedData = sorted(filteredData, getCreatedDate, true);
        const list = filterEmpty(
            sortedData.map((e) => {
                return e.node;
            })
        );

        const noReview = list.length == 0;
        const listReview = list.length > 0;

        return (
            <>
                {noReview && (
                    <SimpleText style={styles.noReview}>
                        Vous n'avez aucune évaluation réçente.
                    </SimpleText>
                )}
                {listReview && (
                    <>
                        <ScrollView style={styles.reviews}>
                            <FlatList
                                data={list}
                                renderItem={renderRow}
                                keyExtractor={(item) => item.reviewId}
                                style={styles.listContainer}
                            />
                        </ScrollView>
                        <PopupReadReview
                            visible={modalReadReviewVisible}
                            onRequestClose={onRequestCloseReadReview}
                            reviewData={reviewData}
                            animationType={'none'}
                        />
                    </>
                )}
            </>
        );
    }

    return <BlocContentLoader />;
}

const styles = StyleSheet.create({
    reviews: {
        flex: 1,
        flexDirection: 'column',
        maxHeight: 390,
    },
    noReview: {
        alignSelf: 'center',
        padding: 17,
        flex: 1,
    },
    reviewContainer: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 10,
        marginTop: 15,
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    profileNameContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    nameContainer: {},
    listContainer: {},
    profilePicture: {
        width: 37,
        height: 37,
        borderRadius: 40,
        alignSelf: 'center',
        marginRight: 8,
    },
    messageHeaderContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    client: {
        fontSize: 14,
    },
    date: {
        fontSize: 12,
        fontStyle: 'italic',
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    iconGradeContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    reviewLink: {
        color: Colors.link,
        fontSize: 12,
        alignSelf: 'center',
        paddingTop: 3,
    },
    grade: {
        fontSize: 14,
        margin: 8,
    },
});
