import * as React from 'react';
import {StyleSheet, ImageBackground, View, ViewProps, Pressable} from 'react-native';

import {LinearGradient} from 'expo-linear-gradient';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText, Underlined} from '~/components/common/Texts';
import {
    CallButton,
    MessageButton,
    ChatButton,
} from '~/components/expert/ExpertButtonsLandingPageVersion';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {useConfirm} from '~/contexts/ConfirmContext';
import {
    getSpecialities,
    getSmallerMessagePrice,
    ExpertWithLastReview,
} from '~/helpers/experts';

const MAX_REVIEW_LENGTH = 70;
const DESCRIPTION_MAX_NB_LINES = 3;

export default function ExpertCard(props: ExpertCardProps) {
    return (
        <View style={[styles.expertContainer, props.style]}>
            <CardHeader user={props.user} />
            <CardBody
                user={props.user}
                medium={props.medium}
                onAuthRequired={props.onAuthRequired}
                onPaymentMethodRequired={props.onPaymentMethodRequired}
                onCallStarted={props.onCallStarted}
                onCommunicationPressed={props.onCommunicationPressed}
                queryParams={props.queryParams}
                onCancelPayment={props.onCancelPayment}
                paymentIntent={props.paymentIntent}
                paymentIntentSecret={props.paymentIntentSecret}
                freeCall={props.freeCall}
                canUseACoupon={props.canUseACoupon}
            />
        </View>
    );
}

function CardHeader({user}: ExpertCardHeaderProps) {
    const reviewSummary = user?.reviewSummary;
    let positiveReviewRate = '100%';
    if (reviewSummary && reviewSummary?.communicationNumber > 0) {
        positiveReviewRate = `${reviewSummary?.positiveReviewRate} %`;
    }

    const expertInfo = user?.expert;

    return (
        <View>
            <ImageBackground
                style={styles.skin}
                imageStyle={styles.skinImage}
                source={{
                    uri: Settings.getUrlImageSkinType(expertInfo?.skinType, 'small'),
                }}
                accessibilityLabel={'Photo de couverture de ' + user?.profile?.displayName}
            >
                <LinearGradient
                    colors={[Colors.opacity, 'transparent']}
                    style={styles.skinGradient}
                />
                <View style={styles.skinOverGradientView}>
                    <View style={styles.bottomSkinView}>
                        <View style={styles.reviewNumberContainer}>
                            <SimpleText style={styles.reviewNumber}>
                                {positiveReviewRate}
                            </SimpleText>
                            <SimpleText style={styles.reviewNumberTitle}>
                                Avis positifs
                            </SimpleText>
                        </View>
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={user?.profile?.pictureName}
                            displayName={user?.profile?.displayName}
                        />
                        <View style={styles.communicationContainer}>
                            <SimpleText style={styles.communications}>
                                {reviewSummary?.communicationNumber}
                            </SimpleText>
                            <SimpleText style={styles.communicationsTitle}>
                                Consultations
                            </SimpleText>
                        </View>
                        {/* {newProfil && <SimpleText style={styles.new}>NEW</SimpleText>} */}
                    </View>
                </View>
            </ImageBackground>
        </View>
    );
}

function CardBody(props: ExpertCardBodyProps) {
    const confirm = useConfirm();
    const profile = props.user?.profile;
    const specialities = getSpecialities(props.user);
    const specialitySize = {fontSize: specialities.length > 35 ? 13 : 16};
    const voiceMailUri = props.user?.expert?.voicemail;
    const alert = useAlert();

    const {play, stop, isPlaying, currentUri} = useAudio();
    const isPlayingCurrentExpert = isPlaying && currentUri == voiceMailUri;

    const togglePlay = React.useCallback(() => {
        async function pop() {
            if (isPlayingCurrentExpert) {
                await stop();
            }
            if (voiceMailUri != null && !isPlayingCurrentExpert) {
                await play({uri: voiceMailUri});
            }
        }
        pop();
    }, [voiceMailUri, isPlayingCurrentExpert]);

    const callParameters = props.user?.callParameters;
    let callPrice = ' - ';
    if (callParameters?.price?.label) {
        callPrice = `${callParameters.price.label} / min`;
    }

    const smallerMessagePrice = getSmallerMessagePrice(props.user?.messageParameters);
    let messagePrice = ' - ';
    if (smallerMessagePrice) {
        messagePrice = smallerMessagePrice?.price;
    }

    const chatParameters = props.user?.chatParameters;
    let chatPrice = ' - ';
    if (chatParameters?.chatPrice?.label) {
        chatPrice = `${chatParameters.chatPrice.label} / min`;
    }

    const buttonTitle = `Je choisis ${profile?.displayName}`;
    const lastReviewCompleteText = props.user?.reviews?.edges?.[0]?.node?.comment;
    let lastReviewText = lastReviewCompleteText;
    if (lastReviewText != null && lastReviewText.length > MAX_REVIEW_LENGTH) {
        lastReviewText = lastReviewText.slice(0, MAX_REVIEW_LENGTH) + '~...';
    }

    let isReviewTruncated = lastReviewText != lastReviewCompleteText;

    function showFullComment() {
        if (lastReviewCompleteText) {
            alert({
                title: 'Commentaire complet',
                message: lastReviewCompleteText,
            });
        }
    }

    const onCommunicationPressed = React.useCallback(() => {
        if (props.canUseACoupon) {
            props.onCommunicationPressed?.();
        } else {
            confirm({
                title: 'Communication payante',
                message: `Vous n'avez plus de coupon de réduction pour ${profile?.displayName}. Cette communication sera facturée dès la première minute. Vous pouvez choisir un autre voyant pour profiter de vos réductions ou continuer avec ${profile?.displayName}.`,
                onYes: () => props.onCommunicationPressed?.(),
                yesText: `Choisir ${profile?.displayName}`,
                noText: 'Annuler',
            });
        }
    }, []);

    return (
        <View style={styles.cardBody}>
            <View>
                <SimpleText style={styles.pseudo}>{profile?.displayName}</SimpleText>
                <SimpleText style={[styles.specialty, specialitySize]} numberOfLines={1}>
                    {specialities}
                </SimpleText>
            </View>
            <View style={{marginVertical: 20, flex: 1, justifyContent: 'flex-start'}}>
                {lastReviewText != null && (
                    <Pressable
                        style={{flexDirection: 'row', flexWrap: 'wrap'}}
                        onPress={showFullComment}
                    >
                        <SimpleText
                            numberOfLines={DESCRIPTION_MAX_NB_LINES}
                            ellipsizeMode="tail"
                        >
                            <SimpleText
                                style={{
                                    color: Colors.primary,
                                    fontWeight: 'bold',
                                    textDecorationLine: 'underline',
                                }}
                            >
                                Avis client
                            </SimpleText>{' '}
                            {`« ${lastReviewText} »`}
                        </SimpleText>
                        {isReviewTruncated && (
                            <Underlined style={{textAlign: 'right', marginTop: 10, flex: 1}}>
                                Voir plus
                            </Underlined>
                        )}
                    </Pressable>
                )}
                {lastReviewText == null && (
                    <>
                        <SimpleText
                            numberOfLines={DESCRIPTION_MAX_NB_LINES}
                            ellipsizeMode="tail"
                        >
                            <SimpleText style={{fontWeight: 'bold', textAlign: 'center'}}>
                                Bio.{' '}
                            </SimpleText>
                            {props.user?.expert?.shortDescription}
                        </SimpleText>
                    </>
                )}
            </View>
            {voiceMailUri != null && (
                <Button
                    icon={
                        isPlayingCurrentExpert ? (
                            <Icons.Stop
                                size={16}
                                style={{paddingRight: 10}}
                                color={Colors.primary}
                            />
                        ) : (
                            <Icons.HeadPhone
                                size={16}
                                style={{paddingRight: 10}}
                                color={Colors.primary}
                            />
                        )
                    }
                    title={
                        isPlayingCurrentExpert ? 'Stopper' : 'Ecouter la présentation audio'
                    }
                    buttonStyle={{
                        width: 250,
                        alignSelf: 'center',
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: Colors.primary,
                    }}
                    titleStyle={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: Colors.primary,
                    }}
                    onPress={togglePlay}
                />
            )}
            <View style={styles.serviceContainer}>
                {props.medium == 'no-action' && (
                    <View style={styles.communicationColumn}>
                        <Button
                            style={styles.communicationButton}
                            title={buttonTitle}
                            titleStyle={{
                                fontSize: 16,
                                paddingHorizontal: 8,
                            }}
                            buttonStyle={{backgroundColor: Colors.cta}}
                            onPress={onCommunicationPressed}
                        />
                    </View>
                )}
                {props.medium == 'call' && (
                    <View style={styles.communicationColumn}>
                        {!props.freeCall && (
                            <SimpleText style={styles.communicationPrice}>
                                5 minutes gratuites puis {callPrice}
                            </SimpleText>
                        )}
                        {props.freeCall && (
                            <SimpleText style={styles.communicationPrice}>
                                5 minutes gratuites
                            </SimpleText>
                        )}
                        <CallButton
                            style={styles.communicationButton}
                            title={buttonTitle}
                            titleStyle={{
                                fontSize: 16,
                                paddingHorizontal: 8,
                            }}
                            buttonStyle={{backgroundColor: Colors.cta}}
                            user={props.user}
                            onAuthRequired={props.onAuthRequired}
                            onPaymentMethodRequired={props.onPaymentMethodRequired}
                            onCallStarted={props.onCallStarted}
                            onPress={props.onCommunicationPressed}
                            paymentIntent={props.paymentIntent}
                            paymentIntentSecret={props.paymentIntentSecret}
                            onCancelPayment={props.onCancelPayment}
                            freeCall={props.freeCall}
                        />
                    </View>
                )}
                {props.medium == 'chat' && (
                    <View style={styles.communicationColumn}>
                        <SimpleText style={styles.communicationPrice}>{chatPrice}</SimpleText>
                        <ChatButton
                            style={styles.communicationButton}
                            title={buttonTitle}
                            titleStyle={{
                                fontSize: 16,
                                fontWeight: '600',
                                paddingHorizontal: 14,
                            }}
                            containerStyle={{padding: 2}}
                            buttonStyle={{margin: 0}}
                            user={props.user}
                        />
                    </View>
                )}
                {props.medium == 'message' && (
                    <View style={styles.communicationColumn}>
                        <SimpleText style={styles.communicationPrice}>
                            {messagePrice}
                        </SimpleText>
                        <MessageButton
                            style={styles.communicationButton}
                            title={buttonTitle}
                            titleStyle={{
                                fontSize: 16,
                                fontWeight: '600',
                                paddingHorizontal: 14,
                            }}
                            containerStyle={{padding: 2}}
                            user={props.user}
                        />
                    </View>
                )}
            </View>
        </View>
    );
}

export type CommunicationMedium = 'call' | 'chat' | 'message' | 'no-action';

type ExpertCardHeaderProps = {
    user?: ExpertWithLastReview;
    onPressProfile?: () => void;
};

type ExpertCardBodyProps = {
    user?: ExpertWithLastReview;
    canUseACoupon: boolean;
    medium: CommunicationMedium;
    onAuthRequired?: () => void;
    onPaymentMethodRequired?: () => void;
    onCallStarted?: () => void;
    onCommunicationPressed?: () => void;
    onCancelPayment?: () => void;
    queryParams: Object;
    paymentIntent: string | undefined | null;
    paymentIntentSecret: string | undefined | null;
    freeCall?: boolean;
};

export type ExpertCardProps = ViewProps & ExpertCardHeaderProps & ExpertCardBodyProps;

const CARD_BORDER_RADIUS = 12;
export const CARD_WIDTH = 320;
export const CARD_HEIGHT = 460;
const DESKTOP_PICTURE_SIZE = 100;

const styles = StyleSheet.create({
    bottomSkinView: {
        width: CARD_WIDTH,
        flexDirection: 'row',
        alignItems: 'flex-end',
        position: 'absolute',
        justifyContent: 'space-between',
        top: 60,
        paddingHorizontal: 10,
    },
    expertContainer: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        borderRadius: CARD_BORDER_RADIUS,
        shadowColor: Colors.secondary,
        shadowOpacity: 0.16,
        shadowRadius: 10,
        shadowOffset: {
            height: 0,
            width: 0,
        },
        backgroundColor: 'white',
        marginTop: 40,
    },
    profilePicture: {
        borderRadius: 60,
        width: DESKTOP_PICTURE_SIZE,
        height: DESKTOP_PICTURE_SIZE,
        marginBottom: 20,
        borderColor: 'white',
        borderWidth: 4,
        backgroundColor: Colors.dark,
    },
    cardBody: {
        flex: 1,
        paddingHorizontal: 17,
        justifyContent: 'space-between',
    },
    pseudo: {
        alignSelf: 'center',
        fontSize: 16,
        lineHeight: 19,
        fontWeight: 'bold',
    },
    specialty: {
        minHeight: 20,
        alignSelf: 'center',
    },
    communicationButton: {
        alignSelf: 'center',
        maxWidth: CARD_WIDTH * 0.8,
        marginHorizontal: 0,
        marginVertical: 7,
        padding: 0,
    },
    pause: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
        color: Colors.pause,
    },
    communicationPrice: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: 10,
    },
    skin: {
        alignSelf: 'center',
        width: CARD_WIDTH,
        height: 180,
        overflow: 'hidden',
    },
    skinImage: {
        borderRadius: CARD_BORDER_RADIUS,
        height: 100,
    },
    skinGradient: {
        zIndex: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        borderTopLeftRadius: CARD_BORDER_RADIUS,
        borderTopRightRadius: CARD_BORDER_RADIUS,
        height: 90,
    },
    skinOverGradientView: {
        zIndex: 2,
    },
    serviceContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingHorizontal: 0,
    },
    communicationColumn: {
        justifyContent: 'space-between',
    },
    communicationContainer: {
        alignSelf: 'center',
        marginTop: 35,
        flex: 1,
    },
    reviewNumberContainer: {
        alignSelf: 'center',
        marginTop: 35,
        flex: 1,
    },
    communicationsTitle: {
        alignSelf: 'center',
        fontSize: 14,
        color: Colors.fade,
    },
    communications: {
        alignSelf: 'center',
        fontSize: 18,
    },
    reviewNumberTitle: {
        alignSelf: 'center',
        fontSize: 14,
        color: Colors.fade,
    },
    reviewNumber: {
        alignSelf: 'center',
        fontSize: 18,
    },
    new: {
        position: 'absolute',
        top: 15,
        left: -8,
        backgroundColor: Colors.highlight,
        color: 'white',
        paddingVertical: 2,
        paddingHorizontal: 6,
        fontSize: 10,
        borderRadius: 5,
        fontWeight: 'bold',
    },
});
