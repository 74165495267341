import {useEffect} from 'react';

import {gql, useQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import {isExpired, setExpiration} from '~/helpers/storage';

import type {PriceQuery} from './types/PriceQuery';

const PRICE_QUERY = gql`
    query PriceQuery {
        getCallPrices {
            edges {
                node {
                    priceId
                    label
                    priceDecimal
                    isVisible
                }
            }
        }
        getChatPrices {
            edges {
                node {
                    chatPriceId
                    label
                    priceDecimal
                    isVisible
                }
            }
        }
        getMessagePrices {
            edges {
                node {
                    messagePriceId
                    type
                    label
                    priceDecimal
                    isVisible
                }
            }
        }
    }
`;

const PRICE_EXPIRATION = 60;
const PRICE_KEY = 'PRICE_EXPIRE';
let REFETCHING = false;

export default function usePrices() {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only',
    };
    const query = useQuery<PriceQuery>(PRICE_QUERY, options);

    async function refetchIfCacheExpire() {
        const expired = await isExpired(PRICE_KEY);
        if (expired && !REFETCHING) {
            REFETCHING = true;
            await query.refetch();
            setExpiration(PRICE_KEY, PRICE_EXPIRATION);
            REFETCHING = false;
        }
    }

    useEffect(() => {
        refetchIfCacheExpire();
    });

    return query;
}
