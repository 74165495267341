import {StyleSheet} from 'react-native';

import * as Colors from '~/constants/Colors';
import {MOBILE_MAX_WIDTH} from '~/constants/Layout';

export const styles = StyleSheet.create({
    title: {
        fontSize: 40,
        textAlign: 'center',
        paddingTop: 40,
        paddingBottom: 20,
        paddingHorizontal: 20,
    },
    subtitle: {
        fontSize: 25,
        paddingTop: 15,
        paddingBottom: 10,
        marginLeft: 30,
    },
    selectedSubtitle: {
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        alignSelf: 'center',
        maxWidth: 1366,
        width: '100%',
        flexGrow: 1,
    },
    header: {
        flexDirection: 'row',
        paddingHorizontal: '6%',
        width: '100%',
        justifyContent: 'center',
        height: 75,
    },
    headerContent: {
        paddingVertical: 20,
        alignSelf: 'center',
    },
    promoParagraph: {
        textAlign: 'center',
        fontSize: 16,
        padding: 20,
        maxWidth: 630,
        alignSelf: 'center',
    },
    leftParagraph: {
        textAlign: 'left',
        fontSize: 16,
        padding: 20,
        maxWidth: 940,
        alignSelf: 'flex-start',
    },
    callView: {
        alignSelf: 'center',
        backgroundColor: Colors.primary,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20,
        margin: 20,
        borderRadius: 5,
        maxWidth: 400,
    },
    expertBadge: {
        alignSelf: 'center',
        backgroundColor: Colors.primary,
        margin: 10,
        borderRadius: 5,
        maxWidth: 300,
    },
    expertBadgeInfo: {
        alignItems: 'center',
        padding: 10,
        flexDirection: 'row',
    },
    expertBadgeLink: {
        borderTopWidth: 1,
        borderTopColor: Colors.primaryDarker,
    },
    expertBadgeLinkText: {
        color: 'white',
        textDecorationLine: 'underline',
        fontWeight: '600',
        textAlign: 'center',
        padding: 10,
    },
    profilePicture: {
        borderRadius: 60,
        width: 90,
        height: 90,
        borderColor: 'white',
        borderWidth: 4,
        backgroundColor: Colors.dark,
        alignItems: 'center',
        justifyContent: 'center',
    },
    smallProfilePicture: {
        borderRadius: 60,
        width: 60,
        height: 60,
        borderColor: 'white',
        borderWidth: 4,
        backgroundColor: Colors.dark,
        alignItems: 'center',
        justifyContent: 'center',
    },
    callMessage: {color: 'white', fontWeight: '600', fontSize: 16, marginLeft: 20},
    expertBadgeMessage: {color: 'white', fontSize: 14, marginLeft: 15},
    price: {
        fontSize: 28,
        fontWeight: 'bold',
        backgroundImage: '-webkit-linear-gradient(left, #4bc0c8, #c779d0, #feac5e)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        alignSelf: 'center',
        textAlign: 'center',
    },
    economy: {
        fontSize: 10,
        fontStyle: 'italic',
        alignSelf: 'center',
        textAlign: 'center',
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: 'center',
        maxWidth: MOBILE_MAX_WIDTH,
        width: '100%',
    },
});
