import {NavigationProp, RouteProp, useNavigation, useRoute} from '@react-navigation/native';

import {RootStackParamList} from '~/types';

export type CallLandingPageRouteProps = RouteProp<RootStackParamList, 'LandingPageCallScreen'>;
export type CallLandingPageNavigationProps = NavigationProp<
    RootStackParamList,
    'LandingPageCallScreen'
>;
export const useCallLandingPageRoute = () => useRoute<CallLandingPageRouteProps>();
export const useCallLandingPageNavigation = () =>
    useNavigation<CallLandingPageNavigationProps>();

export type CallPackLandingPageRouteProps = RouteProp<
    RootStackParamList,
    'LandingPageCallPackScreen'
>;
export type CallPackLandingPageNavigationProps = NavigationProp<
    RootStackParamList,
    'LandingPageCallPackScreen'
>;
export const useCallPackLandingPageRoute = () => useRoute<CallPackLandingPageRouteProps>();
export const useCallPackLandingPageNavigation = () =>
    useNavigation<CallPackLandingPageNavigationProps>();

export type CallFreeLandingPageRouteProps = RouteProp<
    RootStackParamList,
    'LandingPageCallFreeScreen'
>;
export type CallFreeLandingPageNavigationProps = NavigationProp<
    RootStackParamList,
    'LandingPageCallFreeScreen'
>;
export const useCallFreeLandingPageRoute = () => useRoute<CallFreeLandingPageRouteProps>();
export const useCallFreeLandingPageNavigation = () =>
    useNavigation<CallFreeLandingPageNavigationProps>();

export type CallFreeNoCbLandingPageRouteProps = RouteProp<
    RootStackParamList,
    'LandingPageCallFreeNoCbScreen'
>;
export type CallFreeNoCbLandingPageNavigationProps = NavigationProp<
    RootStackParamList,
    'LandingPageCallFreeNoCbScreen'
>;
export const useCallFreeNoCbLandingPageRoute = () =>
    useRoute<CallFreeNoCbLandingPageRouteProps>();
export const useCallFreeNoCbLandingPageNavigation = () =>
    useNavigation<CallFreeNoCbLandingPageNavigationProps>();

export type LandingPageRouteProps = CallLandingPageRouteProps | CallPackLandingPageRouteProps;
export type LandingPageNavigationProps =
    | CallLandingPageNavigationProps
    | CallPackLandingPageNavigationProps;
export const useLandingPageRoute = () => useRoute<CallPackLandingPageRouteProps>();
export const useLandingPageNavigation = () => useNavigation<LandingPageNavigationProps>();
