import React from 'react';
import {View, StyleSheet} from 'react-native';

import {SimpleText} from '../common/Texts';

export function ScreenLoader() {
    return (
        <View style={styles.container}>
            <SimpleText>Chargement en cours</SimpleText>
        </View>
    );
}

export function BlocContentLoader() {
    return (
        <View style={styles.container}>
            <SimpleText>Chargement en cours</SimpleText>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        margin: 25,
        textAlign: 'center',
        alignSelf: 'center',
    },
});
