import * as React from 'react';
import {StyleSheet, Image, ImageBackground, View, TouchableOpacity} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import {MainView} from '~/components/common/Containers';
import {MobileTitle, SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function CommitmentScreen() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();

    const style = isDesktop ? styles.center : mobileStyles.center;

    const textsOne = [
        'Avant d’être admis sur le site, nos spirites sont tous sélectionnés et testés avec soin et minutie. Nous préférons vous proposer un petit nombre de médiums mais vous offrir ce qui se fait de meilleur !',
        'Vous l’aurez compris, l’important à nos yeux est que vous viviez la meilleure et la plus sérieuse des expériences !',
    ] as string[];

    const textsTwo = [
        'Toutes vos informations sont strictement confidentielles, seul votre pseudo apparaît auprès de nos médiums.',
        'Les consultations sont, par conséquent, totalement anonymes et la protection de vos données est notre priorité.',
        'Vos moyens de paiement sont également sécurisés grâce à Stripe, qui est accrédité PCI DSS.',
    ] as string[];

    const textsThree = [
        `Chez Kyvoitou.fr, vous l’avez compris, nous souhaitons protéger chacun de nos clients. Aussi, une provision de ${Settings.provision} est nécessaire pour pouvoir consulter, cela limite les cas d’addiction à la consultation.`,
        'De même, c’est VOUS qui choisissez quand commence et se termine une consultation, vous pouvez ainsi maitriser votre budget grâce au paiement à la minute !',
    ] as string[];

    const textsFour = [
        `Si vous avez la moindre question, le moindre problème, vous pouvez nous contacter au ${Settings.support_telephone} ou par email à ${Settings.support_mail}`,
        'Nous sommes là pour vous guider dans votre inscription mais également pour vous aider dans toutes les démarches nécessaires !',
    ] as string[];

    return (
        <MainView>
            <CommitmentTitle />
            <View>
                <SimpleText style={style}>
                    Chez Kyvoitou.fr, nous avons à cœur d’offrir à nos clients des
                    consultations de qualité en toute confidentialité.
                </SimpleText>
                <SimpleText style={style}>
                    Voici les points les plus importants pour nous et sur lesquels nous nous
                    engageons :
                </SimpleText>
            </View>
            <Bloc
                title={'La qualité avant la quantité !'}
                image={Settings.getUrlImageCommitmentEvaluation()}
                texts={textsOne}
                type={BlocType.IMAGE_THEN_TEXT}
            />
            <Bloc
                title={'La sécurité : notre maître mot !'}
                image={Settings.getUrlImageCommitmentSecure()}
                texts={textsTwo}
                type={BlocType.TEXT_THEN_IMAGE}
            />
            <View>
                <SimpleText style={styles.more}>
                    Pour en savoir plus :{' '}
                    <TouchableOpacity onPress={() => navigation.navigate('CgvScreen')}>
                        <SimpleText>https://www.kyvoitou.fr/cgv</SimpleText>
                    </TouchableOpacity>
                </SimpleText>
            </View>
            <Bloc
                title={'Un budget maîtrisé et une lutte contre l’addiction !'}
                image={Settings.getUrlImageCommitmentFlow()}
                texts={textsThree}
                type={BlocType.IMAGE_THEN_TEXT}
            />
            <Bloc
                title={'Nous sommes à votre écoute !'}
                image={Settings.getUrlImageCommitmentSupport()}
                texts={textsFour}
                type={BlocType.TEXT_THEN_IMAGE}
            />
            <View>
                <SimpleText style={styles.end}>
                    Vous trouverez également la réponse à de nombreuses questions en consultant
                    notre FAQ :{' '}
                    <TouchableOpacity onPress={() => navigation.navigate('FaqScreen')}>
                        <SimpleText>https://www.kyvoitou.fr/faq</SimpleText>
                    </TouchableOpacity>
                </SimpleText>
            </View>
        </MainView>
    );
}

export enum BlocType {
    IMAGE_THEN_TEXT,
    TEXT_THEN_IMAGE,
}

type BlocProps = {
    title: string;
    texts: string[];
    image: string;
    type: BlocType;
    addRegisterToFirstPhrase?: boolean;
};

export function Bloc(props: BlocProps) {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();
    const deviceStyles = isDesktop ? styles : mobileStyles;

    function Title() {
        return <SimpleText style={deviceStyles.section_title}>{props.title}</SimpleText>;
    }

    function Texts() {
        return (
            <View style={deviceStyles.text_container}>
                {isDesktop && <Title />}
                {props.texts.map((t, i) => renderText(t, i))}
            </View>
        );
    }

    function renderText(text: string, i: number) {
        return (
            <SimpleText style={styles.text} key={i}>
                {text}
                <>
                    {i == 0 && props.addRegisterToFirstPhrase && (
                        <TouchableOpacity
                            onPress={() => navigation.navigate('RegisterScreen')}
                        >
                            <SimpleText>https://www.kyvoitou.fr/creer-mon-compte</SimpleText>
                        </TouchableOpacity>
                    )}
                </>
            </SimpleText>
        );
    }

    function Picto() {
        return (
            <View style={styles.image_container}>
                <Image
                    style={styles.image}
                    source={{
                        uri: props.image,
                    }}
                    accessibilityLabel={props.title}
                />
            </View>
        );
    }

    return (
        <View style={deviceStyles.section_container}>
            {!isDesktop && <Title />}
            {isDesktop && (
                <>
                    {props.type == BlocType.IMAGE_THEN_TEXT && <Picto />}
                    <Texts />
                    {props.type == BlocType.TEXT_THEN_IMAGE && <Picto />}
                </>
            )}
            {!isDesktop && (
                <ImageBackground
                    source={{uri: props.image}}
                    imageStyle={{resizeMode: 'contain', opacity: 0.15}}
                >
                    <Texts />
                </ImageBackground>
            )}
        </View>
    );
}

function CommitmentTitle() {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return <PageTitle>Nos engagements !</PageTitle>;
    } else {
        return <MobileTitle title={'Nos engagements'} />;
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    section_container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 45,
        backgroundColor: Colors.dark,
        marginTop: 30,
    },
    section_title: {
        color: Colors.highlight,
        fontSize: 18,
        marginBottom: 40,
    },
    text_container: {
        maxWidth: 650,
    },
    text: {
        marginTop: 15,
        fontSize: 14,
    },
    center: {
        marginTop: 15,
        fontSize: 20,
        alignSelf: 'center',
        paddingHorizontal: 10,
    },
    image_container: {},
    image: {
        height: 330,
        width: 320,
    },
    more: {
        fontSize: 18,
        textAlign: 'center',
        marginTop: 40,
    },
    end: {
        fontSize: 18,
        textAlign: 'center',
        marginTop: 40,
        marginBottom: 25,
    },
});

const mobileStyles = StyleSheet.create({
    section_container: {
        alignSelf: 'center',
        maxWidth: 400,
        textAlign: 'center',
        padding: 15,
        backgroundColor: 'white',
        borderRadius: 8,
        margin: 15,
    },
    section_title: {
        color: Colors.highlight,
        fontSize: 18,
        marginBottom: 20,
    },
    center: {
        marginTop: 15,
        fontSize: 16,
        alignSelf: 'center',
        paddingHorizontal: 10,
        textAlign: 'center',
    },
    text_container: {},
});
