import React from 'react';
import {Image, Linking, Pressable, StyleSheet} from 'react-native';

const AUDIO_TEL_NUMBER = '0892390707';
const AUDIO_TEL_IMAGE_URI =
    'https://blog.kyvoitou.fr/wp-content/uploads/2023/09/audiotel-kyvoitou-300x36.jpg';

export default function AudiotelImageLink() {
    return (
        <Pressable onPress={() => Linking.openURL(`tel:${AUDIO_TEL_NUMBER}`)}>
            <Image
                style={styles.audiotelImage}
                source={{
                    uri: AUDIO_TEL_IMAGE_URI,
                }}
            />
        </Pressable>
    );
}

const styles = StyleSheet.create({
    audiotelImage: {width: 300, height: 36, alignSelf: 'center'},
});
