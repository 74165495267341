import React, {createContext, useRef} from 'react';

import ExpertCallStatus from '~/components/expert/ExpertCallStatus';
import CallStatus from '~/components/user/CallStatus';
import type {CallStatusHandles} from '~/components/user/CallStatus';
import {UserContext} from '~/contexts/UserContext';

export const CallStatusContext = createContext<React.RefObject<CallStatusHandles> | null>(
    null
);

export function CallStatusProvider(props: {children: React.ReactNode}) {
    const call = useRef<CallStatusHandles>(null);
    const {me} = React.useContext(UserContext);
    return (
        <CallStatusContext.Provider value={call} {...props}>
            {props.children}
            {me && !me?.isExpert && <CallStatus clientId={me.id} ref={call} />}
            {me?.isExpert && <ExpertCallStatus ref={call} />}
        </CallStatusContext.Provider>
    );
}
