export const UNKNOWN_DATE = 'date inconnue';
export const UNKNOWN_TIME = 'heure inconnue';

export function dateString(date: Date | null) {
    if (!date) return UNKNOWN_DATE;
    return date?.toLocaleDateString();
}

export function timeString(date: Date | null) {
    if (!date) {
        return UNKNOWN_TIME;
    }
    return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
}

export const SQL = {
    dateString: function (sqlDatetime: string | null) {
        if (!sqlDatetime) return UNKNOWN_DATE;
        return new Date(sqlDatetime).toLocaleDateString('fr-fr', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'utc',
        });
    },
    timeString: function (sqlDatetime: string | null) {
        // const [hours, minutes, seconds] = sqlDatetime?.split(':');
        // if (parseInt(hours) > 0) {
        //     return `${hours}h${minutes}`;
        // }
        // const numMinutes = parseInt(minutes);
        // return `${numMinutes} minutes`;
        return sqlDatetime;
    },
};

export function getDurationInSeconds(duration: string | null | undefined) {
    if (!duration) {
        return 0;
    }
    const durations = duration.split(':');
    const hours = parseInt(durations[0]);
    const minutes = parseInt(durations[1]);
    const seconds = parseInt(durations[2]);
    const result = hours * 3600 + minutes * 60 + seconds;
    return result;
}

export function getDurationText(duration: number) {
    var sec_num = duration;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
}

export function getDurationInMinutes(duration: string) {
    const durations = duration.split(':');
    const hours = parseInt(durations[0]);
    const minutes = parseInt(durations[1]);
    const seconds = parseInt(durations[2]);
    const result = hours * 60 + minutes + (seconds > 0 ? 1 : 0);
    return result;
}

export function getWarningDuration(
    warning: number,
    price: number | null | undefined
): number | null {
    if (warning > 0 && price) {
        return Math.floor((warning / price) * 60 - 60);
    } else {
        return null;
    }
}

export function dayDiff(d1: Date, d2: Date) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return Math.floor((t2 - t1) / (24 * 3600 * 1000));
}
