import React from 'react';
import {Image, StyleSheet, View} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';

import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import Button from '../common/Buttons';

export default function Note() {
    const navigation = useNavigation();
    const {isDesktop} = useDeviceQuery();

    function signIn() {
        if (isDesktop) {
            navigation.navigate('LoginScreen');
        } else {
            navigation.navigate('Main', {screen: 'Home', params: {screen: 'LoginScreen'}});
        }
    }
    const deviceStyle = isDesktop ? styles : mobileStyles;
    const url = Settings.getUrlSignature();

    return (
        <View style={deviceStyle.container}>
            <NoteTitle />
            <SimpleText style={deviceStyle.highlight}>
                Kyvoitou.fr favorise le développement personnel pour vous permettre de devenir
                la meilleure version éveillée de vous même !
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                En ces temps difficiles et plus généralement dans notre vie, nous traversons
                tous des épreuves qui nous empêchent de prendre des décisions éclairées...
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                Dans ces situations, nous n'avons pas le recul nécessaire car nous sommes
                aveuglés par nos états d'âmes et nos émotions.
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                Comment les gérer ? Quelle est la meilleure chose à faire pour moi ? La
                situation me convient elle ? Quels sont mes objectifs professionnels ?
                Est-ce-que cette relation amoureuse est la bonne ?
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                Alerte sur le fait que le monde de la voyance actuel est en plein essor et
                rempli de personnes qui se prétendent être médiums ou thérapeutes et dans
                lesquels vous placez votre confiance, nous avons cherché pour vous des pépites
                de gentillesse et de compétences avérées pour que vous puissiez être serein
                dans l'exercice de la consultation.{' '}
                <SimpleText style={deviceStyle.bold}>Kyvoitou.fr</SimpleText> est né de la
                prise de conscience que la médiumnité doit être crédibilisée avec des personnes
                reconnues et des qualités humaines hors pair afin de vous aider à prendre les
                meilleures décisions possibles.
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                Nos spirites sont testés, approuvés et donc choisis pour vous aider à
                appréhender au mieux les futurs évènements de votre vie.
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                Rappelez-vous, vous avez votre libre arbitre ! Vous êtes créateur de votre vie
                même si certains aspects de celle ci sont scellés. Lors d'une consultation nous
                rentrons dans une analyse médiumnique qui vous permet d'avoir tous les outils
                pour apprendre de vos vies passées, comprendre votre présent afin d'améliorer
                votre futur.
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                Chez <SimpleText style={deviceStyle.bold}>Kyvoitou.fr</SimpleText>, nous avons
                à coeur que vous ne soyez pas dépendant de la voyance mais a contrario de vous
                rendre autonome sur les différentes possibilités qui s'offrent à vous. La
                voyance, la numérologie et l'astrologie sont autant de clefs de compréhension
                que nous mettons à disposition pour vous ainsi que le blog, une source
                intarissable d'actualité sur l'ésotérisme, le développement personnel, la
                philosophie, et autres thérapies qui continueront à vous faire avancer dans
                votre cheminement.
            </SimpleText>
            <SimpleText style={deviceStyle.text}>
                Venez tenter l'aventure avec des médiums et thérapeutes authentiques, qualifiés
                et reconnus.
            </SimpleText>
            <View style={{paddingTop: 20}}>
                <Image
                    source={{uri: url}}
                    style={deviceStyle.image}
                    accessibilityLabel={'La Team Kyvoitou.fr'}
                />
            </View>
            {/* <LinearGradient
                start={{x: 0, y: 0}}
                end={{x: 0.5000000000000001, y: 0.8660254037844386}}
                colors={Colors.rainbow}
                style={deviceStyle.bloc}
            >
                <SimpleText style={deviceStyle.line}>
                    Envie de participer à l'aventure Kyvoitou.fr ?
                </SimpleText>
                <SimpleText style={deviceStyle.description}>
                    Nos spirites vous accompagnent au quotidien sur tous les sujets qui
                    comptent pour vous
                </SimpleText>
                <Button
                    title="Je crée mon compte sur Kyvoitou.fr"
                    buttonStyle={{
                        alignSelf: 'center',
                        backgroundColor: 'white',
                        marginTop: 15,
                    }}
                    titleStyle={deviceStyle.button}
                    onPress={signIn}
                />
            </LinearGradient> */}
        </View>
    );
}

function NoteTitle() {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <>
            <SimpleText style={deviceStyles.title}>Notre philosophie</SimpleText>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 30,
        alignSelf: 'center',
    },
    text: {
        fontSize: 16,
        textAlign: 'center',
        alignSelf: 'center',
        paddingTop: 10,
    },
    title: {
        paddingTop: 30,
        fontSize: 38,
        alignSelf: 'center',
        fontWeight: 'bold',
        color: Colors.secondary,
    },
    highlight: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        alignSelf: 'center',
        paddingTop: 25,
    },
    bold: {
        fontWeight: 'bold',
    },
    image: {
        width: 320,
        height: 40,
        alignSelf: 'center',
    },
    bloc: {
        backgroundColor: Colors.secondary,
        paddingVertical: 25,
        paddingHorizontal: 40,
        marginTop: 30,
        borderRadius: 8,
    },
    line: {
        color: 'white',
        fontSize: 32,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    description: {
        color: 'white',
        fontSize: 16,
        marginTop: 15,
        textAlign: 'center',
    },
    button: {
        color: Colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
        paddingHorizontal: 30,
        paddingVertical: 10,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        paddingTop: 10,
        paddingHorizontal: 15,
        alignItems: 'center',
    },
    text: {
        fontSize: 16,
        textAlign: 'center',
        alignSelf: 'center',
        paddingTop: 10,
    },
    title: {
        paddingTop: 25,
        fontSize: 28,
        fontWeight: 'bold',
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    highlight: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        alignSelf: 'center',
        paddingTop: 20,
    },
    bold: {
        fontWeight: 'bold',
    },
    image: {
        width: 320,
        height: 40,
        alignSelf: 'center',
    },
    bloc: {
        backgroundColor: Colors.secondary,
        paddingVertical: 25,
        paddingHorizontal: 20,
        marginVertical: 30,
        borderRadius: 8,
        marginHorizontal: 15,
    },
    line: {
        color: 'white',
        fontSize: 20,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    description: {
        color: 'white',
        fontSize: 14,
        marginTop: 15,
        textAlign: 'center',
    },
    button: {
        color: Colors.secondary,
        fontWeight: 'bold',
        fontSize: 14,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
});
