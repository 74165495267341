import React from 'react';

import {useApolloClient} from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
    userAuthToken,
    onAuthStateChanged,
    onIdTokenChanged,
    TOKEN_CACHE_KEY,
    tokenExpired,
} from '~/helpers/login/auth';
import type {User} from '~/helpers/login/auth';
import {GetClientOffers} from '~/queries/types/GetClientOffers';
import {GetMinuteBalance} from '~/queries/types/GetMinuteBalance';
import useMinuteBalance from '~/queries/useMinuteBalance';
import useOffers from '~/queries/useOffers';
import useQueryMe, {Me} from '~/queries/useQueryMe';

export function UserContextProvider(props: {children: React.ReactNode}) {
    const [loadingUser, setLoadingUser] = React.useState<boolean>(
        DEFAULT_CONTEXT_VALUE.loadingUser
    );
    const [isAuthenticated, setIsAuthenticated] = React.useState(
        DEFAULT_CONTEXT_VALUE.isAuthenticated
    );
    const [user, setUser] = React.useState<User>(DEFAULT_CONTEXT_VALUE.user);

    const skip = !isAuthenticated;
    const client = useApolloClient();
    const fetchPolicy = 'network-only';
    const {data, loading, refetch, error} = useQueryMe({
        skip,
        client,
        fetchPolicy,
    });
    const me = data?.me;
    const {data: offers, refetch: refetchOffers, loading: loadingOffers} = useOffers(
        me == null
    );
    const {data: minutes, refetch: refetchMinutes} = useMinuteBalance(me == null);

    React.useEffect(() => {
        if (me) {
            refetchOffers();
        }
    }, [me]);

    React.useEffect(() => {
        async function useStoredToken() {
            const storedToken = await AsyncStorage.getItem(TOKEN_CACHE_KEY);
            if (storedToken && !tokenExpired(storedToken)) {
                await onUserAuthenticated(storedToken);
            }
        }
        useStoredToken();
        onIdTokenChanged(onNewUserStatus);
    }, []);

    async function onNewUserStatus(user: User) {
        setLoadingUser(true);
        let newToken;
        if (user) {
            newToken = await userAuthToken(user);
        }
        await onUserAuthenticated(newToken);
        setUser(user);
    }

    async function onUserAuthenticated(newToken?: string | null) {
        if (newToken) {
            const oldToken = await AsyncStorage.getItem(TOKEN_CACHE_KEY);
            const isNewAuthentication = oldToken === null;
            await AsyncStorage.setItem(TOKEN_CACHE_KEY, newToken);
            setIsAuthenticated(true);
            if (isNewAuthentication) {
                await client.cache.reset();
            }
            if (!me) {
                await refetch();
            }
        } else {
            setIsAuthenticated(false);
            await AsyncStorage.removeItem(TOKEN_CACHE_KEY);
            await client.cache.reset();
        }
        setLoadingUser(false);
    }

    const loadingMe = loadingUser && loading && me == null;
    const value = {
        user,
        isAuthenticated,
        loadingUser,
        me,
        loadingMe,
        refetchMe: refetch,
        loadingOffers,
        offers,
        refetchOffers,
        minutes,
        refetchMinutes,
    };

    return <UserContext.Provider value={value} {...props} />;
}

const DEFAULT_CONTEXT_VALUE: UserContextInfo = {
    user: null,
    isAuthenticated: false,
    loadingUser: true,
    me: null,
    loadingMe: true,
    refetchMe: () => {},
    loadingOffers: true,
    offers: null,
    refetchOffers: () => {},
    minutes: null,
    refetchMinutes: () => {},
};

export type UserContextInfo = {
    user: User | null;
    isAuthenticated: boolean;
    loadingUser: boolean;
    me?: Me | null;
    loadingMe: boolean;
    refetchMe: Function;
    loadingOffers: boolean;
    offers?: GetClientOffers | null;
    refetchOffers: Function;
    minutes?: GetMinuteBalance | null;
    refetchMinutes: Function;
};

export const UserContext = React.createContext(DEFAULT_CONTEXT_VALUE);
