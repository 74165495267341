import * as React from 'react';

import {gql, useMutation} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/core';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';

import {MustBeConnected} from '~/components/navigation/Link';
import {ScreenLoader} from '~/components/navigation/Loader';
import ClientAccount from '~/components/user/client/account/ClientAccount';
import PopupWriteReview from '~/components/user/client/review/PopupWriteReview';
import {getCommunicationType, ReviewData} from '~/components/user/client/review/types/calls';
import ExpertAccount from '~/components/user/expert/account/ExpertAccount';
import {
    addPaymentMethod,
    isPaymentIntentSuccess,
} from '~/components/user/stripe/AddPaymentMethod';
import PopupWaitValidation from '~/components/user/stripe/PopupWaitValidation';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import {ProfileParamList} from '~/types';

import {confirmPaymentCapture} from './types/confirmPaymentCapture';

const CONFIRM_PAYMENT_CAPTURE = gql`
    mutation confirmPaymentCapture(
        $service: String!
        $paymentIntentId: String!
        $success: Boolean!
    ) {
        confirmPaymentCapture(
            input: {service: $service, paymentIntentId: $paymentIntentId, success: $success}
        ) {
            ok
            communicationId
            communicationType
            date
            expertId
        }
    }
`;

export default function AccountScreen() {
    const navigation = useNavigation();
    const route = useRoute<RouteProp<ProfileParamList, 'AccountScreen'>>();
    const {loadingUser, user, loadingMe, me} = React.useContext(UserContext);
    const [confirmPaymentCapture] = useMutation<confirmPaymentCapture>(
        CONFIRM_PAYMENT_CAPTURE
    );
    const alert = useAlert();
    const [isReviewVisible, setIsReviewVisible] = React.useState(false);
    const [waitForValidation, setWaitForValidation] = React.useState(false);
    const [sessionId, setSessionId] = React.useState('');
    const [reviewData, setReviewData] = React.useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: true,
    });

    useFocusEffect(
        React.useCallback(() => {
            if (route.params) {
                !loadingMe && route.params.event && dispatch();
            }
        }, [loadingMe, route.params])
    );

    const EVENTS = new Map([
        ['stripe-success', onCheckoutSuccess],
        ['stripe-cancel', onCheckoutFail],
        ['add-payment-method', onAddPaymentMethod],
        ['stripe-3ds-capture', on3dsCapture],
    ]);

    async function dispatch() {
        if (route.params.event) {
            const result = await EVENTS.get(route.params.event)?.();
            navigation.setParams({
                event: undefined,
                sessionId: undefined,
                payment_intent: undefined,
                payment_intent_client_secret: undefined,
                service: undefined,
                source_redirect_slug: undefined,
                source_type: undefined,
            });
            return result;
        }
    }

    async function on3dsCapture() {
        const service = route.params.service;
        const paymentIntent = route.params.payment_intent;
        const paymentIntentClientSecret = route.params.payment_intent_client_secret;

        if (service && paymentIntent && paymentIntentClientSecret) {
            const success = await isPaymentIntentSuccess(paymentIntentClientSecret);

            const result = await confirmCapture(service, paymentIntent, success);

            let reviewData: ReviewData = {
                communicationId: result.data?.confirmPaymentCapture?.communicationId,
                communicationType: getCommunicationType(
                    result.data?.confirmPaymentCapture?.communicationType
                ),
                date: new Date(result.data?.confirmPaymentCapture?.date ?? Date.now()),
                expertId: result.data?.confirmPaymentCapture?.expertId,
                clientMode: true,
            };

            setReviewData(reviewData);
            if (success) {
                alert({
                    title: 'Merci',
                    message:
                        "Votre paiement a bien été enregistré. Vous pouvez retrouver votre consultation dans l'historique",
                    onClose: () => setIsReviewVisible(true),
                });
            } else {
                alert({
                    title: 'Avertissement',
                    message:
                        "Une erreur est survenue pendant votre paiement. Veuillez contactez l'équipe support.",
                    onClose: () => setIsReviewVisible(true),
                });
            }
        }
    }

    async function confirmCapture(service: string, paymentIntent: string, success: boolean) {
        const mutationData = {
            service: service,
            paymentIntentId: paymentIntent,
            success: success,
        };

        return await confirmPaymentCapture({variables: mutationData});
    }

    async function onCheckoutSuccess() {
        setSessionId(route.params.sessionId ?? '');
        setWaitForValidation(true);
    }

    async function onCheckoutFail() {
        setTimeout(() => {
            alert({
                title: 'Erreur',
                message:
                    "Votre nouveau moyen de paiement n'a pas pû être ajouté à votre compte.",
            });
        }, 1000);
    }

    async function onAddPaymentMethod() {
        addPaymentMethod();
    }

    async function closeWaitForValidation() {
        setWaitForValidation(false);
        setSessionId('');
    }

    if (!loadingUser && !loadingMe && !user && !me) {
        return <MustBeConnected />;
    }

    if (me) {
        if (me.isExpert) {
            return <ExpertAccount />;
        } else {
            return (
                <>
                    <PopupWaitValidation
                        sessionId={sessionId}
                        visible={waitForValidation}
                        onRequestClose={closeWaitForValidation}
                    />
                    <PopupWriteReview
                        visible={isReviewVisible}
                        onRequestClose={() => setIsReviewVisible(false)}
                        reviewData={reviewData}
                        onSuccess={() => setIsReviewVisible(false)}
                    />
                    <ClientAccount />
                </>
            );
        }
    }

    return <ScreenLoader />;
}

type StripeParams = {
    event: string;
    sessionId?: string | null | undefined;
};
