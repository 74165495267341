import {Pressable} from 'react-native';

import {useLinkTo} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';

import * as Colors from '~/constants/Colors';

import {SimpleText} from '../common/Texts';

export function KyvoitouHomeButton() {
    const linkTo = useLinkTo();
    return (
        <Pressable
            onPress={() => linkTo('/home')}
            style={{
                alignItems: 'center',
                alignSelf: 'center',
            }}
        >
            <LinearGradient
                start={{x: 0, y: 0}}
                end={{x: 1, y: 0}}
                colors={Colors.rainbow}
                style={{
                    paddingHorizontal: 30,
                    paddingVertical: 10,
                    marginVertical: 20,
                    borderRadius: 5,
                }}
            >
                <SimpleText style={{color: 'white', fontSize: 18}}>
                    Se rendre sur{' '}
                    <SimpleText style={{fontWeight: 'bold', textDecorationLine: 'underline'}}>
                        Kyvoitou.fr
                    </SimpleText>
                </SimpleText>
            </LinearGradient>
        </Pressable>
    );
}
