import Axios from 'axios';
import type {AxiosError, AxiosResponse, AxiosRequestConfig} from 'axios';

import Settings from '~/constants/Settings';
import {getToken, signOut} from '~/helpers/login/auth';

export const axios = Axios.create({
    baseURL: Settings.url,
});

axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);

async function onRequestFulfilled(config: AxiosRequestConfig) {
    const authHeader = await getToken();

    config.headers = {
        Authorization: authHeader,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    return config;
}

async function onRequestRejected(error: AxiosError) {
    Promise.reject(error);
}

function onResponseFulfilled(response: AxiosResponse<any>) {
    return response;
}

async function onResponseRejected(error: AxiosError) {
    if (error?.response?.status === 403) {
        await signOut();
    }

    return Promise.reject(error);
}
