import {useContext, useEffect, useMemo} from 'react';

import {gql, useQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import {UserContext} from '~/contexts/UserContext';
import {filterEmpty, sorted} from '~/helpers/list';
import {isExpired, setExpiration} from '~/helpers/storage';

import {GetPackQuery, GetPackQuery_getPacks_edges} from './types/GetPackQuery';
import {PackAvailabilityQuery} from './types/PackAvailabilityQuery';
import {PacksAvailableForUserQuery} from './types/PacksAvailableForUserQuery';

const GET_PACK_QUERY = gql`
    query GetPackQuery {
        getPacks {
            edges {
                node {
                    packId
                    name
                    packType
                    studyType
                    amount
                    shortDescription
                    longDescription
                    avantage
                    minutes
                    economy
                    validityDays
                    position
                    bestSeller
                    decorationId
                    isDeleted
                    maxPurchaseQuantity
                    onlyAvailableAtUrl
                }
            }
        }
    }
`;

const PACK_EXPIRATION = 60;
const PACK_KEY = 'PACK_EXPIRE';
let REFETCHING = false;

export default function usePack() {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    };

    const query = useQuery<GetPackQuery>(GET_PACK_QUERY, options);

    async function refetchIfCacheExpire() {
        const expired = await isExpired(PACK_KEY);
        if (expired && !REFETCHING) {
            REFETCHING = true;
            await query.refetch();
            setExpiration(PACK_KEY, PACK_EXPIRATION);
            REFETCHING = false;
        }
    }

    useEffect(() => {
        refetchIfCacheExpire();
    });

    return query;
}

const PACK_AVAILABILITY_FOR_USER = gql`
    query PackAvailabilityQuery($packId: String!) {
        isPackAvailableForUser(packId: $packId) {
            quantity
            date
        }
    }
`;

export function useUserAllowedToBuyPack(
    packId: string | null | undefined,
    userId: string | null | undefined
) {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        skip: packId == null || userId == null,
        variables: {
            packId,
        },
    };

    return useQuery<PackAvailabilityQuery>(PACK_AVAILABILITY_FOR_USER, options);
}

const PACKS_AVAILABLE_FOR_USER = gql`
    query PacksAvailableForUserQuery {
        packsAvailableForUser {
            quantity
            date
            packId
        }
    }
`;

export function usePacksAvailableForUser(userId: string | null | undefined) {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        skip: userId == null,
    };

    return useQuery<PacksAvailableForUserQuery>(PACKS_AVAILABLE_FOR_USER, options);
}

export type ShowALlPacksConfig = {
    showPacks: 'all';
};

export type ShowOnlyCurrentPagePacksConfig = {
    showPacks: 'only-current-page';
    currentPath: string;
};

export function useUserPacks(config: ShowALlPacksConfig | ShowOnlyCurrentPagePacksConfig) {
    const {me} = useContext(UserContext);
    const {data, loading: loadingPacks} = usePack();
    const {data: userPacks, loading: loadingUserPacks} = usePacksAvailableForUser(me?.id);
    const userPackAvailability = new Map(
        userPacks?.packsAvailableForUser?.map((a) => [a?.packId, a])
    );

    const packs = useMemo(() => {
        const edges = filterEmpty(data?.getPacks?.edges);
        return filterEmpty(sorted(edges, getPosition, false).map((e) => e.node));
    }, [data]);

    const availablePacks = packs.filter((pack) => {
        console.log('Pack ', pack.packId);
        if (pack.isDeleted) {
            return false;
        }
        if (config.showPacks == 'all' && pack.onlyAvailableAtUrl != null) {
            return false;
        }
        if (
            config.showPacks == 'only-current-page' &&
            pack.onlyAvailableAtUrl != config.currentPath
        ) {
            return false;
        }
        const userAvailability = userPackAvailability.get(pack.packId);
        return (
            userAvailability == null || (userAvailability.date && userAvailability.quantity)
        );
    });
    return {packs: availablePacks, loading: loadingPacks || loadingUserPacks};
}

function getPosition(packEdge: GetPackQuery_getPacks_edges) {
    return packEdge.node?.position;
}
