import React, {useState} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import {Bloc} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {useAlert} from '~/contexts/AlertContext';

import PopupEmail from './PopupEmail';
import PopupProfil from './PopupProfil';
import PopupService from './PopupService';
import PopupSkin from './PopupSkin';
import PopupSpecialty from './PopupSpecialty';
import PopupVoicemail from './PopupVoicemail';

export default function BlocProfil() {
    const [modalSpecialtyVisible, setModalSpecialtyVisible] = useState(false);
    const [modalProfilVisible, setModalProfilVisible] = useState(false);
    const [modalServiceVisible, setModalServiceVisible] = useState(false);
    const [modalEmailVisible, setModalEmailVisible] = useState(false);
    const [modalVoicemailVisible, setModalVoicemailVisible] = useState(false);
    const [modalSkinVisible, setModalSkinVisible] = useState(false);
    const alert = useAlert();

    function onSuccess() {
        alert({
            message: 'Vos données ont bien été modifiées',
            width: 400,
        });
    }

    function onRequestCloseSpecialty() {
        setModalSpecialtyVisible(false);
    }

    function openSpecialty() {
        setModalSpecialtyVisible(true);
    }

    function onSuccessSpecialty() {
        setModalSpecialtyVisible(false);
        onSuccess();
    }

    function onRequestCloseProfil() {
        setModalProfilVisible(false);
    }

    function openProfil() {
        setModalProfilVisible(true);
    }

    function onSuccessProfil() {
        setModalProfilVisible(false);
        onSuccess();
    }

    function onRequestCloseService() {
        setModalServiceVisible(false);
    }

    function openService() {
        setModalServiceVisible(true);
    }

    function onSuccessService() {
        setModalServiceVisible(false);
        onSuccess();
    }

    function onRequestCloseEmail() {
        setModalEmailVisible(false);
    }

    function openEmail() {
        setModalEmailVisible(true);
    }

    function onSuccessEmail() {
        setModalEmailVisible(false);
        onSuccess();
    }

    function onRequestCloseVoicemail() {
        setModalVoicemailVisible(false);
    }

    function openVoicemail() {
        setModalVoicemailVisible(true);
    }

    function onSuccessVoicemail() {
        setModalVoicemailVisible(false);
        onSuccess();
    }

    return (
        <Bloc title="Mon profil public" iconName="address-book">
            <View style={styles.container}>
                <Field text="Mes spécialités" onPress={openSpecialty} />
                <Field text="Mon profil" onPress={openProfil} />
                <Field text="Mes prestations" onPress={openService} />
                <Field text="Mes emails de relance" onPress={openEmail} />
                <Field text="Mon message vocale" onPress={openVoicemail} />
                <Field text="Ma carte de visite" onPress={() => setModalSkinVisible(true)} />
            </View>
            <PopupSkin
                visible={modalSkinVisible}
                onRequestClose={() => setModalSkinVisible(false)}
                onSuccess={() => setModalSkinVisible(false)}
            />
            <PopupProfil
                visible={modalProfilVisible}
                onRequestClose={onRequestCloseProfil}
                onSuccess={onSuccessProfil}
            />
            <PopupSpecialty
                visible={modalSpecialtyVisible}
                onRequestClose={onRequestCloseSpecialty}
                onSuccess={onSuccessSpecialty}
            />
            <PopupService
                visible={modalServiceVisible}
                onRequestClose={onRequestCloseService}
                onSuccess={onSuccessService}
            />
            <PopupEmail
                visible={modalEmailVisible}
                onRequestClose={onRequestCloseEmail}
                onSuccess={onSuccessEmail}
            />
            <PopupVoicemail
                visible={modalVoicemailVisible}
                onRequestClose={onRequestCloseVoicemail}
                onSuccess={onSuccessVoicemail}
            />
        </Bloc>
    );
}

function Field({onPress, text}: {text: string; onPress: () => void}) {
    return (
        <View style={styles.fieldContainer}>
            <View style={styles.labelContainer}>
                <SimpleText style={styles.label}>{text}</SimpleText>
            </View>
            <TouchableOpacity onPress={onPress}>
                <Icons.Edit color={Colors.primary} size={15} />
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    button: {
        margin: 15,
        width: 250,
        alignSelf: 'center',
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    titleTextContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'flex-start',
    },
    picto: {
        width: 29,
        height: 22,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.secondary,
        alignSelf: 'center',
    },
    container: {
        margin: 20,
        padding: 10,
        backgroundColor: 'white',
    },
    fieldContainer: {
        flex: 1,
        flexDirection: 'row',
        margin: 5,
        justifyContent: 'space-between',
    },
    labelContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    pictoEditGold: {
        width: 17,
        height: 15,
    },
    label: {},
});
