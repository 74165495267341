import {useMediaQuery} from 'react-responsive';

import {LARGE_DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH} from '~/constants/Layout';

export default function useDeviceQuery(): MediaQueryResponse {
    const isDesktop = useMediaQuery({query: `(min-width: ${MOBILE_MAX_WIDTH}px)`});
    const isLargeDesktop = useMediaQuery({query: `(min-width: ${LARGE_DESKTOP_MIN_WIDTH}px)`});
    const isTouchDevice = useMediaQuery({query: '(hover: none)'});
    return {isDesktop, isMobile: !isDesktop, isLargeDesktop, isTouchDevice};
}

export type MediaQueryResponse = {
    isDesktop: boolean;
    isLargeDesktop: boolean;
    isMobile: boolean;
    isTouchDevice: boolean;
};
