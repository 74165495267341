/**
 * Experts are subscribed to a socket at all time, this socket is
 * then used to communicate new call ID's so the expert can subscribe
 * to new call events.
 *
 * A client initiate a call, once the server returns the call ID, the client
 * subscribe to all call events for this ID.
 *
 * A session look like this:
 *
 * - the expert subscribes to the global events socket (GlobalEvent::subscribeExpertGlobalEvents)
 * - a client initiate a call (initiateCall), and subscribe to call events (he will receive twilio call status events forwarded by the server)
 * - the expert is notified (onNewCallSubscription) about the new incoming call via its global socket
 * - the expert subscribes to the given call events (again, twilio events forwarded by our server)
 * - when the call ends, both sockets are closed
 * - the expert's global events socket remains open
 */
import Settings, {endpoints} from '~/constants/Settings';
import {axios} from '~/helpers/network';

export async function initiateCall(
    expertId: string | null | undefined,
    clientId: string | null | undefined,
    warning: number | null,
    usePack: boolean,
    freeCall?: boolean,
    withoutCard?: boolean,
    paymentIntent?: string,
    returnPath?: string,
    queryParams?: Object
) {
    const body = JSON.stringify({
        expert_id: expertId,
        client_id: clientId,
        warning: warning,
        use_pack: usePack,
        without_card: withoutCard,
        is_free: freeCall,
        payment_intent: paymentIntent,
        return_path: returnPath,
        query_params: queryParams,
    });
    try {
        const response = await axios.post(endpoints.call, body);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        }
        throw error;
    }
}

export async function terminateExpertCall() {
    try {
        const response = await axios.post(endpoints.expertHangup);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        }
        throw error;
    }
}

export async function terminateClientCall(callId: string | null) {
    try {
        const body = callId ? JSON.stringify({call_id: callId}) : null;
        const response = await axios.post(endpoints.clientHangup, body);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        }
        throw error;
    }
}

export async function checkCallClient() {
    const response = await axios.post(Settings.checkCallClient());
    return await response.data;
}

export async function checkCallExpert() {
    const response = await axios.post(Settings.checkCallExpert());
    return await response.data;
}

type OptionalString = string | null | undefined;
export type MessageCallback = (message: string) => Promise<void>;
export type CallEndCallback = () => void;
export type CallSubscriptionParams = {callSid: string; clientId: string};
export type CallParams = {
    clientId: OptionalString;
    expertId: OptionalString;
    warning: number | null;
};
export type EventsCallbacks = {
    onMessage?: MessageCallback;
};
export type InitiateCallParams = CallParams & EventsCallbacks;
export type SocketOptions = {
    uri: string;
    label: string;
};
export type GlobalSubscriptionParams = EventsCallbacks & {
    expertId: string;
    onNewCallSubscription?: (data: CallSubscriptionParams) => void;
};
