import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ModalProps, TouchableOpacity, Image} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Formik, FormikHelpers, FormikProps, ErrorMessage} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {
    SurnameInput,
    LastnameInput,
    AddressInput,
    AddressComplementInput,
    PostalCodeInput,
    CityInput,
    CountryInput,
} from '~/components/common/Inputs';
import CustomModal from '~/components/common/Modal';
import {FormLabel, SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {GenderEnum} from '~/types/graphql-global-types';

const UPDATE_USER_CONTACT = gql`
    mutation updateUserContact($contact: UserContactInput!) {
        updateUserContact(input: {contact: $contact}) {
            ok
        }
    }
`;

export default function PopupContact(props: ModalProps & {onSuccess: Function}) {
    const [selectedGender, setSelectedGender] = useState<GenderEnum>(GenderEnum.WOMAN);
    const {isDesktop} = useDeviceQuery();
    const {me, loadingMe, refetchMe} = React.useContext(UserContext);
    const [updateUserContact, {data: callData, error: callError}] = useMutation(
        UPDATE_USER_CONTACT,
        {
            errorPolicy: 'all',
        }
    );

    useEffect(() => {
        if (me?.identity?.gender) {
            setSelectedGender(me?.identity?.gender);
        }
    }, [me]);

    const message = (
        <SimpleText>
            Ces informations personnelles permettront d'établir vos factures.
        </SimpleText>
    );

    type Inputs = Pick<
        YupTypes,
        | 'surname'
        | 'lastname'
        | 'address'
        | 'addressComplement'
        | 'postalCode'
        | 'city'
        | 'country'
    >;
    type Helpers = FormikHelpers<Inputs>;

    async function submit(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        const data = {
            contact: {
                gender: selectedGender,
                surname: values.surname,
                lastname: values.lastname,
                address: values.address,
                addressComplement: values.addressComplement,
                postalCode: values.postalCode,
                city: values.city,
                country: values.country,
            },
        };

        await updateUserContact({variables: data});
        props.onSuccess();
        logEvent('modify_account', {fields: 'address'});
    }

    const {
        surname,
        lastname,
        address,
        addressComplement,
        postalCode,
        city,
        country,
    } = yupRules;
    const validationSchema = yup
        .object()
        .shape({surname, lastname, address, addressComplement, postalCode, city, country});

    const style = isDesktop ? styles.formContainer : mobileStyles.formContainer;

    return (
        <CustomModal
            title="Mes coordonnées"
            iconName="house-user"
            hasCloseIcon={true}
            message={message}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            mobileContentMaxWidth={500}
        >
            <SimpleText style={{alignSelf: 'center', textAlign: 'center'}}>
                Merci de les compléter ou de les modifier à tout changement de situation
                personnelle.
            </SimpleText>
            <View style={styles.container}>
                <Formik
                    initialValues={{
                        surname: me?.identity?.surname ?? '',
                        lastname: me?.identity?.lastname ?? '',
                        address: me?.identity?.address ?? '',
                        addressComplement: me?.identity?.addressComplement ?? '',
                        postalCode: me?.identity?.postalCode ?? '',
                        city: me?.identity?.city ?? '',
                        country: me?.identity?.country ?? '',
                    }}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                >
                    {(formikProps) => (
                        <>
                            <View style={style}>
                                <View style={styles.column}>
                                    <FormLabel style={styles.label}>Genre</FormLabel>
                                    <View style={styles.genders}>
                                        <ItemPressable
                                            setSelected={setSelectedGender}
                                            text={'Femme'}
                                            value={GenderEnum.WOMAN}
                                            selected={selectedGender == GenderEnum.WOMAN}
                                        />
                                        <ItemPressable
                                            setSelected={setSelectedGender}
                                            text={'Homme'}
                                            value={GenderEnum.MAN}
                                            selected={selectedGender == GenderEnum.MAN}
                                        />
                                        <ItemPressable
                                            setSelected={setSelectedGender}
                                            text={'Autre'}
                                            value={GenderEnum.OTHER}
                                            selected={selectedGender == GenderEnum.OTHER}
                                        />
                                    </View>
                                    <FormLabel style={styles.label}>Prénom</FormLabel>
                                    <SurnameInput
                                        value={formikProps.values.surname}
                                        placeholder={''}
                                        onChangeText={formikProps.handleChange('surname')}
                                        onBlur={() => formikProps.setFieldTouched('surname')}
                                        errorMessage={formikProps.errors.surname || ' '}
                                        style={styles.input}
                                    />
                                    <FormLabel style={styles.label}>Nom</FormLabel>
                                    <LastnameInput
                                        value={formikProps.values.lastname}
                                        placeholder={''}
                                        onChangeText={formikProps.handleChange('lastname')}
                                        onBlur={() => formikProps.setFieldTouched('lastname')}
                                        errorMessage={formikProps.errors.lastname || ' '}
                                        style={styles.input}
                                    />
                                    <FormLabel style={styles.label}>Adresse</FormLabel>
                                    <AddressInput
                                        value={formikProps.values.address}
                                        placeholder={''}
                                        onChangeText={formikProps.handleChange('address')}
                                        onBlur={() => formikProps.setFieldTouched('address')}
                                        errorMessage={formikProps.errors.address || ' '}
                                        style={styles.input}
                                    />
                                </View>
                                <View style={styles.column}>
                                    <FormLabel style={styles.label}>Complément</FormLabel>
                                    <AddressComplementInput
                                        value={formikProps.values.addressComplement}
                                        placeholder={''}
                                        onChangeText={formikProps.handleChange(
                                            'addressComplement'
                                        )}
                                        onBlur={() =>
                                            formikProps.setFieldTouched('addressComplement')
                                        }
                                        errorMessage={
                                            formikProps.errors.addressComplement || ' '
                                        }
                                        style={styles.input}
                                    />
                                    <FormLabel style={styles.label}>Code Postal</FormLabel>
                                    <PostalCodeInput
                                        value={formikProps.values.postalCode}
                                        placeholder={''}
                                        onChangeText={formikProps.handleChange('postalCode')}
                                        onBlur={() =>
                                            formikProps.setFieldTouched('postalCode')
                                        }
                                        errorMessage={formikProps.errors.postalCode || ' '}
                                        style={styles.input}
                                    />
                                    <FormLabel style={styles.label}>Ville</FormLabel>
                                    <CityInput
                                        value={formikProps.values.city}
                                        placeholder={''}
                                        onChangeText={formikProps.handleChange('city')}
                                        onBlur={() => formikProps.setFieldTouched('city')}
                                        errorMessage={formikProps.errors.city || ' '}
                                        style={styles.input}
                                    />
                                    <FormLabel style={styles.label}>Pays</FormLabel>
                                    <CountryInput
                                        value={formikProps.values.country}
                                        placeholder={''}
                                        onChangeText={formikProps.handleChange('country')}
                                        onBlur={() => formikProps.setFieldTouched('country')}
                                        errorMessage={formikProps.errors.country || ' '}
                                        style={styles.input}
                                    />
                                </View>
                            </View>
                            <View style={styles.buttons}>
                                <Button
                                    icon={
                                        <Icons.Validate size={16} style={{paddingRight: 10}} />
                                    }
                                    title="Valider"
                                    buttonStyle={{
                                        width: 160,
                                        alignSelf: 'center',
                                        marginTop: 10,
                                    }}
                                    disabled={!formikProps.isValid}
                                    onPress={() => formikProps.handleSubmit()}
                                    loading={formikProps.isSubmitting}
                                />
                            </View>
                        </>
                    )}
                </Formik>
            </View>
        </CustomModal>
    );
}

type ItemPressableProps = {
    setSelected: (item: GenderEnum) => void;
    text: string;
    value: GenderEnum;
    selected: boolean;
};

function ItemPressable(props: ItemPressableProps) {
    function onPress() {
        props.setSelected(props.value);
    }

    const icon = props.selected ? 'check-circle' : 'circle';
    const background = props.selected
        ? {backgroundColor: Colors.secondary}
        : {backgroundColor: Colors.dark};
    const text = props.selected ? {color: 'white'} : {color: Colors.secondary};
    const url = props.selected
        ? Settings.getUrlPictoSelected()
        : Settings.getUrlPictoUnselected();

    return (
        <TouchableOpacity onPress={onPress} style={{height: 40}}>
            <View style={[styles.item, background]}>
                <SimpleText style={text}>{props.text}</SimpleText>
                <Image
                    style={{height: 12, width: 12, marginLeft: 10, marginTop: 3}}
                    source={{uri: url}}
                    accessibilityLabel={props.text}
                />
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        padding: 10,
        marginTop: 20,
    },
    formContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    column: {},
    title: {
        color: Colors.secondary,
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 20,
        textTransform: 'uppercase',
    },
    label: {
        marginTop: 10,
    },
    genders: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: 10,
    },
    item: {
        borderRadius: 5,
        width: 100,
        flex: 1,
        flexDirection: 'row',
        padding: 5,
        marginRight: 15,
        marginTop: 10,
    },
    input: {
        width: 300,
        minwidth: 300,
        backgroundColor: 'white',
    },
    buttons: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
});

const mobileStyles = StyleSheet.create({
    formContainer: {},
});
