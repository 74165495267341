import React, {createContext, useImperativeHandle, useRef} from 'react';

import ChatStatus from '~/components/user/chat/ChatStatus';
import type {ChatStatusHandles} from '~/components/user/chat/ChatStatus';
import {UserContext} from '~/contexts/UserContext';

export const ChatStatusContext = createContext<React.RefObject<ChatStatusHandles> | null>(null);

export function ChatStatusProvider(props: {children: React.ReactNode}) {
    const chat = useRef<ChatStatusHandles>(null);
    const {me} = React.useContext(UserContext);
    
    return (
        <ChatStatusContext.Provider value={chat} {...props}>
            {props.children}
            {me && <ChatStatus ref={chat} me={me} />}
        </ChatStatusContext.Provider>
    );
}
