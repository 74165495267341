import {useContext} from 'react';
import {ActivityIndicator} from 'react-native';

import {UserContext} from '~/contexts/UserContext';

import {SimpleText} from '../common/Texts';
import StepCheckedIcon from './StepIcon';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';
import {styles} from './styles';

export default function PostRegisterView(props: {stepIndex: number}) {
    const {me, loadingMe} = useContext(UserContext);
    const {isPaymentStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const isVisible = isPaymentStep || (pageVersion == 2 && me != null);
    if (!isVisible) {
        return null;
    }
    return (
        <>
            <StepTitle>
                {props.stepIndex}/ Compte créé <StepCheckedIcon />
            </StepTitle>
            <SimpleText style={styles.promoParagraph}>
                Félicitation, votre compte a été créé avec succès !
            </SimpleText>
            <SimpleText style={[styles.promoParagraph, {paddingTop: 0}]}>
                Vous êtes connecté en tant que
                <SimpleText style={{fontWeight: 'bold'}}>
                    {' '}
                    {loadingMe ? <ActivityIndicator /> : me?.profile?.displayName}
                </SimpleText>
                .
            </SimpleText>
        </>
    );
}
