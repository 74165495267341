import React from 'react';
import {ModalProps} from 'react-native';

import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';

import PasswordForm from './PasswordForm';

export default function PopupPassword(props: ModalProps & {onSuccess: Function}) {
    const message = (
        <SimpleText>
            Vous pouvez modifier ou compléter vos informations personnelles Kyvoitou.fr. Ce
            sont elles qui vous permettront de rentrer en relation avec vos spirites. Veillez à
            leur exactitude !
        </SimpleText>
    );
    return (
        <CustomModal
            title="Changer mon mot de passe"
            iconName="unlock-alt"
            message={message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            mobileContentMaxWidth={400}
        >
            <PasswordForm onSuccess={props.onSuccess} />
        </CustomModal>
    );
}
