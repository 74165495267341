import {endpoints} from '~/constants/Settings';
import {axios} from '~/helpers/network';

export type holdPaymentWithoutCardData = {
    service: string;
    communicationId: string;
    returnUrl: string;
    returnQueryParams: Record<string, string | null | undefined>;
    amount?: number;
    packId?: string;
    setupIntent?: boolean; // If true, setup an intent instead of a payment
};

export async function holdPaymentWithoutCard(
    data: holdPaymentWithoutCardData,
    existingClientSecret: string | null | undefined
) {
    const body = JSON.stringify({
        service: data.service,
        communication_id: data.communicationId,
        return_base_url: data.returnUrl,
        query_params: data.returnQueryParams,
        existing_client_secret: existingClientSecret,
    });
    const response = await axios.post(endpoints.holdPaymentWithoutCard, body);
    const {client_secret: clientSecret} = response.data;
    return {clientSecret} as {clientSecret: string};
}
