import React from 'react';
import {View, StyleSheet} from 'react-native';
import {useFocusEffect} from '@react-navigation/native';

import {gql, useLazyQuery} from '@apollo/client';
import {Bloc} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';

const GET_EMAIL_RECALL_HISTORY_QUERY = gql`
    query GetEmailRecallHistoryQuery {
        getEmailRecallHistory {
            emailNumber
        }
    }
`;

export default function BlocRecall() {

    const [load, {called, loading, data, refetch}] = useLazyQuery(GET_EMAIL_RECALL_HISTORY_QUERY, { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' });

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    function reload() {        
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    let text = "";
    const emailNumber = data?.getEmailRecallHistory?.emailNumber;

    if (emailNumber == 1) {
        text = `${data.getEmailRecallHistory.emailNumber} relance client effectuée`;        
    }
    else if (emailNumber > 1) {
        text = `${data.getEmailRecallHistory.emailNumber} relances client effectuées`;        
    }

    return (
        <Bloc title="Relances automatiques" iconName="mail-bulk">
            {emailNumber > 0 && (
                <View style={styles.container}>
                    <SimpleText style={styles.text}>{text} ce jour.</SimpleText>
                    <SimpleText style={styles.text}>Félicitations !</SimpleText>
                </View>
            )}
            {!emailNumber && (
                <SimpleText style={styles.noRecall}>Aucune relance effectuée ce jour.</SimpleText>
            )}
        </Bloc>
    );
}

const styles = StyleSheet.create({
    container : {
        alignSelf: 'center',
        backgroundColor: 'white',
        padding: 15,
        marginBottom: 10
    },
    text : {
        alignSelf: 'center',
        marginBottom: 5
    },
    noRecall : {
        alignSelf: 'center',
        padding: 17,
    },
});
