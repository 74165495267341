import {useEffect} from 'react';

import {gql, useQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import {isExpired, setExpiration} from '~/helpers/storage';

import type {TagsQuery} from './types/TagsQuery';

const TAGS_QUERY = gql`
    query TagsQuery {
        tagType {
            edges {
                node {
                    id
                    label
                    tagTypeId
                    tags {
                        edges {
                            node {
                                id
                                label
                                title
                                description
                                order
                                urlLabel
                            }
                        }
                    }
                }
            }
        }
    }
`;

const TAG_EXPIRATION = 60;
const TAG_KEY = 'TAG_EXPIRE';
let REFETCHING = false;

export default function useTags() {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only',
    };
    const query = useQuery<TagsQuery>(TAGS_QUERY, options);

    async function refetchIfCacheExpire() {
        const expired = await isExpired(TAG_KEY);
        if (expired && !REFETCHING) {
            REFETCHING = true;
            await query.refetch();
            setExpiration(TAG_KEY, TAG_EXPIRATION);
            REFETCHING = false;
        }
    }

    useEffect(() => {
        refetchIfCacheExpire();
    });

    return query;
}
