import React from 'react';
import {View} from 'react-native';

import {useNavigation} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import logEvent from '~/helpers/analytics';
import * as auth from '~/helpers/login/auth';

export default function SignOut() {
    const navigation = useNavigation();

    async function signOut() {
        await auth.signOut();
        navigation.navigate('HomeScreen');
        logEvent('logout');
    }

    return (
        <View>
            <Button
                icon={<Icons.SignOut size={18} style={{paddingRight: 10, paddingTop: 2}} />}
                title="Se déconnecter"
                buttonStyle={{width: 200, alignSelf: 'center', marginTop: 30}}
                onPress={() => signOut()}
            />
        </View>
    );
}
