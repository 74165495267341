import {useCallback} from 'react';
import {View} from 'react-native';

import usePack from '~/queries/usePack';

import Button from '../common/Buttons';
import {Bold, SimpleText} from '../common/Texts';
import StepCheckedIcon from './StepIcon';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';
import {useSelectedPack} from './hook/useSelectedPack';
import {styles} from './styles';

export default function PickedPackView() {
    const {isAuthStep, isPaymentStep, showPacks} = useUrlStepContext();
    const [selectedPackId, setSelectedPackId] = useSelectedPack();
    const {data} = usePack();
    const packEdge = data?.getPacks?.edges?.find((f) => f?.node?.packId == selectedPackId);
    const pack = packEdge?.node;
    const isVisible = pack && (isAuthStep || isPaymentStep);
    const pickAnotherPack = useCallback(() => {
        setSelectedPackId(undefined);
        showPacks();
    }, []);
    if (!isVisible) {
        return null;
    }
    return (
        <View>
            <StepTitle>
                2/ Forfait choisi <StepCheckedIcon />
            </StepTitle>
            {/* <PackView pack={pack} /> */}
            <SimpleText style={{textAlign: 'center'}}>
                Pack {pack.name} : <Bold>{pack.minutes}&nbsp;minutes</Bold> pour{' '}
                <Bold style={styles.price}>{pack.amount}&nbsp;€ </Bold>
            </SimpleText>
            <SimpleText style={{fontSize: 12, fontStyle: 'italic', textAlign: 'center'}}>
                soit une économie de {pack?.economy}&nbsp;€
            </SimpleText>
            <Button
                title="Choisir un autre pack"
                onPress={pickAnotherPack}
                titleStyle={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    textDecorationLine: 'underline',
                    fontFamily: 'Montserrat_500Medium',
                }}
                style={{minWidth: 300}}
            />
        </View>
    );
}
