import React, {useEffect} from 'react';
import {StyleSheet, ScrollView, ViewStyle, View} from 'react-native';

import {useFocusEffect, useNavigation} from '@react-navigation/native';

import {SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import {
    filterIncompleteProfile,
    sortExperts,
    ServiceEnum,
    Expert,
    getGenderFromIndex,
    UNAVAILABLE,
} from '~/helpers/experts';
import {getOffer} from '~/helpers/experts';
import {filterEmpty} from '~/helpers/list';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {useExpertsDetails} from '~/queries/useExperts';
import {ALL, FAVORITE} from '~/screens/SearchScreen';
import {SearchEngineItem} from '~/types/expertListOptions';

import ExpertCard from './ExpertCard';

export default function ExpertList(props: ExpertListProps) {
    const navigation = useNavigation();
    const {loading, error, data, refetch} = useExpertsDetails({pollInterval: 60000});
    const {me, offers} = React.useContext(UserContext);
    const {isDesktop} = useDeviceQuery();
    const favorites = filterEmpty(me?.favorites?.edges.map((e) => e?.node?.expertId));

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    useEffect(() => {
        if (error) {
            setTimeout(refetch, 2000);
        }
    }, [error]);

    function reload() {
        if (refetch) {
            refetch();
        }
    }

    function isGenderDisplayed(expert: Expert) {
        let displayed = false;

        for (let index = 0; index < props.genders.length; index++) {
            const element = props.genders[index];
            if (element.selected && element.id == ALL) {
                displayed = true; // All
            } else if (element.selected && element.id == FAVORITE) {
                displayed = favorites.indexOf(expert.userId) != -1; // Favorite
            } else if (
                element.selected &&
                getGenderFromIndex(parseInt(element.id)) == expert.identity?.gender
            ) {
                displayed = true;
            }
        }

        return displayed;
    }

    function isTagDisplayed(expert: Expert, list: SearchEngineItem[]) {
        if (!list.some((i) => i.selected)) {
            return true;
        }

        let displayed = false;
        const tags = expert?.tags?.edges.map((tag) => tag?.node?.id);

        if (tags) {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];

                if (element.selected && element.id == ALL) {
                    displayed = true; // All
                } else if (element.selected && tags.some((t) => t == element.id)) {
                    displayed = true;
                }
            }
        }

        return displayed;
    }

    function isSpecialityDisplayed(expert: Expert) {
        return isTagDisplayed(expert, props.specialities);
    }

    function isAvailabilityDisplayed(expert: Expert) {
        if (!props.availabilities.some((i) => i.selected)) {
            return true;
        }

        let displayed = false;

        for (let index = 0; index < props.availabilities.length; index++) {
            const element = props.availabilities[index];

            if (element.selected && element.id == ALL) {
                displayed = true; // All
            } else if (element.selected) {
                if (
                    element.id == ServiceEnum.CALL &&
                    expert.expert?.callStatus != UNAVAILABLE
                ) {
                    displayed = true;
                } else if (
                    element.id == ServiceEnum.MESSAGE &&
                    expert.expert?.messageStatus != UNAVAILABLE
                ) {
                    displayed = true;
                } else if (
                    element.id == ServiceEnum.CHAT &&
                    expert.expert?.chatStatus != UNAVAILABLE
                ) {
                    displayed = true;
                }
            }
        }

        return displayed;
    }

    function isConnectedDisplayed(expert: Expert) {
        if (!props.connected.some((i) => i.selected)) {
            return true;
        }

        const call = expert.expert?.callStatus != UNAVAILABLE;
        const message = expert.expert?.messageStatus != UNAVAILABLE;
        const chat = expert.expert?.chatStatus != UNAVAILABLE;

        return call || message || chat;
    }

    function filterExperts(expert: Expert) {
        return (
            isGenderDisplayed(expert) &&
            isSpecialityDisplayed(expert) &&
            isConnectedDisplayed(expert) &&
            isAvailabilityDisplayed(expert)
        );
    }

    const renderExpertCard = (expert: Expert) => {
        function onPressProfile() {
            navigation.navigate('ExpertScreen', {url: expert?.expert?.url});
        }

        return (
            <ExpertCard
                user={expert}
                offer={getOffer(me, offers, expert.id)}
                onPressProfile={onPressProfile}
                key={expert?.id}
                style={{display: 'flex'}}
            />
        );
    };

    if (error || (loading && !data)) {
        return <></>;
    }

    const allExperts = data?.findExperts?.edges.map((e) => e?.node);
    let experts = filterIncompleteProfile(allExperts);
    experts = experts.filter(filterExperts);
    const order = props.orders.find((o) => o.selected);
    if (order) {
        experts = sortExperts(experts, me, offers, order.id);
    } else {
        experts = sortExperts(experts, me, offers, '-1');
    }

    const desktopStyle = {
        justifyContent: 'space-around',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
    } as ViewStyle;

    const mobileStyle = {
        flexDirection: 'column',
        alignItems: 'center',
    } as ViewStyle;

    const contentContainerStyle = isDesktop ? desktopStyle : mobileStyle;
    const style = isDesktop ? styles.expertContainer : mobileStyles.expertContainer;

    return (
        <View style={style}>
            {experts.length > 0 && (
                <ScrollView contentContainerStyle={contentContainerStyle}>
                    {experts.map(renderExpertCard)}
                </ScrollView>
            )}
            {experts.length == 0 && (
                <SimpleText style={styles.noExpert}>
                    Aucun spirite ne correspond à votre recherche.
                </SimpleText>
            )}
        </View>
    );
}

export type ExpertListProps = {
    genders: SearchEngineItem[];
    specialities: SearchEngineItem[];
    connected: SearchEngineItem[];
    availabilities: SearchEngineItem[];
    orders: SearchEngineItem[];
};

const styles = StyleSheet.create({
    expertContainer: {
        flex: 1,
        backgroundColor: 'white',
        marginTop: 10,
    },
    noExpert: {
        textAlign: 'center',
        alignSelf: 'center',
        paddingTop: 45,
    },
});

const mobileStyles = StyleSheet.create({
    expertContainer: {
        flex: 1,
        marginTop: 10,
    },
});
