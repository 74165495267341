import React, {useState, useEffect, useRef} from 'react';
import {View, StyleSheet, TouchableOpacity, FlatList} from 'react-native';

import ReactCountryFlag from 'react-country-flag';

import HoverableView from '~/components/common/HoverableView';
import * as Icons from '~/components/common/Icons';
import * as Colors from '~/constants/Colors';

import {COUNTRIES, Country} from './Countries';
import {SimpleText} from './Texts';

type CountryPickerProps = {
    country: string | null;
    setCountry: (country: string) => void;
};

export default function CountryPicker(props: CountryPickerProps) {
    const node = useRef();

    const [open, setOpen] = useState(false);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    };

    function onPressTabItem(item: Country) {
        props.setCountry(item.code);
        setOpen(false);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    function renderOption({item}: {item: Country}) {
        return (
            <HoverableView hoverStyle={styles.hover}>
                <TouchableOpacity onPress={(e) => onPressTabItem(item)} style={styles.country}>
                    <ReactCountryFlag countryCode={item.code} svg />
                    <SimpleText style={styles.item}>{item.name}</SimpleText>
                </TouchableOpacity>
            </HoverableView>
        );
    }

    function onPressTab() {
        setOpen(!open);
    }

    const country = COUNTRIES.find((c) => c.code == props.country);

    return (
        <View ref={node}>
            <TouchableOpacity onPress={onPressTab} style={[styles.country, styles.container]}>
                {props.country && <ReactCountryFlag countryCode={props.country} svg />}
                <SimpleText numberOfLines={1} lineBreakMode={'clip'} style={styles.title}>
                    {country?.name}
                </SimpleText>
                <Icons.Down size={20} color={Colors.secondary} style={{alignSelf: 'center'}} />
            </TouchableOpacity>
            {open && (
                <FlatList
                    data={COUNTRIES}
                    renderItem={renderOption}
                    keyExtractor={(item) => item.code}
                    numColumns={1}
                    style={styles.menu}
                />
            )}
        </View>
    );
}

const HOVER_COLOR = Colors.colorOpacity(Colors.primary, '22');
const styles = StyleSheet.create({
    hover: {
        backgroundColor: HOVER_COLOR,
    },
    menu: {
        position: 'absolute',
        zIndex: 9999,
        elevation: 9999,
        backgroundColor: 'white',
        top: 42,
        left: 10,
        borderRadius: 5,
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        width: 140,
        height: 250,
    },
    item: {
        paddingHorizontal: 10,
    },
    title: {
        paddingHorizontal: 10,
        width: 100,
    },
    country: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    container: {
        width: 140,
        borderWidth: 1,
        borderRadius: 10,
        marginLeft: 10,
        paddingHorizontal: 10,
        paddingVertical: 9,
        borderColor: Colors.primary,
    },
});
