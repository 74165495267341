import {GetClientOffers} from '~/queries/types/GetClientOffers';
import {QueryMe_me} from '~/queries/types/QueryMe';

export function filterEmpty<T>(list?: (T | null | undefined)[]): T[] {
    return list?.filter(notEmpty) ?? [];
}

export function notEmpty<T>(value: T | null | undefined): value is T {
    /* https://stackoverflow.com/a/46700791/1720199  */
    if (value === null || value === undefined) return false;
    return true;
}

export function sorted<T>(array: T[], key: Key<T>, reverse?: boolean, args?: Args): T[] {
    /** Usage: 
        
        // Sort according to a
        sorted([{a: "aaa", b: "bbz"}, {a: "aaz", b: "bbb"}], (e) => e.a)

        // Sort according to b
        sorted([{ a: "aaa", b: "bbz" }, { a: "aaz", b: "bbb" }], (e) => e.b)
    **/
    function write(value: T): SortElement<T> {
        return {sortValue: key(value, args), value};
    }

    function compare(a: SortElement<T>, b: SortElement<T>) {
        if (a.sortValue == null || b.sortValue == null) {
            return 0;
        }
        if (a.sortValue < b.sortValue) {
            return -1;
        }
        if (a.sortValue > b.sortValue) {
            return 1;
        }
        return 0;
    }

    function read(element: SortElement<T>) {
        return element.value;
    }

    const sortedArray = [...array].map(write).sort(compare).map(read);
    return reverse ? sortedArray.reverse() : sortedArray;
}

export type Comparable = number | string | undefined | null;
export type Key<T> = (value: T, args?: Args) => Comparable;
export type SortElement<T> = {
    sortValue: Comparable;
    value: T;
};
export type Args = {
    maxCall: number;
    maxMessageSimpleQuestion: number;
    maxMessageCompleteStudy: number;
    maxChat: number;
    maxCom: number;
    me: QueryMe_me | null | undefined;
    offers: GetClientOffers | null | undefined;
};
