import Constants from 'expo-constants';

const extra = Constants.manifest?.extra;
const HOST = extra?.serverHost;
const SSL = extra?.useSSL;
export const IS_DEBUG = Constants.expoConfig?.extra?.isDebug;
const CLOUDCUBE_URL = extra?.cloudcubeUrl;

export default class Settings {
    static host = HOST;
    static url = SSL ? `https://${HOST}` : `http://${HOST}`;
    static socketUrl = SSL ? `wss://${HOST}` : `ws://${HOST}`;
    static uri_pdf = '/static/pdf';
    static uri_image = '/static/img';
    static uri_decoration = '/decoration';
    static uri_image_picto = '/picto';
    static uri_image_how_to = '/how_to';
    static uri_image_commitment = '/commitment';
    static uri_image_faq = '/faq';
    static uri_image_skin_type = '/skin_type';
    static uri_image_profile = '/profile_pic';
    static uri_image_attachment = '/attachment';
    static uri_image_banner = '/banner';
    static uri_image_post = '/post';
    static uri_image_popup = '/popup';
    static uri_image_support = '/support';
    static uri_image_logo = '/logo';
    static uri_image_home = '/home';
    static url_bucket = CLOUDCUBE_URL + '/public';
    static url_newsletter = 'https://kyvoitou-0917.restdb.io/rest/emails';
    static url_blog = 'https://blog.kyvoitou.fr';
    static api_key_newsletter = '6093eccee3b6e02545eda4a6';
    static support_telephone = '05 53 40 15 55';
    static support_mobile = '06 73 72 00 43';
    static support_mail = 'camille@kyvoitou.fr';
    static contact_mail = 'info@kyvoitou.fr';
    static provision = '100€';
    static google_form = 'https://forms.gle/By3HDnAWFM2MuSf67';

    static getUrlImageSkinType(idSkinType?: number | string | null, size?: string | null) {
        const id = idSkinType ?? DEFAULT_SKIN_TYPE;
        return (
            this.url +
            this.uri_image +
            this.uri_image_skin_type +
            '/' +
            id +
            '_' +
            size +
            '.webp'
        );
    }

    static getUrlImageProfile(pictureName: string | null | undefined) {
        if (pictureName) {
            return (
                this.url_bucket + this.uri_image + this.uri_image_profile + '/' + pictureName
            );
        } else {
            return Settings.defaultImageProfile();
        }
    }

    static getUrlImageAttachment(attachmentName: string | null | undefined) {
        return (
            this.url_bucket + this.uri_image + this.uri_image_attachment + '/' + attachmentName
        );
    }

    static getUrlMp3(mp3: string) {
        return this.url_bucket + '/' + mp3;
    }

    static getUrlImageSupport(image: string) {
        return this.url + this.uri_image + this.uri_image_support + '/' + image;
    }

    static getUrlImageLogo(image: string) {
        return this.url + this.uri_image + this.uri_image_logo + '/' + image;
    }

    static defaultImageProfile() {
        return this.url + this.uri_image + this.uri_image_profile + '/default.png';
    }

    static getUrlPictoSelected() {
        return this.url + this.uri_image + this.uri_image_picto + '/selected.webp';
    }

    static getUrlPictoUnselected() {
        return this.url + this.uri_image + this.uri_image_picto + '/unselected.webp';
    }

    static getUrlPictoBall() {
        return this.url + this.uri_image + this.uri_image_picto + '/ball.png';
    }

    static getUrlBestSeller() {
        return this.url + this.uri_image + this.uri_image_picto + '/best_seller.png';
    }

    static getUrlDecoration(decorationId: string) {
        return (
            this.url_bucket +
            this.uri_image +
            this.uri_decoration +
            '/' +
            decorationId +
            '.png'
        );
    }

    static getUrlDecorationBack(decorationId: string) {
        return (
            this.url_bucket +
            this.uri_image +
            this.uri_decoration +
            '/' +
            decorationId +
            '_back.png'
        );
    }

    static getUrlPictoReviews() {
        return this.url + this.uri_image + this.uri_image_picto + '/reviews.png';
    }

    static getUrlImageHowToChoice() {
        return this.url + this.uri_image + this.uri_image_how_to + '/choice.webp';
    }

    static getUrlImageHowToLogin() {
        return this.url + this.uri_image + this.uri_image_how_to + '/login.webp';
    }

    static getUrlImageHowToCommunication() {
        return this.url + this.uri_image + this.uri_image_how_to + '/communication.webp';
    }

    static getUrlImageHowToGift() {
        return this.url + this.uri_image + this.uri_image_how_to + '/gift.webp';
    }

    static getUrlImageCommitmentEvaluation() {
        return this.url + this.uri_image + this.uri_image_commitment + '/evaluation.webp';
    }

    static getUrlImageCommitmentSecure() {
        return this.url + this.uri_image + this.uri_image_commitment + '/secure.webp';
    }

    static getUrlImageCommitmentFlow() {
        return this.url + this.uri_image + this.uri_image_commitment + '/flow.webp';
    }

    static getUrlImageCommitmentSupport() {
        return this.url + this.uri_image + this.uri_image_commitment + '/support.webp';
    }

    static getUrlImageFaq(id: string) {
        return this.url + this.uri_image + this.uri_image_faq + '/' + id + '.webp';
    }

    static getUrlPictoFaqStar() {
        return this.url + this.uri_image + this.uri_image_faq + '/star.webp';
    }

    static getUrlImageFaqContact() {
        return this.url + this.uri_image + this.uri_image_faq + '/contact.webp';
    }

    static getUrlHeader() {
        return this.url + this.uri_image + this.uri_image_banner + '/header.webp';
    }

    static getUrlHeaderMobile() {
        return this.url + this.uri_image + this.uri_image_banner + '/mobile.webp';
    }

    static getUrlWelcome() {
        return this.url + this.uri_image + this.uri_image_banner + '/welcome.webp';
    }

    static getUrlBanner() {
        return this.url + this.uri_image + this.uri_image_banner + '/banner.webp';
    }

    static getUrlSignature() {
        return this.url + this.uri_image + this.uri_image_home + '/signature.webp';
    }

    static getUrlJustine(isDesktop: boolean) {
        if (isDesktop) {
            return this.url + this.uri_image + this.uri_image_home + '/justine.webp';
        } else {
            return this.url + this.uri_image + this.uri_image_home + '/justine_mobile.webp';
        }
    }

    static getUrlCamille(isDesktop: boolean) {
        if (isDesktop) {
            return this.url + this.uri_image + this.uri_image_home + '/camille.webp';
        } else {
            return this.url + this.uri_image + this.uri_image_home + '/camille_mobile.webp';
        }
    }

    static getUrlFlag() {
        return this.url + this.uri_image + this.uri_image_home + '/flag.webp';
    }

    static getUrlBall() {
        return this.url + this.uri_image + this.uri_image_home + '/ball.webp';
    }

    static getUrlPoint() {
        return this.url + this.uri_image + this.uri_image_home + '/point.webp';
    }

    static getUrlPrey() {
        return this.url + this.uri_image + this.uri_image_home + '/prey.webp';
    }

    static getUrlUploadImage() {
        return this.url + endpoints.setProfilePicture;
    }

    static getUrlMessageSupport() {
        return this.url + '/support/send-support-message';
    }

    static phoneCall() {
        return this.url + '/call';
    }

    static waitingList() {
        return this.url + '/waitingList';
    }

    static exitWaitingList() {
        return this.url + '/waitingList/exit';
    }

    static closeWaitingList() {
        return this.url + '/waitingList/close';
    }

    static checkWaitingListClient() {
        return this.url + '/waitingList/client/check';
    }

    static waitingListNext() {
        return this.url + '/waitingList/next';
    }

    static waitingListRemove() {
        return this.url + '/waitingList/remove';
    }

    static checkCallClient() {
        return this.url + '/call/client/check';
    }

    static checkCallExpert() {
        return this.url + '/call/expert/check';
    }

    static expertGlobalSocket(expertId: string, deviceId: string) {
        return (
            this.socketUrl +
            `/expert/event/subscribe?expert_id=${expertId}&device_id=${deviceId}`
        );
    }

    static userCallSocket(userCategory: UserCategory, communicationId: string) {
        const id = encodeURIComponent(communicationId);
        return this.socketUrl + `/call/${userCategory}/event/subscribe?call_sid=${id}`;
    }
    static userSocketKey(userCategory: UserCategory, communicationId: string) {
        return `${userCategory}_${encodeURIComponent(communicationId)}`;
    }

    static userChatSocket(
        userCategory: UserCategory,
        communicationId: string,
        deviceId: string
    ) {
        const id = encodeURIComponent(communicationId);
        const device =
            userCategory == 'expert' ? `&device_id=${encodeURIComponent(deviceId)}` : '';
        return this.socketUrl + `/chat/${userCategory}/event/subscribe?chat_id=${id}${device}`;
    }

    static waitingListSocketClient(clientId: string, expertId: string) {
        const c = `${encodeURIComponent(clientId)}`;
        const e = `${encodeURIComponent(expertId)}`;
        return (
            this.socketUrl +
            `/waitingList/client/event/subscribe?client_id=${c}&expert_id=${e}`
        );
    }
    static waitingListSocketKeyClient(clientId: string, expertId: string) {
        return `client_${encodeURIComponent(clientId)}_${encodeURIComponent(expertId)}`;
    }

    static waitingListSocketExpert(expertId: string) {
        const e = `${encodeURIComponent(expertId)}`;
        return this.socketUrl + `/waitingList/expert/event/subscribe?expert_id=${e}`;
    }

    static createStripeCustomer() {
        return this.url + '/stripe/add-payment-method';
    }

    static defaultImagePost(n: number) {
        return this.url + this.uri_image + this.uri_image_post + `/default_${n}.webp`;
    }

    static getUrlBackgroundPopup() {
        return this.url + this.uri_image + this.uri_image_popup + '/background.webp';
    }

    static getUrlLeftPopup() {
        return this.url + this.uri_image + this.uri_image_popup + '/left.webp';
    }

    static getUrlRightPopup() {
        return this.url + this.uri_image + this.uri_image_popup + '/right.webp';
    }

    static getUrlBackgroundPopupMobile() {
        return this.url + this.uri_image + this.uri_image_popup + '/background_mobile.webp';
    }

    static getUrlEmphasePopupMobile() {
        return this.url + this.uri_image + this.uri_image_popup + '/emphase_mobile.webp';
    }

    static getUrllogoStripe() {
        return this.url + this.uri_image + '/stripe/stripe.png';
    }
}

const DEFAULT_SKIN_TYPE = 1;

export const MIN_DURATION_FOR_REVIEW = 240;

export const endpoints = {
    call: '/call',
    clientHangup: '/hangup/client',
    expertHangup: '/hangup/expert',
    setProfilePicture: '/account/set-profile-picture',
    createStripeCustomer: '/stripe/add-payment-method',
    getPaymentMethod: '/stripe/get-payment-method',
    holdPaymentWithoutCard: '/stripe/hold-payment-without-card',
    setupIntent: '/stripe/setup-intent',
    getCurrentMonthBenefit: '/expert/benefit',
    recordVoicemail: '/voicemail/record',
    checkVoicemail: '/voicemail/check',
    wordpress:
        'https://blog.kyvoitou.fr/?rest_route=/wp/v2/posts&_fields=id,date,status,link,title,excerpt,_links&per_page=3&page=1&orderby=date&order=desc&_embed=true',
    deleteCard: '/stripe/delete-payment-method',
    cancelMessageIntent: '/stripe/cancel-message-intent',
    cancelCallIntent: '/stripe/cancel-call-intent',
    cancelChatIntent: '/stripe/cancel-chat-intent',
    apply: '/support/send-apply-message',
    phone: '/account/phone-number',
    email: '/account/email',
    version: '/version',
    newsletter: '/newsletter',
    buyPack: '/stripe/buy-pack',
    cancelOrder: '/stripe/cancel-order',
    saveStudy: '/study/save',
};

export type UserCategory = 'expert' | 'client';

export const STRIPE_3DS_HOLD = 'stripe-3ds-hold';
