import {SimpleText} from '../common/Texts';
import StepCheckedIcon from './StepIcon';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';
import {styles} from './styles';

export default function PostPaymentView() {
    const {isOngoingCallStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const isVisible = pageVersion == 2 && isOngoingCallStep;
    if (!isVisible) {
        return null;
    }
    return (
        <>
            <StepTitle>
                3/ Renseignez un moyen de paiement <StepCheckedIcon />
            </StepTitle>
            <SimpleText style={styles.promoParagraph}>
                Félicitation, votre moyen de paiement a été correctement ajouté !
            </SimpleText>
            <StepTitle selected>4/ Profitez de votre appel</StepTitle>
        </>
    );
}
