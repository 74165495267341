import React, {useState, useImperativeHandle, useEffect, useRef} from 'react';
import {Modal, StyleSheet, Pressable, View} from 'react-native';

import {gql, useLazyQuery, useQuery} from '@apollo/client';

import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import Chat from '~/components/user/chat/Chat';
import PopupChat from '~/components/user/chat/PopupChat';
import {GetLastExpertsQuery_getLastExperts_edges_node as Expert} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import {CommunicationType} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import Settings from '~/constants/Settings';
import type {UserCategory} from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';
import {useAudio} from '~/contexts/AudioPlayer';
import {useConfirm} from '~/contexts/ConfirmContext';
import logEvent, {purchase} from '~/helpers/analytics';
import {ChatExchange, getChatExchanges} from '~/helpers/chat';
import {getWarningDuration} from '~/helpers/dates';
import {getIdentification} from '~/helpers/identification';
import {ExchangeTypeEnum} from '~/helpers/message';
import {axios} from '~/helpers/network';
import redirect from '~/helpers/redirect';
import {
    waitingList,
    exitWaitingList,
    checkWaitingListClient,
    WaitListEventData,
    closeWaitingList,
} from '~/helpers/twilio/waitingList';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {ExpertsDetailsQuery_findExperts_edges_node as ExpertDetails} from '~/queries/types/ExpertsDetailsQuery';
import {GetClientOffers} from '~/queries/types/GetClientOffers';
import {QueryMe_me} from '~/queries/types/QueryMe';

import type {ExpertProfile_getExpert as ExpertProfile} from '../../../screens/types/ExpertProfile';
import type {CheckChatQuery, CheckChatQuery_checkChat} from './types/CheckChatQuery';

// import {GetPreviousChatQuery} from './types/GetPreviousChatQuery';

export enum ChatStatusEnum {
    INITIALIZED = 'INITIALIZED',
    STARTED = 'STARTED',
    ENDED = 'ENDED',
}

export type ChatProfile = {
    id: string | undefined;
    userId: string | undefined;
    name: string | undefined;
    picture: string | undefined;
};

type ChatEventData = {
    code: string;
    offer: string | null;
    content: string;
    attachments: string[];
    exchangeType: string;
    exchangeId: string;
    time: number;
    cost: number | null;
    pack: boolean | null;
    duration: number;
    url: string | null;
};

export type SendInput = {
    content: string;
    attachments: string[];
};

const CLIENT_PROFILE_QUERY = gql`
    query ClientProfileQuery($id: ID!) {
        user(id: $id) {
            id
            userId
            profile {
                displayName
                pictureName
            }
        }
    }
`;

const CHECK_CHAT_QUERY = gql`
    query CheckChatQuery {
        checkChat {
            id
            chatId
            expertId
            clientId
            startDatetime
            status
            usePack
            costWarning
            cost
            chatPrice {
                priceDecimal
            }
            clientOffer {
                offer {
                    label
                }
            }
            client {
                id
                userId
                profile {
                    displayName
                    pictureName
                }
            }
            expert {
                id
                userId
                profile {
                    displayName
                    pictureName
                }
            }
            exchanges {
                edges {
                    node {
                        chatExchangeId
                        type
                        content
                        creationDate
                        attachments {
                            edges {
                                node {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

// const GET_PREVIOUS_CHAT = gql`
//     query GetPreviousChatQuery($chatId: String!, $expertId: String!, $clientId: String!) {
//         getPreviousChat(chatId: $chatId, expertId: $expertId, clientId: $clientId) {
//             edges {
//                 node {
//                     chatExchangeId
//                     type
//                     content
//                     creationDate
//                     attachments {
//                         edges {
//                             node {
//                                 url
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;

const CHAT_EXPERT_QUERY = gql`
    query ChatExpertQuery($id: ID!) {
        user(id: $id) {
            id
            userId
            profile {
                displayName
                pictureName
            }
        }
    }
`;

export const TIMEOUT_WRITING = 2000;
const TIMEOUT_REFETCH = 5000;

const ChatStatus = React.forwardRef<ChatStatusHandles, ChatStatusProps>((props, ref) => {
    const {...modalProps} = props;
    const {isDesktop} = useDeviceQuery();
    const [chatId, setChatId] = useState<string | null>(null);
    const [warning, setWarning] = useState<number | null>(null);
    const [pack, setPack] = useState<boolean>(false);
    const [type, setType] = useState<ExchangeTypeEnum | null>(null);
    const [chatStatus, setChatStatus] = useState<ChatStatusEnum | null>(null);
    const [cost, setCost] = useState<number | null>(null);
    const [isPopupChatVisible, setIsPopupChatVisible] = useState(false);
    const [clientId, setClientId] = useState<string | null>(null);
    const [popupClient, setPopupClient] = useState<QueryMe_me | null | undefined>(null);
    const [popupExpert, setPopupExpert] = useState<
        ExpertProfile | ExpertDetails | Expert | null | undefined
    >(null);
    const [client, setClient] = useState<ChatProfile | null>(null);
    const [expert, setExpert] = useState<ChatProfile | null>(null);
    const [clientOffers, setClientOffers] = useState<GetClientOffers | null | undefined>(null);
    const [refetchClientOffers, setRefetchClientOffers] = useState<
        Function | null | undefined
    >(null);
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [isChatReduced, setIsChatReduced] = useState<boolean>(false);
    const [chatStartDate, setChatStartDate] = useState<Date | null>(null);
    const [duration, setDuration] = useState<number | null>(null);
    const [exchanges, setExchanges] = useState<ChatExchange[]>([]);
    const [isWriting, setIsWriting] = useState(false);
    const [hasNewMessage, setHasNewMessage] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const confirm = useConfirm();
    const [position, setPosition] = useState<number>(0);
    const [waitClientId, setWaitClientId] = useState<string | null | undefined>(null);
    const [waitExpertId, setWaitExpertId] = useState<string | null | undefined>(null);
    const [offer, setOffer] = useState<string | undefined>(undefined);
    const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
    const [error, setError] = useState<boolean>(false);
    const alert = useAlert();
    const {play, stop} = useAudio();

    useImperativeHandle(ref, () => ({
        chatExpert,
        newChat,
        onChat,
        onWriting,
        onStart,
        onEnd,
        onCheck,
        startChatFromOutside,
    }));

    const clientVariables = {id: clientId};
    const {data: clientData} = useQuery(CLIENT_PROFILE_QUERY, {
        variables: clientVariables,
        skip: clientId == null,
    });
    const expertVariable = {id: waitExpertId};
    const {data: expertData} = useQuery(CHAT_EXPERT_QUERY, {
        variables: expertVariable,
        skip: waitExpertId == null,
    });

    const {data: chatData, error: chatError, refetch: chatRefetch} = useQuery<CheckChatQuery>(
        CHECK_CHAT_QUERY,
        {
            fetchPolicy: 'network-only',
            skip: !props.me,
        }
    );

    const chatVariable = {chatId: chatId, expertId: expert?.userId, clientId: client?.userId};
    // const [
    //     loadPreviousChat,
    //     {data: previousChat, refetch: refetchPreviousChat},
    // ] = useLazyQuery<GetPreviousChatQuery>(GET_PREVIOUS_CHAT, {
    //     fetchPolicy: 'network-only',
    //     variables: chatVariable,
    // });

    useEffect(() => {
        if (clientData != null) {
            const clientProfile: ChatProfile = {
                id: clientData.user?.id,
                userId: clientData.user?.userId,
                name: clientData.user?.profile?.displayName,
                picture: clientData.user?.profile?.pictureName,
            };
            setClient(clientProfile);
        }
    }, [clientData]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isChatVisible) {
                chatRefetch();
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [isChatVisible]);

    useEffect(() => {
        if (chatData && chatData.checkChat && !error) {
            ref?.current?.onCheck(chatData.checkChat);
        }
    }, [chatData]);

    useEffect(() => {
        if (chatError) {
            console.log('chatError = ', chatError);
        }
    }, [chatError]);

    useEffect(() => {
        async function checkWaitingList() {
            if (props.me) {
                const data = await checkWaitingListClient(CommunicationType.CHAT);
                if (data.expertId != '') {
                    setPosition(data.position);
                    setWaitExpertId(data.expertId);
                    setWaitClientId(props.me.id);
                    setType(ExchangeTypeEnum.CLIENT_TO_EXPERT);
                    waitingList({
                        clientId: props.me.id,
                        expertId: data.expertId,
                        onWaitListMessage: _onWaitListMessage,
                        onCloseWaitingList: _onCloseWaitingList,
                        communicationType: CommunicationType.CHAT,
                        position: data.position,
                    });
                }
            }
        }
        checkWaitingList();
    }, [props.me]);

    useEffect(() => {
        if (expert == null && expertData != null) {
            const expertProfile: ChatProfile = {
                id: expertData.user?.id,
                userId: expertData.user?.userId,
                name: expertData.user?.profile?.displayName,
                picture: expertData.user?.profile?.pictureName,
            };
            setExpert(expertProfile);
        }
    }, [expertData]);

    // useEffect(() => {
    //     if (chatId) {
    //         addPreviousChat();
    //     }
    // }, [chatId]);

    // useEffect(() => {
    //     if (previousChat) {
    //         if (previousChat.getPreviousChat?.edges) {
    //             const previousExchanges = getChatExchanges(
    //                 previousChat.getPreviousChat?.edges,
    //                 true
    //             );
    //             const newExchanges = [...previousExchanges, ...exchanges];
    //             setExchanges(newExchanges);
    //         }
    //     }
    // }, [previousChat]);

    // function addPreviousChat() {
    //     if (refetchPreviousChat) {
    //         refetchPreviousChat();
    //     } else {
    //         loadPreviousChat();
    //     }
    // }

    function updateDuration() {
        if (
            chatStartDate &&
            chatStatus != ChatStatusEnum.ENDED &&
            chatStatus != ChatStatusEnum.INITIALIZED
        ) {
            const now = new Date();
            const duration = Math.round((now.getTime() - chatStartDate.getTime()) / 1000);
            setDuration(duration);
        }
    }

    useEffect(() => {
        const interval = setInterval(updateDuration, 1000);
        return () => clearInterval(interval);
    }, [chatStartDate, chatStatus]);

    function wait(warning: number | null) {
        setWarning(warning);
        confirm({
            title: 'Information',
            message:
                "Votre spirite est déjà en communication. Vous pouvez rejoindre la liste d'attente.",
            yesText: 'Rejoindre',
            noText: 'Annuler',
            onYes: () => {
                joinWaitingList();
                setIsPopupChatVisible(false);
            },
            onNo: () => {
                axios.post(endpoints.cancelChatIntent);
            },
        });
    }

    async function joinWaitingList() {
        var position = await waitingList({
            clientId: client?.id,
            expertId: expert?.id,
            onWaitListMessage: _onWaitListMessage,
            onCloseWaitingList: _onCloseWaitingList,
            communicationType: CommunicationType.CHAT,
        });
        if (position != null) {
            setPosition(position);
            setWaitClientId(client?.id);
            setWaitExpertId(expert?.id);
        }
        logEvent('waiting_list_joined', {type: 'chat'});
    }

    async function _onWaitListMessage(
        message: string,
        clientId: string | null | undefined,
        expertId: string | null | undefined
    ) {
        const messageData: WaitListEventData = JSON.parse(message);
        if (messageData.code == 'UPDATE_POSITION') {
            setPosition(messageData.position);
        } else if (messageData.code == 'EXPERT_STATUS_CHANGED') {
            await exit(clientId, expertId);
            alert({
                message:
                    'Votre spirite est maintenant indisponible. Nous sommes désolé pour la gêne occasionnée.',
            });
            logEvent('waiting_list_closed');
        } else if (messageData.code == 'END') {
            await closeWaitingList();
            startChatExpert(messageData.communicationId, warning, pack);
            logEvent('waiting_list_ended');
        }
    }

    async function exit(
        clientId: string | null | undefined = null,
        expertId: string | null | undefined = null,
        requestedByClient: boolean = false
    ) {
        await exitWaitingList(
            expertId ?? waitExpertId,
            CommunicationType.CHAT,
            requestedByClient
        );
    }

    async function exitManually() {
        exit(null, null, true);
        setPosition(0);
    }

    function _onCloseWaitingList() {
        setPosition(0);
    }

    // A client want to chat an expert : display pre-popup
    async function chatExpert(
        client: QueryMe_me | null | undefined,
        expert: ExpertProfile | ExpertDetails | Expert | null | undefined,
        offers: GetClientOffers | null | undefined,
        refetchOffers: Function | null | undefined
    ) {
        setPopupClient(client);
        setPopupExpert(expert);
        const clientProfile: ChatProfile = {
            id: client?.id,
            userId: client?.userId,
            name: client?.profile?.displayName,
            picture: client?.profile?.pictureName,
        };
        const expertProfile: ChatProfile = {
            id: expert?.id,
            userId: expert?.userId,
            name: expert?.profile?.displayName,
            picture: expert?.profile?.pictureName,
        };

        setClient(clientProfile);
        setExpert(expertProfile);
        setClientOffers(offers);
        setRefetchClientOffers(() => refetchOffers);
        setType(ExchangeTypeEnum.CLIENT_TO_EXPERT);
        setIsPopupChatVisible(true);
    }

    // A client initialize the chat : display chat popup
    async function startChatExpert(chatId: string, warning: number | null, pack: boolean) {
        setChatId(chatId);
        setChatStartDate(null);
        setDuration(null);
        setOffer(undefined);
        setWarning(warning);
        setPack(pack);
        setIsPopupChatVisible(false);
        setPopupClient(null);
        setPopupExpert(null);
        setIsChatVisible(true);
        setChatStatus(ChatStatusEnum.INITIALIZED);
        setExchanges([]);
        subscribeUser('client', chatId);
    }

    // An expert receive the chat demand
    async function newChat(
        chatId: string,
        clientId: string,
        expert: QueryMe_me | null | undefined
    ) {
        const expertProfile: ChatProfile = {
            id: expert?.id,
            userId: expert?.userId,
            name: expert?.profile?.displayName,
            picture: expert?.profile?.pictureName,
        };
        setExpert(expertProfile);
        setClientId('User:' + clientId);

        setChatId(chatId);
        setChatStartDate(null);
        setDuration(null);
        setOffer(undefined);
        setIsChatVisible(true);
        setType(ExchangeTypeEnum.EXPERT_TO_CLIENT);
        setChatStatus(ChatStatusEnum.INITIALIZED);
        setExchanges([]);
        subscribeUser('expert', chatId);
        playRing();
    }

    // The expert accept the chat demand
    async function onStart(data: ChatEventData) {
        stop();
        setChatStartDate(new Date());
        setChatStatus(ChatStatusEnum.STARTED);
        if (data.offer) {
            setOffer(data.offer);
        }
    }

    async function onEnd(data: ChatEventData) {
        stop();
        setChatStatus(ChatStatusEnum.ENDED);
        setCost(data.cost);
        setPack(data.pack ?? false);
        if (type == ExchangeTypeEnum.CLIENT_TO_EXPERT) {
            purchase(chatId, 'chat', data.cost);
            refetchClientOffers && refetchClientOffers();
            if (data.url) {
                setIsChatReduced(true);
                alert({
                    message:
                        'Conformément à la demande de votre banque, nous allons ouvrir une page sécurisé afin de finaliser votre consultation.',
                    onClose: () => redirect(data.url),
                });
            }
        }
    }

    async function onWriting(data: ChatEventData) {
        if (data.exchangeType != type) {
            setIsWriting(true);
            if (timer) {
                clearTimeout(timer);
            }
            const timeout = setTimeout(() => {
                setIsWriting(false);
            }, TIMEOUT_WRITING);
            setTimer(timeout);
        }
    }

    async function playSound() {
        play(require('../../../assets/sound/plop.mp3'));
    }

    async function playRing() {
        play(require('../../../assets/sound/ring.mp3'));
    }

    async function onChat(data: ChatEventData) {
        const exchange: ChatExchange = {
            exchangeId: data.exchangeId,
            exchangeType: data.exchangeType,
            content: data.content,
            attachments: data.attachments,
            time: data.time,
        };

        const newExchanges = [...exchanges, exchange];
        setExchanges(newExchanges);
        if (timer) {
            clearTimeout(timer);
        }
        setIsWriting(false);
        setHasNewMessage(true);
        if (type != data.exchangeType) {
            playSound();
        }
    }

    async function onRead() {
        setHasNewMessage(false);
    }

    async function retry() {
        console.log('retry');
        try {
            const result = await chatRefetch();
            if (result.data.checkChat) {
                ref?.current?.onCheck(result.data.checkChat);
                // addPreviousChat();
                setError(false);
            } else {
                setTimeout(retry, TIMEOUT_REFETCH);
            }
        } catch (e) {
            console.log('retry::catch ', e);
            setTimeout(retry, TIMEOUT_REFETCH);
        }
    }

    async function onError() {
        console.log('onError');
        console.log('error = ', error);
        console.log('chatId = ', chatId);
        if (!error && chatId) {
            setError(true);
            setTimeout(retry, TIMEOUT_REFETCH);
            try {
                webSocket?.close();
            } catch (e) {
                console.log('onError::catch ', e);
            }
        }
    }

    async function onMessage(message: string) {
        const messageData: ChatEventData = JSON.parse(message);
        if (messageData.code == ChatStatusEnum.STARTED) {
            ref?.current?.onStart(messageData);
        }
        if (messageData.code == 'MESSAGE') {
            ref?.current?.onChat(messageData);
        }
        if (messageData.code == 'WRITING') {
            ref?.current?.onWriting(messageData);
        }
        if (messageData.code == ChatStatusEnum.ENDED) {
            ref?.current?.onEnd(messageData);
        }
    }

    function onChatEnd(cost: number, usePack: boolean) {
        ref?.current?.onEnd({cost: cost, pack: usePack} as ChatEventData);
    }

    async function onCheck(chat: CheckChatQuery_checkChat) {
        const clientProfile: ChatProfile = {
            id: chat.client?.id,
            userId: chat.client?.userId,
            name: chat.client?.profile?.displayName,
            picture: chat.client?.profile?.pictureName,
        };
        const expertProfile: ChatProfile = {
            id: chat.expert?.id,
            userId: chat.expert?.userId,
            name: chat.expert?.profile?.displayName,
            picture: chat.expert?.profile?.pictureName,
        };

        setChatId(chat.chatId);
        setPack(chat.usePack ?? false);
        setDuration(null);
        if (chat.chatPrice && chat.costWarning) {
            const warningDuration = getWarningDuration(
                chat.costWarning,
                chat.chatPrice?.priceDecimal
            );
            setWarning(warningDuration);
        }
        setClient(clientProfile);
        setExpert(expertProfile);

        if (chat.clientId == props.me?.userId) {
            setType(ExchangeTypeEnum.CLIENT_TO_EXPERT);
            subscribeUser('client', chat.chatId);
        }
        if (chat.expertId == props.me?.userId) {
            setType(ExchangeTypeEnum.EXPERT_TO_CLIENT);
            subscribeUser('expert', chat.chatId);
        }

        if (chat.exchanges && chat.exchanges.edges) {
            // let previousExchanges = [] as ChatExchange[];
            // if (previousChat?.getPreviousChat?.edges) {
            //     previousExchanges = getChatExchanges(previousChat.getPreviousChat.edges, true);
            // }
            const currentExchange = getChatExchanges(chat.exchanges.edges, false);
            // const newExchange = [...previousExchanges, ...currentExchange];
            setExchanges(currentExchange);
        }

        const date = new Date(+new Date(chat.startDatetime));
        setChatStartDate(date);
        setChatStatus(chat.status as ChatStatusEnum);
        setIsChatVisible(true);
        setOffer(chat.clientOffer?.offer?.label);

        setCost(chat.cost);
        setPack(chat.usePack ?? false);

        if (type == ExchangeTypeEnum.CLIENT_TO_EXPERT) {
            purchase(chatId, 'chat', chat.cost);
            refetchClientOffers && refetchClientOffers();
        }
    }

    async function subscribeUser(user: UserCategory, chatId: string) {
        if (chatId) {
            const uri = Settings.userChatSocket(user, chatId, await getIdentification());
            const ws = new WebSocket(uri);
            setWebSocket(ws);
            ws.onmessage = async function (event) {
                const message = event.data;
                onMessage(message);
            };
            ws.onclose = function (event) {
                onError();
            };
            ws.onerror = function (event) {
                onError();
            };
        }
    }

    async function close() {
        console.log('close');
        setIsChatVisible(!isChatVisible);
        try {
            webSocket?.close(1000);
        } catch (e) {
            console.log('close::catch ', e);
        }
    }

    const _popupChat = useRef(null);

    async function startChatFromOutside(expertId: string, warning: number) {
        _popupChat?.current?.startChatFromOutside(expertId, warning);
    }

    return (
        <>
            {position > 0 && (
                <ChatWaitingList
                    position={position}
                    name={expert?.name}
                    picture={expert?.picture}
                    exit={exitManually}
                />
            )}
            <Chat
                visible={isChatVisible}
                isReduced={isChatReduced}
                setIsReduced={setIsChatReduced}
                onRequestClose={close}
                type={type}
                client={client}
                expert={expert}
                warning={warning}
                pack={pack}
                duration={duration}
                status={chatStatus}
                chatId={chatId}
                exchanges={exchanges}
                cost={cost}
                isWriting={isWriting}
                onRead={onRead}
                hasNewMessage={hasNewMessage}
                offer={offer}
                onError={onError}
                error={error}
                chatRefetch={chatRefetch}
                onChatEnd={onChatEnd}
            />
            <PopupChat
                visible={isPopupChatVisible}
                onRequestClose={() => {
                    setIsPopupChatVisible(!isPopupChatVisible);
                }}
                startChatExpert={startChatExpert}
                wait={wait}
                client={popupClient}
                expert={popupExpert}
                clientOffers={clientOffers}
                ref={_popupChat}
            />
        </>
    );
});

type ChatWaitingListProps = {
    position: number | null;
    picture: string | undefined;
    name: string | undefined;
    exit: () => void;
};

function ChatWaitingList(props: ChatWaitingListProps) {
    const {isDesktop} = useDeviceQuery();
    if (isDesktop) {
        return (
            <View style={styles.wait}>
                <View style={styles.container}>
                    <View style={styles.line}>
                        <Icons.Chat size={40} />
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={props.picture}
                        />
                        <View style={styles.textContainer}>
                            <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                <SimpleText style={styles.title}>
                                    En liste d'attente pour communiquer avec {props.name}
                                </SimpleText>
                                <SimpleText style={[styles.title, {marginHorizontal: 50}]}>
                                    |
                                </SimpleText>
                                <SimpleText style={styles.title}>
                                    Votre position dans la file d'attente : {props.position}
                                </SimpleText>
                            </View>
                            <View style={{alignSelf: 'center'}}>
                                <Pressable onPress={props.exit}>
                                    <SimpleText style={{textDecorationLine: 'underline'}}>
                                        Quitter la file
                                    </SimpleText>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    } else {
        return (
            <View style={[mobileStyles.containerStyle, {backgroundColor: Colors.pause}]}>
                <SimpleText style={{color: 'white'}}>
                    Position dans la file d'attente : {props.position}
                </SimpleText>
                <Pressable onPress={props.exit}>
                    <SimpleText style={{textDecorationLine: 'underline', color: 'white'}}>
                        Quitter la file
                    </SimpleText>
                </Pressable>
            </View>
        );
    }
}

export type ChatStatusProps = Modal['props'] & {
    me: QueryMe_me | null | undefined;
};

export type ChatStatusCallback = (
    client: QueryMe_me | null | undefined,
    expert: ExpertProfile | ExpertDetails | Expert | null | undefined,
    offers: GetClientOffers | null | undefined,
    refetchOffers: Function | null | undefined
) => void;

export type NewChatCallback = (
    chatId: string,
    clientId: string,
    expert: QueryMe_me | null | undefined
) => void;

export type ChatStatusHandles = {
    chatExpert: ChatStatusCallback;
    newChat: NewChatCallback;
    onChat: (data: ChatEventData) => void;
    onWriting: (data: ChatEventData) => void;
    onStart: (data: ChatEventData) => void;
    onEnd: (data: ChatEventData) => void;
    onCheck: (data: CheckChatQuery_checkChat) => void;
    startChatFromOutside: (expertId: string, warning: number) => void;
};

const styles = StyleSheet.create({
    wait: {
        alignSelf: 'center',
        width: '100%',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        borderRadius: 0,
    },
    container: {
        backgroundColor: Colors.pause,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        padding: 10,
        flex: 1,
        flexDirection: 'row',
    },
    profilePicture: {
        borderRadius: 60,
        width: 45,
        height: 45,
        marginHorizontal: 20,
    },
    title: {
        fontSize: 14,
        color: 'white',
    },
    line: {
        flex: 1,
        flexDirection: 'row',
    },
    textContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

const mobileStyles = StyleSheet.create({
    containerStyle: {
        position: 'absolute',
        top: 75,
        flexDirection: 'row',
        left: 0,
        right: 0,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

export default ChatStatus;
