export type ReviewData = {
    clientMode: boolean;
    communicationId: string | null | undefined;
    communicationType: CommunicationType | null;
    expertId: string | null | undefined;
    date: Date | null;
};

export enum CommunicationType {
    CALL = 'CALL',
    CHAT = 'CHAT',
    MESSAGE = 'MESSAGE',
}

export function getCommunicationType(service: string | null | undefined) {
    if (service == CommunicationType.CALL) {
        return CommunicationType.CALL;
    } else if (service == CommunicationType.MESSAGE) {
        return CommunicationType.MESSAGE;
    } else if (service == CommunicationType.CHAT) {
        return CommunicationType.CHAT;
    }
    return CommunicationType.MESSAGE;
}
