import React, {useEffect, useMemo, useState} from 'react';
import {FlatList, Image, StyleSheet, View, Text} from 'react-native';

import {CommonActions, RouteProp, useNavigation, useRoute} from '@react-navigation/native';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import {BallItem} from '~/components/common/IconText';
import {Separator} from '~/components/common/Separator';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import {SmallCard} from '~/components/expert/ExpertCard';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import {purchase} from '~/helpers/analytics';
import {
    getPackMinuteContent,
    getReloadForExpert,
    getStudyField,
    removeReloadForExpert,
    setReloadForExpert,
} from '~/helpers/pack';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {ExpertsDetailsQuery_findExperts_edges_node} from '~/queries/types/ExpertsDetailsQuery';
import {ExpertsQuery_findExperts_edges_node} from '~/queries/types/ExpertsQuery';
import useExperts, {useExpertsDetails} from '~/queries/useExperts';
import usePack from '~/queries/usePack';
import usePackOrder from '~/queries/usePackOrder';
import {PackParamList} from '~/types';
import {PackOrderStatus, PackType} from '~/types/graphql-global-types';

export default function SuccessScreen() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();
    const route = useRoute<RouteProp<PackParamList, 'SuccessScreen'>>();
    const [expert, setExpert] = useState<ExpertsDetailsQuery_findExperts_edges_node | null>(
        null
    );
    const {me, minutes, refetchMinutes} = React.useContext(UserContext);
    const {data: dataExperts} = useExpertsDetails({});

    const paymentIntentId = route.params?.paymentIntentId;
    const sessionId = route.params?.sessionId;

    const {data: dataOrder, refetch: refetchOrder} = usePackOrder(paymentIntentId, sessionId);

    const {data: dataPack} = usePack();

    useEffect(() => {
        async function isReloadForExpert() {
            const expertId = await getReloadForExpert();
            if (expertId) {
                const expert = dataExperts?.findExperts?.edges?.find(
                    (e) => e?.node?.userId == expertId
                );
                if (expert?.node) {
                    setExpert(expert.node);
                }
            }
        }

        if (dataExperts?.findExperts) {
            isReloadForExpert();
        }
    }, [dataExperts, paymentIntentId, sessionId]);

    useEffect(() => {
        if (dataOrder?.getPackOrder?.status == PackOrderStatus.WAITING) {
            setTimeout(refetchOrder, 2000);
        } else if (
            me &&
            dataOrder?.getPackOrder?.status == PackOrderStatus.CONFIRMED &&
            pack?.node?.packType == PackType.MINUTE &&
            minutes
        ) {
            refetchMinutes();
        }
    }, [me, dataPack, minutes, dataOrder]);

    useEffect(() => {
        if (dataOrder?.getPackOrder) {
            purchase(
                dataOrder.getPackOrder.packOrderId,
                'pack',
                dataOrder.getPackOrder.amount
            );
        }
    }, [dataOrder]);

    // console.log('minutes = ', minutes);

    async function goToExpert() {
        await removeReloadForExpert();
        if (isDesktop) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [
                        {
                            name: 'Home',
                            state: {
                                routes: [
                                    {
                                        name: 'HomeExpertScreen',
                                        params: {url: expert?.expert?.url},
                                    },
                                ],
                            },
                        },
                    ],
                })
            );
        } else {
            navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [
                        {
                            name: 'Main',
                            state: {
                                routes: [
                                    {
                                        name: 'Home',
                                        state: {
                                            routes: [
                                                {
                                                    name: 'HomeExpertScreen',
                                                    params: {url: expert?.expert?.url},
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                })
            );
        }
    }

    async function goToAccount() {
        if (isDesktop) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [
                        {
                            name: 'Profile',
                            state: {
                                routes: [
                                    {
                                        name: 'ProfileScreen',
                                    },
                                ],
                            },
                        },
                    ],
                })
            );
        } else {
            navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [
                        {
                            name: 'Main',
                            state: {
                                routes: [
                                    {
                                        name: 'Profile',
                                        state: {
                                            routes: [
                                                {
                                                    name: 'ProfileScreen',
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                })
            );
        }
    }

    // console.log('dataOrder = ', dataOrder);

    const order = dataOrder?.getPackOrder;

    const pack = dataPack?.getPacks?.edges?.find((f) => f?.node?.packId == order?.packId);

    const type = pack?.node?.packType;

    const solde = minutes?.getMinuteBalance?.minutes ?? 0;

    return (
        <MainView>
            <PageTitle>Récapitulatif de votre commande</PageTitle>
            <SimpleText style={styles.title}>Achat réussi !</SimpleText>
            {dataOrder && pack && (
                <View>
                    <SimpleText style={styles.edito}>Vous venez d'acheter :</SimpleText>
                    <View style={styles.buy}>
                        <SimpleText style={styles.left}>🎉</SimpleText>
                        <SimpleText style={styles.name}>Pack {pack?.node?.name}</SimpleText>
                        <SimpleText style={styles.right}>🎉</SimpleText>
                    </View>
                    <SimpleText style={styles.value}>Prix : {pack?.node?.amount} €</SimpleText>

                    <SimpleText style={styles.avantages}>Mes avantages</SimpleText>

                    {type == PackType.MINUTE && (
                        <View style={styles.list}>
                            <BallItem
                                text={getPackMinuteContent(pack?.node)}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                            <BallItem
                                text={`Valable ${pack?.node?.validityDays} jours à compter de la date d'achat`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                            <BallItem
                                text={`Paiement sécurisé grâce au 3D secure`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                            <BallItem
                                text={`Une maîtrise de votre budget`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                            <BallItem
                                text={`Une consultation immédiate`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                            <BallItem
                                text={`Aucune obligation d'utiliser votre crédit d'un seul coup`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                        </View>
                    )}

                    {type == PackType.EMAIL && (
                        <View style={styles.list}>
                            <BallItem
                                text={`Une étude complète par un expert en ${getStudyField(
                                    pack?.node?.studyType
                                )}`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                            <BallItem
                                text={`Un réponse dans les 10 jours`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                            <BallItem
                                text={`Je bénéficie de 3 messages de suivi si jamais j'ai des incompréhensions`}
                                style={styles.avantage}
                                containerStyle={styles.container}
                            />
                        </View>
                    )}

                    {type == PackType.MINUTE && (
                        <>
                            <SimpleText style={styles.avantages}>
                                Comment ça marche ?
                            </SimpleText>
                            <View style={styles.list}>
                                <BallItem
                                    text={`Je me rends sur la fiche du voyant que je souhaite consulter`}
                                    style={styles.avantage}
                                    containerStyle={styles.container}
                                />

                                <BallItem
                                    text={`Je choisis mon moyen de consultation : appel ou chat`}
                                    style={styles.avantage}
                                    containerStyle={styles.container}
                                />
                                <BallItem
                                    text={`Je sélectionne "j'utilise un pack"`}
                                    style={styles.avantage}
                                    containerStyle={styles.container}
                                />
                            </View>
                        </>
                    )}

                    <SimpleText style={styles.bye}>Bonne(s) consultation(s) !</SimpleText>

                    <SimpleText style={styles.contact}>
                        Un problème ? Contactez-nous à {Settings.support_mail} ou au{' '}
                        {Settings.support_telephone}
                    </SimpleText>

                    <SimpleText style={styles.reference}>
                        Numéro de commande : {order?.packOrderId}
                    </SimpleText>

                    {dataOrder?.getPackOrder?.status == PackOrderStatus.CONFIRMED &&
                        pack?.node?.packType == PackType.MINUTE &&
                        solde > 0 && (
                            <View>
                                <SimpleText style={styles.info}>
                                    Votre solde de minutes a bien été mis à jour.
                                </SimpleText>
                                <View style={styles.item}>
                                    <View style={styles.line}>
                                        <SimpleText style={[styles.label, styles.bold]}>
                                            Nouveau solde :{' '}
                                        </SimpleText>
                                        <SimpleText style={[styles.minute, styles.bold]}>
                                            {solde} minutes
                                        </SimpleText>
                                    </View>
                                </View>
                            </View>
                        )}

                    {dataOrder?.getPackOrder?.status == PackOrderStatus.CONFIRMED &&
                        pack?.node?.packType == PackType.EMAIL && (
                            <View>
                                <SimpleText style={styles.info}>
                                    Votre demande d'étude a bien été transmise à votre voyant.
                                </SimpleText>
                            </View>
                        )}
                    {expert && (
                        <View style={styles.shortcut}>
                            <SimpleText style={styles.access}>
                                Accéder directement à votre expert en cliquant sur son profil
                            </SimpleText>
                            <View style={{alignSelf: 'center'}}>
                                <SmallCard user={expert} onPressProfile={goToExpert} />
                            </View>
                        </View>
                    )}
                    <Separator style={styles.separator} />
                    <SimpleText style={styles.access}>Continuer votre navigation</SimpleText>
                    <Button
                        titleStyle={{fontSize: 16, marginHorizontal: 20}}
                        title={'Mon espace'}
                        onPress={goToAccount}
                    />
                </View>
            )}
        </MainView>
    );
}

const styles = StyleSheet.create({
    title: {
        color: Colors.secondary,
        fontSize: 22,
        textAlign: 'center',
        alignSelf: 'center',
    },
    edito: {
        color: Colors.secondary,
        fontSize: 16,
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: 20,
    },
    item: {
        alignSelf: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginVertical: 25,
        backgroundColor: Colors.dark,
        borderColor: Colors.primary,
        borderWidth: 1,
        borderRadius: 5,
        padding: 15,
        width: 300,
    },
    buy: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20,
    },
    left: {
        fontSize: 30,
        alignSelf: 'center',
    },
    name: {
        color: Colors.highlight,
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: 30,
        marginHorizontal: 15,
    },
    right: {
        fontSize: 30,
        alignSelf: 'center',
        transform: [{scaleX: -1}],
    },
    value: {
        color: Colors.secondary,
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: 18,
        fontStyle: 'italic',
        marginVertical: 10,
    },
    avantages: {
        color: Colors.secondary,
        fontSize: 20,
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: 30,
        marginBottom: 10,
        fontStyle: 'italic',
    },
    list: {
        textAlign: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    avantage: {
        fontSize: 16,
        textAlign: 'center',
        alignSelf: 'center',
        color: Colors.secondary,
    },
    container: {
        justifyContent: 'center',
    },
    bye: {
        fontSize: 32,
        textAlign: 'center',
        alignSelf: 'center',
        color: Colors.secondary,
        marginVertical: 30,
    },
    contact: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        alignSelf: 'center',
        color: Colors.secondary,
    },
    reference: {
        color: Colors.secondary,
        fontSize: 10,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 25,
    },
    info: {
        marginTop: 25,
        color: Colors.secondary,
        fontSize: 14,
        textAlign: 'center',
        alignSelf: 'center',
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    label: {
        textAlign: 'right',
        alignSelf: 'center',
        color: Colors.secondary,
        fontSize: 14,
        width: 150,
    },
    minute: {
        textAlign: 'left',
        alignSelf: 'center',
        color: Colors.secondary,
        fontSize: 14,
        width: 100,
    },
    bold: {
        fontWeight: 'bold',
    },
    shortcut: {
        alignSelf: 'center',
    },
    access: {
        marginTop: 20,
        textAlign: 'center',
        alignSelf: 'center',
        color: Colors.secondary,
        fontSize: 16,
        fontWeight: 'bold',
    },
    separator: {
        backgroundColor: Colors.secondary,
        marginVertical: 30,
        height: 2,
        alignSelf: 'center',
        width: '200px',
    },
});
