import AsyncStorage from '@react-native-async-storage/async-storage';

import {
    GetPackQuery_getPacks_edges,
    GetPackQuery_getPacks_edges_node,
} from '~/queries/types/GetPackQuery';
import {PackType, StudyType} from '~/types/graphql-global-types';

export function getPackContent(pack: GetPackQuery_getPacks_edges | null | undefined) {
    return pack?.node?.packType == PackType.MINUTE
        ? getPackMinuteContent(pack?.node)
        : 'Étude complète par email, dans mon espace personnel';
}

export function getPackMinuteContent(
    pack: GetPackQuery_getPacks_edges_node | null | undefined
) {
    return `${pack?.minutes} minutes à utiliser avec le/les voyant(s) de votre choix`;
}

export function getStudyField(studyType: StudyType | null | undefined) {
    return studyType == StudyType.ASTROLOGY ? 'astrologie' : 'numérologie';
}

export function getStudyTag(studyType: StudyType | null | undefined) {
    return studyType == StudyType.ASTROLOGY ? 'Astrologue' : 'Numérologie';
}

export function getStudyPerson(studyType: StudyType | null | undefined) {
    return studyType == StudyType.ASTROLOGY ? 'astrologue' : 'numérologue';
}

const RELOAD_KEY = 'RELOAD_KEY';

export async function setReloadForExpert(expertId: string | undefined) {
    if (expertId) {
        await AsyncStorage.setItem(RELOAD_KEY, expertId);
    }
}

export async function getReloadForExpert() {
    return await AsyncStorage.getItem(RELOAD_KEY);
}

export async function removeReloadForExpert() {
    return await AsyncStorage.removeItem(RELOAD_KEY);
}
