import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {IdentifiantInput} from '~/components/common/Inputs';
import {PhoneInput} from '~/components/common/PhoneInput';
import {FormLabel} from '~/components/common/Texts';
import {endpoints} from '~/constants/Settings';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {axios} from '~/helpers/network';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

const {identifiant} = yupRules;
const validationSchema = yup.object().shape({identifiant});

export default function AccountForm({onSuccess}: {onSuccess: Function}) {
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const {me, loadingMe} = React.useContext(UserContext);
    const [updateUser] = useMutation(UPDATE_USER, {
        errorPolicy: 'all',
    });

    useEffect(() => {
        setPhone(me?.identity?.phone ?? '');
        setPhoneError('');
    }, [me]);

    async function modify(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        const body = JSON.stringify({phone: phone});
        const response = await axios.post(endpoints.phone, body);

        if (!response.data.available) {
            setPhoneError('Ce numéro de téléphone est déjà utilisé');
        } else {
            try {
                const data = {
                    identifiant: values.identifiant,
                    phone: phone,
                };

                await updateUser({variables: data});
                onSuccess();
                logEvent('modify_account', {fields: 'identifiant, phone'});
            } catch (error) {
                throw error;
            }
        }
    }

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    return (
        <View>
            <Formik
                initialValues={{
                    identifiant: me?.profile?.displayName ?? '',
                }}
                onSubmit={modify}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <AccountInputs
                        formikProps={formikProps}
                        phone={phone}
                        setPhone={setPhone}
                        phoneError={phoneError}
                        setPhoneError={setPhoneError}
                    />
                )}
            </Formik>
        </View>
    );
}

type AccountInputsProps = {
    formikProps: FormikProps<Inputs>;
    phone: string;
    setPhone: (phone: string) => void;
    phoneError: string;
    setPhoneError: (phone: string) => void;
};

function AccountInputs(props: AccountInputsProps) {
    return (
        <View style={styles.container}>
            <FormLabel>Identifiant</FormLabel>
            <IdentifiantInput
                placeholder={yupLabels.identifiant}
                value={props.formikProps.values.identifiant}
                onChangeText={props.formikProps.handleChange('identifiant')}
                onBlur={() => props.formikProps.setFieldTouched('identifiant')}
                blurOnSubmit={true}
                errorMessage={props.formikProps.errors.identifiant || ' '}
            />
            <FormLabel>Numéro de téléphone</FormLabel>
            <PhoneInput
                disabled={false}
                initialize={true}
                width={490}
                phone={props.phone}
                setPhone={props.setPhone}
                phoneError={props.phoneError}
                setPhoneError={props.setPhoneError}
            />
            <Button
                icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                title="Valider"
                buttonStyle={{width: 160, alignSelf: 'center', marginVertical: 60}}
                disabled={!props.formikProps.isValid || props.phoneError != ''}
                onPress={() => props.formikProps.handleSubmit()}
                loading={props.formikProps.isSubmitting}
            />
        </View>
    );
}

type Inputs = Pick<YupTypes, 'identifiant'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const UPDATE_USER = gql`
    mutation updateUser($identifiant: String!, $phone: String!) {
        updateUser(input: {phone: $phone, displayName: $identifiant}) {
            ok
        }
    }
`;

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: 'black',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    container: {
        marginTop: 20,
        marginVertical: 10,
        borderRadius: 5,
        marginHorizontal: 25,
        alignContent: 'space-around',
        backgroundColor: 'white',
        paddingHorizontal: 10,
        paddingBottom: 30,
    },
    radioLabel: {
        fontSize: 14,
        alignSelf: 'flex-start',
        borderWidth: 0,
    },
});
