import {useCallback} from 'react';

import {useLandingPageNavigation, useLandingPageRoute} from '../route';

export function useExpertId() {
    const route = useLandingPageRoute();
    const navigation = useLandingPageNavigation();
    const selectedExpertId = route.params?.selectedExpertId;
    const setSelectedExpertId = useCallback((expertId: string | undefined) => {
        navigation.setParams({
            selectedExpertId: expertId,
        });
    }, []);

    return [selectedExpertId, setSelectedExpertId] as const;
}
