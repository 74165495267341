import {Bold, SimpleText, Underlined} from '../common/Texts';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';
import {styles} from './styles';

export default function TitleView(props: {nbStepsText: string}) {
    const {isExpertListStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const isVisible = isExpertListStep || pageVersion == 2;
    if (!isVisible) {
        return null;
    }
    return (
        <>
            <Title />
            <PromoText nbStepsText={props.nbStepsText} />
        </>
    );
}

function Title() {
    return (
        <SimpleText accessibilityRole="header" aria-level="1" style={styles.title}>
            Votre Voyance 100% Gratuite par téléphone !
        </SimpleText>
    );
}

function PromoText(props: {nbStepsText: string}) {
    return (
        <>
            <SimpleText style={styles.promoParagraph}>
                Pour bénéficier d’une <Bold>voyance gratuite par téléphone</Bold>, c’est très
                simple ! Suivez ces <Underlined>{props.nbStepsText} étapes</Underlined> et dans
                quelques minutes obtenez les réponses à vos questions !
            </SimpleText>
            <SimpleText style={styles.promoParagraph}>
                Convaincus de la qualité de nos experts, nous vous proposons{' '}
                <Bold>3 consultations de voyance gratuite</Bold> de 5 minutes chacune afin de
                vous en rendre compte par vous même.
            </SimpleText>
        </>
    );
}
