import React from 'react';

import {useFonts, Montserrat_500Medium} from '@expo-google-fonts/montserrat';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {SafeAreaProvider} from 'react-native-safe-area-context';

import Header from '~/components/Header';
import {AlertProvider} from '~/contexts/AlertContext';
import ApolloProvider from '~/contexts/ApolloProvider';
import {AudioPlayer} from '~/contexts/AudioPlayer';
import {CallStatusProvider} from '~/contexts/CallStatusContext';
import {ChatStatusProvider} from '~/contexts/ChatStatusContext';
import {ConfirmProvider} from '~/contexts/ConfirmContext';
import {UserContextProvider} from '~/contexts/UserContext';
import injectWebCss from '~/helpers/injectWebCss';
import Navigation from '~/navigation';

import {GlobalEvent} from './contexts/GlobalEvent';
import ensureVersion from './helpers/version';

injectWebCss();
ensureVersion();

export default function App() {
    const [fontsLoaded] = useFonts({Montserrat_500Medium});

    if (!fontsLoaded) {
        return null;
    } else {
        return (
            <ApolloProvider>
                <UserContextProvider>
                    <SafeAreaProvider>
                        <AudioPlayer>
                            <AlertProvider>
                                <ConfirmProvider>
                                    <CallStatusProvider>
                                        <ChatStatusProvider>
                                            <GlobalEvent>
                                                <GestureHandlerRootView style={{flex: 1}}>
                                                    <Header />
                                                    <Navigation />
                                                </GestureHandlerRootView>
                                            </GlobalEvent>
                                        </ChatStatusProvider>
                                    </CallStatusProvider>
                                </ConfirmProvider>
                            </AlertProvider>
                        </AudioPlayer>
                    </SafeAreaProvider>
                </UserContextProvider>
            </ApolloProvider>
        );
    }
}
