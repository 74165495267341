import React from 'react';
import {View, StyleSheet, FlatList} from 'react-native';

import {TouchableOpacity} from 'react-native-gesture-handler';

import {CardView} from '~/components/common/Containers';
import DateText from '~/components/common/Date';
import {GradeResume} from '~/components/common/GradeItem';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import {CommunicationType} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {filterEmpty} from '~/helpers/list';

import type {
    ExpertProfile_getExpert_reviews_edges,
    ExpertProfile_getExpert_reviews_edges_node,
    ExpertProfile_getExpert_reviews_edges_node_qualities_edges,
} from '../../screens/types/ExpertProfile';
import {ReviewData} from '../user/client/review/types/calls';

export default function ReviewPage({
    expertId,
    pageNumber,
    reviews,
    onPressReviewList,
}: ReviewPageProps) {
    function renderItem({item}: {item: ExpertProfile_getExpert_reviews_edges_node}) {
        function onPressReview() {
            let communicationId = '';
            let communicationType = CommunicationType.CALL;
            if (item.call) {
                communicationId = item.call.id;
                communicationType = CommunicationType.CALL;
            } else if (item.message) {
                communicationId = item.message.id;
                communicationType = CommunicationType.MESSAGE;
            } else if (item.chat) {
                communicationId = item.chat.id;
                communicationType = CommunicationType.CHAT;
            }

            let reviewData: ReviewData = {
                communicationId: communicationId,
                communicationType: communicationType,
                date: item?.createdDate,
                expertId: expertId,
                clientMode: true,
            };
            onPressReviewList(reviewData);
        }

        if (!item) {
            return null;
        }
        return <Review reviewContent={item} onPress={onPressReview} />;
    }

    if (!reviews) {
        return null;
    }

    const data = filterEmpty(
        reviews.map((edge: ExpertProfile_getExpert_reviews_edges) => edge.node)
    );

    return (
        <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={(item) => item?.reviewId}
            listKey={`review-page-${pageNumber}`}
        />
    );
}

function Review(props: ReviewProps) {
    function isQuality(f: ExpertProfile_getExpert_reviews_edges_node_qualities_edges) {
        return f.node?.starNumber ? f.node?.starNumber > 2 : false;
    }

    const qualities = filterEmpty(props.reviewContent.qualities?.edges)
        .filter(isQuality)
        .map((edge) => {
            return edge.node?.quality ? edge.node?.quality?.label : '';
        });

    function renderQuality(item: {item: string}) {
        return (
            <View style={styles.quality}>
                <Icons.Validate
                    color={Colors.secondary}
                    style={{marginRight: 10, marginTop: 2}}
                    size={14}
                />
                <SimpleText>{item.item}</SimpleText>
            </View>
        );
    }

    let icon = 'phone-alt';
    if (props.reviewContent.call) {
        icon = 'phone-alt';
    } else if (props.reviewContent.chat) {
        icon = 'comments';
    } else if (props.reviewContent.message) {
        icon = 'envelope';
    }

    return (
        <TouchableOpacity onPress={props.onPress}>
            <CardView shadow style={styles.reviewCard}>
                <View style={styles.reviewResume}>
                    <ReviewTitle {...props.reviewContent} icon={icon} />
                    <View style={styles.reviewInfo}>
                        <GradeResume
                            text={props.reviewContent.grade?.label || ''}
                            grade={props.reviewContent.gradeId}
                        />
                        <FlatList
                            data={qualities}
                            renderItem={renderQuality}
                            keyExtractor={(item, index) => `quality${index}`}
                        />
                    </View>
                </View>
                <ReviewContent {...props.reviewContent} />
            </CardView>
        </TouchableOpacity>
    );
}

function ReviewTitle({
    client,
    createdDate,
    icon,
}: ExpertProfile_getExpert_reviews_edges_node & {icon: string}) {
    const date = new Date(createdDate);
    return (
        <View style={styles.reviewTitleCard}>
            {Icons.getIcon(icon, 24, 'white', {
                backgroundColor: Colors.secondary,
                borderRadius: 5,
                padding: 5,
                justifyContent: 'center',
            })}
            <View style={styles.reviewTitle}>
                <SimpleText style={styles.reviewClientDisplayName}>
                    {client?.profile?.displayName}
                </SimpleText>
                <SimpleText style={styles.reviewDate}>
                    <DateText date={date} />
                </SimpleText>
            </View>
        </View>
    );
}

function ReviewContent(props: ExpertProfile_getExpert_reviews_edges_node) {
    return (
        <View style={styles.reviewContent}>
            <SimpleText style={styles.reviewText}>{props.comment}</SimpleText>
        </View>
    );
}

type ReviewPageProps = {
    expertId: string | undefined;
    pageNumber: number;
    reviews?: ExpertProfile_getExpert_reviews_edges[];
    onPressReviewList: (reviewData: ReviewData) => void;
};

type ReviewProps = {
    reviewContent: ExpertProfile_getExpert_reviews_edges_node;
    onPress: () => void;
};

const styles = StyleSheet.create({
    reviewCard: {
        padding: 0,
        flex: 1,
        flexDirection: 'row',
        marginBottom: 20,
    },
    reviewResume: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: Colors.dark,
        padding: 10,
        maxWidth: 250,
    },
    reviewInfo: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    reviewTitleCard: {
        flexDirection: 'row',
    },
    reviewTitle: {
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
    },
    reviewDate: {
        alignSelf: 'center',
    },
    reviewClientDisplayName: {
        alignSelf: 'center',
    },
    reviewContent: {
        padding: 15,
        width: 500,
        textAlign: 'center',
        flex: 1,
    },
    reviewGrade: {},
    quality: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 10,
    },
    qualityText: {
        textTransform: 'lowercase',
    },
    reviewText: {
        textAlign: 'left',
    },
});
