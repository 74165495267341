import React from 'react';
import {StyleSheet, View} from 'react-native';

import {Formik, FormikProps, FormikHelpers} from 'formik';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import type {InputType} from '~/components/common/Inputs';
import {PasswordInput} from '~/components/common/Inputs';
import CustomModal, {CustomModalProps} from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {reAuthenticate, UserCredential} from '~/helpers/login/auth';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';

const {password} = yupRules;
const authValidationSchema = yup.object().shape({password});

export default function ReAuthenticateModal(props: ReAuthenticateModalProps) {
    const {onSuccess, onFailure, ...modalProps} = props;

    const onSubmit = async ({password}: Inputs, {setErrors}: Helpers) => {
        try {
            const credentials = await reAuthenticate(password);
            onSuccess?.(credentials);
        } catch (error) {
            if (error.code == 'auth/wrong-password') {
                setErrors({password: 'Le mot de passe est incorrect'});
            }
            onFailure?.(error);
        }
    };

    return (
        <CustomModal iconName="user" hasCloseIcon={true} {...modalProps}>
            <Formik
                initialValues={{password: ''}}
                onSubmit={onSubmit}
                validationSchema={authValidationSchema}
                validateOnBlur={false}
            >
                {(formikProps) => <PasswordForm {...formikProps} />}
            </Formik>
        </CustomModal>
    );
}

function PasswordForm(formikProps: Props) {
    const autofocusFieldRef = React.useRef<InputType>(null);

    React.useEffect(() => {
        setTimeout(() => {
            autofocusFieldRef?.current?.focus();
        }, 400);
    }, []);

    return (
        <View style={styles.container}>
            <SimpleText style={styles.formLabel}>Email</SimpleText>
            <PasswordInput
                placeholder={yupLabels.password}
                value={formikProps.values.password}
                onChangeText={formikProps.handleChange('password')}
                onBlur={() => formikProps.setFieldTouched('password')}
                errorMessage={formikProps.errors.password || ' '}
                onSubmitEditing={() => formikProps.handleSubmit()}
                ref={autofocusFieldRef}
            />
            <Button
                icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
                title="Valider"
                buttonStyle={{width: 160, alignSelf: 'center', marginBottom: 20}}
                disabled={!formikProps.isValid}
                onPress={() => formikProps.handleSubmit()}
                loading={formikProps.isSubmitting}
            />
        </View>
    );
}

export type ReAuthenticateModalProps = Omit<CustomModalProps, 'children'> & {
    onSuccess?: (credentials: UserCredential | undefined) => void;
    onFailure?: (error: Error) => void;
};

type Inputs = Pick<YupTypes, 'password'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        marginVertical: 10,
        borderRadius: 5,
        marginHorizontal: 25,
        alignContent: 'space-around',
        backgroundColor: 'white',
    },
    formLabel: {
        fontSize: 14,
        marginBottom: 10,
        marginTop: 10,
        marginLeft: 10,
    },
});
