import * as React from 'react';
import {StyleSheet, Image, Text, View} from 'react-native';

import {MainView} from '~/components/common/Containers';
import {SimpleText, MobileTitle} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

import {Bloc, BlocType} from './CommitmentScreen';

export default function HowToScreen() {
    const {isDesktop} = useDeviceQuery();

    const style = isDesktop ? styles.center : mobileStyles.center;

    const textsOne = [
        'Pour commencer, il vous faut créer un compte sur Kyvoitou.fr, vous pouvez vous rendre ici : ',
        'Pour créer votre espace client vous aurez besoin de renseigner votre email, votre numéro de téléphone et de créer un mot de passe.',
        'Attention, un même numéro de téléphone ne peut être affilié qu’à un seul compte.',
        'Une fois votre compte créé, rendez-vous dans votre espace personnel puis dans « mon compte ». Vous pourrez alors y ajouter votre moyen de paiement.',
        'Ca y est, vous êtes prêt(e) à consulter !',
    ] as string[];

    const textsTwo = [
        'Maintenant que votre espace personnel est créé et rempli, vous pouvez retourner sur la page d’accueil pour consulter les profils de nos médiums.',
        'Chacun d’entre eux possède une fiche sur laquelle vous allez pouvoir voir leurs spécificités et entendre leur voix via une description vocale.',
        'Vous pouvez également faire des recherches par catégorie d’expert !',
        `Je peux vous aider à faire votre choix : contactez-moi au ${Settings.support_telephone} ou par email à ${Settings.support_mail} !`,
    ] as string[];

    const textsThree = [
        'Après avoir sélectionné le spirite que vous souhaitez consulter, il vous suffit de le contacter avec le mode qui vous convient : par tchat, par téléphone ou par email. Vous n’avez qu’à cliquer sur l’icône du moyen de communication qui vous convient !',
        'Si vous choisissez l’appel téléphonique, vous serez appelé sur le numéro avec lequel vous vous êtes inscrit(e).',
        'Nos spirites n’ont pas accès à votre numéro de téléphone, ni à aucune information personnelle vous concernant, tout passe par Kyvoitou.fr pour la mise en relation !',
    ] as string[];

    const textsFour = [
        'Grâce à notre offre de bienvenue, vous pouvez entrer en contact gratuitement pendant 5 minutes avec 3 spirites différents.',
        'Grâce à cette offre, vous pouvez tester jusqu’à 3 médiums afin de pouvoir faire votre choix !',
        'Et n’oubliez pas de leur laisser un petit avis, ils sont toujours ravis de les lire !',
    ] as string[];

    return (
        <MainView>
            <HowToTitle />
            <View>
                <SimpleText style={style}>
                    Sur Kyvoitou.fr vous trouverez des spirites qui ont été sélectionnés
                    méticuleusement par nos soins. Ils sont là pour vous apporter des réponses
                    et vous accompagner dans vos choix de vie en vous offrant des clés de
                    réussite !
                </SimpleText>
                <SimpleText style={style}>
                    Amour, famille, vie professionnelle, finances... Nos médiums sont là pour
                    vous guider et vous aider dans vos décisions.
                </SimpleText>
                <SimpleText style={style}>
                    Nous vous expliquons ici comment procéder pour pouvoir consulter un de nos
                    spirites !
                </SimpleText>
            </View>
            <Bloc
                title={'1ère étape – Inscription'}
                image={Settings.getUrlImageHowToChoice()}
                texts={textsOne}
                type={BlocType.IMAGE_THEN_TEXT}
                addRegisterToFirstPhrase={true}
            />
            <Bloc
                title={'2ème étape – Choisissez votre expert'}
                image={Settings.getUrlImageHowToLogin()}
                texts={textsTwo}
                type={BlocType.TEXT_THEN_IMAGE}
            />
            <Bloc
                title={'3ème étape - Lancez une consultation'}
                image={Settings.getUrlImageHowToCommunication()}
                texts={textsThree}
                type={BlocType.IMAGE_THEN_TEXT}
            />
            <Bloc
                title={'4ème étape – De nombreuses offres vous attendent !'}
                image={Settings.getUrlImageHowToGift()}
                texts={textsFour}
                type={BlocType.TEXT_THEN_IMAGE}
            />
        </MainView>
    );
}

function HowToTitle() {
    const {isDesktop} = useDeviceQuery();

    if (isDesktop) {
        return <PageTitle>COMMENT CA MARCHE ?</PageTitle>;
    } else {
        return <MobileTitle title={'Comment ca marche ?'} />;
    }
}

const styles = StyleSheet.create({
    center: {
        marginTop: 15,
        fontSize: 20,
        alignSelf: 'center',
        textAlign: 'center',
    },
});

const mobileStyles = StyleSheet.create({
    center: {
        marginTop: 15,
        fontSize: 16,
        alignSelf: 'center',
        paddingHorizontal: 10,
        textAlign: 'center',
    },
});
