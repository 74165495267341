import React, {useRef, useState} from 'react';
import {View, StyleSheet, FlatList, ScrollView, TouchableOpacity} from 'react-native';

import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/native';
import {CheckBox} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import {MainView} from '~/components/common/Containers';
import HoverableView from '~/components/common/HoverableView';
import * as Icons from '~/components/common/Icons';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {ScreenLoader} from '~/components/navigation/Loader';
import Exchange from '~/components/user/client/message/Exchange';
import MessageHeader from '~/components/user/client/message/MessageHeader';
import * as Colors from '~/constants/Colors';
import {useConfirm} from '~/contexts/ConfirmContext';
import * as dates from '~/helpers/dates';
import {sorted} from '~/helpers/list';
import {
    MessageStatusEnum,
    getLastExchangeFromMessage,
    getLastExchange,
    EnumModeMessageBox,
    ExchangeTypeEnum,
    mergeMessages,
    Message,
    getTypeLabel,
} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import PopupStatistics from '../../client/statistics/PopupStatistics';
import FreeMessage from './FreeMessage';
import {
    mobileStyles,
    MobileInboxNavigation,
    MobileListHeaderTitle,
    MobileMessageRow,
} from './MobileMessageBox';
import PopupMessageFromExpert from './PopupMessageFromExpert';
import {GetMessageQuery} from './types/GetMessageQuery';

export type MessageInput = {
    id: string;
    group: boolean;
};

const TRASH_MESSAGES = gql`
    mutation trashMessages($messages: [MessageInput]) {
        trashMessages(input: {messages: $messages}) {
            ok
        }
    }
`;

const READ_MESSAGES = gql`
    mutation readMessages($messages: [MessageInput]) {
        readMessages(input: {messages: $messages}) {
            ok
        }
    }
`;

const GET_MESSAGES_QUERY = gql`
    query GetMessageQuery {
        getMessages {
            edges {
                node {
                    messageId
                    status
                    type
                    clientRead
                    clientArchived
                    expertRead
                    expertArchived
                    creationDate
                    subject
                    responseDate
                    reviewId
                    expert {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    client {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    exchanges {
                        edges {
                            node {
                                messageExchangeId
                                content
                                type
                                creationDate
                                attachments {
                                    edges {
                                        node {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        getMessagesGroupClient {
            edges {
                node {
                    messageGroupRecipientId
                    clientRead
                    clientArchived
                    client {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    messageGroup {
                        messageGroupId
                        creationDate
                        subject
                        content
                        expert {
                            id
                            profile {
                                displayName
                                pictureName
                            }
                        }
                        attachments {
                            edges {
                                node {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
        getMessagesGroupExpert {
            edges {
                node {
                    messageGroupId
                    expertArchived
                    expert {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    creationDate
                    subject
                    content
                    attachments {
                        edges {
                            node {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

type MessageBoxProps = {
    mode: EnumModeMessageBox;
};

export default function MessageBox(props: MessageBoxProps) {
    const {isDesktop} = useDeviceQuery();
    const [load, {called, loading, data, refetch}] = useLazyQuery<GetMessageQuery>(
        GET_MESSAGES_QUERY,
        {fetchPolicy: 'network-only', pollInterval: 3600000}
    );
    const [statisticsClientId, setStatisticsClientId] = React.useState<string | null>(null);
    const [modalStatisticsVisible, setModalStatisticsVisible] = React.useState(false);
    const [inboxFilter, setInboxFilter] = React.useState<InboxType>('received');

    const [message, setMessage] = React.useState<Message | null>(null);
    const _scrollView = useRef<ScrollView>(null);

    useFocusEffect(
        React.useCallback(() => {
            const requestId = requestAnimationFrame(reload);
            return () => cancelAnimationFrame(requestId);
        }, [inboxFilter])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
        setMessage(null);
        refreshScroll();
    }

    function filterBy(filterType: InboxType) {
        setInboxFilter(filterType);
        if (inboxFilter == filterType) {
            reload();
        }
    }

    function refreshScroll() {
        _scrollView?.current?.scrollTo({x: 0, y: 0, animated: false});
    }

    function onMessagePressed(item: Message) {
        setMessage(item);
        refreshScroll();
    }

    function onStatisticsClientPress(clientId: string) {
        setStatisticsClientId(clientId);
        setModalStatisticsVisible(true);
    }

    function onRequestCloseStatistics() {
        setModalStatisticsVisible(false);
    }

    function onReviewSuccess() {
        reload();
    }

    function onResponseSuccess() {
        filterBy('sent');
        reload();
    }

    function getExchangeType() {
        if (props.mode == EnumModeMessageBox.CLIENT) {
            return ExchangeTypeEnum.CLIENT_TO_EXPERT;
        } else if (props.mode == EnumModeMessageBox.EXPERT) {
            return ExchangeTypeEnum.EXPERT_TO_CLIENT;
        } else {
            return ExchangeTypeEnum.EXPERT_TO_CLIENT;
        }
    }

    function newMessage(item: Message | null) {
        if (item == null) {
            return false;
        }
        if (props.mode == EnumModeMessageBox.CLIENT) {
            return !item.clientRead;
        } else if (props.mode == EnumModeMessageBox.EXPERT) {
            return !item.expertRead;
        }
    }

    async function onSuccessFreeMessage() {
        filterBy('sent');
    }

    function onCancelFreeMessage() {
        filterBy('sent');
    }

    if (data) {
        const messagesData = mergeMessages(data);
        const nbNewMessages = messagesData.filter(newMessage).length ?? 0;
        const exchangeType = getExchangeType();

        return (
            <MainView ref={_scrollView}>
                <MessageHeader mode={props.mode} />
                <View style={isDesktop ? styles.container : mobileStyles.container}>
                    <InboxNavigation
                        nbNewMessages={nbNewMessages}
                        inboxFilter={inboxFilter}
                        onFilterPress={filterBy}
                        isClient={exchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT}
                    />
                    <FreeMessage
                        visible={inboxFilter == 'free-message'}
                        onSuccess={onSuccessFreeMessage}
                        onCancel={onCancelFreeMessage}
                    />
                    {message && (
                        <Exchange
                            message={message}
                            onResponseSuccess={onResponseSuccess}
                            onReviewSuccess={onReviewSuccess}
                            exchangeType={exchangeType}
                            onStatisticsClientPress={onStatisticsClientPress}
                        />
                    )}
                    {!message && messagesData && (
                        <MessageList
                            messages={messagesData}
                            inboxType={inboxFilter}
                            onMessagePressed={onMessagePressed}
                            mode={props.mode}
                            onMessagesDeleted={reload}
                            onMessagesRead={reload}
                        />
                    )}
                </View>
                <PopupStatistics
                    visible={modalStatisticsVisible}
                    onRequestClose={onRequestCloseStatistics}
                    clientId={statisticsClientId}
                />
            </MainView>
        );
    }

    return <ScreenLoader />;
}

function InboxNavigation(props: InboxNavigationProps) {
    const {isDesktop} = useDeviceQuery();
    return isDesktop ? (
        <DesktopInboxNavigation {...props} />
    ) : (
        <MobileInboxNavigation {...props} />
    );
}

function DesktopInboxNavigation({
    inboxFilter,
    onFilterPress,
    isClient,
    nbNewMessages,
}: InboxNavigationProps) {
    const [popupMessageVisible, setPopupMessageVisible] = useState(false);

    function openPopupMessage() {
        setPopupMessageVisible(true);
    }

    function closePopupMessage() {
        setPopupMessageVisible(false);
    }

    return (
        <View style={styles.filterContainer}>
            <View>
                <View style={styles.filterTitle}>
                    <Icons.Down size={15} color={Colors.link} />
                    <SimpleText style={styles.textTitle}>Emails</SimpleText>
                    {nbNewMessages > 0 && (
                        <SimpleText style={styles.newMessage}>{nbNewMessages}</SimpleText>
                    )}
                </View>
                <MessageFilter
                    isSelected={inboxFilter == 'received'}
                    onPress={() => onFilterPress('received')}
                    text={'Reçus'}
                    icon={
                        <Icons.MailSelectable
                            isSelected={inboxFilter == 'received'}
                            style={{alignSelf: 'center'}}
                            size={18}
                        />
                    }
                />
                <MessageFilter
                    isSelected={inboxFilter == 'sent'}
                    onPress={() => onFilterPress('sent')}
                    text={'Envoyés'}
                    icon={
                        <Icons.MailSendSelectable
                            isSelected={inboxFilter == 'sent'}
                            style={{alignSelf: 'center'}}
                            size={18}
                        />
                    }
                />
                <MessageFilter
                    isSelected={inboxFilter == 'trash'}
                    onPress={() => onFilterPress('trash')}
                    text={'Supprimés'}
                    icon={
                        <Icons.TrashSelectable
                            isSelected={inboxFilter == 'trash'}
                            style={{alignSelf: 'center', paddingLeft: 1}}
                            size={20}
                        />
                    }
                />
                {isClient && (
                    <Button
                        title="Envoyer un message de suivi"
                        icon={<Icons.MailSend size={12} />}
                        onPress={() => onFilterPress('free-message')}
                        titleStyle={{fontSize: 12, paddingLeft: 10}}
                        containerStyle={{alignSelf: 'center', marginTop: 50}}
                        buttonStyle={{
                            borderRadius: 5,
                            paddingVertical: 10,
                            paddingHorizontal: 30,
                        }}
                    />
                )}
                {!isClient && (
                    <>
                        <Button
                            title="Envoyer un message"
                            icon={<Icons.MailSend size={12} />}
                            onPress={openPopupMessage}
                            titleStyle={{fontSize: 12, paddingLeft: 10}}
                            containerStyle={{alignSelf: 'center', marginTop: 50}}
                            buttonStyle={{
                                borderRadius: 5,
                                paddingVertical: 10,
                                paddingHorizontal: 30,
                            }}
                        />
                        <PopupMessageFromExpert
                            visible={popupMessageVisible}
                            onRequestClose={closePopupMessage}
                        />
                    </>
                )}
            </View>
        </View>
    );
}

function MessageFilter(props: MessageFilterProps) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    let selectedStyle = {};
    let borderStyle = deviceStyles.borderUnselected;

    if (props.isSelected) {
        selectedStyle = deviceStyles.textSelected;
        borderStyle = deviceStyles.borderSelected;
    }

    return (
        <View>
            <TouchableOpacity
                onPress={props.onPress}
                style={[deviceStyles.touchFilter, selectedStyle, borderStyle]}
            >
                {props.icon}
                {isDesktop && (
                    <SimpleText style={[deviceStyles.textFilter, selectedStyle]}>
                        {props.text}
                    </SimpleText>
                )}
            </TouchableOpacity>
        </View>
    );
}

function MessageList(props: MessageListProps) {
    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;
    const confirm = useConfirm();
    const [selectedMessages, setSelectedMessages] = React.useState<MessageInput[]>([]);
    const [trashMessages, {data: dataTrash, error: errorTrash}] = useMutation(TRASH_MESSAGES, {
        errorPolicy: 'all',
    });
    const [readMessages, {data: dataRead, error: errorRead}] = useMutation(READ_MESSAGES, {
        errorPolicy: 'all',
    });
    const [checked, setChecked] = React.useState(false);

    React.useEffect(() => {
        setSelectedMessages([]);
        setChecked(false);
    }, [props.inboxType]);

    async function onPressMessage(message: Message) {
        const data = {
            messages: [{id: message.messageId, group: message.groupInternal}],
        };
        readMessages({variables: data});
        props.onMessagePressed(message);
    }

    async function onPressDeleteMessage(message: Message) {
        confirm({
            message: 'Voulez-vous vraiment supprimer ce message ?',
            onYes: () => deleteMessage(message),
        });
    }

    async function deleteMessage(message: Message) {
        const data = {
            messages: [{id: message.messageId, group: message.groupInternal}],
        };
        await trashMessages({variables: data});
        setSelectedMessages([]);
        props.onMessagesDeleted();
    }

    async function deleteAllSelectedMessages() {
        const data = {
            messages: selectedMessages,
        };
        const result = await trashMessages({variables: data});
        setSelectedMessages([]);
        props.onMessagesDeleted();
    }

    async function readAllSelectedMessages() {
        const data = {
            messages: selectedMessages,
        };
        const result = await readMessages({variables: data});
        setSelectedMessages([]);
        props.onMessagesRead();
    }

    async function confirmDeleteAllSelectedMessages() {
        confirm({
            message: `Voulez-vous vraiment supprimer ce(s) ${selectedMessages.length} message(s) ?`,
            onYes: deleteAllSelectedMessages,
        });
    }

    function onPressCheckBoxMessage(id: string, group: boolean) {
        if (selectedMessages.some((s) => s.id == id)) {
            const newSelectedMessages = selectedMessages.filter(function (value) {
                return value.id != id;
            });
            setSelectedMessages(newSelectedMessages);
        } else {
            const newSelectedMessages = [...selectedMessages, {id: id, group: group}];
            setSelectedMessages(newSelectedMessages);
        }
    }

    function onPressCheckBoxAll() {
        if (!checked) {
            const filter = filterMessage(props.inboxType, props.mode);
            const filteredData = props.messages.filter(filter);
            let newSelectedMessages = [];
            for (let index = 0; index < filteredData.length; index++) {
                const message = filteredData[index];
                if (message) {
                    newSelectedMessages.push({
                        id: message.messageId,
                        group: message.groupInternal,
                    });
                }
            }
            setSelectedMessages(newSelectedMessages);
        } else {
            setSelectedMessages([]);
        }

        setChecked(!checked);
    }

    function renderRow({item}: {item: Message}) {
        const selected = selectedMessages.some((s) => s.id == item.messageId);
        return (
            <MessageRow
                message={item}
                selected={selected}
                mode={props.mode}
                showTrashAction={props.inboxType != 'trash'}
                onPressDeleteMessage={() => onPressDeleteMessage(item)}
                onPressMessage={() => onPressMessage(item)}
                onPressCheckBoxMessage={() =>
                    onPressCheckBoxMessage(item.messageId, item.groupInternal)
                }
            />
        );
    }

    if (!MESSAGE_LIST_TYPES.has(props.inboxType)) {
        return null;
    }

    const filter = filterMessage(props.inboxType, props.mode);
    const filteredData = props.messages.filter(filter);
    const list = sorted(filteredData, getLastExchangeFromMessage, true);
    const noMessage = list.length == 0;
    const inboxTypeLabel = getMessageTypeLabel(props.inboxType);

    if (noMessage) {
        return (
            <SimpleText style={deviceStyles.noMessage}>
                Vous n'avez aucun message {inboxTypeLabel}.
            </SimpleText>
        );
    }

    const messageViewStyle = isDesktop ? styles.messages : mobileStyles.messages;

    return (
        <View style={messageViewStyle}>
            <MessageListHeader
                checked={checked}
                inboxTypeLabel={`${inboxTypeLabel}s`}
                showActions={selectedMessages.length > 0}
                showTrashAction={props.inboxType != 'trash'}
                onPressCheckBoxAll={onPressCheckBoxAll}
                onReadActionPress={readAllSelectedMessages}
                onTrashActionPress={confirmDeleteAllSelectedMessages}
            />
            <FlatList
                data={list}
                renderItem={renderRow}
                keyExtractor={(item) => item.messageId}
                // style={styles.listContainer}
            />
        </View>
    );
}

function MessageListHeader(props: MessageListHeaderProps) {
    const {isDesktop} = useDeviceQuery();
    if (isDesktop) return <DesktopListHeaderTitle {...props} />;
    else return <MobileListHeaderTitle {...props} />;
}

function DesktopListHeaderTitle(props: MessageListHeaderProps) {
    return (
        <View style={styles.headerContainer}>
            <View style={styles.checkboxContainer}>
                <CheckBox
                    checked={props.checked}
                    onPress={props.onPressCheckBoxAll}
                    containerStyle={{
                        backgroundColor: Colors.dark,
                        borderWidth: 0,
                    }}
                />
                <SimpleText style={styles.checkboxLabel}>Tout sélectionner</SimpleText>
            </View>
            {props.showActions && (
                <>
                    <TouchableOpacity onPress={props.onReadActionPress}>
                        <Icons.Mail
                            style={{alignSelf: 'center', paddingTop: 13}}
                            size={26}
                            color={'black'}
                        />
                    </TouchableOpacity>
                    {props.showTrashAction && (
                        <TouchableOpacity onPress={props.onTrashActionPress}>
                            <Icons.Trash
                                style={{alignSelf: 'center', paddingTop: 13, paddingLeft: 25}}
                                size={26}
                                color={'black'}
                            />
                        </TouchableOpacity>
                    )}
                </>
            )}
        </View>
    );
}

function MessageRow(props: MessageRowProps) {
    const {isDesktop} = useDeviceQuery();
    return isDesktop ? <DesktopMessageRow {...props} /> : <MobileMessageRow {...props} />;
}

function DesktopMessageRow(props: MessageRowProps) {
    const profile = getProfile(props.message, props.mode);
    const open = isOpen(props.message, props.mode);
    const typeLabel = getTypeLabel(props.message.type, props.message.groupVisible);
    const exchange = getLastExchange(props.message);
    const date = new Date(exchange.creationDate);
    const readStyle = open ? styles.read : styles.notRead;

    return (
        <HoverableView style={styles.hoverableContainer} hoverStyle={styles.messageHover}>
            <View style={styles.messageContainer}>
                <View style={styles.leftContainer}>
                    <TouchableOpacity
                        style={styles.pictureContainer}
                        onPress={props.onPressMessage}
                    >
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={profile?.pictureName}
                            displayName={profile?.displayName}
                        />
                    </TouchableOpacity>
                    <View style={styles.pictoContainer}>
                        <CheckBox
                            checked={props.selected}
                            onPress={props.onPressCheckBoxMessage}
                            containerStyle={{borderWidth: 0, margin: 0, padding: 0}}
                            style={{margin: 0, padding: 0}}
                            textStyle={{margin: 0, padding: 0}}
                        />
                        {!open && (
                            <Icons.Mail
                                size={15}
                                color={'black'}
                                style={{alignSelf: 'center'}}
                            />
                        )}
                        {open && (
                            <Icons.MailOpen
                                size={15}
                                color={'black'}
                                style={{alignSelf: 'center'}}
                            />
                        )}
                    </View>
                </View>
                <TouchableOpacity
                    style={styles.rightContainer}
                    containerStyle={{flex: 1}}
                    onPress={props.onPressMessage}
                >
                    <View style={styles.messageHeaderContainer}>
                        <SimpleText style={[styles.expertName, readStyle]}>
                            {profile?.displayName}
                        </SimpleText>
                        <View style={styles.dateTypeContainer}>
                            <SimpleText style={styles.type}>{typeLabel}</SimpleText>
                            <SimpleText style={[styles.date, readStyle]}>
                                le {dates.dateString(date)} à {dates.timeString(date)}
                            </SimpleText>
                        </View>
                    </View>
                    <SimpleText style={[styles.subject, readStyle]}>
                        {props.message.subject}
                    </SimpleText>
                    <SimpleText numberOfLines={3} style={[styles.content, readStyle]}>
                        {exchange.content}
                    </SimpleText>
                </TouchableOpacity>
            </View>
        </HoverableView>
    );
}

export function filterMessage(inboxType: InboxType, mode: EnumModeMessageBox) {
    function filterFunction(message: Message) {
        const lastExchangeType = getLastExchange(message).type;
        let isArchived, isFromMe;

        if (mode == EnumModeMessageBox.CLIENT) {
            isFromMe = lastExchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT;
            isArchived = message.clientArchived;
        } else {
            isFromMe = lastExchangeType == ExchangeTypeEnum.EXPERT_TO_CLIENT;
            isArchived = message.expertArchived;
        }
        if (inboxType == 'trash') {
            return isArchived;
        }
        if (inboxType == 'received') {
            return !isArchived && !isFromMe;
        }
        if (inboxType == 'sent') {
            return !isArchived && isFromMe;
        }
    }
    return filterFunction;
}

export function getMessageTypeLabel(inboxType: InboxType) {
    if (inboxType) {
        return INBOX_TYPE_LABEL.get(inboxType);
    } else {
        console.error("The inbox type doesn't have any label", inboxType);
    }
}

export function isOpen(message: Message, mode: EnumModeMessageBox) {
    if (mode == EnumModeMessageBox.CLIENT) {
        return message.clientRead;
    } else if (mode == EnumModeMessageBox.EXPERT) {
        return message.expertRead;
    }
}

export function getProfile(message: Message, mode: EnumModeMessageBox) {
    if (mode == EnumModeMessageBox.CLIENT) {
        return message.expert;
    } else if (mode == EnumModeMessageBox.EXPERT) {
        return message.client;
    }
}

const MESSAGE_LIST_TYPES = new Set<InboxType>(['received', 'sent', 'trash']);
export type FilterType = 'received' | 'sent' | 'trash';
export type InboxType = FilterType | 'free-message' | null;
const INBOX_TYPE_LABEL = new Map([
    ['received', 'reçu'],
    ['sent', 'envoyé'],
    ['trash', 'supprimé'],
]);

export type MessageRowProps = {
    onPressMessage: () => void;
    onPressCheckBoxMessage: () => void;
    onPressDeleteMessage?: () => void;
    message: Message;
    selected: boolean;
    mode: EnumModeMessageBox;
    showTrashAction: boolean;
};

export type MessageListProps = {
    messages: Message[];
    inboxType: InboxType;
    mode: EnumModeMessageBox;
    onMessagePressed: (item: Message) => void;
    onMessagesRead: () => void;
    onMessagesDeleted: () => void;
};

export type InboxNavigationProps = {
    inboxFilter: InboxType;
    onFilterPress: (filterType: InboxType) => void;
    isClient: boolean;
    nbNewMessages: number;
};

export type MessageFilterProps = {
    text: string;
    isSelected: boolean;
    onPress: () => void;
    icon: React.ReactNode;
};

export type MessageListHeaderProps = {
    checked: boolean;
    inboxTypeLabel?: string;
    showActions: boolean;
    showTrashAction: boolean;
    onPressCheckBoxAll: () => void;
    onReadActionPress: () => void;
    onTrashActionPress: () => void;
};

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        borderColor: Colors.separator,
        borderWidth: 1,
        padding: 20,
        backgroundColor: Colors.dark,
    },
    checkboxContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    checkboxLabel: {
        paddingTop: 18,
    },
    noMessage: {
        padding: 17,
        flex: 1,
        borderColor: Colors.separator,
        borderWidth: 1,
        marginLeft: 20,
    },
    messages: {
        flex: 1,
        flexDirection: 'column',
        marginLeft: 20,
    },
    textSelected: {
        color: Colors.primary,
        backgroundColor: 'white',
    },
    borderUnselected: {
        borderColor: Colors.dark,
        borderLeftWidth: 5,
    },
    borderSelected: {
        borderColor: Colors.primary,
        borderLeftWidth: 5,
    },
    filterContainer: {
        backgroundColor: Colors.dark,
    },
    filterTitle: {
        flex: 1,
        flexDirection: 'row',
        padding: 15,
    },
    textTitle: {
        marginLeft: 20,
        fontSize: 15,
        fontWeight: 'bold',
        color: Colors.link,
    },
    newMessage: {
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 1,
        minWidth: 30,
        marginLeft: 20,
        fontSize: 12,
        borderRadius: 10,
        color: 'white',
        backgroundColor: Colors.errors,
        textAlign: 'center',
    },
    messageFilterContainer: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    touchFilter: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 50,
        paddingBottom: 10,
    },
    textFilter: {
        marginLeft: 20,
        fontSize: 15,
    },
    listContainer: {
        marginLeft: 20,
    },
    hoverableContainer: {},
    messageContainer: {
        flex: 1,
        flexDirection: 'row',
        borderColor: Colors.separator,
        borderWidth: 1,
    },
    messageHover: {
        backgroundColor: Colors.dark,
    },
    leftContainer: {},
    pictureContainer: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
    },
    pictoContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
    },
    profilePicture: {
        width: 37,
        height: 37,
        borderRadius: 40,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 15,
        alignSelf: 'center',
    },
    rightContainer: {
        flex: 1,
        flexDirection: 'column',
        marginLeft: 15,
        padding: 15,
    },
    messageHeaderContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    read: {},
    notRead: {
        fontWeight: 'bold',
    },
    expertName: {
        fontSize: 14,
    },
    dateTypeContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    date: {
        fontSize: 14,
        width: 150,
    },
    type: {
        backgroundColor: Colors.highlight,
        color: 'white',
        borderRadius: 5,
        fontSize: 12,
        paddingLeft: 5,
        paddingRight: 5,
        height: 16,
        marginRight: 25,
        width: 120,
        textAlign: 'center',
    },
    subject: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5,
    },
    content: {
        fontSize: 12,
    },
});
