import React from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';

import {useNavigation} from '@react-navigation/core';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function CguScreen() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();
    function home() {
        navigation.navigate('HomeScreen');
    }
    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <MainView style={deviceStyles.container}>
            <CguHOneTitle />
            <CguText />
        </MainView>
    );
}

export function CguHOneTitle() {
    return (
        <SimpleText style={styles.title} accessibilityRole="header" aria-level="1">
            CONDITIONS GÉNÉRALES D'UTILISATION
        </SimpleText>
    );
}

export function CguText() {
    return (
        <>
            <SimpleText style={styles.chapter}>PREAMBULE</SimpleText>
            <SimpleText style={styles.text}>
                Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du
                21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est
                porté à la connaissance des Utilisateurs du site kyvoitou.fr les présentes
                mentions légales.
            </SimpleText>
            <SimpleText style={styles.text}>
                La connexion et la navigation sur le site kyvoitou.fr par l’Utilisateur
                implique acceptation intégrale et sans réserve des présentes modalités.
            </SimpleText>
            <SimpleText style={styles.text}>
                Ces dernières sont accessibles sur le site à la rubrique « Mentions légales ».
            </SimpleText>
            <SimpleText style={styles.article}>
                1. Conditions générales d’utilisation du site et des services proposés
            </SimpleText>
            <SimpleText style={styles.text}>
                L’utilisation du kyvoitou.fr implique l’acceptation pleine et entière des
                conditions générales d’utilisation ci-après décrites. Ces conditions
                d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment,
                les utilisateurs du site kyvoitou.fr sont donc invités à les consulter de
                manière régulière.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le site est accessible par tout endroit, 7j/7, 24h/24, sauf cas de force
                majeure, interruption programmée ou non et pouvant découlant d’une nécessité de
                maintenance.
            </SimpleText>
            <SimpleText style={styles.text}>
                En cas de modification, interruption ou suspension des services, le site
                kyvoitou.fr ne saurait être tenu responsable.
            </SimpleText>
            <SimpleText style={styles.article}>2. Propriété intellectuelle</SimpleText>
            <SimpleText style={styles.text}>
                Tous les textes présents sur le site internet kyvoitou.fr ont été rédigés par
                les soins de la responsable de la rédaction. Camille Guilbert et Franky Mulliez
                sont propriétaires des droits de propriété intellectuelle ou détiennent les
                droits d’usage sur tous les éléments accessibles sur le site, notamment les
                textes, images, graphismes, logo, icônes, sons, logiciels.
            </SimpleText>
            <SimpleText style={styles.text}>
                Toute reproduction, représentation, modification, publication, adaptation de
                tout ou partie des éléments du site, quel que soit le moyen ou le procédé
                utilisé, sauf autorisation écrite préalable Camille et / ou Franky fera l’objet
                d’un avertissement au(x) webmaster(s) concerné(s). En l’absence de retrait
                dudit contenu, seront prises toutes les mesures nécessaires pour sauvegarder la
                propriété intellectuelle. Ces mesures, sans être exhaustives, peuvent aller du
                signalement à Google, à l’hébergeur, au constat d’huissier, ou à une action en
                justice.
            </SimpleText>
            <SimpleText style={styles.article}>
                3. Collecte et gestion des données personnelles
            </SimpleText>
            <SimpleText style={styles.text}>
                En France, les données personnelles sont notamment protégées par la loi n°
                78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004 et l’article L.
                226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
            </SimpleText>
            <SimpleText style={styles.text}>
                À l’occasion de l’utilisation du site kyvoitou.fr, peuvent être recueillies :
                l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au site
                kyvoitou.fr, le fournisseur d’accès de l’utilisateur et l’adresse de protocole
                Internet (IP) de l’utilisateur.
            </SimpleText>
            <SimpleText style={styles.text}>
                L’utilisateur fournit ces informations en toute connaissance de cause,
                notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à
                l’utilisateur du site kyvoitou.fr l’obligation ou non de fournir ces
                informations.
            </SimpleText>
            <SimpleText style={styles.text}>
                Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6
                janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout
                utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux
                données personnelles le concernant, en effectuant sa demande écrite et signée,
                accompagnée d’une copie du titre d’identité avec signature du titulaire de la
                pièce, en précisant l’adresse à laquelle la réponse doit être envoyée. Le site
                n’est pas déclaré à la CNIL conformément à la nouvelle réglementation RGPD,
                vous pouvez consulter les mentions légales du site et faire valoir vos droits
                via les outils mis en place sur le site à cet effet.
            </SimpleText>
            <SimpleText style={styles.article}>4. Limitations de responsabilité</SimpleText>
            <SimpleText style={styles.text}>
                Camille GUILBERT ou Franky Mulliez ne pourront être tenues responsables des
                dommages directs et indirects causés au matériel de l’utilisateur, lors de
                l’accès au site kyvoitou.fr
            </SimpleText>
            <SimpleText style={styles.text}>
                Camille GUILBERT ou Franky Mulliez se réservent le droit de supprimer, sans
                mise en demeure préalable, tout contenu déposé sur le site kyvoitou.fr qui
                contreviendrait à la législation applicable en France, en particulier aux
                dispositions relatives à la protection des données. Le cas échéant, Camille
                GUILBERT ou Franky Mulliez se réservent également la possibilité de mettre en
                cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas
                de message à caractère raciste, injurieux, diffamant, ou pornographique, quel
                que soit le support utilisé (texte, photographie...).
            </SimpleText>
            <SimpleText style={styles.article}>5. Liens hypertextes et cookies</SimpleText>
            <SimpleText style={styles.text}>
                Le site kyvoitou.fr contient un certain nombre de liens hypertextes vers
                d’autres sites. Cependant, Camille GUILBERT ET Franky Mulliez n’ont pas la
                possibilité de vérifier le contenu des sites ainsi visités, et n’assumeront en
                conséquence aucune responsabilité de ce fait.
            </SimpleText>
            <SimpleText style={styles.text}>
                La navigation sur le kyvoitou.fr est susceptible de provoquer l’installation de
                cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite
                taille, qui ne permet pas l’identification de l’utilisateur, mais qui
                enregistre des informations relatives à la navigation d’un ordinateur sur un
                site. Les données ainsi obtenues visent à faciliter la navigation ultérieure
                sur le site, et ont également vocation à permettre diverses mesures de
                fréquentation.
            </SimpleText>
            <SimpleText style={styles.text}>
                En naviguant sur le site, l’Utilisateur accepte les cookies. Leur désactivation
                peut s’effectuer via les paramètres du logiciel de navigation. Certaines
                parties de ce site ne peuvent être fonctionnelles sans l’acceptation de
                cookies. Les informations collectées sont et resteront anonymes.
            </SimpleText>
            <SimpleText style={styles.article}>
                6. Droit applicable et attribution de juridiction
            </SimpleText>
            <SimpleText style={styles.text}>
                Tout litige en relation avec l’utilisation du site kyvoitou.fr est soumis au
                droit français quel que soit le lieu d’utilisation. En cas de contestation
                éventuelle, et après l’échec de toute tentative de recherche d’une solution
                amiable, les tribunaux français seront seuls compétents pour répondre de ce
                litige. En l’espèce, seul le Tribunal de Paris est compétent.
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour toute question relative aux présentes conditions d’utilisation du site,
                vous pouvez écrire à l’adresse suivante : {Settings.contact_mail} ou Terre de
                Pompiac 47330 CASTILLONNES
            </SimpleText>
        </>
    );
}

const styles = StyleSheet.create({
    container: {},
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 50,
    },
    chapter: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 30,
    },
    article: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 15,
    },
    underline: {
        fontSize: 14,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
    },
    bold: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    italic: {
        fontSize: 12,
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    text: {
        fontSize: 12,
        marginVertical: 5,
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    tab: {
        fontSize: 12,
        fontWeight: 'bold',
        width: 200,
        alignSelf: 'center',
    },
    definition: {
        fontSize: 12,
        marginVertical: 5,
        width: 1166,
    },
    address: {
        fontSize: 14,
        alignSelf: 'center',
        marginVertical: 10,
    },
    center: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    list: {
        fontSize: 12,
        marginVertical: 5,
        marginLeft: 5,
    },
    sub: {
        fontSize: 13,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 20,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        paddingHorizontal: 10,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 50,
    },
    chapter: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 30,
    },
    article: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 15,
    },
    underline: {
        fontSize: 14,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
    },
    bold: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    italic: {
        fontSize: 12,
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    text: {
        fontSize: 12,
        marginVertical: 5,
    },
    line: {},
    tab: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    definition: {
        fontSize: 12,
        marginVertical: 5,
    },
    address: {
        fontSize: 14,
        alignSelf: 'center',
        marginVertical: 10,
    },
    center: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    list: {
        fontSize: 12,
        marginVertical: 5,
        marginLeft: 5,
    },
    sub: {
        fontSize: 13,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 20,
    },
});
