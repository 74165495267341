import React, {useState, useImperativeHandle, useEffect, useRef} from 'react';
import {
    Modal,
    Pressable,
    StyleSheet,
    Animated,
    NativeModules,
    LayoutAnimation,
    Text,
    TouchableOpacity,
    View,
    ViewProps,
} from 'react-native';

import {gql, useQuery} from '@apollo/client';

import * as Icons from '~/components/common/Icons';
import CustomModal from '~/components/common/Modal';
import {CenterText, SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAudio} from '~/contexts/AudioPlayer';
import logEvent from '~/helpers/analytics';
import {getBenefit, getCurrentMonthBenefit} from '~/helpers/experts';
import {
    terminateExpertCall,
    CallSubscriptionParams,
    checkCallExpert,
} from '~/helpers/twilio/clickToCall';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {CallStatusEnum} from '~/types/graphql-global-types';

import {ProfilePicture} from '../common/Images';
import {CommunicationType} from '../user/client/review/types/calls';
import PopupMemo from './PopupMemo';
import {
    CallStatusClientQuery,
    CallStatusClientQuery_user,
} from './types/CallStatusClientQuery';

const {UIManager} = NativeModules;
UIManager.setLayoutAnimationEnabledExperimental &&
    UIManager.setLayoutAnimationEnabledExperimental(true);

const ANIMATION_DURATION = 400;
const TIMEOUT_REFETCH = 5000;

export type CallEventData = {
    callSid: string;
    code: CallStatusEnum;
    message: string;
    clientId?: string;
    callDuration?: number;
    callCost?: number;
    usePack?: boolean;
    offer?: string;
};

export type onMessageArguments = (data: CallEventData) => void;

export type CallStatusProps = Modal['props'] & {
    onMessage?: onMessageArguments;
    onCallStart?: () => void;
    onCallComplete?: (callDuration: number) => void;
};

export type ExpertCallStatusHandles = {
    endCall?: () => void;
    newCall?: (data: CallSubscriptionParams) => void;
};

const ExpertCallStatus = React.forwardRef<ExpertCallStatusHandles, CallStatusProps>(
    (props, ref) => {
        const {isDesktop} = useDeviceQuery();
        const {play} = useAudio();
        const [mobileInfoVisible, setMobileInfoVisible] = useState(false);
        const {onMessage, onCallStart, onCallComplete, ...modalProps} = props;
        const [clientId, setClientId] = useState<string | null>(null);
        const [client, setClient] = useState<CallStatusClientQuery_user | null | undefined>(
            null
        );
        const [callStatus, setCallStatus] = useState<CallStatusEnum>(CallStatusEnum.STARTED);
        const [callDuration, setCallDuration] = useState<number | null>(null);
        const [callCost, setCallCost] = useState<number>(0);
        const [pack, setPack] = useState<boolean>(false);
        const [offer, setOffer] = useState<string | null>(null);
        const [hasCallEnded, setHasCallEnded] = useState<boolean>(false);
        const [callStartDate, setCallStartDate] = useState<number | null>(null);
        const height = useRef(new Animated.Value(0)).current;
        const [isPopupMemoVisible, setIsPopupMemoVisible] = useState<boolean>(false);
        const [benefit, setBenefit] = useState<string | null>(null);
        const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
        const [error, setError] = useState<boolean>(false);
        const variables = {clientId};
        const {data} = useQuery<CallStatusClientQuery>(CALL_STATUS_CLIENT_QUERY, {
            variables,
            skip: clientId == null,
        });

        useEffect(() => {
            async function checkCall() {
                const data = await checkCallExpert();
                if (data.clientId != '') {
                    newCall(data);
                }
            }

            checkCall();
        }, []);

        React.useEffect(() => {
            if (data?.user) {
                setClient(data?.user);
            }
        }, [data]);

        useEffect(() => {
            async function updateBenefit() {
                const result = await getCurrentMonthBenefit();
                setBenefit(result);
            }

            if (callStatus == CallStatusEnum.FINISHED) {
                updateBenefit();
            }
        }, [callStatus]);

        async function _onCallInit() {
            setOffer(null);
            setCallStartDate(null);
            setCallDuration(null);
            setCallCost(0);
            setHasCallEnded(false);

            await play(require('../../assets/sound/ding.mp3'));
        }

        function _onCallStart(offer?: string) {
            setCallStartDate(+new Date());
            setCallDuration(null);
            setCallCost(0);
            setHasCallEnded(false);
            onCallStart && onCallStart();

            if (offer) {
                setOffer(offer);
            } else {
                setOffer(null);
            }
        }

        function _onCallComplete(
            actualCallDuration?: number,
            callCost?: number,
            usePack: boolean = false
        ) {
            actualCallDuration && setCallDuration(actualCallDuration);
            actualCallDuration && setHasCallEnded(true);
            callCost && setCallCost(callCost);
            setPack(usePack);
            onCallComplete && actualCallDuration && onCallComplete(actualCallDuration);
            setMobileInfoVisible(false);
        }

        async function _onMessage(message: string) {
            const messageData: CallEventData = JSON.parse(message);
            setCallStatus(messageData.code);
            await eventDispatch(messageData);
            onMessage && onMessage(messageData);
            if (messageData?.callDuration && messageData?.callDuration > 0) {
                var seconds = Math.floor(new Date().getTime() / 1000);
                var start = seconds - messageData.callDuration;
                setCallStartDate(start * 1000);
            }
        }

        async function retry() {
            try {
                const data = await checkCallExpert();
                if (data.clientId != '') {
                    newCall(data);
                }
                setError(false);
            } catch (e) {
                setTimeout(retry, TIMEOUT_REFETCH);
            }
        }

        async function onError() {
            if (!error) {
                setError(true);
                setTimeout(retry, TIMEOUT_REFETCH);
                webSocket?.close();
            }
        }

        async function subscribeExpert(callSid: string) {
            const uri = Settings.userCallSocket('expert', callSid);
            const ws = new WebSocket(uri);
            setWebSocket(ws);

            ws.onmessage = async function (event) {
                const message = event.data;
                _onMessage(message);
            };
            ws.onclose = function (event) {
                onError();
            };
            ws.onerror = function (event) {
                onError();
            };
        }

        async function eventDispatch(data: CallEventData) {
            switch (data.code) {
                case CallStatusEnum.STARTED:
                    await _onCallInit();
                    logEvent('call_initialized');
                    break;
                case CallStatusEnum.EXPERT_JOINED:
                    _onCallStart(data.offer);
                    logEvent('call_joined');
                    break;
                case CallStatusEnum.FINISHED:
                    _onCallComplete(data.callDuration, data.callCost, data.usePack);
                    logEvent('call_finished');
                    break;
                case CallStatusEnum.ERROR:
                    _onCallComplete(0, 0);
                    setCallStartDate(null);
                    logEvent('call_finished');
                    break;
                default:
                    break;
            }
        }

        /** Handles can be accessed by using a ref to the instance (useRef), then
         * simply using ref.callExpert(expertId) or ref.endCall() to respectively
         * initiate and terminate a call */
        useImperativeHandle(ref, () => ({endCall, newCall}));

        function endCall() {
            setOffer(null);
            setClientId(null);
            setCallDuration(null);
            setCallCost(0);
            setHasCallEnded(false);
            setCallStartDate(null);
            terminateExpertCall();
            modalProps.onRequestClose && modalProps.onRequestClose();
            logEvent('call_stopped');
        }

        function newCall(data: CallSubscriptionParams) {
            subscribeExpert(data.callSid);
            data.clientId && setClientId(data.clientId);
        }

        function openPopupNote() {
            setIsPopupMemoVisible(true);
        }

        useEffect(() => {
            const interval = setInterval(() => {
                if (!hasCallEnded && callStartDate) {
                    const duration = Math.round((+new Date() - callStartDate) / 1000);
                    setCallDuration(duration);
                }
            }, 1000);
            return () => clearInterval(interval);
        }, [hasCallEnded, callStartDate]);

        const visible = clientId != null;
        useEffect(() => {
            LayoutAnimation.spring();
            if (visible) {
                Animated.timing(height, {
                    toValue: 1,
                    duration: ANIMATION_DURATION,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(height, {
                    toValue: 0,
                    duration: ANIMATION_DURATION,
                    useNativeDriver: true,
                }).start();
            }
        }, [visible]);

        const statusProps = callStatusProps(callStatus);

        const statusStyle = {
            backgroundColor: statusProps.color,
            maxHeight: height.interpolate({inputRange: [0, 1], outputRange: [0, 100]}),
            padding: height.interpolate({inputRange: [0, 1], outputRange: [0, 20]}),
        };

        if (isDesktop) {
            return (
                <>
                    <Animated.View style={[styles.statusView, statusStyle]}>
                        <CenterText style={styles.label}>
                            <Icons.Phone size={30} style={{marginRight: 30}} />
                            <Text>{statusProps.text} : </Text>
                            <ClientInfoText
                                clientName={client?.profile?.displayName}
                                onNote={openPopupNote}
                            />
                            <CallDurationText callDuration={callDuration} separator={true} />
                            <CallCostText
                                callCost={callCost}
                                pack={pack}
                                duration={callDuration}
                                status={callStatus}
                            />
                            <BenefitText benefit={benefit} />
                        </CenterText>
                        <HangupButton
                            onClose={endCall}
                            style={{marginLeft: 'auto'}}
                            color={'black'}
                            text={"Terminer l'appel"}
                        />
                    </Animated.View>
                    <PopupMemo
                        clientId={clientId}
                        clientName={client?.profile?.displayName}
                        status={callStatus}
                        duration={callDuration}
                        cost={callCost}
                        pack={pack}
                        onRequestClose={() => setIsPopupMemoVisible(false)}
                        visible={isPopupMemoVisible}
                        offer={offer}
                    />
                </>
            );
        } else if (visible) {
            return (
                <>
                    <View
                        style={[
                            mobileStyles.containerStyle,
                            {backgroundColor: statusProps.color},
                        ]}
                    >
                        {callStatus == CallStatusEnum.STARTED && (
                            <SimpleText style={styles.label}>Initialisation...</SimpleText>
                        )}
                        <TouchableOpacity
                            onPress={() => setMobileInfoVisible(!mobileInfoVisible)}
                            style={{flexDirection: 'row'}}
                        >
                            {(callStatus == CallStatusEnum.EXPERT_JOINED ||
                                callStatus == CallStatusEnum.BOTH_PARTIES_JOINED) && (
                                <Icons.SortDown
                                    size={14}
                                    color={'white'}
                                    style={{marginRight: 5}}
                                />
                            )}
                            <CallTextMobile
                                callDuration={callDuration}
                                callCost={callCost}
                                pack={pack}
                                status={callStatus}
                            />
                        </TouchableOpacity>
                        <HangupButton
                            onClose={endCall}
                            style={{marginLeft: 'auto'}}
                            color={'white'}
                            text={'Terminer'}
                        />
                    </View>
                    <CustomModal
                        title={'Information sur votre appel'}
                        visible={mobileInfoVisible}
                        onRequestClose={() => {
                            setMobileInfoVisible(!mobileInfoVisible);
                        }}
                    >
                        <MobileClientInfoText client={client} />
                    </CustomModal>
                </>
            );
        } else {
            return <></>;
        }
    }
);

function callStatusProps(callStatus: CallStatusEnum) {
    switch (callStatus) {
        case CallStatusEnum.STARTED:
            return {color: Colors.light, text: "Initialisation de l'appel avec"};
        case CallStatusEnum.EXPERT_JOINED:
            return {color: Colors.connected, text: 'En communication avec'};
        case CallStatusEnum.BOTH_PARTIES_JOINED:
            return {color: Colors.connected, text: 'En communication avec'};
        case CallStatusEnum.FINISHED:
            return {color: Colors.highlight, text: 'Fin de communication avec'};
        case CallStatusEnum.ERROR:
            return {color: Colors.error, text: 'Appel terminé en erreur avec'};
        case CallStatusEnum.CANCELED:
            return {color: Colors.error, text: 'Appel terminé en erreur avec'};
        default:
            return {color: Colors.light, text: "Status de l'appel inconnu"};
    }
}

type ClientInfoTextProps = {
    clientName: string | null | undefined;
    onNote: () => void;
};

function ClientInfoText({clientName, onNote}: ClientInfoTextProps) {
    return (
        <>
            <SimpleText>{clientName}</SimpleText>
            <Pressable onPress={onNote} style={styles.client}>
                <Icons.Note size={16} color={'black'} />
                <SimpleText style={styles.notes}>Notes</SimpleText>
            </Pressable>
        </>
    );
}

type MobileClientInfoText = {
    client: CallStatusClientQuery_user | null | undefined;
};

function MobileClientInfoText({client}: MobileClientInfoText) {
    return (
        <View>
            <SimpleText style={mobileStyles.status}>
                Actuellement en communication avec {client?.profile?.displayName}
            </SimpleText>
            <ProfilePicture
                style={mobileStyles.profilePicture}
                pictureName={client?.profile?.pictureName}
                displayName={client?.profile?.displayName}
            />
        </View>
    );
}

function CallDurationText({callDuration, separator}: CallDurationTextProps) {
    if (!callDuration || callDuration < 0) return <SimpleText> </SimpleText>;

    const seconds = callDuration % 60;
    const minutes = Math.floor(callDuration / 60) % 60;
    const hours = Math.floor(callDuration / 3600);

    return (
        <SimpleText style={styles.statusText}>
            {separator && <Text style={styles.margin}>|</Text>}
            Durée de l'appel : {hours}h{minutes}m{seconds}s
        </SimpleText>
    );
}

function CallCostText({callCost, pack, duration, status}: CallCostTextProps) {
    if (status != CallStatusEnum.FINISHED) {
        return <SimpleText> </SimpleText>;
    }

    return (
        <SimpleText style={styles.statusText}>
            <Text style={styles.margin}>|</Text> Gain de cet appel :{' '}
            {getBenefit(callCost, pack, duration, CommunicationType.CALL).toFixed(2)}€
        </SimpleText>
    );
}

function CallTextMobile(props: CallTextMobileProps) {
    const callDuration = props.callDuration;
    const callCost = props.callCost;
    const pack = props.pack;
    const status = props.status;

    if (!callDuration || callDuration < 0) return <></>;

    const seconds = callDuration % 60;
    const minutes = Math.floor(callDuration / 60) % 60;
    const hours = Math.floor(callDuration / 3600);

    if (status != CallStatusEnum.FINISHED) {
        return (
            <SimpleText style={styles.statusText}>
                Durée de l'appel : {hours}h{minutes}m{seconds}s
            </SimpleText>
        );
    } else {
        return (
            <SimpleText style={styles.statusText}>
                Gain :{' '}
                {getBenefit(callCost, pack, callDuration, CommunicationType.CALL).toFixed(2)}€
                ({hours}h{minutes}m{seconds}s)
            </SimpleText>
        );
    }
}

type CallTextMobileProps = {
    callCost: number;
    callDuration: number | null | undefined;
    pack: boolean;
    status: CallStatusEnum;
};

function BenefitText({benefit}: BenefitTextProps) {
    if (benefit == null) return <></>;

    return (
        <SimpleText style={styles.statusText}>
            <Text style={styles.margin}>|</Text> Solde : {benefit}€
        </SimpleText>
    );
}

type HangupButtonProps = {
    color: string;
    text: string;
};

function HangupButton({
    onClose,
    color,
    text,
    ...viewProps
}: ActionsProps & ViewProps & HangupButtonProps) {
    return (
        <View {...viewProps}>
            <Pressable onPress={onClose}>
                <SimpleText style={[{color: color}, styles.hang]}>{text}</SimpleText>
            </Pressable>
        </View>
    );
}

const CALL_STATUS_CLIENT_QUERY = gql`
    query CallStatusClientQuery($clientId: ID!) {
        user(id: $clientId) {
            id
            profile {
                id
                displayName
                pictureName
            }
        }
    }
`;

type CallDurationTextProps = {
    callDuration: number | null;
    separator: boolean;
};

type CallCostTextProps = {
    callCost: number;
    pack: boolean;
    duration: number | null;
    status: CallStatusEnum;
};

type BenefitTextProps = {
    benefit: string | null;
};

type ActionsProps = {
    onClose?: () => void;
    onNote?: () => void;
};

const styles = StyleSheet.create({
    statusText: {
        color: 'white',
        fontWeight: '500',
    },
    statusView: {
        alignSelf: 'center',
        width: '100%',
        backgroundColor: Colors.primary,
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        alignItems: 'center',
        paddingHorizontal: 20,
    },
    text_container: {
        flex: 1,
        flexDirection: 'row',
        padding: 10,
    },
    exit_container: {
        flex: 1,
        flexDirection: 'row-reverse',
    },
    exit_button: {
        marginRight: 10,
        marginLeft: 10,
    },
    margin: {
        marginHorizontal: 30,
    },
    client: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 15,
    },
    notes: {
        textDecorationLine: 'underline',
        marginLeft: 5,
    },
    hang: {
        textDecorationLine: 'underline',
    },
    label: {
        color: 'white',
    },
});

const mobileStyles = StyleSheet.create({
    containerStyle: {
        position: 'absolute',
        top: 75,
        flexDirection: 'row',
        left: 0,
        right: 0,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    profilePicture: {
        borderRadius: 10,
        width: 150,
        height: 150,
        margin: 30,
        alignSelf: 'center',
    },
    status: {
        alignSelf: 'center',
        textAlign: 'center',
        margin: 20,
        fontSize: 24,
    },
});

export default ExpertCallStatus;
