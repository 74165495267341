import * as React from 'react';
import {StyleSheet, FlatList, View} from 'react-native';

import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {range} from '~/helpers/iterations';

export default function QualityList({qualities}: QualityListProps) {
    function renderItemQuality({item}: {item: Quality}) {
        function color(starIndex: number) {
            return starIndex < item.starNumber ? Colors.star : Colors.separator;
        }

        return (
            <View style={styles.qualityStyle}>
                <SimpleText style={[styles.qualityLabelStyle]}>{item.label}</SimpleText>
                <View style={styles.starContainer}>
                    <View style={[styles.starStyle]}>
                        {range(5).map((i) => (
                            <Icons.Star size={15} color={color(i)} key={`start_${i}`} />
                        ))}
                    </View>
                </View>
            </View>
        );
    }

    return (
        <View>
            <FlatList
                data={qualities}
                renderItem={renderItemQuality}
                keyExtractor={(item) => item.id}
            />
        </View>
    );
}

export type QualityListProps = {
    qualities: Qualities;
};
export type Qualities = Quality[];
export type Quality = {
    starNumber: number;
    label: string;
    id: string;
};

const styles = StyleSheet.create({
    qualityStyle: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 7,
    },
    qualityLabelStyle: {
        fontSize: 12,
        color: Colors.fade,
        minWidth: 200,
    },
    starContainer: {
        borderRadius: 4,
    },
    starStyle: {
        borderRadius: 4,
        flexDirection: 'row',
        flex: 1,
    },
});
