import {SimpleText, TextProps} from '../common/Texts';
import {styles} from './styles';

export default function StepTitle({selected, ...textProps}: TextProps & {selected?: boolean}) {
    return (
        <SimpleText
            accessibilityRole="header"
            aria-level="2"
            style={[styles.subtitle, selected ? styles.selectedSubtitle : {}]}
            {...textProps}
        />
    );
}
