import React from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';

import {useNavigation} from '@react-navigation/core';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export default function CgvScreen() {
    const {isDesktop} = useDeviceQuery();
    const navigation = useNavigation();
    function home() {
        navigation.navigate('HomeScreen');
    }
    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <MainView style={deviceStyles.container}>
            <SimpleText style={styles.title} accessibilityRole="header" aria-level="1">
                CONDITIONS GENERALES DE VENTE
            </SimpleText>
            <SimpleText style={styles.chapter}>PREAMBULE</SimpleText>
            <SimpleText style={styles.text}>
                <SimpleText style={styles.bold}>La société SAS – KYVOITOU - WZA</SimpleText>,
                société par actions simplifiée au capital de 202.000 euros dont le siège social
                est situé Terre de Pompiac, premier étage, 47330 Castillonnes et immatriculée
                au R.C.S. d’Agen sous le numéro 884 628 124 (ci-après «
                <SimpleText style={styles.italic}> KYVOITOU </SimpleText> ») est une société
                qui souhaite promouvoir le développement personnel par le biais du coaching
                divinatoire.
            </SimpleText>
            <SimpleText style={styles.text}>
                C’est dans ce contexte que KYVOITOU a conçu, développé et mis à disposition de
                consommateurs et non professionnels (ci-après le(s) «
                <SimpleText style={styles.italic}> Client(s) </SimpleText> ») une solution clé
                en main, désignée sous la marque KYVOITOU®, par laquelle elle propose des
                consultations de voyance en ligne payantes (ci-après les «
                <SimpleText style={styles.italic}> Services </SimpleText> ») à partir d’un site
                internet accessible à l’adresse suivante :
                <TouchableOpacity onPress={home}>
                    <SimpleText style={styles.bold}> https://www.kyvoitou.fr/home </SimpleText>
                </TouchableOpacity>
                (ci-après le « <SimpleText style={styles.italic}> Site Internet </SimpleText>{' '}
                »). Le Site Internet est hébergé par la société Heroku Inc., 650 7th Street,
                San Francisco, CA.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client souhaite bénéficier des Services proposés par KYVOITOU dans les
                conditions qui suivent.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 1. <SimpleText style={styles.underline}>DEFINITION</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Les termes débutant par une majuscule au sein des Conditions Générales, qu’ils
                soient utilisés au singulier ou au pluriel, auront la signification qui leur
                est donnée ci-après.
            </SimpleText>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« Client »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne toute personne physique, consommateur et non professionnel, ayant
                    atteint l’âge de la majorité légale et jouissant de ses pleines capacités,
                    inscrit sur le Site et ayant enregistré un moyen de paiement, qui se porte
                    acquéreur des Services.
                </SimpleText>
            </View>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« Commande »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne l’achat d’un Service par le Client à KYVOITOU sur le Site Internet.
                </SimpleText>
            </View>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« Compte Client »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne l’espace privé sécurisé accessible au Client sur le Site Internet
                    lui permettant d’accéder aux Services.
                </SimpleText>
            </View>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« Conditions Générales »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne l’ensemble des stipulations énoncées dans les présentes Conditions
                    Générales de Vente et qui peuvent être complétées par d’autres conditions
                    générales ou particulières ainsi que tout avenant qui viendrait les
                    compléter, les modifier ou s’y substituer, étant précisé que le préambule
                    en fait partie intégrante.
                </SimpleText>
            </View>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« KYVOITOU »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne la Société SAS – KYVOITOU – WZA, propriétaire du Site Internet et
                    prestataire des Services.
                </SimpleText>
            </View>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« Parties »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne KYVOITOU et le Client.
                </SimpleText>
            </View>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« Service »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne la prestation de consultation de voyance en ligne rendue à
                    destination du Client parmi celles proposées sur le Site Internet par
                    KYVOITOU.
                </SimpleText>
            </View>
            <View style={deviceStyles.line}>
                <SimpleText style={deviceStyles.tab}>« Site Internet »</SimpleText>
                <SimpleText style={deviceStyles.definition}>
                    désigne le site internet accessible à l’adresse suivante :
                    <TouchableOpacity onPress={home}>
                        <SimpleText style={styles.bold}>
                            {' '}
                            https://www.kyvoitou.fr/home
                        </SimpleText>
                    </TouchableOpacity>
                    .
                </SimpleText>
            </View>
            <SimpleText style={styles.article}>
                ARTICLE 2.{' '}
                <SimpleText style={styles.underline}>CHAMP D’APPLICATION</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Les présentes Conditions Générales s'appliquent, sans restriction ni réserve, à
                tout achat de Services proposés par KYVOITOU aux Clients sur son Site Internet.
            </SimpleText>
            <SimpleText style={styles.text}>
                Les caractéristiques principales des Services sont présentées sur le Site
                Internet. Le Client est tenu d'en prendre connaissance avant toute passation de
                Commande. Le choix et l'achat d'un Service est de la seule responsabilité du
                Client. Il appartient ainsi au Client de s’assurer de l’adéquation des Services
                à ses besoins propres, notamment sur la base des indications fournies dans la
                documentation et la présentation des Services, accessibles sur le Site
                Internet, dont il reconnait avoir pris connaissance avant toute Commande.
            </SimpleText>
            <SimpleText style={styles.text}>
                Ces Conditions Générales s'appliquent à l'exclusion de toutes autres
                conditions.
            </SimpleText>
            <SimpleText style={styles.text}>
                Elles sont susceptibles d'être complétées par des conditions particulières,
                énoncées sur le Site Internet, avant toute transaction avec le Client.
            </SimpleText>
            <SimpleText style={styles.text}>
                Ces Conditions Générales sont accessibles à tout moment en bas de chaque page
                du Site Internet et prévaudront, le cas échéant, sur toute autre version ou
                tout autre document contradictoire. Sauf preuve contraire, les données
                enregistrées dans le système informatique de KYVOITOU constituent la preuve de
                l'ensemble des transactions conclues avec le Client.
            </SimpleText>
            <SimpleText style={styles.text}>
                Conformément à la loi Informatique et Libertés du 6 janvier 1978, renforcée et
                complétée par le RGPD (Règlement général sur la protection des données) entré
                en vigueur le 25 mai 2018, le Client dispose, à tout moment, d'un droit
                d'accès, de rectification, d'opposition, d'effacement et de portabilité de
                l'ensemble de ses données personnelles en écrivant, par courrier et en
                justifiant de son identité, à
            </SimpleText>
            <View style={styles.address}>
                <SimpleText style={styles.center}>KYVOITOU</SimpleText>
                <SimpleText style={styles.center}>Terre de Pompiac</SimpleText>
                <SimpleText style={styles.center}>47330 Castillonnes</SimpleText>
            </View>
            <SimpleText style={styles.text}>
                Le Client déclare avoir pris connaissance des présentes Conditions Générales et
                les avoir acceptées en cochant la case prévue à cet effet avant la validation
                d’une Commande en ligne. La validation d’une Commande par le Client vaut
                acceptation sans restriction ni réserve des présentes Conditions Générales. Le
                Client déclare et certifie.
            </SimpleText>
            <SimpleText style={styles.list}>
                - Avoir atteint l’âge de la majorité légale ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - Jouissant de la pleine et entière capacité de discernement, étant capable
                juridiquement de contracter et d’utiliser les Services conformément aux
                présentes Conditions Générales ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - Avoir les capacités financières suffisantes pour bénéficier des prestations
                proposées sur ce Site, et ne faire l’objet d’aucune procédure de surendettement
            </SimpleText>
            <SimpleText style={styles.text}>
                Ces Conditions Générales pouvant faire l'objet de modifications ultérieures, la
                version applicable à l'achat du Client est celle en vigueur sur le Site
                Internet à la date de passation par le Client d’une Commande. Les Services
                présentés sur le Site Internet sont proposés à la vente pour la France et en
                langue française.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 3. <SimpleText style={styles.underline}>ACCES AUX SERVICES</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                L’achat de tout Service s’effectue en ligne sur le site à l’issue d’une
                procédure d’identification du Client puis d’enregistrement d’un moyen de
                paiement.
            </SimpleText>
            <SimpleText style={styles.sub}>
                3.1. Inscription et création d’un Compte Client
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client a la possibilité de s’inscrire gratuitement sur le Site Internet en
                cliquant sur l’encart « Inscription ». Il renseigne un identifiant de son
                choix, son genre, une adresse téléphonique, une adresse e-mail et le mot de
                passe de son choix.
            </SimpleText>
            <SimpleText style={styles.text}>
                Afin de créer son Compte Client, le Client doit accepter les conditions
                générales d’utilisation en cochant la case prévue à cet effet.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client peut modifier et/ou clôturer son Compte Client à tout moment.
            </SimpleText>
            <SimpleText style={styles.text}>
                Il appartient au Client de vérifier la validité de l'adresse e-mail communiquée
                dans le formulaire d'inscription dans la mesure où cet e-mail est un élément
                d'identification du Compte Client. En cas d'adresse e-mail erronée, le Client
                ne sera pas en mesure en mesure d'accéder à son compte sans que la
                responsabilité en incombe à KYVOITOU.
            </SimpleText>
            <SimpleText style={styles.text}>
                Il appartient au Client de vérifier la validité du numéro de téléphone
                communiqué dans le formulaire d'inscription dans la mesure où ce numéro permet
                l’utilisation des Services. En cas de numéro de téléphone erroné, le Client ne
                sera pas en mesure d'accéder aux Services sans que la responsabilité en incombe
                à KYVOITOU.
            </SimpleText>
            <SimpleText style={styles.sub}>
                3.2. Enregistrement d’un moyen de paiement au sein du Compte Client
            </SimpleText>
            <SimpleText style={styles.text}>
                Une fois son Compte Client créé préalablement à l’issue de l’inscription, le
                Client doit enregistrer un moyen de paiement accepté afin de pouvoir passer
                Commande des Services proposés par KYVOITOU sur le Site Internet. Seuls les
                Clients inscrits ayant enregistré un moyen de paiement peuvent souscrire à
                l'ensemble des Services offerts sur le site, en procédant au règlement des
                Services selon les modes de paiement indiqués.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client enregistre le moyen de paiement de son choix dans l’onglet « Profil »
                puis « Mon Compte ».
            </SimpleText>
            <SimpleText style={styles.text}>
                Il devra communiquer sur un formulaire en ligne les informations relatives à sa
                Carte bancaire, permettant ainsi le règlement sécurisé de sa commande :
            </SimpleText>
            <SimpleText style={styles.list}>- le nom du titulaire,</SimpleText>
            <SimpleText style={styles.list}>
                - les seize (16) chiffres de sa carte bancaire,
            </SimpleText>
            <SimpleText style={styles.list}>- la date d’expiration,</SimpleText>
            <SimpleText style={styles.list}>
                - le code de cryptogramme virtuel figurant au dos de la carte bancaire.
            </SimpleText>
            <SimpleText style={styles.text}>
                Il appartient au Client de vérifier la validité du moyen de paiement dans le
                formulaire en ligne dans la mesure où ce moyen de paiement permet l’utilisation
                des Services. En cas de moyen de paiement invalide, le Client ne sera pas en
                mesure d'accéder aux Services sans que la responsabilité en incombe à KYVOITOU.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 4.{' '}
                <SimpleText style={styles.underline}>PROCEDURE DE COMMANDE</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Les Commandes sont passés directement sur le Site Internet. Le Client
                sélectionne sur le Site Internet les Services qu'il désire commander, selon les
                modalités décrites ci-après.
            </SimpleText>
            <SimpleText style={styles.text}>
                Conformément aux dispositions de l’article 1127-1 du Code civil, le Client doit
                respecter plusieurs étapes pour conclure le contrat par voie électronique et
                pouvoir obtenir sa Commande.
            </SimpleText>
            <SimpleText style={styles.sub}>4.1. Connexion au Compte Client</SimpleText>
            <SimpleText style={styles.text}>
                Tout d’abord, le Client devra se connecter à son Compte Client sur le badge «
                Connexion » avec ses identifiants (adresse email et mot de passe choisi lors de
                l’enregistrement/inscription) sur le Site Internet.
            </SimpleText>
            <SimpleText style={styles.sub}>
                4.2. Sélection des Services et options d’achat
            </SimpleText>
            <SimpleText style={styles.text}>
                Une fois connecté, le Client choisit le Service dont il souhaite bénéficier sur
                la page « Accueil » ou « Spécialité » du Site Internet, en parcourant les
                profils des conseillers en voyance.
            </SimpleText>
            <SimpleText style={styles.text}>
                Il est rappelé que KYVOITOU informe sur :
            </SimpleText>
            <SimpleText style={styles.list}>- le choix du Service ;</SimpleText>
            <SimpleText style={styles.list}>- ses options ;</SimpleText>
            <SimpleText style={styles.list}>
                - la vérification des éléments de la Commande par le double clic ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - la correction des erreurs avant la validation définitive de la Commande.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client doit avoir la possibilité de vérifier le détail de sa Commande, son
                prix et de corriger ses éventuelles erreurs ou annuler sa Commande.
            </SimpleText>
            <SimpleText style={styles.sub}>4.3. Commandes</SimpleText>
            <SimpleText style={styles.text}>
                S’il ne l’a pas encore fait, le Client est invité à se connecter ou à
                s’inscrire et à enregistrer un moyen de paiement.
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour les Services en direct, le Client valide une Commande en cliquant sur le
                Service sélectionné (appel ou tchat).
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour les Services impliquant une question écrite (question simple ou étude
                complète), le Client valide la Commande du Service en cliquant sur le Service «
                Question », choisit le type de question (question simple ou étude complète),
                pose la question en la tapant dans un encart prévu à cet effet puis valide la
                question.
            </SimpleText>
            <SimpleText style={styles.text}>
                Une fois la Commande validée, s’affichera à l’attention du Client un formulaire
                en ligne complété automatiquement et récapitulant le contenu du Service
                sélectionné, la quantité, le prix, les taxes applicables. Le Client est invité
                à vérifier le contenu de sa Commande avant de la valider définitivement en
                cliquant sur le bouton « Valider ».
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client doit toujours avoir la possibilité d’annuler sa Commande ou de
                corriger, le cas échéant, ses erreurs avant de procéder à la confirmation
                (quantité de produits achetés, produit acheté, ...), ce mécanisme devant être
                obligatoirement implémenté lors du processus de Commande en vertu de l’article
                1127-2 du Code Civil. Cette validation implique l'acceptation de l'intégralité
                des présentes Conditions Générales et constituent une preuve du contrat de
                vente. Il appartient donc au Client de vérifier l'exactitude de la Commande et
                de signaler immédiatement toute erreur.
            </SimpleText>
            <SimpleText style={styles.text}>
                La vente de Services est définitive à la validation de la Commande dans les
                conditions ci-dessus décrites.
            </SimpleText>
            <SimpleText style={styles.text}>
                Toute Commande de Services passée par le Client par « clic » pour le Service
                sélectionné par ses soins constitue une acceptation irrévocable qui ne peut
                être remise en cause que dans les limites prévues dans les présentes Conditions
                Générales.
            </SimpleText>
            <SimpleText style={styles.text}>
                Les informations contractuelles sont présentées en langue française et font
                l'objet d'une confirmation au plus tard au moment de l’enregistrement du moyen
                de paiement. Toute Commande passée sur le Site Internet constitue la formation
                d'un contrat conclu à distance entre le Client et KYVOITOU.
            </SimpleText>
            <SimpleText style={styles.text}>
                KYVOITOU se réserve le droit d'annuler ou de refuser toute commande d'un Client
                avec lequel il existerait un litige relatif au paiement d'une commande
                antérieure. La Commande n'est pas modifiable et ne peut être annulée, hors
                l'exercice du droit de rétractation, si applicable, ou cas de force majeure.
            </SimpleText>
            <SimpleText style={styles.sub}>4.4. Enregistrement des Services</SimpleText>
            <SimpleText style={styles.text}>
                Le Client est expressément informé que les appels téléphoniques, messages du
                tchat en ligne ou emails relatifs aux Services sont enregistrées par KYVOITOU.
                Le Client devra donner son consentement pour que la conversation soit
                enregistrée et aura la possibilité de s’y opposer avant le début du Service.
            </SimpleText>
            <SimpleText style={styles.text}>
                En tant que responsable de traitement, KYVOITOU respecte les obligations
                prévues par la loi Informatique & Libertés et par le RGPD lorsque ces
                conversations contiennent des données à caractère personnel.
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour exercer ses droits, le Client peut se reporter aux dispositions de
                l’article 10 des présentes Conditions Générales.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 5. <SimpleText style={styles.underline}>TARIFS</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Les Services proposés par KYVOITOU sont fournis aux tarifs en vigueur sur le
                Site Internet.
            </SimpleText>
            <SimpleText style={styles.text}>
                Les prix sont exprimés en Euros, toutes taxes comprises. Les prix incluent en
                particulier la taxe sur la valeur ajoutée (T.V.A.) au taux en vigueur à la date
                de Commande. Toute modification du taux applicable peut impacter le prix des
                Services à compter de la date d’entrée en vigueur du nouveau taux. Le taux de
                TVA applicable est exprimé en pourcentage de la valeur de la Prestation vendue.
                Pour les Services en direct (appel téléphonique ou tchat en ligne), le Service
                est facturé à la minute, toute minute commencée étant due. Le taux de TVA
                applicable est exprimé en pourcentage de la valeur de la Prestation vendue.
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour ces Services en direct, le Client est informé qu’il bénéficie de cinq (5)
                minutes de gratuité lors de la création de son Compte Client et de
                l’enregistrement de son moyen de paiement auprès de trois (3) conseillers en
                voyance différents. Au bout de la cinquième minute et à compter seulement de la
                sixième minute, le Client est informé qu’il sera facturé et s’il décide de ne
                pas interrompre la connexion, le Service en direct est facturé au tarif à la
                minute en vigueur sur le Site Internet.
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour les Services sous forme de question, le Service est facturé pour chaque
                consultation (question simple ou étude complète).
            </SimpleText>
            <SimpleText style={styles.text}>
                Les prix référencés sur le Site Internet sont susceptibles d’être modifiés. Ils
                peuvent également être modifiés en cas d’offres ou de ventes spéciales.
            </SimpleText>
            <SimpleText style={styles.text}>
                Une facture est établie par KYVOITOU et remise au Client sur demande de
                celui-ci.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 6.{' '}
                <SimpleText style={styles.underline}>MODALITES DE PAIEMENT</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour les Services en direct (appel téléphonique et tchat en ligne), la carte
                bancaire enregistrée par le Client dans son Compte Client est débitée selon les
                tarifs en vigueur sur le Site Internet pour chaque minute de consultation. Le
                Service en direct ne prend effet qu'à compter de la mise en relation effective
                du Client avec un conseiller qu'il a sélectionné et pour lequel il est
                éventuellement en attente de mise en relation dès lors que le Conseiller est
                connecté au Site internet. La mise en attente de relation n'est pas décomptée.
                En cas d'échec ou d'interruption de la mise en relation entre le Client et le
                conseiller, pour quelque cause que ce soit échappant à la volonté ou à l'action
                du Client, le Client a la possibilité de retenter une mise en relation.
            </SimpleText>
            <SimpleText style={styles.text}>
                Pour les Services sous forme de question (question simple ou étude complète),
                la carte bancaire enregistrée par le Client dans son Compte Client est débitée
                selon les tarifs en vigueur sur le Site Internet à réception de la réponse de
                consultation dans l’onglet « Messagerie » de son Compte Client.
            </SimpleText>
            <SimpleText style={styles.text}>
                Les moyens de paiement acceptés sont les cartes bancaires à savoir VISA et
                EUROCARD/MASTERCARD, ainsi que toutes les cartes acceptées par le partenaire de
                paiement de KYVOITOU (STRIPE).
            </SimpleText>
            <SimpleText style={styles.text}>
                Le système de paiement est confié à un prestataire spécialisé (STRIPE) qui
                assure la sécurité et la confidentialité des données de paiement transmises par
                le Client. Les données de paiement sont échangées en mode crypté grâce au
                protocole HTTPS. KYVOITOU n'a aucun accès aux données relatives aux moyens de
                paiement du Client.
            </SimpleText>
            <SimpleText style={styles.text}>
                Les paiements effectués par le Client ne seront considérés comme définitifs
                qu'après encaissement effectif des sommes dues par KYVOITOU.
            </SimpleText>
            <SimpleText style={styles.text}>
                En cas d'insuffisance de provisions des moyens de paiement du Client ou de
                refus de la carte bancaire par notre partenaire de sécurisation des paiements,
                le Service commandé ne pourra être fourni.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 7. <SimpleText style={styles.underline}>SERVICES</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Les Services commandés par le Client, qui comprennent les consultations de
                voyance, seront fournis par écrit selon les modalités suivantes :
            </SimpleText>
            <SimpleText style={styles.list}>
                - Une consultation de voyance en direct effectuée par le conseiller disponible
                et sélectionné par le Client par appel téléphonique tarifé à la minute ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - Une consultation de voyance en direct effectuée par le conseiller disponible
                et sélectionné par le Client par tchat en ligne tarifé à la minute ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - Une consultation de voyance effectuée par le conseiller sélectionné par le
                Client sous forme de réponse écrite (question simple ou étude complète)
                apportée à une question écrite précise posée par le Client et livrée sur
                l’espace « Messagerie » du Compte Client.
            </SimpleText>
            <SimpleText style={styles.text}>
                Tout Client est expressément informé par les présentes que pour toute Commande
                d’un Service sur le Site Internet, KYVOITOU sous-traite ladite Prestation
                auprès de conseillers qui fourniront le Service au Client.
            </SimpleText>
            <SimpleText style={styles.text}>
                En cliquant sur l’onglet « Spécialité » du Site Internet, le Client a la
                possibilité de consulter les profils des conseillers en voyance et de choisir
                parmi les catégories suivantes : Médium, Tarologie, Astrologie, Numérologie,
                Voyance et Cartomancie.
            </SimpleText>
            <SimpleText style={styles.text}>Les Services intègrent également :</SimpleText>
            <SimpleText style={styles.list}>
                - Une offre commerciale : trois fois « 5 premières minutes offertes avec trois
                conseiller au choix du Client » à la création d’un Compte Client suivi d’un
                enregistrement de moyen de paiement. Cette offre n’est pas cumulable sur un
                même conseiller.
            </SimpleText>
            <SimpleText style={styles.list}>
                - Un espace de notation pour permettre aux Clients de commenter et de donner
                leur avis sur la qualité des Services ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - L’accès à l’espace messagerie sur le Compte Client regroupant les échanges
                reçus et envoyés avec les conseillers ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - L’accès à l’historique de consultations ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - La possibilité d’activer et de désactiver des alertes (message ou réponse
                d’un conseiller, paiement effectué).
            </SimpleText>
            <SimpleText style={styles.text}>
                L’indisponibilité d’un Service et/ou d’un profil de conseiller est en principe
                indiquée sur le profil du conseiller sélectionné. Les Clients peuvent également
                être informés de la disponibilité du Service (alerte).
            </SimpleText>
            <SimpleText style={styles.text}>
                KYVOITOU fait de son mieux pour assurer la disponibilité des Services ainsi que
                les transmissions. Du fait de la nature d’internet, cela ne peut pas être
                garanti systématiquement si bien que les Services peuvent être
                occasionnellement suspendus ou limités afin de permettre des réparations de
                maintenance ou ajouter une nouvelle fonctionnalité ou nouveau service.
                L’identification du prestataire est la suivante :
            </SimpleText>
            <View style={styles.address}>
                <SimpleText style={styles.center}>SAS - KYVOITOU – WZA</SimpleText>
                <SimpleText style={styles.center}>
                    Société par actions simplifiée au capital de 202.000 €
                </SimpleText>
                <SimpleText style={styles.center}>
                    Siège social e/ Terre De Pompiac, 47330 Castillonnès
                </SimpleText>
                <SimpleText style={styles.center}>884 628 124 R.C.S. Agen</SimpleText>
            </View>
            <SimpleText style={styles.article}>
                ARTICLE 8.{' '}
                <SimpleText style={styles.underline}>DROIT DE RETRACTATION</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client dispose en principe, conformément à la loi, d'un délai de
                rétractation de quatorze (14) jours à compter de la conclusion du Contrat pour
                exercer son droit de rétractation auprès du prestataire et annuler sa commande,
                sans avoir à justifier de motifs ni à payer de pénalité, à fin d'échange ou de
                remboursement.
            </SimpleText>
            <SimpleText style={styles.text}>
                Toutefois, et compte tenu de la nature des services fournis, les commandes
                passées par le Client ne bénéficient pas du droit de rétractation dans la
                mesure où le droit de rétractation ne peut être exercé pour les contrats de
                fourniture de services pleinement exécutés avant la fin du délai de
                rétractation et dont l'exécution a commencé après accord préalable exprès du
                consommateur et renoncement exprès à son droit de rétractation, comme c’est le
                cas en l’espèce, conformément à l’article L.221-28 du Code de la consommation.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le contrat est donc conclu de façon définitive dès la passation définitive de
                la Commande par le Client selon les modalités précisées aux présentes
                Conditions Générales.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 9.{' '}
                <SimpleText style={styles.underline}>
                    RESPONSABILITE DU PRESTATAIRE – GARANTIE
                </SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                KYVOITOU garantit, conformément aux dispositions légales et sans paiement
                complémentaire, le Client, contre tout défaut de conformité ou vice caché,
                provenant d'un défaut de réalisation des Services commandés dans les conditions
                et selon les modalités définies en annexe aux présentes Conditions Générales.
            </SimpleText>
            <SimpleText style={styles.text}>
                Afin de faire valoir ses droits, le Client devra informer le Prestataire, par
                écrit, de l'existence des vices ou défauts de conformité dans un délai maximum
                de 14 à compter de la fourniture des Services. KYVOITOU remboursera ou
                rectifiera ou fera rectifier (dans la mesure du possible) les services jugés
                défectueux dans les meilleurs délais et au plus tard dans les 3 jours suivant
                la constatation par KYVOITOU du défaut ou du vice. Le remboursement
                s'effectuera par crédit sur le compte bancaire du Client.
            </SimpleText>
            <SimpleText style={styles.text}>
                La garantie de KYVOITOU est limitée au remboursement des Services effectivement
                payés par le Client et le Prestataire ne pourra être considéré comme
                responsable ni défaillant pour tout retard ou inexécution consécutif à la
                survenance d'un cas de force majeure habituellement reconnu par la
                jurisprudence française.
            </SimpleText>
            <SimpleText style={styles.text}>
                Les Services fournis par l'intermédiaire du Site Internet sont conformes à la
                réglementation en vigueur en France. La responsabilité de KYVOITOU ne saurait
                être engagée en cas de non-respect de la législation du pays dans lequel les
                Services sont fournis, qu'il appartient au Client, qui est seul responsable du
                choix des Services demandés, de vérifier.
            </SimpleText>
            <SimpleText style={styles.text}>
                Par ailleurs, KYVOITOU est tenue à une obligation de moyens et ne saurait
                garantir un quelconque résultat obtenu par l'utilisation des Services qui est
                faite sous la seule responsabilité du Client, telle qu'elle est permise par la
                loi et explicitée et limitée aux seuls Services prévus aux termes des
                présentes.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 10.{' '}
                <SimpleText style={styles.underline}>
                    PROTECTION DES DONNEES PERSONNELLES
                </SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493
                du 20 juin 2018, il est rappelé que les données nominatives demandées au Client
                sont nécessaires au traitement de sa Commande et à l'établissement des
                factures, notamment.
            </SimpleText>
            <SimpleText style={styles.text}>
                Ces données peuvent être communiquées aux éventuels partenaires de KYVOITOU
                chargés de l'exécution, du traitement, de la gestion et du paiement des
                Commandes. Le traitement des informations communiquées par l'intermédiaire du
                Site internet aux exigences légales en matière de protection des données
                personnelles, le système d'information utilisé assurant une protection optimale
                de ces données.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client dispose, conformément aux réglementations nationales et européennes
                en vigueur d'un droit d'accès permanent, de modification, de rectification,
                d'opposition de portabilité et de limitation du traitement s'agissant des
                informations le concernant.
            </SimpleText>
            <SimpleText style={styles.text}>
                Ce droit peut être exercé dans les conditions et selon les modalités définies
                sur le Site Internet.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 11.{' '}
                <SimpleText style={styles.underline}>PROPRIETE INTELLECTUELLE</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Le contenu du Site Internet est la propriété de KYVOITOU de ses partenaires et
                est protégé par les lois françaises et internationales relatives à la propriété
                intellectuelle. Toute reproduction totale ou partielle de ce contenu est
                strictement interdite et est susceptible de constituer un délit de contrefaçon.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 12. <SimpleText style={styles.underline}>IMPREVISION</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Les Parties, d’un commun accord, déclarent écarter les dispositions supplétives
                de l’article 1195 du Code civil qui disposent que si un changement de
                circonstances imprévisible lors de la conclusion du contrat rend l’exécution
                excessivement onéreuse pour une partie qui n’avait pas accepté d’en assumer le
                risque, celle-ci peut redemander une renégociation du contrat à son
                cocontractant.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 13. <SimpleText style={styles.underline}>FORCE MAJEURE</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                La responsabilité de KYVOITOU ne pourra pas être mise en œuvre si la
                non-exécution ou le retard dans l’exécution de l’une de ses obligations
                décrites dans les présentes Conditions Générales découle d’un cas de force
                majeure.
            </SimpleText>
            <SimpleText style={styles.text}>
                Il y a force majeure en matière contractuelle lorsqu’un évènement échappant au
                contrôle du débiteur, qui ne pouvait être raisonnablement prévu lors de la
                conclusion du contrat et dont les effets ne peuvent être évités par des mesures
                appropriées, empêche l’exécution de son obligation par le débiteur. Si
                l’empêchement est temporaire, l’exécution de l’obligation est suspendue à moins
                que le retard qui en résulterait ne justifie la résolution du contrat. Si
                l’empêchement est définitif, le contrat est résolu de plein droit et les
                parties sont libérées de leurs obligations dans les conditions prévues aux
                articles 1351 et 1351-1 du Code civil. A ce titre, la responsabilité de
                KYVOITOU ne pourra pas être engagée notamment et de façon non limitative en cas
                d'attaque de pirates informatiques, d'indisponibilité de matériels,
                fournitures, pièces détachées, équipements personnels ou autres ; et
                d'interruption, de suspension, de réduction ou des dérangements de
                l'électricité ou autres ou toutes interruptions de réseaux de communications
                électroniques, ainsi qu’en cas de survenance de toute circonstance ou événement
                extérieur à la volonté de KYVOITOU intervenant postérieurement à la conclusion
                des Conditions Générales et en empêchant l’exécution dans des conditions
                normales.
            </SimpleText>
            <SimpleText style={styles.text}>
                Il est précisé que, dans une telle situation, le Client ne peut réclamer le
                versement d’aucune indemnité et ne peut intenter aucun recours à l’encontre de
                KYVOITOU.
            </SimpleText>
            <SimpleText style={styles.text}>
                En cas de survenance d’un des évènements susvisés, KYVOITOU s’efforcera
                d’informer le Client dès que possible.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 14.{' '}
                <SimpleText style={styles.underline}>DROIT APPLICABLE – LANGUE</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Les présentes Conditions générales de vente et les opérations qui en découlent
                sont régies par le droit français.
            </SimpleText>
            <SimpleText style={styles.text}>
                Elles sont rédigées en langue française. Dans le cas où elles seraient
                traduites en une ou plusieurs langues, seul le texte français ferait foi en cas
                de litige.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 15. <SimpleText style={styles.underline}>LITIGES</SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Tous les litiges auxquels les opérations d'achat et de vente conclues en
                application des présentes conditions générales de vente pourraient donner lieu,
                concernant tant leur validité, leur interprétation, leur exécution, leur
                résiliation, leurs conséquences et leurs suites et qui n'auraient pu être
                résolues entre le vendeur et le client seront soumis aux tribunaux compétents
                dans les conditions de droit commun.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client est informé qu'il peut en tout état de cause recourir à une médiation
                conventionnelle, notamment auprès de la Commission de la médiation de la
                consommation (C. consom. art. L 612-1) ou auprès des instances de médiation
                sectorielles existantes, et dont les références figurent sur le site Internet
                ou à tout mode alternatif de règlement des différends (conciliation, par
                exemple) en cas de contestation.
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client, constatant qu'une violation au Règlement général sur la protection
                des données personnelles aurait été commise, a la possibilité de mandater une
                association ou un organisme mentionné au IV de l'article 43 ter de la loi
                informatique et liberté de 1978, afin d'obtenir contre le responsable de
                traitement ou sous-traitant, réparation devant une juridiction civile ou
                administrative ou devant la commission nationale de l'informatique et des
                libertés.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 16. <SimpleText style={styles.underline}>SUPPORT</SimpleText>
            </SimpleText>
            <SimpleText style={styles.sub}>16.1. Support Spirite</SimpleText>
            <SimpleText style={styles.text}>
                Pour répondre aux questions du Client et l’aider à choisir un conseiller
                KYVOITOU, cette dernière propose un service de support pour résoudre les
                problèmes que le Client serait susceptible de rencontrer, le Client pouvant
                contacter le support Spirite à :
            </SimpleText>
            <SimpleText style={styles.list}>
                - Hotline : {Settings.support_telephone} du lundi au vendredi de 9h à 19h (prix
                d’un appel local)
            </SimpleText>
            <SimpleText style={styles.list}>
                - Adresse postale : Terre de Pompiac, premier étage, 47330 Castillonnes
            </SimpleText>
            <SimpleText style={styles.list}>
                - Adresse e-mail : {Settings.support_mail}
            </SimpleText>
            <SimpleText style={styles.sub}>16.2. Support technique</SimpleText>
            <SimpleText style={styles.text}>
                Pour répondre aux questions du Client et l’aider à résoudre d’éventuels
                problèmes techniques, KYVOITOU propose un service de support pour résoudre les
                problèmes techniques que le Client serait susceptible de rencontrer, le Client
                pouvant contacter le support technique à :
            </SimpleText>
            <SimpleText style={styles.list}>
                - Hotline : {Settings.support_telephone} du lundi au vendredi de 9h à 19h (prix
                d’un appel local)
            </SimpleText>
            <SimpleText style={styles.list}>
                - Adresse postale : Terre de Pompiac, premier étage, 47330 Castillonnes
            </SimpleText>
            <SimpleText style={styles.list}>
                - Adresse e-mail : {Settings.contact_mail}
            </SimpleText>
            <SimpleText style={styles.text}>
                En cas de panne, KYVOITOU n’est tenue qu’à une obligation de moyens.
            </SimpleText>
            <SimpleText style={styles.article}>
                ARTICLE 17.{' '}
                <SimpleText style={styles.underline}>
                    INFORMATION PRECONTRACTUELLE - ACCEPTATION DU CLIENT
                </SimpleText>
            </SimpleText>
            <SimpleText style={styles.text}>
                Le Client reconnaît avoir eu communication, préalablement à la passation de sa
                commande et à la conclusion du contrat, d'une manière lisible et
                compréhensible, des présentes Conditions Générales de Vente et de toutes les
                informations listées à l'article L. 221-5 du Code de la consommation, et
                notamment les informations suivantes :
            </SimpleText>
            <SimpleText style={styles.list}>
                - les caractéristiques essentielles des Services, compte tenu du support de
                communication utilisé et du Service concerné ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - le prix des Services et des frais annexes (livraison, par exemple) ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - en l'absence d'exécution immédiate du Contrat, la date ou le délai auquel le
                Prestataire s'engage à fournir les Services commandés ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - les informations relatives à l'identité de KYVOITOU, à ses coordonnées
                postales, téléphoniques et électroniques, et à ses activités, si elles ne
                ressortent pas du contexte ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - les informations relatives aux garanties légales et contractuelles et à leurs
                modalités de mise en œuvre ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - les fonctionnalités du contenu numérique et, le cas échéant, à son
                interopérabilité ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - la possibilité de recourir à une médiation conventionnelle en cas de litige ;
            </SimpleText>
            <SimpleText style={styles.list}>
                - les informations relatives au droit de rétractation (existence, conditions,
                délai, modalités d'exercice de ce droit et formulaire type de rétractation),
                aux modalités de résiliation et autres conditions contractuelles importantes ;
            </SimpleText>
            <SimpleText style={styles.list}>- les moyens de paiement acceptés.</SimpleText>
            <SimpleText style={styles.text}>
                Le fait pour une personne physique de commander sur le Site Internet emporte
                adhésion et acceptation pleine et entière des présentes Conditions Générales et
                obligation au paiement des Services commandés, ce qui est expressément reconnu
                par le Client, qui renonce, notamment, à se prévaloir de tout document
                contradictoire, qui serait inopposable à KYVOITOU.
            </SimpleText>
        </MainView>
    );
}

const styles = StyleSheet.create({
    container: {},
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 50,
    },
    chapter: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 30,
    },
    article: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 15,
    },
    underline: {
        fontSize: 14,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
    },
    bold: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    italic: {
        fontSize: 12,
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    text: {
        fontSize: 12,
        marginVertical: 5,
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    tab: {
        fontSize: 12,
        fontWeight: 'bold',
        width: 200,
        alignSelf: 'center',
    },
    definition: {
        fontSize: 12,
        marginVertical: 5,
        width: 1166,
    },
    address: {
        fontSize: 14,
        alignSelf: 'center',
        marginVertical: 10,
    },
    center: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    list: {
        fontSize: 12,
        marginVertical: 5,
        marginLeft: 5,
    },
    sub: {
        fontSize: 13,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 20,
    },
});

const mobileStyles = StyleSheet.create({
    container: {
        paddingHorizontal: 10,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 50,
    },
    chapter: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 30,
    },
    article: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 15,
    },
    underline: {
        fontSize: 14,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
    },
    bold: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    italic: {
        fontSize: 12,
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    text: {
        fontSize: 12,
        marginVertical: 5,
    },
    line: {},
    tab: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    definition: {
        fontSize: 12,
        marginVertical: 5,
    },
    address: {
        fontSize: 14,
        alignSelf: 'center',
        marginVertical: 10,
    },
    center: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    list: {
        fontSize: 12,
        marginVertical: 5,
        marginLeft: 5,
    },
    sub: {
        fontSize: 13,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textDecorationColor: 'black',
        marginVertical: 20,
    },
});
