import React, {useState} from 'react';
import {FlatList, View, StyleSheet, TouchableOpacity} from 'react-native';

import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {range} from '~/helpers/iterations';
import {filterEmpty, sorted} from '~/helpers/list';

import type {
    ExpertProfile_getExpert_reviews,
    ExpertProfile_getExpert_reviews_edges,
} from '../../screens/types/ExpertProfile';
import PopupReadReview from '../user/client/review/PopupReadReview';
import {ReviewData} from '../user/client/review/types/calls';
import ReviewPage from './ReviewPage';

const NB_REVIEWS_PER_PAGE = 4;

type ReviewsProps = {
    reviews: ExpertProfile_getExpert_reviews | null | undefined;
    expertId: string | undefined;
};

export default function Reviews(props: ReviewsProps) {
    const [currentPage, setCurrentPage] = useState(1);

    const [reviewData, setReviewData] = useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: true,
    });
    const [modalReadReviewVisible, setModalReadReviewVisible] = useState(false);

    function onPressReview(reviewData: ReviewData) {
        setReviewData(reviewData);
        setModalReadReviewVisible(true);
    }

    function onRequestCloseReadReview() {
        setModalReadReviewVisible(false);
    }

    const start = (currentPage - 1) * NB_REVIEWS_PER_PAGE;
    const end = currentPage * NB_REVIEWS_PER_PAGE - 1;

    function isPageVisible(element: ExpertProfile_getExpert_reviews_edges, index: number) {
        return index >= start && index <= end;
    }

    function getCreationDate(review: ExpertProfile_getExpert_reviews_edges) {
        return Date.parse(review?.node?.createdDate);
    }

    const sortedReviews = sorted(filterEmpty(props.reviews?.edges), getCreationDate, true);

    const myReviews = sortedReviews.filter(isPageVisible);

    if (props.reviews?.edges.length == 0) {
        return (
            <View style={styles.container}>
                <SimpleText style={styles.emptyBlockMessage}>
                    Ce spirite n'a encore aucune évaluation. Soyez le premier à communiquer
                    avec lui et laisser votre avis !
                </SimpleText>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <View style={styles.reviewsContainer}>
                <ReviewPage
                    expertId={props.expertId}
                    pageNumber={currentPage}
                    reviews={myReviews}
                    onPressReviewList={onPressReview}
                />
            </View>
            <View style={styles.pagerContainer}>
                <ReviewsPageNavigation
                    reviews={sortedReviews}
                    currentPage={currentPage}
                    onPress={(pageIndex) => {
                        setCurrentPage(pageIndex);
                    }}
                />
            </View>
            <PopupReadReview
                visible={modalReadReviewVisible}
                onRequestClose={onRequestCloseReadReview}
                reviewData={reviewData}
                animationType={'none'}
            />
        </View>
    );
}

function ReviewsPageNavigation(props: ReviewsPageNavigationProps) {
    const nbReview = props.reviews?.length;
    let nbPage = nbReview ? Math.ceil(nbReview / NB_REVIEWS_PER_PAGE) : 1;
    let pages = range(nbPage, 1).map((i) => ({pageIndex: i, n: true}));

    function renderPageNumber({item}: PageProps) {
        const focused = item.pageIndex == props.currentPage;
        const focusedStyle = focused ? styles.focused : styles.notFocused;

        if (item.pageIndex > 0) {
            return (
                <View>
                    <TouchableOpacity
                        onPress={() =>
                            item.pageIndex > 0 ? props.onPress(item.pageIndex) : null
                        }
                    >
                        <SimpleText style={[styles.pageNumber, focusedStyle]}>
                            {item.pageIndex}
                        </SimpleText>
                    </TouchableOpacity>
                </View>
            );
        } else {
            return (
                <View>
                    <SimpleText style={styles.pageNumber}>...</SimpleText>
                </View>
            );
        }
    }

    if (pages.length <= 1) {
        return null;
    }

    console.log(pages);

    if (pages.length > 10) {
        let newPages = [];

        const min = 3;
        const total = pages.length;
        const max = total - min;

        let i = 0;
        while (i < min) {
            newPages[i] = pages[i];
            i++;
        }

        if (props.currentPage >= min && props.currentPage <= max + 1) {
            if (props.currentPage == min) {
                newPages[i] = pages[i];
                i++;
            }

            if (props.currentPage == min + 1) {
                newPages[i] = pages[props.currentPage - 1];
                i++;
                newPages[i] = pages[props.currentPage];
                i++;
            }

            if (props.currentPage > min + 2) {
                newPages[i] = {pageIndex: -1, n: false};
                i++;
            }

            if (props.currentPage > min + 1 && props.currentPage < max) {
                newPages[i] = pages[props.currentPage - 2];
                i++;
                newPages[i] = pages[props.currentPage - 1];
                i++;
                newPages[i] = pages[props.currentPage];
                i++;
            }

            if (props.currentPage == max + 1) {
                newPages[i] = pages[max - 1];
                i++;
            }

            if (props.currentPage == max) {
                newPages[i] = pages[max - 2];
                i++;
                newPages[i] = pages[max - 1];
                i++;
            }
        } else {
            newPages[i] = {pageIndex: -3, n: false};
            i++;
        }

        if (props.currentPage >= min && props.currentPage < max - 1) {
            newPages[i] = {pageIndex: -2, n: false};
            i++;
        }

        let j = max;
        while (j < total) {
            newPages[i] = pages[j];
            j++;
            i++;
        }

        console.log(newPages);
        pages = newPages;
    }

    return (
        <>
            <SimpleText>Pages : </SimpleText>
            <FlatList
                data={pages}
                renderItem={renderPageNumber}
                keyExtractor={(item) => item.pageIndex.toString()}
                contentContainerStyle={styles.pager}
                listKey={'pages'}
            />
        </>
    );
}

type ReviewsPageNavigationProps = {
    reviews: ExpertProfile_getExpert_reviews_edges[] | null | undefined;
    onPress: (pageIndex: number) => void;
    currentPage: number;
};

type PageProps = {
    item: {
        pageIndex: number;
    };
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    emptyBlockMessage: {
        color: 'grey',
        paddingHorizontal: 100,
        paddingVertical: 30,
    },
    reviewsContainer: {
        flex: 1,
    },
    pagerContainer: {
        alignSelf: 'flex-end',
        alignItems: 'baseline',
        flexDirection: 'row',
    },
    pager: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    pageNumber: {
        margin: 8,
        fontSize: 16,
    },
    notFocused: {
        textDecorationLine: 'underline',
    },
    focused: {
        fontWeight: '900',
        color: Colors.primary,
    },
});
