import React, {useState} from 'react';
import {View, StyleSheet, FlatList} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {Picker} from '@react-native-picker/picker';
import {useFocusEffect} from '@react-navigation/native';
import {IconNode} from 'react-native-elements';
import {TouchableOpacity} from 'react-native-gesture-handler';

import {MainView} from '~/components/common/Containers';
import {GradeColorLabel} from '~/components/common/GradeItem';
import * as Icons from '~/components/common/Icons';
import * as Table from '~/components/common/Table';
import {SimpleText} from '~/components/common/Texts';
import {ScreenLoader} from '~/components/navigation/Loader';
import * as Colors from '~/constants/Colors';
import {APP_ACTIVE_YEARS, MONTHS_NAMES} from '~/constants/dates';
import * as dates from '~/helpers/dates';
import {getBenefit} from '~/helpers/experts';
import {sorted, filterEmpty, Key} from '~/helpers/list';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import PopupReadReview from '../../client/review/PopupReadReview';
import type {ReviewData} from '../../client/review/types/calls';
import {CommunicationType} from '../../client/review/types/calls';
import PopupStatistics from '../../client/statistics/PopupStatistics';
import type {ExpertHistoricQuery} from './types/ExpertHistoricQuery';

export default function ExpertHistoric() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonth);

    const [load, {called, loading, data, refetch}] = useLazyQuery<ExpertHistoricQuery>(
        EXPERT_HISTORIC_QUERY,
        {
            errorPolicy: 'all',
            fetchPolicy: 'cache-and-network',
            variables: {month: month + 1, year: year},
        }
    );

    useFocusEffect(
        React.useCallback(() => {
            if (month == currentMonth && year == currentYear) {
                requestAnimationFrame(reload);
            }
        }, [month, year])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    const [reviewData, setReviewData] = useState<ReviewData>({
        communicationId: null,
        communicationType: null,
        date: null,
        expertId: null,
        clientMode: false,
    });

    const [modalReadReviewVisible, setModalReadReviewVisible] = useState(false);

    const [statisticsClientId, setStatisticsClientId] = useState<string | null>(null);

    const [modalStatisticsVisible, setModalStatisticsVisible] = useState(false);

    if (loading && !data) {
        return <ScreenLoader />;
    }

    function onReadReviewPress(reviewData: ReviewData) {
        setReviewData(reviewData);
        setModalReadReviewVisible(true);
    }

    function onRequestCloseReadReview() {
        setModalReadReviewVisible(false);
    }

    function onClientPress(clientId: string) {
        setStatisticsClientId(clientId);
        setModalStatisticsVisible(true);
    }

    function onRequestCloseStatistics() {
        setModalStatisticsVisible(false);
    }

    const monthItems = MONTHS_NAMES.map((name, i) => (
        <Picker.Item label={name} value={i} key={`month_${i}`} />
    ));

    const yearItems = APP_ACTIVE_YEARS.map((year) => (
        <Picker.Item label={`${year}`} value={year} key={`year_${year}`} />
    ));

    return (
        <MainView>
            <SimpleText style={styles.title} accessibilityRole="header" aria-level="1">
                Mon historique de consultation
            </SimpleText>
            <SimpleText style={styles.description}>
                Vous retrouverez ici toutes les statistiques liées à vos activités sur
                Kyvoitou.fr.
            </SimpleText>
            <View style={styles.filterContainer}>
                <SimpleText style={styles.pickerContainer}>Filtrer par : </SimpleText>
                <Picker
                    selectedValue={month}
                    style={styles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setMonth(parseInt(itemValue))}
                >
                    {monthItems}
                </Picker>
                <Picker
                    selectedValue={year}
                    style={styles.pickerContainer}
                    onValueChange={(itemValue, itemIndex) => setYear(parseInt(itemValue))}
                >
                    {yearItems}
                </Picker>
            </View>
            <ExpertHistoricElementTable
                data={data}
                month={month}
                year={year}
                onReadReviewPress={onReadReviewPress}
                onClientPress={onClientPress}
            />
            <PopupReadReview
                visible={modalReadReviewVisible}
                onRequestClose={onRequestCloseReadReview}
                reviewData={reviewData}
                animationType={'none'}
            />
            <PopupStatistics
                visible={modalStatisticsVisible}
                onRequestClose={onRequestCloseStatistics}
                clientId={statisticsClientId}
            />
        </MainView>
    );
}

type ExpertHistoricElement = {
    type: string;
    communicationType: CommunicationType;
    id: string;
    expertId: string;
    clientId: string;
    clientDisplayName: string;
    date: string;
    reviewId: string;
    response: string;
    cost: number;
    duration: string;
    gradeLabel: string;
    gradeId: number;
};

function ExpertHistoricElementTable(props: ExpertHistoricElementTableProps) {
    const [order, setOrder] = useState({field: 'date', value: 'desc'});

    function onPressHeader(field: string, value: string) {
        setOrder({field: field, value: value});
    }

    function getAverageDuration(elements: ExpertHistoricElement[]) {
        const list = elements.filter((e) => e.duration !== '');
        if (list.length <= 0) {
            return '-';
        }
        const average =
            list.reduce(
                (total, next) => total + dates.getDurationInSeconds(next.duration),
                0
            ) / list.length;
        const averageSeconds = Math.round(average);
        return dates.getDurationText(averageSeconds);
    }

    function getSumCost(elements: ExpertHistoricElement[]) {
        const list = elements.filter((e) => e.cost > 0);
        const sum = list.reduce((total, next) => total + next.cost, 0);
        return sum.toFixed(2);
    }

    function getMostReview(elements: ExpertHistoricElement[]) {
        let grades = new Map<string, number>();
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.gradeLabel) {
                const grade = grades.get(element.gradeLabel);
                if (grade) {
                    grades.set(element.gradeLabel, grade + 1);
                } else {
                    grades.set(element.gradeLabel, 1);
                }
            }
        }

        if (grades.size > 0) {
            const sortedGrades = new Map([...grades.entries()].sort((a, b) => b[1] - a[1]));
            return sortedGrades.keys().next().value;
        } else {
            return ' - ';
        }
    }

    const renderItem = ({item}: {item: ExpertHistoricElement}) => {
        const date = new Date(item.date);

        function readReview() {
            const reviewData = {
                date,
                communicationId: item.id,
                communicationType: item.communicationType,
                expertId: item.expertId,
                clientMode: false,
            };
            props.onReadReviewPress(reviewData);
        }

        function displayStatistics() {
            props.onClientPress(item.clientId);
        }

        return (
            <Row
                element={item}
                onReadReviewPress={readReview}
                onClientPress={displayStatistics}
            />
        );
    };

    var calls = props.data?.findExpertCalls?.edges?.filter(
        (e) =>
            (e?.node?.status == 'FINISHED' || e?.node?.status == 'ERROR') &&
            new Date(e.node.startDatetime).getFullYear() == props.year &&
            new Date(e.node.startDatetime).getMonth() == props.month
    );

    const callsElements = calls?.map((e) => {
        return {
            type: 'Appel' + (e?.node?.usePack ? ' + PACK' : ''),
            communicationType: CommunicationType.CALL,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            clientId: e?.node?.client?.id,
            clientDisplayName: e?.node?.client?.profile?.displayName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            response: e?.node?.review?.response,
            cost: getBenefit(
                e?.node?.cost,
                e?.node?.usePack,
                dates.getDurationInSeconds(e?.node?.duration),
                CommunicationType.CALL
            ),
            duration: e?.node?.duration,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as ExpertHistoricElement;
    });

    const messages = props.data?.findExpertMessages?.edges?.filter(
        (e) =>
            (e?.node?.type == MessageTypeEnum.PACK_STUDY || e?.node?.status == 'ANSWERED') &&
            new Date(e.node.creationDate).getFullYear() == props.year &&
            new Date(e.node.creationDate).getMonth() == props.month
    );

    const messagesElements = messages?.map((e) => {
        return {
            type: e?.node?.type == MessageTypeEnum.PACK_STUDY ? 'Étude complète' : 'Message',
            communicationType: CommunicationType.MESSAGE,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            clientId: e?.node?.client?.id,
            clientDisplayName: e?.node?.client?.profile?.displayName,
            date: e?.node?.creationDate,
            reviewId: e?.node?.review?.reviewId,
            response: e?.node?.review?.response,
            cost:
                e?.node?.type == MessageTypeEnum.PACK_STUDY
                    ? getBenefit(
                          e?.node?.packOrder?.amount,
                          null,
                          null,
                          CommunicationType.MESSAGE
                      )
                    : getBenefit(e?.node?.cost, null, null, CommunicationType.MESSAGE),
            duration: '',
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as ExpertHistoricElement;
    });

    var chats = props.data?.findExpertChats?.edges?.filter(
        (e) =>
            e?.node?.status == 'ENDED' &&
            new Date(e.node.startDatetime).getFullYear() == props.year &&
            new Date(e.node.startDatetime).getMonth() == props.month
    );

    const chatsElements = chats?.map((e) => {
        return {
            type: 'Chat' + (e?.node?.usePack ? ' + PACK' : ''),
            communicationType: CommunicationType.CHAT,
            id: e?.node?.id,
            expertId: e?.node?.expert?.id,
            clientId: e?.node?.client?.id,
            clientDisplayName: e?.node?.client?.profile?.displayName,
            date: e?.node?.startDatetime,
            reviewId: e?.node?.review?.reviewId,
            response: e?.node?.review?.response,
            cost: getBenefit(
                e?.node?.cost,
                e?.node?.usePack,
                dates.getDurationInSeconds(e?.node?.duration),
                CommunicationType.CHAT
            ),
            duration: e?.node?.duration,
            gradeLabel: e?.node?.review?.grade?.label,
            gradeId: parseInt(e?.node?.review?.grade?.gradeId ?? '-1'),
        } as ExpertHistoricElement;
    });

    const elements = [
        ...(callsElements ?? []),
        ...(messagesElements ?? []),
        ...(chatsElements ?? []),
    ];

    if (elements?.length == 0) {
        return (
            <SimpleText style={styles.description}>
                Vous n'avez pas d'élément dans l'historique pour ce mois-ci.
            </SimpleText>
        );
    }

    const reverse = order.value == 'desc';
    let displayedElements = filterEmpty(elements);
    const sortedElements = sortCalls(displayedElements, order.field, reverse);

    const total = sortedElements.length;
    const duration = getAverageDuration(sortedElements);
    const earn = getSumCost(sortedElements).toString() + '€';
    const mostReview = getMostReview(sortedElements);

    return (
        <View>
            <View style={styles.line}>
                <Statistic
                    icon={<Icons.Users size={52} color={Colors.secondary} />}
                    backgroundColor={Colors.primary}
                    number={total.toString()}
                    label={'Consultations sur la période'}
                />
                <Statistic
                    icon={<Icons.File size={52} color={'white'} />}
                    backgroundColor={Colors.pause}
                    number={duration}
                    label={"Durée moyenne d'un échange"}
                />
                <Statistic
                    icon={<Icons.Redo size={52} color={'white'} />}
                    backgroundColor={Colors.available}
                    number={earn}
                    label={'De revenus'}
                />
                <Statistic
                    icon={<Icons.Chart size={52} color={'white'} />}
                    backgroundColor={Colors.light}
                    number={mostReview}
                    label={'Evaluation le plus souvent laissée'}
                />
            </View>
            <ExpertHeaderRow order={order.field} onPressHeader={onPressHeader} />
            <FlatList
                data={sortedElements}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                contentContainerStyle={{
                    justifyContent: 'space-around',
                    flex: 1,
                    flexDirection: 'column',
                }}
            />
        </View>
    );
}

type StatisticProps = {
    icon: IconNode;
    backgroundColor: string;
    number: string;
    label: string;
};

function Statistic(props: StatisticProps) {
    return (
        <View style={[styles.statisticContainer, {backgroundColor: props.backgroundColor}]}>
            {props.icon}
            <View style={styles.statistic}>
                <SimpleText style={styles.number}>{props.number}</SimpleText>
                <SimpleText style={styles.label}>{props.label}</SimpleText>
            </View>
        </View>
    );
}

function ExpertHeaderRow({order, onPressHeader, ...otherProps}: ExpertHeaderRowProps) {
    return (
        <Table.HeaderRow {...otherProps}>
            <Table.HeaderCell
                large
                label={'Client'}
                onPressHeader={onPressHeader}
                field={'client'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                label={'Type'}
                onPressHeader={onPressHeader}
                field={'type'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                large
                label={'Date'}
                onPressHeader={onPressHeader}
                field={'date'}
                init={true}
                orderField={order}
            />
            <Table.HeaderCell
                label={'Durée'}
                onPressHeader={onPressHeader}
                field={'duration'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                label={'Gain'}
                onPressHeader={onPressHeader}
                field={'cost'}
                init={false}
                orderField={order}
            />
            <Table.HeaderCell
                label={'Evaluation'}
                onPressHeader={onPressHeader}
                field={'review'}
                init={false}
                orderField={order}
            />
        </Table.HeaderRow>
    );
}

function Row(props: RowProps) {
    const element = props.element;
    const date = new Date(element.date);
    const hasReview = element.reviewId != null;
    const hasResponse = element.response;

    return (
        <Table.Row>
            <Table.Cell large>
                <TouchableOpacity onPress={props.onClientPress}>
                    <SimpleText>{element.clientDisplayName}</SimpleText>
                </TouchableOpacity>
            </Table.Cell>
            <Table.Cell>{element.type}</Table.Cell>
            <Table.Cell large>
                {dates.dateString(date)} à {dates.timeString(date)}
            </Table.Cell>
            <Table.Cell>{element?.duration ? element?.duration : '-'}</Table.Cell>
            <Table.Cell>{element?.cost.toFixed(2)}€</Table.Cell>
            <Table.ViewCell>
                {hasReview && hasResponse && (
                    <TouchableOpacity onPress={props.onReadReviewPress}>
                        <GradeColorLabel
                            style={styles.grade}
                            text={element?.gradeLabel ?? ''}
                            grade={element?.gradeId}
                        />
                        <View style={styles.link}>
                            <Icons.Read
                                size={16}
                                color={Colors.link}
                                style={{paddingRight: 10}}
                            />
                            <SimpleText style={styles.text}>Lire</SimpleText>
                        </View>
                    </TouchableOpacity>
                )}
                {hasReview && !hasResponse && (
                    <TouchableOpacity onPress={props.onReadReviewPress}>
                        <GradeColorLabel
                            style={styles.grade}
                            text={element?.gradeLabel ?? ''}
                            grade={element?.gradeId}
                        />
                        <View style={styles.link}>
                            <Icons.Reply
                                size={16}
                                color={Colors.link}
                                style={{paddingRight: 10}}
                            />
                            <SimpleText style={styles.text}>Répondre</SimpleText>
                        </View>
                    </TouchableOpacity>
                )}
                {!hasReview && <SimpleText style={styles.center}> - </SimpleText>}
            </Table.ViewCell>
        </Table.Row>
    );
}

// TODO clean this (use sorted from helpers/list)
const COMPARISONS = new Map<string, Key<ExpertHistoricElement>>([
    ['client', (element: ExpertHistoricElement) => element?.clientDisplayName],
    ['type', (element: ExpertHistoricElement) => element?.type],
    ['date', (element: ExpertHistoricElement) => Date.parse(element?.date)],
    ['duration', (element: ExpertHistoricElement) => element?.duration],
    ['cost', (element: ExpertHistoricElement) => element?.cost],
]);

function sortCalls(
    calls: ExpertHistoricElement[],
    order: string,
    reverse?: boolean
): ExpertHistoricElement[] {
    const comparison = COMPARISONS.get(order);
    if (!comparison) {
        return [...calls];
    }
    return sorted(calls, comparison, reverse);
}

type ExpertHistoricElementTableProps = {
    data?: ExpertHistoricQuery;
    year: number;
    month: number;
    onReadReviewPress: (call: ReviewData) => void;
    onClientPress: (clientId: string) => void;
};

type RowProps = {
    element: ExpertHistoricElement;
    onReadReviewPress: () => void;
    onClientPress: () => void;
};

type ExpertHeaderRowProps = Table.HeaderRowProps & {
    onPressHeader: Table.CellPressHeaderCallback;
    order: string;
};

const EXPERT_HISTORIC_QUERY = gql`
    query ExpertHistoricQuery($month: Int, $year: Int) {
        findExpertCalls(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    usePack
                    status
                    expert {
                        id
                    }
                    client {
                        id
                        profile {
                            id
                            displayName
                        }
                    }
                    review {
                        id
                        reviewId
                        response
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
        findExpertMessages(month: $month, year: $year) {
            edges {
                node {
                    id
                    type
                    status
                    creationDate
                    cost
                    review {
                        id
                        reviewId
                        response
                        grade {
                            label
                            gradeId
                        }
                    }
                    expert {
                        id
                    }
                    client {
                        id
                        profile {
                            id
                            displayName
                        }
                    }
                    packOrder {
                        amount
                    }
                }
            }
        }
        findExpertChats(month: $month, year: $year) {
            edges {
                node {
                    id
                    status
                    startDatetime
                    duration
                    cost
                    usePack
                    status
                    expert {
                        id
                    }
                    client {
                        id
                        profile {
                            id
                            displayName
                        }
                    }
                    review {
                        id
                        reviewId
                        response
                        grade {
                            label
                            gradeId
                        }
                    }
                }
            }
        }
    }
`;

const styles = StyleSheet.create({
    title: {
        fontWeight: 'bold',
        fontSize: 38,
        textTransform: 'uppercase',
        color: Colors.secondary,
        alignSelf: 'center',
        margin: 44,
    },
    description: {
        fontSize: 20,
        color: Colors.secondary,
        alignSelf: 'center',
        textAlign: 'center',
    },
    filterContainer: {
        flexDirection: 'row',
        margin: 25,
        justifyContent: 'flex-end',
    },
    pickerContainer: {
        margin: 20,
    },
    grade: {
        fontSize: 12,
        alignSelf: 'center',
        marginBottom: 5,
    },
    link: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
    text: {
        color: Colors.link,
    },
    center: {
        textAlign: 'center',
    },
    statisticContainer: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        paddingVertical: 30,
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        borderRadius: 7,
        width: 215,
    },
    statistic: {
        paddingLeft: 15,
        width: 130,
    },
    number: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    label: {
        paddingTop: 5,
        flexDirection: 'row',
        textAlign: 'center',
        fontSize: 12,
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});
