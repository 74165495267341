import React from 'react';
import {Image, StyleSheet, View} from 'react-native';

import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import useDeviceQuery from '~/hooks/useDeviceQuery';

export function Help() {
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles : mobileStyles;

    return (
        <View style={deviceStyle.container}>
            <Image
                source={{uri: Settings.getUrlJustine(isDesktop)}}
                style={[
                    {
                        width: isDesktop ? 350 : 300,
                        height: isDesktop ? 350 : 200,
                    },
                    deviceStyle.justine,
                ]}
                accessibilityLabel={'Justine'}
            />
            <View style={deviceStyle.help}>
                <View style={deviceStyle.titleContainer}>
                    <SimpleText style={deviceStyle.title}>Vous avez une question ?</SimpleText>
                    <SimpleText style={deviceStyle.title}>
                        Peur de franchir le cap ?
                    </SimpleText>
                    <SimpleText style={deviceStyle.title}>Notre équipe est là !</SimpleText>
                </View>
                <SimpleText style={deviceStyle.description}>
                    Contactez Camille ou Justine directement sur le standard de Kyvoitou.fr
                    pour vous aider à :
                </SimpleText>
                <View style={deviceStyle.lines}>
                    <View style={deviceStyle.line}>
                        <Image
                            source={{uri: Settings.getUrlFlag()}}
                            accessibilityLabel={'Finaliser une inscription'}
                            style={{width: 22, height: 22}}
                        />
                        <SimpleText style={deviceStyle.text}>
                            Finaliser une inscription
                        </SimpleText>
                    </View>
                    <View style={deviceStyle.line}>
                        <Image
                            source={{uri: Settings.getUrlBall()}}
                            accessibilityLabel={'Choisir votre médium'}
                            style={{width: 22, height: 22}}
                        />
                        <SimpleText style={deviceStyle.text}>Choisir votre médium</SimpleText>
                    </View>
                    <View style={deviceStyle.line}>
                        <Image
                            source={{uri: Settings.getUrlPoint()}}
                            accessibilityLabel={"S'abonner à la newsletter"}
                            style={{width: 22, height: 22}}
                        />
                        <SimpleText style={deviceStyle.text}>
                            S'abonner à la newsletter
                        </SimpleText>
                    </View>
                    <View style={deviceStyle.line}>
                        <Image
                            source={{uri: Settings.getUrlPrey()}}
                            accessibilityLabel={'Tout autre demande'}
                            style={{width: 22, height: 22}}
                        />
                        <SimpleText style={deviceStyle.text}>Tout autre demande</SimpleText>
                    </View>
                </View>
                <View>
                    <SimpleText style={deviceStyle.description}>
                        {Settings.contact_mail}
                    </SimpleText>
                    <SimpleText style={deviceStyle.description}>
                        {Settings.support_telephone}
                    </SimpleText>
                </View>
            </View>
            <Image
                source={{uri: Settings.getUrlCamille(isDesktop)}}
                style={[
                    {
                        width: isDesktop ? 311 : 300,
                        height: isDesktop ? 350 : 200,
                    },
                    deviceStyle.camille,
                ]}
                accessibilityLabel={'Camille'}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 50,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    help: {
        maxWidth: 500,
        justifyContent: 'space-between',
    },
    titleContainer: {},
    title: {
        fontSize: 32,
        fontWeight: 'bold',
        color: Colors.secondary,
        textAlign: 'center',
    },
    description: {
        color: Colors.secondary,
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
    },
    text: {
        color: Colors.secondary,
        fontSize: 16,
        width: 210,
        marginLeft: 10,
        alignSelf: 'center',
    },
    lines: {},
    line: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 5,
    },
    camille: {},
    justine: {},
});

const mobileStyles = StyleSheet.create({
    container: {
        marginTop: 25,
    },
    help: {
        alignSelf: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 15,
    },
    titleContainer: {
        marginBottom: 15,
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        color: Colors.secondary,
        textAlign: 'center',
    },
    description: {
        color: Colors.secondary,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
    },
    text: {
        color: Colors.secondary,
        fontSize: 16,
        width: 210,
        marginLeft: 10,
        alignSelf: 'center',
    },
    lines: {
        marginVertical: 10,
    },
    line: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 5,
    },
    camille: {
        alignSelf: 'center',
        borderRadius: 8,
        marginTop: 15,
    },
    justine: {
        alignSelf: 'center',
        borderRadius: 8,
        marginBottom: 15,
    },
});
