import * as React from 'react';
import {ScrollView, View} from 'react-native';

import {useLinkTo} from '@react-navigation/native';
import {StripeError} from '@stripe/stripe-js';

import AddPaymentView from '~/components/landingPage/AddPaymentView';
import ExpertPickerView from '~/components/landingPage/ExpertPickerView';
import OngoingCallView from '~/components/landingPage/OngoingCallView';
import {Pack} from '~/components/landingPage/PackList';
import TitleView from '~/components/landingPage/PackTitleView';
import PickPackView from '~/components/landingPage/PickPackView';
import PickedExpertView from '~/components/landingPage/PickedExpertView';
import PickedPackView from '~/components/landingPage/PickedPackView';
import PostPaymentView from '~/components/landingPage/PostPaymentView';
import PostRegisterView from '~/components/landingPage/PostRegisterView';
import RegisterView from '~/components/landingPage/RegisterView';
import Reviews from '~/components/landingPage/Reviews';
import {
    Step,
    UrlStepProvider,
    useUrlStepContext,
} from '~/components/landingPage/context/UrlStepContext';
import {useCallStarted} from '~/components/landingPage/hook/useCallStarted';
import useCampaignQuery from '~/components/landingPage/hook/useCampaignQuery';
import {useExpertId} from '~/components/landingPage/hook/useExpertId';
import {useSelectedPack} from '~/components/landingPage/hook/useSelectedPack';
import useSelectedExpert from '~/components/landingPage/query/useSelectedExpert';
import {
    useCallLandingPageRoute,
    useCallPackLandingPageNavigation,
} from '~/components/landingPage/route';
import {styles} from '~/components/landingPage/styles';
import {mobileStyles} from '~/components/user/client/message/MobileMessageBox';
import {PACK_CALL_LANDING_PAGE_URL} from '~/constants/LandingPageSettings';
import {useAlert} from '~/contexts/AlertContext';
import {UserContext} from '~/contexts/UserContext';
import {purchase} from '~/helpers/analytics';
import event from '~/helpers/analytics';
import {ExpertWithLastReview} from '~/helpers/experts';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {useMinuteHistory} from '~/queries/useMinuteBalance';
import {TransactionType} from '~/types/graphql-global-types';

export default function LandingPageScreen() {
    return (
        <UrlStepProvider>
            <Content />
        </UrlStepProvider>
    );
}

function Content() {
    const alert = useAlert();
    const {isDesktop} = useDeviceQuery();
    const deviceStyle = isDesktop ? styles.container : mobileStyles.container;
    const scrollViewRef = React.useRef<ScrollView>(null);
    function scrollToTop() {
        scrollViewRef.current?.scrollTo({y: 0, animated: false});
    }

    const query = useCampaignQuery();
    const linkTo = useLinkTo();
    const {me, refetchMe, minutes} = React.useContext(UserContext);
    const {data: minutesHistoryData, refetch: refetchMinutesHistory} = useMinuteHistory();
    const route = useCallLandingPageRoute();
    const sessionId =
        route.params?.sessionId || me?.identity?.selectedPaymentMethod?.sessionId;

    React.useEffect(() => {
        const transactions = minutesHistoryData?.getMinuteHistory?.edges.map((e) => e?.node);
        const hasTransaction = transactions?.some(
            (t) => t?.transaction == TransactionType.SPEND
        );
        if (hasTransaction) {
            const balance = minutes?.getMinuteBalance?.minutes ?? 0;
            const hasBalance = balance > 0;
            const balanceText = hasBalance
                ? `\n\nVous pourrez profiter de votre solde de ${balance} minutes restantes avec l'entièreté de nos médiums.`
                : ``;
            alert({
                title: 'Heureux de vous revoir !',
                message: `Vous allez être redirigé vers l'accueil de Kyvoitou pour profiter de l'expérience complète sur la plateforme. ${balanceText}`,
                buttonText: "C'est parti 🚀",
                onClose: () => {
                    linkTo('/home');
                },
            });
        }
    }, [minutes]);

    const {
        showPacks,
        showExpertList,
        showAuthForm,
        showPaymentCTA,
        showCallStatus,
    } = useUrlStepContext();
    const [callAlreadyStarted, setCallAlreadyStarted] = useCallStarted();
    const [selectedExpertId, setSelectedExpertId] = useExpertId();
    const [selectedPackId, setSelectedPackId] = useSelectedPack();
    const [selectedPack, setSelectedPack] = React.useState<Pack>();
    const onExpertSelected = React.useCallback(
        (user: ExpertWithLastReview) => {
            setSelectedExpertId(user.userId);
            if (selectedPackId) {
                if (me?.id) {
                    showPaymentCTA();
                } else {
                    showAuthForm();
                }
            } else {
                showPacks();
            }
            scrollToTop();
            event('call_landing_page_expert_selected', {expert_id: user.userId});
        },
        [selectedPackId, me]
    );

    function showPaymentCtaAndScroll() {
        showPaymentCTA();
        scrollToTop();
    }
    const onPackSelected = React.useCallback(
        (pack: Pack) => {
            setSelectedPack(pack);
            setSelectedPackId(pack.packId);
            event('call_landing_page_pack_selected', {
                packId: pack.packId,
                expertId: selectedExpertId,
                price: pack.amount,
            });
            if (me?.id) {
                showPaymentCTA();
            } else {
                showAuthForm();
            }
            scrollToTop();
        },
        [me]
    );

    const onRegisterAccount = React.useCallback(() => {
        showPaymentCtaAndScroll();
        setTimeout(() => refetchMe(), 2000);
        event('call_landing_page_register');
    }, []);

    const onPaymentIntentConfirmed = React.useCallback(() => {
        purchase(`Pack:${selectedPack?.packId}`, 'pack', selectedPack?.amount);
        showCallStatus();
    }, [selectedPack]);

    const onPaymentIntentError = React.useCallback((error: StripeError) => {
        event('call_landing_page_pack_payment_method_error', {
            stripe_code: error.code,
            stripe_message: error.message,
        });
    }, []);

    const onCancelPayment = React.useCallback(() => {
        showExpertList();
        scrollToTop();
        event('call_landing_page_pack_refused_waiting_list');
    }, []);

    const pickAnotherExpert = React.useCallback(() => {
        showExpertList();
        setSelectedExpertId(undefined);
        scrollToTop();
        event('call_landing_page_pack_pick_another_expert');
    }, []);

    const {data, loading} = useSelectedExpert(selectedExpertId);
    const selectedExpertUser = data?.user;

    const returnQueryParams = {...route.params};
    returnQueryParams.step = Step.ONGOING_CALL_STEP.valueOf();

    const navigation = useCallPackLandingPageNavigation();
    const returnToFirstStep = React.useCallback(() => {
        refetchMinutesHistory();
        navigation.navigate('LandingPageCallPackScreen', {});
    }, []);

    return (
        <View style={{flex: 1}}>
            <ScrollView ref={scrollViewRef} contentContainerStyle={{flexGrow: 1}}>
                <View style={deviceStyle}>
                    <TitleView />
                    <ExpertPickerView
                        onPaymentMethodRequired={showPaymentCtaAndScroll}
                        onExpertSelected={onExpertSelected}
                        medium="no-action"
                    />
                    <PickedExpertView
                        expertUser={selectedExpertUser}
                        onPickAnotherExpertPress={pickAnotherExpert}
                    />
                    <PickPackView onPress={onPackSelected} />
                    <PickedPackView />
                    <RegisterView
                        stepIndex={3}
                        style={{
                            maxWidth: 700,
                            alignSelf: 'center',
                            flex: 1,
                            marginVertical: 20,
                        }}
                        query={query}
                        onRegister={onRegisterAccount}
                    />
                    <PostRegisterView stepIndex={3} />
                    <AddPaymentView
                        stepIndex={4}
                        packId={selectedPackId}
                        expertUser={selectedExpertUser}
                        loadingExpert={loading}
                        onPaymentIntentConfirmed={onPaymentIntentConfirmed}
                        onPaymentIntentError={onPaymentIntentError}
                        returnUrl={PACK_CALL_LANDING_PAGE_URL}
                        returnQueryParams={returnQueryParams}
                    />
                    <PostPaymentView />
                    <OngoingCallView
                        usePack
                        onPressReturn={returnToFirstStep}
                        sessionId={sessionId}
                        expertUser={selectedExpertUser}
                        selectedExpertId={selectedExpertId}
                        loadingExpert={loading}
                        callAlreadyStarted={callAlreadyStarted}
                        setCallAlreadyStarted={setCallAlreadyStarted}
                        onCancelPayment={onCancelPayment}
                    />
                    <Reviews />
                </View>
            </ScrollView>
        </View>
    );
}
