import React from 'react';
import {ModalProps} from 'react-native';

import CustomModal from '~/components/common/Modal';

import SupportForm from './SupportForm';

export default function PopupSupport(props: ModalProps & {onSuccess: Function}) {
    return (
        <CustomModal
            title="Envoyer un message pour l'équipe Kyvoitou.fr"
            iconName="envelope"
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
        >
            <SupportForm onSuccess={props.onSuccess} />
        </CustomModal>
    );
}
