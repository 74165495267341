import React, {useState, useEffect} from 'react';
import {View, StyleSheet, FlatList, TouchableOpacity, Image} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {SearchBar} from 'react-native-elements';

import HoverableView from '~/components/common/HoverableView';
import {ProfilePicture} from '~/components/common/Images';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {filterEmpty} from '~/helpers/list';
import {ExchangeTypeEnum} from '~/helpers/message';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import {GROUP, MessageForm} from './NewMessage';
import {GetSearchClient} from './types/GetSearchClient';

const GET_SEARCH_CLIENT_QUERY = gql`
    query GetSearchClient($search: String!) {
        getSearchClient(search: $search) {
            edges {
                node {
                    id
                    displayName
                    pictureName
                }
            }
        }
    }
`;

type PopupMessageFromExpertProps = {
    visible: boolean;
    onRequestClose: () => void;
};

type FreeMessageClient = {
    clientId: string;
    displayName: string;
    pictureName: string;
};

export default function PopupMessageFromExpert(props: PopupMessageFromExpertProps) {
    const [isSearch, setIsSearch] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [timer, setTimer] = useState(null);
    const [emptyResult, setEmptyResult] = useState<boolean>(false);
    const [client, setClient] = React.useState<FreeMessageClient | null>(null);
    const [clients, setClients] = React.useState<FreeMessageClient[]>([]);
    const variables = {search};
    const [load, {data, loading, refetch}] = useLazyQuery<GetSearchClient>(
        GET_SEARCH_CLIENT_QUERY,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    function getSearch() {
        if (refetch) {
            refetch(variables);
        } else {
            load({variables});
        }
    }

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        if (search.length > 3) {
            const timeout = setTimeout(getSearch, 500);
            setTimer(timeout);
        }
    }, [search]);

    useEffect(() => {
        if (!loading && search.length > 3) {
            const newClients = filterEmpty(
                data?.getSearchClient?.edges.map((e) => {
                    return {
                        clientId: e?.node?.id,
                        displayName: e?.node?.displayName,
                        pictureName: e?.node?.pictureName,
                    } as FreeMessageClient;
                })
            );
            setClients(newClients);
            setEmptyResult(newClients.length == 0);
        }
    }, [data, loading]);

    async function onSuccess() {
        setIsSearch(true);
        setClient(null);
        setSearch('');
        setClients([]);
        props.onRequestClose();
    }

    function onCancel() {
        setIsSearch(true);
        setClient(null);
        setSearch('');
        setClients([]);
        props.onRequestClose();
    }

    function selectSearch() {
        setIsSearch(true);
    }

    function selectGroup() {
        setIsSearch(false);
        setClient(null);
        setSearch('');
        setClients([]);
    }

    function onPressClient(item: FreeMessageClient) {
        setClient(item);
        setSearch('');
        setClients([]);
    }

    function renderClient({item}: {item: FreeMessageClient}) {
        return (
            <HoverableView style={styles.row} hoverStyle={styles.hover}>
                <TouchableOpacity style={styles.client} onPress={() => onPressClient(item)}>
                    <ProfilePicture
                        style={styles.profilePicture}
                        pictureName={item.pictureName}
                    />
                    <SimpleText style={styles.name}>#{item.displayName}</SimpleText>
                </TouchableOpacity>
            </HoverableView>
        );
    }

    const message = (
        <SimpleText>
            Vous pouvez envoyer un message à un client ou à l'ensemble de vos clients payants
        </SimpleText>
    );

    const title = `Envoi de messages`;

    const recieverId = isSearch && client ? client.clientId : GROUP;
    const messageId = isSearch ? 'FREE' : 'GROUP';
    const displayName = isSearch && client ? client.displayName : 'tous vos clients payants';
    const pictureName = isSearch && client ? client.pictureName : '';
    const ready = !isSearch || client;

    return (
        <CustomModal
            title={title}
            iconName="paper-plane"
            message={message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={onCancel}
        >
            <View style={styles.container}>
                <View style={styles.buttons}>
                    <PressableItem
                        text={'Recherche client'}
                        selected={isSearch}
                        select={selectSearch}
                    />
                    <PressableItem
                        text={'Message groupé'}
                        selected={!isSearch}
                        select={selectGroup}
                    />
                </View>
                {isSearch && (
                    <View style={styles.search}>
                        <SearchBar
                            style={styles.searchBar}
                            placeholder="Chercher un client par pseudo"
                            onChangeText={setSearch}
                            value={search}
                            lightTheme={true}
                            allowFontScaling
                            leftIconContainerStyle={{}}
                            containerStyle={{
                                padding: 10,
                                alignSelf: 'center',
                                width: 350,
                                borderBottomWidth: 0,
                                borderTopWidth: 0,
                                backgroundColor: 'transparent',
                            }}
                            inputContainerStyle={{
                                borderTopWidth: 1,
                                borderBottomWidth: 1,
                                borderRightWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: Colors.primary,
                                backgroundColor: 'white',
                            }}
                            rightIconContainerStyle={{
                                width: 20,
                            }}
                        />
                        {emptyResult && (
                            <SimpleText style={styles.none}>Aucun résultat</SimpleText>
                        )}
                        {clients.length > 0 && client == null && (
                            <FlatList
                                data={clients}
                                renderItem={renderClient}
                                keyExtractor={(item) => item.clientId}
                                style={styles.list}
                            />
                        )}
                    </View>
                )}
                {ready && (
                    <MessageForm
                        exchangeType={ExchangeTypeEnum.EXPERT_TO_CLIENT}
                        recieverId={recieverId}
                        messageId={messageId}
                        displayName={displayName}
                        pictureName={pictureName}
                        messageType={MessageTypeEnum.FREE}
                        onSuccess={onSuccess}
                        onCancel={onCancel}
                    />
                )}
            </View>
        </CustomModal>
    );
}

type PressableItemProps = {
    select: () => void;
    text: string;
    selected: boolean;
};

function PressableItem(props: PressableItemProps) {
    function onPress() {
        props.select();
    }

    const background = props.selected
        ? {backgroundColor: Colors.light}
        : {backgroundColor: Colors.dark};
    const text = props.selected ? {color: 'white'} : {color: Colors.light};
    const url = props.selected
        ? Settings.getUrlPictoSelected()
        : Settings.getUrlPictoUnselected();

    return (
        <TouchableOpacity onPress={onPress}>
            <View style={[styles.item, background]}>
                <SimpleText style={text}>{props.text}</SimpleText>
                <Image
                    style={{height: 12, width: 12, marginLeft: 5, marginTop: 3}}
                    source={{uri: url}}
                    accessibilityLabel={props.text}
                />
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    buttons: {
        borderRadius: 5,
        padding: 10,
        backgroundColor: 'white',
        flexDirection: 'row',
        marginTop: 25,
        justifyContent: 'center',
        width: 600,
    },
    search: {
        width: 330,
        alignSelf: 'center',
    },
    searchBar: {
        alignSelf: 'center',
        borderRadius: 5,
        padding: 10,
        backgroundColor: 'white',
    },
    list: {
        borderRadius: 5,
        padding: 10,
        backgroundColor: 'white',
        width: 330,
        borderTopWidth: 0,
        alignSelf: 'center',
        height: 135,
    },
    none: {
        borderRadius: 5,
        padding: 10,
        backgroundColor: 'white',
        width: 330,
        borderTopWidth: 0,
        alignSelf: 'center',
        height: 135,
        textAlign: 'center',
    },
    client: {
        flexDirection: 'row',
        flexGrow: 1,
        padding: 3,
    },
    row: {
        marginVertical: 3,
    },
    hover: {
        backgroundColor: Colors.colorOpacity(Colors.primary, 'aa'),
        borderRadius: 5,
    },
    name: {
        fontSize: 12,
        marginLeft: 20,
        alignSelf: 'center',
    },
    profilePicture: {
        height: 25,
        width: 25,
        borderRadius: 25,
    },
    item: {
        flexDirection: 'row',
        borderRadius: 5,
        padding: 10,
        width: 160,
        justifyContent: 'center',
        marginHorizontal: 5,
    },
});
