import {Grade} from '~/components/expert/GradeList';
import type {Quality} from '~/components/expert/QualityList';
import type {GetLastExpertsQuery_getLastExperts_edges_node as ExpertLight} from '~/components/user/client/dashboard/types/GetLastExpertsQuery';
import {CommunicationType} from '~/components/user/client/review/types/calls';
import * as Colors from '~/constants/Colors';
import {endpoints} from '~/constants/Settings';
import {axios} from '~/helpers/network';
import {ExpertsDetailsQuery_findExperts_edges_node as Expert} from '~/queries/types/ExpertsDetailsQuery';
import {ExpertsDetailsQuery_findExperts_edges_node_messageParameters as MessageParameters} from '~/queries/types/ExpertsDetailsQuery';
import {ExpertsDetailsQuery_findExperts_edges_node_messageParameters_edges as MessageParameter} from '~/queries/types/ExpertsDetailsQuery';
import {ExpertsDetailsQueryWithLastReview_findExperts_edges_node as ExpertWithLastReview} from '~/queries/types/ExpertsDetailsQueryWithLastReview';
import {GetClientOffers} from '~/queries/types/GetClientOffers';
import {HomeExpertsQuery_homeExperts_edges_node_expert as HomeExpert} from '~/queries/types/HomeExpertsQuery';
import {QueryMe_me} from '~/queries/types/QueryMe';
import type {ExpertProfile_getExpert} from '~/screens/types/ExpertProfile';
import {QualityEnum, GradeEnum} from '~/types/enum';
import {TagTypesEnum} from '~/types/graphql-global-types';
import {MessageTypeEnum} from '~/types/graphql-global-types';
import {GenderEnum} from '~/types/graphql-global-types';

import {dayDiff} from './dates';
import {Key, notEmpty, filterEmpty, sorted, Args} from './list';

const DEFAULT_POSITIVE_REVIEW_RATE = 0;
const DEFAULT_COMMUNICATION_NUMBER = 0;
export const UNAVAILABLE = 'UNAVAILABLE';
export const AVAILABLE = 'AVAILABLE';
export const PAUSE = 'PAUSE';

export enum ServiceEnum {
    CALL = 'CALL',
    MESSAGE = 'MESSAGE',
    CHAT = 'CHAT',
}

export function getColor(status: string | null | undefined) {
    if (status == AVAILABLE) {
        return Colors.nice;
    }
    if (status == UNAVAILABLE) {
        return Colors.bad;
    }

    return Colors.pause;
}

export function getText(status: string | null | undefined) {
    if (status == AVAILABLE) {
        return 'Disponible';
    }
    if (status == UNAVAILABLE) {
        return 'Non disponible';
    }
    return 'En consultation';
}

export function minPriceText(expert: Expert | ExpertProfile_getExpert | null | undefined) {
    if (!expert) {
        return '';
    }

    const callPrice = expert.callParameters?.price;
    const call = callPrice?.priceDecimal;
    const chatPrice = expert.chatParameters?.chatPrice;
    const chat = chatPrice?.priceDecimal;

    if (!call && !chat) {
        return '';
    }

    if (call && !chat) {
        return `${callPrice.label} / min`;
    }

    if (!call && chat) {
        return `${chatPrice.label} / min`;
    }

    if (call && chat && call <= chat) {
        return `${callPrice.label} / min`;
    }

    if (call && chat && call > chat) {
        return `${chatPrice.label} / min`;
    }

    return '';
}

export function maxAvailability(
    expert: Expert | ExpertLight | ExpertProfile_getExpert | null | undefined
) {
    if (!expert) {
        return UNAVAILABLE;
    }
    const call = expert.expert?.callStatus;
    const message = expert.expert?.messageStatus;
    const chat = expert.expert?.chatStatus;

    const list = [call, message, chat];

    if (list.some((l) => l == AVAILABLE)) {
        return AVAILABLE;
    }
    if (list.some((l) => l == PAUSE)) {
        return PAUSE;
    }

    return UNAVAILABLE;
}

export function getNumberOfSpecialities(
    user: Expert | ExpertProfile_getExpert | ExpertLight | null | undefined
) {
    if (!user?.tags?.edges?.length) {
        return 0;
    }

    const skills = user.tags.edges.filter((tag) => {
        return tag?.node?.tagTypeId === TagTypesEnum.SPECIALTIES;
    });

    return skills.length;
}

export function getSpecialities(
    user: Expert | ExpertProfile_getExpert | ExpertLight | null | undefined
): string {
    if (!user?.tags?.edges?.length) {
        return '';
    }

    const skills = user.tags.edges.filter((tag) => {
        return tag?.node?.tagTypeId === TagTypesEnum.SPECIALTIES;
    });

    if (skills.length == 0) {
        return '';
    }
    const specialities = skills.slice(0, 3).map((tag) => {
        return tag?.node?.label;
    });

    return specialities.join(' | ');
}

export function isFavorite(me?: QueryMe_me | null, expertId?: string) {
    return me && me?.favorites?.edges.some((s) => s?.node?.expertId == expertId);
}

export function expertGrades(expert?: Expert | null): Grade[] {
    let grades = filterEmpty(expert?.reviewSummaryGrade?.edges).map((e, i) => {
        return {
            id: e?.node?.gradeId ? parseInt(e?.node?.gradeId) : 0,
            percentage: e?.node?.percentage,
            label: e?.node?.grade?.label,
        } as Grade;
    });
    if (!grades || grades.length == 0) {
        grades = [];
        grades.push({id: 1, percentage: 100, label: GradeEnum.VERY_SATISFIED} as Grade);
        grades.push({id: 2, percentage: 0, label: GradeEnum.SATISFIED} as Grade);
        grades.push({id: 3, percentage: 0, label: GradeEnum.DISSATISFIED} as Grade);
        grades.push({id: 4, percentage: 0, label: GradeEnum.VERY_DISSATISFIED} as Grade);
    }
    return grades;
}

export function expertQualities(expert?: Expert | null) {
    let qualities = expert?.reviewSummaryQuality?.edges.map((e, i) => {
        return {
            id: e?.node?.qualityId,
            starNumber: e?.node?.starNumber,
            label: e?.node?.quality?.label,
        } as Quality;
    });
    if (!qualities || qualities.length == 0) {
        qualities = [];
        qualities.push({id: '1', starNumber: 5, label: QualityEnum.LISTEN});
        qualities.push({id: '2', starNumber: 5, label: QualityEnum.HOST});
        qualities.push({id: '3', starNumber: 5, label: QualityEnum.PRICE});
        qualities.push({id: '4', starNumber: 5, label: QualityEnum.RESPONSE});
    }
    return qualities;
}

export function hasAnyService<
    T extends Expert | ExpertWithLastReview | HomeExpert | null | undefined
>(expert: T) {
    const message =
        expert?.messageParameters?.edges &&
        expert?.messageParameters?.edges?.some(
            (s) => s?.node?.messagePrice?.priceDecimal ?? 0 > 0
        );
    const call =
        expert?.callParameters?.price && expert?.callParameters?.price?.priceDecimal > 0;
    const chat =
        expert?.chatParameters?.chatPrice &&
        expert?.chatParameters?.chatPrice?.priceDecimal > 0;
    return message || call || chat;
}

export function hasCompleteProfile<
    T extends Expert | ExpertWithLastReview | HomeExpert | null | undefined
>(expert: T) {
    return expert?.expert?.isProfileComplete ?? false;
}

export function filterIncompleteProfile<
    T extends Expert | ExpertWithLastReview | HomeExpert | null | undefined
>(experts?: T[]): T[] {
    if (!experts) {
        return [];
    }
    return experts
        ?.filter(notEmpty)
        .filter((e) => e?.expert != null && e?.reviewSummary != null)
        .filter(hasAnyTag)
        .filter(hasAnyService)
        .filter(hasCompleteProfile);
}

export function hasAnyTag<
    T extends Expert | ExpertWithLastReview | HomeExpert | null | undefined
>(expert: T) {
    return expert?.tags?.edges && expert?.tags?.edges.length > 0;
}

export function getPriceMaximums(experts: Expert[]) {
    let maxCall = 0;
    let maxMessageSimpleQuestion = 0;
    let maxMessageCompleteStudy = 0;
    let maxChat = 0;
    for (let index = 0; index < experts.length; index++) {
        const expert = experts[index];

        if (expert?.messageParameters?.edges) {
            for (let idx = 0; idx < expert.messageParameters.edges.length; idx++) {
                const parameter = expert.messageParameters.edges[idx];
                const price = parameter?.node?.messagePrice?.priceDecimal ?? 0;
                if (parameter?.node?.type == MessageTypeEnum.SIMPLE_QUESTION) {
                    maxMessageSimpleQuestion = Math.max(maxMessageSimpleQuestion, price);
                }
                if (parameter?.node?.type == MessageTypeEnum.COMPLETE_STUDY) {
                    maxMessageCompleteStudy = Math.max(maxMessageCompleteStudy, price);
                }
            }
        }

        maxCall = Math.max(maxCall, expert?.callParameters?.price?.priceDecimal ?? 0);
        maxChat = Math.max(maxCall, expert?.chatParameters?.chatPrice?.priceDecimal ?? 0);
    }

    return {
        maxCall: maxCall,
        maxMessageSimpleQuestion: maxMessageSimpleQuestion,
        maxMessageCompleteStudy: maxMessageCompleteStudy,
        maxChat: maxChat,
    } as Args;
}

export function getCommunicationMaximum(experts: Expert[]) {
    let maxCom = 0;
    for (let index = 0; index < experts.length; index++) {
        const expert = experts[index];

        maxCom = Math.max(maxCom, communicationNumber(expert));
    }

    return {
        maxCom: maxCom,
    } as Args;
}

export function getArgs(
    experts: Expert[],
    me: QueryMe_me | null | undefined,
    offers: GetClientOffers | null | undefined
) {
    let maxCom = 0;
    for (let index = 0; index < experts.length; index++) {
        const expert = experts[index];

        maxCom = Math.max(maxCom, communicationNumber(expert));
    }

    return {
        maxCom: maxCom,
        me: me,
        offers: offers,
    } as Args;
}

/* Default sorting : 
 - First score availability (0 / 10 / 20 / 30)
 - Then add a multi-criteria score between 0 and 3
 */
function defaultSort(expert: Expert, args?: Args) {
    if (!args) {
        return 0;
    }

    const availabilityScore = available(expert); /* between 0 and 30 */
    const reviewsScore = positiveReviewRate(expert) / 100;
    const comNumber = communicationNumber(expert);
    const comScore = comNumber == 0 ? 0 : Math.log10(comNumber) / Math.log10(args.maxCom);
    const offerScore = offer(expert, args.me, args.offers);
    const total = availabilityScore + reviewsScore + comScore + offerScore;

    // console.log(
    //     `${expert.profile?.displayName} : available=${availabilityScore} + review=${reviewsScore} + com=${comScore} + offer=${offerScore} = ${total}`
    // );

    return total;
}

function offer(
    expert: Expert,
    me: QueryMe_me | null | undefined,
    offers: GetClientOffers | null | undefined
) {
    const offer = getOffer(me, offers, expert.id);
    if (offer == 0) {
        return 0;
    }

    const scoreCallOffer = expert.callParameters?.acceptOffer ? 0.25 : 0;
    const scoreChatOffer = expert.chatParameters?.acceptOffer ? 0.25 : 0;

    return scoreCallOffer + scoreChatOffer;
}

function communicationNumber(expert: Expert) {
    return expert?.reviewSummary?.communicationNumber ?? DEFAULT_COMMUNICATION_NUMBER;
}

function positiveReviewRate(expert: Expert) {
    return expert?.reviewSummary?.positiveReviewRate ?? DEFAULT_POSITIVE_REVIEW_RATE;
}

function priceScore(expert: Expert, args?: Args) {
    if (!args) {
        return 0;
    }

    let messageSimpleQuestion = undefined;
    let messageCompleteStudy = undefined;

    if (expert?.messageParameters?.edges) {
        for (let idx = 0; idx < expert.messageParameters.edges.length; idx++) {
            const parameter = expert.messageParameters.edges[idx];
            const price = parameter?.node?.messagePrice?.priceDecimal ?? 0;
            if (parameter?.node?.type == MessageTypeEnum.SIMPLE_QUESTION) {
                messageSimpleQuestion = price;
            }
            if (parameter?.node?.type == MessageTypeEnum.COMPLETE_STUDY) {
                messageCompleteStudy = price;
            }
        }
    }

    const call = expert?.callParameters?.price?.priceDecimal;
    const chat = expert?.chatParameters?.chatPrice?.priceDecimal;

    let ratios = [];

    if (call) {
        ratios.push(call / args.maxCall);
    }
    if (chat) {
        ratios.push(chat / args.maxChat);
    }
    if (messageSimpleQuestion) {
        ratios.push(messageSimpleQuestion / args.maxMessageSimpleQuestion);
    }
    if (messageCompleteStudy) {
        ratios.push(messageCompleteStudy / args.maxMessageCompleteStudy);
    }

    let sum = 0;
    for (var i = 0; i < ratios.length; i++) {
        sum += ratios[i];
    }

    const avg = sum / ratios.length;

    return avg;
}

function created(expert: Expert) {
    return new Date(expert?.identity?.created).getTime();
}

function getAvailabilityScore(status: string | null | undefined, max: number) {
    if (status == AVAILABLE) {
        return max;
    }
    if (status == PAUSE) {
        return 1;
    }
    return 0;
}

function available(expert: Expert) {
    let score = 0;
    score += getAvailabilityScore(expert.expert?.callStatus, 10);
    score += getAvailabilityScore(expert.expert?.chatStatus, 10);
    score += getAvailabilityScore(expert.expert?.messageStatus, 5);

    return score;
}

export const expertKeys = {
    defaultSort,
    communicationNumber,
    positiveReviewRate,
    priceScore,
    created,
    available,
};

type Order = {
    key: Key<Expert>;
    reverse?: boolean;
    args?: Args;
};

function getSortBy(
    experts: Expert[],
    me: QueryMe_me | null | undefined,
    offers: GetClientOffers | null | undefined,
    index: string
) {
    switch (index) {
        case '-1':
            return {
                key: expertKeys.defaultSort,
                args: getArgs(experts, me, offers),
                reverse: true,
            } as Order;
        case '0':
            return {
                key: expertKeys.positiveReviewRate,
                reverse: true,
            } as Order;
        case '1':
            return {
                key: expertKeys.communicationNumber,
                reverse: true,
            } as Order;
        case '2':
            return {
                key: expertKeys.priceScore,
                reverse: false,
                args: getPriceMaximums(experts),
            } as Order;
        case '3':
            return {
                key: expertKeys.created,
                reverse: true,
            };
        default:
            return {
                key: expertKeys.defaultSort,
                args: getCommunicationMaximum(experts),
                reverse: true,
            };
    }
}

export function sortExperts<T extends Expert | ExpertWithLastReview>(
    experts: T[],
    me: QueryMe_me | null | undefined,
    offers: GetClientOffers | null | undefined,
    index: string
) {
    const order = getSortBy(experts, me, offers, index);

    if (!order) {
        return experts;
    }

    return sorted(experts, order.key, order.reverse, order.args);
}

export type Tag = string;
export type {Expert, ExpertWithLastReview};

function comparePrice(a: MessageParameter | null, b: MessageParameter | null) {
    const na = a?.node?.messagePrice?.priceDecimal;
    const nb = b?.node?.messagePrice?.priceDecimal;
    if (na && nb) {
        return na - nb;
    } else {
        return 0;
    }
}

export function getSmallerMessagePrice(parameters: MessageParameters | null | undefined) {
    if (parameters && parameters.edges && parameters.edges.length > 0) {
        const parameter = parameters.edges.slice().sort(comparePrice)[0];
        if (parameter?.node?.messagePrice?.messagePriceId) {
            return {
                price: parameter.node?.messagePrice?.label,
            };
        }
    }
    return null;
}

export function getIndexFromGender(gender: GenderEnum) {
    if (gender == GenderEnum.WOMAN) {
        return 0;
    } else if (gender == GenderEnum.MAN) {
        return 1;
    } else {
        return 2;
    }
}

export function getGenderFromIndex(index: number) {
    if (index == 0) {
        return GenderEnum.WOMAN;
    } else if (index == 1) {
        return GenderEnum.MAN;
    } else {
        return GenderEnum.OTHER;
    }
}

const RATIO = 41;
const PACK_BENEFIT_EXPERT = 0.7;
const MIN_CALL_DURATION = 120;
const MIN_CHAT_DURATION = 60;

export function getBenefit(
    cost: number | null | undefined,
    pack: boolean | null | undefined,
    duration: number | null | undefined,
    type: CommunicationType
) {
    if (pack && duration) {
        if (type == CommunicationType.CALL && duration < MIN_CALL_DURATION) {
            return 0;
        }
        if (type == CommunicationType.CHAT && duration < MIN_CHAT_DURATION) {
            return 0;
        }
        return Math.ceil(duration / 60) * PACK_BENEFIT_EXPERT;
    } else if (cost) {
        return (cost * RATIO) / 100;
    } else {
        return 0;
    }
}

export async function getCurrentMonthBenefit() {
    try {
        const response = await axios.get(endpoints.getCurrentMonthBenefit);
        if (response && response.data) {
            const benefitString = response.data['benefit'];
            if (benefitString) {
                const benefitFloat = parseFloat(benefitString);
                return benefitFloat.toFixed(2);
            }
        }
    } catch (error) {
        console.log(error);
    }
    return null;
}

const DEFAULT_OFFER_MINUTES = 5;

export function getOffer(
    me: QueryMe_me | null | undefined,
    offers: GetClientOffers | null | undefined,
    expertId: string | undefined
) {
    if (!me) {
        return DEFAULT_OFFER_MINUTES;
    }

    if (!offers || !expertId) {
        return 0;
    }

    const available = filterEmpty(
        offers.getClientOffers?.edges.filter((o) => o?.node?.status == 'AVAILABLE')
    );

    const unrestricted = available.filter((o) => !o?.node?.isRestricted);

    if (unrestricted.length > 0) {
        const sorted_offers = sorted(
            unrestricted,
            (o) => Date.parse(o.node?.expirationDate),
            false
        );

        const offer = sorted_offers[0];
        return offer.node?.offer?.freeMinuteInteger ?? 0;
    } else {
        const alreadyUsed =
            offers.getClientOffers?.edges.filter(
                (o) => o?.node?.isRestricted && 'User:' + o?.node?.expertId == expertId
            ).length ?? 0;

        if (alreadyUsed > 0) {
            return 0;
        } else {
            return DEFAULT_OFFER_MINUTES;
        }
    }
}

export function isNew(user: Expert | ExpertProfile_getExpert | null | undefined) {
    if (!user) {
        return false;
    }

    const now = new Date(Date.now());
    const createdDate = new Date(user?.expert?.created);
    const isNew = dayDiff(createdDate, now) <= 90;
    return isNew;
}
