import {gql, useQuery} from '@apollo/client';

import {GetMinuteBalance} from './types/GetMinuteBalance';
import {GetMinuteHistory} from './types/GetMinuteHistory';

const GET_MINUTE_BALANCE = gql`
    query GetMinuteBalance {
        getMinuteBalance {
            minutes
            expireAt
            updated
        }
    }
`;

export default function useMinuteBalance(skip: boolean) {
    return useQuery<GetMinuteBalance>(GET_MINUTE_BALANCE, {
        skip: skip,
        fetchPolicy: 'network-only',
    });
}

const GET_MINUTE_HISTORY = gql`
    query GetMinuteHistory {
        getMinuteHistory {
            edges {
                node {
                    created
                    minutes
                    expireAt
                    transaction
                }
            }
        }
    }
`;

export function useMinuteHistory() {
    return useQuery<GetMinuteHistory>(GET_MINUTE_HISTORY, {
        fetchPolicy: 'network-only',
    });
}
