import * as yup from 'yup';
import {fr} from 'yup-locales';

yup.setLocale(fr);

export type YupTypes = {
    identifiant: string;
    birthdate10: string;
    email: string;
    password: string;
    passwordConfirm: string;
    job: string;
    shortDescription: string;
    longDescription: string;
    experience: string;
    catchPhrase: string;
    message: string;
    callValue: string;
    chatValue: string;
    callAcceptOffer: boolean;
    chatAcceptOffer: boolean;
    simpleMessageValue: string;
    simpleMessageDescription: string;
    completeMessageValue: string;
    completeMessageDescription: string;
    subject: string;
    content: string;
    chat: string;
    surname: string;
    lastname: string;
    address: string;
    addressComplement: string;
    postalCode: string;
    city: string;
    country: string;
    presentation: string;
    career: string;
    profil: string;
    cgv: boolean;
    newsletter: boolean;
    firstname: string;
    birthname: string;
    othername: string;
    birthdate: string;
    birthhour: string;
    birthplace: string;
    birthdaypostalcode: string;
    birthdaycity: string;
    spousefirstname: string;
    spousebirthname: string;
    spouseothername: string;
    spousebirthdate: string;
    spousebirthhour: string;
    spousebirthplace: string;
    astrologytype: string;
    phone: string;
};

export const yupLabels = {
    identifiant: 'Pseudo',
    birthdate10: 'Date de naissance',
    email: 'Adresse email',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmation du mot de passe',
    subject: 'Sujet du message',
    message: 'Message',
    job: 'Fonction',
    shortDescription: 'Description courte',
    longDescription: 'Description longue',
    experience: 'Expérience',
    catchPhrase: "Phrase d'accroche",
    callValue: 'Tarif appel',
    chatValue: 'Tarif tchat',
    callAcceptOffer: '',
    chatAcceptOffer: '',
    simpleMessageValue: 'Tarif message',
    completeMessageValue: 'Tarif message',
    simpleMessageDescription: 'Description',
    completeMessageDescription: 'Description',
    content: 'Contenu du message',
    chat: 'Contenu du message',
    surname: 'Prénom',
    lastname: 'Nom',
    address: 'Adresse',
    addressComplement: 'Complément',
    postalCode: 'Code Postal',
    city: 'Ville',
    country: 'Pays',
    presentation: 'Présentation',
    career: 'Parcours',
    profil: 'Profil',
    cgv: 'Termes & Conditions de Kyvoitou.fr',
    newsletter: 'Newsletter',
    firstname: 'Prénom',
    birthname: 'Nom de naissance',
    othername: 'Autres prénoms',
    birthdate: 'Date de naissance',
    birthhour: 'Heure de naissance',
    birthplace: 'Lieu de naissance',
    birthdaypostalcode: "Code postal du lieu d'anniversaire",
    birthdaycity: "Ville du lieu d'anniversaire",
    spousefirstname: 'Nom de naissance de votre conjoint',
    spousebirthname: 'Prénom de votre conjoint',
    spouseothername: 'Autres de prénom de votre conjoint',
    spousebirthdate: 'Date de naissance de votre conjoint',
    spousebirthhour: 'Heure de naissance de votre conjoint',
    spousebirthplace: 'Lieu de naissance de votre conjoint',
    astrologytype: 'Étude',
};

export const yupRules = {
    email: yup.string().email().required().label(yupLabels.email),
    birthdate10: yup.string().required().label(yupLabels.birthdate10),
    password: yup.string().min(6).required().label(yupLabels.password),
    identifiant: yup.string().min(3).max(100).required().label(yupLabels.identifiant),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Les mot de passe doivent être identiques')
        .min(6)
        .required()
        .label(yupLabels.passwordConfirm),
    cgv: yup
        .bool()
        .required()
        .oneOf([true], 'Vous devez accepter les Termes & Conditions de Kyvoitou.fr'),
    newsletter: yup.bool(),
    subject: yup.string().min(10).max(100).required().label(yupLabels.subject),
    message: yup.string().min(100).required().label(yupLabels.message),
    job: yup.string().max(500).required().label(yupLabels.job),
    shortDescription: yup.string().max(500).required().label(yupLabels.shortDescription),
    longDescription: yup.string().max(5000).required().label(yupLabels.longDescription),
    experience: yup.string().max(500).required().label(yupLabels.experience),
    catchPhrase: yup.string().max(500).required().label(yupLabels.catchPhrase),
    callValue: yup.string().required().label(yupLabels.callValue),
    chatValue: yup.string().required().label(yupLabels.chatValue),
    callAcceptOffer: yup.bool().oneOf([true, false], ''),
    chatAcceptOffer: yup.bool().oneOf([true, false], ''),
    simpleMessageValue: yup.string().required().label(yupLabels.simpleMessageValue),
    completeMessageValue: yup.string().required().label(yupLabels.completeMessageValue),
    simpleMessageDescription: yup
        .string()
        .max(500)
        .notRequired()
        .when('simpleMessageValue', {
            is: (val: string) => val != '0',
            then: yup.string().max(500).required().label(yupLabels.simpleMessageDescription),
            otherwise: yup
                .string()
                .max(500)
                .notRequired()
                .label(yupLabels.simpleMessageDescription),
        }),
    completeMessageDescription: yup
        .string()
        .max(500)
        .notRequired()
        .when('completeMessageValue', {
            is: (val: string) => val != '0',
            then: yup.string().max(500).required().label(yupLabels.completeMessageDescription),
            otherwise: yup
                .string()
                .max(500)
                .notRequired()
                .label(yupLabels.completeMessageDescription),
        }),
    content: yup.string().max(5000).required().label(yupLabels.content),
    chat: yup.string().max(300).required().label(yupLabels.chat),
    surname: yup.string().max(100).label(yupLabels.surname),
    lastname: yup.string().max(100).label(yupLabels.lastname),
    address: yup.string().max(200).label(yupLabels.address),
    addressComplement: yup.string().max(200).label(yupLabels.addressComplement),
    postalCode: yup.string().max(10).label(yupLabels.postalCode),
    city: yup.string().max(50).label(yupLabels.city),
    country: yup.string().max(50).label(yupLabels.country),
    presentation: yup.string().min(100).max(5000).required().label(yupLabels.presentation),
    career: yup.string().min(100).max(5000).required().label(yupLabels.career),
    profil: yup.string().min(100).max(5000).required().label(yupLabels.profil),
    firstname: yup.string().max(50).required().label(yupLabels.firstname),
    birthname: yup.string().max(50).required().label(yupLabels.birthname),
    othername: yup.string().max(50).label(yupLabels.othername),
    birthdate: yup.string().max(50).required().label(yupLabels.birthdate),
    birthhour: yup.string().max(50).required().label(yupLabels.birthhour),
    birthplace: yup.string().max(50).required().label(yupLabels.birthplace),
    birthdaypostalcode: yup
        .string()
        .max(50)
        .notRequired()
        .when('astrologytype', {
            is: (val: string) => val == '2', // Météo de l'année
            then: yup.string().max(50).required().label(yupLabels.birthdaypostalcode),
            otherwise: yup.string().max(50).notRequired().label(yupLabels.birthdaypostalcode),
        }),
    birthdaycity: yup
        .string()
        .max(50)
        .notRequired()
        .when('astrologytype', {
            is: (val: string) => val == '2', // Météo de l'année
            then: yup.string().max(50).required().label(yupLabels.birthdaycity),
            otherwise: yup.string().max(50).notRequired().label(yupLabels.birthdaycity),
        }),
    spousefirstname: yup
        .string()
        .max(50)
        .notRequired()
        .when('astrologytype', {
            is: (val: string) => val == '4', // Compatibilité couple
            then: yup.string().max(50).required().label(yupLabels.spousefirstname),
            otherwise: yup.string().max(50).notRequired().label(yupLabels.spousefirstname),
        }),
    spousebirthname: yup
        .string()
        .max(50)
        .notRequired()
        .when('astrologytype', {
            is: (val: string) => val == '4', // Compatibilité couple
            then: yup.string().max(50).required().label(yupLabels.spousebirthname),
            otherwise: yup.string().max(50).notRequired().label(yupLabels.spousebirthname),
        }),
    spouseothername: yup.string().max(50).notRequired(),
    spousebirthdate: yup
        .string()
        .max(50)
        .notRequired()
        .when('astrologytype', {
            is: (val: string) => val == '4', // Compatibilité couple
            then: yup.string().max(50).required().label(yupLabels.spousebirthdate),
            otherwise: yup.string().max(50).notRequired().label(yupLabels.spousebirthdate),
        }),
    spousebirthhour: yup
        .string()
        .max(50)
        .notRequired()
        .when('astrologytype', {
            is: (val: string) => val == '4', // Compatibilité couple
            then: yup.string().max(50).required().label(yupLabels.spousebirthhour),
            otherwise: yup.string().max(50).notRequired().label(yupLabels.spousebirthhour),
        }),
    spousebirthplace: yup
        .string()
        .max(50)
        .notRequired()
        .when('astrologytype', {
            is: (val: string) => val == '4', // Compatibilité couple
            then: yup.string().max(50).required().label(yupLabels.spousebirthplace),
            otherwise: yup.string().max(50).notRequired().label(yupLabels.spousebirthplace),
        }),
    astrologytype: yup.string().required().label(yupLabels.astrologytype),
};

export default yup;
