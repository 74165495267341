import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

import Button from '~/components/common/Buttons';
import {Bloc} from '~/components/common/Containers';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';
import {useAlert} from '~/contexts/AlertContext';

import PopupSupport from './PopupSupport';

export default function BlocSupport() {
    const alert = useAlert();
    const [modalSupportVisible, setModalSupportVisible] = useState(false);

    function openSupport() {
        setModalSupportVisible(true);
    }

    function onSuccessSupport() {
        setModalSupportVisible(false);
        alert({
            message: 'Votre message a bien été envoyé',
            width: 400,
        });
    }

    function onRequestCloseSupport() {
        setModalSupportVisible(false);
    }

    return (
        <Bloc>
            <View style={styles.titleTextContainer}>
                <SimpleText style={styles.title}>Une question ?</SimpleText>
                <SimpleText style={styles.title}>
                    Besoin d'aide pour choisir votre spirite ?
                </SimpleText>
            </View>
            <Button
                onPress={openSupport}
                style={styles.button}
                title="Écrire au support"
                titleStyle={{marginHorizontal: 10}}
                icon={<Icons.MailSend size={15} style={{color: 'white'}} />}
            ></Button>
            <SimpleText style={styles.description}>
                Notre service client est aussi là pour vous du lundi au vendredi de 9h à 19h au
                :
            </SimpleText>
            <SimpleText style={styles.telephone}>{Settings.support_telephone}</SimpleText>
            <SimpleText style={styles.telephoneDescription}>Prix d'un appel local</SimpleText>
            <PopupSupport
                onSuccess={onSuccessSupport}
                visible={modalSupportVisible}
                onRequestClose={onRequestCloseSupport}
            />
        </Bloc>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16,
        height: 130,
        borderRadius: 5,
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        margin: 16,
    },
    titleTextContainer: {
        margin: 10,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 14,
        alignSelf: 'center',
    },
    button: {
        margin: 15,
        width: 200,
        alignSelf: 'center',
        color: 'white',
    },
    description: {
        margin: 15,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    telephone: {
        color: Colors.highlight,
        fontWeight: 'bold',
        fontSize: 16,
        alignSelf: 'center',
        textAlign: 'center',
    },
    telephoneDescription: {
        marginTop: 10,
        marginBottom: 15,
        color: Colors.highlight,
        fontSize: 12,
        alignSelf: 'center',
        textAlign: 'center',
    },
});
