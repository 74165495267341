import * as React from 'react';

import {library} from '@fortawesome/fontawesome-svg-core';
import {IconName, IconPrefix} from '@fortawesome/fontawesome-svg-core';
import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcPaypal,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faBloggerB,
    faReadme,
} from '@fortawesome/free-brands-svg-icons';
import {
    faEnvelope as farEnvelope,
    faEnvelopeOpen,
    faStar as farStar,
    faCommentAlt,
    faSmile as farSmile,
} from '@fortawesome/free-regular-svg-icons';
import {
    faCheck,
    faPhoneAlt,
    faEnvelope as fasEnvelope,
    faEnvelopeOpenText,
    faPaperPlane,
    faComments,
    faCalendar,
    faCaretDown,
    faTrash,
    faPaperclip,
    faUserCircle,
    faUser,
    faSearch,
    faHome,
    faBars,
    faCog,
    faHandshake,
    faQuestionCircle,
    faPlus,
    faStar as fasStar,
    faSortUp,
    faSortDown,
    faReply,
    faInfo,
    faClipboard,
    faSave,
    faCommentDots,
    faStopCircle,
    faSignOutAlt,
    faPen,
    faCheckCircle,
    faUsers,
    faFileAlt,
    faRedoAlt,
    faChartBar,
    faHeadphones,
    faFileAudio,
    faMicrophone,
    faEye,
    faEyeSlash,
    faCircle,
    faAngleLeft,
    faAngleRight,
    faLifeRing,
    faSlidersH,
    faWindowClose,
    faGift,
    faSmile,
    faVolumeUp,
    faEdit,
    faLock,
    faCreditCard,
    faAddressCard,
    faBell,
    faHouseUser,
    faUserSlash,
    faIdBadge,
    faPortrait,
    faUnlockAlt,
    faUserTie,
    faPoll,
    faChartLine,
    faAddressBook,
    faMailBulk,
    faHandHoldingUsd,
    faImage,
    faBook,
    faMedal,
    faUserClock,
    faList,
    faListOl,
    faListUl,
    faInfoCircle,
    faScroll,
    faCookieBite,
    faClock,
    faCogs,
    faFileContract,
    faHandPointRight,
    faHandPointDown,
    faHourglassStart,
    faSync,
    faMoneyCheckAlt,
    faTags,
    faBoxOpen,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';

import * as Colors from '~/constants/Colors';

import {RotaryPhone} from './RotaryPhone';

library.add(
    farEnvelope,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    farStar,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcPaypal,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faBloggerB,
    faReadme,
    faCheck,
    faPhoneAlt,
    fasEnvelope,
    faPaperPlane,
    faComments,
    faCalendar,
    faCaretDown,
    faTrash,
    faPaperclip,
    faUserCircle,
    faUser,
    faSearch,
    faHome,
    faBars,
    faCog,
    faHandshake,
    faQuestionCircle,
    faPlus,
    fasStar,
    faSortUp,
    faSortDown,
    faReply,
    faInfo,
    faClipboard,
    faSave,
    faCommentDots,
    faStopCircle,
    faSignOutAlt,
    faPen,
    faCheckCircle,
    faUsers,
    faFileAlt,
    faRedoAlt,
    faChartBar,
    faHeadphones,
    faFileAudio,
    faMicrophone,
    faEye,
    faEyeSlash,
    faCircle,
    faAngleLeft,
    faAngleRight,
    faLifeRing,
    faSlidersH,
    faWindowClose,
    faGift,
    faSmile,
    faVolumeUp,
    faEdit,
    faLock,
    faCreditCard,
    faAddressCard,
    faBell,
    faHouseUser,
    faUserSlash,
    faIdBadge,
    faPortrait,
    faUnlockAlt,
    faUserTie,
    faPoll,
    faChartLine,
    faAddressBook,
    faMailBulk,
    faHandHoldingUsd,
    faImage,
    faBook,
    faMedal,
    faUserClock,
    faList,
    faListOl,
    faListUl,
    faInfoCircle,
    faScroll,
    faCommentAlt,
    farSmile,
    faCookieBite,
    faClock,
    faCogs,
    faFileContract,
    faHandPointRight,
    faHandPointDown,
    faHourglassStart,
    faSync,
    faMoneyCheckAlt,
    faTags,
    faBoxOpen
);

// Using react fontawesome because react-native-fontawesome has an error :
// https://github.com/FortAwesome/react-native-fontawesome/pull/74
export interface BaseIconProps extends Omit<FontAwesomeIconProps, 'size'> {
    size: number;
}

export interface IconProps extends Omit<BaseIconProps, 'icon'> {}

export function Icon({color, size, style, ...otherProps}: BaseIconProps) {
    const fontSize = {fontSize: size} as React.CSSProperties;
    return (
        <FontAwesomeIcon
            color={color ? color : 'white'}
            style={{...style, ...fontSize}}
            {...otherProps}
        />
    );
}

export const Visa = (props: IconProps) => <Icon icon={['fab', 'cc-visa']} {...props} />;
export const Mastercard = (props: IconProps) => (
    <Icon icon={['fab', 'cc-mastercard']} {...props} />
);
export const Paypal = (props: IconProps) => <Icon icon={['fab', 'cc-paypal']} {...props} />;
export const Amex = (props: IconProps) => <Icon icon={['fab', 'cc-amex']} {...props} />;
export const Jcb = (props: IconProps) => <Icon icon={['fab', 'cc-jcb']} {...props} />;
export const Discover = (props: IconProps) => (
    <Icon icon={['fab', 'cc-discover']} {...props} />
);
export const Diners = (props: IconProps) => (
    <Icon icon={['fab', 'cc-diners-club']} {...props} />
);
export const Check = (props: IconProps) => <Icon icon="check" {...props} />;
export const Phone = (props: IconProps) => (
    <RotaryPhone color={props.color} style={props.style} size={props.size} />
);
export const Mail = (props: IconProps) => <Icon icon="envelope" {...props} />;
export const Envelope = (props: IconProps) => <Icon icon={['far', 'envelope']} {...props} />;
export const MailOpen = (props: IconProps) => (
    <Icon icon={['far', 'envelope-open']} {...props} />
);
export const MailSend = (props: IconProps) => <Icon icon="paper-plane" {...props} />;
export const Chat = (props: IconProps) => <Icon icon="comments" {...props} />;
export const Calendar = (props: IconProps) => <Icon icon="calendar" {...props} />;
export const Down = (props: IconProps) => <Icon icon="caret-down" {...props} />;
export const Trash = (props: IconProps) => <Icon icon="trash" {...props} />;
export const Attachment = (props: IconProps) => <Icon icon="paperclip" {...props} />;
export const User = (props: IconProps) => <Icon icon="user-circle" {...props} />;
export const Profile = (props: IconProps) => <Icon icon="user" {...props} />;
export const Search = (props: IconProps) => <Icon icon="search" {...props} />;
export const Home = (props: IconProps) => <Icon icon="home" {...props} />;
export const Blog = (props: IconProps) => <Icon icon={['fab', 'blogger-b']} {...props} />;
export const Burger = (props: IconProps) => <Icon icon="bars" {...props} />;
export const Settings = (props: IconProps) => <Icon icon="cog" {...props} />;
export const Commitments = (props: IconProps) => <Icon icon="handshake" {...props} />;
export const Question = (props: IconProps) => <Icon icon="question-circle" {...props} />;
export const Add = (props: IconProps) => <Icon icon="plus" {...props} />;
export const Star = (props: IconProps) => <Icon icon="star" {...props} />;
export const Favorite = ({
    isFavorite,
    ...props
}: IconProps & {isFavorite?: boolean | null}) => (
    <Icon icon={[isFavorite ? 'fas' : 'far', 'star']} {...props} />
);

export function MailSelectable(props: IconProps & {isSelected?: boolean | null}) {
    const {isSelected, ...otherProps} = props;
    const color = props.isSelected ? Colors.primary : 'black';
    return <Icon icon={'envelope'} color={color} {...otherProps} />;
}

export function MailSendSelectable(props: IconProps & {isSelected?: boolean | null}) {
    const {isSelected, ...otherProps} = props;
    const color = props.isSelected ? Colors.primary : 'black';
    return <Icon icon={'paper-plane'} color={color} {...otherProps} />;
}

export function TrashSelectable(props: IconProps & {isSelected?: boolean | null}) {
    const {isSelected, ...otherProps} = props;
    const color = isSelected ? Colors.primary : 'black';
    return <Icon icon={'trash'} color={color} {...otherProps} />;
}

export const SortUp = (props: IconProps) => <Icon icon="sort-up" {...props} />;
export const SortDown = (props: IconProps) => <Icon icon="sort-down" {...props} />;
export const Reply = (props: IconProps) => <Icon icon="reply" {...props} />;
export const Info = (props: IconProps) => <Icon icon="info-circle" {...props} />;
export const Note = (props: IconProps) => <Icon icon="clipboard" {...props} />;
export const Save = (props: IconProps) => <Icon icon="save" {...props} />;
export const Comment = (props: IconProps) => <Icon icon="comment-dots" {...props} />;
export const Stop = (props: IconProps) => <Icon icon="stop-circle" {...props} />;
export const SignOut = (props: IconProps) => <Icon icon="sign-out-alt" {...props} />;
export const Read = (props: IconProps) => <Icon icon={['fab', 'readme']} {...props} />;
export const Write = (props: IconProps) => <Icon icon="comment-dots" {...props} />;
export const Edit = (props: IconProps) => <Icon icon="pen" {...props} />;
export const Validate = (props: IconProps) => <Icon icon="check-circle" {...props} />;
export const Users = (props: IconProps) => <Icon icon="users" {...props} />;
export const File = (props: IconProps) => <Icon icon="file-alt" {...props} />;
export const Redo = (props: IconProps) => <Icon icon="redo-alt" {...props} />;
export const Chart = (props: IconProps) => <Icon icon="chart-bar" {...props} />;
export const HeadPhone = (props: IconProps) => <Icon icon="headphones" {...props} />;
export const AudioFile = (props: IconProps) => <Icon icon="file-audio" {...props} />;
export const Micro = (props: IconProps) => <Icon icon="microphone" {...props} />;
export const See = (props: IconProps) => <Icon icon="eye" {...props} />;
export const Circle = (props: IconProps) => <Icon icon="circle" {...props} />;
export const Return = (props: IconProps) => <Icon icon="angle-left" {...props} />;
export const Next = (props: IconProps) => <Icon icon="angle-right" {...props} />;
export const LifeRing = (props: IconProps) => <Icon icon="life-ring" {...props} />;
export const Slider = (props: IconProps) => <Icon icon="sliders-h" {...props} />;
export const Close = (props: IconProps) => <Icon icon="window-close" {...props} />;
export const Gift = (props: IconProps) => <Icon icon="gift" {...props} />;
export const Smile = (props: IconProps) => <Icon icon="smile" {...props} />;
export const Sound = (props: IconProps) => <Icon icon="volume-up" {...props} />;
export const EditBox = (props: IconProps) => <Icon icon="edit" {...props} />;
export const Lock = (props: IconProps) => <Icon icon="lock" {...props} />;
export const Scroll = (props: IconProps) => <Icon icon="scroll" {...props} />;
export const SmileAlt = (props: IconProps) => <Icon icon={['far', 'smile']} {...props} />;
export const ChatAlt = (props: IconProps) => <Icon icon={['far', 'comment-alt']} {...props} />;
export const Cogs = (props: IconProps) => <Icon icon={'cogs'} {...props} />;
export const Clock = (props: IconProps) => <Icon icon={'clock'} {...props} />;
export const Contract = (props: IconProps) => <Icon icon={'file-contract'} {...props} />;
export const Hand = (props: IconProps) => <Icon icon={'hand-point-right'} {...props} />;
export const HandDown = (props: IconProps) => <Icon icon={'hand-point-down'} {...props} />;
export const Flag = (props: IconProps) => <Icon icon={'flag'} {...props} />;
export const Eye = (props: IconProps) => <Icon icon={'eye'} {...props} />;
export const Minute = (props: IconProps) => <Icon icon={'hourglass-start'} {...props} />;
export const Sync = (props: IconProps) => <Icon icon={'sync'} {...props} />;
export const List = (props: IconProps) => <Icon icon={'list'} {...props} />;
export const ListOl = (props: IconProps) => <Icon icon={'list-ol'} {...props} />;
export const ListUl = (props: IconProps) => <Icon icon={'list-ul'} {...props} />;
export const Money = (props: IconProps) => <Icon icon={'money-check-alt'} {...props} />;
export const Price = (props: IconProps) => <Icon icon={'tags'} {...props} />;
export const Box = (props: IconProps) => <Icon icon={'box-open'} {...props} />;

export function Secure(props: IconProps & {isSecure?: boolean | null}) {
    const {isSecure, ...otherProps} = props;
    const icon = isSecure ? 'eye-slash' : 'eye';
    return <Icon icon={icon} color={Colors.primary} {...otherProps} />;
}

export function getIcon(
    iconName: string,
    size: number,
    color: string,
    style?: React.CSSProperties,
    prefix: IconPrefix = 'fas'
) {
    if (iconName == 'phone-alt') {
        return <Phone size={size} color={color} style={style} />;
    } else {
        return (
            <Icon
                icon={[prefix, iconName as IconName]}
                size={size}
                color={color}
                style={style}
            />
        );
    }
}
