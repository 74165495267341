import React, {useState, useEffect, useRef} from 'react';
import {View, Text, TouchableOpacity, StyleSheet, FlatList} from 'react-native';

import HoverableView from '~/components/common/HoverableView';
import * as Icons from '~/components/common/Icons';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';

type DropdownProps = {
    label: string;
    isFocused: boolean;
    options: DropdownItem[];
    onPressItem: (parameter: string, value: string) => void;
    parameter: string;
};

export type DropdownItem = {
    title: string;
    value: string;
};

export default function Dropdown(props: DropdownProps) {
    const node = useRef();

    const [open, setOpen] = useState(false);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    };

    function onPressTabItem(item: DropdownItem) {
        props.onPressItem(props.parameter, item.value, item.tag);
        setOpen(false);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    let isFocusedTextStyle = {};
    if (props.isFocused) {
        isFocusedTextStyle = {
            color: Colors.primary,
            fontWeight: 'bold',
        };
    } else {
        isFocusedTextStyle = {
            color: Colors.secondary,
            fontWeight: 'normal',
        };
    }

    function renderOption({item}: {item: DropdownItem}) {
        return (
            <HoverableView style={styles.tab} hoverStyle={styles.tabHover}>
                <TouchableOpacity onPress={(e) => onPressTabItem(item)}>
                    <SimpleText style={styles.itemText}>{item.title}</SimpleText>
                </TouchableOpacity>
            </HoverableView>
        );
    }

    function onPressTab() {
        setOpen(!open);
    }

    return (
        <View ref={node} style={{flex: 1, alignSelf: 'center', marginLeft: 10}}>
            <HoverableView style={styles.tab} hoverStyle={styles.tabHover}>
                <TouchableOpacity
                    onPress={onPressTab}
                    style={{flex: 1, flexDirection: 'row', alignSelf: 'center'}}
                >
                    {/* <SimpleText style={[styles.tabText, isFocusedTextStyle]}>
                        {props.label}
                    </SimpleText> */}
                    <Icons.Down
                        size={20}
                        color={Colors.secondary}
                        style={{alignSelf: 'center'}}
                    />
                </TouchableOpacity>
            </HoverableView>
            {open && (
                <FlatList
                    data={props.options}
                    renderItem={renderOption}
                    keyExtractor={(item) => item.title}
                    numColumns={1}
                    style={styles.menu}
                />
            )}
        </View>
    );
}

const HOVER_COLOR = Colors.colorOpacity(Colors.primary, '22');

const styles = StyleSheet.create({
    tabText: {
        fontFamily: 'montserrat-medium',
        textTransform: 'uppercase',
        fontSize: 14,
        paddingHorizontal: 25,
        paddingVertical: 20,
        textAlign: 'center',
    },
    tabHover: {
        backgroundColor: HOVER_COLOR,
    },
    tab: {},
    menu: {
        position: 'absolute',
        zIndex: 9,
        elevation: 9999,
        backgroundColor: 'white',
        top: 45,
        borderRadius: 5,
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        width: 200,
    },
    itemText: {
        padding: 10,
        alignSelf: 'flex-start',
    },
});
