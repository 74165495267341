import React, {ReactNode} from 'react';
import {View, StyleSheet, ModalProps} from 'react-native';

import {gql, useMutation} from '@apollo/client';
import {Picker} from '@react-native-picker/picker';
import {Formik, FormikHelpers, FormikProps, ErrorMessage} from 'formik';
import {CheckBox} from 'react-native-elements';

import Button from '~/components/common/Buttons';
import * as Icons from '~/components/common/Icons';
import {ServiceDescriptionInput} from '~/components/common/Inputs';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import logEvent from '~/helpers/analytics';
import {AVAILABLE} from '~/helpers/experts';
import {filterEmpty} from '~/helpers/list';
import yup, {yupRules, yupLabels, YupTypes} from '~/helpers/yup';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {
    PriceQuery_getCallPrices_edges,
    PriceQuery_getChatPrices_edges,
    PriceQuery_getMessagePrices_edges,
} from '~/queries/types/PriceQuery';
import {QueryMe_me} from '~/queries/types/QueryMe';
import usePrices from '~/queries/usePrices';
import {MessageTypeEnum} from '~/types/graphql-global-types';

function getValue(me: QueryMe_me, type: MessageTypeEnum) {
    let value = '0';
    let description = '';

    if (me.messageParameters && me.messageParameters.edges) {
        const parameter = me.messageParameters.edges.find((e) => e?.node?.type == type);
        if (parameter?.node) {
            description = parameter.node?.description;
            if (parameter.node?.messagePrice?.messagePriceId) {
                value = parameter.node?.messagePrice?.messagePriceId;
            }
        }
    }
    return {
        value: value,
        description: description,
    };
}

const {
    callValue,
    simpleMessageValue,
    simpleMessageDescription,
    completeMessageValue,
    completeMessageDescription,
    chatValue,
} = yupRules;
const validationSchema = yup.object().shape({
    callValue,
    simpleMessageValue,
    simpleMessageDescription,
    completeMessageValue,
    completeMessageDescription,
    chatValue,
});

export default function PopupService(props: ModalProps & {onSuccess: Function}) {
    const {loadingMe, me, refetchMe} = React.useContext(UserContext);
    const [updateServiceParameters, {data: callData, error: callError}] = useMutation(
        UPDATE_SERVICE_PARAMETERS,
        {
            errorPolicy: 'all',
        }
    );

    async function updateService(
        values: Inputs,
        {resetForm, setErrors, setSubmitting}: Helpers
    ) {
        const messageSimpleData = {
            messagePriceId: values.simpleMessageValue,
            typeMessage: MessageTypeEnum.SIMPLE_QUESTION,
            description: values.simpleMessageDescription,
        };
        const messageCompleteData = {
            messagePriceId: values.completeMessageValue,
            typeMessage: MessageTypeEnum.COMPLETE_STUDY,
            description: values.completeMessageDescription,
        };
        const data = {
            callPriceId: values.callValue,
            callAcceptOffer: values.callAcceptOffer,
            chatPriceId: values.chatValue,
            chatAcceptOffer: values.chatAcceptOffer,
            messageParameters: [messageSimpleData, messageCompleteData],
        };

        await updateServiceParameters({variables: data});

        refetchMe();
        props.onSuccess();
        logEvent('modify_expert', {fields: 'services'});
    }

    const message = (
        <SimpleText>
            Pour chaque type de support, vous pouvez définir un tarif et le modifier à tout
            instant.
        </SimpleText>
    );

    if (loadingMe) {
        return <></>;
    }

    if (!me) {
        return <></>;
    }

    let callValue = '0';
    let callAcceptOffer = true;
    if (me.callParameters?.price?.priceId) {
        callValue = me.callParameters.price.priceId;
        callAcceptOffer = me.callParameters.acceptOffer;
    }

    let chatValue = '0';
    let chatAcceptOffer = true;
    if (me.chatParameters?.chatPrice?.chatPriceId) {
        chatValue = me.chatParameters?.chatPrice?.chatPriceId;
        chatAcceptOffer = me.chatParameters.acceptOffer;
    }

    const simpleMessageValues = getValue(me, MessageTypeEnum.SIMPLE_QUESTION);
    const completeMessageValues = getValue(me, MessageTypeEnum.COMPLETE_STUDY);

    const callPickerEnabled = me.expert?.callStatus != AVAILABLE;
    const messagePickerEnabled = me.expert?.messageStatus != AVAILABLE;
    const chatPickerEnabled = me.expert?.chatStatus != AVAILABLE;

    return (
        <CustomModal
            title="Choisissez vos prestations"
            iconName="hand-holding-usd"
            message={message}
            hasCloseIcon={true}
            visible={props.visible}
            onRequestClose={props.onRequestClose}
            mobileContentMaxWidth={700}
        >
            <Formik
                initialValues={{
                    callValue: callValue,
                    callAcceptOffer: callAcceptOffer,
                    simpleMessageValue: simpleMessageValues.value,
                    simpleMessageDescription: simpleMessageValues.description,
                    completeMessageValue: completeMessageValues.value,
                    completeMessageDescription: completeMessageValues.description,
                    chatValue: chatValue,
                    chatAcceptOffer: chatAcceptOffer,
                }}
                onSubmit={updateService}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <ServiceInputs
                        formik={formikProps}
                        callPickerEnabled={callPickerEnabled}
                        messagePickerEnabled={messagePickerEnabled}
                        chatPickerEnabled={chatPickerEnabled}
                    />
                )}
            </Formik>
        </CustomModal>
    );
}

type Inputs = Pick<
    YupTypes,
    | 'callValue'
    | 'callAcceptOffer'
    | 'simpleMessageValue'
    | 'simpleMessageDescription'
    | 'completeMessageValue'
    | 'completeMessageDescription'
    | 'chatValue'
    | 'chatAcceptOffer'
>;
type Helpers = FormikHelpers<Inputs>;
type Props = {
    formik: FormikProps<Inputs>;
    callPickerEnabled: boolean;
    messagePickerEnabled: boolean;
    chatPickerEnabled: boolean;
};

function ServiceInputs(props: Props) {
    const error =
        !props.callPickerEnabled || !props.chatPickerEnabled || !props.messagePickerEnabled;

    const {isDesktop} = useDeviceQuery();
    const deviceStyles = isDesktop ? styles : mobileStyles;

    return (
        <>
            <View style={deviceStyles.serviceContainer}>
                <View style={deviceStyles.containerHalf}>
                    <CallServicePicker
                        enabled={props.callPickerEnabled}
                        formik={props.formik}
                    />
                </View>
                <View style={deviceStyles.containerHalf}>
                    <ChatServicePicker
                        enabled={props.chatPickerEnabled}
                        formik={props.formik}
                    />
                </View>
            </View>
            <View style={deviceStyles.container}>
                <MessageServicePicker
                    enabled={props.messagePickerEnabled}
                    formik={props.formik}
                />
            </View>
            {error && <DisabledPickerInfo />}
            <SubmitButton formik={props.formik} />
        </>
    );
}

function SubmitButton({formik}: {formik: FormikProps<Inputs>}) {
    return (
        <Button
            icon={<Icons.Validate size={16} style={{paddingRight: 10}} />}
            title="Valider"
            buttonStyle={{width: 160, alignSelf: 'center', marginTop: 10}}
            disabled={!formik.isValid}
            onPress={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
        />
    );
}

function DisabledPickerInfo() {
    return (
        <SimpleText style={styles.label_error}>
            Pour modifier le tarif d'un moyen de communication, vous devez être indisponible
            sur ce moyen de communication.
        </SimpleText>
    );
}

function CallServicePicker(props: {formik: FormikProps<Inputs>; enabled: boolean}) {
    const {data} = usePrices();

    function byPrice(a: PriceQuery_getCallPrices_edges, b: PriceQuery_getCallPrices_edges) {
        return (a.node?.priceDecimal ?? 0) - (b.node?.priceDecimal ?? 0);
    }

    const callPrices = filterEmpty(
        data?.getCallPrices?.edges.filter((p) => p?.node?.isVisible)
    )
        .sort(byPrice)
        .map((e) => {
            return {
                value: e?.node?.priceId,
                label: `${e?.node?.label} / minute`,
            };
        });

    return (
        <>
            <ServiceHeader
                title="Prestation par téléphone"
                icon={<Icons.Phone size={20} style={styles.picto} />}
            />
            <View style={styles.pickerContainer}>
                <SimpleText style={styles.labelPicker}>Tarif à la minute</SimpleText>
                <Picker
                    selectedValue={props.formik.values.callValue}
                    onValueChange={props.formik.handleChange('callValue')}
                    style={{height: 30, width: 180, borderRadius: 5}}
                    enabled={props.enabled}
                >
                    <Picker.Item label="Choisir vos tarifs" value="0" />
                    {callPrices?.map((c) => (
                        <Picker.Item label={c.label} value={c.value} key={c.label} />
                    ))}
                </Picker>
            </View>
            {/* <View style={styles.checkboxContainer}>
                <CheckBox
                    checked={props.formik.values.callAcceptOffer}
                    onPress={() =>
                        props.formik.setFieldValue(
                            'callAcceptOffer',
                            !props.formik.values.callAcceptOffer
                        )
                    }
                    containerStyle={{
                        backgroundColor: 'white',
                        borderWidth: 0,
                        margin: 0,
                        padding: 0,
                    }}
                    style={{margin: 0, padding: 0}}
                    wrapperStyle={{margin: 0, padding: 0}}
                />
                <SimpleText style={styles.checkboxLabel}>
                    Accepter les minutes gratuites
                </SimpleText>
            </View> */}
        </>
    );
}

function ChatServicePicker(props: {formik: FormikProps<Inputs>; enabled: boolean}) {
    const {data} = usePrices();

    function byPrice(a: PriceQuery_getChatPrices_edges, b: PriceQuery_getChatPrices_edges) {
        return (a.node?.priceDecimal ?? 0) - (b.node?.priceDecimal ?? 0);
    }

    const chatPrices = filterEmpty(
        data?.getChatPrices?.edges.filter((p) => p?.node?.isVisible)
    )
        .sort(byPrice)
        .map((e) => {
            return {
                value: e?.node?.chatPriceId,
                label: `${e?.node?.label} / minute`,
            };
        });

    return (
        <>
            <ServiceHeader
                title="Prestation par chat"
                icon={<Icons.Chat size={20} style={styles.picto} />}
            />
            <View style={styles.pickerContainer}>
                <SimpleText style={styles.labelPicker}>Tarif à la minute</SimpleText>
                <Picker
                    selectedValue={props.formik.values.chatValue}
                    onValueChange={props.formik.handleChange('chatValue')}
                    style={{height: 30, width: 180, borderRadius: 5}}
                    enabled={props.enabled}
                >
                    <Picker.Item label="Choisir vos tarifs" value="0" />
                    {chatPrices?.map((c) => (
                        <Picker.Item label={c.label} value={c.value} key={c.label} />
                    ))}
                </Picker>
            </View>
            {/* <View style={styles.checkboxContainer}>
                <CheckBox
                    checked={props.formik.values.chatAcceptOffer}
                    onPress={() =>
                        props.formik.setFieldValue(
                            'chatAcceptOffer',
                            !props.formik.values.chatAcceptOffer
                        )
                    }
                    containerStyle={{
                        backgroundColor: 'white',
                        borderWidth: 0,
                        margin: 0,
                        padding: 0,
                    }}
                    style={{margin: 0, padding: 0}}
                    wrapperStyle={{margin: 0, padding: 0}}
                />
                <SimpleText style={styles.checkboxLabel}>
                    Accepter les minutes gratuites
                </SimpleText>
            </View> */}
        </>
    );
}

function MessageServicePicker(props: {formik: FormikProps<Inputs>; enabled: boolean}) {
    const {isDesktop} = useDeviceQuery();
    const {data} = usePrices();

    function byPrice(
        a: PriceQuery_getMessagePrices_edges,
        b: PriceQuery_getMessagePrices_edges
    ) {
        return (a.node?.priceDecimal ?? 0) - (b.node?.priceDecimal ?? 0);
    }

    const simpleQuestionData = data?.getMessagePrices?.edges?.filter(
        (e) => e?.node?.type == MessageTypeEnum.SIMPLE_QUESTION && e?.node?.isVisible
    );
    const simpleQuestionPrices = filterEmpty(simpleQuestionData)
        .sort(byPrice)
        .map((e) => {
            return {
                value: e?.node?.messagePriceId,
                label: e?.node?.label,
            };
        });

    const completeStudyData = data?.getMessagePrices?.edges?.filter(
        (e) => e?.node?.type == MessageTypeEnum.COMPLETE_STUDY && e?.node?.isVisible
    );
    const completeStudyPrices = filterEmpty(completeStudyData)
        .sort(byPrice)
        .map((e) => {
            return {
                value: e?.node?.messagePriceId,
                label: e?.node?.label,
            };
        });

    const deviceStyles = isDesktop ? styles : mobileStyles;
    return (
        <>
            <ServiceHeader
                title="Question simple ou étude complète"
                icon={<Icons.Mail size={20} style={styles.picto} />}
            />
            <View style={deviceStyles.columnContainer}>
                <View style={deviceStyles.leftContainer}>
                    <SimpleText style={styles.title}>
                        Format
                        <SimpleText style={styles.bold}> question simple</SimpleText>
                    </SimpleText>
                    <SimpleText style={styles.description}>
                        Réponse concise en 15 lignes, en 24h max.
                    </SimpleText>
                </View>
                <View style={deviceStyles.rightContainer}>
                    <SimpleText style={styles.label}>Tarif à la réponse</SimpleText>
                    <Picker
                        selectedValue={props.formik.values.simpleMessageValue}
                        onValueChange={props.formik.handleChange('simpleMessageValue')}
                        style={{height: 30, width: 180, borderRadius: 5}}
                        enabled={props.enabled}
                    >
                        <Picker.Item label="Choisir vos tarifs" value="0" />
                        {simpleQuestionPrices?.map((c) => (
                            <Picker.Item label={c.label} value={c.value} key={c.label} />
                        ))}
                    </Picker>
                </View>
            </View>
            <View style={deviceStyles.descriptionContainer}>
                <SimpleText style={deviceStyles.descriptionLabel}>
                    Description de la prestation :
                </SimpleText>
                <ServiceDescriptionInput
                    value={props.formik.values.simpleMessageDescription}
                    placeholder={'prestation "question simple" ...'}
                    onChangeText={props.formik.handleChange('simpleMessageDescription')}
                    onBlur={() => props.formik.setFieldTouched('simpleMessageDescription')}
                    errorMessage={props.formik.errors.simpleMessageDescription || ' '}
                    style={deviceStyles.serviceDescriptionInput}
                    numberOfLines={5}
                />
            </View>
            <View style={deviceStyles.columnContainer}>
                <View style={deviceStyles.leftContainer}>
                    <SimpleText style={styles.title}>
                        Format
                        <SimpleText style={styles.bold}> étude complète</SimpleText>
                    </SimpleText>
                    <SimpleText style={styles.description}>
                        Réponse approfondie sans limitation, en 48h max.
                    </SimpleText>
                </View>
                <View style={deviceStyles.rightContainer}>
                    <SimpleText style={styles.label}>Tarif à la réponse</SimpleText>
                    <Picker
                        selectedValue={props.formik.values.completeMessageValue}
                        onValueChange={props.formik.handleChange('completeMessageValue')}
                        enabled={props.enabled}
                        style={{height: 30, width: 180, borderRadius: 5}}
                    >
                        <Picker.Item label="Choisir vos tarifs" value="0" />
                        {completeStudyPrices?.map((c) => (
                            <Picker.Item label={c.label} value={c.value} key={c.label} />
                        ))}
                    </Picker>
                </View>
            </View>
            <View style={deviceStyles.descriptionContainer}>
                <SimpleText style={deviceStyles.descriptionLabel}>
                    Description de la prestation :
                </SimpleText>
                <ServiceDescriptionInput
                    value={props.formik.values.completeMessageDescription}
                    placeholder={'prestation "étude complète" ...'}
                    onChangeText={props.formik.handleChange('completeMessageDescription')}
                    onBlur={() => props.formik.setFieldTouched('completeMessageDescription')}
                    errorMessage={props.formik.errors.completeMessageDescription || ' '}
                    onSubmitEditing={() => props.formik.handleSubmit()}
                    style={deviceStyles.serviceDescriptionInput}
                />
            </View>
        </>
    );
}

function ServiceHeader({title, icon}: {title: string; icon: ReactNode}) {
    return (
        <View style={styles.headerContainer}>
            {icon}
            <SimpleText style={styles.header}>{title}</SimpleText>
        </View>
    );
}

export interface MessageParameterInput {
    messagePriceId?: number | null;
    typeMessage?: string | null;
    description?: string | null;
}

const UPDATE_SERVICE_PARAMETERS = gql`
    mutation updateServiceParameters(
        $callPriceId: Int!
        $callAcceptOffer: Boolean!
        $messageParameters: [MessageParameterInput]
        $chatPriceId: Int!
        $chatAcceptOffer: Boolean!
    ) {
        updateServiceParameters(
            input: {
                callPriceId: $callPriceId
                callAcceptOffer: $callAcceptOffer
                messageParameters: $messageParameters
                chatPriceId: $chatPriceId
                chatAcceptOffer: $chatAcceptOffer
            }
        ) {
            callParameters {
                id
            }
        }
    }
`;

const styles = StyleSheet.create({
    serviceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerContainer: {
        backgroundColor: Colors.highlight,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        flexDirection: 'row',
        padding: 10,
    },
    containerHalf: {
        marginTop: 15,
        backgroundColor: 'white',
    },
    container: {
        marginTop: 15,
        backgroundColor: 'white',
    },
    header: {
        fontSize: 14,
        color: 'white',
        paddingTop: 2,
        alignSelf: 'center',
        marginLeft: 10,
    },
    picto: {
        alignSelf: 'center',
    },
    title: {
        fontSize: 14,
        marginBottom: 5,
    },
    label: {
        fontSize: 12,
        marginBottom: 5,
    },
    label_error: {
        fontSize: 11,
        textAlign: 'center',
        color: 'red',
        marginHorizontal: 10,
        marginTop: 10,
    },
    description: {
        fontSize: 12,
        marginBottom: 10,
        opacity: 0.7,
        fontStyle: 'italic',
    },
    pickerContainer: {
        padding: 10,
        flexDirection: 'row',
    },
    labelPicker: {
        fontSize: 12,
        paddingTop: 7,
        paddingRight: 15,
    },
    columnContainer: {
        flexDirection: 'row',
        paddingTop: 10,
        justifyContent: 'space-between',
        marginLeft: 10,
        marginRight: 10,
    },
    leftContainer: {
        marginLeft: 20,
    },
    rightContainer: {
        marginRight: 20,
    },
    descriptionContainer: {
        marginHorizontal: 20,
        marginVertical: 10,
    },
    descriptionLabel: {
        fontSize: 12,
        marginBottom: 5,
        marginLeft: 10,
    },
    checkboxContainer: {
        flexDirection: 'row',
        paddingBottom: 5,
    },
    checkboxLabel: {
        fontSize: 12,
        paddingTop: 5,
    },
    serviceDescriptionInput: {
        backgroundColor: 'white',
        height: 50,
        borderColor: Colors.primary,
        borderRadius: 10,
        borderWidth: 1,
        padding: 5,
    },
    bold: {
        fontWeight: 'bold',
    },
});

const mobileContainer = {
    marginHorizontal: 10,
    marginVertical: 15,
    paddingBottom: 10,
    borderColor: Colors.separator,
    borderWidth: 1,
};

const mobileStyles = StyleSheet.create({
    serviceContainer: {
        flexDirection: 'column',
    },
    containerHalf: mobileContainer,
    container: mobileContainer,
    columnContainer: {},
    leftContainer: {
        margin: 10,
    },
    rightContainer: {
        alignSelf: 'center',
        marginBottom: 20,
    },
    descriptionLabel: {
        marginLeft: 10,
    },
    descriptionContainer: {},
    serviceDescriptionInput: {
        height: 150,
        borderColor: Colors.primary,
        borderRadius: 10,
        borderWidth: 1,
        padding: 3,
        marginTop: 15,
    },
});
