import React, {useEffect} from 'react';
import {View, StyleSheet, ModalProps} from 'react-native';

import Button from '~/components/common/Buttons';
import CustomModal from '~/components/common/Modal';
import {SimpleText} from '~/components/common/Texts';
import {UserContext} from '~/contexts/UserContext';
import {QueryMe_me} from '~/queries/types/QueryMe';

import {checkPaymentMethod} from './AddPaymentMethod';

export default function PopupWaitValidation(
    props: ModalProps & {sessionId: string; automaticallyCloseOnFinish?: boolean}
) {
    const {loadingMe, me, refetchMe} = React.useContext(UserContext);
    const [timer, setTimer] = React.useState<number | null>(null);

    async function refetchPaymentMethod() {
        if (props.sessionId) {
            console.log('checkPaymentMethod', props.sessionId);
            await checkPaymentMethod(props.sessionId);
        }
    }

    useEffect(() => {
        refetchPaymentMethod();
        if (!loadingMe) {
            if (mustWait(me) && !timer) {
                const timer = setInterval(refetchMe, 3000);
                setTimer(timer);
            }
        }
    }, [me, loadingMe, timer]);

    useEffect(() => {
        if (!mustWait(me) && timer) {
            clearInterval(timer);
        }
    }, [me, timer]);

    function mustWait(me: QueryMe_me | null | undefined) {
        const wait = me?.identity?.paymentMethods?.edges.some(
            (p) => !(p?.node?.isPaymentValidated ?? false)
        );
        return wait;
    }

    const finished = !mustWait(me);
    let visible = props.visible;
    if (props.automaticallyCloseOnFinish && finished) {
        visible = false;
    }

    return (
        <CustomModal
            title={`Votre moyen de paiement`}
            iconName="credit-card"
            visible={visible}
        >
            {finished && (
                <View>
                    <SimpleText style={styles.title}>Félicitations</SimpleText>
                    <SimpleText style={styles.center}>
                        Votre nouveau moyen de paiement a été ajouté avec succès.
                    </SimpleText>
                    <Button title={'Ok'} onPress={props.onRequestClose} />
                </View>
            )}
            {!finished && (
                <View>
                    <SimpleText style={styles.center}>
                        Nous sommes en attente du prestataire de paiement.
                    </SimpleText>
                    <SimpleText style={styles.center}>
                        Merci de patienter quelques secondes.
                    </SimpleText>
                </View>
            )}
        </CustomModal>
    );
}

const styles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    center: {
        textAlign: 'center',
    },
});
