import * as React from 'react';
import {Platform} from 'react-native';

import {HeaderBackButton} from '@react-navigation/elements';
import {createMaterialTopTabNavigator as TabNavigator} from '@react-navigation/material-top-tabs';
import {createStackNavigator} from '@react-navigation/stack';

import TabBar from '~/components/navigation/TabBar';
import * as Colors from '~/constants/Colors';
import ExpertScreen from '~/screens/ExpertScreen';
import HomeScreen from '~/screens/HomeScreen';
import SearchScreen from '~/screens/SearchScreen';
import AboutScreen from '~/screens/information/AboutScreen';
import ApplyScreen from '~/screens/information/ApplyScreen';
import CguScreen from '~/screens/information/CguScreen';
import CgvScreen from '~/screens/information/CgvScreen';
import CommitmentScreen from '~/screens/information/CommitmentScreen';
import ContactScreen from '~/screens/information/ContactScreen';
import EthicScreen from '~/screens/information/EthicScreen';
import FaqScreen from '~/screens/information/FaqScreen';
import HowToScreen from '~/screens/information/HowToScreen';
import JobScreen from '~/screens/information/JobScreen';
import LegalNoticeScreen from '~/screens/information/LegalNoticeScreen';
import PriceScreen from '~/screens/information/PriceScreen';
import BasketScreen from '~/screens/pack/BasketScreen';
import CancelScreen from '~/screens/pack/CancelScreen';
import PackScreen from '~/screens/pack/PackScreen';
import PaymentScreen from '~/screens/pack/PaymentScreen';
import SuccessScreen from '~/screens/pack/SuccessScreen';
import AccountScreen from '~/screens/profile/AccountScreen';
import HistoricScreen from '~/screens/profile/HistoricScreen';
import MessageScreen from '~/screens/profile/MessageScreen';
import ProfileScreen from '~/screens/profile/ProfileScreen';
import ForgottenPasswordScreen from '~/screens/user/ForgottenPasswordScreen';
import LoginScreen from '~/screens/user/LoginScreen';
import RegisterScreen from '~/screens/user/RegisterScreen';
import {
    BottomTabParamList,
    SearchParamList,
    InformationParamList,
    HomeParamList,
    ProfileParamList,
    PackParamList,
} from '~/types';

const Tab = TabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
    return (
        <Tab.Navigator
            initialRouteName="Home"
            tabBarOptions={{
                activeTintColor: Colors.primary,
                inactiveTintColor: Colors.disabled,
                labelStyle: {fontWeight: 'bold'},
                style: {position: 'absolute', elevation: 55},
            }}
            swipeEnabled={false}
            timingConfig={{duration: Platform.OS === 'web' ? 1 : 500}}
            tabBar={(props: any) => <TabBar {...props} />}
        >
            <Tab.Screen
                name="Home"
                component={HomeNavigator}
                options={{
                    tabBarLabel: 'Accueil',
                    title: 'Kyvoitou.fr | Accueil',
                }}
            />
            <Tab.Screen
                name="Search"
                component={TabSearchNavigator}
                options={{
                    tabBarLabel: 'Nos experts',
                    title: 'Kyvoitou.fr | Nos experts',
                }}
            />
            <Tab.Screen
                name="PriceScreen"
                component={PriceScreen}
                options={{
                    tabBarLabel: 'Nos tarifs',
                    title: 'Kyvoitou.fr | Nos tarifs',
                }}
            />
            <Tab.Screen
                name="HowToScreen"
                component={HowToScreen}
                options={{
                    tabBarLabel: 'Comment ça marche ?',
                    title: 'Kyvoitou.fr | Comment ça marche ?',
                }}
            />
            <Tab.Screen
                name="AboutScreen"
                component={AboutScreen}
                options={{
                    tabBarLabel: 'Qui sommes-nous ?',
                    title: 'Kyvoitou.fr | Qui sommes-nous ?',
                }}
            />
            <Tab.Screen
                name="Information"
                component={InformationNavigator}
                options={{
                    tabBarLabel: 'Information',
                    title: 'Kyvoitou.fr | Information',
                }}
            />
            <Tab.Screen
                name="Blog"
                component={Fake}
                options={{
                    tabBarLabel: 'Blog',
                    title: 'Kyvoitou.fr | Blog',
                }}
            />
            <Tab.Screen
                name="Profile"
                component={ProfileNavigator}
                options={{
                    tabBarLabel: 'Mon Profil',
                    title: 'Kyvoitou.fr | Mon Profil',
                }}
            />
        </Tab.Navigator>
    );
}

function Fake() {
    return <></>;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const TabSearchStack = createStackNavigator<SearchParamList>();

export function TabSearchNavigator() {
    return (
        <TabSearchStack.Navigator>
            <TabSearchStack.Screen
                name="SearchScreen"
                component={SearchScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Recherche',
                }}
            />
            <TabSearchStack.Screen
                name="ExpertScreen"
                component={ExpertScreen}
                options={({navigation}) => ({
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('SearchScreen');
                            }}
                        />
                    ),
                    title: 'Recherche',
                })}
            />
        </TabSearchStack.Navigator>
    );
}

const TabInformationStack = createStackNavigator<InformationParamList>();

function InformationNavigator() {
    return (
        <TabInformationStack.Navigator>
            <TabInformationStack.Screen
                name="PriceScreen"
                component={PriceScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Nos tarifs',
                }}
            />
            <TabInformationStack.Screen
                name="HowToScreen"
                component={HowToScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Comment ça marche ?',
                }}
            />
            <TabInformationStack.Screen
                name="CommitmentScreen"
                component={CommitmentScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Nos engagements Kyvoitou.fr',
                }}
            />
            <TabInformationStack.Screen
                name="FaqScreen"
                component={FaqScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Aide : Foire Aux Questions',
                }}
            />
            <TabInformationStack.Screen
                name="ContactScreen"
                component={ContactScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Contact & SAV',
                }}
            />
            <TabInformationStack.Screen
                name="CgvScreen"
                component={CgvScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Conditions générales de vente',
                }}
            />
            <TabInformationStack.Screen
                name="ApplyScreen"
                component={ApplyScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Devenir spirite Kyvoitou.fr',
                }}
            />
            <TabInformationStack.Screen
                name="JobScreen"
                component={JobScreen}
                options={{
                    headerShown: false,
                    title: "Kyvoitou.fr | Offre d'emploi",
                }}
            />
            <TabInformationStack.Screen
                name="AboutScreen"
                component={AboutScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Qui sommes-nous ?',
                }}
            />
            <TabInformationStack.Screen
                name="EthicScreen"
                component={EthicScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Charte éthique',
                }}
            />
            <TabInformationStack.Screen
                name="LegalNoticeScreen"
                component={LegalNoticeScreen}
                options={{
                    headerShown: false,
                    title: 'Kyvoitou.fr | Mentions légales',
                }}
            />
            <TabInformationStack.Screen
                name="CguScreen"
                component={CguScreen}
                options={{
                    headerShown: false,
                    title: "Kyvoitou.fr | Conditions générales d'utilisation",
                }}
            />
        </TabInformationStack.Navigator>
    );
}

const TabProfile = TabNavigator<ProfileParamList>();

function ProfileNavigator() {
    return (
        <TabProfile.Navigator
            initialRouteName="ProfileScreen"
            tabBarOptions={{
                activeTintColor: Colors.primary,
                inactiveTintColor: Colors.link,
                labelStyle: {fontWeight: 'bold'},
                indicatorStyle: {
                    backgroundColor: Colors.primary,
                },
            }}
            swipeEnabled={false}
        >
            <TabProfile.Screen
                name="ProfileScreen"
                component={ProfileScreen}
                options={{
                    tabBarLabel: 'Espace Perso',
                    title: 'Kyvoitou.fr | Espace Perso',
                }}
            />
            <TabProfile.Screen
                name="MessageScreen"
                component={MessageScreen}
                options={{
                    tabBarLabel: 'Mes messages',
                    title: 'Kyvoitou.fr | Mes messages',
                }}
            />
            <TabProfile.Screen
                name="HistoricScreen"
                component={HistoricScreen}
                options={{
                    tabBarLabel: 'Mon historique',
                    title: 'Kyvoitou.fr | Mon historique',
                }}
            />
            <TabProfile.Screen
                name="AccountScreen"
                component={AccountScreen}
                options={{
                    tabBarLabel: 'Mon compte',
                    title: 'Kyvoitou.fr | Mon compte',
                }}
            />
        </TabProfile.Navigator>
    );
}

const HomeStack = createStackNavigator<HomeParamList>();

function HomeNavigator() {
    return (
        <HomeStack.Navigator>
            <HomeStack.Screen
                name="HomeScreen"
                component={HomeScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Accueil',
                    title: 'Kyvoitou.fr | Accueil',
                }}
            />
            <HomeStack.Screen
                name="LoginScreen"
                component={LoginScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Identification',
                    title: 'Kyvoitou.fr | Identification',
                }}
            />
            <HomeStack.Screen
                name="RegisterScreen"
                component={RegisterScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Enregistrement',
                    title: 'Kyvoitou.fr | Enregistrement',
                }}
            />
            <HomeStack.Screen
                name="ForgottenPasswordScreen"
                component={ForgottenPasswordScreen}
                options={{
                    headerShown: false,
                    headerTitle: 'Oubli de mot de passe',
                    title: 'Kyvoitou.fr | Oubli de mot de passe',
                }}
            />
            <HomeStack.Screen
                name="HomeExpertScreen"
                component={ExpertScreen}
                options={({navigation}) => ({
                    title: 'Kyvoitou.fr | Spirite du moment',
                    headerTitle: "Retour à l'accueil",
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('Home', {screen: 'HomeScreen'});
                            }}
                        />
                    ),
                })}
            />
            <HomeStack.Screen
                name="PackScreen"
                component={PackNavigator}
                options={{
                    title: 'Kyvoitou.fr | Nos packs',
                    headerShown: false,
                }}
            />
        </HomeStack.Navigator>
    );
}

const PackStack = createStackNavigator<PackParamList>();

function PackNavigator() {
    return (
        <PackStack.Navigator>
            <PackStack.Screen
                name="PackScreen"
                component={PackScreen}
                options={({navigation}) => ({
                    title: 'Kyvoitou.fr | Nos packs',
                    headerTitle: "Retour à l'accueil",
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('HomeScreen');
                            }}
                        />
                    ),
                })}
            />
            <PackStack.Screen
                name="BasketScreen"
                component={BasketScreen}
                options={({navigation}) => ({
                    title: 'Kyvoitou.fr | Votre panier',
                    headerTitle: 'Changer de pack',
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('PackScreen');
                            }}
                        />
                    ),
                })}
            />
            <PackStack.Screen
                name="PaymentScreen"
                component={PaymentScreen}
                options={({route, navigation}) => ({
                    title: 'Kyvoitou.fr | Paiement',
                    headerTitle: 'Mon panier',
                    headerShown: true,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...props}
                            onPress={() => {
                                navigation.navigate('BasketScreen', route.params);
                            }}
                        />
                    ),
                })}
            />
            <PackStack.Screen
                name="SuccessScreen"
                component={SuccessScreen}
                options={{
                    title: 'Kyvoitou.fr | Récapitulatif',
                    headerShown: false,
                }}
            />
            <PackStack.Screen
                name="CancelScreen"
                component={CancelScreen}
                options={{
                    title: 'Kyvoitou.fr | Annulation',
                    headerShown: false,
                }}
            />
        </PackStack.Navigator>
    );
}
