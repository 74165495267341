import * as React from 'react';
import {
    View,
    StyleSheet,
    FlatList,
    TouchableOpacity,
    ScrollView,
    TextStyle,
} from 'react-native';

import {gql, useLazyQuery} from '@apollo/client';
import {useFocusEffect} from '@react-navigation/native';
import {useNavigation} from '@react-navigation/native';

import {Bloc} from '~/components/common/Containers';
import {ProfilePicture} from '~/components/common/Images';
import {SimpleText} from '~/components/common/Texts';
import {BlocContentLoader} from '~/components/navigation/Loader';
import * as Colors from '~/constants/Colors';
import * as dates from '~/helpers/dates';
import {sorted, filterEmpty} from '~/helpers/list';
import {
    MessageStatusEnum,
    EnumModeMessageBox,
    ExchangeTypeEnum,
    mergeLastMessages,
    Message,
    MessageExchange,
    getStudyStatus,
} from '~/helpers/message';
import useDeviceQuery from '~/hooks/useDeviceQuery';
import {MessageTypeEnum} from '~/types/graphql-global-types';

import {GetLastMessageQuery} from './types/GetLastMessageQuery';

const GET_LAST_MESSAGES_QUERY = gql`
    query GetLastMessageQuery {
        getLastMessages {
            edges {
                node {
                    messageId
                    status
                    type
                    subject
                    creationDate
                    clientRead
                    clientArchived
                    expertRead
                    expertArchived
                    expert {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    client {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    exchanges {
                        edges {
                            node {
                                content
                                type
                                creationDate
                            }
                        }
                    }
                }
            }
        }
        getLastMessagesGroupClient {
            edges {
                node {
                    messageGroupRecipientId
                    clientRead
                    clientArchived
                    client {
                        id
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    messageGroup {
                        messageGroupId
                        creationDate
                        subject
                        content
                        expert {
                            id
                            profile {
                                displayName
                                pictureName
                            }
                        }
                    }
                }
            }
        }
    }
`;

type MessageBoxProps = {
    mode: EnumModeMessageBox;
};

function getCreationDate(exchange: MessageExchange) {
    return Date.parse(exchange.creationDate);
}

function getLastExchange(item: Message) {
    return sorted(item.exchanges, getCreationDate, true)[0];
}

export default function BlocMessage(props: MessageBoxProps) {
    const [load, {called, loading, data, refetch}] = useLazyQuery<GetLastMessageQuery>(
        GET_LAST_MESSAGES_QUERY,
        {fetchPolicy: 'network-only', pollInterval: 3600000}
    );

    useFocusEffect(
        React.useCallback(() => {
            requestAnimationFrame(reload);
        }, [])
    );

    function reload() {
        if (refetch) {
            refetch();
        } else {
            load();
        }
    }

    function filterMessage(item: Message) {
        const lastExchangeType = getLastExchange(item).type;
        if (props.mode == EnumModeMessageBox.CLIENT) {
            return (
                item.type != MessageTypeEnum.PACK_STUDY &&
                !item.clientArchived &&
                lastExchangeType == ExchangeTypeEnum.EXPERT_TO_CLIENT
            );
        } else if (props.mode == EnumModeMessageBox.EXPERT) {
            return (
                !item.expertArchived &&
                lastExchangeType == ExchangeTypeEnum.CLIENT_TO_EXPERT &&
                !(item.status == MessageStatusEnum.SENT && item.type != MessageTypeEnum.FREE)
            );
        }
        return false;
    }

    function filterMessageToRespond(item: Message) {
        return (
            props.mode == EnumModeMessageBox.EXPERT &&
            item.status == MessageStatusEnum.SENT &&
            item.type != MessageTypeEnum.FREE
        );
    }

    function filterMessagePack(item: Message) {
        return item.type == MessageTypeEnum.PACK_STUDY;
    }

    const messages = mergeLastMessages(data);
    const desktopTitle =
        props.mode == EnumModeMessageBox.EXPERT ? 'Derniers messages de suivi' : 'Mes emails';
    const mobileTitle =
        props.mode == EnumModeMessageBox.EXPERT ? 'Dernier message de suivi' : 'Dernier email';

    return (
        <>
            {props.mode == EnumModeMessageBox.EXPERT && (
                <BlocMessageCommon
                    data={messages}
                    mode={props.mode}
                    filter={filterMessageToRespond}
                    desktopTitle={'Messages payants à rédiger'}
                    mobileTitle={'Message payant à rédiger'}
                    icon={'envelope-open-text'}
                    noMessage={"Vous n'avez aucun message payant à rédiger."}
                />
            )}
            <BlocMessageCommon
                data={messages}
                mode={props.mode}
                filter={filterMessage}
                desktopTitle={desktopTitle}
                mobileTitle={mobileTitle}
                icon={'envelope'}
                noMessage={"Vous n'avez aucun message reçu."}
            />
            {props.mode == EnumModeMessageBox.CLIENT && (
                <BlocMessageCommon
                    data={messages}
                    mode={props.mode}
                    filter={filterMessagePack}
                    desktopTitle={'Mes études complètes'}
                    mobileTitle={'Dernière étude complète'}
                    icon={'envelope-open-text'}
                    noMessage={"Vous n'avez aucune étude complète."}
                />
            )}
        </>
    );
}

type BlocMessageCommonProps = {
    data: Message[];
    mode: EnumModeMessageBox;
    filter: (item: Message) => boolean;
    desktopTitle: string;
    mobileTitle: string;
    icon: string;
    noMessage: string;
};

function BlocMessageCommon(props: BlocMessageCommonProps) {
    const {isDesktop} = useDeviceQuery();

    function key(item: Message) {
        const exchanges = item.exchanges;
        const lastExchange = sorted(filterEmpty(exchanges), getCreationDate, true)[0];
        const date = lastExchange.creationDate;
        return Date.parse(date);
    }

    const filteredData = props.data.filter(props.filter);
    let list = sorted(filterEmpty(filteredData), key, true);

    const noMessage = list.length == 0;
    const listMessage = list.length > 0;

    let title = props.desktopTitle;
    if (!isDesktop) {
        list = list.slice(0, 1);
        title = props.mobileTitle;
    }

    return (
        <Bloc title={title} iconName={props.icon}>
            {!props.data && <BlocContentLoader />}
            {props.data && noMessage && (
                <SimpleText style={styles.noMessage}>{props.noMessage}</SimpleText>
            )}
            {props.data && listMessage && <ListMessage list={list} mode={props.mode} />}
        </Bloc>
    );
}

type ListMessageProps = {
    list: Message[];
    mode: EnumModeMessageBox;
};

function ListMessage(props: ListMessageProps) {
    const navigation = useNavigation();

    function goToMessage() {
        navigation.navigate('MessageScreen');
    }

    function getProfile(item: Message) {
        if (props.mode == EnumModeMessageBox.CLIENT) {
            return item?.expert;
        } else if (props.mode == EnumModeMessageBox.EXPERT) {
            return item?.client;
        }
    }

    function isOpen(item: Message) {
        if (props.mode == EnumModeMessageBox.CLIENT) {
            return item.clientRead;
        } else if (props.mode == EnumModeMessageBox.EXPERT) {
            return item.expertRead;
        }
    }

    function renderRow({item}: {item: Message}) {
        const profile = getProfile(item);
        const open = isOpen(item);
        const exchange = getLastExchange(item);
        const date = new Date(exchange.creationDate);
        const readStyle = open ? styles.read : styles.notRead;

        if (item.type == MessageTypeEnum.PACK_STUDY) {
            const pack = item.subject.split(':')[0];
            const bold =
                item.status == MessageStatusEnum.ANSWERED
                    ? ({fontWeight: 'bold'} as TextStyle)
                    : ({} as TextStyle);
            return (
                <View style={styles.study}>
                    <SimpleText style={styles.studyDate}>
                        {date.toLocaleDateString()}
                    </SimpleText>
                    <SimpleText style={styles.studyName}>{pack}</SimpleText>
                    <SimpleText style={[styles.studyStatus, bold]}>
                        {getStudyStatus(item.status)}
                    </SimpleText>
                </View>
            );
        } else {
            return (
                <View style={styles.messageContainer}>
                    <View style={styles.messageHeaderContainer}>
                        <ProfilePicture
                            style={styles.profilePicture}
                            pictureName={profile?.pictureName}
                            displayName={profile?.displayName}
                        />
                        <View style={styles.textContainer}>
                            <View style={styles.nameContainer}>
                                <SimpleText style={[styles.expertName, readStyle]}>
                                    {profile?.displayName}
                                </SimpleText>
                            </View>
                            <SimpleText style={[styles.date, readStyle]}>
                                le {dates.dateString(date)} à {dates.timeString(date)}
                            </SimpleText>
                        </View>
                    </View>
                    <SimpleText numberOfLines={3} style={[styles.content, readStyle]}>
                        {exchange.content}
                    </SimpleText>
                </View>
            );
        }
    }

    return (
        <TouchableOpacity onPress={goToMessage}>
            <ScrollView style={styles.messages}>
                <FlatList
                    data={props.list}
                    renderItem={renderRow}
                    keyExtractor={(item) => item.messageId}
                    style={styles.listContainer}
                />
            </ScrollView>
            <View style={styles.link_container}>
                <SimpleText style={styles.link}>Voir mes messages</SimpleText>
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    noMessage: {
        padding: 17,
        flex: 1,
        alignSelf: 'center',
        textAlign: 'center',
    },
    messages: {
        flex: 1,
        flexDirection: 'column',
        maxHeight: 390,
    },
    messageContainer: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 15,
        padding: 10,
    },
    textContainer: {
        flex: 1,
        marginBottom: 15,
    },
    nameContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listContainer: {},
    pictureContainer: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
    },
    profilePicture: {
        width: 37,
        height: 37,
        borderRadius: 40,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 15,
        alignSelf: 'center',
    },
    messageHeaderContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    expertName: {
        fontSize: 14,
    },
    date: {
        fontSize: 14,
        width: 150,
    },
    read: {},
    notRead: {
        fontWeight: 'bold',
    },
    content: {
        fontSize: 12,
    },
    link_container: {},
    link: {
        fontSize: 12,
        color: Colors.link,
        marginBottom: 11,
        marginRight: 16,
        alignSelf: 'flex-end',
    },
    study: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    studyDate: {
        width: 75,
        fontSize: 12,
        alignSelf: 'center',
        textAlign: 'center',
    },
    studyName: {
        width: 150,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    studyStatus: {
        width: 75,
        fontSize: 12,
        alignSelf: 'center',
        textAlign: 'center',
        fontStyle: 'italic',
    },
});
