import Constants from 'expo-constants';
import {getAnalytics, logEvent} from 'firebase/analytics';

import {getAcceptation} from './cookie';
import {app} from './login/auth';

const extra = Constants.manifest?.extra;
const gtmEnabled = extra?.gtmEnabled;
const analytics = getAnalytics(app);

export default function event(event: string, properties?: {[key: string]: any}) {
    if (gtmEnabled) {
        getAcceptation().then((value: string | null) => {
            if (value == 'true') {
                logEvent(analytics, event, properties);
            }
        });
    }
}

export function purchase(
    communicationId: string | null,
    communicationType: string,
    cost: number | null | undefined
) {
    if (cost == null || cost <= 0) {
        return;
    }
    const item = {id: communicationType, name: communicationType};
    event('purchase', {
        transactionId: communicationId,
        affiliation: 'kyvoitou',
        value: cost,
        currency: 'EUR',
        tax: 0,
        shipping: 0,
        items: [item],
    });
}

export function screen(name: string) {
    if (gtmEnabled) {
        getAcceptation().then((value: string | null) => {
            if (value == 'true') {
                logEvent(analytics, 'screen_view', {
                    firebase_screen: name,
                    screen: name,
                });
            }
        });
    }
}
