import {useContext} from 'react';
import {ActivityIndicator, View} from 'react-native';

import {StripeError} from '@stripe/stripe-js';

import {UserContext} from '~/contexts/UserContext';

import {ViewProps} from '../common/Containers';
import {SimpleText, Underlined} from '../common/Texts';
import {SetupPaymentIntentForm} from '../user/stripe/SetupPaymentForm';
import StepTitle from './StepTitle';
import {useUrlStepContext} from './context/UrlStepContext';
import {usePageVersion} from './hook/usePageVersion';
import {SelectedExpertUser} from './query/useSelectedExpert';
import {useLandingPageRoute} from './route';
import {styles} from './styles';

export type AddPaymentViewProps = ViewProps & {
    stepIndex: number;
    expertUser: SelectedExpertUser | undefined;
    loadingExpert?: boolean;
    onPaymentIntentConfirmed?: () => void;
    onPaymentIntentError?: (error: StripeError) => void;
    returnUrl: string;
    returnQueryParams: Record<string, string | null | undefined>;
    packId?: string;
};

export default function AddPaymentView({
    stepIndex,
    expertUser,
    loadingExpert,
    onPaymentIntentConfirmed,
    onPaymentIntentError,
    returnUrl,
    returnQueryParams,
    packId,
    ...viewProps
}: AddPaymentViewProps) {
    const {me} = useContext(UserContext);
    const route = useLandingPageRoute();
    const communicationId = 'payment-initiated-before-communication (landing page)';
    const {isPaymentStep, isOngoingCallStep} = useUrlStepContext();
    const pageVersion = usePageVersion();
    const isVisible = isPaymentStep || (pageVersion == 2 && !isOngoingCallStep);

    if (!isVisible) {
        return null;
    }

    const {step, event, ...checkoutQueryParams} = route.params;
    const isPackPayment = packId != null;

    return (
        <>
            <StepTitle selected={isPaymentStep}>
                {stepIndex}/ Renseignez un moyen de paiement
            </StepTitle>
            <View {...viewProps} style={{alignSelf: 'center'}}>
                {!isPackPayment && (
                    <>
                        <SimpleText style={styles.leftParagraph}>
                            La voyance est 100% gratuite. Renseigner une carte bancaire reste
                            essentiel pour deux raisons :
                        </SimpleText>
                        <SimpleText style={styles.leftParagraph}>
                            1/ Si vous allez au-delà des trois consultations de 5min{' '}
                            <Underlined>sur votre demande</Underlined>, la voyance pourra
                            continuer sans être coupée. Aucune crainte à avoir vous serez
                            prévenu avant et pourrez donc arrêter la consultation quand vous le
                            désirez{' '}
                            <Underlined>sans que cela ne vous coûte un centime</Underlined>.
                        </SimpleText>
                        <SimpleText style={styles.leftParagraph}>
                            2/{' '}
                            <Underlined>
                                La voyance est réservée aux personnes majeures
                            </Underlined>
                            . Renseigner une CB, nous permet donc de nous assurer que notre
                            service est bien adressé à une personne de plus de 18 ans.
                        </SimpleText>
                    </>
                )}
                {me == null && (
                    <>
                        <ActivityIndicator
                            style={{marginVertical: 50}}
                            size={50}
                            color={'grey'}
                        />
                        <SimpleText>Connexion en cours</SimpleText>
                    </>
                )}
                {me != null && (
                    <SetupPaymentIntentForm
                        returnUrl={returnUrl}
                        returnQueryParams={returnQueryParams}
                        service="call"
                        communicationId={communicationId}
                        onPaymentIntentConfirmed={onPaymentIntentConfirmed}
                        onPaymentIntentError={onPaymentIntentError}
                        packId={packId}
                    />
                )}
            </View>
        </>
    );
}
