import * as React from 'react';
import {StyleSheet} from 'react-native';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import {AlreadyConnected} from '~/components/navigation/Link';
import LoginForm from '~/components/user/LoginForm';
import * as Colors from '~/constants/Colors';
import {UserContext} from '~/contexts/UserContext';
import type {HomeStackProps} from '~/types';

export default function LoginScreen({navigation}: HomeStackProps) {
    const {me} = React.useContext(UserContext);

    return (
        <MainView style={styles.container}>
            <SimpleText style={styles.loginTitle}>S'identifier sur Kyvoitou.fr</SimpleText>
            {!me && (
                <LoginForm onSuccess={(credentials) => navigation.navigate('HomeScreen')} />
            )}
            {me && <AlreadyConnected />}
        </MainView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    loginTitle: {
        color: Colors.secondary,
        fontSize: 30,
        alignSelf: 'center',
        marginTop: 40,
        textAlign: 'center',
    },
});
