import {gql, useQuery} from '@apollo/client';
import type {QueryHookOptions} from '@apollo/client';

import type {HomeReviewsQuery} from './types/HomeReviewsQuery';

const HOME_REVIEW_QUERY = gql`
    query HomeReviewsQuery {
        getHomeReviews {
            edges {
                node {
                    id
                    expert {
                        expert {
                            url
                        }
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    client {
                        profile {
                            displayName
                            pictureName
                        }
                    }
                    createdDate
                    grade {
                        gradeId
                        label
                    }
                    comment
                }
            }
        }
    }
`;

export default function useHomeReviews() {
    const options: QueryHookOptions = {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    };
    return useQuery<HomeReviewsQuery>(HOME_REVIEW_QUERY, options);
}
