import React from 'react';

import Svg, {Defs, LinearGradient as SvgLinearGradient, Path, Stop} from 'react-native-svg';

import * as Colors from '~/constants/Colors';

export function Wave() {
    return (
        <Svg viewBox="0 0 1440 30">
            <Defs>
                <SvgLinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
                    <Stop offset="0" stopColor={Colors.rainbowFirst} stopOpacity="1" />
                    <Stop offset="0.5" stopColor={Colors.rainbowSecond} stopOpacity="1" />
                    <Stop offset="1" stopColor={Colors.rainbowThird} stopOpacity="1" />
                </SvgLinearGradient>
            </Defs>
            <Path
                fill="url(#grad)"
                d="M0-83.98v18.833S30.176-54.284,198.963-53.984,753.7-72.048,1071.925-72.048,1440-64.573,1440-64.573V-83.98Z"
                transform="translate(0 83.98)"
            />
        </Svg>
    );
}
