import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {MainView} from '~/components/common/Containers';
import {SimpleText} from '~/components/common/Texts';
import {PageTitle} from '~/components/common/Titles';
import * as Colors from '~/constants/Colors';
import Settings from '~/constants/Settings';

export default function EthicScreen() {
    return (
        <MainView>
            <View>
                <PageTitle>Charte éthique</PageTitle>
                <View style={styles.section}>
                    <SimpleText style={styles.text}>
                        Kyvoitou.fr est une plateforme dédiée aux arts divinatoires. Elle met
                        en relation des praticiens du monde de l’ésotérisme et des clients en
                        demande de véracité dans leur existence ou de réponses à des questions
                        sur des sujets comme le sentimental, le professionnel, le financier ou
                        autre.
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Dans ce contexte, Kyvoitou.fr propose un outil numérique qui permet la
                        réalisation de consultations entre les dits praticiens et les clients
                        tout en respectant les points légitimes à noter : professionnalisme,
                        sécurité, bienveillance, justesse et expérience.
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Si vous souhaitez en savoir plus vous pouvez jeter un coup d’œil sur
                        l’onglet « code déontologique voyant » dans le footer de notre site.
                        Tous les spirites doivent être en accord avec ce code pour pouvoir
                        participer à l’aventure Kyvoitou.fr.
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Chaque Spirite à été testé et approuvé par notre voyante phare Myrianne
                        Triade et un groupe de novices pour évaluer leurs compétences et la
                        véracité de leur voyance.
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Tout manquement au règlement kyvoitou.fr en terme de politesse, de
                        gentillesse de professionnalisme en réponse au code déontologique mis
                        en place pourra entrainer une fermeture du compte spirite ou le blocage
                        d’un compte client de manière immédiate et sans versement ou
                        rétribution aucune.
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Tous les spirites doivent pour pouvoir s’inscrire chez nous justifier
                        d’une expérience et d’une qualification notable reconnue pour pratiquer
                        leur arts sur Kyvoitou.fr.
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Nous avons à cœur de sélectionner des personnes qualifiées pour vous
                        fournir la plus belle et sincère des expérience médiumnique.
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Notre démarche exigeante reflète aujourd’hui notre implication pour
                        dépoussiérer le monde de la voyance et le rendre attractif et loyal à
                        notre philosophie : « Vous aider à prendre les meilleures décisions
                        pour être la meilleure version de vous même. »
                    </SimpleText>
                </View>
            </View>
            <View style={styles.code}>
                <PageTitle>Code de déontologie</PageTitle>
                <View style={styles.section}>
                    <SimpleText style={styles.text}>
                        En acceptant d’être consultant chez KYVOITOU, vous acceptez un certain
                        nombre de responsabilités, règles et vous êtes en accord avec le code
                        de déontologie de la plateforme.
                    </SimpleText>
                </View>
                <View style={styles.section}>
                    <SimpleText style={styles.article}>GÉNÉRALITÉS DU MÉTIER</SimpleText>
                    <SimpleText style={styles.text}>
                        Nous vous rappelons tout d’abord que la pratique des arts divinatoires
                        n’a pour seul but que d’aider ou éclairer le client de façon à ce que
                        celui-ci prenne conscience de sa situation et puisse pleinement exercer
                        son libre arbitre en toute connaissance de cause. Nous ne souhaitons
                        pas inquiéter nos clients mais les soutenir et les rassurer dans des
                        passages de vie difficiles.
                    </SimpleText>
                </View>
                <View style={styles.section}>
                    <SimpleText style={styles.article}>ABUS DE FAIBLESSE</SimpleText>
                    <SimpleText style={styles.text}>
                        Il est donc immoral, et par la même occasion sévèrement sanctionné,
                        d’induire en erreur le client, de l’intimidé, de profiter de sa
                        faiblesse morale, d’abuser de la naïveté de personnes en détresse,
                        âgées ou malades.
                    </SimpleText>
                </View>
                <View style={styles.section}>
                    <SimpleText style={styles.article}>PRÉDICTIONS</SimpleText>
                    <SimpleText style={styles.text}>
                        De par la nature « nonexacte » et « non vérifiable » de votre activité,
                        il est interdit de faire des promesses de toutes natures, de proposer
                        des réalisations de vœux, de proposer un retour d’affection ou le
                        retour de l’être aimé et de donner des garanties aux clients quant à la
                        réalisation de vos prédictions de changements dans leur vie ou tout
                        autre élément incertain.
                    </SimpleText>
                </View>
                <View style={styles.section}>
                    <SimpleText style={styles.article}>
                        PRÉDICTIONS DE MAUVAISES AUGURE
                    </SimpleText>
                    <SimpleText style={styles.text}>
                        Les prédictions non vérifiables, mêmes positives, étant interdites, il
                        va de soi que les prédictions négatives le sont plus encore. Ainsi il
                        est défendu de faire des prévisions de maladie, de décès, d’accidents
                        ou toutes autres prédictions de malheur. Ce cas est valable aussi pour
                        les accouchements, naissances et grossesses.
                    </SimpleText>
                </View>
                <View style={styles.section}>
                    <SimpleText style={styles.article}>MÉDECINE & SANTÉ</SimpleText>
                    <SimpleText style={styles.text}>
                        Les consultants sont aptes à prodiguer des conseils de vie à condition
                        que les conseils ne soient pas néfastes pour les clients. Les
                        consultants doivent se cantonner à leur domaine de compétence qui est
                        le conseil de vie et s’exposent à de dures sanctions s’ils
                        s’improvisaient médecins, avocats, pharmaciens, guérisseur ou tout
                        autre corps de métier nécessitant une formation et une accréditation.
                        Sera sanctionné le fait de vouloir pratiquer via la plateforme toute
                        forme de guérison (physique, mentale, psychique et émotionnelle) ou de
                        prodiguer des conseils de santé (ex : prescription médicamenteuse,
                        alimentation etc...).
                    </SimpleText>
                </View>
                <View style={styles.section}>
                    <SimpleText style={styles.article}>CORPS DE MÉTIER</SimpleText>
                    <SimpleText style={styles.text}>
                        Dans le même ordre d’idée, il est interdit de donner des conseils
                        juridiques, légaux et pénaux, mécaniques, architecturaux, techniques ou
                        tout autres conseils dont il s’avèrerait que le consultant les
                        prodiguant ne possèderait pas la formation adéquate.
                    </SimpleText>
                </View>
                <View style={styles.section}>
                    <SimpleText style={styles.article}>MAGIE BLANCHE OU NOIRE</SimpleText>
                    <SimpleText style={styles.text}>
                        Nous considérons le conseil de vie comme un métier noble qui apporte du
                        positif dans la vie de nos clients, ce qui n’est pas le cas de
                        certaines autres pratiques ésotériques. Il est donc INTERDIT de
                        proposer des travaux occultes et rituels de toutes sortes, de proposer
                        une protection contre des attaques x, des sorts de protection ou encore
                        de la résolution de malédictions.
                    </SimpleText>
                </View>
            </View>
        </MainView>
    );
}

const styles = StyleSheet.create({
    container: {
        marginVertical: 40,
    },
    article: {
        fontSize: 22,
        marginTop: 30,
        marginBottom: 15,
        textTransform: 'uppercase',
        textAlign: 'center',
        color: Colors.secondary,
        fontWeight: 'bold',
    },
    section: {
        paddingHorizontal: 15,
    },
    text: {
        marginTop: 15,
        fontSize: 16,
        textAlign: 'center',
        color: Colors.secondary,
    },
    bold: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: Colors.secondary,
    },
    code: {
        marginVertical: 50,
    },
});
